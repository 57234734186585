module.exports = {
	"fi": {
		"core": {
			"alert": {
				"message": "Jokin meni vikaan! Yritä uudelleen.",
				"correlationIdLabel": "Korrelaatiotunnus:"
			},
			"header": {
				"title": "Kauppiasportaali"
			},
			"usermenu": {
				"language": "Kieli",
				"country": "Maa",
				"timezone": "Aikavyöhyke",
				"logout": "Kirjaudu ulos",
				"profile": "Profiili",
				"changeProfile": "Muokkaa",
				"tooltip": "Profiilisi"
			},
			"appsmenu": {
				"defaultTooltip": "Omat sovelluksesi",
				"initialTooltip": "Löydät omat sovelluksesi täältä.",
				"newAppsTooltip": "Meillä on sinulle uusia sovelluksia!"
			},
			"two-factor-opt-in": {
				"title": "Ota kaksivaiheinen vahvistus käyttöön",
				"intro-paragraph": "Kauppiasportaalin tililläsi ei ole käytössä kaksivaiheista tunnistautumista (2FA). <b>16. tammikuuta 2023</b> alkaen kaksivaiheinen tunnistautuminen on kaikille Kauppiasportaalin käyttäjille pakollista. Voit lukea lisää kaksivaiheisen tunnistautumisen tärkeydestä, sen käyttöönotosta ja sitä koskevista yleisistä kysymyksistä <faqLink>täältä</faqLink>.",
				"help-link-text": "Klikkaamalla tästä",
				"help-text": " saat lisätietoja siitä, kuinka otat sen käyttöön ja kuinka se suojaa sinua.",
				"warning-text": "Ennen kuin jatkat, varmista että sinulla on älypuhelin ja pääsy internetiin",
				"continue-button": "Ota käyttöön"
			},
			"kuasPrompter": {
				"title": "Olet valinnut",
				"content": "{count} kauppiastunnusta",
				"contentTooltip": "Kopioi kauppiastunnukset leikepöydälle napsauttamalla",
				"button": "Vaihda valitut kauppiastunnukset"
			},
			"notification": {
				"title": "Ilmoitus",
				"content": {
					"uptodate": "Tietosi ovat ajan tasalla!"
				}
			},
			"languages": {
				"cs": "Tšekki",
				"da": "Tanska",
				"de": "Saksa",
				"el": "Kreikka",
				"en": "Englanti",
				"en_GB": "Englanti (UK)",
				"en_US": "Englanti (US)",
				"es": "Espanja",
				"fi": "Suomi",
				"fr": "Ranska",
				"it": "Italia",
				"nl": "Hollanti",
				"no": "Norja",
				"pl": "Puola",
				"pt": "Portugali",
				"sv": "Ruotsi"
			},
			"countries": {
				"at": "Itävalta",
				"au": "Australia",
				"be": "Belgia",
				"ca": "Kanada",
				"ch": "Sveitsi",
				"cz": "Tšekki",
				"de": "Saksa",
				"dk": "Tanska",
				"ee": "Viro",
				"es": "Espanja",
				"fi": "Suomi",
				"fr": "Ranska",
				"gb": "Iso-Britannia",
				"gr": "Kreikka",
				"ie": "Irlanti",
				"it": "Italia",
				"mx": "Meksiko",
				"nl": "Alankomaat",
				"no": "Norja",
				"se": "Ruotsi",
				"us": "Yhdysvallat",
				"nz": "Uusi-Seelanti",
				"pl": "Puola",
				"pt": "Portugali",
				"ro": "Romania"
			},
			"selectLanguage": {
				"title": "Valitse kieli",
				"paragraph": "Tämä on nyt oletuskielesi.",
				"submit": "OK"
			},
			"selectCountry": {
				"title": "Valitse maa",
				"paragraph": "Tämä on nyt oletusmaasi.",
				"submit": "OK"
			},
			"updateProfile": {
				"title": "Päivitä profiili",
				"submit": "OK",
				"emailLabel": "Sähköpostiosoite",
				"firstNameLabel": "Etunimi",
				"lastNameLabel": "Sukunimi",
				"resetPasswordLabel": "Salasana",
				"resetPasswordSelectedValue": "Nollaa",
				"resetPasswordSuccessLabel": "Sähköpostin nollausviestin lähetys onnistui.",
				"resetPasswordErrorLabel": "Sähköpostin lähetyksessä tapahtui virhe. Yritä myöhemmin uudelleen.",
				"error": "Tietojen päivitys epäonnistui",
				"changeCookiePolicyLabel": "Evästekäytäntö",
				"changeCookiePolicySelectedValue": "Muuta",
				"securitySectionTitle": "Turvallisuus",
				"preferencesSectionTitle": "Asetukset",
				"mfaLabel": "Kaksivaiheinen tunnistus",
				"mfaDescription": "Edellyttää sisäänkirjautumista aikaan perustuvalla kertaluonteisella salasanalla. Suosittelemme ottamaan tämän käyttöön.",
				"twoFactor": {
					"reset": "Muuta kaksivaiheista tunnistautumistasi",
					"isEnabled": "Aktivoitu",
					"isDisabled": "Poistettu käytöstä",
					"title": "Kaksivaiheinen tunnistus (2FA)",
					"description": "Aikarajoitetun kertakäyttöisen salasanan ansiosta käyttäjät voivat kirjautua sisään turvallisemmin.",
					"tooltip": "Varmista, että sinulla on vähintään lupa {permission} sovellukselle {app} jokaisen kaupan osalta, johon käyttäjällä on käyttöoikeus, jotta kaksivaiheisen tunnistautumisen asetuksien muuttaminen on mahdollista.",
					"confirmationDialog": {
						"closeButton": "Sulje",
						"confirmationButton": "Kyllä, jatka",
						"cancelButton": "Peruuta",
						"resetSuccess": {
							"title": "Kaksivaiheisen tunnistautumisen laitteen nollaus onnistui.",
							"description": "Seuraavan kerran, kun käyttäjä kirjautuu sisään, hänen tulee määrittää kaksivaiheiselle tunnistautumiselle uusi laite."
						},
						"resetConfirmation": {
							"title": "Haluatko varmasti nollata tämän käyttäjän kaksivaiheisen tunnistautumisen laitteen?",
							"description": "Jatkamalla nollaat laitteen, jota on käytetty kaksivaiheisen tunnistautumisen koodien luomiseen tälle käyttäjälle. Seuraavan kerran, kun käyttäjä kirjautuu sisään, hänen tulee määrittää kaksivaiheiselle tunnistautumiselle uusi laite."
						}
					}
				}
			},
			"footer": {
				"copyright": {
					"eu": "Tekijänoikeus © 2005-{currentYear} Klarna Bank AB (publ). Kaikki oikeudet pidätetään",
					"oc": "Tekijänoikeus © 2005-{currentYear} Klarna Australia Pty Ltd, ABN # 82 635 912 579",
					"ap": "Tekijänoikeus © 2005-{currentYear} Klarna Australia Pty Ltd, ABN # 82 635 912 579",
					"us": "Tekijänoikeus © 2005-{currentYear} Klarna Inc. NMLS #1353190, 629 N. High Street, Third Floor, Columbus, OH 43215"
				},
				"terms": "Ehdot"
			},
			"accessDenied": {
				"title": "Tarvitset lisäpääsyn tätä sivua varten. ",
				"message": "Jos sinulla on kysyttävää, olethan yhteydessä ylläpitäjääsi tai Klarnaan."
			},
			"midSelector": {
				"label": "Kauppa"
			},
			"storeSelector": {
				"label": "Kauppa"
			},
			"storesSelector": {
				"closeText": "Valmis",
				"closeTextWithSelection": "Sovella # valintaan",
				"deselectAllOptionText": "Tyhjennä valinta",
				"emptyStateText": "Kauppoja ei löytynyt",
				"label": "Kaupat",
				"searchFieldLabel": "Hae kaupan mukaan",
				"selectAllOptionText": "Valitse kaikki kaupat",
				"selectedAllText": "Kaikki kaupat",
				"selectedText": "# kaupalle"
			},
			"pagination": {
				"ofLabel": "/"
			},
			"singleDatePicker": {
				"selectDate": "Valitse päivä"
			},
			"dateRangePicker": {
				"startLabel": "Alkaen",
				"endLabel": "Asti",
				"months": {
					"january": "Tammikuu",
					"february": "Helmikuu",
					"march": "Maaliskuu",
					"april": "Huhtikuu",
					"may": "Toukokuu",
					"june": "Kesäkuu",
					"july": "Heinäkuu",
					"august": "Elokuu",
					"september": "Syyskuu",
					"october": "Lokakuu",
					"november": "Marraskuu",
					"december": "Joulukuu"
				},
				"weekDaysShort": {
					"monday": "M",
					"tuesday": "T",
					"wednesday": "K",
					"thursday": "T",
					"friday": "P",
					"saturday": "S",
					"sunday": "S"
				}
			},
			"regionSelector": {
				"noStores": "Kauppoja ei määritetty",
				"andMore": "ja lisää",
				"regions": {
					"eu": "Eurooppa ",
					"us": "Pohjois-Amerikka",
					"ap": "Aasian ja Tyynenmeren alue"
				}
			},
			"regionSelectorBar": {
				"modal": {
					"title": "Vaihda alue",
					"description": "Valitse alue ja siihen liittyvät kaupat asianmukaisten tietojen näyttämiseksi.",
					"buttonText": "Vahvista"
				},
				"selectedRegion": "Valittu alue",
				"changeButton": "Muuta"
			},
			"appIcon": {
				"newLabel": "UUSI"
			},
			"appCategory": {
				"dayToDay": "Päivittäisiin",
				"nko": "Klarna Online",
				"tools": "Työkalut",
				"preferences": "Asetukset"
			},
			"apps": {
				"account-information-fe": {
					"title": "Tilitiedot",
					"description": "Löydä yritys- ja pankkitietosi sekä allekirjoittamasi sopimukset."
				},
				"home-fe": {
					"title": "Maksut-etusivu"
				},
				"boosters-home-fe": {
					"title": "Aloita heti"
				},
				"marketing-home-fe": {
					"title": "Markkinointi-etusivu"
				},
				"nko-app": {
					"title": "Klarnan verkkosovellukset"
				},
				"orders-fe": {
					"title": "Tilaushallinta",
					"description": "Etsi ja hallinnoi tilauksiasi Klarnasta.",
					"nkoLinkText": "Katso vanhempia tilauksia"
				},
				"nko-orders": {
					"title": "Tilaukset (KO)",
					"description": "Etsi ja hallinnoi Klarna Onlinen kautta tekemiäsi tilauksia."
				},
				"settlements-fe": {
					"title": "Tilitykset",
					"description": "Katsele tapahtumiasi ja luo täsmäytysraportteja.",
					"nkoLinkText": "Katso vanhempia raportteja"
				},
				"nko-settlements": {
					"title": "Tilitykset (KO)",
					"description": "Etsi tilaus- ja maksuraportteja Klarna Onlinesta."
				},
				"logs-app-fe": {
					"title": "Kehittäjälokit",
					"description": "Etsi API-lokeja ratkaistaksesi integrointiongelmia."
				},
				"users-fe": {
					"title": "Käyttäjähallinta",
					"description": "Kutsu uusia käyttäjiä ja hallinnoi nykyisiä käyttäjiä portaalissa."
				},
				"settings-fe": {
					"title": "Maksuasetukset",
					"description": ""
				},
				"nko-settings": {
					"title": "Asetukset (KO)",
					"description": "Hallitse kauppasi asetuksia, raportteja ja yhteystietoja."
				},
				"seller-fe": {
					"title": "Kaupassa",
					"description": "Luo uusia tilauksia Klarnan kaupansisäisen ratkaisun avulla."
				},
				"products-fe": {
					"title": "Perehdytys",
					"description": "Ota maksut käyttöön määrittämällä tilisi."
				},
				"upstream-fe": {
					"title": "Viestintä sivulla",
					"description": "Myy enemmän räätälöityjen ja personoitujen rahoitustarjousten avulla."
				},
				"lending-fe": {
					"title": "Business Financing",
					"description": "Yritysrahoitusta yksinkertaisesti."
				},
				"branding-fe": {
					"title": "Brändihallinta",
					"description": "Hallitse sitä, kuinka oma brändisi näkyy Klarnassa."
				},
				"disputes-fe": {
					"title": "Twister",
					"description": "Käsittele kiista-asioitasi."
				},
				"logistics-fe": {
					"title": "Toimitusintegroinnit",
					"description": "Hallitse kaikkea logistiikkaasi yhdestä paikasta."
				},
				"developers": {
					"title": "Kehittäjät"
				},
				"klarna-docs": {
					"title": "Klarna Docs"
				},
				"support": {
					"title": "Tuki",
					"description": "Tarkastele ja hallitse tukipyyntöjäsi."
				},
				"integration-guides-fe": {
					"title": "Integrointioppaat",
					"description": ""
				},
				"klarna-search-b2b-portal": {
					"title": "Hae ja vertaa",
					"description": "Aloita Klarnan tehokkaalla hakukoneella ja vertailushoppailulla. Pääset tilastoihin ja työkaluihin, jotka on suunniteltu näkyvyytesi parantamiseksi."
				},
				"siwk-fe": {
					"title": "Kirjaudu Klarnalla",
					"description": "Hallitse Kirjaudu Klarnalla -integrointiasi."
				},
				"kec-fe": {
					"title": "Pikakassa",
					"description": "Hallitse Express Checkout -integrointiasi."
				},
				"partner-self-service": {
					"title": "Kaupan hallinta",
					"description": "Hallitse Klarna-kauppaasi.",
					"nkoLinkText": "Tarkastele ja hallitse kauppasi asetuksia"
				}
			},
			"ui": {
				"tabs": {
					"home": "Maksut",
					"growth": "Kasvumarkkinointi",
					"boosters": "Konversiota tehostavat ominaisuudet",
					"badge": "Uutta"
				},
				"buttons": {
					"backButton": "Takaisin"
				}
			},
			"errorBoundary": {
				"contact": "Kokeile päivittää sivua. {br}Jos virhe jatkuu, ota yhteyttä <supportLink>Kauppiastukeen</supportLink> ja anna tunnus {errorId}.",
				"errorDescription": "Virhetunnus - {errorId}",
				"reloadButton": "Päivitä",
				"title": "Tapahtui valitettavasti virhe"
			},
			"maintenance": {
				"pageTitle": "Ylläpito",
				"title": "Kauppiasportaalissa on käynnissä aikataulutettu huolto",
				"summary": "Palaamme pian",
				"scheduledSummary": "Palaamme {until}"
			},
			"errorTemplates": {
				"errorDescription": "Virhe: {code}",
				"backButton": "Takaisin",
				"reloadButton": "Päivitä",
				"default": {
					"errorDescription": "Virhetunnus - {errorId}",
					"title": "Tapahtui valitettavasti virhe",
					"contact": "Kokeile päivittää sivua. Jos virhe jatkuu, ota yhteyttä <supportLink>Kauppiastukeen</supportLink> ja anna virhetunnus {errorId}."
				},
				"internalServerError": {
					"title": "Hups, jotain meni pieleen",
					"message": "Jostakin meistä johtuvasta syystä pyyntöäsi ei pystytä käsittelemään. Voit yrittää uudelleen tai palata myöhemmin takaisin."
				},
				"notFound": {
					"title": "Täällä ei ole mitään!",
					"message": "Sivua ei ole olemassa, tai olet kirjoittanut selaimeesi väärän osoitteen. Tarkista osoite ja yritä uudelleen."
				},
				"forbidden": {
					"title": "Sinulla ei ole tämän sivun käyttöoikeutta",
					"message": "Kauppiastunnuksellasi ei ole tämän sivun käyttöoikeutta. Jos uskot, että kyseessä on virhe, otathan yhteyttä Kauppiastukeen tai yrityksesi tilin ylläpitäjään."
				}
			},
			"codeSnippet": {
				"clipboardButtonLabel": "Kopioi leikepöydälle",
				"clipboardButtonLabelSuccess": "Kopioitu!"
			}
		},
		"blackFriday": {
			"v1": {
				"title": "Black Friday",
				"intro": "Yksi vuoden tärkeimmistä myyntipäivistä on tulossa. Black Fridayn aikana toimitamme sinulle lähes reaaliaikaisia tietoja myynneistäsi. Valmistaudutaan sillä välin menestykseen!",
				"countdown": "Alkaa #dd #ht #mp #ss…",
				"box1": {
					"title": "3,5-kertainen kulutus*",
					"text": "Viime vuonna Klarnan shoppailijat* kuluttivat keskimäärin 3,5 kertaa enemmän Black Fridayn aikana kuin minä tahansa muuna päivänä samana vuonna.",
					"footnote": "*Kaikkien Klarna-kauppiaiden kategorioiden ja alueiden keskiarvo."
				},
				"box2": {
					"title": "2,5-kertainen myyntivolyymi*",
					"text": "Viime vuonna Klarna-kauppiaat saavuttivat 2,5 kertaa suuremman myyntivolyymin Black Fridayn aikana kuin minä tahansa muuna päivänä samana vuonna.",
					"footnote": "*Kaikkien Klarna-kauppiaiden kategorioiden ja alueiden keskiarvo."
				},
				"sectionTitle": "Kasvatetaan Black Fridayn myyntiäsi",
				"marketing": {
					"title": "Markkinoi liiketoimintaasi ennen Black Fridaytä ja sen aikana",
					"text": "Houkuttele lisää shoppailijoita kauppaasi Black Friday -shoppailukauden aikana maksuttomien sosiaalisen median materiaalien ja sähköpostikampanjoiden avulla."
				},
				"branding": {
					"title": "Tule nähdyksi Klarna-sovelluksessa",
					"text": "12 miljoonaa shoppailijaa käyttää Klarna-sovellusta joka kuukausi. Varmista näkyvyytesi sovelluksessa tänä Black Fridaynä lisäämällä ja päivittämällä brändisi tiedot täällä Kauppiasportaalissa."
				},
				"cta": "Aloita heti",
				"close": "Sulje"
			},
			"v2": {
				"title": "Black Friday week",
				"orderVolume": "Tilausmäärä",
				"orderCountTitle": "Luodut tilaukset",
				"orderCount": "{count} tilausta",
				"box": {
					"title": "Haluatko lisätä konversiota?",
					"subtitle1": "On-site messaging",
					"subtitle2": "Tiedottava kampanjaviesti.",
					"text": "On-site messaging-työkalumme avulla tehostat myyntiäsi ja tilauksen keskimääräistä arvoa kampanjaviestien ansiosta. Kerro asiakkaillesi heidän maksuvaihtoehdoistaan ostoskokemuksen alusta loppuun asti.",
					"cta": "Aloita heti"
				},
				"selector": {
					"done": "Valmis",
					"applySelection": "Sovella # valintaan",
					"selectAll": "Valitse kaikki",
					"allOptions": "Kaikki vaihtoehdot valittu",
					"someOptions": "# vaihtoehtoa valittu",
					"storeMultiSelectLabel": "Valitse # kauppaa",
					"allStores": "Kaikki kaupat"
				},
				"currencyLabel": "Valitse valuutta",
				"storeLabel": "Valitse kauppa"
			},
			"v3": {
				"title": "Black Friday week",
				"text": "Tässä naet myyntilukusi yhdeltä vuoden tärkeimmista myyntiviikoista.",
				"summaryTitle": "Yhteenveto",
				"orderVolume": "Kokonaismäärä",
				"orderCount": "Tilaukset yhteensä",
				"summaryRange": "23-30 November",
				"comparisonLastWeek": "verrattuna samaan päivään viime viikolla",
				"tabs": {
					"monday": "Maanantai",
					"tuesday": "Tiistai",
					"wednesday": "Keskiviikko",
					"thursday": "Torstai",
					"blackFriday": "Black Friday",
					"saturday": "Lauantai",
					"sunday": "Sunnuntai",
					"cyberMonday": "Cyber Monday"
				}
			}
		},
		"mpSatSurvey": {
			"marble": {
				"topbarButtonLabel": "Palautetta uudesta ilmeestämme",
				"step1": {
					"welcome": "Pidätkö uudesta ilmeestänne?",
					"satisfied": "Erittäin tyytyväinen",
					"unsatisfied": "Erittäin tyytymätön"
				},
				"step2": {
					"textAreaLabel": "Muu (mielipiteesi)",
					"textAreaMaxLength": "Enimmäismerkkiraja saavutettu",
					"submit": "Lähetä",
					"content": {
						"titlePositive": "Mistä pidät eniten?",
						"titleNegative": "Mistä pidät vähiten?",
						"tags": {
							"color": "Väri ja tyyppi",
							"navigation": "Navigointi",
							"names": "Sivujen nimi",
							"structure": "Sivujen rakenne"
						}
					}
				},
				"step3": {
					"title": "Kiitos!",
					"description": "Arvostamme palautettasi."
				}
			},
			"buttonLabel": "Anna palautetta",
			"errorMessage": "Palautteesi lähettämisessä tapahtui virhe.",
			"retry": "Yritä uudelleen",
			"step1": {
				"welcome1": "Miten tyytyväinen olet",
				"welcome2": "Kauppiasportaaliin?",
				"emojiSelector": {
					"angry": "Erittäin tyytymätön",
					"unhappy": "Hieman tyytymätön",
					"neutral": "En tyytymätön enkä tyytyväinen",
					"happy": "Tyytyväinen",
					"love": "Pidän siitä kovasti"
				},
				"remindMeText": "Haluatko jakaa mielipiteesi?",
				"remindMeLink": "Muistuta minua tästä kyselystä myöhemmin.",
				"dontShowAgainLink": "Älä näytä tätä uudelleen."
			},
			"step2": {
				"textAreaLabel": "Muu (mielipiteesi)",
				"textAreaMaxLength": "Enimmäismerkkiraja saavutettu",
				"submit": "Lähetä",
				"content": {
					"negative": {
						"title": "Onpa harmillista.",
						"description": "Mitä asioita meidän pitäisi Kauppiasportaalissa parantaa? (Valitse kaikki sopivat)",
						"tags": {
							"loading": "Latausaika",
							"menu": "Valikon rakenne",
							"mobile": "Mobiiliversio",
							"filter": "Suodattimen tiedot",
							"complexity": "Toimintojen suorittaminen tehokkaasti"
						}
					},
					"positive": {
						"title": "Hieno juttu!",
						"description": "Haluamme silti tehdä asiat paremmin. Mitkä asiat tekisivät Kauppiasportaalin käyttökokemuksestasi vieläkin paremman",
						"tags": {
							"views": "Mukautetut näkymät",
							"looks": "Ulkoasu ja tuntu",
							"tours": "Ohjatut kierrokset",
							"shortcuts": "Oikopolut tehtäviin"
						}
					}
				}
			},
			"step3": {
				"title": "Kiitos! Vielä viimeinen asia.",
				"content1": "Haluaisimme kutsua sinut haastatteluun oppiaksemme lisää Kauppiasportaalin käyttökokemuksestasi.",
				"content2": "Haluaisitko osallistua?",
				"content3": "Jos haluat, otamme sinuun yhteyttä rekisteröidyn sähköpostisi kautta.",
				"yes": "Kyllä kiitos",
				"no": "Ei kiitos"
			},
			"step4": {
				"yesTitle": "Hienoa! Otamme sinuun yhteyttä.",
				"noTitle": "Selvä. Kiitos!",
				"content1": "Kiitos, kun käytit aikaasi näihin kysymyksiin vastaamiseen.",
				"content2": "Voit nyt sulkea tämän ponnahdusikkunan."
			}
		},
		"boost": {
			"integrationTypeModal": {
				"title": "Kuinka haluat tuotteen {productName} määrittää?",
				"labels": {
					"plugin": "Määritä lisäosalla",
					"web": "Määritä Web SDK:lla"
				}
			}
		}
	}
};