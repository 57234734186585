module.exports = {
	"pt": {
		"shared": {
			"months": {
				"january": "Janeiro",
				"february": "Fevereiro",
				"march": "Março",
				"april": "Abril",
				"may": "Maio",
				"june": "Junho",
				"july": "Julho",
				"august": "Agosto",
				"september": "Setembro",
				"october": "Outubro",
				"november": "Novembro",
				"december": "Dezembro"
			},
			"weekDaysShort": {
				"monday": "S",
				"tuesday": "T",
				"wednesday": "Q",
				"thursday": "Q",
				"friday": "S",
				"saturday": "S",
				"sunday": "D"
			}
		}
	}
};