module.exports = {
	"de": {
		"credentials": {
			"addOriginModal": {
				"buttonPrimaryText": "Registrieren",
				"buttonSecondaryText": "Löschen",
				"description": "Bitte registrieren Sie eine Herkunftsdomäne, von der Sie erwarten, dass Sie das Web SDK damit verwenden werden. Wenn Sie mehr als eine registrieren müssen, können Sie das später tun.",
				"errorMessage": "Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut oder kontaktieren Sie den Support, wenn das Problem weiterhin besteht.",
				"inputLabel": "Geben Sie den Rest Ihrer URL ein",
				"title": "Erlaubte Herkunft registrieren"
			},
			"clientKeyBlock": {
				"mainText": "Ihre Mandatenkennung ist direkt im Code mit der von Ihnen bereitgestellten Ursprungsdomain eingerichtet.",
				"secondaryText": "Folgen Sie zur weiteren Verwaltung bitte dem Link zur",
				"linkDescription": "Mandantenkennungs-Seite",
				"linkLabel": "Schließen"
			},
			"clientKeyField": {
				"label": "Mandantenkennung",
				"defaultErrorMessage": "Mandantenkennung fehlt"
			},
			"copyToClipboardButton": {
				"label": "Kopieren",
				"labelOnCopy": "Kopiert"
			},
			"originField": {
				"label": "Ursprungsdomäne",
				"tooltipMainText": "Um weitere Ursprungsdomänen zu registrieren oder bestehende zu verwalten, gehen Sie zur",
				"tooltipLinkLabel": "Mandantenkennungs-Seite"
			},
			"originInputField": {
				"label": "Geben Sie den Rest Ihrer URL ein",
				"validationError": "Bitte geben Sie eine gültige Domänen-URL ein",
				"invalidProtocolError": "URL muss beginnen mit http:// oder https://"
			},
			"addApiKeySuccessModal": {
				"title": "",
				"description": "Bitte speichern Sie die folgenden Zugangsdaten auf Ihrem Gerät oder an einem anderen sicheren Speicherort.",
				"warning": "Das Passwort wird nur dieses Mal angezeigt. Nach Schließen des Fensters wird es verschwinden.",
				"checkbox": "Ich habe die Zugangsdaten auf meinem Gerät gespeichert.",
				"download": "Als .txt herunterladen"
			},
			"common": {
				"password": "Passwort",
				"username": "Benutzername",
				"close": "Schließen"
			}
		}
	}
};