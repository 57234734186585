module.exports = {
	"sv": {
		"help-tool": {
			"button-text": "Hjälp",
			"support-button": "Få hjälp",
			"support-page": {
				"create-ticket": {
					"title": "Få hjälp",
					"instructions": "Skicka en förfrågan nedan så återkommer vi inom en vardag.",
					"related-content": {
						"title": "Detta kanske löser ditt problem"
					},
					"fields": {
						"mid": {
							"label": "Välj butik",
							"validation": "Du måste välja en butik"
						},
						"phoneNumber": {
							"label": "Telefonnummer (valfritt)"
						},
						"topic": {
							"question": "Vad är din fråga relaterad till?",
							"label": "Välj ett ämne",
							"validation": "Du måste välja ett ämne",
							"options": {
								"orders": "Beställningar",
								"settlements": "Avräkningar",
								"agreements": "Avtal",
								"others": "Övriga"
							}
						},
						"subject": {
							"label": "Ämne",
							"validation": "Du måste ange ett ämne"
						},
						"description": {
							"label": "Meddelande",
							"validation": "Du måste ange en beskrivning"
						},
						"attachment": {
							"label": "Lägg till bilaga",
							"button": "Ladda upp",
							"info": "Lägg till skärmdumpar så att vi kan lösa problemet snabbare.",
							"supportedFileTypes": "Filtyper som stöds: .png, .jpg, .pdf",
							"error": "Total filstorlek måste vara under {maxSize} MB"
						}
					},
					"submit": "Skicka förfrågan",
					"service-unavailable-error": "Oj! Något gick fel. Försök igen om några minuter. Om problemet kvarstår, {link} för hjälp.",
					"generic-error": "Vi kunde inte behandla din förfrågan. Våra system har inte kontakt med varandra just nu. Testa att uppdatera sidan eller kom tillbaka senare. Om problemet kvarstår, {link} för hjälp.",
					"contact-link": "kontakta oss"
				},
				"view-ticket": {
					"title": "Klart!",
					"details": "Vi har mottagit din förfrågan och återkommer inom en vardag. Här är din info:",
					"ticket": "Ärende #{ticketId}",
					"email": "E-postadress: {email}",
					"status": "Du kan se status för ditt ärende på vår Supportsida.",
					"track": "Se status"
				}
			},
			"header": {
				"homeLink": "Återställ"
			},
			"home": {
				"greeting": "Hej, {givenName}! Hur kan vi hjälpa dig?",
				"topics": "Vad behöver du hjälp med?"
			},
			"topics": {
				"expandButton": "Mer"
			},
			"feedback": {
				"title": "Var det här till hjälp?",
				"optionYes": "Ja, det var till hjälp",
				"optionNo": "Nej, jag behöver mer hjälp",
				"positiveFeedback": "Tack för din positiva feedback. Detta hjälper oss att erbjuda dig mer relevanta artiklar i framtiden."
			},
			"freeTextFeedback": {
				"title": "Hjälp oss att bli bättre",
				"placeholder": "Varför tyckte du inte att innehållet var användbart?",
				"submitButton": "Skicka",
				"feedbackMessage": "Tack för din feedback. Detta hjälper oss att erbjuda dig mer relevanta artiklar i framtiden."
			},
			"relatedContent": {
				"title": "Relaterat innehåll"
			},
			"search": {
				"buttonLabel": "Sök",
				"input": "Söker efter artiklar...",
				"loading": "Laddar …",
				"results": {
					"contact": "Kan du inte hitta vad du behöver?",
					"empty": "Tyvärr hittades inga resultat för din fråga. Försök att söka efter en annan term.",
					"singleTopicFound": "Vi har hittat 1 ämne",
					"multipleTopicsFound": "Vi har hittat {count} ämnen",
					"singleArticleFound": "Vi har hittat 1 artikel",
					"multipleArticlesFound": "Vi har hittat {count} artiklar"
				}
			},
			"contact": {
				"title": "Kontakta oss",
				"channelsIntro": "Hör av dig till oss så att vi kan hjälpa dig",
				"businessDetailsTitle": "Dina företagsuppgifter",
				"businessDetailsName": "Företagsnamn",
				"businessDetailsEmail": "E-post",
				"businessDetailsMid": "Handlar-id",
				"phone": {
					"title": "Ring oss",
					"paragraph": "Du kan ringa oss under arbetstid på vardagar. Om du befinner dig i Sverige eller Storbritannien kan du även ringa oss på helger mellan klockan 10-16."
				},
				"email": {
					"title": "Skicka e-post till oss",
					"paragraph": "Skicka ett e-postmeddelande så hör vi av oss så snart vi kan."
				}
			},
			"errorBoundary": {
				"title": "Något gick fel",
				"message": "Vi ber om ursäkt, ett fel inträffade. Du kan klicka på knappen \"Gå tillbaka\" nedan för att gå tillbaka till hjälpverktyget eller \"Få support\"-knappen för att kontakta oss.",
				"resetButton": "Gå tillbaka",
				"errorId": "Fel-ID: {errorId}"
			},
			"tour": {
				"prev": "Föregående",
				"next": "Nästa",
				"done": "Klar"
			},
			"notAvailableMobile": {
				"title": "Ej tillgänglig",
				"buttonBack": "Tillbaka",
				"paragraph": "Detta innehåll är inte tillgängligt på din enhet. Försök att öppna det på en enhet med större skärm så som en stationär dator."
			},
			"shareButton": {
				"title": {
					"article": "Dela en djuplänk till den här artikeln",
					"topic": "Dela en djuplänk till det här ämnet",
					"tour": "Dela en djuplänk till den här rundturen"
				},
				"radioItemHome": "Hemapp",
				"descriptionHome": "Öppna hjälpartiklar på Butikportalens hemsida",
				"radioItemCurrent": "Aktuell sida",
				"descriptionCurrent": "Öppna hjälpartiklar på aktuell sida"
			},
			"marketSelector": {
				"whichMarketToContact": "Vilken marknad vill du kontakta?"
			}
		},
		"orientation": {
			"title": "Lär dig hur man..."
		},
		"helpbot": {
			"questions": {
				"whatDoYouNeedHelpWith": "Hej! Vad behöver du hjälp med idag?",
				"selectSupportChannel": "Välj en kanal för att kontakta vår support.",
				"thatHelped": "Tack för din positiva feedback. Detta hjälper oss att erbjuda dig mer relevant hjälp i framtiden. 😀"
			},
			"orders": {
				"orderManagement": {
					"title": "Orderhantering",
					"createOrderPos": {
						"question": "Hur skapar jag en order genom Point of Sales?",
						"answer": "1. Öppna Point of Sales 2. Ange artikelnamnet, produktens pris och mängd. 3. Dela den genererade betalningslänken med din kund vis SMS, e-post eller genom att visa kundskanningen eller QR-koden på kassaskärmen. 4. Kunden kan nu slutföra köpet från sin enhet."
					},
					"extendExpiryReservation": {
						"question": "Hur förlänger jag utgångsdatumet på en bokning?",
						"answer": "1. Gå till orderhantering och välj en eller flera ordrar du vill förlänga utgångsdatumet för. 2. Klicka på förläng utgångsdatum. 3. Bekräfta ditt val.\nOrdrar förlängs enligt reservationstidsinställningarna för din butik (vanligen 28 dagar). Om en order har gått ut kan den inte längre förlängas.\""
					},
					"howToCaptureOrders": {
						"question": "Hur aktiverar jag ordrar?",
						"partially": {
							"question": "Jag vill delvis aktivera en order",
							"answer": "Om det endast är möjligt att leverera en del av en kundorder kan du delvis aktivera en order genom orderrader eller summa. Aktivera orderrader 1. Gå till ordern i orderhanteraren och välj de produkter du för närvarande har i lager från ordersidan. Kom ihåg att redigera orderraderna för att justera mängderna om det behövs. 2. Klicka för att aktivera. Summa att aktivera: 1. Gå till ordern i orderhanteraren. Du kan aktivera en order genom att klicka på \"Aktivera alla\"-knappen. Här anger du den önskade summan. 2. Klicka för att aktivera.\nKom ihåg att avsluta ordern när det är möjligt, antingen genom att aktivera återstående produkter/belopp när det är möjligt att skicka dem – eller genom att avbryta den resterande ordern om du inte kan skicka varorna."
						},
						"fully": {
							"question": "Jag vill aktivera ordrar fullständigt",
							"answer": "1. Gå till orderhanteraren och klicka på Klarna-referensnumret för att öppna ordern. 2. Aktivera hela ordern genom ett av följande steg: – Klicka på \"För att aktivera\"-knappen – Välj orderrader och klicka på \"För att aktivera\". – Klicka på \"För att aktivera\" och ange hela beloppet för beställningen. 3. När du aktiverar ordern kan du ladda ner följesedeln för att inkludera den i din försändelse.\nSe till att aktivera ordrar innan deras utgångsdatum (vanligtvis 28 dagar från skapandet av ordern). Du kan aktivera flera ordrar genom att välja dem i orderlistan och klicka på \"Aktivera fullständigt\" om du vill snabba på det."
						}
					},
					"canIUpdateCustomerInfo": {
						"question": "Kan jag uppdatera kundinformationen?",
						"answer": "Du kan aldrig ändra mottagaren för en order. Men det är möjligt att ändra leverans- och faktureringsadressen för reserverade eller delvis aktiverade ordrar. Men om Klarna avvisar den nya adressen på grund av att den inte är ansluten till kunden kanske leveransadressen fortfarande kan ändras."
					},
					"extendDueDate": {
						"question": "Hur förlänger jag förfallodatumet på en order?",
						"answer": "Du kan förlänga förfallodatumet för en order genom att klicka på \"Förläng betaltiden\" på en aktiverad order. Att förlänga en orders förfallodatum innebär en avgift som kommer att specificeras innan du slutför åtgärden."
					},
					"refundOrder": {
						"question": "Hur återbetalar jag en order?",
						"answer": "1. Gå till orderhanteraren och hitta ordern du vill få återbetalad. 2. Välj återbetalningsmetoden: antingen valda produkter att återbetala och klicka sedan \"återbetala\" eller klicka \"återbetala\" först för att ange beloppet du vill återbetala. 3. Det återbetalade beloppet kommer att dras från din nästa utbetalning och kunden kommer att få den inom fem arbetsdagar.\nDetta gäller endast aktiverade ordrar. För reserverade ordrar, avbryt dem om du inte kommer att skicka några produkter. Om endast delar av ordern aktiverades och skickades, släpp den reserverade delen."
					},
					"cancelOrder": {
						"question": "Hur avbryter jag en order?",
						"answer": "1. Gå till orderhanteraren och hitta ordern du vill avbryta. 2. Klicka på \"Avbryt order\". Denna åtgärd informerar även din kund om att ordern inte kommer att skickas.\nDu kan återskapa annullerade ordrar om du behöver det. Du kan inte annullera aktiverade ordrar men du kan återbetala dem."
					},
					"unableToTakeActions": {
						"question": "Varför kan jag inte utföra åtgärder på en order?",
						"directPartner": {
							"question": "Jag har en överenskommelse direkt med Klarna",
							"answer": "Användartillstånd fastställer vad användare kan göra på partnerportalen. Det finns två olika typer av användarbehörigheter: Admin – användare med full åtkomst, till exempel kan en administratör få åtkomst till och redigera alla funktioner i partnerportalen. Avsnittsspecifik – användare som inte är administratörer har åtkomst till en eller flera specifika avsnitt på partnerportalen.\nDetta kan vara anledningen till att du inte kan utföra åtgärder för ordrar. Det finns olika åtkomstroller relaterade till orderhanteraren. tala med din administratör för att fastställa vilken åtkomstroll som är mest lämplig för de åtgärder du behöver utföra."
						},
						"distributionPartner": {
							"question": "Jag har en överenskommelse direkt med Klarna",
							"answer": "Eventuella åtgärder som du skulle vilja vidta för en order kan endas utföras genom plattformen som tillhandahålls av distributionspartnern"
						}
					}
				},
				"orderModification": "Ändringar i beställningar och problem",
				"orderProcessing": "Orderbehandling",
				"refundIssues": {
					"title": "Återbetalningsproblem",
					"refundedOrderWhenCustomerFunds": {
						"question": "Jag har återbetalat en beställning, när får kunden sina pengar?",
						"answer": "Kunden ska motta full återbetalning inom fem arbetsdagar från att beställningen återbetalades. Tiden kan variera beroende på vilken betalmetod kunden har valt. Om kunden har frågor om detta, hänvisa dem till Klarnas kundsupport."
					}
				},
				"reservations": {
					"title": "Reservationer",
					"whyUnableToExtendReservation": {
						"question": "Varför kan jag inte förlänga reservationstid på en beställning?",
						"answer": "Det finns omständigheter då det inte går att förlänga förfallotiden på en beställning:\n– Om beställningen redan har förfallit måste du skapa den på nytt. För att göra detta går du till beställningssidan, klickar på ”Kopiera”” och väljer ”Skapa ny beställning”.\n– Om beställningen betalats med kort måste kund lägga en ny order då det inte går att förlänga förfallotiden för den typen av beställning.\n"
					}
				},
				"paymentsForMyOrders": {
					"title": "Betalningar för mina ordrar",
					"whenWillIGetPaid": {
						"question": "När får jag betalt för en beställning?",
						"answer": "För att få betalt för en beställning måste du först aktivera den. Den utbetalda summan, efter avdragna avgifter, returer och andra kostnader, skickas till din bank enligt ditt utbetalningsschema. Du kan se inställningar för ditt utbetalningsschema under avsnittet Avräkningar."
					},
					"refundOrderReceivedPayment": {
						"question": "Vad händer om jag godkänner återbetalning för en beställning som jag redan fått pengar för?",
						"answer": "Summan du återbetalar kommer att dras från nästa schemalagda utbetalning."
					},
					"referenceNotShowUp": {
						"question": "Ordernummer eller referensnummer kommer inte att synas i rapporten.",
						"answer": "Om du inte kan hitta betalning för en specifik beställning:\n– Bekräfta att order- eller referensnummer stämmer.\n– Se till att beställningen är aktiverad.\n– Kontrollera om beställningen ligger inom rapportens aktiveringsperiod.\n– Observera att beställningar som avbrutits innan avräkning inte kommer att synas i rapporten.\n"
					},
					"gotPaidSpecificOrder": {
						"question": "Hur ser jag om jag har fått betalt för en specifik beställning?",
						"answer": "Söka efter beställningen i beställningshanteraren på partnerportalen för att kontrollera att beställnings- eller referensnumret stämmer. Nedanför referensnumret kan du kontrollera om beställningen är aktiverad. Om den är aktiverad kommer statusen stå som väntande eller utbetald. När det gäller beställningar som betalats ut, klicka på ”Se avräkningar” för att visa motsvarande rapport."
					}
				}
			},
			"disputes": {
				"disputeManagement": "Tvisthantering",
				"contactInfoAndNotifications": "Kontaktinformation och notisinställningar",
				"timingAndDeadlines": "Tidsfrist för tvister",
				"resolutionProcess": "Tvistlösningsprocess",
				"merchantDisputesApp": {
					"title": "Appen för tvister",
					"respondToDispute": {
						"question": "Hur vet jag om jag måste svara på en tvistförfrågan?",
						"answer": "Se Att göra-sidan i partnerportalen för att se om väntande tvistförfrågningar kräver svar."
					},
					"overviewDisputesCustomer": {
						"question": "Var kan jag se en översikt över alla tvistförfrågningar från kunder?",
						"answer": "Du kan se inkomna tvistförfrågningar under avdelningen för tvister, under ”Alla tvister”, direkt då en tvist inkommer.\n\nObservera att tvister syns under ”Alla tvister” i upp till 180 dagar efter att de avslutats.\n"
					},
					"overviewDisputesResponse": {
						"question": "Var kan jag se en överblick av alla tvister som kräver hantering?",
						"answer": "Du hittar alla tvister som kräver hantering under sektionen Tvister på sidan ”Pågående tvister ”under ”Hantering krävs”."
					},
					"updateContactInformation": {
						"question": "Hur kan jag uppdatera min kontaktinformation för e-postpåminnelser angående tvister?",
						"answer": "1. Gå till sidan för tvister\n2. Klicka på sidan för inställningar för e-post och tvister\n3. Uppdatera din kontaktinformation under fliken E-post och notiser.\n\nObservera att du kan ange olika kontaktinformation för ”tvister,” ”obehöriga köp” och ”högriskbeställningar”. Dessutom kan du ange hur frekventa notiser du vill ha under fliken ”Notiser”.\n"
					},
					"updateCustomerServiceOnKlarnaApp": {
						"question": "Hur uppdaterar jag min kundservice-information i Klarna-appen?",
						"answer": "1. Gå till inställningar för betalningar\n2. Klicka på ”Kundservice-information”\n3. Välj ”Marknad” (om du har marknadsspecifik support)\n\nDu kan ange kontaktinformation för kundservice samt om returpolicyn ska synas i Klarna-appen för en smidigare kundupplevelse.\n\nObservera att du måste ha full tillgång till partnerportalen för att se denna information.\n"
					},
					"improveDisputesPerformance": {
						"question": "Hur kan jag nå bättre resultat för min tvisthantering?",
						"answer": "För att få tips och trick om hur du förbättrar resultaten för dina tvister, gå till ”Guiden för förbättring av returer och tvister”. Du kommer åt den via sidan ”Statistik” genom att klicka på knappen ”Få insikter” högts upp till höger på sidan.\n\nObservera att du måste ha full tillgång till partnerportalen för att se denna information.\n"
					},
					"overviewDisputesPerformance": {
						"question": "Var kan jag se en översikt av mina tvistresultat?",
						"answer": "För att se detaljerad statistik om tvister, gå till statistiksidan i tvistappen. Här ser du en månatlig översikt över tvister, resultat, kostnader och förluster relaterat till tvister.\n\nObservera att du måste ha full tillgång till partnerportalen för att se denna information.\n"
					}
				},
				"disputesLifecycle": {
					"title": "Tvisternas livscykel",
					"whenCanSeeDisputes": {
						"question": "När kan jag se tvister som inletts av kunder?",
						"answer": "Du ser samtliga tvister som inletts i tvistappen under sidan ”Alla tvister” direkt efter att de inletts."
					},
					"howLongToResolve": {
						"question": "Hur mycket tid har jag att lösa en tvist direkt med kund?",
						"answer": "Du har 21 dagar på dig från att tvisten inletts att lösa den direkt med kund. Om du och kunden inte har kommit överens inom 21 dagar från inledd tvist kommer Klarna att komma in för att stötta båda parter i lösningsprocessen."
					},
					"howMuchTimeToRefund": {
						"question": "Hur mycket tid har jag för att återbetala returer?",
						"answer": "Du har 21 dagar från returdatum på dig att behandla en återbetalning innan Klarna kommer in för att stötta båda parter i lösningsprocessen. Med detta sagt är det alltid bäst att återbetala en retur så fort de inkommer."
					},
					"howToResolve": {
						"question": "Hur löser jag tvister och returer?",
						"answer": "Det finns flera sätt att lösa en tvist eller hantera en retur.\n\n– Hantera retur eller återbetalning så fort den inkommer/hitta en lösning.\n– Ange en spärr för återbetalning under ”Inställningar för e-post och tvister”\n– Var proaktiv genom att hålla koll på ”Alla tvister”\n– Acceptera förlustkostnader\n"
					},
					"whenToRespond": {
						"question": "När behöver jag aktivt hantera/besvara tvister?",
						"answer": "Om du och kunden inte kan komma överens själva inom angiven tidsram (21 dagar) kommer Klarna att komma in för att stötta båda parter i lösningsprocessen. Om tvisten bedöms rimlig kommer Klarnas support-team att kontakta dig genom tvistappen med en förfrågan om mer information.\n\nVid denna tidpunkt kommer vi be dig att förklara/besvara en tvist.\n"
					},
					"howMuchTimeToRespond": {
						"question": "Hur mycket tid har jag att besvara en tvistförfrågan?",
						"answer": "Din deadline för första förfrågan är 14 dagar oavsett typ av tvist, med undantag för obehöriga köp (7 dagar). För varje ny förfrågan* som skickas till dig (upp till max fem förfrågningar) har du sju dagar på dig att svara. Om du behöver tillägga mer information i ditt svar har du, från det att du skickar in, 60 minuter på dig att göra ändringar eller tillägg. När denna deadline har passerat kan ni inte vidta ytterligare åtgärder som försvar i ärendet.\n\n*Särskilda villkor kan gälla beroende på överenskommelse med partner.\n"
					},
					"howLongToSeeDispute": {
						"question": "Hur länge ligger en tvist kvar i tvistappen från att den lösts?",
						"answer": "En tvist ligger kvar under ”Alla tvister” i upp till 180 dagar från att den avslutats."
					},
					"whenToGetCharged": {
						"question": "När tas tvistavgiften ut?",
						"answer": "Du åläggs en avgift för tvist om:\n– Tvister anses åligga dig.\n– Du inte besvarar uppkommen tvist före deadlinen.\n– Du utfärdar en återbetalning efter att Klarnas klivit in som stöd för i lösningsprocessen.\n\nAvgiften läggs på retroaktivt efter att tvistens utgång fastställts. Du åläggs ingen avgift om tvistens löses till din fördel, eller om den avbryts eller avvisas.\n"
					}
				},
				"disputesHandling": {
					"title": "Tvisthantering",
					"whatIsDispute": {
						"question": "Vilken information får jag i samband med tvistförfrågan?",
						"answer": "En tvistförfrågan innehåller:\n\n– Skäl: Kategori, d.v.s retur, varor ej mottagna.\n– Berörd handlare: Endast om flera butiks-ID (MIDs) är registrerade hos Klarna.\n– Datum för tvist: När kunden inledde tvisten.\n– Deadline: Den deadline innan vilken du måste bemöta ärendet.\n– Produkter under tvist: Produkt/produkter tvisten gäller.\n– Orderdetaljer: Inkluderar handlarreferens ett och två, orderdatum, summa tvisten avser.\n"
					},
					"howManyTimesDefend": {
						"question": "Hur många gånger kan jag besvara samma tvistförfrågan?",
						"answer": "Du har max (5) svar att på dig att försvara din tvist, men oftast räcker det med ett eller två."
					},
					"doIReceiveNotification": {
						"question": "Får jag notiser om när jag behöver svara på en tvist?",
						"answer": "Om du har en pågående tvist kommer denna dyka upp på flera ställen:\n\n– Under ”Att göra” i partnerportalen.\n– Som en notis på tvistavsnittet på sidan ”Öppna tvister”, vilken indikerar antalet tvister som behöver bemötas.\n\nDu kan välja att få e-postutskick för varje tvist genom att ändra dina inställningar under ”Inställningar för e-post och tvister” till ”Notis via e-post för varje tvist”.\n"
					},
					"howToAvoidDisputesFees": {
						"question": "Hur kan jag undvika avgifter för tvister?",
						"answer": "För att undvika avgifter för tvister:\n– Hantera returer och återbetalningar omgående.\n– Ange en tröskel för återbetalning under ”Inställningar för e-post och tvister”.\n– Håll regelbunden koll på ”Alla tvister”.\n– Besvara tvister med relevant information.\n– Svara innan deadline har gått ut.\n"
					}
				}
			},
			"settlements": {
				"payoutNotFound": {
					"title": "Utbetalning för specifik beställning hittades inte",
					"orderNumberNotFound": {
						"question": "Beställningsnummer eller referensnummer syns inte i rapporten",
						"answer": "Om du inte kan hitta betalning för en specifik beställning:\n– Bekräfta att order- eller referensnummer stämmer.\n– Se till att beställningen är aktiverad.\n– Kontrollera om beställningen ligger inom rapportens aktiveringsperiod.\n– Observera att beställningar som avbrutits innan avräkning inte kommer att synas i rapporten.\n"
					},
					"checkIfPaidForOrder": {
						"question": "Hur ser jag om jag har fått betalt för en specifik beställning?",
						"answer": "Söka efter beställningen i beställningshanteraren på partnerportalen för att kontrollera att beställnings- eller referensnumret stämmer. Nedanför referensnumret kan du kontrollera om beställningen är aktiverad. Om den är aktiverad kommer statusen stå som väntande eller utbetald. När det gäller beställningar som betalats ut, klicka på ”Se avräkningar” för att visa motsvarande rapport."
					}
				},
				"payouts": {
					"title": "Utbetalningar",
					"whenIsNext": {
						"question": "När är min nästa utbetalning?",
						"answer": null
					},
					"whyCalculationNotMatch": {
						"question": "Varför överensstämmer inte mina egna beräkningar med utbetalningen?",
						"answer": "Enligt handlarkontraktet dras avgifter såsom transaktioner och förseningsavgifter av. Det kan innebära att dina beräkningar inte överensstämmer med Klarnas avräkningar. För att se detaljerad avräkning rekommenderar vi att öppna PDF-versionen av avräkningsrapporten."
					},
					"whenGetPaid": {
						"question": "När får jag betalt?",
						"answer": null
					},
					"missingPayout": {
						"question": "Jag missar min utbetalning",
						"answer": "Innan du kontaktar vår support. Se om missad utbetalning kan bero på följande:\n\n– Klarna skickar utbetalningen samma dag som rapporten genereras, det kan dock ta ytterligare ett par dagar innan summan syns på ditt konto.\n– Du har inte aktiverat några beställningar under aktiveringsperioden.\n– Du har nyligen uppdaterat dina bankkontouppgifter hos Klarna vilket kan ha skickat betalning till tidigare angett bankkonto. Detta kan ske om ändringen inte hanterats innan betalningen gått iväg.\n– Beställningsnumret eller referensnumret du söker efter är inte korrekt, dubbelkolla så att dessa överensstämmer.\n– Dina betalningar hålls inne av Klarna. Du ska ha blivit informerad om en spärr i separat e-post, dessa utskick går vanligtvis till en administratör\n"
					},
					"howIsPayoutCalculated": {
						"question": "Hur beräknas min utbetalning?",
						"answer": "Utbetalningen beräknas baserat på aktiverade beställningar inom aktiveringsperioden minus avgifter, returer och andra kostnader. En avräkningsrapport ger detaljerad information om dessa summor.\nOm antalet återbetalningar är fler än antalet försäljningar under en aktiveringsperiod kommer överskjutande summa att dras från nästa utbetalning.\n"
					}
				},
				"howPayoutsWork": {
					"question": "Hur fungerar utbetalningar?",
					"answer": "Under avsnittet Avräkningar, ser du en överblick över hur utbetalningar fungerar, inklusive betalningsscheman, aktiveringsperioder och utbetalningsdatum. Du kan ladda ner transaktionsrapporter för varje utbetalning som PDF eller CSV och visa eller ladda ner detaljer för specifika betalningar genom att klicka på deras betalningsreferens."
				},
				"payoutSchedule": {
					"title": "Utbetalningsschema",
					"whyDelay": {
						"question": "Varför finns en utbetalningsfördröjning?",
						"answer": "Det huvudsakliga skälet är att undvika att betalning för fakturor skickas fram och tillbaka mellan dig och Klarna, samt för att täcka situationer då du skulle återbetala en aktiverad beställning."
					},
					"changePayoutDelay": {
						"question": "Jag vill ändra min utbetalningsfördröjning",
						"answer": "Vi utför även interna kontroller för att säkerställa att villkoren efterföljs. Standardvillkoren innebär:\n– Du måste vara fullt onboardad med Klarna\n– Ditt första orderdatum överstiger 100 dagar\n– Din verksamhet måste vara äldre än sex månader.\n\nKlarna efterfrågar även information från administratör angående…\n– Ny önskad betalningsfördröjning och skäl till denna betalningsfördröjning är nödvändig\n– Vilken butiks-ID (merchant ID, MID) ändringen gäller\nYtterligare kriterier kan gälla, med beslut fattade på individuell basis.\n"
					}
				},
				"generatingReports": {
					"title": "Skapa rapporter",
					"howToSeeReports": {
						"question": "Hur kan jag se rapporter för specifika perioder?",
						"answer": "Du kan anpassa dina rapporter så att de täcker specifika perioder genom att använda ”Anpassade rapporter” under sidan för Avräkningar."
					},
					"convertCsvToExcel2": {
						"answer": "Följ dessa steg\n1. Öppna Excel, klicka på ”Fil” > ”Ny arbetsbok”.\n"
					},
					"convertCsvToExcel": {
						"question": "Hur konverterar jag en CSV-rapport till ett Excel-kalkylblad?",
						"answer": "1. Öppna Excel, klicka på ”Fil” > ”Ny arbetsbok”.\n2. Gå till fliken ”Data” och välj ”Från Text”.\n3. Välj önskad .CVS-fil och klicka på ”Hämta data”.\n4. Under ”Importera text” väljer du ”Avgränsare” och klickar sedan på ”Fortsätt”.\n5. Under ”Avgränsare”, välj ”Semikolon” och ställ in ”Textkvalificerare” på ”ingen”. Klicka på ”Slutför”.\n6. Under dialogrutan ”Importera data” klickar du på ”OK” för att fylla på data med början i cell A1.\n\nObs: CSV-rapporter i Butiksportalen använder semikolon (;) som avgränsare. Se ditt kalkylprogram för att lära dig mer om hur du kan anpassa standardavgränsaren.\n"
					}
				},
				"payoutAndFeeCalculation": {
					"title": "Beräkning av utbetalning och avgifter",
					"howAreFeesCalculated": {
						"question": "Hur beräknas avgifter?",
						"answer": "Avgifter som dras av från Klarna utbetalningar anges på ditt handlarkontrakt, och tilläggsavgifter såsom sena returer anges under avsnittet ”Avgifter” i dina utbetalnings-/avräkningsrapporter.\n\nAnpassa avgiften i dina rapporter:\n1. Gå till Avräkningsrapporter > Konfigurera rapporter > Lägg till ny konfigurering.\n2. För CSV-rapporter, välj ”Detaljerad transaktionstyp” i transaktionslistan.\n3. För PDF-rapporter, välj ”Inkludera orderdetaljer” under inställningar för layout och innehåll.\n\nSe att anpassa inställningar för varje butiks-ID och kanal (SFTP, butiksportal, API) separat.\n"
					},
					"howIsPayoutCalculated": {
						"question": "Hur beräknas min utbetalning?",
						"answer": "Utbetalningen beräknas baserat på aktiverade beställningar inom aktiveringsperioden minus avgifter, returer och andra kostnader. En avräkningsrapport ger detaljerad information om dessa summor.\n\nOm antalet återbetalningar är fler än antalet försäljningar under en aktiveringsperiod kommer överskjutande summa att dras från nästa utbetalning.\n"
					}
				}
			},
			"options": {
				"payout": "Utbetalning ",
				"orders": "Beställningar",
				"settlements": "Avräkningar",
				"disputes": "Tvister",
				"otherIssue": "Övriga frågor",
				"yes": "Ja",
				"no": "Nej",
				"partially": "Delvis",
				"iNeedMoreHelp": "Jag behöver mer hjälp",
				"thatHelped": "Det hjälpte",
				"contactSupportChannel": "Kontakta supportkanaler"
			},
			"button": {
				"label": "Få hjälp"
			},
			"contact": {
				"chat": "Chatta med support",
				"ticket": "Skicka in förfrågan",
				"times": {
					"minutes": "Väntetid: {time} minut/minuter",
					"hours": "Väntetid: {time} timme/timmar",
					"days": "Väntetid: {time} dag/dagar"
				}
			}
		}
	}
};