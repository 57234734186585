module.exports = {
	"no": {
		"core": {
			"alert": {
				"message": "Noe gikk galt. Vennligst prøv igjen.",
				"correlationIdLabel": "Korrelasjons-ID:"
			},
			"header": {
				"title": "Merchant Portal"
			},
			"usermenu": {
				"language": "Språk",
				"country": "Land",
				"timezone": "Tidssone",
				"logout": "Logg ut",
				"profile": "Profil",
				"changeProfile": "Endre",
				"tooltip": "Din profil"
			},
			"appsmenu": {
				"defaultTooltip": "Dine apper",
				"initialTooltip": "Finn dine apper her.",
				"newAppsTooltip": "Vi har nye apper til deg!"
			},
			"two-factor-opt-in": {
				"title": "Sett opp tofaktorautentisering",
				"intro-paragraph": "Du har ikke aktivert tofaktorautentisering (2FA) på forhandlerportal-kontoen din. Fra og med <b>16. januar 2023</b>, vil 2FA være obligatorisk for alle brukere for å få tilgang til forhandlerportalen. Du kan lese mer om viktigheten av 2FA, hvordan du setter det opp, og svar på vanlige spørsmål <faqLink>her</faqLink>.",
				"help-link-text": "Klikk her",
				"help-text": " for å lære mer om hvordan du setter den opp og hvordan den beskytter deg.",
				"warning-text": "Forsikre deg om at du har smarttelefonen og tilgang til internett før du fortsetter",
				"continue-button": "Start oppsettet"
			},
			"kuasPrompter": {
				"title": "Du har valgt",
				"content": "{count} MID-er",
				"contentTooltip": "Klikk for å kopiere butikk-ID til utklippstavle",
				"button": "Endre valgte MID-er"
			},
			"notification": {
				"title": "Kunngjøring",
				"content": {
					"uptodate": "Du er oppdatert!"
				}
			},
			"languages": {
				"cs": "Tsjekkisk",
				"da": "Dansk",
				"de": "Tysk",
				"el": "Gresk",
				"en": "Engelsk",
				"en_GB": "Engelsk (GB)",
				"en_US": "Engelsk (US)",
				"es": "Spansk",
				"fi": "Finsk",
				"fr": "Fransk",
				"it": "Italiensk",
				"nl": "Nederlandsk",
				"no": "Norsk",
				"pl": "Polsk",
				"pt": "Portugisisk",
				"sv": "Svensk"
			},
			"countries": {
				"at": "Østerrike",
				"au": "Australia",
				"be": "Belgia",
				"ca": "Canada",
				"ch": "Sveits",
				"cz": "Tsjekkia",
				"de": "Tyskland",
				"dk": "Danmark",
				"ee": "Estland",
				"es": "Spania",
				"fi": "Finland",
				"fr": "Frankrike",
				"gb": "Storbritannia",
				"gr": "Hellas",
				"ie": "Irland",
				"it": "Italia",
				"mx": "Mexico",
				"nl": "Nederland",
				"no": "Norge",
				"se": "Sverige",
				"us": "USA",
				"nz": "New Zealand",
				"pl": "Polen",
				"pt": "Portugal",
				"ro": "Romania"
			},
			"selectLanguage": {
				"title": "Velg språk",
				"paragraph": "Dette vil være ditt standardspråk",
				"submit": "OK"
			},
			"selectCountry": {
				"title": "Velg land",
				"paragraph": "Dette vil være ditt standardland.",
				"submit": "OK"
			},
			"updateProfile": {
				"title": "Oppdater profil",
				"submit": "OK",
				"emailLabel": "E-postadresse",
				"firstNameLabel": "Fornavn",
				"lastNameLabel": "Etternavn",
				"resetPasswordLabel": "Passord",
				"resetPasswordSelectedValue": "Tilbakestill",
				"resetPasswordSuccessLabel": "E-post om tilbakestilling er sent.",
				"resetPasswordErrorLabel": "Feil ved sending av e-post. Prøv igjen senere.",
				"error": "Detaljoppdatering feilet",
				"changeCookiePolicyLabel": "Retningslinjer for informasjonskapsler",
				"changeCookiePolicySelectedValue": "Endre",
				"securitySectionTitle": "Sikkerhet",
				"preferencesSectionTitle": "Preferanser",
				"mfaLabel": "Tofaktorautentisering",
				"mfaDescription": "Krever innlogging med et tidsbasert engangspassord. Vi anbefaler å aktivere det.",
				"twoFactor": {
					"reset": "Endre din 2FA",
					"isEnabled": "Aktivert",
					"isDisabled": "Deaktivert",
					"title": "Tofaktorautentisering (2FA)",
					"description": "Dette lar brukere logge inn med den ekstra sikkerheten til et tidsbasert engangspassord.",
					"tooltip": "Pass på at du minst har tillatelsen {permission} til {app} appen for hver butikk brukeren har tilgang til for å kunne veksle innstillingen for tofaktorautentisering.",
					"confirmationDialog": {
						"closeButton": "Lukk",
						"confirmationButton": "Ja, fortsett",
						"cancelButton": "Avbryt",
						"resetSuccess": {
							"title": "Tilbakestilling av 2FA-enhet gjennomført.",
							"description": "Neste gang brukeren logger inn må de sette opp en ny enhet for 2FA."
						},
						"resetConfirmation": {
							"title": "Er du sikker på at du vil tilbakestille denne brukerens 2FA-enhet?",
							"description": "Ved å fortsette tilbakestiller du enheten som brukes til å generere 2FA-koder for denne brukeren. Neste gang brukeren logger inn må de sette opp en ny enhet for 2FA."
						}
					}
				}
			},
			"footer": {
				"copyright": {
					"eu": "Copyright © 2005-{currentYear} Klarna Bank AB (publ). Alle rettigheter reservert.",
					"oc": "Copyright © 2005-{currentYear} Klarna Australia Pty Ltd, ABN # 82 635 912 579",
					"ap": "Copyright © 2005-{currentYear} Klarna Australia Pty Ltd, ABN # 82 635 912 579",
					"us": "Copyright © 2005-{currentYear} Klarna Inc. NMLS #1353190, 629 N. High Street, Third Floor, Columbus, OH 43215"
				},
				"terms": "Betingelser og vilkår"
			},
			"accessDenied": {
				"title": "Du trenger mer tilgang for denne siden.",
				"message": "Kontakt din administrator eller Klarna dersom du har spørsmål."
			},
			"midSelector": {
				"label": "Butikk"
			},
			"storeSelector": {
				"label": "Butikk"
			},
			"storesSelector": {
				"closeText": "Ferdig",
				"closeTextWithSelection": "Bruk # valg",
				"deselectAllOptionText": "Fjern markeringen",
				"emptyStateText": "Ingen butikker funnet",
				"label": "Butikker",
				"searchFieldLabel": "Søk etter butikk",
				"selectAllOptionText": "Velg alle butikker",
				"selectedAllText": "Alle butikker",
				"selectedText": "# butikker"
			},
			"pagination": {
				"ofLabel": "av"
			},
			"singleDatePicker": {
				"selectDate": "Velg dato"
			},
			"dateRangePicker": {
				"startLabel": "Startdato ",
				"endLabel": "Sluttdato",
				"months": {
					"january": "Januar",
					"february": "Februar",
					"march": "Mars",
					"april": "April",
					"may": "Mai",
					"june": "Juni",
					"july": "Juli",
					"august": "August",
					"september": "September",
					"october": "Oktober",
					"november": "November",
					"december": "Desember"
				},
				"weekDaysShort": {
					"monday": "M",
					"tuesday": "T",
					"wednesday": "O",
					"thursday": "T",
					"friday": "F",
					"saturday": "S",
					"sunday": "S"
				}
			},
			"regionSelector": {
				"noStores": "Ingen butikker tildelt",
				"andMore": "og mer",
				"regions": {
					"eu": "Europa ",
					"us": "Nord-Amerika",
					"ap": "Asia - Stillehavsområdet"
				}
			},
			"regionSelectorBar": {
				"modal": {
					"title": "Endre region",
					"description": "Velg en region med tilhørende butikker for å vise relevante data.",
					"buttonText": "Bekreft"
				},
				"selectedRegion": "Valgt region",
				"changeButton": "Endre"
			},
			"appIcon": {
				"newLabel": "NYHET"
			},
			"appCategory": {
				"dayToDay": "For det daglige",
				"nko": "Klarna på nett",
				"tools": "Verktøy",
				"preferences": "Preferanser"
			},
			"apps": {
				"account-information-fe": {
					"title": "Kontoinformasjon",
					"description": "Finn bedrifts- og bankinformasjonen din og avtalene du signerte."
				},
				"home-fe": {
					"title": "Hjem betalinger"
				},
				"boosters-home-fe": {
					"title": "Kom i gang"
				},
				"marketing-home-fe": {
					"title": "Hjem markedsføring"
				},
				"nko-app": {
					"title": "Klarna apper på nett"
				},
				"orders-fe": {
					"title": "Ordreadministrator",
					"description": "Søk opp og administrer ordre fra Klarna.",
					"nkoLinkText": "Få tilgang til tidligere ordre"
				},
				"nko-orders": {
					"title": "Ordre (KO)",
					"description": "Søk etter og administrer bestillingene dine gjennom Klarna Online."
				},
				"settlements-fe": {
					"title": "Oppgjør",
					"description": "Se transaksjonene dine og generer rapporter for avstemming.",
					"nkoLinkText": "Få tilgang til tidligere rapporter"
				},
				"nko-settlements": {
					"title": "Oppgjør (KO)",
					"description": "Finn rapporter om bestillinger og utbetalinger fra Klarna Online."
				},
				"logs-app-fe": {
					"title": "Utvikler logger",
					"description": "Søk i API-logger for å feilsøke integrasjonsproblemer."
				},
				"users-fe": {
					"title": "Brukeradministrator",
					"description": "Inviter nye brukere og administrer eksisterende brukere i portalen."
				},
				"settings-fe": {
					"title": "Betalingsinnstillinger",
					"description": ""
				},
				"nko-settings": {
					"title": "Innstillinger (KO)",
					"description": "Administrer butikkinnstillingene, rapportene og kontaktinformasjonene."
				},
				"seller-fe": {
					"title": "I butikk",
					"description": "Opprett nye ordrer med Klarnas løsning i butikk."
				},
				"products-fe": {
					"title": "Onboarding",
					"description": "Sett opp kontoen din for å aktivere betalinger."
				},
				"upstream-fe": {
					"title": "Meldinger på siden",
					"description": "Selg mer med skreddersydde og personlige finansieringstilbud."
				},
				"lending-fe": {
					"title": "Business Financing",
					"description": "Bedriftsfinansiering gjort enkelt"
				},
				"branding-fe": {
					"title": "Merkevaresjef",
					"description": "Ta kontroll over hvordan merkevaren din presenteres innad i Klarna."
				},
				"disputes-fe": {
					"title": "Tvister",
					"description": "Håndter dine tvister."
				},
				"logistics-fe": {
					"title": "Fraktintegrasjoner",
					"description": "Administrer all logistikk på ett sted."
				},
				"developers": {
					"title": "Utviklere"
				},
				"klarna-docs": {
					"title": "Klarna Docs"
				},
				"support": {
					"title": "Support",
					"description": "Sjekk og administrer supportforespørslene dine."
				},
				"integration-guides-fe": {
					"title": "Integrasjonsveiledninger",
					"description": ""
				},
				"klarna-search-b2b-portal": {
					"title": "Search & Compare",
					"description": "Kom i gang med Klarnas kraftige søkemotor og prissammenligning. Få tilgang til statistikk og verktøy utviklet for å forbedre din synlighet."
				},
				"siwk-fe": {
					"title": "Logg inn med Klarna",
					"description": "Administrer innloggingen med Klarna-integrering."
				},
				"kec-fe": {
					"title": "Express Checkout",
					"description": "Administrer din Express Checkout-integrering."
				},
				"partner-self-service": {
					"title": "Butikkadministrator",
					"description": "Administrer din Klarna-butikk.",
					"nkoLinkText": "Se og administrer innstillinger for butikken din"
				}
			},
			"ui": {
				"tabs": {
					"home": "Betalinger",
					"growth": "Growth Marketing",
					"boosters": "Konverteringsforsterkere",
					"badge": "Nytt"
				},
				"buttons": {
					"backButton": "Tilbake"
				}
			},
			"errorBoundary": {
				"contact": "Prøv å oppdatere siden. {br}Hvis feilen vedvarer, kontakter du <supportLink>forhandlersupport</supportLink> og oppgir ID-en {errorId}.",
				"errorDescription": "Feil-ID - {errorId}",
				"reloadButton": "Oppdater",
				"title": "Det har dessverre oppstått en feil"
			},
			"maintenance": {
				"pageTitle": "Vedlikehold",
				"title": "Merchant Portal gjennomgår for tiden planlagt vedlikehold",
				"summary": "Vi er tilbake om litt",
				"scheduledSummary": "Vi er tilbake {until}"
			},
			"errorTemplates": {
				"errorDescription": "Feil: {code}",
				"backButton": "Tilbake",
				"reloadButton": "Oppdater",
				"default": {
					"errorDescription": "Feil-ID - {errorId}",
					"title": "Det har dessverre oppstått en feil",
					"contact": "Prøv å oppdatere siden. Hvis feilen vedvarer, kontakter du <supportLink>forhandlersupport</supportLink> og oppgir feil-IDen {errorId}."
				},
				"internalServerError": {
					"title": "Oi sann, noe gikk galt",
					"message": "Av en eller annen grunn fra vår side klarte vi ikke å behandle forespørselen din. Du kan prøve igjen eller komme tilbake senere."
				},
				"notFound": {
					"title": "Her var det er ingenting!",
					"message": "Denne siden eksisterer ikke, eller du skrev inn feil adresse i nettleseren. Kontroller adressen og prøv igjen."
				},
				"forbidden": {
					"title": "Du har ikke tilgang til denne siden",
					"message": "Forhandler-IDen din har ikke tilgang til denne siden. Hvis du mener at dette er en feil, kan du kontakte fohandlersupport eller kontakte administratoren for selskapets konto."
				}
			},
			"codeSnippet": {
				"clipboardButtonLabel": "Kopier til utklippstavlen",
				"clipboardButtonLabelSuccess": "Kopiert!"
			}
		},
		"blackFriday": {
			"v1": {
				"title": "Black Friday",
				"intro": "En av de viktigste handelsdagene er rett rundt hjørnet. I løpet av Black Friday vil vi være her for å levere nærtidsstatistikk over salget ditt. I mellomtiden, la oss forberede oss på suksess!",
				"countdown": "Starter om #dd #ht #mm #ss…",
				"box1": {
					"title": "Kunder bruker 3,5 ganger mer*",
					"text": "I fjor brukte Klarna-kunder* i gjennomsnitt 3,5 ganger mer på Black Friday enn de gjorde noen annen dag i året.",
					"footnote": "*Gjennomsnitt for alle Klarnas forhandlerkategorier og -regioner."
				},
				"box2": {
					"title": "2,5 ganger større salgsvolum*",
					"text": "I fjor genererte Klarna-forhandlere 2,5 ganger så høyt salgsvolum på Black Friday enn noen annen dag i året.",
					"footnote": "*Gjennomsnitt for alle Klarnas forhandlerkategorier og -regioner."
				},
				"sectionTitle": "La oss øke Black Friday-salget ditt",
				"marketing": {
					"title": "Markedsfør virksomheten din før og på Black Friday",
					"text": "Driv flere kunder til butikken din i løpet av Black Friday med disse gratis markedsføringsressursene for sosiale medier og e-postkampanjer."
				},
				"branding": {
					"title": "Bli sett i Klarna-appen",
					"text": "12 millioner shoppere bruker Klarna-appen hver måned. Sørg for at du skinner i appen på Black Friday ved å legge til og oppdatere merkevareinformasjonen din her i Merchant Portal."
				},
				"cta": "Kom i gang",
				"close": "Lukk"
			},
			"v2": {
				"title": "Black Friday week",
				"orderVolume": "Ordrevolum",
				"orderCountTitle": "Opprettede ordrer",
				"orderCount": "{count} ordrer",
				"box": {
					"title": "Vil du øke konverteringen ytterligere?",
					"subtitle1": "On-site messaging",
					"subtitle2": "Salgsfremmende og informative meldinger.",
					"text": "On-site messaging-verktøyet hjelper deg med å øke salget og gjennomsnittlige ordreverdier med salgsfremmende meldinger. La kundene få vite hvilke betalingsalternativ de har med én gang de kommer til nettstedet ditt og gjennom hele shoppingreisen.",
					"cta": "Kom i gang"
				},
				"selector": {
					"done": "Ferdig",
					"applySelection": "Bruk # valg",
					"selectAll": "Velg alle",
					"allOptions": "Alle muligheter er valgt",
					"someOptions": "# muligheter valgt",
					"storeMultiSelectLabel": "Velg # butikker",
					"allStores": "Alle butikker"
				},
				"currencyLabel": "Velg valuta",
				"storeLabel": "Velg butikk"
			},
			"v3": {
				"title": "Black Friday week",
				"text": "Dette er omsetningen din fra en av årets viktigste shopping-uker.",
				"summaryTitle": "Oppsummering",
				"orderVolume": "Totalt volum",
				"orderCount": "Totalt antall ordrer",
				"summaryRange": "23-30 November",
				"comparisonLastWeek": "sammenlignet med samme dag, forrige uke",
				"tabs": {
					"monday": "Mandag",
					"tuesday": "Tirsdag",
					"wednesday": "Onsdag",
					"thursday": "Torsdag",
					"blackFriday": "Black Friday",
					"saturday": "Lørdag",
					"sunday": "Søndag",
					"cyberMonday": "Cyber Monday"
				}
			}
		},
		"mpSatSurvey": {
			"marble": {
				"topbarButtonLabel": "Tilbakemelding på vårt nye utseende",
				"step1": {
					"welcome": "Liker du vårt nye utseende og følelse?",
					"satisfied": "Svært fornøyd",
					"unsatisfied": "Veldig misfornøyd"
				},
				"step2": {
					"textAreaLabel": "Annet (din mening)",
					"textAreaMaxLength": "Maksimal lengde nådd",
					"submit": "Send inn",
					"content": {
						"titlePositive": "Hva liker du best?",
						"titleNegative": "Hva misliker du sterkest?",
						"tags": {
							"color": "Farge og stil",
							"navigation": "Navigasjon",
							"names": "Navn på sider",
							"structure": "Strukturen på sider"
						}
					}
				},
				"step3": {
					"title": "Takk!",
					"description": "Vi setter pris på tilbakemeldingen din."
				}
			},
			"buttonLabel": "Gi tilbakemelding",
			"errorMessage": "Det oppstod en feil med å sende tilbakemeldingen din gjennom.",
			"retry": "Prøv på nytt",
			"step1": {
				"welcome1": "Hvor fornøyd er du med",
				"welcome2": "Merchant Portal?",
				"emojiSelector": {
					"angry": "Svært misfornøyd",
					"unhappy": "Litt misfornøyd",
					"neutral": "Nøytral",
					"happy": "Fornøyd",
					"love": "Jeg elsker den"
				},
				"remindMeText": "Vil du ikke dele meningen din?",
				"remindMeLink": "Minn meg på denne undersøkelsen senere.",
				"dontShowAgainLink": "Ikke vis dette igjen."
			},
			"step2": {
				"textAreaLabel": "Annet (din mening)",
				"textAreaMaxLength": "Maksimal lengde nådd",
				"submit": "Send inn",
				"content": {
					"negative": {
						"title": "Det var leit å høre.",
						"description": "Hvilke deler av forhandlerportalen bør vi forbedre? (Velg alle som gjelder)",
						"tags": {
							"loading": "Innlastingstid",
							"menu": "Organisering av menyen",
							"mobile": "Mobilversjon",
							"filter": "Filtrering av data",
							"complexity": "Utføre handlinger på en effektiv måte"
						}
					},
					"positive": {
						"title": "Det er godt å høre!",
						"description": "Vi ønsker fremdeles å gjøre ting bedre. Hva er tingene som ville gjøre din opplevelse av Merchant Portal enda bedre?",
						"tags": {
							"views": "Egendefinerte visninger",
							"looks": "Hvordan portalen ser og føles ut",
							"tours": "Veiledede omvisninger",
							"shortcuts": "Snarveier for oppgaver"
						}
					}
				}
			},
			"step3": {
				"title": "Takk! En siste ting.",
				"content1": "Vi ønsker å invitere deg til et intervju, slik at vi kan lære mer om opplevelsen din når du bruker Merchant Portal.",
				"content2": "Har du lyst til å delta?",
				"content3": "Hvis du aksepterer, kontakter vi deg via din registrerte e-postadresse for å sette dette opp.",
				"yes": "Ja takk!",
				"no": "Nei takk"
			},
			"step4": {
				"yesTitle": "Flott! Vi tar kontakt.",
				"noTitle": "Skjønner. Takk!",
				"content1": "Takk for at du tar deg tid til å svare på disse spørsmålene.",
				"content2": "Du kan lukke dette popup-vinduet nå."
			}
		},
		"boost": {
			"integrationTypeModal": {
				"title": "Hvordan vil du konfigurere {productName}?",
				"labels": {
					"plugin": "Konfigurer med en plugin",
					"web": "Konfigurer med Web SDK"
				}
			}
		}
	}
};