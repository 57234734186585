module.exports = {
	"de": {
		"help-tool": {
			"button-text": "Hilfe",
			"support-button": "Support erhalten",
			"support-page": {
				"create-ticket": {
					"title": "Support erhalten",
					"instructions": "Reichen Sie unten eine Anfrage ein und wir melden uns innerhalb eines Werktags bei Ihnen.",
					"related-content": {
						"title": "Dies könnte Ihnen bei der Problemlösung helfen"
					},
					"fields": {
						"mid": {
							"label": "Shop wählen",
							"validation": "Shop ist erforderlich"
						},
						"phoneNumber": {
							"label": "Telefonnummer (optional)"
						},
						"topic": {
							"question": "Um was geht es bei Ihrer Frage?",
							"label": "Wählen Sie ein Thema aus",
							"validation": "Thema ist erforderlich",
							"options": {
								"orders": "Bestellungen",
								"settlements": "Abrechnungen",
								"agreements": "Vereinbarungen",
								"others": "Anderes"
							}
						},
						"subject": {
							"label": "Betreff",
							"validation": "Betreff ist erforderlich"
						},
						"description": {
							"label": "Nachricht",
							"validation": "Beschreibung ist erforderlich"
						},
						"attachment": {
							"label": "Anlagen hinzufügen",
							"button": "Hochladen",
							"info": "Das Hinzufügen von Screenshots hilft, das Problem schneller zu lösen.",
							"supportedFileTypes": "Unterstützte Dateiformate: .png, .jpg, .pdf",
							"error": "Die Gesamtgröße der Dateien darf nicht über {maxSize} MB liegen."
						}
					},
					"submit": "Antrag senden",
					"service-unavailable-error": "Ups! Ein Fehler ist aufgetreten. Bitte versuchen Sie es in ein paar Minuten erneut oder kontaktieren Sie {link}, sollte das Problem weiterhin bestehen.",
					"generic-error": "Wir konnten Ihre Anfrage nicht verarbeiten. Die Kommunikation unserer Systeme wurde unterbrochen. Bitte laden Sie die Seite neu oder versuchen Sie es später noch einmal. Sollte das Problem fortbestehen, kontaktieren Sie bitte {link}.",
					"contact-link": "Kontaktieren Sie uns"
				},
				"view-ticket": {
					"title": "Fertig!",
					"details": "Wir haben Ihre Anfrage erhalten und werden uns innerhalb eines Werktags bei Ihnen melden. Dies sind Ihre Angaben:",
					"ticket": "Anfrage Nr. {ticketId}",
					"email": "E-Mail: {email}",
					"status": "Sie können den Status in unserer Support-Seite einsehen",
					"track": "Status einsehen"
				}
			},
			"header": {
				"homeLink": "Zurücksetzen"
			},
			"home": {
				"greeting": "Hallo, {givenName}! Wie können wir Ihnen helfen?",
				"topics": "Wobei benötigen Sie Hilfe?"
			},
			"topics": {
				"expandButton": "Mehr"
			},
			"feedback": {
				"title": "Hat Ihnen das weitergeholfen?",
				"optionYes": "Ja, das hat mir weitergeholfen",
				"optionNo": "Nein, ich benötige noch Hilfe",
				"positiveFeedback": "Vielen Dank für das positive Feedback, das macht es für uns viel einfacher, Ihnen in Zukunft relevantere Artikel bieten zu können."
			},
			"freeTextFeedback": {
				"title": "Helfen Sie uns, besser zu werden",
				"placeholder": "Warum fanden Sie diesen Inhalt nicht nützlich?",
				"submitButton": "Senden",
				"feedbackMessage": "Vielen Dank für Ihr Feedback, das macht es für uns viel einfacher, Ihnen in Zukunft relevantere Artikel bieten zu können."
			},
			"relatedContent": {
				"title": "Relevante Inhalte"
			},
			"search": {
				"buttonLabel": "Suchen",
				"input": "Suche nach Artikeln ...",
				"loading": "Lädt …",
				"results": {
					"contact": "Sie finden nicht die benötigte Hilfe?",
					"empty": "Leider wurden keine Ergebnisse für Ihre Anfrage gefunden. Versuchen Sie, nach einem anderen Begriff zu suchen.",
					"singleTopicFound": "Wir haben 1 Thema gefunden",
					"multipleTopicsFound": "Wir haben {count} Themen gefunden",
					"singleArticleFound": "Wir haben 1 Artikel gefunden",
					"multipleArticlesFound": "Wir haben {count} Artikel gefunden"
				}
			},
			"contact": {
				"title": "Kontakt",
				"channelsIntro": "Wenden Sie sich an uns, sodass wir Ihnen behilflich sein können.",
				"businessDetailsTitle": "Ihre Geschäftsdaten",
				"businessDetailsName": "Name des Geschäfts",
				"businessDetailsEmail": "E-Mail",
				"businessDetailsMid": "Händler ID",
				"phone": {
					"title": "Rufen Sie uns an",
					"paragraph": "Sie können uns werktags während der Geschäftszeiten anrufen. Wenn Sie in Schweden oder im Vereinigten Königreich wohnen, können Sie uns auch am Wochenende zwischen 10:00 und 16:00 Uhr anrufen."
				},
				"email": {
					"title": "Schreiben Sie uns eine E-Mail",
					"paragraph": "Schreiben Sie uns eine E-Mail. Wir werden uns so schnell wie möglich mit Ihnen in Verbindung setzen."
				}
			},
			"errorBoundary": {
				"title": "Etwas ist schief gelaufen",
				"message": "Leider ist ein Fehler aufgetreten. Klicken Sie entweder auf \"Zurück\", um zum Hilfe-Tool zurückzukehren oder auf \"Support erhalten\", um uns zu kontaktieren.",
				"resetButton": "Zurück",
				"errorId": "Fehler-ID: {errorId}"
			},
			"tour": {
				"prev": "Zurück",
				"next": "Weiter",
				"done": "Fertig"
			},
			"notAvailableMobile": {
				"title": "Nicht verfügbar",
				"buttonBack": "Zurück",
				"paragraph": "Dieser Inhalt ist auf Ihrem Gerät nicht verfügbar. Versuchen Sie, ihn auf einem Gerät mit höherer Bildschirmauflösung zu starten, etwa auf einem Desktop-Computer."
			},
			"shareButton": {
				"title": {
					"article": "Einen Deep-Link zu diesem Artikel teilen",
					"topic": "Einen Deep-Link zu diesem Thema teilen",
					"tour": "Einen Deep-Link zu dieser Tour teilen"
				},
				"radioItemHome": "Start App",
				"descriptionHome": "Öffnet den Hilfe-Artikel auf der Startseite im Händlerportal",
				"radioItemCurrent": "Aktuelle Seite",
				"descriptionCurrent": "Öffnet den Hilfe-Artikel auf der aktuellen Seite"
			},
			"marketSelector": {
				"whichMarketToContact": "Welchen Markt möchten Sie gerne kontaktieren?"
			}
		},
		"orientation": {
			"title": "Lernen Sie ..."
		},
		"helpbot": {
			"questions": {
				"whatDoYouNeedHelpWith": "Hallo! Womit können wir Ihnen heute behilflich sein?",
				"selectSupportChannel": "Bitte wählen Sie einen Kanal, um unseren Support-Service zu kontaktieren.",
				"thatHelped": "Vielen Dank für das positive Feedback, das macht es für uns viel einfacher, Ihnen in Zukunft eine individuellere Hilfe bieten zu können. 😀"
			},
			"orders": {
				"orderManagement": {
					"title": "Bestellmanagement",
					"createOrderPos": {
						"question": "Wie erstelle ich eine Bestellung über Point of Sales?",
						"answer": "1. Öffnen Sie Point of Sales 2. Geben Sie den Artikelnamen, den Produktpreis und die Menge ein. 3. Teilen Sie den generierten Zahlungslink per SMS oder E-Mail mit Ihrer Kundschaft oder lassen diese den auf Ihrem Kassenbildschirm angezeigten QR-Code scannen. 4. Ihre Kundschaft kann den Kauf nun über ihr eigenes Gerät abschließen."
					},
					"extendExpiryReservation": {
						"question": "Wie kann ich das Ablaufdatum einer Reservierung verlängern?",
						"answer": "1. Öffnen Sie den Bestellmanager und wählen Sie eine oder mehrere Bestellungen aus, deren Ablaufdatum Sie verlängern möchten. 2. Klicken Sie auf „Ablaufdatum verlängern“. 3. Bestätigen Sie Ihre Auswahl.\nDie Verlängerung für Bestellungen richtet sich nach den für Ihren Shop geltenden Vorgaben für den Reservierungszeitraum (in der Regel 28 Tage). Ist eine Bestellung abgelaufen, kann sie jedoch nicht mehr verlängert werden.\""
					},
					"howToCaptureOrders": {
						"question": "Wie erfasse ich Bestellungen?",
						"partially": {
							"question": "Ich möchte eine Bestellung nur teilweise erfassen",
							"answer": "Wenn nur ein Teil einer Kundenbestellung ausgeliefert werden kann, können Sie die Bestellung nach Auftragszeilen oder Betrag teilweise erfassen. Auftragszeilen erfassen 1. Gehen Sie im Bestellmanager zur entsprechenden Bestellung und wählen Sie auf der Bestellseite die Artikel aus, die Sie aktuell auf Lager haben. Denken Sie daran, die Auftragszeilen zu bearbeiten, um die Mengen bei Bedarf anzupassen. 2. Klicken Sie auf „Erfassen“. Betrag erfassen: 1. Rufen Sie im Bestellmanager die Bestellung auf. Sie können einen Betrag erfassen, indem Sie auf die Schaltfläche \"Alles erfassen\" klicken. Geben Sie hier den gewünschten Betrag ein. 2. Klicken Sie auf „Erfassen“.\nDenken Sie daran, die Bestellung nach Möglichkeit abzuschließen, indem Sie entweder die verbleibenden Artikel bzw. den restlichen Betrag erfassen, sobald die Artikel zum Versand bereitstehen, oder indem Sie die verbleibende Bestellung stornieren, wenn Sie die Waren nicht versenden können."
						},
						"fully": {
							"question": "Ich möchte Bestellungen vollständig erfassen",
							"answer": "1. Öffnen Sie den Bestellmanager und klicken Sie auf die Klarna Referenznummer, um die Bestellung zu öffnen. 2. Zur Erfassung der gesamten Bestellung gehen Sie bitte wie folgt vor: – Klicken Sie auf die Schaltfläche \"Erfassen\". Wählen Sie die Auftragszeilen aus und klicken Sie auf \"Erfassen\". – Oder klicken Sie auf \"Alles erfassen\" und geben Sie den Gesamtbetrag für die Bestellung ein. 3. Beim Erfassen der Bestellung können Sie den Packzettel, der Ihrer Sendung beigelegt wird, herunterladen.\nAchten Sie darauf, Bestellungen vor ihrem Ablaufdatum (in der Regel 28 Tage nach Auftragserstellung) zu erfassen. Sie können mehrere Bestellungen erfassen, indem Sie diese in der Auftragsliste auswählen und auf \"Vollständig erfassen\" klicken, wenn Sie den Vorgang beschleunigen möchten."
						}
					},
					"canIUpdateCustomerInfo": {
						"question": "Kann ich Kundendaten aktualisieren?",
						"answer": "Den Empfänger einer Bestellung können Sie grundsätzlich nicht ändern. Eine Änderung der Liefer- und Rechnungsadresse für noch nicht oder nur teilweise erfasste Bestellungen ist jedoch möglich. Falls Klarna die neue Adresse mit der Begründung ablehnt, dass diese nicht für den Kunden hinterlegt ist, kann die Rechnungsadresse dennoch geändert werden."
					},
					"extendDueDate": {
						"question": "Wie kann ich die Zahlungsfrist für eine Bestellung verlängern?",
						"answer": "Sie können die Zahlungsfrist einer Bestellung verlängern, indem Sie bei einer bereits erfassten Bestellung auf \"Zahlungsfrist verlängern\" klicken. Für die Verlängerung der Zahlungsfrist einer Bestellung fällt eine Gebühr an, die Ihnen vor Abschluss des Vorgangs mitgeteilt wird."
					},
					"refundOrder": {
						"question": "Wie erstatte ich eine Bestellung?",
						"answer": "1. Öffnen Sie den Bestellmanager und suchen Sie nach dem Betrag, den Sie zurückerstatten möchten. 2. Legen Sie das Verfahren für die Rückerstattung fest: Wählen Sie entweder die zu erstattenden Artikel aus und klicken Sie dann auf \"Rückerstattung\", oder klicken Sie zuerst auf \"Rückerstattung\", um den Betrag einzugeben, den Sie erstatten möchten. 3. Der erstattete Betrag wird von Ihrer nächsten Auszahlung abgezogen und Kunden innerhalb von 5 Werktagen gutgeschrieben.\nDies gilt nur für bereits erfasste Bestellungen. Stornieren Sie nicht erfasste Bestellungen, sofern Sie keine Artikel versenden. Wenn nur ein Teil der Bestellung erfasst und versendet wurde, geben Sie den nicht erfassten Anteil frei."
					},
					"cancelOrder": {
						"question": "Wie storniere ich eine Bestellung?",
						"answer": "1. Öffnen Sie den Bestellmanager und suchen Sie nach der Bestellung, die Sie stornieren möchten. 2. Klicken Sie auf \"Bestellung stornieren\". Damit wird auch Ihre Kundschaft darüber informiert, dass die Bestellung nicht versendet wird.\nSie können stornierte Bestellungen immer neu erstellen, falls das nötig ist. Sie können erfasste Bestellungen nicht stornieren, aber Sie können Sie erstatten."
					},
					"unableToTakeActions": {
						"question": "Warum kann ich für eine Bestellung keine Aktionen ausführen?",
						"directPartner": {
							"question": "Ich habe eine direkte Vereinbarung mit Klarna",
							"answer": "In den Benutzerberechtigungen ist festgelegt, was NutzerInnen im Partnerportal tun können. Es gibt zwei verschiedene Arten von Benutzerberechtigungen: Admin – NutzerInnen mit vollem Zugriff. Administratoren können beispielsweise auf alle Funktionen im Partnerportal zugreifen und diese bearbeiten. App-spezifische Berechtigungen – NutzerInnen ohne Admin-Rechte haben Zugriff auf einen oder mehrere bestimmte Bereiche im Partnerportal.\nDies kann der Grund dafür sein, dass Sie keine Bestellungen ausführen können. Für den Bestellmanager gibt es unterschiedliche Zugriffsberechtigungen. Sprechen Sie mit Ihrem Administrator, um zu erfahren, welche Zugriffsberechtigungen für die von Ihnen durchzuführenden Aktionen am besten geeignet ist."
						},
						"distributionPartner": {
							"question": "Ich habe eine direkte Vereinbarung mit Klarna",
							"answer": "Alle Aktionen, die Sie bezüglich einer Bestellung durchführen möchten, können nur über die vom Vertriebspartner bereitgestellte Plattform erfolgen."
						}
					}
				},
				"orderModification": "Bestellungsänderung und Fragen",
				"orderProcessing": "Bearbeitung von Bestellungen",
				"refundIssues": {
					"title": "Probleme bei der Rückerstattung",
					"refundedOrderWhenCustomerFunds": {
						"question": "Ich habe eine Bestellung zurückerstattet. Wann erhält meine Kundschaft ihr Geld?",
						"answer": "Das Geld sollte innerhalb von 5 Arbeitstagen nach der Rückerstattung der Bestellung bei der Kundschaft eingehen. Dies kann je nach gewählter Zahlungsart bei der Bestellung variieren. Sollten Fragen dazu bestehen, können sich Kundinnen und Kunden an den Klarna Kundenservice wenden."
					}
				},
				"reservations": {
					"title": "Reservierungen",
					"whyUnableToExtendReservation": {
						"question": "Warum kann ich die Reservierungszeit für eine Bestellung nicht verlängern?",
						"answer": "Unter bestimmten Umständen ist eine Verlängerung der Gültigkeitsdauer einer Bestellung nicht möglich:\n- Wenn die Bestellung bereits abgelaufen ist, müssen Sie sie neu erstellen. Gehen Sie dazu auf die Bestellseite, klicken Sie auf „Kopieren“ und wählen Sie „Neue Bestellung erstellen“.\n- Wenn die Bestellung per Karte bezahlt wurde, muss die Kundschaft eine neue Bestellung aufgeben, da die Gültigkeitsdauer für diese Bestellungen nicht verlängert werden kann.\n"
					}
				},
				"paymentsForMyOrders": {
					"title": "Zahlungen für meine Bestellungen",
					"whenWillIGetPaid": {
						"question": "Wann erhalte ich die Zahlung für eine Bestellung?",
						"answer": "Zum Erhalt einer Zahlung für eine Bestellung müssen Sie diese zunächst erfassen. Der Auszahlungsbetrag wird nach Abzug von Gebühren, Retouren und anderen Kosten gemäß Ihrem Auszahlungs-Zeitplan auf Ihr Bankkonto überwiesen. Sie können Ihre Auszahlungseinstellungen im Abschnitt „Abrechnungen“ einsehen."
					},
					"refundOrderReceivedPayment": {
						"question": "Was geschieht, wenn ich eine Rückerstattung für eine bereits bezahlte Bestellung vornehme?",
						"answer": "Alle Beträge, die Sie zurückerstatten, werden von Ihrer nächsten geplanten Auszahlung abgezogen."
					},
					"referenceNotShowUp": {
						"question": "Die Bestell- oder Referenznummer wird im Bericht nicht angezeigt.",
						"answer": "Wenn Sie eine Zahlung für eine bestimmte Bestellung nicht finden können, gehen Sie folgendermaßen vor:\n- Überprüfen Sie die Richtigkeit der Bestell- oder Referenznummer.\n- Prüfen Sie, ob die Bestellung erfasst wurde.\n- Prüfen Sie, ob die Bestellung in den Erfassungszeitraum des Berichts fällt.\n- Beachten Sie, dass vor der Abrechnung stornierte Bestellungen nicht in dem Bericht erscheinen.\n"
					},
					"gotPaidSpecificOrder": {
						"question": "Wie kann ich sehen, ob ich für eine bestimmte Bestellung eine Zahlung erhalten habe?",
						"answer": "Suchen Sie im Bestellmanager des Partnerportals nach der Bestellung und überprüfen Sie, ob die Bestell- oder Referenznummer korrekt ist. Prüfen Sie unter der Referenznummer, ob die Bestellung erfasst wurde. Wenn sie erfasst wurde, wird der Status als ausstehend oder ausgezahlt angezeigt. Bei ausgezahlten Bestellungen klicken Sie auf „Abrechnung anzeigen“, um den entsprechenden Bericht einzusehen."
					}
				}
			},
			"disputes": {
				"disputeManagement": "Beanstandungsverwaltung",
				"contactInfoAndNotifications": "Kontaktinformationen und Benachrichtigungs-Einstellungen",
				"timingAndDeadlines": "Zeitplan und Fristen für Beanstandungen",
				"resolutionProcess": "Verfahren zur Beilegung von Beanstandungen",
				"merchantDisputesApp": {
					"title": "Beanstandungen-App für Händler",
					"respondToDispute": {
						"question": "Woher weiß ich, ob ich auf eine Anfrage zu einer Beanstandung antworten muss?",
						"answer": "Prüfen Sie auf der Startseite Ihres Partnerportals im Abschnitt „Zu erledigen“, ob Sie auf eine Beanstandung reagieren müssen."
					},
					"overviewDisputesCustomer": {
						"question": "Wo finde ich eine Übersicht zu allen von der Kundschaft vorgebrachten Beanstandungen?",
						"answer": "Alle eingegangenen Beanstandungen finden Sie direkt nach deren Eingang im Abschnitt „Beanstandungen“ auf der Seite „Alle Beanstandungen“.\n\nHinweis: Beanstandungen sind auf der Seite „Alle Beanstandungen“ bis zu 180 Tage nach deren Schließung verfügbar.\n"
					},
					"overviewDisputesResponse": {
						"question": "Wo finde ich eine Übersicht aller Beanstandungen, die eine Antwort erfordern?",
						"answer": "Alle Beanstandungen, die eine Antwort erfordern, finden Sie im Abschnitt „Beanstandungen“ auf der Seite „Offene Beanstandungen“ unter „Antwort erforderlich“."
					},
					"updateContactInformation": {
						"question": "Wie kann ich meine Kontaktinformationen für Erinnerungs-E-Mails zu Beanstandungen aktualisieren?",
						"answer": "1. Rufen Sie den Abschnitt „Beanstandungen“ auf.\n2. Klicken Sie auf die Seite „Einstellungen für E-Mails und Beanstandungen“.\n3. Auf der Registerkarte „E-Mail und Benachrichtigungen“ können Sie Ihre Kontaktinformationen anpassen.\n\nHinweis: Für „Beanstandungen“, „nicht autorisierte Käufe“ und „risikobehaftete Bestellungen“ können unterschiedliche Kontaktinformationen angegeben werden. Außerdem kann auf der Registerkarte „Benachrichtigungen“ auch die Häufigkeit der Benachrichtigungen festgelegt werden.\n"
					},
					"updateCustomerServiceOnKlarnaApp": {
						"question": "Wie kann ich meine Kundenservice-Kontaktdaten in der Klarna App aktualisieren?",
						"answer": "1. Gehen Sie zu „Zahlungseinstellungen“.\n2. Klicken Sie auf „Kundenservice-Kontaktdaten“.\n3. Wählen Sie die Option „Markt“ (falls Sie marktspezifischen Support anbieten).\n\nSie können die in der Klarna App anzuzeigenden Kundenkontaktinformationen und die Retouren-Richtlinie eingeben und dadurch die Zufriedenheit der Kundschaft erhöhen.\n\nUm diese Informationen einsehen zu können, benötigen Sie volle Zugriffsrechte für das Partnerportal.\n"
					},
					"improveDisputesPerformance": {
						"question": "Wie kann ich meine Ergebnisse bei Beanstandungen verbessern?",
						"answer": "Tipps und Tricks zur Verbesserung Ihrer Ergebnisse bei Beanstandungen finden Sie im „Verbesserungsleitfaden für Retouren und Beanstandungen“. Zugriff darauf besteht über die Seite „Statistiken“. Klicken Sie dazu auf die Schaltfläche „Einblicke erhalten“ oben rechts auf der Seite.\n\nUm diese Informationen einsehen zu können, benötigen Sie volle Zugriffsrechte für das Partnerportal.\n"
					},
					"overviewDisputesPerformance": {
						"question": "Wo finde ich einen Überblick über die Ergebnisse meiner Beanstandungen?",
						"answer": "Ausführliche Statistiken zu Beanstandungen finden Sie auf der Seite „Statistiken“ in der Disputes App. Hier erhalten Sie einen Überblick über die monatlichen Beanstandungen, die Ergebnisse, die Kosten sowie die Verluste im Zusammenhang mit Beanstandungen.\n\nUm diese Informationen einsehen zu können, benötigen Sie volle Zugriffsrechte für das Partnerportal.\n"
					}
				},
				"disputesLifecycle": {
					"title": "Lebenszyklus von Beanstandungen",
					"whenCanSeeDisputes": {
						"question": "Wann kann ich die von der Kundschaft vorgebrachten Beanstandungen sehen?",
						"answer": "In der Disputes App können Sie auf der Seite „Alle Beanstandungen“ alle erhobenen Beanstandungen unmittelbar nach deren Eingang einsehen."
					},
					"howLongToResolve": {
						"question": "Wie viel Zeit habe ich, um die Beanstandung direkt mit der Kundschaft zu klären?",
						"answer": "Ab dem Tag der Meldung einer Beanstandung haben Sie 21 Tage Zeit zur Klärung. Können Sie den Streitfall nicht innerhalb von 21 Tagen mit der Kundschaft klären, schaltet sich Klarna ein, um eine Beilegung zwischen beiden Parteien zu unterstützen."
					},
					"howMuchTimeToRefund": {
						"question": "Wie viel Zeit habe ich für die Rückerstattung von Retouren?",
						"answer": "Für die Bearbeitung der Rückerstattung haben Sie ab dem Rückgabedatum 21 Tage Zeit. Danach schaltet sich Klarna ein, um das Verfahren zwischen beiden Parteien zu unterstützen. Daher ist es immer am besten, Retouren unmittelbar nach Erhalt zu erstatten."
					},
					"howToResolve": {
						"question": "Wie lassen sich Beanstandungen und Retouren am besten regeln?",
						"answer": "Es gibt mehrere Möglichkeiten zur Regelung von Beanstandungen oder Retouren.\n\n- Bearbeiten Sie die Retoure oder die Rückerstattung, sobald Sie diese erhalten bzw. sich auf eine Lösung geeinigt haben.\n- Legen Sie unter „Einstellungen für E-Mails und Beanstandungen“ einen Schwellenwert für Rückerstattungen fest.\n- Überprüfen Sie proaktiv die Einträge unter „Alle Beanstandungen“.\n- Akzeptieren Sie mögliche Verluste.\n"
					},
					"whenToRespond": {
						"question": "Wann muss ich mich aktiv verteidigen/auf Beanstandungen reagieren?",
						"answer": "Wenn Sie und die Kundschaft nicht innerhalb der vorgegebenen Frist (21 Tage) zu einer Einigung gelangen, wird Klarna den Lösungsprozess zwischen beiden Parteien unterstützen. Wenn die Beanstandung berechtigt ist, wird sich das Support-Team von Klarna über die Disputes App mit der Bitte um weitere Informationen an Sie wenden.\n\nIn diesem Fall werden Sie aufgefordert, sich zu verteidigen bzw. auf eine Beanstandung zu reagieren.\n"
					},
					"howMuchTimeToRespond": {
						"question": "Wie viel Zeit habe ich, um auf eine Anfrage zu einer Beanstandung zu reagieren?",
						"answer": "Die Frist für die erste Anfrage beträgt 14 Tage für alle Beanstandungen. Eine Ausnahme bilden die nicht autorisierten Käufe (7 Tage). Für jede an Sie gerichtete Folge-Anfrage* (maximal 5 Anfragen) gilt eine Frist von 7 Tagen. Falls Sie Ihrer Antwort noch etwas hinzufügen möchten, haben Sie ab dem Zeitpunkt der Einreichung 60 Minuten Zeit zum Hinzufügen von beispielsweise Informationen oder Anhängen. Wenn die Frist verstrichen ist, können Sie sich in dem Fall nicht länger verteidigen.\n\n*Je nach Partnerschaftsvereinbarung können spezielle Vorschriften gelten.\n"
					},
					"howLongToSeeDispute": {
						"question": "Wie lange bleibt eine Beanstandung in der Disputes App nach der Beilegung sichtbar?",
						"answer": "Beanstandungen sind auf der Seite „Alle Beanstandungen“ bis zu 180 Tage nach deren Schließung verfügbar."
					},
					"whenToGetCharged": {
						"question": "Wann wird eine Beanstandungsgebühr erhoben?",
						"answer": "Eine Beanstandungsgebühr kann anfallen, wenn:\n- Sie die Beanstandung verschuldet haben.\n- Sie es versäumen, innerhalb der Frist zu antworten.\n- Sie eine Rückerstattung ausstellen, nachdem Klarna bei der Lösung des Problems unterstützend eingegriffen hat.\n\nDie Gebühr wird rückwirkend nach der Entscheidung über die Beanstandung erhoben. Es entstehen Ihnen keine Kosten, wenn die Beanstandung zu Ihren Gunsten entschieden, annulliert oder abgewiesen wird.\n"
					}
				},
				"disputesHandling": {
					"title": "Behandlung von Beanstandungen",
					"whatIsDispute": {
						"question": "Welche Informationen erhalte ich im Rahmen einer Beanstandung?",
						"answer": "Eine Beanstandungsanfrage enthält Folgendes:\n\n- Grund: Kategorie der Beanstandung (z. B. Retouren, Artikel nicht erhalten usw.).\n- Händler: Relevant, wenn mehrere Händler-IDs (MIDs) bei Klarna registriert sind.\n- Beanstandungsdatum: Das Datum, an dem die Kundschaft die Beanstandung erhoben hat.\n- Frist: Die Frist, bis zu der Sie reagieren müssen, um den Fall zu verteidigen.\n- Produkte der Beanstandung: Der/die Artikel, um den/die es bei der Beanstandung geht.\n- Bestelldetails: Dazu gehören Angaben wie „Bestellnummer 1 und 2“, „Bestelldatum“ und „strittiger Betrag“.\n"
					},
					"howManyTimesDefend": {
						"question": "Wie oft kann ich eine Beanstandung verteidigen?",
						"answer": "Sie erhalten maximal fünf (5) Anfragen zur Verteidigung Ihrer Beanstandung, aber in den meisten Fällen sind nicht mehr als ein oder zwei nötig."
					},
					"doIReceiveNotification": {
						"question": "Erhalte ich eine Benachrichtigung, wenn ich auf eine Beanstandung reagieren muss?",
						"answer": "Anhängige Beanstandungen werden an mehreren Stellen angezeigt:\n\n- Auf der Startseite Ihres Partnerportals im Abschnitt „Zu erledigen“.\n- Als Benachrichtigung im Abschnitt „Beanstandungen“ auf der Seite „Offene Beanstandungen“, mit Angabe der Anzahl der Beanstandungen, die eine Antwort erfordern.\n\nSie können auch festlegen, dass Sie für jede Beanstandung eine E-Mail erhalten. Wählen Sie dazu unter „Einstellungen für E-Mails und Beanstandungen“ die Option „E-Mail-Benachrichtigung für jede Beanstandung“.\n"
					},
					"howToAvoidDisputesFees": {
						"question": "Wie kann ich Beanstandungsgebühren vermeiden?",
						"answer": "So können Sie Beanstandungsgebühren vermeiden:\n- Bearbeiten Sie Retouren oder Rückerstattungen umgehend.\n- Legen Sie unter „Einstellungen für E-Mails und Beanstandungen“ einen Schwellenwert für Rückerstattungen fest.\n- Überprüfen Sie regelmäßig die Einträge unter „Alle Beanstandungen“.\n- Reagieren Sie auf Beanstandungen mit relevanten Informationen.\n- Antworten Sie vor Ablauf der Frist.\n"
					}
				}
			},
			"settlements": {
				"payoutNotFound": {
					"title": "Die Auszahlung für eine bestimmte Bestellung kann nicht gefunden werden.",
					"orderNumberNotFound": {
						"question": "Die Bestell- oder Referenznummer wird im Bericht nicht angezeigt.",
						"answer": "Wenn Sie eine Zahlung für eine bestimmte Bestellung nicht finden können, gehen Sie folgendermaßen vor:\n- Überprüfen Sie die Richtigkeit der Bestell- oder Referenznummer.\n- Prüfen Sie, ob die Bestellung erfasst wurde.\n- Prüfen Sie, ob die Bestellung in den Erfassungszeitraum des Berichts fällt.\n- Beachten Sie, dass vor der Abrechnung stornierte Bestellungen nicht in dem Bericht erscheinen.\n"
					},
					"checkIfPaidForOrder": {
						"question": "Wie kann ich sehen, ob ich für eine bestimmte Bestellung eine Zahlung erhalten habe?",
						"answer": "Suchen Sie im Bestellmanager des Partnerportals nach der Bestellung und überprüfen Sie, ob die Bestell- oder Referenznummer korrekt ist. Prüfen Sie unter der Referenznummer, ob die Bestellung erfasst wurde. Wenn sie erfasst wurde, wird der Status als ausstehend oder ausgezahlt angezeigt. Bei ausgezahlten Bestellungen klicken Sie auf „Abrechnung anzeigen“, um den entsprechenden Bericht einzusehen."
					}
				},
				"payouts": {
					"title": "Auszahlungen",
					"whenIsNext": {
						"question": "Wann erfolgt meine nächste Auszahlung?",
						"answer": null
					},
					"whyCalculationNotMatch": {
						"question": "Warum stimmt meine eigene Berechnung nicht mit den erhaltenen Auszahlungen überein?",
						"answer": "Gemäß dem Händlervertrag werden Gebühren für Transaktionen oder Retouren abgezogen. Daher können Ihre Berechnungen von Klarnas Abrechnungen abweichen. Eine klare Übersicht über die Abzüge erhalten Sie in der PDF-Version des Abrechnungsberichts."
					},
					"whenGetPaid": {
						"question": "Wann erhalte ich mein Geld?",
						"answer": null
					},
					"missingPayout": {
						"question": "Ich habe meine Auszahlung nicht erhalten.",
						"answer": "Bedenken Sie Folgendes, bevor Sie sich an unseren Support-Service wenden. Fehlende Auszahlungen können folgende Gründe haben:\n\n- Klarna sendet die Zahlung noch am selben Tag, an dem der Bericht erstellt wird. Es kann jedoch einige Tage dauern, bis das Geld auf Ihrem Bankkonto gutgeschrieben wird.\n- Sie haben für diesen Erfassungszeitraum keine Bestellungen erfasst.\n- Sie haben vor Kurzem Ihre Kontoinformationen bei Klarna aktualisiert. Dadurch wurde die Zahlung möglicherweise auf Ihr früheres Bankkonto überwiesen. Dies kann vorkommen, wenn die Änderung vor der Ausführung der Zahlung nicht bearbeitet wird.\n- Die von Ihnen gesuchte Bestell- oder Referenznummer ist falsch. Überprüfen Sie die Bestell- oder Referenznummer.\n- Ihre Auszahlungen wurden von Klarna einbehalten. Über die Einbehaltung sollten Sie separat per E-Mail informiert worden sein. Diese E-Mails werden in der Regel an einen Administrator gesendet.\n"
					},
					"howIsPayoutCalculated": {
						"question": "Wie wird meine Auszahlung berechnet?",
						"answer": "Die Auszahlung errechnet sich aus den innerhalb des Erfassungszeitraums erfassten Bestellungen, abzüglich Gebühren, Retouren und anderer Kosten. Eine genaue Aufschlüsselung dieser Beträge liefert ein Abrechnungsbericht.\nÜbersteigen die Rückerstattungen den Umsatz in einem Erfassungszeitraum, wird der überschüssige Betrag von der nächsten Auszahlung abgezogen.\n"
					}
				},
				"howPayoutsWork": {
					"question": "Wie funktioniert die Auszahlung?",
					"answer": "Im Abschnitt „Abrechnungen“ finden Sie einen Überblick über die Auszahlungen, einschließlich Zahlungsplänen, Erfassungszeiträumen und Auszahlungsdaten. Für jede Auszahlung können Sie Transaktionsberichte im PDF- oder CSV-Format herunterladen und Einzelheiten zu bestimmten Zahlungen ansehen oder herunterladen. Klicken Sie dazu auf die entsprechenden Zahlungsreferenzen."
				},
				"payoutSchedule": {
					"title": "Zahlungs-Zeitplan",
					"whyDelay": {
						"question": "Wie kommt es zu einer Auszahlungsverzögerung?",
						"answer": "Hauptsächlich soll damit verhindert werden, dass Geld für Rechnungen zwischen Klarna und Ihnen hin- und hergeschickt wird. Außerdem sollen damit Fälle abgedeckt werden, in denen Sie eine erfasste Bestellung zurückerstatten möchten."
					},
					"changePayoutDelay": {
						"question": "Ich möchte die Einstellungen für meine Auszahlungsverzögerung anpassen.",
						"answer": "Es gibt interne Kontrollen sowie allgemeine Kriterien, die erfüllt werden müssen. Zu diesen allgemeinen Kriterien gehört Folgendes:\n- Sie müssen vollständig bei Klarna aufgenommen worden sein.\n- Das Datum Ihrer ersten Bestellung liegt über 100 Tage zurück.\n- Ihr Unternehmen muss älter als 6 Monate sein.\n\nKlarna benötigt darüber hinaus auch Informationen von einem Administrator über …\n- die gewünschte neue Zahlungsfrist und warum dies notwendig ist;\n- welche Händler-ID (MID) die Änderung betrifft.\nEs können auch zusätzliche Kriterien gelten, über die im Einzelfall entschieden wird.\n"
					}
				},
				"generatingReports": {
					"title": "Erstellung von Berichten",
					"howToSeeReports": {
						"question": "Wie kann ich Berichte für bestimmte Zeiträume anzeigen?",
						"answer": "Sie können Ihre Berichte für bestimmte Zeiträume anpassen. Verwenden Sie dazu unter „Abrechnungen“ den Abschnitt „Individuelle Berichte“."
					},
					"convertCsvToExcel2": {
						"answer": "Befolgen Sie diese Schritte:\n1. Öffnen Sie Excel und klicken Sie auf „Datei“ > „Neu“ > „Leere Arbeitsmappe“.\n"
					},
					"convertCsvToExcel": {
						"question": "Wie konvertiere ich einen CSV-Bericht in eine Excel-Tabelle?",
						"answer": "1. Öffnen Sie Excel und klicken Sie auf „Datei“ > „Neu“ > „Leere Arbeitsmappe“.\n2. Klicken Sie auf die Registerkarte „Daten“ und wählen Sie „Aus Text/CSV“ aus.\n3. Rufen Sie die gewünschte .CSV-Datei aus und klicken Sie auf „Daten transformieren“.\n4. Wählen Sie im Textimport-Assistenten „Trennzeichen“ als Option aus und klicken Sie dann auf „Weiter“.\n5. Wählen Sie unter „Trennzeichen“ die Option „Semikolon“ und für „Textqualifizierer“ die Option „kein“ aus. Klicken Sie auf „Fertig stellen“.\n6. Klicken Sie im Dialogfeld „Daten importieren“ auf „OK“, um die Daten beginnend in Zelle A1 zu platzieren.\n\nHinweis: CSV-Berichte im Händlerportal verwenden ein Semikolon (;) als Trennzeichen. In Ihrer Software für Tabellenkalkulation können Sie nachlesen, wie Sie das Standardtrennzeichen anpassen können.\n"
					}
				},
				"payoutAndFeeCalculation": {
					"title": "Auszahlungs- und Gebührenberechnung",
					"howAreFeesCalculated": {
						"question": "Wie werden die Gebühren berechnet?",
						"answer": "Die von den Auszahlungen durch Klarna abgezogenen Gebühren sind in Ihrer Händlervereinbarung festgelegt. Zusätzliche Gebühren wie beispielsweise für verspätete Retouren sind im Abschnitt „Gebühr“ Ihrer Auszahlungs-/Abrechnungsberichte aufgeführt.\n\nSo passen Sie die Gebührenbeschreibungen in Ihren Berichten an:\n1. Gehen Sie zu „Abrechnungsberichte“ > „Berichte konfigurieren“ > „Neue Konfiguration hinzufügen“.\n2. Für CSV-Berichte wählen Sie in der Transaktionsliste die Option „Detaillierter Transaktionstyp“.\n3. Für PDF-Berichte wählen Sie in den Einstellungen für Layout und Inhalt die Option „Bestelldetails einfügen“.\n\nDie Einstellungen müssen für jede Händler-ID und jeden Kanal (SFTP, Händlerportal, API) separat vorgenommen werden.\n"
					},
					"howIsPayoutCalculated": {
						"question": "Wie wird meine Auszahlung berechnet?",
						"answer": "Die Auszahlung errechnet sich aus den innerhalb des Erfassungszeitraums erfassten Bestellungen, abzüglich Gebühren, Retouren und anderer Kosten. Eine genaue Aufschlüsselung dieser Beträge liefert ein Abrechnungsbericht.\n\nÜbersteigen die Rückerstattungen den Umsatz in einem Erfassungszeitraum, wird der überschüssige Betrag von der nächsten Auszahlung abgezogen.\n"
					}
				}
			},
			"options": {
				"payout": "Auszahlungen",
				"orders": "Bestellungen",
				"settlements": "Abrechnungen",
				"disputes": "Beanstandungen",
				"otherIssue": "Andere Probleme",
				"yes": "Ja",
				"no": "Nein",
				"partially": "Teilweise",
				"iNeedMoreHelp": "Ich benötige weitere Hilfe",
				"thatHelped": "Das hat geholfen",
				"contactSupportChannel": "Support-Kanäle kontaktieren"
			},
			"button": {
				"label": "Support erhalten"
			},
			"contact": {
				"chat": "Mit einem/r Mitarbeitenden chatten",
				"ticket": "Ein Ticket einreichen",
				"times": {
					"minutes": "Wartezeit: {time} Minute(n)",
					"hours": "Wartezeit: {time} Stunde(n)",
					"days": "Wartezeit: {time} Tag(e)"
				}
			}
		}
	}
};