import { ButtonQuaternary, Loader, SpacerVertical } from '@klarna/bubble-ui'
import { Layout, Modal } from '@klarna/mp-ui'
import { hooks as coreHooks, selectors as coreSelectors } from 'mage-core'
import { hooks as i18nHooks } from 'mage-i18n'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import MidInvite, { InviteChoices } from './MidInvite'

const combineMidsAndStoreName = (mids, stores) => {
  return mids.reduce((acc, mid) => [...acc, { mid, storeName: R.propOr('', mid, stores) }], [])
}

const handleInvites = async (emberClient, choices, userId) => {
  const results = []
  for (const choice of choices) {
    switch (choice.value) {
      case InviteChoices.ACCEPT:
        results.push(await emberClient.put(`/users/${userId}/entities`, {
          entity_type: 'merchant',
          entity_id: choice.mid,
          status: 'ACTIVE'
        }).catch(() => ({ mid: choice.mid })))
        break
      case InviteChoices.DECLINE:
        results.push(await emberClient.delete(`/users/${userId}/entities?entity_type=merchant&entity_id=${choice.mid}`).catch(() => ({ mid: choice.mid }))
        )
        break
      default:
        break
    }
  }
  return results
}

const sortInviteByMid = (a, b) => a.mid.localeCompare(b.mid)

const AcceptInviteModal = ({ reload = () => window.location.assign('/') }) => {
  const t = i18nHooks.useTranslator()
  const tokenHelper = coreHooks.useTokenHelper()
  const userId = tokenHelper.getUserId()

  const midsWithPendingInvite = useSelector(coreSelectors.getPendingMerchantIds)
  const emberClient = useSelector(coreSelectors.createBackendClient)('users')
  const mpApiClient = useSelector(coreSelectors.createBackendClient)('merchant-portal')

  const [choices, setChoices] = useState([])
  const [error, setError] = useState(null)
  const [midsWithErrors, setMidsWithErrors] = useState([])
  const [stores, setStores] = useState([])
  const [storesLoading, setStoresLoading] = useState(true)
  const [submitLoading, setSubmitLoading] = useState(false)

  const hasOpenInvites = midsWithPendingInvite.length
  const choicesMade = choices.filter(choice => choice.value !== null).length

  useEffect(async () => {
    const stores = await fetchStores(mpApiClient, midsWithPendingInvite)
    const storesWithNames = combineMidsAndStoreName(midsWithPendingInvite, stores)
    setStores(storesWithNames)
    setChoices(storesWithNames.map(({ mid, storeName }) => ({ mid, storeName, value: null })))
  }, [midsWithPendingInvite])

  const fetchStores = async (client, mids) => {
    try {
      const { data } = await client.request({
        method: 'GET',
        url: '/merchants',
        params: {
          mid: mids
        }
      })
      setStoresLoading(false)
      return data.reduce((acc, store) => {
        acc[store.merchant_id] = store.store_name
        return acc
      }, {})
    } catch (error) {
      setStoresLoading(false)
      return {}
    }
  }

  const handleAcceptAll = () => {
    setChoices(stores.map(({ mid, storeName }) => ({ mid, storeName, value: InviteChoices.ACCEPT })))
  }
  const handleDeclineAll = () => {
    setChoices(stores.map(({ mid, storeName }) => ({ mid, storeName, value: InviteChoices.DECLINE })))
  }
  const handleMidInviteChoice = (choice) => {
    setChoices((previousChoices) => [...previousChoices.filter((previousChoice) => previousChoice.mid !== choice.mid), choice])
  }

  const handleSave = async () => {
    setSubmitLoading(true)
    const results = await handleInvites(emberClient, choices, userId)
    const errors = results.filter(result => 'mid' in result)

    if (errors.length) {
      setMidsWithErrors(errors.map(error => error.mid))
      setError(t('home-fe.accept-invite-modal.error'))
      setSubmitLoading(false)
    } else {
      setError(null)
      setSubmitLoading(false)
      reload()
    }
  }

  return hasOpenInvites
    ? (
      <Modal
        title={t('home-fe.accept-invite-modal.title')}
        description={t('home-fe.accept-invite-modal.description')}
        generalError={error}
        onClose={reload}
        buttonPrimary={{
          disabled: !choicesMade,
          text: t('home-fe.accept-invite-modal.save-button'),
          onClick: handleSave,
          loading: submitLoading
        }}
      >
        {storesLoading
          ? (
            <Layout.Grid>
              <Layout.Section alignItems='center'>
                <Layout.Column alignItems='center'>
                  <Loader large />
                </Layout.Column>
              </Layout.Section>
            </Layout.Grid>
          )
          : (
            <>
              <div>
                <Layout.Grid>
                  <Layout.Section>
                    <Layout.Column mobileWidth={6}>
                      <ButtonQuaternary onClick={handleAcceptAll}>
                        {t('home-fe.accept-invite-modal.accept-all-button')}
                      </ButtonQuaternary>
                    </Layout.Column>
                    <Layout.Column mobileWidth={6}>
                      <ButtonQuaternary onClick={handleDeclineAll}>
                        {t('home-fe.accept-invite-modal.decline-all-button')}
                      </ButtonQuaternary>
                      <SpacerVertical spaceToken='space.500' />
                    </Layout.Column>
                  </Layout.Section>
                </Layout.Grid>
              </div>
              {choices.sort(sortInviteByMid).map(({ mid, value, storeName }) => (
                <MidInvite
                  hasError={midsWithErrors.includes(mid)}
                  isDisabled={submitLoading}
                  key={`midInvite-${mid}`}
                  mid={mid}
                  handleMidInviteChoice={handleMidInviteChoice}
                  storeName={storeName}
                  value={value}
                />
              ))}
            </>
          )}
      </Modal>
    )
    : null
}

AcceptInviteModal.propTypes = {
  reload: PropTypes.func
}

export default AcceptInviteModal

export { AcceptInviteModal }
