module.exports = {
	"nl": {
		"home-fe": {
			"boosters-home-v03": {
				"install-plugin": {
					"stepNumber": "1",
					"title": "Installeer de plug-in",
					"description": "Selecteer je e-commerce platform zodat we een koppeling kunnen maken om je installatie te versnellen",
					"selectorLabel": "Selecteer een platform",
					"getPluginButtonLabel": "Haal de plug-in",
					"klarnaDocsLinkLabel": "Meer informatie op Klarna-documenten"
				},
				"start-setup": {
					"stepNumber": "2",
					"title": "Zet de plug-in aan",
					"description": "Genereer en kopieer de klantidentificatie en de API-sleutel, deze heb je nodig om de plug-in in te schakelen."
				},
				"promotion-banner": {
					"title": "Klarna voor platforms",
					"subtitle": "Breid je bedrijf uit met één enkele plug-in, die je zonder extra kosten toegang geeft tot meerdere functies voor verhoogde omzet en verbeterde winkelervaringen",
					"banner-cards": {
						"osm-title": "Verhoog de gemiddelde orderwaarde met <span>On-site berichten</span>",
						"kec-title": "Verhoog je conversie met Express Checkout",
						"siwk-title": "Trek nieuwe klanten aan met <span>Aanmelden met Klarna</span>"
					}
				},
				"configure-boost-features": {
					"stepNumber": "3",
					"title": "Plaats speciaaltjes",
					"description": "Dit is een optionele stap om te veranderen hoe de functies er in je winkel uitzien. Hiervoor moet je stap 2 al voltooid hebben.",
					"no-credentials": "Zorg ervoor dat je eerst de klantidentificatie en de API-sleutel genereert om verder te kunnen gaan met deze stap.",
					"apps": {
						"osm": {
							"link-label": "Maak On-site berichten op maat",
							"description": "Maak On-site berichten op maat voor jouw merk door het thema en de plaatsing te selecteren"
						},
						"kec": {
							"link-label": "Op maat gemaakte Express checkout",
							"description": "Pas de express checkout-knop aan je merk aan door het thema en de vorm van de knop aan te passen en plaatsingen te selecteren"
						},
						"siwk": {
							"link-label": "Aanmelden met Klarna instellen",
							"description": "Selecteer het bereik voor aanmelden met Klarna en voeg een omleidings-URL toe. Het bereik is de informatie die van je klant wordt verzameld en de omleidings-URL wordt gebruikt als pop-ups door klanten worden geblokkeerd"
						}
					}
				},
				"client-identifier-section": {
					"title": "Cliëntidentificator",
					"description": "De unieke identificatie voor het instellen",
					"manage-link": "Beheer klantidentificatie en herkomst",
					"generate-button": "Genereren",
					"tooltip": "Genereer en kopieer de klantidentificatie. Je hebt deze nodig om de installatie van de plug-in op jouw portaal te starten."
				},
				"api-key": {
					"warning-banner": {
						"content": "U heeft al actieve API-sleutels. Voordat u nieuwe aanmaakt, moet u ervoor zorgen dat u alle sleutels die u niet gebruikt, hebt gedeactiveerd."
					},
					"title": "Klarna API-sleutel",
					"description": "API-sleutel voor de instelling",
					"tooltip": "Houd er rekening mee dat we je geheime API-sleutel niet meer weergeven nadat je deze hebt gegenereerd.",
					"generate-button": "Genereren",
					"manageApiKey": "Beheer de API-sleutel"
				},
				"non-platform-integration": {
					"question": "Gebruikt u Klarna niet voor platforms?",
					"link": "Bekijk hoe u Klarna direct kunt integreren"
				}
			},
			"boosters-home": {
				"welcome-banner": {
					"title": "Onthul de volledige kracht van Klarna",
					"text": "Functies ontworpen om van bezoekers supershoppers te maken. Verbeter elke stap van de winkelervaring - van het browsen tot het afrekenen."
				},
				"plugin-carousel": {
					"title": "Geen code instellen met een plug-in",
					"overviewLink": "Bekijk alle platforms",
					"directIntegrationLabel": "Gebruikt u Klarna niet voor platforms?",
					"directIntegrationLink": "Bekijk hoe u Klarna direct kunt integreren"
				},
				"booster-apps": {
					"title": "Ontdek meer",
					"kec-fe": {
						"title": "Zorg voor 5x sneller betalen",
						"description": "Met minder klikken haken minder mensen af. Stimuleer de conversie en bepert het verlaten van het winkelmandje met express checkout op de product- en winkelmandjepagina.",
						"callToAction": "Meer over Express Checkout"
					},
					"siwk-fe": {
						"title": "Social Sign in om te shoppen",
						"description": "Shoppers krijgen een snelle en veilige optie om met één klik in te loggen. U ontvangt verbeterde, bruikbare gegevens van het klantprofiel.",
						"callToAction": "Meer over Aanmelden met Klarna"
					},
					"upstream-fe": {
						"title": "Maar shoppers van uw bezoekers",
						"description": "Maximaliseer conversie met dynamische promotionele berichten gedurende de shopervaring.",
						"callToAction": "Meer over On-site berichten"
					},
					"branding-fe": {
						"title": "Verbeter de zichtbaarheid van uw winkel",
						"description": "Beheer uw merk in de Klarna-app door een winkellogo, pictogram en URL te uploaden.",
						"callToAction": "Stel Brand manager in"
					}
				}
			},
			"boosters-plugins": {
				"title": "Plug-in instellen",
				"description": "Ga aan de slag met onze plug-ins en zorg dat u klaar bent om live te gaan."
			},
			"boosters-plugin": {
				"title": "Klarna voor {pluginName}",
				"description": "Voer de onderstaande stappen uit om betalingen met Klarna of boostfuncties in te schakelen met onze setup zonder code.",
				"access-warning": {
					"both": "Je hebt geen toestemming voor {selectedStoreName} ({selectedMid}) om API-sleutels en klantidentificaties te genereren. Neem contact op met je beheerder en vraag om toegang tot de betalingsinstellingen om verder te gaan.",
					"client": "Je hebt geen toestemming voor {selectedStoreName} ({selectedMid}) om klantidentificaties te genereren. Neem contact op met je beheerder en vraag om toegang tot de betalingsinstellingen om verder te gaan.",
					"api": "Je hebt geen toestemming voor {selectedStoreName} ({selectedMid}) om API-sleutels te genereren. Neem contact op met je beheerder en vraag om toegang tot de betalingsinstellingen om verder te gaan."
				},
				"back": "Terug",
				"instructions": {
					"api-key": {
						"title": "Klarna-betalingen inschakelen",
						"content": "Je moet API-sleutels genereren en kopiëren zodat je ze kunt gebruiken tijdens de instelling.",
						"link-label": "Lees meer in Klarna Docs.",
						"generate": "Genereer de API-sleutels",
						"active-warning": {
							"content": "U heeft al actieve API-sleutels. Voordat u nieuwe aanmaakt, moet u ervoor zorgen dat u alle sleutels die u niet gebruikt, hebt gedeactiveerd.",
							"link": "API-sleutels beheren"
						},
						"active-warning-api-keys": {
							"content": "U heeft al actieve API-sleutels. Voordat u nieuwe aanmaakt, moet u ervoor zorgen dat u alle sleutels die u niet gebruikt, hebt gedeactiveerd.",
							"link": "API-sleutels beheren"
						}
					},
					"client-id": {
						"title": "Boostfuncties inschakelen",
						"content": "Om Klarna Express Checkout en Berichtgeving op website toe te voegen, moet u een cliëntidentificator genereren en kopiëren, zodat u deze kunt gebruiken tijdens het instellen.",
						"generate": "Cliëntidentificator genereren",
						"manage": "Cliëntidentificator beheren"
					},
					"plugin-doc": {
						"title": "Boostfuncties configureren",
						"content": "Om uw installatie af te ronden moet u Klarna Express Checkout en Berichtgeving op website configureren in de instellingen van uw platform."
					}
				},
				"error-messages": {
					"default": "Er is een fout opgetreden. Probeer het later nog eens of ga naar help.",
					"fetch-data": "Er is een fout opgetreden bij het ophalen van uw gegevens. Probeer het later nog eens of ga naar help.",
					"create-api-key": "Er is een fout opgetreden bij het aanmaken van je API-sleutels. Probeer het later nog eens of ga naar help.",
					"create-client-id": "Er is een fout opgetreden bij het aanmaken van uw cliëntidentificator. Probeer het later nog eens of ga naar help."
				}
			},
			"marketing-home": {
				"description": "Welkom bij onze marketingproducten. Bekijk ze hieronder.",
				"essentials": {
					"title": "Marketing",
					"cta-label": "Ontdek meer",
					"branding-fe": {
						"description": "Stel uw merk in en beheer het. Toon uzelf van uw beste kant aan uw klanten in Klarna's ecosysteem, inclusief in onze smoooth app."
					},
					"klarna-search-b2b-portal": {
						"description": "Begin met Klarna's krachtige zoekmachine en vergelijkingen. Krijg toegang tot statistieken en tools die zijn ontworpen om uw zichtbaarheid te verbeteren."
					}
				},
				"external-apps": {
					"title": "Apps",
					"cta-label": "Open de app"
				},
				"popOvers": {
					"searchCompare": {
						"title": "Begin met Zoeken en Vergelijken",
						"content": "Kijk hier voor statistieken en tools die kunnen helpen om uw zichtbaarheid op Klarna's vergelijkingsservice te vergroten."
					}
				}
			},
			"no-apps": {
				"title": "Geen apps beschikbaar!",
				"subtitle": "Neem contact op met je beheerder om je te helpen toegang te krijgen tot de apps die je nodig hebt."
			},
			"contact": {
				"title": "Heb je ergens hulp bij nodig?",
				"paragraph": "Als je vragen hebt, aarzel dan niet om contact op te nemen en we zullen ze met alle plezier beantwoorden.",
				"label": "Neem contact met ons op"
			},
			"important-banner": {
				"title": "",
				"content": "",
				"linkLabel": ""
			},
			"two-factor-home-banner": {
				"banner-text": "Maak uw account veiliger met two-factor-authentication.",
				"banner-link": "Stel het in"
			},
			"two-factor-opt-in": {
				"title": "Stel two-factor-authentication in",
				"intro-paragraph": "Om de veiligheid van uw account te verhogen, raden we u aan om vandaag two-factor-authentication in te stellen.",
				"help-link-text": "Klik hier",
				"help-text": " voor meer informatie over het instellen en hoe het uw account beschermt.",
				"warning-text": "Zorg ervoor dat u uw smartphone en toegang tot internet hebt voordat u doorgaat",
				"continue-button": "Start de instelling"
			},
			"accept-invite-modal": {
				"title": "Accepteer uitstaande uitnodigingen",
				"description": "U bent uitgenodigd voor toegang tot de volgende winkels. Houd er rekening mee dat het enkele minuten kan duren voordat uw keuzes geactiveerd worden.",
				"accept-all-button": "Alles accepteren",
				"decline-all-button": "Alles afwijzen",
				"save-button": "Opslaan",
				"error": "Er heeft zich een probleem voorgedaan. Probeer het later opnieuw!",
				"invite": {
					"accept-label": "Accepteren",
					"decline-label": "Afwijzen"
				}
			},
			"capture-orientation": {
				"title": "Gebruik je Klarna voor het eerst?",
				"sub-title": "Volg deze stappen en leer de basis:",
				"order-label": "Ontvang een bestelling",
				"capture-label": "Activeer de bestelling",
				"payout-label": "Krijg betaald",
				"capture-cta": "Activeer deze bestelling",
				"payout-cta": "Afrekeningen-app",
				"dismiss-cta": "Negeren",
				"order-step": "Eerst moeten we wachten op een bestelling van je klanten.",
				"capture-step": "Gefeliciteerd met je eerste bestelling.\nOm je betaling te ontvangen, dien je je bestelling te verzenden en activeren.",
				"payout-step": "Gefeliciteerd met het activeren van je eerste bestelling.\nU ontvangt vanaf nu een uitbetaling van ons in lijn met de in jouw Klarna-overeenkomst vastgelegde termijnen.\nVoor meer informatie over je eerste uitbetaling ga je naar het",
				"completed-step": "Gefeliciteerd met je eerste uitbetaling.\nVanaf nu beleef je de fijne ervaring van Klarna\nOntdek gerust de andere apps die we hieronder aanbieden."
			},
			"banner": {
				"common": {
					"branding-fe": {
						"content": "We hebben zojuist onze Branding-app gelanceerd!",
						"linkLabel": "Behoud direct de controle over je merk!"
					},
					"onSiteMessaging": {
						"title": "Maak shoppers van uw bezoekers met On-site berichten",
						"text": "Het shoppen begint al voordat uw klanten het winkelmandje bereiken. Met On-site berichten kunt u al vroeg in het aankoopproces meer nadruk leggen op Klarna's flexibele betaalmethoden en andere voordelen.",
						"cta": "Bekijk het eens"
					}
				}
			},
			"nko": {
				"dialog": {
					"title": "We introduceren een aantal spannende functies voor je bedrijf.",
					"subtitle": "En, we willen je graag beter leren kennen.",
					"explore": "Laten we aan de slag aan!",
					"name-field": "Dus, hoe wil je het liefst genoemd worden?",
					"terms-pre": "Ik ga akkoord met de",
					"terms-text": "Algemene voorwaarden"
				}
			},
			"insightsWidget": {
				"title": "Verkoop vandaag",
				"orderCount": "Aangemaakte bestellingen",
				"orderVolume": "Bestelvolume",
				"orderVolumeAverage": "Gemiddelde bestelwaarde",
				"currencyFootnote": "Weergegeven in {currency}",
				"link": "Bekijk bestellingen",
				"allStores": "Alle winkels",
				"selectAllOptionText": "Selecteer alle",
				"deselectAllOptionText": "Selectie wissen",
				"closeText": "Klaar",
				"closeTextWithSelection": "# selectie(s) toepassen",
				"elapsedTime": "Laatste bestelling {hours, plural, =0 {} =1 {een uur} other {# uren}} {minutes, plural, =0 {} =1 {een minuut} other {# minuten}} geleden ontvangen",
				"error": {
					"content": "Helaas zijn de bestelstatistieken niet goed geladen."
				},
				"relativeDate": {
					"today": "Vandaag",
					"yesterday": "Gisteren"
				},
				"weekDays": {
					"1": "Maandag",
					"2": "Dinsdag",
					"3": "Woensdag",
					"4": "Donderdag",
					"5": "Vrijdag",
					"6": "Zaterdag",
					"7": "Zondag"
				}
			},
			"blackFridayWidget": {
				"title": "Het is Black Friday week.",
				"text": "Volg je actuele verkoopstatistieken.",
				"button": "Bekijk statistieken"
			},
			"tasks": {
				"title": "To-do's",
				"error": {
					"title": "We konden de to-dolijst niet laden",
					"desc": "Herlaad de pagina, dan proberen we het opnieuw"
				},
				"empty": {
					"title": "Je bent helemaal klaar!",
					"desc": "Je hoeft nu niets te doen."
				},
				"single": {
					"products-fe:complete-recollection": "Verplicht AML-formulier dat je voor je bedrijf moet invullen om witwassen te voorkomen",
					"products-fe:resume-onboarding": "Voltooi je aanmelding",
					"settings-fe:compile-cs-info": "Voeg uw informatie over de klantenservice toe"
				},
				"aggregated": {
					"disputes-fe:unresolved": "{ count, plural, =1 {1 onopgeloste betwisting} other {# onopgeloste betwistingen}}",
					"orders-fe:expiring": "{ count, plural, =1 {1 vervallende bestelling} other {# vervallen bestellingen}}",
					"orders-fe:tocapture": "{ count, plural, =1 {1 bestelling te activeren} other {# bestellingen te activeren}}",
					"orders-fe:uncaptured": "{ count, plural, =1 {1 ongeactiveerde bestelling} other {# ongeactiveerde bestellingen}}",
					"users-fe:mid-added": "{ count, plural, =1 {1 nieuwe winkel is toegevoegd} other {# nieuwe winkels zijn toegevoegd}}",
					"users-fe:mid-invited": "{ count, plural, =1 {1 winkeluitnodiging in afwachting van actie} other {# winkeluitnodigingen in afwachting van actie}}"
				}
			}
		}
	}
};