import frontendEvents from '@klarna/frontend-events';
import { getConfig } from '../config';
import filteredEventCategories from './filteredEventCategories';
const getEnvironment = () => {
    const { region, type: environment } = getConfig('environment', {});
    return { region, environment };
};
const stateSingleton = (() => {
    let state = {};
    function getUserId() {
        return state === null || state === void 0 ? void 0 : state.userId;
    }
    function setUserId(userId) {
        state = Object.assign(Object.assign({}, state), { userId: userId });
    }
    return {
        getUserId,
        setUserId
    };
})();
const tracker = frontendEvents({
    client: 'merchant-portal',
    // TODO use git tag or commit hash
    clientVersion: '0.0.1',
    sessionId: sessionStorage.getItem('mage-app.sessionId') || ''
});
const getAdditionalEventData = () => {
    return Object.assign({ userId: stateSingleton.getUserId(), currentMid: sessionStorage.getItem('mid') || '' }, getEnvironment());
};
export const pageview = (pathname = '/', search = '', title) => {
    if (pathname === null || pathname === void 0 ? void 0 : pathname.startsWith('/boost/')) {
        tracker.event('pageview', Object.assign({ pathname, search, title }, getAdditionalEventData()));
    }
};
export const events = (eventData) => {
    if (filteredEventCategories.includes(eventData.category)) {
        tracker.event('event', Object.assign(Object.assign(Object.assign({}, eventData), getAdditionalEventData()), { sessionId: sessionStorage.getItem('mage-app.sessionId') || '' }));
    }
};
export const setUserId = stateSingleton.setUserId;
