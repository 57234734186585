module.exports = {
	"it": {
		"home-fe": {
			"boosters-home-v03": {
				"install-plugin": {
					"stepNumber": "1",
					"title": "Installa plugin",
					"description": "Seleziona la tua piattaforma di e-commerce così possiamo creare un link per accelerare l’installazione",
					"selectorLabel": "Seleziona piattaforma",
					"getPluginButtonLabel": "Scarica il plugin",
					"klarnaDocsLinkLabel": "Scopri di più sulla documentazione Klarna."
				},
				"start-setup": {
					"stepNumber": "2",
					"title": "Abilita il plugin",
					"description": "Genera e copia l’identificatore client e la chiave API, avrai bisogno di questi per abilitare il plugin."
				},
				"promotion-banner": {
					"title": "Klarna per le piattaforme",
					"subtitle": "Fai crescere la tua attività con un solo plugin: ti darà l’accesso a molteplici funzionalità per accrescere le vendite e migliorare l’esperienza di acquisto senza costi addizionali",
					"banner-cards": {
						"osm-title": "Aumenta il valore medio degli ordini con l’<span>On-site messaging</span>",
						"kec-title": "Aumenta il tasso di conversione al Checkout espresso",
						"siwk-title": "Attrai nuovi clienti con l’<span>Accesso con Klarna</span>"
					}
				},
				"configure-boost-features": {
					"stepNumber": "3",
					"title": "Posizionamento personalizzato delle funzionalità",
					"description": "Questo è un passaggio facoltativo per modificare l’aspetto delle funzionalità del tuo negozio. Per farlo, devi aver già completato la fase 2.",
					"no-credentials": "Assicurati di aver generato prima l’identificativo del client e la chiave API per poter continuare questo passaggio.",
					"apps": {
						"osm": {
							"link-label": "Personalizza l’on-site messaging",
							"description": "Personalizza l’on-site messaging per adattarlo al tuo marchio regolando il tema e selezionando i posizionamenti"
						},
						"kec": {
							"link-label": "Personalizza il Checkout espresso",
							"description": "Personalizza il pulsante del Checkout espresso perché sia adatto al tuo marchio modificando il tema e la forma del pulsante e selezionando il suo posizionamento."
						},
						"siwk": {
							"link-label": "Imposta l’accesso con Klarna",
							"description": "Seleziona l’ambito per l’accesso a Klarna e aggiungi un URL di reindirizzamento. L’ambito è costituito dalle informazioni raccolte dal cliente, mentre l’URL di reindirizzamento è quello utilizzato se i pop-up sono bloccati dai clienti."
						}
					}
				},
				"client-identifier-section": {
					"title": "Identificatore client",
					"description": "L’identificativo univoco per la configurazione",
					"manage-link": "Gestisci l’identificativo client e l’origine",
					"generate-button": "Genera",
					"tooltip": "Genera e copia l’identificativo client. Sono necessari per avviare la configurazione del plugin sul portale della piattaforma."
				},
				"api-key": {
					"warning-banner": {
						"content": "Hai già delle chiavi API attive. Prima di generarne di nuove, assicurati di aver disattivato quelle che non utilizzi."
					},
					"title": "Chiave API Klarna",
					"description": "Chiave API per la configurazione",
					"tooltip": "Ricorda che la chiave API segreta non viene più visualizzata dopo la sua generazione.",
					"generate-button": "Genera",
					"manageApiKey": "Gestisci la chiave API"
				},
				"non-platform-integration": {
					"question": "Non usi Klarna per le piattaforme?",
					"link": "Scopri come integrarti direttamente con Klarna"
				}
			},
			"boosters-home": {
				"welcome-banner": {
					"title": "Sblocca l’intera potenza di Klarna",
					"text": "Caratteristiche disegnate per trasformare i visitatori in acquirenti di successo. Migliora ogni fase dell’esperienza d’acquisto, dalla navigazione alla checkout."
				},
				"plugin-carousel": {
					"title": "Nessun codice di configurazione con un plugin",
					"overviewLink": "Vedi tutte le piattaforme",
					"directIntegrationLabel": "Non usi Klarna per le piattaforme?",
					"directIntegrationLink": "Scopri come integrarti direttamente con Klarna"
				},
				"booster-apps": {
					"title": "Maggiori informazioni",
					"kec-fe": {
						"title": "Rendi il checkout 5 volte più veloce",
						"description": "Meno clic vogliono dire meno abbandoni dell’acquisto. Aumenta il tasso di conversione e minimizza l’abbandono di carrello con il checkout espresso per le pagine prodotto e del carrello.",
						"callToAction": "Maggiori informazioni sul Checkout espresso"
					},
					"siwk-fe": {
						"title": "Accesso tramite social per lo shopping",
						"description": "Gli acquirenti possono accedere in modo rapido e sicuro con un solo clic. Otterrai dati sul profilo degli acquirenti arricchiti e fruibili.",
						"callToAction": "Ulteriori informazioni sull’accesso con Klarna"
					},
					"upstream-fe": {
						"title": "Tramuta i visitatori in clienti",
						"description": "Massimizza il tasso di conversione con messaggi promozionali dinamici attraverso l’intero percorso di acquisto.",
						"callToAction": "Maggiori informazioni sull’On-site messaging"
					},
					"branding-fe": {
						"title": "Migliora la visibilità del tuo negozio",
						"description": "Gestisci il tuo brand nella Klarna App caricando il logo del negozio, l’icona, e indicando l’URL.",
						"callToAction": "Imposta Gestione brand"
					}
				}
			},
			"boosters-plugins": {
				"title": "Configurazione plugin",
				"description": "Inizia a utilizzare i nostri plugin e assicurati di poter andare online."
			},
			"boosters-plugin": {
				"title": "Klarna per {pluginName}",
				"description": "Segui le indicazioni riportate di seguito per abilitare i pagamenti Klarna e/o le funzioni di potenziamento con la nostra configurazione senza codice.",
				"access-warning": {
					"both": "Non hai l’autorizzazione necessaria perché {selectedStoreName} ({selectedMid}) per generare le chiavi API e l’identificativo client. Contatta il tuo utente amministratore e richiedi l’accesso alle impostazioni di pagamento per procedere.",
					"client": "Non hai l’autorizzazione necessaria perché {selectedStoreName} ({selectedMid}) per generare l’identificativo client. Contatta il tuo utente amministratore e richiedi l’accesso alle impostazioni di pagamento per procedere.",
					"api": "Non hai l’autorizzazione necessaria perché {selectedStoreName} ({selectedMid}) per generare le chiavi API. Contatta il tuo utente amministratore e richiedi l’accesso alle impostazioni di pagamento per procedere."
				},
				"back": "Indietro",
				"instructions": {
					"api-key": {
						"title": "Abilita i pagamenti Klarna",
						"content": "Devi generare e copiare le chiavi API in modo da poterle utilizzare durante la configurazione.",
						"link-label": "Scopri di più sulla documentazione Klarna.",
						"generate": "Genera le chiavi API",
						"active-warning": {
							"content": "Hai già delle chiavi API attive. Prima di generarne di nuove, assicurati di aver disattivato quelle che non utilizzi.",
							"link": "Gestisci le chiavi API"
						},
						"active-warning-api-keys": {
							"content": "Hai già delle chiavi API attive. Prima di generarne di nuove, assicurati di aver disattivato quelle che non utilizzi.",
							"link": "Gestisci le chiavi API"
						}
					},
					"client-id": {
						"title": "Abilita le funzionalità di boost",
						"content": "Per aggiungere Checkout express e l’on-site messaging di Klarna devi generare e copiare l’identificativo client in modo da poterlo utilizzare durante la configurazione.",
						"generate": "Genera identificativo client",
						"manage": "Gestisci identificativo client"
					},
					"plugin-doc": {
						"title": "Configura le funzionalità di boost",
						"content": "Per completare la configurazione devi configurare il Checkout express e l'On-site messaging di Klarna nelle impostazioni della tua piattaforma."
					}
				},
				"error-messages": {
					"default": "Si è verificato un errore, riprova più tardi o contattaci",
					"fetch-data": "Si è verificato un errore durante il recupero dei dati, riprova più tardi o contattaci.",
					"create-api-key": "Si è verificato un errore durante la creazione delle tue chiavi API, riprova più tardi o contattaci.",
					"create-client-id": "Si è verificato un errore durante la creazione dell’identificativo client, riprova più tardi o contattaci."
				}
			},
			"marketing-home": {
				"description": "Ti diamo il benvenuto ai nostri prodotti di marketing. Comincia a esplorarli di seguito.",
				"essentials": {
					"title": "Marketing",
					"cta-label": "Maggiori informazioni",
					"branding-fe": {
						"description": "Imposta e gestisci il tuo brand. Fa sì che i clienti vedano il brand sotto la migliore luce in tutto l’ecosistema Klarna, compresa la nostra app smoooth."
					},
					"klarna-search-b2b-portal": {
						"description": "Inizia con il potente motore di ricerca e con lo shopping comparativo. Ottieni l’accesso a statistiche e strumenti pensati per migliorare la tua visibilità."
					}
				},
				"external-apps": {
					"title": "Apps",
					"cta-label": "Apri app"
				},
				"popOvers": {
					"searchCompare": {
						"title": "Inizia con Cerca e compara",
						"content": "Scopri qui le statistiche e gli strumenti che possono essere utili per incrementare la tua visibilità sul servizio di shopping comparativo di Klarna."
					}
				}
			},
			"no-apps": {
				"title": "Nessuna app disponibile!",
				"subtitle": "Contatta l’amministratore per ricevere aiuto nell’accedere alle app di cui hai bisogno."
			},
			"contact": {
				"title": "Serve aiuto per qualcosa?",
				"paragraph": "Qualora avessi delle domande non esitare a contattarci e saremo lieti di rispondere.",
				"label": "Contattaci"
			},
			"important-banner": {
				"title": "",
				"content": "",
				"linkLabel": ""
			},
			"two-factor-home-banner": {
				"banner-text": "Rendi il tuo account più sicuro con l’autenticazione a due fattori.",
				"banner-link": "Impostala"
			},
			"two-factor-opt-in": {
				"title": "Imposta l’autenticazione a due fattori",
				"intro-paragraph": "Per rendere il tuo account più sicuro ti incoraggiamo a impostare ora l’autenticazione a due fattori.",
				"help-link-text": "Clicca qui",
				"help-text": " per avere maggiori informazioni su come impostarla e su come protegge l’account.",
				"warning-text": "Assicurati di avere con te il tuo smartphone connesso a internet prima di procedere",
				"continue-button": "Inizia la configurazione"
			},
			"accept-invite-modal": {
				"title": "Accetta gli inviti in sospeso",
				"description": "Sei stato invitato ad accedere ai seguenti negozi. Tieni presente che potrebbero essere necessari alcuni minuti per offrire le tue scelte.",
				"accept-all-button": "Accetta tutti",
				"decline-all-button": "Rifiuta tutti",
				"save-button": "Salva",
				"error": "Si è verificato un errore, riprova più tardi!",
				"invite": {
					"accept-label": "Accetta",
					"decline-label": "Rifiuta"
				}
			},
			"capture-orientation": {
				"title": "Sei un nuovo utente Klarna?",
				"sub-title": "Segui questi passi per apprendere le basi:",
				"order-label": "Ricevere un ordine",
				"capture-label": "Acquisire un ordine",
				"payout-label": "Ottenere il pagamento",
				"capture-cta": "Acquisisci questo ordine",
				"payout-cta": "App liquidazioni",
				"dismiss-cta": "Cancella",
				"order-step": "Prima di tutto, dobbiamo attendere perché giunga un ordine da parte di uno dei clienti.",
				"capture-step": "Congratulazioni per il tuo primo ordine!\nPer ottenere il pagamento, il prossimo passo è inviare l’ordine e acquisirlo.",
				"payout-step": "Congratulazioni per aver acquisito il tuo primo ordine!\nA questo punto riceverai un pagamento da parte nostra in linea con le tempistiche definite nell’accordo con Klarna.\nPer ottenere ulteriori informazioni sul primo pagamento, vai alla pagina",
				"completed-step": "Congratulazioni per il tuo primo pagamento!\nTi diamo il benvenuto a un fantastico viaggio con Klarna.\nNon esitare ad esplorare le altre app che offriamo qui di seguito."
			},
			"banner": {
				"common": {
					"branding-fe": {
						"content": "Abbiamo appena lanciato la nostra nuova app Branding!",
						"linkLabel": "Prendi controllo sin da subito del tuo brand!"
					},
					"onSiteMessaging": {
						"title": "Trasforma i visitatori in acquirenti con l’On-site messaging",
						"text": "Gli acquisti iniziano prima che i tuoi clienti raggiungano il carrello. Grazie all’On-site messaging, potrai incrementare la consapevolezza delle opzioni di pagamento flessibili di Klarna e di altri vantaggi già nelle prime fasi del funnel di vendita.",
						"cta": "Dai un’occhiata"
					}
				}
			},
			"nko": {
				"dialog": {
					"title": "Stiamo lanciando alcune caratteristiche interessanti per la gestione del business",
					"subtitle": "e vorremmo conoscerti meglio.",
					"explore": "Avanti, partiamo!",
					"name-field": "In che modo dobbiamo chiamarti?",
					"terms-pre": "Ho letto e acconsento ai",
					"terms-text": "Termini e condizioni"
				}
			},
			"insightsWidget": {
				"title": "Vendite odierne",
				"orderCount": "Ordini creati",
				"orderVolume": "Volume vendite",
				"orderVolumeAverage": "Valore medio degli ordini",
				"currencyFootnote": "Visualizzazione in {currency}",
				"link": "Vedere ordini",
				"allStores": "Tutti i negozi",
				"selectAllOptionText": "Seleziona tutto",
				"deselectAllOptionText": "Cancella selezione",
				"closeText": "Fatto",
				"closeTextWithSelection": "Applica # selezione/i",
				"elapsedTime": "Ultimo ordine ricevuto {hours, plural, =0 {} =1 {un’ora} other {# ore}} {minutes, plural, =0 {} =1 {un minuto} other {# minuti}} fa",
				"error": {
					"content": "Purtroppo le statistiche degli ordini non sono state caricate correttamente."
				},
				"relativeDate": {
					"today": "Oggi",
					"yesterday": "Ieri"
				},
				"weekDays": {
					"1": "Lunedì",
					"2": "Martedì",
					"3": "Mercoledì",
					"4": "Giovedì",
					"5": "Venerdì",
					"6": "Sabato",
					"7": "Domenica"
				}
			},
			"blackFridayWidget": {
				"title": "Il Black Friday è qui.",
				"text": "Segui le statistiche dei tuoi ordini in tempo quasi reale.",
				"button": "Guarda le statistiche"
			},
			"tasks": {
				"title": "Da fare",
				"error": {
					"title": "Non siamo riusciti a caricare la lista “Da fare”",
					"desc": "Aggiornar la pagina, così possiamo riprovare"
				},
				"empty": {
					"title": "Va tutto bene!",
					"desc": "Adesso non ci sono attività aperte."
				},
				"single": {
					"products-fe:complete-recollection": "Modulo AML da compilare obbligatoriamente da parte della tua azienda, per prevenire il riciclaggio di denaro",
					"products-fe:resume-onboarding": "Completa il viaggio",
					"settings-fe:compile-cs-info": "Aggiungi le informazioni sul tuo servizio clienti"
				},
				"aggregated": {
					"disputes-fe:unresolved": "{ count, plural, =1 {1 contestazione non risolta} other {# contestazioni non risolte}}",
					"orders-fe:expiring": "{ count, plural, =1 {1 ordine in scadenza} other {# ordini in scadenza}}",
					"orders-fe:tocapture": "{ count, plural, =1 {1 ordine da acquisire} other {# ordini da acquisire}}",
					"orders-fe:uncaptured": "{ count, plural, =1 {1 ordine non acquisito} other {# ordini non acquisiti}}",
					"users-fe:mid-added": "{ count, plural, =1 {1 nuovo negozio è stato aggiunto} other {# nuovi negozi sono stati aggiunti}}",
					"users-fe:mid-invited": "{ count, plural, =1 {Un invito di negozio in attesa di azione} other {# inviti di negozi in attesa di azione}}"
				}
			}
		}
	}
};