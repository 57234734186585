module.exports = {
	"en": {
		"help-tool": {
			"button-text": "Help",
			"support-button": "Get Support",
			"support-page": {
				"create-ticket": {
					"title": "Get support",
					"instructions": "Submit a request below and we will get back to you within 1 business day.",
					"related-content": {
						"title": "These might help solve your issue"
					},
					"fields": {
						"mid": {
							"label": "Select store",
							"validation": "Store required"
						},
						"phoneNumber": {
							"label": "Phone number (optional)"
						},
						"topic": {
							"question": "What is your question related to?",
							"label": "Select a topic",
							"validation": "Topic required",
							"options": {
								"orders": "Orders",
								"settlements": "Settlements",
								"agreements": "Agreements",
								"others": "Others"
							}
						},
						"subject": {
							"label": "Subject",
							"validation": "Subject required"
						},
						"description": {
							"label": "Message",
							"validation": "Description required"
						},
						"attachment": {
							"label": "Add attachments",
							"button": "Upload",
							"info": "Adding screenshots will help us solve the issue faster.",
							"supportedFileTypes": "Supported file types: .png, .jpg, .pdf",
							"error": "Total files size may not exceed {maxSize} MB"
						}
					},
					"submit": "Submit request",
					"service-unavailable-error": "Oops! Something went wrong, try again in a few minutes. If this continues, please {link} for help.",
					"generic-error": "We couldn't process your request. Our systems aren't talking to each other right now. Try refreshing, or come back later. If this continues, please {link} for help.",
					"contact-link": "contact us"
				},
				"view-ticket": {
					"title": "All done!",
					"details": "We've got your request and we'll get you an answer within 1 business day. Here are your details:",
					"ticket": "Request #{ticketId}",
					"email": "Email: {email}",
					"status": "You can track the status in our Support page.",
					"track": "Track status"
				}
			},
			"header": {
				"homeLink": "Reset"
			},
			"home": {
				"greeting": "Hello {givenName}! How can we help you?",
				"topics": "What do you need help with?"
			},
			"topics": {
				"expandButton": "More"
			},
			"feedback": {
				"title": "Was this helpful?",
				"optionYes": "Yes, this was helpful",
				"optionNo": "No, I need more help",
				"positiveFeedback": "Thank you for the positive feedback, this helps us a lot to provide you with more relevant articles in the future."
			},
			"freeTextFeedback": {
				"title": "Help us improve",
				"placeholder": "Why did you not find this content useful?",
				"submitButton": "Send",
				"feedbackMessage": "Thank you for your feedback, this helps us a lot to provide you with more relevant articles in the future."
			},
			"relatedContent": {
				"title": "Related content"
			},
			"search": {
				"buttonLabel": "Search",
				"input": "Search for articles ...",
				"loading": "Loading ...",
				"results": {
					"contact": "Can't find the help you need?",
					"empty": "Sorry, no results were found for your query. Try searching for a different term.",
					"singleTopicFound": "We've found 1 topic",
					"multipleTopicsFound": "We've found {count} topics",
					"singleArticleFound": "We've found 1 article",
					"multipleArticlesFound": "We've found {count} articles"
				}
			},
			"contact": {
				"title": "Contact us",
				"channelsIntro": "Reach out to us so that we can help you.",
				"businessDetailsTitle": "Your business details",
				"businessDetailsName": "Business Name",
				"businessDetailsEmail": "Email",
				"businessDetailsMid": "Merchant ID",
				"phone": {
					"title": "Call us",
					"paragraph": "You can give us a call during business hours on weekdays. If you're located in Sweden or the United Kingdom, you can also call us on the weekend between 10am-4pm."
				},
				"email": {
					"title": "Email us",
					"paragraph": "Shoot us an email and we will get back to you as soon as we can."
				}
			},
			"errorBoundary": {
				"title": "Something went wrong",
				"message": "We are very sorry but there was an error. You can click the 'Go back' button below to go back to the help tool or 'Get Support' button to contact us.",
				"resetButton": "Go back",
				"errorId": "Error ID: {errorId}"
			},
			"tour": {
				"prev": "Previous",
				"next": "Next",
				"done": "Done"
			},
			"notAvailableMobile": {
				"title": "Not Available",
				"buttonBack": "Back",
				"paragraph": "This content is not available on your device. Try opening on a device with a larger screen size such as a desktop computer."
			},
			"shareButton": {
				"title": {
					"article": "Share a deep link to this article",
					"topic": "Share a deep link to this topic",
					"tour": "Share a deep link to this tour"
				},
				"radioItemHome": "Home App",
				"descriptionHome": "Opens help article on Merchant Portal home page",
				"radioItemCurrent": "Current Page",
				"descriptionCurrent": "Opens help article on current page"
			},
			"marketSelector": {
				"whichMarketToContact": "Which market would you like to contact?"
			}
		},
		"orientation": {
			"title": "Learn how to ..."
		},
		"helpbot": {
			"questions": {
				"whatDoYouNeedHelpWith": "Hello! What do you need support with today?",
				"selectSupportChannel": "Please select a channel to contact our support service.",
				"thatHelped": "Thank you for the positive feedback, this helps us a lot to provide you with more relevant help in the future. 😀"
			},
			"orders": {
				"orderManagement": {
					"title": "Order Management",
					"createOrderPos": {
						"question": "How do I create an order through Point of Sales?",
						"answer": "1. Open Point of Sales\n2. Input the article name, product price and quantity.\n3. Share the generated payment link with your customer via SMS, email or by having the customer scan the QR code displayed on your checkout screen.\n4. The customer can now complete the purchase from their device.\n"
					},
					"extendExpiryReservation": {
						"question": "How do I extend the expiry date on a reservation?",
						"answer": "1. Go to the Order manager and select one or multiple orders you want to extend the expiry date for.\n2. Click on Extend expiry date.\n3. Confirm your choice.\n\nOrders are extended according to the reservation time settings for your store (usually 28 days). If an order has expired, it can no longer be extended.\n"
					},
					"howToCaptureOrders": {
						"question": "How do I capture orders?",
						"partially": {
							"question": "I want to partially capture an order",
							"answer": "If it's only possible to ship part of a customer order, you can partially capture the order by order lines or amount.\n\nCapture order lines\n1. Go to the order in the Order Manager and select the items you currently have in stock from the order page. Remember to edit order lines to adjust the quantities if needed.\n2. Click capture.\n\nCapture amount\n1. Go to the order in the Order Manager. You can capture an amount by clicking the \"Capture all\" button. Here you enter the desired amount.\n2. Click Capture.\n\nRemember to complete the order when possible, either by capturing remaining items/amount once available to be shipped - or by cancelling the remaining order if you won't be able to ship the goods.\"\n"
						},
						"fully": {
							"question": "I want to fully capture orders",
							"answer": "1. Go to the Order Manager and click on the Klarna reference number to open the order.\n2. Capture the full order using one of the following steps:\n- Clicking the \"Capture\" button\n- Selecting the order lines and clicking \"Capture\".\n- Clicking \"Capture all\" and entering the full amount of the order.\n3. While capturing the order, you can download the packing slip to include it in your shipment.\n\nMake sure to capture orders before their expiry date (usually 28 days from order creation).\nYou can capture multiple orders selecting them in the orders list and clicking on \"Full capture\" if you want to speed things up.\n"
						}
					},
					"canIUpdateCustomerInfo": {
						"question": "Can I update customer information?",
						"answer": "You can never change the recipient of an order.\nBut it is possible to change the shipping and billing address for uncaptured or partially captured orders.\nHowever, if Klarna rejects the new address because it's not connected to the customer, the billing address may still be changed.\n"
					},
					"extendDueDate": {
						"question": "How do I extend the due date on an order?",
						"answer": "You can extend the due date for an order by clicking \"Extend due date\" on a captured order. Extending the due date of an order comes with a fee that will be specified before you finalize the action."
					},
					"refundOrder": {
						"question": "How do I refund an order?",
						"answer": "1. Go to the Order Manager and locate the you wish to refund.\n2. Choose the refund method: either select items to refund and then click \"Refund\" or click \"Refund\" first to enter the amount you want to refund.\n3. The refunded amount will be deducted from your next payout, and the customer will receive it within 5 business days.\n\nThis only applies for Captured orders. For uncaptured orders, cancel them if you won't be shipping any items. If only part of the order was captured and shipped, release the uncaptured portion.\n"
					},
					"cancelOrder": {
						"question": "How do I cancel an order?",
						"answer": "1. Go to the Order Manager and locate the order you wish to cancel.\n2. Click on \"Cancel Order\". This action also informs your customer that the order won't be shipped.\n\nYou can recreate canceled orders if you need to. You can't cancel captured orders, but you can refund them.\n"
					},
					"unableToTakeActions": {
						"question": "Why am I unable to take actions on an order?",
						"directPartner": {
							"question": "I have an agreement directly with Klarna",
							"answer": "User permissions determine what users can do in the Partner Portal. There are two different types of user permissions:\nAdmin - users with full access; for example, an admin can access and edit all functionality in Partner Portal.\nSection-specific - Non-admin users have access to one or more specific sections in the Partner Portal.\n\nThis may be the cause for why you are unable to action orders. There are different access roles relating to the Order Manager, talk to your administrator to determine which access role is most suitable for the actions you need to perform.\n"
						},
						"distributionPartner": {
							"question": "I have an agreement with Klarna through a Distribution Partner",
							"answer": "Any actions you would like to take on an order can only be done through the platform provided by the Distributon Partner"
						}
					}
				},
				"orderModification": "Order Modification and Issues",
				"orderProcessing": "Order Processing",
				"refundIssues": {
					"title": "Refund Issues",
					"refundedOrderWhenCustomerFunds": {
						"question": "I have refunded an order, when will my customer receive their funds?",
						"answer": "A customer should receive their funds within 5 business days from when the order was refunded. This may vary depending on which payment method the customer has chosen. If the customer has questions about this, please refer them to Klarna's Customer Support."
					}
				},
				"reservations": {
					"title": "Reservations",
					"whyUnableToExtendReservation": {
						"question": "Why am I unable to extend the reservation time on an order?",
						"answer": "There are specific circumstances where extending the expiry date of an order is not possible:\n- If the order has already expired, you'll need to recreate it. To do this, go to the order page, click \"Copy\" and select \"Create new order\".\n- If the order was paid by card, the customer must place a new order, as expiry dates for these orders cannot be extended.\n"
					}
				},
				"paymentsForMyOrders": {
					"title": "Payments for my orders",
					"whenWillIGetPaid": {
						"question": "When will I get paid for an order?",
						"answer": "To receive payment for an order, you must first capture it. The payout amount, after deducting fees, returns, and other charges, will be sent to your bank account based on your payout schedule. You can review your payout setup in the Settlements section."
					},
					"refundOrderReceivedPayment": {
						"question": "What happens if I refund an order that I have already received funds for?",
						"answer": "Any amount you refund will be deducted from your next scheduled payout."
					},
					"referenceNotShowUp": {
						"question": "Order number or reference number does not show up in report.",
						"answer": "If you cannot find a payment for a specific order:\n- Verify the accuracy of the order or reference number.\n- Ensure that the order is captured.\n- Check if the order falls within the report's capture period.\n- Note that orders cancelled before settlement will not appear in the report.\n"
					},
					"gotPaidSpecificOrder": {
						"question": "How do I see if I have gotten paid for a specific order?",
						"answer": "In Partner Portal's order manager, search for the order ensuring the order or reference number is accurate. Below the reference number, check if the order is captured. If captured, the status will show as pending or paid out. For paid out orders, click 'See settlement' to view the corresponding report."
					}
				}
			},
			"disputes": {
				"disputeManagement": "Dispute Management",
				"contactInfoAndNotifications": "Contact Information and Notification Settings",
				"timingAndDeadlines": "Dispute Timing and Deadlines",
				"resolutionProcess": "Dispute Resolution Process",
				"merchantDisputesApp": {
					"title": "Merchant Disputes App",
					"respondToDispute": {
						"question": "How do I know if I have to respond to a dispute request?",
						"answer": "Check the 'To-Do' Section in your Partner Portal Homepage to see if any disputes require your response."
					},
					"overviewDisputesCustomer": {
						"question": "Where can I find an overview of all disputes raised by customers?",
						"answer": "You can find all raised disputes in the Disputes section, under the 'All disputes' page, immediately after the dispute has been raised.\n\nPlease note that disputes are available in the 'All disputes' page for up to 180 days after closing.\n"
					},
					"overviewDisputesResponse": {
						"question": "Where can I find an overview of all disputes that require a response?",
						"answer": "You can find all disputes that need a response in the Disputes section in the 'Open disputes' page under 'Response required'."
					},
					"updateContactInformation": {
						"question": "How can I update my contact information for dispute reminder emails?",
						"answer": "1. Go to the Disputes section\n2. Click into the 'Email & Dispute Settings' page\n3. Update your contact information in the 'Email & notification' tab.\n\nPlease note that you can set different contact information for 'disputes', 'unauthorized purchases' & 'high-risk orders'. Additionally, you can set the frequency of notifications in the tab 'Notifications'.\n"
					},
					"updateCustomerServiceOnKlarnaApp": {
						"question": "How can I update my Customer Service information in the Klarna App?",
						"answer": "1. Go to 'Payment settings'\n2. Click 'Customer service information'\n3. Select 'Market' (if you have market-specific support)\n\nYou can enter the Customer contact information as well as the return policy to be displayed in the Klarna App for a smoother customer experience.\n\nPlease note that you need full access rights for the Partner Portal to view this information.\n"
					},
					"improveDisputesPerformance": {
						"question": "How can I improve my dispute performance?",
						"answer": "To get tips & tricks on how to improve you dispute performance, please go to the 'Returns & Disputes Improvement Guide'. You can access it via the 'Statistics' page by clicking the 'Get insights' button at the top right of the page.\n\nPlease note that you need full access rights for the Partner Portal to view this information.\n"
					},
					"overviewDisputesPerformance": {
						"question": "Where can I see an overview of my disputes performance?",
						"answer": "To view detailed statistics about disputes, please go to the 'Statistics' page in the Disputes App. This section provides an overview of monthly disputes, outcomes, costs, and losses related to disputes.\n\nPlease mote that you need full access rights for the Partner Portal to view this information.\n"
					}
				},
				"disputesLifecycle": {
					"title": "Disputes Lifecycle",
					"whenCanSeeDisputes": {
						"question": "When am I able to see disputes being raised by customers?",
						"answer": "You are able to see all disputes raised in the Disputes App on the 'All disputes' page immediately after they have been raised."
					},
					"howLongToResolve": {
						"question": "How much time do I have to resolve the dispute with the customer directly?",
						"answer": "You have 21 days from the day a dispute being raised to resolve it with the customer. If you and the customer have not resolved the dispute within 21 days of it being raised, Klarna will step in to support the resolution process between both parties."
					},
					"howMuchTimeToRefund": {
						"question": "How much time do I have to refund returns?",
						"answer": "You have 21 days from the return date to process a refund before Klarna will step in to support the resolution process between both parties. With that said, it is always best to refund returns as soon as you receive them."
					},
					"howToResolve": {
						"question": "How can I resolve disputes & returns?",
						"answer": "There are several ways to resolve a dispute or return.\n\n- Process the return or refund as soon as you receive it / agree on a solution.\n- Set a Chargeback Threshold under 'Email & dispute settings'\n- Be proactive by checking 'All disputes'\n- Accept loss\n"
					},
					"whenToRespond": {
						"question": "When do I have to actively defend/respond to disputes?",
						"answer": "If you and the customer can't reach a solution on your own within the given time (21 days), Klarna will step in to support the resolution process between both parties. If the dispute is valid, Klarna’s support team will reach out to you via the Disputes App with a request for more information.\n\nIt is at this point you will be asked to defend/ respond to a dispute.\n"
					},
					"howMuchTimeToRespond": {
						"question": "How much time do I have to respond to a dispute request?",
						"answer": "The Deadline for the first request is 14 days for all dispute reasons, with the exception of Unauthorized Purchases (7 days). For each follow-up request* sent to you (with a maximum of 5 requests) you have a deadline of 7 days. Should you need to add to your response you have 60 minutes from submission to add, for example, information or attachments. Once the deadline has passed you will no longer be able to defend the case.\n\n*Special rules may apply depending on partnership agreement.\n"
					},
					"howLongToSeeDispute": {
						"question": "How long can I see a dispute in the Dispute App after it is resolved?",
						"answer": "A dispute is available in the 'All disputes' page for up to 180 days after closing."
					},
					"whenToGetCharged": {
						"question": "When do I get charged with a dispute fee?",
						"answer": "You can incur a dispute fee if:\n- You are at fault for the dispute.\n- You fail to respond before the deadline.\n- You issue a refund after Klarna intervenes to support in the resolution process.\n\nThe fee is applied retroactively after the dispute outcome is determined. You are not charged if the dispute resolves in your favor, or if it is cancelled or rejected.\n"
					}
				},
				"disputesHandling": {
					"title": "Disputes Handling",
					"whatIsDispute": {
						"question": "What information do I receive with a dispute request?",
						"answer": "The dispute request includes:\n\n- Reason: Category of dispute, e.g., returns, goods not received.\n- Merchant: Relevant if multiple merchant IDs (MIDs) are registered with Klarna.\n- Dispute Date: When the customer raised the dispute.\n- Deadline: The deadline by which you must respond to defend the case.\n- Disputed Products: Item(s) being disputed.\n- Order Details: Includes 'merchant reference 1 & 2', 'order date', 'disputed amount'.\n"
					},
					"howManyTimesDefend": {
						"question": "How many times can I defend one dispute request?",
						"answer": "You get a maximum of five (5) requests to defend your dispute, but most often it doesn't require more than one or two."
					},
					"doIReceiveNotification": {
						"question": "Do I receive notifications when I have to respond to disputes?",
						"answer": "If you have a pending dispute request, it appears in multiple locations:\n\n- In the 'To-Do' section on your Partner Portal Homepage.\n- As a notification in the Dispute section on the 'Open disputes' page, indicating the number of disputes needing a response.\n\nYou can also opt to receive an email for each dispute by adjusting your settings in 'Email & dispute settings' to 'Email notification for each dispute'.\n"
					},
					"howToAvoidDisputesFees": {
						"question": "How can I avoid dispute fees?",
						"answer": "To avoid dispute fees:\n- Process returns or refunds promptly.\n- Set a Chargeback Threshold under 'Email & dispute settings'.\n- Regularly check 'All disputes'.\n- Respond to disputes with relevant information.\n- Reply before the deadline expires.\n"
					}
				}
			},
			"settlements": {
				"payoutNotFound": {
					"title": "Payout for specific order not found",
					"orderNumberNotFound": {
						"question": "Order number or reference number does not show up in report",
						"answer": "If you cannot find a payment for a specific order:\n- Verify the accuracy of the order or reference number.\n- Ensure that the order is captured.\n- Check if the order falls within the report's capture period.\n- Note that orders cancelled before settlement will not appear in the report.\n"
					},
					"checkIfPaidForOrder": {
						"question": "How do I see if I have gotten paid for a specific order?",
						"answer": "In Partner Portal's order manager, search for the order ensuring the order or reference number is accurate. Below the reference number, check if the order is captured. If captured, the status will show as pending or paid out. For paid out orders, click 'See settlement' to view the corresponding report."
					}
				},
				"payouts": {
					"title": "Payouts",
					"whenIsNext": {
						"question": "When is my next payout?",
						"answer": null
					},
					"whyCalculationNotMatch": {
						"question": "Why does my own calculation not match with the payouts receieved?",
						"answer": "Under the merchant contract, fees like transaction and late return fees are deducted. Therefore, your calculations might differ from Klarna's settlements. To see clearly outlined deductions, we recommend opening the PDF version of the settlement report."
					},
					"whenGetPaid": {
						"question": "When do I get paid?",
						"answer": null
					},
					"missingPayout": {
						"question": "I'm missing my payout",
						"answer": "Before reaching out to our support channel. Consider that missing payouts can be due to the following:\n\n- Klarna sends the payment during the same day as the report is generated, it may still take a few days before the money appears in your bank account.\n- You have not captured any orders for this capture period.\n- You've recently updated your Bank Account details with Klarna which may have caused the payment to go to your former bank account. This can happen if the change isn't processed before the payment is executed.\n- The order number or reference number you are searching for is incorrect, make sure to review the order or reference number.\n- Your payments are being held by Klarna. You should have been informed about the hold separately through an email, these emails are most commonly sent to an administrator\n"
					},
					"howIsPayoutCalculated": {
						"question": "How is my payout calculated?",
						"answer": "The payout is calculated from orders captured within the capture period minus fees, returns, and other charges. A settlement report provides a detailed breakdown of these amounts.\nIf refunds exceed sales in a capture period, the excess amount will be deducted from the next payout.\n"
					}
				},
				"howPayoutsWork": {
					"question": "How do the payouts work?",
					"answer": "The Settlements section provides an overview of how payouts work, including payment schedules, capture periods, and payout dates. You can download transaction reports for each payout in PDF or CSV formats, and view or download details for specific payments by clicking on their payment references."
				},
				"payoutSchedule": {
					"title": "Payout schedule",
					"whyDelay": {
						"question": "Why is there a Payout delay?",
						"answer": "The main purpose is to prevent money for invoices being sent back and forth between Klarna and you, and also to cover cases where you would refund a captured order."
					},
					"changePayoutDelay": {
						"question": "I want to change my payout delay",
						"answer": "We have internal checks as well as general criteria that need to be met. These general criteria include:\n- You must be fully onboarded with Klarna\n- Your first order date is exceeding 100 days\n- Your business needs to be older than 6 months.\n\nKlarna also requires information from an admin about...\n- The desired new payment delay and why the changed payment delay is necessary\n- Which merchant ID (MID) the change concerns\nAdditional criteria may apply, with decisions made on an individual basis.\n"
					}
				},
				"generatingReports": {
					"title": "Generating reports",
					"howToSeeReports": {
						"question": "How do I see the reports for specific periods?",
						"answer": "You can customize your reports to cater for specific time periods using the Custom reports section in the Settlements section."
					},
					"convertCsvToExcel2": {
						"answer": "Follow these steps\n1. Open Excel, click on 'File' > 'New Workbook'.\n"
					},
					"convertCsvToExcel": {
						"question": "How do I convert a CSV report into an Excel spreadsheet?",
						"answer": "1. Open Excel, click on 'File' > 'New Workbook'.\n2. Go to the 'Data' tab and select 'From Text'.\n3. Choose the desired .CSV file and click 'Get Data'.\n4. In the Text Import Wizard, choose 'Delimited', then click 'Next'.\n5. Under 'Delimiters', select 'Semicolon' and set 'Text qualifier' to 'none'. Click 'Finish'.\n6. In the 'Import Data' dialog box, click 'OK' to place the data starting in cell A1.\n\nNote: CSV reports in the Merchant Portal use a semicolon (;) as the separator. Consider checking your spreadsheet software to learn how to adjust the default separator.\n"
					}
				},
				"payoutAndFeeCalculation": {
					"title": "Payout and fee calculation",
					"howAreFeesCalculated": {
						"question": "How are fees calculated?",
						"answer": "Fees deducted from Klarna payouts are outlined in your merchant contract, with additional fees such as those for late returns detailed in the 'Fee' section of your payout/settlement reports.\n\nTo customize fee descriptions in your reports:\n1. Navigate to Settlement reports > Configure reports > Add new configuration.\n2. For CSV reports, select 'Detailed transaction type' under Transaction list.\n3. For PDF reports, select 'Include order details' in the Layout and content settings.\n\nEnsure you adjust settings for each merchant ID and channel (SFTP, Merchant Portal, API) separately.\n"
					},
					"howIsPayoutCalculated": {
						"question": "How is my payout calculated?",
						"answer": "The payout is calculated from orders captured within the capture period minus fees, returns, and other charges. A settlement report provides a detailed breakdown of these amounts.\n\nIf refunds exceed sales in a capture period, the excess amount will be deducted from the next payout.\n"
					}
				}
			},
			"options": {
				"payout": "Payout",
				"orders": "Orders",
				"settlements": "Settlements",
				"disputes": "Disputes",
				"otherIssue": "Other Issues",
				"yes": "Yes",
				"no": "No",
				"partially": "Partially",
				"iNeedMoreHelp": "I need more help",
				"thatHelped": "That helped",
				"contactSupportChannel": "Contact support channels"
			},
			"button": {
				"label": "Get Support"
			},
			"contact": {
				"chat": "Chat with an agent",
				"ticket": "Submit a ticket",
				"times": {
					"minutes": "Waiting time: {time} minute(s)",
					"hours": "Waiting time: {time} hour(s)",
					"days": "Waiting time: {time} day(s)"
				}
			}
		}
	}
};