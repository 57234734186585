module.exports = {
	"nl": {
		"core": {
			"alert": {
				"message": "Er is een fout opgetreden! Probeer het opnieuw.",
				"correlationIdLabel": "Correlation ID:"
			},
			"header": {
				"title": "Merchant Portal"
			},
			"usermenu": {
				"language": "Taal",
				"country": "Land",
				"timezone": "Tijdzone",
				"logout": "Uitloggen",
				"profile": "Profiel",
				"changeProfile": "Bewerken",
				"tooltip": "Jouw profiel"
			},
			"appsmenu": {
				"defaultTooltip": "Jouw apps",
				"initialTooltip": "Vind je apps hier",
				"newAppsTooltip": "We hebben nieuwe apps voor je!"
			},
			"two-factor-opt-in": {
				"title": "Stel two-factor-authentication in",
				"intro-paragraph": "Twee-factorauthenticatie (2FA) is niet ingeschakeld voor uw Webshop portaal. Sinds <b>16 januari 2023</b> is 2FA verplicht voor alle gebruikers om toegang te krijgen tot het Webshop portaal. Voor meer informatie over het belang van 2FA, hoe u het instelt en antwoorden op veelgestelde vragen, kunt u <faqLink>hier terecht</faqLink>.",
				"help-link-text": "Klik hier",
				"help-text": " voor meer informatie over het instellen en hoe het uw account beschermt.",
				"warning-text": "Zorg ervoor dat u uw smartphone en toegang tot internet hebt voordat u doorgaat",
				"continue-button": "Start de instelling"
			},
			"kuasPrompter": {
				"title": "U selecteerde",
				"content": "{count} MID's",
				"contentTooltip": "Klik om webwinkel-ID’s naar het klembord te kopiëren",
				"button": "Wijzig geselecteerde MID's"
			},
			"notification": {
				"title": "Mededeling",
				"content": {
					"uptodate": "U bent helemaal up-to-date!"
				}
			},
			"languages": {
				"cs": "Tsjechisch",
				"da": "Deens",
				"de": "Duits",
				"el": "Grieks",
				"en": "Engels",
				"en_GB": "Engels (GB)",
				"en_US": "Engels (VS)",
				"es": "Spaans",
				"fi": "Fins",
				"fr": "Frans",
				"it": "Italiaans",
				"nl": "Nederlands",
				"no": "Noors",
				"pl": "Pools",
				"pt": "Portuguees",
				"sv": "Zweeds"
			},
			"countries": {
				"at": "Oostenrijk",
				"au": "Australië",
				"be": "België",
				"ca": "Canada",
				"ch": "Zwitserland",
				"cz": "Tsjechië",
				"de": "Duitsland",
				"dk": "Denemarken",
				"ee": "Estland",
				"es": "Spanje",
				"fi": "Finland",
				"fr": "Frankrijk",
				"gb": "Verenigd Koninkrijk",
				"gr": "Griekenland",
				"ie": "Ierland",
				"it": "Italië",
				"mx": "Mexico",
				"nl": "Nederland",
				"no": "Noorwegen",
				"se": "Zweden",
				"us": "Verenigde Staten",
				"nz": "Nieuw-Caledonië",
				"pl": "Polen",
				"pt": "Portugal",
				"ro": "Roemenië"
			},
			"selectLanguage": {
				"title": "Selecteer je taal",
				"paragraph": "Dit wordt de standaardtaal",
				"submit": "Oké"
			},
			"selectCountry": {
				"title": "Selecteer je land",
				"paragraph": "Dit wordt je standaardland.",
				"submit": "Oké"
			},
			"updateProfile": {
				"title": "Profiel bijwerken",
				"submit": "Oké",
				"emailLabel": "E-mailadres",
				"firstNameLabel": "Voornaam",
				"lastNameLabel": "Achternaam",
				"resetPasswordLabel": "Wachtwoord",
				"resetPasswordSelectedValue": "Reset",
				"resetPasswordSuccessLabel": "E-mail om te resetten is verstuurd.",
				"resetPasswordErrorLabel": "Fout bij verzenden van e-mail. Probeer het later nog eens.",
				"error": "Gegevensupdate mislukt",
				"changeCookiePolicyLabel": "Cookiebeleid",
				"changeCookiePolicySelectedValue": "Wijzigen",
				"securitySectionTitle": "Beveiliging",
				"preferencesSectionTitle": "Voorkeuren",
				"mfaLabel": "Two-factor authentication",
				"mfaDescription": "Vereist inloggen met een eenmalig wachtwoord dat korte tijd geldig is. Wij raden aan om dit in te schakelen.",
				"twoFactor": {
					"reset": "Uw 2FA wijzigen",
					"isEnabled": "Actief",
					"isDisabled": "Gedeactiveerd",
					"title": "Two-factor authentication (2FA)",
					"description": "Hiermee kunnen gebruikers inloggen met de extra beveiliging van een beperkt geldig, eenmalig wachtwoord.",
					"tooltip": "Zorg ervoor dat u op zijn minst de toestemming {permission} hebt voor de {app}-app voor elke winkel waartoe de gebruiker toegang heeft om de twee-factorauthenticatie aan te zetten.",
					"confirmationDialog": {
						"closeButton": "Sluiten",
						"confirmationButton": "Ja, ga verder",
						"cancelButton": "Annuleren",
						"resetSuccess": {
							"title": "2FA-apparaatreset geslaagd.",
							"description": "De volgende keer dat de gebruiker inlogt, moet diegene een nieuw apparaat instellen voor 2FA."
						},
						"resetConfirmation": {
							"title": "Weet u zeker dat je het 2FA-apparaat van deze gebruiker wilt resetten?",
							"description": "Als u doorgaat, reset u het apparaat dat gebruikt wordt om 2FA-codes te genereren voor deze gebruiker. De volgende keer dat de gebruiker inlogt, moet diegene een nieuw apparaat instellen voor 2FA."
						}
					}
				}
			},
			"footer": {
				"copyright": {
					"eu": "Copyright © 2005-{currentYear} Klarna Bank AB (publ). Alle rechten voorbehouden",
					"oc": "Copyright © 2005-{currentYear} Klarna Australië Pty Ltd, ABN # 82 635 912 579",
					"ap": "Copyright © 2005-{currentYear} Klarna Australië Pty Ltd, ABN # 82 635 912 579",
					"us": "Copyright © 2005-{currentYear} Klarna Inc. NMLS #1353190, 629 N. High Street, Derde Etage, Columbus, OH 43215"
				},
				"terms": "Algemene voorwaarden"
			},
			"accessDenied": {
				"title": "Voor deze pagina zijn aanvullende rechten nodig",
				"message": "Neem contact op met je Payment Service Provider of met Klarna"
			},
			"midSelector": {
				"label": "Webwinkel"
			},
			"storeSelector": {
				"label": "Webwinkel"
			},
			"storesSelector": {
				"closeText": "Klaar",
				"closeTextWithSelection": "# selectie(s) toepassen",
				"deselectAllOptionText": "Selectie wissen",
				"emptyStateText": "Geen winkels gevonden",
				"label": "Winkels",
				"searchFieldLabel": "Zoeken op winkel",
				"selectAllOptionText": "Selecteer alle winkels",
				"selectedAllText": "Alle winkels",
				"selectedText": "# winkels"
			},
			"pagination": {
				"ofLabel": "van"
			},
			"singleDatePicker": {
				"selectDate": "Selecteer datum"
			},
			"dateRangePicker": {
				"startLabel": "Begindatum",
				"endLabel": "Einddatum",
				"months": {
					"january": "Januari",
					"february": "Februari",
					"march": "Maart",
					"april": "April",
					"may": "Mei",
					"june": "Juni",
					"july": "Juli",
					"august": "Augustus",
					"september": "September",
					"october": "Oktober",
					"november": "November",
					"december": "December"
				},
				"weekDaysShort": {
					"monday": "M",
					"tuesday": "D",
					"wednesday": "W",
					"thursday": "D",
					"friday": "V",
					"saturday": "Z",
					"sunday": "Z"
				}
			},
			"regionSelector": {
				"noStores": "Geen winkels toegewezen",
				"andMore": "en meer",
				"regions": {
					"eu": "Europa ",
					"us": "Noord-Amerika",
					"ap": "Azië-Pacific"
				}
			},
			"regionSelectorBar": {
				"modal": {
					"title": "Wijzig regio",
					"description": "Selecteer een regio met bijbehorende winkels om relevante gegevens weer te geven.",
					"buttonText": "Bevestigen"
				},
				"selectedRegion": "Geselecteerde regio",
				"changeButton": "Wijzigen"
			},
			"appIcon": {
				"newLabel": "NIEUW"
			},
			"appCategory": {
				"dayToDay": "Van dag-tot-dag",
				"nko": "Klarna Online",
				"tools": "Hulpmiddelen",
				"preferences": "Voorkeuren"
			},
			"apps": {
				"account-information-fe": {
					"title": "Accountgegevens",
					"description": "Vind uw bedrijfs- en bankgegevens en de overeenkomsten die u hebt ondertekend."
				},
				"home-fe": {
					"title": "Betalingen home"
				},
				"boosters-home-fe": {
					"title": "Begin nu"
				},
				"marketing-home-fe": {
					"title": "Marketing home"
				},
				"nko-app": {
					"title": "Klarna online apps"
				},
				"orders-fe": {
					"title": "Bestellingenbeheerder",
					"description": "Zoek en beheer je orders van Klarna.",
					"nkoLinkText": "Bekijk oudere orders"
				},
				"nko-orders": {
					"title": "Bestellingen (KO)",
					"description": "Zoek en beheer je orders geplaatst via Klarna Online."
				},
				"settlements-fe": {
					"title": "Afrekeningen",
					"description": "Bekijk je transacties en genereer rapporten voor afstemming.",
					"nkoLinkText": "Bekijk oudere verslagen"
				},
				"nko-settlements": {
					"title": "Afrekeningen (KO)",
					"description": "Vind verslagen van bestellingen en uitbetalingen van Klarna Online."
				},
				"logs-app-fe": {
					"title": "Ontwikkelaarslogs",
					"description": "API-logbestanden zoeken om integratieproblemen op te lossen."
				},
				"users-fe": {
					"title": "Gebruikersbeheerder",
					"description": "Nodig nieuwe gebruikers uit en beheer bestaande gebruikers in het Portal."
				},
				"settings-fe": {
					"title": "Betalingsinstellingen",
					"description": ""
				},
				"nko-settings": {
					"title": "Instellingen (KO)",
					"description": "Beheer de instellingen voor je webwinkel, verslagen en contactgegevens."
				},
				"seller-fe": {
					"title": "In de winkel",
					"description": "Maak nieuwe bestellingen aan met de Klarna-oplossing voor in de winkel."
				},
				"products-fe": {
					"title": "Aanmelding",
					"description": "Stel uw account in om betalingen mogelijk te maken."
				},
				"upstream-fe": {
					"title": "Berichtgeving op Website",
					"description": "Verkoop meer dankzij het aangepaste en persoonlijke financieringsaanbod."
				},
				"lending-fe": {
					"title": "Business Financing",
					"description": "Bedrijfsfinanciering simpel gemaakt."
				},
				"branding-fe": {
					"title": "Brandmanager",
					"description": "Behoud de controle over de presentatie van je merk binnen Klarna."
				},
				"disputes-fe": {
					"title": "Betwistingen",
					"description": "Behandel je betwistingen."
				},
				"logistics-fe": {
					"title": "Verzendintegraties",
					"description": "Beheer al je logistiek op één plek."
				},
				"developers": {
					"title": "Ontwikkelaars"
				},
				"klarna-docs": {
					"title": "Klarna Docs"
				},
				"support": {
					"title": "Ondersteuning",
					"description": "Bekijk en beheer je ondersteuningsverzoeken."
				},
				"integration-guides-fe": {
					"title": "Integratiehandleidingen",
					"description": ""
				},
				"klarna-search-b2b-portal": {
					"title": "Zoeken en vergelijken",
					"description": "Begin met Klarna's krachtige zoekmachine en vergelijkingen. Krijg toegang tot statistieken en tools die zijn ontworpen om uw zichtbaarheid te verbeteren."
				},
				"siwk-fe": {
					"title": "Aanmelden met Klarna",
					"description": "Beheer uw Aanmelden met Klarna-integratie."
				},
				"kec-fe": {
					"title": "Express Checkout",
					"description": "Beheer uw Express Checkout-integratie."
				},
				"partner-self-service": {
					"title": "Winkelmanager",
					"description": "Beheer uw Klarna Store.",
					"nkoLinkText": "Bekijk en beheer de instellingen voor uw winkel"
				}
			},
			"ui": {
				"tabs": {
					"home": "Betalingen",
					"growth": "Groeimarketing",
					"boosters": "Conversieboosters",
					"badge": "Nieuw"
				},
				"buttons": {
					"backButton": "Terug"
				}
			},
			"errorBoundary": {
				"contact": "Probeer de pagina opnieuw te laden. {br}als de fout aanhoudt, neem dan contact op met  <supportLink>Webshopondersteuning</supportLink> en vermeld het id {errorId}.",
				"errorDescription": "Fout-ID - {errorId}",
				"reloadButton": "Herladen",
				"title": "Er is helaas een fout opgetreden"
			},
			"maintenance": {
				"pageTitle": "Onderhoud",
				"title": "Het Merchant Portal ondergaat momenteel gepland onderhoud.",
				"summary": "We zijn snel bij je terug",
				"scheduledSummary": "We zijn terug om {until}"
			},
			"errorTemplates": {
				"errorDescription": "Fout: {code}",
				"backButton": "Terug",
				"reloadButton": "Herladen",
				"default": {
					"errorDescription": "Fout-ID - {errorId}",
					"title": "Er is helaas een fout opgetreden",
					"contact": "Probeer de pagina opnieuw te laden. Als de fout aanhoudt, neem dan contact op met <supportLink>Webshopondersteuning</supportLink> en vermeld het id {errorId}."
				},
				"internalServerError": {
					"title": "Oeps, er is iets fout gegaan",
					"message": "Vanwege een of ander probleem aan onze kant, konden we je verzoek niet uitvoeren. Probeer het opnieuw of kom later nog eens terug."
				},
				"notFound": {
					"title": "Hier is niets te zien!",
					"message": "Deze pagina bestaat niet of u heeft een verkeerd adres in uw browser getypt. Controleer het adres en probeer het opnieuw."
				},
				"forbidden": {
					"title": "U heeft geen toegang tot deze pagina",
					"message": "Uw Webshop-ID heeft geen toegang tot deze pagina. Als u denkt dat dit een fout is, neem dan contact op met de Webshopondersteuning of met de beheerder van uw bedrijfsaccount."
				}
			},
			"codeSnippet": {
				"clipboardButtonLabel": "Kopieer naar klembord",
				"clipboardButtonLabelSuccess": "Gekopieerd!"
			}
		},
		"blackFriday": {
			"v1": {
				"title": "Black Friday",
				"intro": "Een van de belangrijkste omzetdagen komt eraan. Tijdens Black Friday tonen wij u snel statistieken van uw verkopen. Laten we ons in de tussentijd voorbereiden op succes!",
				"countdown": "Begint over #dd #hu #mm #ss…",
				"box1": {
					"title": "3,5 x meer uitgaven*",
					"text": "Vorig jaar gaven Klarna-shoppers* gemiddeld 3,5 x meer uit op Black Friday dan op welke andere dag van het jaar dan ook.",
					"footnote": "*Gemiddeld over alle Klarna-webshopcategorieën en -regio's."
				},
				"box2": {
					"title": "2,5 x meer verkoopvolume*",
					"text": "Vorig jaar genereerden Klarna-webshops 2,5 x meer verkoopvolume op Black Friday dan op welke andere dag van het jaar dan ook.",
					"footnote": "*Gemiddeld over alle Klarna-webshopcategorieën en -regio's."
				},
				"sectionTitle": "Laat uw Black Friday verkopen stijgen",
				"marketing": {
					"title": "Promoot uw bedrijf voor en op Black Friday",
					"text": "Verwijs meer shoppers naar uw winkel tijdens het Black Friday winkelseizoen met deze gratis te gebruiken hulpmiddelen voor sociale media en e-mailcampagnes."
				},
				"branding": {
					"title": "Val op in de Klarna app",
					"text": "Elke maand gebruiken 12 miljoen shoppers de Klarna app. Zorg ervoor dat u deze Black Friday opvalt in de app door hier in de Merchant Portal uw merkinformatie toe te voegen en bij te werken."
				},
				"cta": "Begin nu",
				"close": "Afsluiten"
			},
			"v2": {
				"title": "Black Friday week",
				"orderVolume": "Bestelvolume",
				"orderCountTitle": "Aangemaakte bestellingen",
				"orderCount": "{count} bestellingen",
				"box": {
					"title": "Wil je je conversie verder verhogen?",
					"subtitle1": "On-site messaging",
					"subtitle2": "Reclameberichten die informeren.",
					"text": "Onze On-site messaging-tool helpt je verkopen en gemiddelde bestelwaarde te verhogen met reclameberichten. Laat je klanten weten wat hun betaalmogelijkheden zijn zodra ze je site bezoeken, tot aan het afrekenen.",
					"cta": "Begin nu"
				},
				"selector": {
					"done": "Klaar",
					"applySelection": "# selectie(s) toepassen",
					"selectAll": "Selecteer alle",
					"allOptions": "Alle opties geselecteerd",
					"someOptions": "# opties geselecteerd",
					"storeMultiSelectLabel": "Kies # winkels",
					"allStores": "Alle winkels"
				},
				"currencyLabel": "Kies valuta",
				"storeLabel": "Kies winkel"
			},
			"v3": {
				"title": "Black Friday week",
				"text": "Dit zijn uw verkopen tijdens een van de belangrijkste verkoopweken van het jaar.",
				"summaryTitle": "Samenvatting",
				"orderVolume": "Totaal volume",
				"orderCount": "Totaal bestellingen",
				"summaryRange": "23-30 November",
				"comparisonLastWeek": "vergeleken met dezelfde dag vorige week",
				"tabs": {
					"monday": "Maandag",
					"tuesday": "Dinsdag",
					"wednesday": "Woensdag",
					"thursday": "Donderdag",
					"blackFriday": "Black Friday",
					"saturday": "Zaterdag",
					"sunday": "Zondag",
					"cyberMonday": "Cyber Monday"
				}
			}
		},
		"mpSatSurvey": {
			"marble": {
				"topbarButtonLabel": "Feedback op onze nieuwe look",
				"step1": {
					"welcome": "Wat vindt u van onze nieuwe look-and-feel?",
					"satisfied": "Zeer tevreden",
					"unsatisfied": "Zeer ontevreden"
				},
				"step2": {
					"textAreaLabel": "Overig (uw mening)",
					"textAreaMaxLength": "Maximale lengte bereikt",
					"submit": "Verzenden",
					"content": {
						"titlePositive": "Wat bevalt u het meest?",
						"titleNegative": "Wat bevalt u het minst?",
						"tags": {
							"color": "Kleur en stijl",
							"navigation": "Navigatie",
							"names": "Naam van pagina’s",
							"structure": "Structuur van pagina’s"
						}
					}
				},
				"step3": {
					"title": "Dank u wel!",
					"description": "We stellen uw feedback op prijs."
				}
			},
			"buttonLabel": "Geef feedback",
			"errorMessage": "Er is een fout opgetreden bij het versturen van uw feedback.",
			"retry": "Opnieuw proberen",
			"step1": {
				"welcome1": "Hoe tevreden bent u met het",
				"welcome2": "Merchant Portal?",
				"emojiSelector": {
					"angry": "Erg ontevreden",
					"unhappy": "Enigszins ontevreden",
					"neutral": "Neutraal",
					"happy": "Tevreden",
					"love": "Erg tevreden"
				},
				"remindMeText": "Wilt u uw mening niet delen?",
				"remindMeLink": "Herinner me later aan deze vragenlijst.",
				"dontShowAgainLink": "Dit niet meer weergeven."
			},
			"step2": {
				"textAreaLabel": "Overig (uw mening)",
				"textAreaMaxLength": "Maximale lengte bereikt",
				"submit": "Indienen",
				"content": {
					"negative": {
						"title": "Wat vervelend om te horen.",
						"description": "Welke aspecten van het Webshop portaal kunnen we verbeteren? (Selecteer alles wat van toepassing is)",
						"tags": {
							"loading": "Laadtijd",
							"menu": "Organisatie van het menu",
							"mobile": "Mobiele versie",
							"filter": "Gegevens filteren",
							"complexity": "Doeltreffend acties uitvoeren"
						}
					},
					"positive": {
						"title": "Wat goed om te horen!",
						"description": "Toch zien we ruimte voor verbetering. Wat zou uw ervaring met het Merchant Portal nog beter maken?",
						"tags": {
							"views": "Aangepaste weergaven",
							"looks": "Kijk en voel",
							"tours": "Rondleidingen",
							"shortcuts": "Snelkoppelingen voor taken"
						}
					}
				}
			},
			"step3": {
				"title": "Hartelijk dank! Nog één ding.",
				"content1": "Wij nodigen u graag uit voor een gesprek, zodat wij meer te weten kunnen komen over uw ervaringen met het gebruik van ons Merchant Portal.",
				"content2": "Wilt u hieraan deelnemen?",
				"content3": "Als u akkoord gaat, nemen wij contact met u op via uw geregistreerde e-mail om dit te regelen.",
				"yes": "Ja, graag",
				"no": "Nee, bedankt"
			},
			"step4": {
				"yesTitle": "Geweldig! We nemen contact met u op.",
				"noTitle": "Begrepen. Hartelijk dank!",
				"content1": "Dank u dat u de tijd nam om deze vragen te beantwoorden.",
				"content2": "U kunt deze popup nu sluiten."
			}
		},
		"boost": {
			"integrationTypeModal": {
				"title": "Hoe wilt u {productName} instellen?",
				"labels": {
					"plugin": "Instellen met een plug-in",
					"web": "Instellen met Web SDK"
				}
			}
		}
	}
};