module.exports = {
	"it": {
		"core": {
			"alert": {
				"message": "Si è verificato un problema. Prova di nuovo.",
				"correlationIdLabel": "ID di correlazione:"
			},
			"header": {
				"title": "Merchant Portal"
			},
			"usermenu": {
				"language": "Seleziona",
				"country": "Paese",
				"timezone": "Fuso orario",
				"logout": "Logout",
				"profile": "Profilo",
				"changeProfile": "Modifica",
				"tooltip": "Il tuo profilo"
			},
			"appsmenu": {
				"defaultTooltip": "Le tue app",
				"initialTooltip": "Trova qui le tue app.",
				"newAppsTooltip": "Abbiamo nuove app per te!"
			},
			"two-factor-opt-in": {
				"title": "Imposta l’autenticazione a due fattori",
				"intro-paragraph": "Non hai l’autenticazione a due fattori (2FA) attivata nel tuo account sul Merchant Portal. A partire dal <b>16 gennaio 2023</b>la 2FA sarà obbligatoria per tutti gli utenti per poter accedere al Portale Merchant. Puoi leggere di più sull’importanza della 2FA, come impostarla e trovare risposte alle domande più frequenti <faqLink>qui</faqLink>.",
				"help-link-text": "Clicca qui",
				"help-text": " per avere maggiori informazioni su come impostarla e su come protegge l’account.",
				"warning-text": "Assicurati di avere con te il tuo smartphone connesso a internet prima di procedere",
				"continue-button": "Inizia la configurazione"
			},
			"kuasPrompter": {
				"title": "Hai selezionato",
				"content": "{count} MIDs",
				"contentTooltip": "Clicca per copiare i merchant ID negli appunti",
				"button": "Cambia MIDs selezionati"
			},
			"notification": {
				"title": "Annuncio",
				"content": {
					"uptodate": "È tutto a posto!"
				}
			},
			"languages": {
				"cs": "Ceco",
				"da": "Danese",
				"de": "Tedesco",
				"el": "Greco",
				"en": "Inglese",
				"en_GB": "English (GB)",
				"en_US": "English (US)",
				"es": "Spagnolo",
				"fi": "Finlandese",
				"fr": "Francese",
				"it": "Italiano",
				"nl": "Olandese",
				"no": "Norvegese",
				"pl": "Polacco",
				"pt": "Portoghese",
				"sv": "Svedese"
			},
			"countries": {
				"at": "Austria",
				"au": "Australia",
				"be": "Belgio",
				"ca": "Canada",
				"ch": "Svizzera",
				"cz": "Repubblica Ceca",
				"de": "Germania",
				"dk": "Danimarca",
				"ee": "Estonia",
				"es": "Spagna",
				"fi": "Finalndia",
				"fr": "Francia",
				"gb": "Gran Bretagna",
				"gr": "Grecia",
				"ie": "Irlanda",
				"it": "Italia",
				"mx": "Messico",
				"nl": "Paesi Bassi",
				"no": "Norvegia",
				"se": "Svezia",
				"us": "Stati Uniti",
				"nz": "Nuova Zelanda",
				"pl": "Polonia",
				"pt": "Portogallo",
				"ro": "Romania"
			},
			"selectLanguage": {
				"title": "Seleziona la lingua",
				"paragraph": "Questa sarà la lingua predefinita.",
				"submit": "OK"
			},
			"selectCountry": {
				"title": "Seleziona il paese",
				"paragraph": "Questo sarà il paese predefinito.",
				"submit": "OK"
			},
			"updateProfile": {
				"title": "Aggiorna profilo",
				"submit": "OK",
				"emailLabel": "Indirizzo email",
				"firstNameLabel": "Nome",
				"lastNameLabel": "Cognome",
				"resetPasswordLabel": "Password",
				"resetPasswordSelectedValue": "Ripristina",
				"resetPasswordSuccessLabel": "Email di ripristino inviata correttamente.",
				"resetPasswordErrorLabel": "Si è verificato un errore nell’invio dell’email. Riprova più tardi.",
				"error": "Aggiornamento dei dettagli non riuscito",
				"changeCookiePolicyLabel": "Politica sui cookie",
				"changeCookiePolicySelectedValue": "Modifica",
				"securitySectionTitle": "Sicurezza",
				"preferencesSectionTitle": "Preferenze",
				"mfaLabel": "Autenticazione a due fattori",
				"mfaDescription": "Richiede l’accesso con una password unica basata sul tempo. Raccomandiamo di abilitarla.",
				"twoFactor": {
					"reset": "Modifica la tua 2FA",
					"isEnabled": "Attivato",
					"isDisabled": "Disattivata",
					"title": "Autenticazione a due fattori (2FA)",
					"description": "Questo permette l’accesso dell’utente con la sicurezza aggiunta di una password monouso a tempo.",
					"tooltip": "Assicurati di avere almeno l’autorizzazione {permission} per l’app {app} per ogni store a cui l’utente ha accesso per poter modificare l’impostazione dell’autenticazione a due fattori.",
					"confirmationDialog": {
						"closeButton": "Chiudi",
						"confirmationButton": "Sì, continua",
						"cancelButton": "Annulla",
						"resetSuccess": {
							"title": "Il ripristino del dispositivo di autenticazione a due fattori è riuscito.",
							"description": "Al prossimo accesso, l'utente dovrà impostare un nuovo dispositivo per l’autenticazione a due fattori."
						},
						"resetConfirmation": {
							"title": "Vuoi proseguire con il ripristino del dispositivo per la 2FA di questo utente?",
							"description": "Continuando, ripristinerai il dispositivo utilizzato per generare codici 2FA per questo utente. Al prossimo accesso, l'utente dovrà impostare un nuovo dispositivo per l’autenticazione a due fattori."
						}
					}
				}
			},
			"footer": {
				"copyright": {
					"eu": "Copyright © 2005-{currentYear} Klarna Bank AB (publ). Tutti i diritti riservati",
					"oc": "Copyright © 2005-{currentYear} Klarna Australia Pty Ltd, ABN # 82 635 912 579",
					"ap": "Copyright © 2005-{currentYear} Klarna Australia Pty Ltd, ABN # 82 635 912 579",
					"us": "Copyright © 2005-{currentYear} Klarna Inc. NMLS #1353190, 629 N. High Street, Third Floor, Columbus, OH 43215"
				},
				"terms": "Termini e condizioni"
			},
			"accessDenied": {
				"title": "Per questa pagina è necessario un accesso aggiuntivo.",
				"message": "Contatta il tuo amministratore o Klarna con qualsiasi domanda."
			},
			"midSelector": {
				"label": "Negozio"
			},
			"storeSelector": {
				"label": "Negozio"
			},
			"storesSelector": {
				"closeText": "Fatto",
				"closeTextWithSelection": "Applica # selezione/i",
				"deselectAllOptionText": "Cancella selezione",
				"emptyStateText": "Nessun negozio trovato",
				"label": "Negozi",
				"searchFieldLabel": "Cerca per negozio",
				"selectAllOptionText": "Seleziona tutti i negozi",
				"selectedAllText": "Tutti i negozi",
				"selectedText": "# negozi"
			},
			"pagination": {
				"ofLabel": "di"
			},
			"singleDatePicker": {
				"selectDate": "Seleziona una data"
			},
			"dateRangePicker": {
				"startLabel": "Data d’inizio",
				"endLabel": "Data di termine",
				"months": {
					"january": "Gennaio",
					"february": "Febbraio",
					"march": "Marzo",
					"april": "Aprile",
					"may": "Maggio",
					"june": "Giugno",
					"july": "Luglio",
					"august": "Agosto",
					"september": "Settembre",
					"october": "Ottobre",
					"november": "Novembre",
					"december": "Dicembre"
				},
				"weekDaysShort": {
					"monday": "L",
					"tuesday": "M",
					"wednesday": "M",
					"thursday": "G",
					"friday": "V",
					"saturday": "S",
					"sunday": "D"
				}
			},
			"regionSelector": {
				"noStores": "Nessun negozio assegnato",
				"andMore": "e altro",
				"regions": {
					"eu": "Europa",
					"us": "Nord America",
					"ap": "Asia Pacifico"
				}
			},
			"regionSelectorBar": {
				"modal": {
					"title": "Cambia area geografica",
					"description": "Seleziona un’area geografica con negozi associati per visualizzare dati rilevanti.",
					"buttonText": "Conferma"
				},
				"selectedRegion": "Seleziona area geografica",
				"changeButton": "Modifica"
			},
			"appIcon": {
				"newLabel": "NOVITÀ"
			},
			"appCategory": {
				"dayToDay": "Collegamenti rapidi",
				"nko": "Klarna Online",
				"tools": "Strumenti",
				"preferences": "Preferenze"
			},
			"apps": {
				"account-information-fe": {
					"title": "Informazioni sul conto",
					"description": "Trova i tuoi dati aziendali e bancari e gli accordi che hai firmato."
				},
				"home-fe": {
					"title": "Home pagamenti"
				},
				"boosters-home-fe": {
					"title": "Inzia"
				},
				"marketing-home-fe": {
					"title": "Home marketing"
				},
				"nko-app": {
					"title": "App online Klarna"
				},
				"orders-fe": {
					"title": "Gestione ordini",
					"description": "Cerca e gestisci gli ordini ricevuti tramite Klarna.",
					"nkoLinkText": "Accedi ai vecchi ordini"
				},
				"nko-orders": {
					"title": "Ordini (KO)",
					"description": "Cerca e gestisci gli ordini ricevuti tramite Klarna Online."
				},
				"settlements-fe": {
					"title": "Liquidazioni",
					"description": "Genera report personalizzati.",
					"nkoLinkText": "Accedi a report più vecchi"
				},
				"nko-settlements": {
					"title": "Liquidazioni (KO)",
					"description": "Trova report di ordini e pagamenti da Klarna Online."
				},
				"logs-app-fe": {
					"title": "Log per sviluppatori",
					"description": "Ricerca log API per risolvere i problemi di integrazione."
				},
				"users-fe": {
					"title": "Gestione utenti",
					"description": "Invita nuovi utenti e gestisci utenti già esistenti nel Portale."
				},
				"settings-fe": {
					"title": "Impostazioni di pagamento",
					"description": ""
				},
				"nko-settings": {
					"title": "Impostazioni (KO)",
					"description": "Gestisci le impostazioni del tuo negozio, i rapporti e i dettagli di contatto."
				},
				"seller-fe": {
					"title": "In negozio",
					"description": "Crea nuovi ordini con la soluzione Klarna In‑store."
				},
				"products-fe": {
					"title": "Applicazione",
					"description": "Imposta il tuo account per abilitare i pagamenti."
				},
				"upstream-fe": {
					"title": "On-site messaging",
					"description": "Aumenta le vendite, offrendo opzioni di pagamento su misura e personalizzate."
				},
				"lending-fe": {
					"title": "Business Financing",
					"description": "Finanziamento aziendale."
				},
				"branding-fe": {
					"title": "Gestione brand",
					"description": "Potenzia l'immagine del tuo brand nell'ecosistema Klarna."
				},
				"disputes-fe": {
					"title": "Contestazioni",
					"description": "Gestisci le contestazioni."
				},
				"logistics-fe": {
					"title": "Integrazioni sulle spedizioni",
					"description": "Gestisci tutta la logistica in un solo posto."
				},
				"developers": {
					"title": "Sviluppatori"
				},
				"klarna-docs": {
					"title": "Klarna Docs"
				},
				"support": {
					"title": "Supporto",
					"description": "Controlla e gestisci le tue richieste di supporto."
				},
				"integration-guides-fe": {
					"title": "Guide sull’integrazione",
					"description": ""
				},
				"klarna-search-b2b-portal": {
					"title": "Cerca e compara",
					"description": "Inizia con il potente motore di ricerca e con lo shopping comparativo. Ottieni l’accesso a statistiche e strumenti pensati per migliorare la tua visibilità."
				},
				"siwk-fe": {
					"title": "Accedi con Klarna",
					"description": "gestisci il tuo accesso con l’integrazione Klarna"
				},
				"kec-fe": {
					"title": "Express Checkout",
					"description": "Gestisci il tuo accesso con l’integrazione Checkout espresso."
				},
				"partner-self-service": {
					"title": "Manger del negozio",
					"description": "Gestisci il tuo negozio Klarna.",
					"nkoLinkText": "Visualizza e gestisci le impostazioni del tuo negozio"
				}
			},
			"ui": {
				"tabs": {
					"home": "Pagamenti",
					"growth": "Marketing di crescita",
					"boosters": "Promotori di conversione",
					"badge": "Nuovo"
				},
				"buttons": {
					"backButton": "Indietro"
				}
			},
			"errorBoundary": {
				"contact": "Prova a ricaricare la pagina. {br}Se l’errore persiste, contatta il <supportLink>Supporto Commercianti</supportLink>fornendo l’identificativo {errorId}.",
				"errorDescription": "ID errore - {errorId}",
				"reloadButton": "Aggiorna",
				"title": "Purtroppo si è verificato un errore"
			},
			"maintenance": {
				"pageTitle": "Manutenzione",
				"title": "Il Merchant Portal è attualmente in fase di manutenzione programmata",
				"summary": "Torneremo presto online",
				"scheduledSummary": "Torneremo alle {until}"
			},
			"errorTemplates": {
				"errorDescription": "Errore: {code}",
				"backButton": "Indietro",
				"reloadButton": "Aggiorna",
				"default": {
					"errorDescription": "ID errore - {errorId}",
					"title": "Purtroppo si è verificato un errore",
					"contact": "Prova a ricaricare la pagina. Se l’errore persiste contatta il <supportLink>Supporto aziende</supportLink> fornendo l’id {errorId}."
				},
				"internalServerError": {
					"title": "Spiacenti, si è verificato un problema",
					"message": "Si è verificato un errore e non è stato possibile eseguire la tua richiesta. Riprova o torna più tardi."
				},
				"notFound": {
					"title": "Non c’è nulla qui!",
					"message": "Questa pagina non esiste o hai digitato un indirizzo sbagliato nel tuo browser. Controllalo e riprova."
				},
				"forbidden": {
					"title": "Non hai accesso a questa pagina",
					"message": "Il tuo ID commerciante non ha accesso a questa pagina. Se ritieni che si tratti di un errore, contatta il Supporto aziende o l’amministratore dell’account della tua azienda."
				}
			},
			"codeSnippet": {
				"clipboardButtonLabel": "Copia negli appunti",
				"clipboardButtonLabelSuccess": "Copiato!"
			}
		},
		"blackFriday": {
			"v1": {
				"title": "Black Friday",
				"intro": "Uno dei giorni più importanti dell'anno è quasi alle porte. Durante il Black Friday, saremo qui per offrirti le statistiche di vendita in tempo reale. Nel frattempo, prepariamoci al successo!",
				"countdown": "Comincia tra #dg #ho #mm #ss…",
				"box1": {
					"title": "Spesa 3,5 volte maggiore*",
					"text": "L’anno scorso, la spesa effettuata tramite Klarna* è stata in media 3,5 volte maggiore nel Black Friday che in qualsiasi altro giorno dell’anno.",
					"footnote": "*Media relativa a tutte le regioni e le categorie di negozi partner Klarna."
				},
				"box2": {
					"title": "Volume delle vendite 2,5 volte maggiore*",
					"text": "L’anno scorso, i negozi partner Klarna hanno generato un volume delle vendite 2,5 volte maggiore durante il Black Friday rispetto a qualsiasi altro giorno dell’anno.",
					"footnote": "*Media relativa a tutte le categorie di commercianti Klarna e regioni."
				},
				"sectionTitle": "Aumentiamo le tue vendite nel Black Friday",
				"marketing": {
					"title": "Pubblicizza la tua attività prima e durante il Black Friday",
					"text": "Attira un maggior numero di clienti nel tuo negozio durante il Black Friday con queste risorse gratuite per i social media e le campagne email."
				},
				"branding": {
					"title": "Renditi visibile nella Klarna app",
					"text": "12 milioni di persone utilizzano la Klarna app ogni mese. Fatti notare nell’app durante il Black Friday aggiungendo e aggiornando le informazioni sul tuo brand qui nel Merchant Portal."
				},
				"cta": "Inzia",
				"close": "Chiudi"
			},
			"v2": {
				"title": "Settimana del Black Friday",
				"orderVolume": "Volume vendite",
				"orderCountTitle": "Ordini creati",
				"orderCount": "{count} ordini",
				"box": {
					"title": "Vuoi aumentare ulteriormente il tasso di conversione?",
					"subtitle1": "On-site messaging",
					"subtitle2": "Messaggistica promozionale.",
					"text": "Il nostro strumento di On-site messaging contribuisce ad aumentare le vendite e il valore medio degli ordini con messaggi promozionali. Fai sapere ai clienti quali sono le loro opzioni di pagamento dal momento in cui arrivano sul tuo sito e in tutte le fasi di acquisto.",
					"cta": "Inziare"
				},
				"selector": {
					"done": "Fatto",
					"applySelection": "Applica # selezione/i",
					"selectAll": "Seleziona tutto",
					"allOptions": "Tutte le opzioni selezionate",
					"someOptions": "# opzioni selezionate",
					"storeMultiSelectLabel": "Scegli # negozi",
					"allStores": "Tutti i negozi"
				},
				"currencyLabel": "Scegli valuta",
				"storeLabel": "Scegli negozio"
			},
			"v3": {
				"title": "Black Friday week",
				"text": "Queste sono le vendite durante una delle più importanti settimane dell'anno",
				"summaryTitle": "Riepilogo",
				"orderVolume": "Volume totale",
				"orderCount": "Totale ordini",
				"summaryRange": "23-30 November",
				"comparisonLastWeek": "rispetto allo stesso giorno della settimana scorsa",
				"tabs": {
					"monday": "Lunedì",
					"tuesday": "Martedì",
					"wednesday": "Mercoledì",
					"thursday": "Giovedì",
					"blackFriday": "Black Friday",
					"saturday": "Sabato",
					"sunday": "Domenica",
					"cyberMonday": "Cyber Monday"
				}
			}
		},
		"mpSatSurvey": {
			"marble": {
				"topbarButtonLabel": "Un feedback sul nostro nuovo look",
				"step1": {
					"welcome": "Ti piace il nostro nuovo design?",
					"satisfied": "Molto soddisfacente",
					"unsatisfied": "Molto insoddisfacente"
				},
				"step2": {
					"textAreaLabel": "Altro (la tua opinione)",
					"textAreaMaxLength": "Lunghezza massima raggiunta",
					"submit": "Invia",
					"content": {
						"titlePositive": "Cosa ti piace di più?",
						"titleNegative": "Cosa ti piace meno?",
						"tags": {
							"color": "Colore e stile",
							"navigation": "Navigazione",
							"names": "Nome delle pagine",
							"structure": "Struttura delle pagine"
						}
					}
				},
				"step3": {
					"title": "Grazie!",
					"description": "Apprezziamo il tuo feedback."
				}
			},
			"buttonLabel": "Lascia feedback",
			"errorMessage": "Si è verificato un errore nell’invio del tuo feedback.",
			"retry": "Riprova",
			"step1": {
				"welcome1": "Quanto puoi ritenerti soddisfatto/a del",
				"welcome2": "Portale Merchant?",
				"emojiSelector": {
					"angry": "Molto insoddisfatto/a",
					"unhappy": "Un po’ insoddisfatto/a",
					"neutral": "Né soddisfatto/a né insoddisfatto/a",
					"happy": "Soddisfatto/a",
					"love": "Lo adoro"
				},
				"remindMeText": "Non vuoi condividere la tua opinione?",
				"remindMeLink": "Ricordami in seguito di completare il sondaggio",
				"dontShowAgainLink": "Non mostrare in futuro."
			},
			"step2": {
				"textAreaLabel": "Altro (la tua opinione)",
				"textAreaMaxLength": "Lunghezza massima raggiunta",
				"submit": "Invia",
				"content": {
					"negative": {
						"title": "Siamo spiacenti di apprendere della tua esperienza negativa.",
						"description": "Quali aspetti del Portale Merchant dovremmo migliorare? (Seleziona tutte le risposte applicabili)",
						"tags": {
							"loading": "Tempo di caricamento",
							"menu": "Organizzazione del menu",
							"mobile": "Versione mobile",
							"filter": "Filtrare i dati",
							"complexity": "Efficienza delle azioni svolte"
						}
					},
					"positive": {
						"title": "Molto bene!",
						"description": "Vogliamo migliorare sempre. Quali aspetti potrebbero la tua esperienza nel Portale Merchant ancora migliore?",
						"tags": {
							"views": "Viste personalizzate",
							"looks": "Aspetto e sensazione",
							"tours": "Tour guidati",
							"shortcuts": "Collegamenti rapidi"
						}
					}
				}
			},
			"step3": {
				"title": "Grazie! Un’ultima cosa.",
				"content1": "Vorremmo invitarti a un'intervista per poter conoscere meglio la tua esperienza nell'uso del nostro Portale Merchant.",
				"content2": "Ti piacerebbe partecipare?",
				"content3": "Se dai il tuo consenso, ti contatteremo all’indirizzo email che hai registrato.",
				"yes": "Sì grazie",
				"no": "No, grazie"
			},
			"step4": {
				"yesTitle": "Ottimo! Ci metteremo in contatto.",
				"noTitle": "Ho capito. Grazie!",
				"content1": "Grazie per il tempo che hai dedicato a rispondere a queste domande.",
				"content2": "Ora puoi chiudere questo popup."
			}
		},
		"boost": {
			"integrationTypeModal": {
				"title": "Come vuoi impostare {productName}?",
				"labels": {
					"plugin": "Impostalo con un plugin",
					"web": "Impostalo con Web SDK"
				}
			}
		}
	}
};