module.exports = {
	"fr": {
		"credentials": {
			"addOriginModal": {
				"buttonPrimaryText": "Enregistrer",
				"buttonSecondaryText": "Annuler",
				"description": "Veuillez enregistrer tout domaine d'origine que vous prévoyez d'utiliser avec le Web SDK. Si vous avez besoin d'enregistrer plus d'un domaine, vous pourrez le faire ultérieurement.",
				"errorMessage": "Une erreur s'est produite. Veuillez réessayer ultérieurement ou contacter l'assistance si le problème persiste.",
				"inputLabel": "Entrez le reste de votre URL",
				"title": "Enregistrer l'origine autorisée"
			},
			"clientKeyBlock": {
				"mainText": "Votre identifiant client est configuré directement dans le code avec le domaine d'origine que vous avez fourni.",
				"secondaryText": "Pour plus de réglages, veuillez suivre le lien vers la",
				"linkDescription": "page des identifiants clients",
				"linkLabel": "Fermer"
			},
			"clientKeyField": {
				"label": "Identifiant du client",
				"defaultErrorMessage": "L'identifiant du client est manquant"
			},
			"copyToClipboardButton": {
				"label": "Copier",
				"labelOnCopy": "Copié"
			},
			"originField": {
				"label": "Domaine d'origine",
				"tooltipMainText": "Pour enregistrer davantage de domaines d'origine ou gérer ceux existants, rendez-vous sur la",
				"tooltipLinkLabel": "page des identifiants clients"
			},
			"originInputField": {
				"label": "Entrez le reste de votre URL",
				"validationError": "Veuillez saisir une URL de domaine valide",
				"invalidProtocolError": "L'URL doit commencer par http:// ou https://"
			},
			"addApiKeySuccessModal": {
				"title": "",
				"description": "Veuillez enregistrer les identifiants suivants sur votre appareil ou un autre lieu sécurisé.",
				"warning": "Le mot de passe sera révélé uniquement à ce moment et disparaîtra une fois que vous aurez fermé cette fenêtre.",
				"checkbox": "J'ai enregistré les identifiants sur mon appareil.",
				"download": "Télécharger au format .txt"
			},
			"common": {
				"password": "Mot de passe",
				"username": "Nom d'utilisateur",
				"close": "Fermer"
			}
		}
	}
};