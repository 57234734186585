module.exports = {
	"no": {
		"home-fe": {
			"boosters-home-v03": {
				"install-plugin": {
					"stepNumber": "1",
					"title": "Installer plugin",
					"description": "Velg din e-handelsplattform slik at vi kan opprette en lenke for å øke hastigheten på installasjonen",
					"selectorLabel": "Velg plattform",
					"getPluginButtonLabel": "Få plugin",
					"klarnaDocsLinkLabel": "Lær mer på Klarna docs"
				},
				"start-setup": {
					"stepNumber": "2",
					"title": "Aktiver plugin",
					"description": "Generer og kopier kundeidentifikatoren og API-nøkkelen. Du trenger disse for å aktivere pluginen."
				},
				"promotion-banner": {
					"title": "Klarna for plattformer",
					"subtitle": "Utvid virksomheten din med én enkel plugin, som gir deg tilgang til flere funksjoner for økt salg og forbedrede handleopplevelser uten ekstra kostnad",
					"banner-cards": {
						"osm-title": "Øk gjennomsnittlig ordreverdi med <span>On-site messaging</span>",
						"kec-title": "Få høyere konvertering med Express Checkout",
						"siwk-title": "Tiltrekk nye kunder med <span>Logg inn med Klarna</span>"
					}
				},
				"configure-boost-features": {
					"stepNumber": "3",
					"title": "Skreddersy plasseringer av funksjoner",
					"description": "Dette er et valgfritt trinn for å endre hvordan funksjonene ser ut i butikken din. For å gjøre dette bør du allerede ha fullført trinn 2.",
					"no-credentials": "Sørg først for å generere kundeidentifikatoren og API-nøkkelen for å fortsette dette trinnet.",
					"apps": {
						"osm": {
							"link-label": "Skreddersy On-site messaging",
							"description": "Skreddersy On-site messaging for å passe merkevaren din ved å justere temaet og velge plasseringer"
						},
						"kec": {
							"link-label": "Skreddersy Express Checkout",
							"description": "Skreddersy Express Checkout-knappen slik at den passer til merkevaren din ved å justere tema, form og plassering av knappen"
						},
						"siwk": {
							"link-label": "Konfigurer Logg inn med Klarna",
							"description": "Velg omfanget for innlogging med Klarna og legg til en omdirigerings-URL. Omfanget er informasjonen som samles inn fra kunden din, og omdirigerings-URL-en er det som brukes hvis popup-vinduer blokkeres av kunder"
						}
					}
				},
				"client-identifier-section": {
					"title": "Kundeidentifikator",
					"description": "Den unike identifikatoren for oppsett",
					"manage-link": "Administrer kundeidentifikator og opprinnelse",
					"generate-button": "Generer",
					"tooltip": "Generer og kopier kundeidentifikatoren. Du trenger disse for å starte plugin-oppsettet på plattformens portal."
				},
				"api-key": {
					"warning-banner": {
						"content": "Du har allerede aktive API-nøkler. Før du genererer nye, sørg for at du har deaktivert noen du ikke bruker."
					},
					"title": "Klarna API-nøkkel",
					"description": "API-nøkkel for oppsett",
					"tooltip": "Vær oppmerksom på at vi ikke viser din hemmelige API-nøkkel igjen etter at du har generert den.",
					"generate-button": "Generer",
					"manageApiKey": "Administrer API-nøkkel"
				},
				"non-platform-integration": {
					"question": "Bruker du ikke Klarna for plattformer?",
					"link": "Se hvordan du integrerer direkte med Klarna"
				}
			},
			"boosters-home": {
				"welcome-banner": {
					"title": "Lås opp Klarnas fulle kraft",
					"text": "Funksjoner utviklet for å gjøre besøkende til supershoppere. Forbedre hvert trinn i handleopplevelsen - fra surfing til  betaling."
				},
				"plugin-carousel": {
					"title": "Ingen kodeoppsett med en plugin",
					"overviewLink": "Se alle plattformer",
					"directIntegrationLabel": "Bruker du ikke Klarna for plattformer?",
					"directIntegrationLink": "Se hvordan du integrerer direkte med Klarna"
				},
				"booster-apps": {
					"title": "Lær mer",
					"kec-fe": {
						"title": "Gjør betalingen 5 ganger raskere",
						"description": "Færre klikk betyr mindre frafall. Oppnå konverteringer og minimer forlatelse av handlekurv med Express Checkout på produkt- og handlekurvsider.",
						"callToAction": "Mer om Express Checkout"
					},
					"siwk-fe": {
						"title": "Sosial innlogging for shopping",
						"description": "Kunder får et raskt og sikkert innloggingsalternativ med ett klikk. Du får forbedrede, handlingsrettede kundeprofildata.",
						"callToAction": "Mer om innlogging med Klarna"
					},
					"upstream-fe": {
						"title": "Gjør besøkende til shoppere",
						"description": "Maksimer konverteringen med dynamiske reklamemeldinger gjennom hele shoppingreisen.",
						"callToAction": "Mer om On-site messaging"
					},
					"branding-fe": {
						"title": "Forbedre butikkens synlighet",
						"description": "Administrer merkevaren din i Klarna-appen ved å laste opp logo, ikon og URL for butikken.",
						"callToAction": "Sett opp Brand Manager"
					}
				}
			},
			"boosters-plugins": {
				"title": "Plugin-oppsett",
				"description": "Kom i gang med våre plugins og sørg for at du er klar til å gå live."
			},
			"boosters-plugin": {
				"title": "Klarna for {pluginName}",
				"description": "Utfør handlingene nedenfor for å aktivere Klarna betalinger og/eller boost-funksjoner uten kodeoppsett.",
				"access-warning": {
					"both": "Du har ikke tillatelse for {selectedStoreName} ({selectedMid}) til å generere API-nøkler og kundeidentifikator. Kontakt administratoren din og be om tilgang til betalingsinnstillingene for å fortsette.",
					"client": "Du har ikke tillatelse for {selectedStoreName} ({selectedMid}) til å generere kundeidentifikator. Kontakt administratoren din og be om tilgang til betalingsinnstillingene for å fortsette.",
					"api": "Du har ikke tillatelse for {selectedStoreName} ({selectedMid}) til å generere API-nøkler. Kontakt administratoren din og be om tilgang til betalingsinnstillingene for å fortsette."
				},
				"back": "Tilbake",
				"instructions": {
					"api-key": {
						"title": "Aktiver Klarna betaling",
						"content": "Du må generere og kopiere API-nøkler slik at du kan bruke dem under oppsettet.",
						"link-label": "Lær mer på Klarna dokumenter.",
						"generate": "Generer API-nøkler",
						"active-warning": {
							"content": "Du har allerede aktive API-nøkler. Før du genererer nye, sørg for at du har deaktivert noen du ikke bruker.",
							"link": "Administrer API-nøkler"
						},
						"active-warning-api-keys": {
							"content": "Du har allerede aktive API-nøkler. Før du genererer nye, sørg for at du har deaktivert noen du ikke bruker.",
							"link": "Administrer API-nøkler"
						}
					},
					"client-id": {
						"title": "Aktiver boost-funksjoner",
						"content": "For å legge til Klarna Express Checkout og On-site messaging må du generere og kopiere kundeidentifikatoren slik at du kan bruke dem under oppsett.",
						"generate": "Generer kundeidentifikator",
						"manage": "Administrer kundeidentifikator"
					},
					"plugin-doc": {
						"title": "Konfigurer boost-funksjoner",
						"content": "For å fullføre oppsettet ditt må du konfigurere Klarna Express Checkout og On-site messaging i plattformens innstillinger."
					}
				},
				"error-messages": {
					"default": "Det har oppstått en feil. Prøv igjen senere eller gå til hjelp.",
					"fetch-data": "Det har oppstått en feil under henting av dataene dine. Prøv igjen senere eller gå til hjelp.",
					"create-api-key": "Det har oppstått en feil under oppretting av API-nøklene dine. Prøv igjen senere eller gå til hjelp.",
					"create-client-id": "Det har oppstått en feil under opprettelsen av kundeidentifikatoren. Prøv igjen senere eller gå til hjelp."
				}
			},
			"marketing-home": {
				"description": "Velkommen til markedsføringsproduktene våre. Utforsk mulighetene dine nedenfor.",
				"essentials": {
					"title": "Markedsføring",
					"cta-label": "Lær mer",
					"branding-fe": {
						"description": "Sett opp og administrer merkevaren din. Få kunder til å se deg i det beste lyset over Klarnas økosystem inkludert vår smooothe app."
					},
					"klarna-search-b2b-portal": {
						"description": "Kom i gang med Klarnas kraftige søkemotor og prissammenligning. Få tilgang til statistikk og verktøy utviklet for å forbedre din synlighet."
					}
				},
				"external-apps": {
					"title": "Apper",
					"cta-label": "Åpne appen"
				},
				"popOvers": {
					"searchCompare": {
						"title": "Kom i gang med Søk og sammenlign",
						"content": "Her kan du finne statistikk og verktøy som kan bidra til å øke din synlighet på Klarnas sammenligningstjeneste."
					}
				}
			},
			"no-apps": {
				"title": "Ingen tilgjengelige apper!",
				"subtitle": "Kontakt administratoren din for å hjelpe deg med tilgang til appene du trenger."
			},
			"contact": {
				"title": "Trenger du hjelp til noe?",
				"paragraph": "Hvis du har spørsmål, ikke nøl med å ta kontakt, og så vil vi hjelpe deg med å besvare dem.",
				"label": "Kontakt oss"
			},
			"important-banner": {
				"title": "",
				"content": "",
				"linkLabel": ""
			},
			"two-factor-home-banner": {
				"banner-text": "Gjør kontoen din sikrere med tofaktorautentisering.",
				"banner-link": "Sette den opp"
			},
			"two-factor-opt-in": {
				"title": "Sett opp tofaktorautentisering",
				"intro-paragraph": "For å øke sikkerheten til kontoen din, oppfordrer vi deg til å konfigurere tofaktorautentisering i dag.",
				"help-link-text": "Klikk her",
				"help-text": " for å lære mer om hvordan du setter den opp og hvordan den beskytter deg.",
				"warning-text": "Forsikre deg om at du har smarttelefonen og tilgang til internett før du fortsetter",
				"continue-button": "Start oppsettet"
			},
			"accept-invite-modal": {
				"title": "Godta ventende invitasjoner",
				"description": "Du har blitt invitert til å få tilgang til følgende butikker. Vær oppmerksom på at det kan ta noen minutter før valgene dine trer i kraft.",
				"accept-all-button": "Aksepter alle",
				"decline-all-button": "Avslå alle",
				"save-button": "Lagre",
				"error": "Det har oppstått et problem. Prøv igjen senere!",
				"invite": {
					"accept-label": "Aksepter",
					"decline-label": "Avslå"
				}
			},
			"capture-orientation": {
				"title": "Første gang hos Klarna?",
				"sub-title": "Følg disse trinnene for å lære det grunnleggende:",
				"order-label": "Motta en ordre",
				"capture-label": "Fang ordren",
				"payout-label": "Få betalt",
				"capture-cta": "Fang denne ordren",
				"payout-cta": "Oppgjørs-app",
				"dismiss-cta": "Avvist",
				"order-step": "Først bør vi vente på en innkommende ordre fra kundene dine.",
				"capture-step": "Gratulerer med din første bestilling.\nFor å få betalt er neste trinn å sende og fange ordren.",
				"payout-step": "Gratulerer med å fange din første bestilling.\nDu vil nå motta en utbetaling fra oss på linje med tidspunktene som er definert i Klarna-avtalen.\nFor ytterligere informasjon om din første utbetaling, gå til",
				"completed-step": "Gratulerer med din første utbetaling.\nVelkommen til en vakker reise med Klarna.\nUtforsk gjerne de andre appene vi tilbyr nedenfor."
			},
			"banner": {
				"common": {
					"branding-fe": {
						"content": "Vi har nettopp lansert vår nye Branding-app!",
						"linkLabel": "Ta kontroll over merkevaren din nå!"
					},
					"onSiteMessaging": {
						"title": "Gjør besøkende til kunder med On-site messaging",
						"text": "Handlingen begynner før kundene dine når handlekurven. Med On-site messaging vil du øke bevisstheten om Klarnas fleksible betalingsalternativer og andre fordeler tidlig i kjøpstrakten.",
						"cta": "Sjekk det ut"
					}
				}
			},
			"nko": {
				"dialog": {
					"title": "Vi ruller ut noen spennende funksjoner for virksomheten din.",
					"subtitle": "Og vi ønsker å bli bedre kjent med deg.",
					"explore": "Ja, la oss utforske dette!",
					"name-field": "Så hva skal vi kalle deg?",
					"terms-pre": "Jeg har lest og godkjent",
					"terms-text": "Vilkår og betingelser"
				}
			},
			"insightsWidget": {
				"title": "Salg i dag",
				"orderCount": "Opprettede ordrer",
				"orderVolume": "Ordrevolum",
				"orderVolumeAverage": "Gjennomsnittlig ordrevolum",
				"currencyFootnote": "Vises i {currency}",
				"link": "Se ordrer",
				"allStores": "Alle butikker",
				"selectAllOptionText": "Velg alle",
				"deselectAllOptionText": "Fjern markeringen",
				"closeText": "Ferdig",
				"closeTextWithSelection": "Bruk # valg",
				"elapsedTime": "Siste ordre mottatt {hours, plural, =0 {} =1 {en time} other {# timer}} {minutes, plural, =0 {} =1  {ett minutt} other {# minutter}} siden",
				"error": {
					"content": "Ordrestatistikken lastet dessverre ikke riktig."
				},
				"relativeDate": {
					"today": "I dag",
					"yesterday": "I går"
				},
				"weekDays": {
					"1": "Mandag",
					"2": "Tirsdag",
					"3": "Onsdag",
					"4": "Torsdag",
					"5": "Fredag",
					"6": "Lørdag",
					"7": "Søndag"
				}
			},
			"blackFridayWidget": {
				"title": "Black Friday-uken er her.",
				"text": "Følg ordrestatistikken din i sanntid.",
				"button": "Sjekk statistikk"
			},
			"tasks": {
				"title": "Gjøremål",
				"error": {
					"title": "Vi kunne ikke laste gjøremål",
					"desc": "Last inn siden på nytt, så prøver vi igjen."
				},
				"empty": {
					"title": "Alt er som det skal!",
					"desc": "Du har ingen gjøremål akkurat nå."
				},
				"single": {
					"products-fe:complete-recollection": "Obligatorisk AML-skjema du må fylle ut for virksomheten din, for å forhindre hvitvasking av penger",
					"products-fe:resume-onboarding": "Fullfør onboarding",
					"settings-fe:compile-cs-info": "Legg til kundeserviceinformasjon"
				},
				"aggregated": {
					"disputes-fe:unresolved": "{ count, plural, =1 {1 uavklart tvist} other {# uavklarte tvister}}",
					"orders-fe:expiring": "{ count, plural, =1 {1 utløpende ordre} other {# utløpende ordre}}",
					"orders-fe:tocapture": "{ count, plural, =1 {1 ordre igjen å levere} other {# ordre igjen å levere}}",
					"orders-fe:uncaptured": "{ count, plural, =1 {1 ikke-levert ordre} other {# ikke-leverte ordre}}",
					"users-fe:mid-added": "{ count, plural, =1 {1 ny butikk er lagt til} other {# nye butikker er lagt til}}",
					"users-fe:mid-invited": "{ count, plural, =1 {1 butikkinvitasjon venter på handling} other {# butikkinvitasjoner venter på handling}}"
				}
			}
		}
	}
};