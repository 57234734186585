module.exports = {
	"el": {
		"core": {
			"alert": {
				"message": "Προέκυψε κάποιο σφάλμα! Προσπαθήστε ξανά.",
				"correlationIdLabel": "Αναγνωριστικό συσχετισμού:"
			},
			"header": {
				"title": "Πύλη Εμπόρων"
			},
			"usermenu": {
				"language": "Γλώσσα",
				"country": "Χώρα",
				"timezone": "Ζώνη ώρας",
				"logout": "Αποσύνδεση",
				"profile": "Προφίλ",
				"changeProfile": "Επεξεργασία",
				"tooltip": "Το προφίλ σας"
			},
			"appsmenu": {
				"defaultTooltip": "Οι εφαρμογές σας",
				"initialTooltip": "Βρείτε τις εφαρμογές σας εδώ.",
				"newAppsTooltip": "Έχουμε νέες εφαρμογές για εσάς!"
			},
			"two-factor-opt-in": {
				"title": "Ρυθμίστε την επαλήθευση δύο βημάτων",
				"intro-paragraph": "Δεν έχετε ενεργοποιήσει την επαλήθευση ταυτότητας σε δύο βήματα (2FA) στον λογαριασμό σας στο Merchant Portal. Από τις <b>16 Ιανουαρίου 2023</b>, το 2FA θα είναι υποχρεωτικό για όλους τους χρήστες προκειμένου να έχουν πρόσβαση στο Merchant Portal. Μπορείτε να διαβάσετε περισσότερα σχετικά με τη σημασία του 2FA, τον τρόπο ρύθμισης και απαντήσεις σε συχνές ερωτήσεις <faqLink>εδώ</faqLink>.",
				"help-link-text": "Κάντε κλικ εδώ",
				"help-text": " για να μάθετε περισσότερα για το πώς να την ρυθμίσετε και το πώς σας προστατεύει.",
				"warning-text": "Βεβαιωθείτε ότι έχετε το smartphone σας, καθώς και πρόσβαση στο διαδίκτυο, πριν προχωρήσετε",
				"continue-button": "Ξεκινήστε τη ρύθμιση"
			},
			"kuasPrompter": {
				"title": "Έχετε επιλέξει",
				"content": "{count} MID",
				"contentTooltip": "Κάντε κλικ για να αντιγράψετε τα MID στο πρόχειρο",
				"button": "Αλλαγή επιλεγμένων MID"
			},
			"notification": {
				"title": "Ανακοίνωση",
				"content": {
					"uptodate": "Η επικαιροποίησή σας ολοκληρώθηκε!"
				}
			},
			"languages": {
				"cs": "Τσεχικά",
				"da": "Δανέζικα",
				"de": "Γερμανικά",
				"el": "Ελληνικά",
				"en": "Αγγλικά",
				"en_GB": "Αγγλικά (ΗΒ)",
				"en_US": "Αγγλικά (ΗΠΑ)",
				"es": "Ισπανικά",
				"fi": "Φινλανδικά",
				"fr": "Γαλλικά",
				"it": "Ιταλικά",
				"nl": "Ολλανδικά",
				"no": "Νορβηγικά",
				"pl": "Πολωνικά",
				"pt": "Πορτογαλικά",
				"sv": "Σουηδικά"
			},
			"countries": {
				"at": "Αυστρία",
				"au": "Αυστραλία",
				"be": "Βέλγιο",
				"ca": "Καναδάς",
				"ch": "Ελβετία",
				"cz": "Τσεχία",
				"de": "Γερμανία",
				"dk": "Δανία",
				"ee": "Εσθονία",
				"es": "Ισπανία",
				"fi": "Φινλανδία",
				"fr": "Γαλλία",
				"gb": "Μεγάλη Βρετανία",
				"gr": "Ελλάδα",
				"ie": "Ιρλανδία",
				"it": "Ιταλία",
				"mx": "Μεξικό",
				"nl": "Ολλανδία",
				"no": "Νορβηγία",
				"se": "Σουηδία",
				"us": "Ηνωμένες Πολιτείες",
				"nz": "Νέα Ζηλανδία",
				"pl": "Πολωνία",
				"pt": "Πορτογαλία",
				"ro": "Ρουμανία"
			},
			"selectLanguage": {
				"title": "Επιλέξτε τη γλώσσα σας",
				"paragraph": "Αυτή θα είναι η προεπιλεγμένη γλώσσα σας.",
				"submit": "Εντάξει"
			},
			"selectCountry": {
				"title": "Επιλέξτε τη χώρα σας",
				"paragraph": "Αυτή θα είναι η προεπιλεγμένη σας χώρα.",
				"submit": "Εντάξει"
			},
			"updateProfile": {
				"title": "Ενημέρωση προφίλ",
				"submit": "Εντάξει",
				"emailLabel": "Διεύθυνση email",
				"firstNameLabel": "Όνομα",
				"lastNameLabel": "Επώνυμο",
				"resetPasswordLabel": "Κωδικός πρόσβασης",
				"resetPasswordSelectedValue": "Επαναφορά",
				"resetPasswordSuccessLabel": "Το email επαναφοράς στάλθηκε επιτυχώς.",
				"resetPasswordErrorLabel": "Σφάλμα αποστολής email. Προσπαθήστε ξανά αργότερα.",
				"error": "Η ενημέρωση των στοιχείων απέτυχε",
				"changeCookiePolicyLabel": "Πολιτική Cookie",
				"changeCookiePolicySelectedValue": "Αλλαγή",
				"securitySectionTitle": "Ασφάλεια",
				"preferencesSectionTitle": "Προτιμήσεις",
				"mfaLabel": "Επαλήθευση ταυτότητας δύο βημάτων",
				"mfaDescription": "Απαιτείται σύνδεση με έναν χρονικά περιορισμένο κωδικό πρόσβασης μίας χρήσης. Σας συνιστούμε την ενεργοποίηση αυτού του στοιχείου.",
				"twoFactor": {
					"reset": "Αλλάξτε το 2FA",
					"isEnabled": "πλαίσιο ελέγχου",
					"isDisabled": "Απενεργοποιήθηκε",
					"title": "Επαλήθευση ταυτότητας σε δύο-βήματα (2FA)",
					"description": "Αυτό επιτρέπει τη σύνδεση των χρηστών με την πρόσθετη ασφάλεια του χρονικά περιορισμένου κωδικού μίας χρήσης. ",
					"tooltip": "Βεβαιωθείτε ότι έχετε τουλάχιστον την άδεια {permission} για την εφαρμογή {app} για κάθε κατάστημα στο οποίο έχει πρόσβαση ο χρήστης προκειμένου να αλλάξετε τη ρύθμιση της επαλήθευσης ταυτότητας σε δύο βήματα.",
					"confirmationDialog": {
						"closeButton": "Κλείσιμο",
						"confirmationButton": "Ναι, συνέχεια",
						"cancelButton": "Ακύρωση",
						"resetSuccess": {
							"title": "Επιτυχής επαναφορά συσκευής ελέγχου 2FA.",
							"description": "Την επόμενη φορά που ο χρήστης θα συνδεθεί, θα χρειαστεί να ρυθμίσει μια νέα συσκευή για έλεγχο ταυτότητας δύο παραγόντων (2FA)."
						},
						"resetConfirmation": {
							"title": "Είστε βέβαιοι ότι θέλετε να κάνετε επαναφορά της συσκευής ελέγχου 2FA αυτού του χρήστη;",
							"description": "Συνεχίζοντας, θα επαναφέρετε τη συσκευή που χρησιμοποιήθηκε για τη δημιουργία κωδικών 2FA για αυτόν τον χρήστη. Την επόμενη φορά που ο χρήστης θα συνδεθεί, θα χρειαστεί να ρυθμίσει μια νέα συσκευή για έλεγχο ταυτότητας δύο παραγόντων (2FA)."
						}
					}
				}
			},
			"footer": {
				"copyright": {
					"eu": "Copyright © 2005-{currentYear} Klarna Bank AB (publ). Με την επιφύλαξη παντός δικαιώματος",
					"oc": "Copyright © 2005-{currentYear} Klarna Australia Pty Ltd, ABN # 82 635 912 579",
					"ap": "Copyright © 2005-{currentYear} Klarna Australia Pty Ltd, ABN # 82 635 912 579",
					"us": "Copyright © 2005-{currentYear} Klarna Inc. NMLS #1353190, 629 N. High Street, Third Floor, Columbus, OH 43215"
				},
				"terms": "Όροι & προϋποθέσεις"
			},
			"accessDenied": {
				"title": "Χρειάζεστε επιπλέον πρόσβαση για αυτήν τη σελίδα.",
				"message": "Επικοινωνήστε με τον διαχειριστή σας ή την Klarna για τυχόν ερωτήσεις."
			},
			"midSelector": {
				"label": "Κατάστημα"
			},
			"storeSelector": {
				"label": "Κατάστημα"
			},
			"storesSelector": {
				"closeText": "Ολοκληρώθηκε",
				"closeTextWithSelection": "Εφαρμογή # επιλογής(ών)",
				"deselectAllOptionText": "Εκκαθάριση επιλογής",
				"emptyStateText": "Δεν βρέθηκαν καταστήματα",
				"label": "Καταστήματα",
				"searchFieldLabel": "Αναζήτηση ανά κατάστημα",
				"selectAllOptionText": "Επιλογή όλων των καταστημάτων",
				"selectedAllText": "Όλα τα καταστήματα",
				"selectedText": "Αρ. καταστημάτων"
			},
			"pagination": {
				"ofLabel": "από"
			},
			"singleDatePicker": {
				"selectDate": "Επιλογή ημερομηνίας"
			},
			"dateRangePicker": {
				"startLabel": "Ημερομηνία έναρξης",
				"endLabel": "Ημερομηνία λήξης",
				"months": {
					"january": "Ιανουάριος",
					"february": "Φεβρουάριος",
					"march": "Μάρτιος",
					"april": "Απρίλιος",
					"may": "Μάιος",
					"june": "Ιούνιος",
					"july": "Ιούλιος",
					"august": "Αύγουστος",
					"september": "Σεπτέμβριος",
					"october": "Οκτώβριος",
					"november": "Νοέμβριος",
					"december": "Δεκέμβριος"
				},
				"weekDaysShort": {
					"monday": "Δ",
					"tuesday": "Τ",
					"wednesday": "Τ",
					"thursday": "Π",
					"friday": "Π",
					"saturday": "Σ",
					"sunday": "Κ"
				}
			},
			"regionSelector": {
				"noStores": "Δεν έγινε ανάθεση καταστημάτων",
				"andMore": "και περισσότερα",
				"regions": {
					"eu": "Ευρώπη",
					"us": "Βόρεια Αμερική",
					"ap": "Ασία Ειρηνικός"
				}
			},
			"regionSelectorBar": {
				"modal": {
					"title": "Αλλαγή περιοχής",
					"description": "Επιλέξτε μια περιοχή με σχετικά καταστήματα για να εμφανίσετε σχετικά δεδομένα.",
					"buttonText": "Επιβεβαίωση"
				},
				"selectedRegion": "Επιλεγμένη περιοχή",
				"changeButton": "Αλλαγή"
			},
			"appIcon": {
				"newLabel": "ΝΕΟ"
			},
			"appCategory": {
				"dayToDay": "Για την καθημερινότητά σας",
				"nko": "Klarna Online",
				"tools": "Εργαλεία",
				"preferences": "Προτιμήσεις"
			},
			"apps": {
				"account-information-fe": {
					"title": "Στοιχεία λογαριασμού",
					"description": "Βρείτε τα επαγγελματικά και τα τραπεζικά σας στοιχεία και τις συμβάσεις που υπογράψατε."
				},
				"home-fe": {
					"title": "Αρχική σελίδα Πληρωμών"
				},
				"boosters-home-fe": {
					"title": "Ξεκινήστε"
				},
				"marketing-home-fe": {
					"title": "Αρχική σελίδα Μάρκετινγκ"
				},
				"nko-app": {
					"title": "Online εφαρμογές Klarna"
				},
				"orders-fe": {
					"title": "Διαχειριστής παραγγελιών",
					"description": "Αναζητήστε και διαχειριστείτε τις παραγγελίες σας από την Klarna.",
					"nkoLinkText": "Προσπελάστε παλαιότερες παραγγελίες"
				},
				"nko-orders": {
					"title": "Παραγγελίες (KO)",
					"description": "Αναζητήστε και διαχειριστείτε τις παραγγελίες που πραγματοποιήθηκαν μέσω του Klarna Online."
				},
				"settlements-fe": {
					"title": "Διακανονισμοί",
					"description": "Λάβετε αναφορές προσαρμοσμένες στις επαγγελματικές σας ανάγκες.",
					"nkoLinkText": "Προσπελάστε παλαιότερες αναφορές"
				},
				"nko-settlements": {
					"title": "Διακανονισμοί (KO)",
					"description": "Βρείτε αναφορές για παραγγελίες και αποπληρωμές από το Klarna Online."
				},
				"logs-app-fe": {
					"title": "Καταγραφές προγραμματιστών",
					"description": "Αναζητήστε καταγραφές API για την επίλυση προβλημάτων ενσωμάτωσης."
				},
				"users-fe": {
					"title": "Διαχειριστής χρηστών",
					"description": "Προσκαλέστε νέους χρήστες και διαχειριστείτε υφιστάμενους χρήστες στην πύλη."
				},
				"settings-fe": {
					"title": "Ρυθμίσεις πληρωμών",
					"description": ""
				},
				"nko-settings": {
					"title": "Ρυθμίσεις (KO)",
					"description": "Διαχειριστείτε τις ρυθμίσεις καταστήματος, τις αναφορές και τα στοιχεία επικοινωνίας σας."
				},
				"seller-fe": {
					"title": "Στο κατάστημα",
					"description": "Δημιουργήστε νέες παραγγελίες με τη λύση in-store της Klarna."
				},
				"products-fe": {
					"title": "Ενσωμάτωση",
					"description": "Ρυθμίστε τον λογαριασμό σας για να ενεργοποιήσετε τις πληρωμές."
				},
				"upstream-fe": {
					"title": "On-site messaging",
					"description": "Αυξήστε τις πωλήσεις με προσαρμοσμένες και εξατομικευμένες προσφορές χρηματοδότησης."
				},
				"lending-fe": {
					"title": "Business Financing",
					"description": "Η επιχειρηματική χρηματοδότηση γίνεται παιχνιδάκι."
				},
				"branding-fe": {
					"title": "Διαχειριστής επωνυμίας",
					"description": "Ελέγξτε τον τρόπο παρουσίασης της επωνυμίας σας με την Klarna."
				},
				"disputes-fe": {
					"title": "Αμφισβητήσεις συναλλαγών",
					"description": "Διαχειριστείτε τις αμφισβητήσεις συναλλαγών."
				},
				"logistics-fe": {
					"title": "Ενσωματώσεις αποστολής",
					"description": "Διαχειριστείτε όλες τις μεταφορικές εταιρείες σας σε ένα σημείο"
				},
				"developers": {
					"title": "Developers"
				},
				"klarna-docs": {
					"title": "Klarna Docs"
				},
				"support": {
					"title": "Υποστήριξη",
					"description": "Ελέγξτε και διαχειριστείτε τα αιτήματα υποστήριξής σας."
				},
				"integration-guides-fe": {
					"title": "Οδηγοί ενσωμάτωσης",
					"description": ""
				},
				"klarna-search-b2b-portal": {
					"title": "Αναζήτηση & Σύγκριση",
					"description": "Ξεκινήστε με την ισχυρή μηχανή αναζήτησης και τη σύγκριση αγορών της Klarna. Αποκτήστε πρόσβαση σε στατιστικά στοιχεία και εργαλεία σχεδιασμένα να αυξήσουν την προβολή σας."
				},
				"siwk-fe": {
					"title": "Σύνδεση με Klarna",
					"description": "Διαχειριστείτε την ενσωμάτωση Σύνδεση με Klarna."
				},
				"kec-fe": {
					"title": "Express Checkout",
					"description": "Διαχειριστείτε την ενσωμάτωση Express Checkout."
				},
				"partner-self-service": {
					"title": "Υπεύθυνος καταστήματος",
					"description": "Διαχειριστείτε το Klarna Store.",
					"nkoLinkText": "Προβάλετε και διαχειριστείτε τις ρυθμίσεις για το κατάστημά σας"
				}
			},
			"ui": {
				"tabs": {
					"home": "Πληρωμές",
					"growth": "Μάρκετινγκ ανάπτυξης",
					"boosters": "Ενισχυτικοί παράγοντες για την αύξηση των πωλήσεων",
					"badge": "Νέα"
				},
				"buttons": {
					"backButton": "Επιστροφή"
				}
			},
			"errorBoundary": {
				"contact": "Δοκιμάστε να ανανεώσετε τη σελίδα. {br}Αν το σφάλμα επιμένει, επικοινωνήστε με την <supportLink>Υποστήριξη Εμπόρων</supportLink> παρέχοντας το αναγνωριστικό {errorId}.",
				"errorDescription": "Αναγνωριστικό σφάλματος - {errorId}",
				"reloadButton": "Ανανέωση",
				"title": "Δυστυχώς παρουσιάστηκε ένα σφάλμα"
			},
			"maintenance": {
				"pageTitle": "Συντήρηση",
				"title": "Η Πύλη Εμπόρων βρίσκεται σε προγραμματισμένη συντήρηση αυτήν τη στιγμή",
				"summary": "Θα επιστρέψουμε σύντομα",
				"scheduledSummary": "Θα επιστρέψουμε στις {until}"
			},
			"errorTemplates": {
				"errorDescription": "Σφάλμα: {code}",
				"backButton": "Επιστροφή",
				"reloadButton": "Ανανέωση",
				"default": {
					"errorDescription": "Αναγνωριστικό σφάλματος - {errorId}",
					"title": "Δυστυχώς παρουσιάστηκε ένα σφάλμα",
					"contact": "Δοκιμάστε να ανανεώσετε τη σελίδα. Αν το σφάλμα επιμένει, επικοινωνήστε με την <supportLink>Υποστήριξη Εμπόρων</supportLink> παρέχοντας το αναγνωριστικό σφάλματος {errorId}."
				},
				"internalServerError": {
					"title": "Προέκυψε κάποιο σφάλμα",
					"message": "Για κάποιον λόγο από πλευράς μας, δεν μπορέσαμε να επεξεργαστούμε το αίτημά σας. Μπορείτε να προσπαθήσετε ξανά ή να επιστρέψετε αργότερα."
				},
				"notFound": {
					"title": "Δεν υπάρχει τίποτα εδώ.",
					"message": "Αυτή η σελίδα δεν υπάρχει ή πληκτρολογήσατε λανθασμένη διεύθυνση στο πρόγραμμα περιήγησής σας. Ελέγξτε τη διεύθυνση και προσπαθήστε ξανά."
				},
				"forbidden": {
					"title": "Δεν έχετε πρόσβαση σε αυτήν τη σελίδα",
					"message": "Το αναγνωριστικό εμπόρου σας δεν έχει πρόσβαση σε αυτήν τη σελίδα. Αν πιστεύετε ότι πρόκειται για σφάλμα, επικοινωνήστε με την Υποστήριξη Εμπόρων ή με τον διαχειριστή του λογαριασμού της εταιρείας σας."
				}
			},
			"codeSnippet": {
				"clipboardButtonLabel": "Αντιγραφή στο πρόχειρο",
				"clipboardButtonLabelSuccess": "Αντιγράφηκε!"
			}
		},
		"blackFriday": {
			"v1": {
				"title": "Black Friday",
				"intro": "Μία από τις πιο σημαντικές ημέρες στον τομέα των λιανικών πωλήσεων έχει σχεδόν φτάσει. Κατά τη διάρκεια του Black Friday, θα είμαστε εδώ για να σας προσφέρουμε στατιστικά στοιχεία των πωλήσεών σας σχεδόν σε πραγματικό χρόνο. Στο μεταξύ, ας προετοιμαστούμε για την επιτυχία!",
				"countdown": "Ξεκινάμε σε #dd #hh #mm #ss…",
				"box1": {
					"title": "3,5 φορές περισσότερες δαπάνες*",
					"text": "Πέρσι, οι αγοραστές της Klarna* δαπάνησαν κατά μέσο όρο 3,5 φορές περισσότερα χρήματα την Black Friday από οποιαδήποτε άλλη ημέρα του χρόνου.",
					"footnote": "*Κατά μέσο όρο σε όλες τις κατηγορίες εμπόρων Klarna και τις περιοχές."
				},
				"box2": {
					"title": "2,5 φορές παραπάνω όγκος πωλήσεων*",
					"text": "Πέρσι, οι έμποροι της Klarna παρήγαγαν 2,5 φορές περισσότερο όγκο πωλήσεων την Black Friday από οποιαδήποτε άλλη μέρα του χρόνου.",
					"footnote": "*Κατά μέσο όρο σε όλες τις κατηγορίες εμπόρων Klarna και τις περιοχές."
				},
				"sectionTitle": "Ας αυξήσουμε τις πωλήσεις σας την Black Friday",
				"marketing": {
					"title": "Διαφημίστε την επιχείρησή σας τόσο πριν, όσο και στην Black Friday",
					"text": "Προσελκύστε περισσότερους αγοραστές στο κατάστημά σας κατά τη διάρκεια της αγοραστικής σεζόν της Black Friday με αυτούς τους δωρεάν πόρους για τα μέσα κοινωνικής δικτύωσης και τις καμπάνιες email."
				},
				"branding": {
					"title": "Αυξήστε την επισκεψιμότητά σας στην εφαρμογή Klarna",
					"text": "12 εκατομμύρια αγοραστές χρησιμοποιούν την εφαρμογή Klarna κάθε μήνα. Βεβαιωθείτε ότι θα τραβήξετε τα βλέμματα αυτήν την Black Friday στην εφαρμογή, προσθέτοντας και ενημερώνοντας τις πληροφορίες επωνυμίας σας εδώ, στην Πύλη Εμπόρων."
				},
				"cta": "Ξεκινήστε",
				"close": "Κλείσιμο"
			},
			"v2": {
				"title": "Black week",
				"orderVolume": "Όγκος πωλήσεων",
				"orderCountTitle": "Αριθμός παραγγελιών",
				"orderCount": "{count} παραγγελίες",
				"box": {
					"title": "Θέλετε να αυξήσετε περισσότερο τις μετατροπές;",
					"subtitle1": "On-site messaging",
					"subtitle2": "Προωθητικά μηνύματα που ενημερώνουν.",
					"text": "Το εργαλείο μας On-site Messaging βοηθά στην ενίσχυση των πωλήσεών σας και τη μέση αξία των παραγγελιών με προωθητικά μηνύματα. Δώστε τη δυνατότητα στους αγοραστές να ενημερώνονται για τις επιλογές πληρωμής τους όταν μεταβαίνουν στον ιστότοπό σας και σε ολόκληρο το ταξίδι αγορών τους.",
					"cta": "Ξεκινήστε"
				},
				"selector": {
					"done": "Ολοκληρώθηκε",
					"applySelection": "Εφαρμογή # επιλογής(ών)",
					"selectAll": "Επιλογή όλων",
					"allOptions": "Επιλέχθηκαν όλες οι επιλογές",
					"someOptions": "Επιλέχθηκαν # επιλογές",
					"storeMultiSelectLabel": "Επιλογή # καταστημάτων",
					"allStores": "Όλα τα καταστήματα"
				},
				"currencyLabel": "Επιλέξτε νόμισμα",
				"storeLabel": "Επιλογή καταστήματος"
			},
			"v3": {
				"title": "Εβδομάδα Black Friday",
				"text": "Αυτές είναι οι πωλήσεις σας από μία από τις σημαντικότερες εβδομάδες εκπτώσεων του έτους.",
				"summaryTitle": "Σύνοψη",
				"orderVolume": "Συνολικός όγκος",
				"orderCount": "Συνολικές παραγγελίες",
				"summaryRange": "23-30 Νοεμβρίου",
				"comparisonLastWeek": "σε σύγκριση με την ίδια ημέρα, την προηγούμενη εβδομάδα",
				"tabs": {
					"monday": "Δευτέρα",
					"tuesday": "Τρίτη",
					"wednesday": "Τετάρτη",
					"thursday": "Πέμπτη",
					"blackFriday": "Black Friday",
					"saturday": "Σάββατο",
					"sunday": "Κυριακή",
					"cyberMonday": "Cyber Monday"
				}
			}
		},
		"mpSatSurvey": {
			"marble": {
				"topbarButtonLabel": "Σχόλια για τη νέα μας εμφάνιση",
				"step1": {
					"welcome": "Σας αρέσει η νέα μας εμφάνιση και αίσθηση;",
					"satisfied": "Πολύ ικανοποιημένος/η",
					"unsatisfied": "Πολύ δυσαρεστημένος/η"
				},
				"step2": {
					"textAreaLabel": "Άλλο (η γνώμη σας)",
					"textAreaMaxLength": "Φτάσατε στο μέγιστο όριο",
					"submit": "Υποβολή",
					"content": {
						"titlePositive": "Τι σας αρέσει περισσότερο;",
						"titleNegative": "Τι αντιπαθείτε περισσότερο;",
						"tags": {
							"color": "Χρώμα και στυλ",
							"navigation": "Πλοήγηση",
							"names": "Όνομα σελίδων",
							"structure": "Δομή σελίδων"
						}
					}
				},
				"step3": {
					"title": "Σας ευχαριστούμε!",
					"description": "Ευχαριστούμε για τα σχόλιά σας."
				}
			},
			"buttonLabel": "Στείλτε μας σχόλια",
			"errorMessage": "Προέκυψε σφάλμα κατά την αποστολή των σχολίων σας.",
			"retry": "Προσπαθήστε ξανά",
			"step1": {
				"welcome1": "Πόσο ευχαριστημένοι είστε με την",
				"welcome2": "Πύλη Εμπόρων;",
				"emojiSelector": {
					"angry": "Πολύ δυσαρεστημένος/η",
					"unhappy": "Ελαφρώς δυσαρεστημένος/η",
					"neutral": "Ουδέτερος/η",
					"happy": "Ικανοποιημένος/η",
					"love": "Τη λατρεύω"
				},
				"remindMeText": "Δεν θέλετε να μοιραστείτε τη γνώμη σας;",
				"remindMeLink": "Να μου γίνει υπενθύμιση αργότερα για αυτήν την έρευνα.",
				"dontShowAgainLink": "Να μην εμφανιστεί ξανά."
			},
			"step2": {
				"textAreaLabel": "Άλλο (η γνώμη σας)",
				"textAreaMaxLength": "Φτάσατε στο μέγιστο όριο",
				"submit": "Υποβολή",
				"content": {
					"negative": {
						"title": "Λυπούμαστε γι’ αυτό.",
						"description": "Ποιες πτυχές της Πύλης Εμπόρων πρέπει να βελτιώσουμε; (Επιλέξτε όλα όσα ισχύουν)",
						"tags": {
							"loading": "Χρόνος φόρτωσης",
							"menu": "Οργάνωση μενού",
							"mobile": "Έκδοση κινητών συσκευών",
							"filter": "Φιλτράρισμα δεδομένων",
							"complexity": "Η αποτελεσματική διεξαγωγή ενεργειών"
						}
					},
					"positive": {
						"title": "Αυτά είναι πολύ καλά νέα!",
						"description": "Θέλουμε να εξακολουθήσουμε να βελτιωνόμαστε. Ποια είναι τα στοιχεία που θα έκαναν την εμπειρία σας στην Πύλη Εμπόρων ακόμη καλύτερη",
						"tags": {
							"views": "Προσαρμοσμένες προβολές",
							"looks": "Όψη & αίσθηση",
							"tours": "Περιηγήσεις με καθοδήγηση",
							"shortcuts": "Συντομεύσεις για εργασίες"
						}
					}
				}
			},
			"step3": {
				"title": "Σας ευχαριστούμε! Κάτι τελευταίο.",
				"content1": "Θα θέλαμε να σας προσκαλέσουμε σε μια συνέντευξη για να μπορέσουμε να μάθουμε περισσότερα για την εμπειρία σας με τη χρήση της Πύλης Εμπόρων.",
				"content2": "Θα θέλατε να συμμετάσχετε;",
				"content3": "Αν συμφωνείτε, θα επικοινωνήσουμε μαζί σας μέσω του καταχωρισμένου σας email για να το κανονίσουμε.",
				"yes": "Ναι, παρακαλώ",
				"no": "Όχι, ευχαριστώ"
			},
			"step4": {
				"yesTitle": "Θαυμάσια! Θα είμαστε σε επαφή.",
				"noTitle": "Κατανοητό. Σας ευχαριστούμε!",
				"content1": "Σας ευχαριστούμε που αφιερώσατε χρόνο για να απαντήσετε σε αυτές τις ερωτήσεις.",
				"content2": "Μπορείτε τώρα να κλείσετε αυτό το αναδυόμενο παράθυρο."
			}
		},
		"boost": {
			"integrationTypeModal": {
				"title": "Πώς θέλετε να ρυθμίσετε το {productName};",
				"labels": {
					"plugin": "Ρύθμιση με ένα πρόσθετο",
					"web": "Ρύθμιση με Web SDK"
				}
			}
		}
	}
};