module.exports = {
	"nl": {
		"help-tool": {
			"button-text": "Help",
			"support-button": "Ondersteuning krijgen",
			"support-page": {
				"create-ticket": {
					"title": "Krijg support",
					"instructions": "Dien onderstaand een aanvraag in en we zullen binnen 1 werkdag contact met u opnemen.",
					"related-content": {
						"title": "Deze kunnen misschien helpen uw probleem op te lossen"
					},
					"fields": {
						"mid": {
							"label": "Kies webwinkel",
							"validation": "Winkel is vereist"
						},
						"phoneNumber": {
							"label": "Telefoonnummer (optioneel)"
						},
						"topic": {
							"question": "Waar gaat je vraag over?",
							"label": "Selecteer een onderwerp",
							"validation": "Onderwerp is vereist",
							"options": {
								"orders": "Bestellingen",
								"settlements": "Afrekeningen",
								"agreements": "Overeenkomsten",
								"others": "Overig"
							}
						},
						"subject": {
							"label": "Onderwerp",
							"validation": "Onderwerp is vereist"
						},
						"description": {
							"label": "Bericht",
							"validation": "Omschrijving is vereist"
						},
						"attachment": {
							"label": "Bijlagen toevoegen",
							"button": "Upload",
							"info": "Door screenshots toe te voegen help je ons het probleem sneller op te lossen.",
							"supportedFileTypes": "Ondersteunde bestandstypen: .png, .jpg, .pdf",
							"error": "De totale bestandsgrootte mag niet meer zijn dan {maxSize} MB"
						}
					},
					"submit": "Verzoek indienen",
					"service-unavailable-error": "Oeps! Er is iets misgegaan, probeer het over een paar minuten nog eens. Als dit aanhoudt, vraag {link} om hulp.",
					"generic-error": "We konden je verzoek niet verwerken. Onze systemen krijgen op dit moment geen contact met elkaar. Probeer de pagina opnieuw te laden, of kom later terug. Als dit aanhoudt, neem dan voor hulp contact op met {link}.",
					"contact-link": "neem contact met ons op"
				},
				"view-ticket": {
					"title": "Klaar!",
					"details": "We hebben uw aanvraag ontvangen en u ontvangt binnen 1 werkdag een antwoord. Dit zijn uw gegevens:",
					"ticket": "Verzoek #{ticketId}",
					"email": "E-mailadres: {email}",
					"status": "Je kunt de status bekijken op onze Support-pagina.",
					"track": "Status bekijken"
				}
			},
			"header": {
				"homeLink": "Reset"
			},
			"home": {
				"greeting": "Hallo {givenName}! Hoe kunnen we je helpen?",
				"topics": "Waar heb je hulp bij nodig?"
			},
			"topics": {
				"expandButton": "Meer"
			},
			"feedback": {
				"title": "Heeft dit geholpen?",
				"optionYes": "Ja, dit heeft geholpen",
				"optionNo": "Nee, ik heb meer hulp nodig",
				"positiveFeedback": "Bedankt voor de positieve feedback, dit helpt ons om je in de toekomst meer relevante artikelen te kunnen bieden."
			},
			"freeTextFeedback": {
				"title": "Help ons te verbeteren",
				"placeholder": "Waarom vond je deze informatie niet nuttig?",
				"submitButton": "Versturen",
				"feedbackMessage": "Bedankt voor je feedback, dit helpt ons om je in de toekomst meer relevante artikelen te kunnen bieden."
			},
			"relatedContent": {
				"title": "Gerelateerde informatie"
			},
			"search": {
				"buttonLabel": "Zoeken",
				"input": "Zoek naar artikelen...",
				"loading": "Laden...",
				"results": {
					"contact": "Kun je de hulp niet vinden die je nodig hebt?",
					"empty": "Sorry, er zijn geen resultaten gevonden voor je vraag. Probeer via een andere term te zoeken.",
					"singleTopicFound": "We vonden 1 onderwerp",
					"multipleTopicsFound": "We vonden {count} onderwerpen",
					"singleArticleFound": "We vonden 1 artikel",
					"multipleArticlesFound": "We vonden {count} artikelen"
				}
			},
			"contact": {
				"title": "Neem contact met ons op",
				"channelsIntro": "Neem contact met ons op zodat we je kunnen helpen.",
				"businessDetailsTitle": "Je bedrijfsgegevens",
				"businessDetailsName": "Bedrijfsnaam",
				"businessDetailsEmail": "E-mailadres",
				"businessDetailsMid": "Webwinkel-ID",
				"phone": {
					"title": "Bel ons",
					"paragraph": "Je kunt ons op werkdagen tijdens kantooruren bellen. Als je in Zweden of het Verenigd Koninkrijk bent gevestigd, kun je ons ook in het weekend tussen 10.00 en 16.00 uur bellen."
				},
				"email": {
					"title": "E-mail ons",
					"paragraph": "Stuur ons een e-mail en we nemen zo snel mogelijk contact met je op."
				}
			},
			"errorBoundary": {
				"title": "Er is iets misgegaan",
				"message": "Het spijt ons, maar er is een fout opgetreden. Je kunt op de knop 'Ga terug' hieronder klikken om terug te gaan naar het hulpprogramma of op de knop 'Hulp inschakelen' om contact met ons op te nemen.",
				"resetButton": "Ga terug",
				"errorId": "Fout-ID: {errorId}"
			},
			"tour": {
				"prev": "Vorige",
				"next": "Volgende",
				"done": "Klaar"
			},
			"notAvailableMobile": {
				"title": "Niet beschikbaar",
				"buttonBack": "Terug",
				"paragraph": "Deze inhoud is niet beschikbaar op je apparaat. Probeer het te openen op een apparaat met een groter scherm, zoals een desktopcomputer."
			},
			"shareButton": {
				"title": {
					"article": "Deel een deeplink naar dit artikel",
					"topic": "Deel een deeplink naar dit onderwerp",
					"tour": "Deel een deeplink naar deze rondleiding"
				},
				"radioItemHome": "Home App",
				"descriptionHome": "Opent hulpartikel op de homepage van de Merchant Portal ",
				"radioItemCurrent": "Huidige pagina",
				"descriptionCurrent": "Opent hulpartikel op de huidige pagina"
			},
			"marketSelector": {
				"whichMarketToContact": "Met welke markt wilt u contact opnemen?"
			}
		},
		"orientation": {
			"title": "Ontdek meer over ..."
		},
		"helpbot": {
			"questions": {
				"whatDoYouNeedHelpWith": "Hallo! Waarmee kunnen we u vandaag helpen?",
				"selectSupportChannel": "Selecteer een kanaal voor contact met onze ondersteuningsservice.",
				"thatHelped": "Bedankt voor de positieve feedback, dit helpt ons bij het bieden van meer relevante hulp in de toekomst. 😀"
			},
			"orders": {
				"orderManagement": {
					"title": "Bestellingenbeheer",
					"createOrderPos": {
						"question": "Hoe maak ik een bestelling aan via Point of Sale?",
						"answer": "1. Open Point of Sale 2. Voer de artikelnaam, productprijs en hoeveelheid in. 3. Deel de gegenereerde betaallink met uw klant via sms, e-mail of door de klant de QR-code te laten scannen die wordt weergegeven op het betaalscherm. 4. De klant kan nu de aankoop afronden vanaf zijn/haar apparaat."
					},
					"extendExpiryReservation": {
						"question": "Hoe verleng ik de vervaldatum van een reservering?",
						"answer": "1. Ga naar Bestellingenbeheer en selecteer een of meerdere bestellingen waarvan u de vervaldatum wilt verlengen. 2. Klik op Vervaldatum verlengen. 3. Bevestig uw keuze.\nBestellingen worden verlengd volgens de reserveringsinstellingen voor uw winkel (meestal 28 dagen). Als een bestelling is verlopen, kan deze niet meer worden verlengd.\""
					},
					"howToCaptureOrders": {
						"question": "Hoe activeer ik een bestelling?",
						"partially": {
							"question": "Ik wil een bestelling deels activeren",
							"answer": "Als het slechts mogelijk is om een deel van een bestelling van een klant te verzenden, kunt u de bestelling gedeeltelijk activeren op bestelregels of bedrag. Bestelregels activeren 1. Ga naar de bestelling in Bestellingenbeheer en selecteer de artikelen die u momenteel op voorraad hebt op de bestelpagina. Vergeet niet om bestelregels te bewerken en de hoeveelheden aan te passen als dat nodig is. 2. Klik op Activeren. Bedrag activeren: 1. Ga naar de bestelling in Bestellingenbeheer. U kunt een bedrag activeren door op de knop \"Alles activeren\" te klikken. Hier voert u het gewenste bedrag in. 2. Klik op Activeren.\nVergeet niet om de bestelling af te ronden wanneer dat mogelijk is, ofwel door de resterende artikelen/het resterende bedrag te activeren zodra ze kunnen worden verzonden, of door de resterende bestelling te annuleren als u de goederen niet kunt verzenden."
						},
						"fully": {
							"question": "Ik wil bestellingen volledig activeren",
							"answer": "1. Ga naar Bestellingenbeheer en klik op het Klarna-referentienummer om de bestelling te openen. 2. Activeer de volledige bestelling aan de hand van een van de volgende stappen: - Klik op de knop \"Activeren\" - Selecteer de bestelregels en klik op \"Activeren\". - Klik op \"Alle activeren\" en voer het volledige bedrag van de bestelling in. 3. Als u de bestelling activeert, kunt u de pakbon downloaden om mee te verpakken.\nZorg dat u bestellingen activeert voor de vervaldatum (meestal 28 dagen vanaf de aanmaak van de bestelling). Om het sneller te laten verlopen, kunt u meerdere bestellingen activeren door ze in de bestellingenlijst te selecteren en op \"Volledig activeren\" te klikken."
						}
					},
					"canIUpdateCustomerInfo": {
						"question": "Kan ik klantgegevens bijwerken?",
						"answer": "U kunt de ontvanger van een bestelling nooit wijzigen. Maar u kunt wel het verzend- en factuuradres wijzigen voor bestellingen die nog niet of gedeeltelijk zijn geactiveerd. Als Klarna het nieuwe adres echter afwijst omdat het niet gekoppeld is aan de klant, kan het factuuradres nog wel worden gewijzigd."
					},
					"extendDueDate": {
						"question": "Hoe verleng ik de vervaldatum van een bestelling?",
						"answer": "U kunt de vervaldatum voor een bestelling verlengen door te klikken op \"Vervaldatum verlengen\" op een geactiveerde bestelling. Aan het verlengen van de vervaldatum van een bestelling zijn kosten verbonden die worden gespecificeerd voordat u de actie afrondt."
					},
					"refundOrder": {
						"question": "Hoe betaal ik een bestelling terug?",
						"answer": "1. Ga naar Bestellingenbeheer en zoek de bestelling die u wilt terugbetalen. 2. Kies de terugbetaalmethode: selecteer de artikelen die u wilt terugbetalen en klik vervolgens op \"Terugbetalen\" of klik eerst op \"Terugbetalen\" om het bedrag in te voeren dat u wilt terugbetalen. 3. Het terugbetaalde bedrag wordt afgetrokken van uw volgende uitbetaling en de klant ontvangt het binnen 5 werkdagen.\nDit geldt alleen voor geactiveerde bestellingen. Annuleer openstaande bestellingen als u geen artikelen gaat verzenden. Als slechts een deel van de bestelling is geactiveerd en verzonden, geef dan het openstaande deel vrij."
					},
					"cancelOrder": {
						"question": "Hoe annuleer ik een bestelling?",
						"answer": "1. Ga naar Bestellingenbeheer en zoek de bestelling die u wilt annuleren. 2. Klik op \"Bestelling annuleren\". Deze actie informeert uw klant ook dat de bestelling niet wordt verzonden.\nU kunt geannuleerde bestellingen opnieuw maken indien nodig. U kunt geactiveerde bestellingen niet annuleren, maar u kunt ze terugbetalen."
					},
					"unableToTakeActions": {
						"question": "Waarom kan ik geen actie ondernemen voor een bestelling?",
						"directPartner": {
							"question": "Ik heb een overeenkomst rechtstreeks met Klarna",
							"answer": "Gebruikersrechten bepalen wat gebruikers kunnen doen in het Partnerportaal. Er zijn twee soorten gebruikersmachtigingen: Beheerder - gebruikers met volledige toegang; een beheerder heeft bijvoorbeeld toegang tot alle functionaliteit in het Partnerportaal en kan deze bewerken. Sectiespecifiek - Niet-beheerders hebben toegang tot een of meer specifieke secties in het Partnerportaal.\nDit kan de reden zijn waarom u geen actie kunt ondernemen. Er zijn verschillende toegangsrollen voor Bestellingenbeheer. Neem contact op met uw beheerder om te bepalen welke toegangsrol het meest geschikt is voor de acties die u wilt uitvoeren."
						},
						"distributionPartner": {
							"question": "Ik heb een overeenkomst rechtstreeks met Klarna",
							"answer": "Acties die u wilt uitvoeren voor een bestelling kunnen alleen worden uitgevoerd via het platform van de Distributiepartner"
						}
					}
				},
				"orderModification": "Wijzigingen in en problemen met bestellingen",
				"orderProcessing": "Verwerking bestellingen",
				"refundIssues": {
					"title": "Problemen met terugbetalingen",
					"refundedOrderWhenCustomerFunds": {
						"question": "Ik heb een bestelling terugbetaald, wanneer ontvangt mijn klant het geld?",
						"answer": "Een klant moet het geld binnen 5 werkdagen ontvangen vanaf het moment dat de bestelling is terugbetaald. Dit kan variëren, afhankelijk van de betaalmethode die de klant heeft gekozen. Als de klant hier vragen over heeft, neem laat ze dan contact opnemen met de Klantenservice van Klarna."
					}
				},
				"reservations": {
					"title": "Reserveringen",
					"whyUnableToExtendReservation": {
						"question": "Waarom kan ik de reserveringstijd van een bestelling niet verlengen?",
						"answer": "Er zijn specifieke omstandigheden waarin het verlengen van de vervaldatum van een bestelling niet mogelijk is:\n- Als de bestelling al is verlopen, moet u deze opnieuw aanmaken. Ga hiervoor naar de bestelpagina, klik op ‘Kopiëren’ en selecteer ‘Nieuwe bestelling aanmaken’.\n- Als de bestelling per kaart is betaald, moet de klant een nieuwe bestelling plaatsen, aangezien de vervaldatum van deze bestellingen niet kan worden verlengd.\n"
					}
				},
				"paymentsForMyOrders": {
					"title": "Betalingen voor mijn bestellingen",
					"whenWillIGetPaid": {
						"question": "Wanneer word ik betaald voor een bestelling?",
						"answer": "Om een betaling voor een bestelling te ontvangen, moet u deze eerst activeren. Het uitbetalingsbedrag wordt, na aftrek van kosten, retourzendingen en andere kosten, op basis van uw uitbetalingsschema naar uw bankrekening overgemaakt. U kunt uw uitbetalingsschema bekijken in het gedeelte Afrekeningen."
					},
					"refundOrderReceivedPayment": {
						"question": "Wat gebeurt er als ik een bestelling terugbetaal waarvoor ik al geld heb ontvangen?",
						"answer": "Elk bedrag dat u terugbetaalt, wordt afgetrokken van uw volgende geplande uitbetaling."
					},
					"referenceNotShowUp": {
						"question": "Bestelnummer of referentienummer verschijnt niet in verslag.",
						"answer": "Als u geen betaling voor een specifieke bestelling kunt vinden:\n- Controleer of het bestel- of referentienummer klopt.\n- Controleer of de bestelling is geactiveerd.\n- Controleer of de bestelling binnen de activeringsperiode van het verslag valt.\n- Houd er rekening mee dat bestellingen die voor de afrekening zijn geannuleerd niet in het verslag verschijnen.\n"
					},
					"gotPaidSpecificOrder": {
						"question": "Hoe kan ik zien of ik voor een bepaalde bestelling betaald heb gekregen?",
						"answer": "Zoek in het bestellingenbeheer van het Partnerportaal naar de bestelling en controleer of het bestel- of referentienummer klopt. Controleer onder het referentienummer of de bestelling is geactiveerd. Als de bestelling is geactiveerd, wordt de status weergegeven als in behandeling of uitbetaald. Klik voor uitbetaalde bestellingen op 'Zie afrekening' om het bijbehorende verslag te bekijken."
					}
				}
			},
			"disputes": {
				"disputeManagement": "Beheer van betwistingen",
				"contactInfoAndNotifications": "Contactinformatie en Instellingen meldingen",
				"timingAndDeadlines": "Tijdschema en deadlines voor betwistingen",
				"resolutionProcess": "Proces beslechting betwistingen",
				"merchantDisputesApp": {
					"title": "Webwinkel Betwistingen-app",
					"respondToDispute": {
						"question": "Hoe weet ik of ik moet reageren op een betwisting?",
						"answer": "Controleer het gedeelte 'To-do' op de startpagina van uw Partnerportaal om te zien of er betwistingen zijn waarop u moet reageren."
					},
					"overviewDisputesCustomer": {
						"question": "Waar kan ik een overzicht vinden van alle betwistingen die door klanten zijn ingediend?",
						"answer": "U kunt alle ingediende betwistingen vinden in de sectie Betwistingen, onder de pagina ‘Alle betwistingen’, direct nadat de betwisting is ingediend.\n\nHoud er rekening mee dat betwistingen tot 180 dagen na sluiting beschikbaar zijn op de pagina ‘Alle betwistingen’.\n"
					},
					"overviewDisputesResponse": {
						"question": "Waar vind ik een overzicht van alle betwistingen die een reactie vereisen?",
						"answer": "U vindt alle betwistingen die een reactie vereisen in het gedeelte Betwistingen op de pagina ‘Openstaande betwistingen’ onder ‘Reactie vereist’."
					},
					"updateContactInformation": {
						"question": "Hoe kan ik mijn contactgegevens voor e-mails ter herinnering van betwistingen bijwerken?",
						"answer": "1. Ga naar de sectie Betwistingen\n2. Klik op de pagina ‘Instellingen E-mail & betwistingen’\n3. Werk uw contactgegevens bij in het tabblad ‘E-mail & meldingen’.\n\nHoud er rekening mee dat u verschillende contactgegevens kunt instellen voor ‘betwistingen’, ‘ongeoorloofde aankopen’ en ‘hoogrisicobestellingen’. Daarnaast kunt u de frequentie van de meldingen instellen in het tabblad ‘Meldingen’.\n"
					},
					"updateCustomerServiceOnKlarnaApp": {
						"question": "Hoe kan ik mijn klantenservicegegevens bijwerken in de Klarna app?",
						"answer": "1. Ga naar ‘Betalingsinstellingen’\n2. Klik op ‘Gegevens klantenservice’\n3. Selecteer ‘Markt’ (als u marktspecifieke ondersteuning hebt)\n\nU kunt de contactgegevens van de klantenservice invoeren, evenals het retourbeleid dat moet worden weergegeven in de Klarna app voor een soepelere klantervaring.\n\nHoud er rekening mee dat u volledige toegangsrechten voor het Partnerportaal nodig heeft om deze informatie te bekijken.\n"
					},
					"improveDisputesPerformance": {
						"question": "Hoe kan ik mijn betwistingenprestaties verbeteren?",
						"answer": "Voor tips over hoe u de prestaties van uw betwistingen kunt verbeteren, gaat u naar de ‘Gids voor verbetering van retouren en betwistingen’. Deze is toegankelijk via de pagina ‘Statistieken’ door rechtsboven op de knop ‘Inzichten verkrijgen’ te klikken.\n\nHoud er rekening mee dat u volledige toegangsrechten voor het Partnerportaal nodig heeft om deze informatie te bekijken.\n"
					},
					"overviewDisputesPerformance": {
						"question": "Waar kan ik een overzicht van mijn betwistingen zien?",
						"answer": "Om gedetailleerde statistieken over geschillen te bekijken, gaat u naar de pagina ‘Statistieken’ in de Betwistingen-app. Hier vindt u een overzicht van maandelijkse betwistingen, uitkomsten, kosten en verliezen in verband met geschillen.\n\nHoud er rekening mee dat u volledige toegangsrechten voor het Partnerportaal nodig heeft om deze informatie te bekijken.\n"
					}
				},
				"disputesLifecycle": {
					"title": "Levenscyclus van betwistingen",
					"whenCanSeeDisputes": {
						"question": "Wanneer kan ik betwistingen zien die door klanten worden ingediend?",
						"answer": "U kunt alle geschillen die in de Betwistingen-app zijn aangemeld direct zien nadat ze zijn aangemeld op de pagina ‘Alle betwistingen’."
					},
					"howLongToResolve": {
						"question": "Hoeveel tijd heb ik om het betwisting rechtstreeks met de klant op te lossen?",
						"answer": "U heeft 21 dagen de tijd om een betwisting met de klant op te lossen. Als u en de klant de betwisting niet binnen 21 dagen hebben opgelost, zal Klarna ingrijpen om het proces tussen beide partijen te ondersteunen."
					},
					"howMuchTimeToRefund": {
						"question": "Hoeveel tijd heb ik om retourzendingen terug te betalen?",
						"answer": "U heeft 21 dagen vanaf de retourdatum om een terugbetaling te verwerken voordat Klarna tussenbeide komt om het proces tussen beide partijen te ondersteunen. Het is echter altijd het beste om retourzendingen terug te betalen zodra u ze heeft ontvangen."
					},
					"howToResolve": {
						"question": "Hoe kan ik betwistingen en retouren oplossen?",
						"answer": "Er zijn verschillende manieren om een betwisting of retour op te lossen.\n\n- Verwerk de retourzending of terugbetaling zodra u deze ontvangt/een oplossing overeenkomt.\n- Stel een Drempel voor terugbetaling in onder ‘Instellingen E-mail & betwistingen’\n- Wees proactief door ‘Alle betwistingen’ aan te vinken\n- Accepteer het verlies\n"
					},
					"whenToRespond": {
						"question": "Wanneer moet ik actief verweer voeren/reageren op betwistingen?",
						"answer": "Als u en de klant niet zelf tot een oplossing kunnen komen binnen de gegeven tijd (21 dagen), zal Klarna ingrijpen om het oplossingsproces tussen beide partijen te ondersteunen. Als het betwisting gegrond is, zal het supportteam van Klarna contact met u opnemen via de Betwistingen-app met een verzoek om meer informatie.\n\nOp dat moment wordt u gevraagd om een betwisting te verdedigen/erop te reageren.\n"
					},
					"howMuchTimeToRespond": {
						"question": "Hoeveel tijd heb ik om te reageren op een betwisting?",
						"answer": "De termijn voor het eerste verzoek is 14 dagen voor alle betwistingen, met uitzondering van ongeautoriseerde aankopen (7 dagen). Voor elk vervolgverzoek* dat naar u wordt verzonden (met een maximum van 5 verzoeken) heeft u een deadline van 7 dagen. Als u uw antwoord moet aanvullen, heeft u vanaf het moment van indienen 60 minuten de tijd om bijvoorbeeld informatie of bijlagen toe te voegen. Zodra de termijn is verstreken, kunt u de zaak niet meer verdedigen.\n\n*Er kunnen speciale regels van toepassing zijn, afhankelijk van de samenwerkingsovereenkomst.\n"
					},
					"howLongToSeeDispute": {
						"question": "Hoelang kan ik een betwisting zien in de Betwistingen-app nadat het is opgelost?",
						"answer": "Een betwisting is tot 180 dagen na sluiting beschikbaar op de pagina ‘Alle betwistingen’."
					},
					"whenToGetCharged": {
						"question": "Wanneer worden er betwistingskosten in rekening gebracht?",
						"answer": "Er kunnen betwistingskosten in rekening worden gebracht als:\n- U schuld hebt aan de betwisting.\n- U niet binnen de gestelde termijn reageert.\n- U een terugbetaling uitgeeft nadat Klarna tussenbeide is gekomen ter ondersteuning van het oplossingsproces.\n\nDe kosten worden met terugwerkende kracht in rekening gebracht nadat de uitkomst van de betwisting is vastgesteld. Er worden geen kosten in rekening gebracht als de betwisting in uw voordeel wordt opgelost of als deze wordt geannuleerd of afgewezen.\n"
					}
				},
				"disputesHandling": {
					"title": "Behandeling van betwistingen",
					"whatIsDispute": {
						"question": "Welke informatie ontvang ik bij een betwisting?",
						"answer": "De betwisting bevat:\n\n- Reden: Categorie van betwisting, bijv. retourzendingen, goederen niet ontvangen.\n- Webshop: Relevant als er meerdere webwinkel-ID’s (MID’s) geregistreerd zijn bij Klarna.\n- Datum betwisting: Wanneer de klant de betwisting heeft ingediend.\n- Deadline: De deadline waarbinnen u moet reageren om de zaak te verdedigen.\n- Betwiste producten: Artikel(en) die worden betwist.\n- Bestelgegevens: Bevat ‘webshopreferentie 1 en 2’, ‘besteldatum’, ‘betwist bedrag’.\n"
					},
					"howManyTimesDefend": {
						"question": "Hoe vaak kan ik een betwistingsverzoek verdedigen?",
						"answer": "U krijgt maximaal vijf (5) verzoeken om uw betwisting te verdedigen, maar meestal zijn er niet meer dan één of twee nodig."
					},
					"doIReceiveNotification": {
						"question": "Ontvang ik meldingen als ik moet reageren op betwistingen?",
						"answer": "Als u een lopende betwisting heeft, wordt dit op meerdere plaatsen weergegeven:\n\n- In het gedeelte ‘To-do’ op de startpagina van uw Partnerportaal.\n- Als melding in het gedeelte ‘Betwisting' op de pagina ‘Openstaande betwistingen’, met het aantal betwistingen waarop moet worden gereageerd.\n\nU kunt er ook voor kiezen om voor elke betwisting een e-mail te ontvangen door uw instellingen in ‘Instellingen e-mail en betwistingen’ aan te passen naar ‘E-mailnotificatie voor elke betwisting’.\n"
					},
					"howToAvoidDisputesFees": {
						"question": "Hoe kan ik betwistingskosten vermijden?",
						"answer": "Om betwistingskosten te vermijden:\n- Verwerk retourzendingen of terugbetalingen onmiddellijk.\n- Stel een Drempel voor terugbetaling in onder ‘Instellingen E-mail & betwistingen’.\n- Controleer regelmatig ‘Alle betwistingen’.\n- Reageer op betwistingen met relevante informatie.\n- Antwoord voordat de deadline verstrijkt.\n"
					}
				}
			},
			"settlements": {
				"payoutNotFound": {
					"title": "Uitbetaling voor specifieke bestelling niet gevonden",
					"orderNumberNotFound": {
						"question": "Bestel- of referentienummer verschijnt niet in verslag",
						"answer": "Als u geen betaling voor een specifieke bestelling kunt vinden:\n- Controleer of het bestel- of referentienummer klopt.\n- Controleer of de bestelling is geactiveerd.\n- Controleer of de bestelling binnen de activeringsperiode van het verslag valt.\n- Houd er rekening mee dat bestellingen die voor de afrekening zijn geannuleerd niet in het verslag verschijnen.\n"
					},
					"checkIfPaidForOrder": {
						"question": "Hoe kan ik zien of ik voor een bepaalde bestelling betaald heb gekregen?",
						"answer": "Zoek in het bestellingenbeheer van het Partnerportaal naar de bestelling en controleer of het bestel- of referentienummer klopt. Controleer onder het referentienummer of de bestelling is geactiveerd. Als de bestelling is geactiveerd, wordt de status weergegeven als in behandeling of uitbetaald. Klik voor uitbetaalde bestellingen op 'Zie afrekening' om het bijbehorende verslag te bekijken."
					}
				},
				"payouts": {
					"title": "Uitbetalingen",
					"whenIsNext": {
						"question": "Wanneer is mijn volgende uitbetaling?",
						"answer": null
					},
					"whyCalculationNotMatch": {
						"question": "Waarom komt mijn eigen berekening niet overeen met de ontvangen uitbetalingen?",
						"answer": "Op grond van het webshopcontract worden kosten zoals transactiekosten en kosten voor te late retourzendingen in mindering gebracht. Daarom kunnen uw berekeningen afwijken van de uitbetalingen van Klarna. Om de aftrekposten duidelijk te zien, raden we u aan de pdf-versie van het afrekeningsverslag te openen."
					},
					"whenGetPaid": {
						"question": "Wanneer word ik uitbetaald?",
						"answer": null
					},
					"missingPayout": {
						"question": "Mijn uitbetaling ontbreekt",
						"answer": "Voordat u contact opneemt met ons supportkanaal. Houd er rekening mee dat ontbrekende uitbetalingen te wijten kunnen zijn aan het volgende:\n\n- Klarna verstuurt de betaling op dezelfde dag als het verslag wordt gegenereerd, het kan nog een paar dagen duren voordat het geld op uw bankrekening verschijnt.\n- U heeft voor deze activeringsperiode geen bestellingen geactiveerd.\n- U heeft onlangs uw bankgegevens bij Klarna aangepast, waardoor de betaling mogelijk naar uw oude bankrekening is gegaan. Dit kan gebeuren als de wijziging niet is verwerkt voordat de betaling wordt uitgevoerd.\n- Het bestel- of referentienummer dat u zoekt is onjuist, controleer het bestel- of referentienummer.\n- Uw betalingen worden vastgehouden door Klarna. U had apart via een e-mail op de hoogte moeten worden gesteld van de blokkering. Deze e-mails worden meestal naar een beheerder gestuurd\n"
					},
					"howIsPayoutCalculated": {
						"question": "Hoe wordt mijn uitbetaling berekend?",
						"answer": "De uitbetaling wordt berekend op basis van bestellingen die binnen de activeringsperiode zijn geactiveerd, minus vergoedingen, retourkosten en andere kosten. Een afrekeningsverslag geeft een gedetailleerd overzicht van deze bedragen.\nAls de terugbetalingen hoger zijn dan de verkopen in een activeringsperiode, wordt het overschot afgetrokken van de volgende uitbetaling.\n"
					}
				},
				"howPayoutsWork": {
					"question": "Hoe werken de uitbetalingen?",
					"answer": "Het gedeelte Afrekeningen biedt een overzicht van hoe uitbetalingen werken, inclusief betalingsschema's, activeringsperioden en uitbetalingsdatums. U kunt transactierapporten voor elke uitbetaling downloaden in pdf- of CSV-formaat en details voor specifieke betalingen bekijken of downloaden door op hun betalingsreferenties te klikken."
				},
				"payoutSchedule": {
					"title": "Uitbetalingsschema",
					"whyDelay": {
						"question": "Waarom is er een uitbetalingvertraging?",
						"answer": "Het belangrijkste doel is te voorkomen dat geld voor facturen heen en weer wordt gestuurd tussen Klarna en u, en ook om gevallen te dekken waarin u een geactiveerde bestelling zou terugbetalen."
					},
					"changePayoutDelay": {
						"question": "Ik wil mijn uitbetalingsvertraging wijzigen",
						"answer": "We hebben zowel interne controles als algemene criteria waaraan moet worden voldaan. Deze algemene criteria zijn onder andere:\n- U moet volledig zijn aangemeld bij Klarna\n- Uw eerste besteldatum is langer dan 100 dagen geleden\n- U bedrijf moet langer dan 6 maanden bestaan.\n\nKlarna heeft ook informatie nodig van een beheerder over...\n- De gewenste nieuwe uitbetalingsvertraging en waarom de gewijzigde uitbetalingsvertraging nodig is\n- Op welke webwinkel-ID (MID) de wijziging betrekking heeft\nAanvullende criteria kunnen van toepassing zijn, beslissingen worden op individuele basis genomen.\n"
					}
				},
				"generatingReports": {
					"title": "Verslagen genereren",
					"howToSeeReports": {
						"question": "Hoe zie ik de verslagen voor specifieke periodes?",
						"answer": "U kunt uw verslagen aanpassen voor specifieke perioden met behulp van de sectie Aangepaste verslagen in het gedeelte Afrekeningen."
					},
					"convertCsvToExcel2": {
						"answer": "Volg deze stappen\n1. Open Excel, klik op ‘Bestand’ > ‘Nieuwe werkmap’.\n"
					},
					"convertCsvToExcel": {
						"question": "Hoe zet ik een CSV-rapport om in een Excel-spreadsheet?",
						"answer": "1. Open Excel, klik op ‘Bestand’ > ‘Nieuwe werkmap’.\n2. Ga naar het tabblad ‘Gegevens’ en selecteer ‘Van tekst’.\n3. Kies het gewenste .CSV-bestand en klik op ‘Gegevens ophalen’.\n4. Selecteer in de wizard Tekst importeren de optie ‘Afgebakend’ en klik op ‘Volgende’.\n5. Selecteer onder ‘Scheidingstekens’ ‘Puntkomma’ en stel ‘Tekstkwalificatie’ in op ‘geen’. Klik op ‘Afronden’.\n6. Klik in het dialoogvenster ‘Gegevens importeren’ op ‘OK’ om de gegevens in cel A1 te plaatsen.\n\nGoed om te weten: CSV-verslagen in Webshop portaal gebruiken een puntkomma (;) als scheidingsteken. Raadpleeg uw spreadsheetsoftware om te zien hoe u het standaard scheidingsteken kunt aanpassen.\n"
					}
				},
				"payoutAndFeeCalculation": {
					"title": "Uitbetaling en berekening van vergoedingen",
					"howAreFeesCalculated": {
						"question": "Hoe worden kosten berekend?",
						"answer": "Vergoedingen die in mindering worden gebracht op Klarna-uitbetalingen worden beschreven in uw webshopcontract, met extra vergoedingen zoals die voor te late retourzendingen gedetailleerd in het ‘Vergoedingen’ gedeelte van uw uitbetalings-/afrekeningsverslagen.\n\nBeschrijvingen van vergoedingen in uw verslagen aanpassen:\n1. Ga naar Afrekeningsverslagen > Verslagen configureren > Nieuwe configuratie toevoegen.\n2. Selecteer voor CSV-verslagen ‘Gedetailleerd transactietype’ onder Transactielijst.\n3. Voor pdf-verslagen selecteert u ‘Bestelgegevens opnemen’ in de instellingen voor lay-out- en inhoud.\n\nZorg ervoor dat u de instellingen voor elke webwinkel-ID en elk kanaal (SFTP, Webshop portaal, API) afzonderlijk aanpast.\n"
					},
					"howIsPayoutCalculated": {
						"question": "Hoe wordt mijn uitbetaling berekend?",
						"answer": "De uitbetaling wordt berekend op basis van bestellingen die binnen de activeringsperiode zijn geactiveerd, minus vergoedingen, retourkosten en andere kosten. Een afrekeningsverslag geeft een gedetailleerd overzicht van deze bedragen.\n\nAls de terugbetalingen hoger zijn dan de verkopen in een activeringsperiode, wordt het overschot afgetrokken van de volgende uitbetaling.\n"
					}
				}
			},
			"options": {
				"payout": "Uitbetaling",
				"orders": "Bestellingen",
				"settlements": "Afrekeningen",
				"disputes": "Betwistingen",
				"otherIssue": "Andere problemen",
				"yes": "Ja",
				"no": "Nee",
				"partially": "Gedeeltelijk",
				"iNeedMoreHelp": "Ik heb meer hulp nodig",
				"thatHelped": "Dat heeft geholpen",
				"contactSupportChannel": "Neem contact op met ondersteuningskanalen"
			},
			"button": {
				"label": "Ondersteuning krijgen"
			},
			"contact": {
				"chat": "Chatten met een medewerker",
				"ticket": "Een ticket indienen",
				"times": {
					"minutes": "Wachttijd: {time} minuut/minuten",
					"hours": "Wachttijd: {time} uur",
					"days": "Wachttijd: {time} dag(en)"
				}
			}
		}
	}
};