module.exports = {
	"es": {
		"core": {
			"alert": {
				"message": "Se ha producido un error. Vuelve a intentarlo.",
				"correlationIdLabel": "Id. de correlación:"
			},
			"header": {
				"title": "Portal del comerciante"
			},
			"usermenu": {
				"language": "Idioma",
				"country": "País",
				"timezone": "Zona horaria",
				"logout": "Cerrar sesión",
				"profile": "Perfil",
				"changeProfile": "Editar",
				"tooltip": "Tu perfil"
			},
			"appsmenu": {
				"defaultTooltip": "Tus aplicaciones",
				"initialTooltip": "Aquí encontrarás tus aplicaciones.",
				"newAppsTooltip": "¡Tenemos nuevas aplicaciones para ti!"
			},
			"two-factor-opt-in": {
				"title": "Establece la autenticación de doble factor",
				"intro-paragraph": "No tienes la autenticación en dos pasos (2FA) activada en tu cuenta del Portal del comercio. A partir del <b>16 de enero 2023</b>, la 2FA será obligatoria para todos los usuarios que quieran acceder al Portal del comercio. Puedes obtener más información sobre la importancia de la 2FA, cómo configurarla y las respuestas a las preguntas más frecuentes <faqLink>aquí</faqLink>.",
				"help-link-text": "Haz clic aquí",
				"help-text": "para más información sobre la forma de establecerla y la protección que te ofrece.",
				"warning-text": "Debes tener un móvil inteligente y conexión a internet para continuar",
				"continue-button": "Iniciar la configuración"
			},
			"kuasPrompter": {
				"title": "Has seleccionado",
				"content": "ID de tiendas {count} ",
				"contentTooltip": "Haz clic para copiar las ID de los comerciantes al portapapeles",
				"button": "Cambiar los ID de tiendas seleccionados"
			},
			"notification": {
				"title": "Anuncio",
				"content": {
					"uptodate": "¡Estás al día!"
				}
			},
			"languages": {
				"cs": "Checo",
				"da": "Danés",
				"de": "Alemán",
				"el": "Griego",
				"en": "Inglés",
				"en_GB": "Inglés (GB)",
				"en_US": "Inglés (EE. UU.)",
				"es": "Español",
				"fi": "Finés",
				"fr": "Francés",
				"it": "Italiano",
				"nl": "Neerlandés",
				"no": "Noruego",
				"pl": "Polaco",
				"pt": "Portugués",
				"sv": "Sueco"
			},
			"countries": {
				"at": "Austria",
				"au": "Australia",
				"be": "Bélgica",
				"ca": "Canadá",
				"ch": "Suiza",
				"cz": "República Checa",
				"de": "Alemania",
				"dk": "Dinamarca",
				"ee": "Estonia",
				"es": "España",
				"fi": "Finlandia",
				"fr": "Francia",
				"gb": "Gran Bretaña",
				"gr": "Grecia",
				"ie": "Irlanda",
				"it": "Italia",
				"mx": "México",
				"nl": "Países Bajos",
				"no": "Noruega",
				"se": "Suecia",
				"us": "Estados Unidos",
				"nz": "Nueva Zelanda",
				"pl": "Polonia",
				"pt": "Portugal",
				"ro": "Rumanía"
			},
			"selectLanguage": {
				"title": "Selecciona tu idioma",
				"paragraph": "Será tu idioma predeterminado",
				"submit": "De acuerdo"
			},
			"selectCountry": {
				"title": "Selecciona tu país",
				"paragraph": "Este será tu país predeterminado.",
				"submit": "De acuerdo"
			},
			"updateProfile": {
				"title": "Actualizar perfil",
				"submit": "De acuerdo",
				"emailLabel": "Dirección de e-mail",
				"firstNameLabel": "Nombre",
				"lastNameLabel": "Apellido",
				"resetPasswordLabel": "Contraseña",
				"resetPasswordSelectedValue": "Restablecer",
				"resetPasswordSuccessLabel": "Mensaje de restablecimiento enviado correctamente.",
				"resetPasswordErrorLabel": "Error al enviar el mensaje. Vuelve a intentarlo más tarde.",
				"error": "Error al actualizar detalles",
				"changeCookiePolicyLabel": "Política de cookies",
				"changeCookiePolicySelectedValue": "Cambiar",
				"securitySectionTitle": "Seguridad",
				"preferencesSectionTitle": "Preferencias",
				"mfaLabel": "Autenticación en dos pasos",
				"mfaDescription": "Requiere iniciar sesión con una contraseña temporal de un solo uso. Recomendamos que se active.",
				"twoFactor": {
					"reset": "Cambiar tu 2FA",
					"isEnabled": "Activar",
					"isDisabled": "Desactivada",
					"title": "Autenticación en dos pasos (2FA)",
					"description": "Permite a los usuarios iniciar sesión con la seguridad adicional de tener una contraseña temporal de un solo uso.",
					"tooltip": "Asegúrate de tener al menos el permiso {permission} para la app {app} para cada tienda a la que el usuario puede acceder para ajustar la configuración de la autenticación en dos pasos.",
					"confirmationDialog": {
						"closeButton": "Cerrar",
						"confirmationButton": "Sí, continuar",
						"cancelButton": "Cancelar",
						"resetSuccess": {
							"title": "El dispositivo 2FA se ha restablecido correctamente.",
							"description": "La próxima vez que el usuario inicie sesión, necesitará configurar un nuevo dispositivo con la 2FA."
						},
						"resetConfirmation": {
							"title": "¿Seguro que quieres restablecer el dispositivo 2FA de este usuario?",
							"description": "Si continúas, restablecerás el dispositivo usado para generar los códigos 2FA para este usuario. La próxima vez que el usuario inicie sesión, necesitará configurar un nuevo dispositivo con la 2FA."
						}
					}
				}
			},
			"footer": {
				"copyright": {
					"eu": "Copyright © 2005-{currentYear} Klarna Bank AB (publ). Todos los derechos reservados",
					"oc": "Copyright © 2005-{currentYear} Klarna Australia Pty Ltd, ABN # 82 635 912 579",
					"ap": "Copyright © 2005-{currentYear} Klarna Australia Pty Ltd, ABN # 82 635 912 579",
					"us": "Copyright © 2005-{currentYear} Klarna Inc. NMLS #1353190, 629 N. High Street, Third Floor, Columbus, OH 43215"
				},
				"terms": "Condiciones generales"
			},
			"accessDenied": {
				"title": "Necesitas acceso adicional para esta página.",
				"message": "Ponte en contacto con tu administrador o con Klarna si tienes alguna pregunta."
			},
			"midSelector": {
				"label": "Tienda"
			},
			"storeSelector": {
				"label": "Tienda"
			},
			"storesSelector": {
				"closeText": "Hecho",
				"closeTextWithSelection": "Aplicar # selección(ones)",
				"deselectAllOptionText": "Borrar selección",
				"emptyStateText": "No se encontraron tiendas",
				"label": "Tiendas",
				"searchFieldLabel": "Buscar por tienda",
				"selectAllOptionText": "Seleccionar todas las tiendas",
				"selectedAllText": "Todas las tiendas",
				"selectedText": "# tiendas"
			},
			"pagination": {
				"ofLabel": "de"
			},
			"singleDatePicker": {
				"selectDate": "Seleccionar fecha"
			},
			"dateRangePicker": {
				"startLabel": "Fecha de inicio",
				"endLabel": "Fecha de fin",
				"months": {
					"january": "Enero",
					"february": "Febrero",
					"march": "Marzo",
					"april": "Abril",
					"may": "Mayo",
					"june": "Junio",
					"july": "Julio",
					"august": "Agosto",
					"september": "Septiembre",
					"october": "Octubre",
					"november": "Noviembre",
					"december": "Diciembre"
				},
				"weekDaysShort": {
					"monday": "L",
					"tuesday": "M",
					"wednesday": "X",
					"thursday": "J",
					"friday": "V",
					"saturday": "S",
					"sunday": "D"
				}
			},
			"regionSelector": {
				"noStores": "No hay tiendas asignadas",
				"andMore": "y mucho más",
				"regions": {
					"eu": "Europa",
					"us": "América del Norte",
					"ap": "Asia-Pacífico"
				}
			},
			"regionSelectorBar": {
				"modal": {
					"title": "Cambiar región",
					"description": "Selecciona una región con tiendas asociadas para mostrar datos pertinentes.",
					"buttonText": "Confirmar"
				},
				"selectedRegion": "Seleccionar región",
				"changeButton": "Cambiar"
			},
			"appIcon": {
				"newLabel": "NUEVO"
			},
			"appCategory": {
				"dayToDay": "Para tu día a día",
				"nko": "Klarna Online",
				"tools": "Herramientas",
				"preferences": "Preferencias"
			},
			"apps": {
				"account-information-fe": {
					"title": "Información de la cuenta",
					"description": "Consulta los datos de tu banco y tu negocio y los acuerdos que has firmado."
				},
				"home-fe": {
					"title": "Página de inicio de Pagos"
				},
				"boosters-home-fe": {
					"title": "Empezar"
				},
				"marketing-home-fe": {
					"title": "Página de inicio de Marketing"
				},
				"nko-app": {
					"title": "Apps online de Klarna"
				},
				"orders-fe": {
					"title": "Gestión de pedidos",
					"description": "Consulta y gestiona tus pedidos de Klarna.",
					"nkoLinkText": "Acceso a pedidos antiguos"
				},
				"nko-orders": {
					"title": "Pedidos (KO)",
					"description": "Consulta y gestiona los pedidos realizados a través de Klarna Online."
				},
				"settlements-fe": {
					"title": "Liquidaciones",
					"description": "Consulta tus transacciones y genera informes para la reconciliación de datos.",
					"nkoLinkText": "Acceso a pedidos antiguos"
				},
				"nko-settlements": {
					"title": "Liquidaciones (KO)",
					"description": "Consulta informes de pedidos y pagos de Klarna Online."
				},
				"logs-app-fe": {
					"title": "Registros del desarrollador",
					"description": "Consulta registros API para resolver problemas con la integración."
				},
				"users-fe": {
					"title": "Gestión de usuarios",
					"description": "Invita a nuevos usuarios y gestiona los existentes en el Portal."
				},
				"settings-fe": {
					"title": "Configuración de pagos",
					"description": ""
				},
				"nko-settings": {
					"title": "Ajustes (KO)",
					"description": "Gestiona los ajustes de la tienda, informes y datos de contacto."
				},
				"seller-fe": {
					"title": "En la tienda",
					"description": "Crea nuevos pedidos con la solución en tienda de Klarna."
				},
				"products-fe": {
					"title": "Incorporación",
					"description": "Configura tu cuenta para habilitar los pagos."
				},
				"upstream-fe": {
					"title": "La mensajería en la web",
					"description": "Vende más con ofertas financieras personalizadas."
				},
				"lending-fe": {
					"title": "Business Financing",
					"description": "Financiación comercial sencilla."
				},
				"branding-fe": {
					"title": "Gestión de marca",
					"description": "Controla la manera en que se presenta tu marca dentro de Klarna."
				},
				"disputes-fe": {
					"title": "Disputas",
					"description": "Gestiona tus disputas."
				},
				"logistics-fe": {
					"title": "Integraciones de envíos",
					"description": "Gestiona toda la logística desde un único lugar."
				},
				"developers": {
					"title": "Desarrolladores"
				},
				"klarna-docs": {
					"title": "Klarna Docs"
				},
				"support": {
					"title": "Respaldo",
					"description": "Consultar y gestionar tus peticiones de ayuda."
				},
				"integration-guides-fe": {
					"title": "Guías de integración",
					"description": ""
				},
				"klarna-search-b2b-portal": {
					"title": "Buscar y comparar",
					"description": "Empieza a usar el potente motor de búsqueda y las comparaciones de compras de Klarna. Obtén estadísticas y herramientas diseñadas para potenciar tu visibilidad."
				},
				"siwk-fe": {
					"title": "Inicia sesión con Klarna",
					"description": "Gestiona tu integración de inicio de sesión con Klarna."
				},
				"kec-fe": {
					"title": "Pago exprés",
					"description": "Gestiona tu integración de pago exprés."
				},
				"partner-self-service": {
					"title": "Responsable de la tienda",
					"description": "Gestiona tu tienda Klarna.",
					"nkoLinkText": "Ver y gestionar los ajustes de tu tienda"
				}
			},
			"ui": {
				"tabs": {
					"home": "Formas de pago",
					"growth": "Growth Marketing",
					"boosters": "Potenciadores de la conversión",
					"badge": "Nuevo"
				},
				"buttons": {
					"backButton": "Atrás"
				}
			},
			"errorBoundary": {
				"contact": "Intenta volver a cargar la página. {br}Si el error continúa, contacta con el <supportLink>Servicio de atención para las tiendas</supportLink> e indica el ID de error {errorId}.",
				"errorDescription": "Código de error: {errorId}",
				"reloadButton": "Volver a cargar",
				"title": "Lo sentimos, se ha producido un error"
			},
			"maintenance": {
				"pageTitle": "Mantenimiento",
				"title": "Se está realizando el mantenimiento programado de Merchant Portal",
				"summary": "Volvemos enseguida",
				"scheduledSummary": "Volveremos a las {until}"
			},
			"errorTemplates": {
				"errorDescription": "Error: {code}",
				"backButton": "Atrás",
				"reloadButton": "Volver a cargar",
				"default": {
					"errorDescription": "Código de error: {errorId}",
					"title": "Lo sentimos, se ha producido un error",
					"contact": "Intenta volver a cargar la página. Si el error continúa, contacta con el<supportLink>Servicio de atención para las tiendas</supportLink> e indica el ID de error {errorId}."
				},
				"internalServerError": {
					"title": "¡Vaya! Se ha producido un error",
					"message": "Por algún error nuestro, no se ha procesado tu solicitud. Puedes volver a intentarlo ahora o más tarde."
				},
				"notFound": {
					"title": "¡Aquí no hay nada!",
					"message": "Esta página no existe o la dirección que escribiste en el navegador no es correcta. Comprueba la dirección y vuelve a intentarlo."
				},
				"forbidden": {
					"title": "No tienes acceso a esta página",
					"message": "Tu ID de tienda no tiene acceso autorizado a esta página. Podría tratarse de un error, así que contacta con el Servicio de atención para las tiendas o con el administrador de la cuenta de tu empresa."
				}
			},
			"codeSnippet": {
				"clipboardButtonLabel": "Copiar al portapapeles",
				"clipboardButtonLabelSuccess": "¡Copiado!"
			}
		},
		"blackFriday": {
			"v1": {
				"title": "Black Friday",
				"intro": "Se acerca uno de los días comerciales más importantes del año. Durante el Black Friday, le ayudaremos con estadísticas de sus ventas casi en tiempo real. ¡Preparémonos para que todo salga bien!",
				"countdown": "Comenzaremos en #dd #hh #mm #ss…",
				"box1": {
					"title": "Gasto multiplicado por 3,5*",
					"text": "El año pasado, los compradores* de Klarna gastaron 3,5 veces más, por término medio, durante el Black Friday que en cualquier otro día.",
					"footnote": "*Media del total de categorías y zonas de comerciantes que utilizan Klarna."
				},
				"box2": {
					"title": "Volumen de ventas multiplicado por 2,5*",
					"text": "El pasado año, los comerciantes de Klarna generaron un volumen de ventas 2,5 veces mayor en el Black Friday que en cualquier otro día.",
					"footnote": "*Media del total de categorías y zonas de comerciantes que utilizan Klarna."
				},
				"sectionTitle": "Incremente sus ventas del Black Friday",
				"marketing": {
					"title": "Anuncie su negocio antes del Black Friday y durante el Black Friday",
					"text": "Atraiga a más compradores hacia su tienda durante la campaña comercial del Black Friday utilizando estos recursos gratuitos en las redes sociales y por e-mail."
				},
				"branding": {
					"title": "Consiga visibilidad en la Klarna app",
					"text": "Cada mes, 12 millones de compradores utilizan la Klarna app. Para asegurarse un lugar destacado en la app este Black Friday añada y actualice la información de su marca aquí, en el portal del comercio."
				},
				"cta": "Comenzar",
				"close": "Cerrar"
			},
			"v2": {
				"title": "Black Friday week",
				"orderVolume": "Volumen de ventas",
				"orderCountTitle": "Pedidos creados",
				"orderCount": "{count} pedidos",
				"box": {
					"title": "¿Quieres una mayor tasa de conversión?",
					"subtitle1": "La mensajería en la web",
					"subtitle2": "Mensajes promocionales didácticos.",
					"text": "Nuestra herramienta la mensajería en la web te ayuda a aumentar las ventas y el valor medio de los pedidos mediante mensajes promocionales. Ten bien visibles las opciones de pago para los compradores, desde el momento en que visiten tu sitio web y durante el proceso de compra.",
					"cta": "Comenzar"
				},
				"selector": {
					"done": "Hecho",
					"applySelection": "Aplicar # selección(ones)",
					"selectAll": "Seleccionar todo",
					"allOptions": "Todas las opciones seleccionadas",
					"someOptions": "# opciones seleccionadas",
					"storeMultiSelectLabel": "Elegir # tiendas",
					"allStores": "Todas las tiendas"
				},
				"currencyLabel": "Elegir moneda",
				"storeLabel": "Elegir tienda"
			},
			"v3": {
				"title": "Black Friday week",
				"text": "Estas son las ventas durante una de las semanas más importantes del año.",
				"summaryTitle": "Summary",
				"orderVolume": "Volumen total",
				"orderCount": "Pedidos totales",
				"summaryRange": "23-30 November",
				"comparisonLastWeek": "comparado con el mismo día de la semana pasada",
				"tabs": {
					"monday": "Lunes",
					"tuesday": "Martes",
					"wednesday": "Miércoles",
					"thursday": "Jueves",
					"blackFriday": "Black Friday",
					"saturday": "Sábado",
					"sunday": "Domingo",
					"cyberMonday": "Cyber Monday"
				}
			}
		},
		"mpSatSurvey": {
			"marble": {
				"topbarButtonLabel": "Comentarios sobre nuestro nuevo look",
				"step1": {
					"welcome": "¿Te gusta nuestro nuevo aspecto?",
					"satisfied": "Muy satisfecho",
					"unsatisfied": "Muy poco satisfecho"
				},
				"step2": {
					"textAreaLabel": "Otros (tu opinión)",
					"textAreaMaxLength": "Límite máximo alcanzado",
					"submit": "Enviar",
					"content": {
						"titlePositive": "¿Qué es lo que más te gusta?",
						"titleNegative": "¿Qué es lo que menos te gusta?",
						"tags": {
							"color": "Color y estilo",
							"navigation": "Navegación",
							"names": "Nombre de las páginas",
							"structure": "Estructura de las páginas"
						}
					}
				},
				"step3": {
					"title": "¡Gracias!",
					"description": "Agradecemos tus comentarios."
				}
			},
			"buttonLabel": "Ofrecer feedback",
			"errorMessage": "Se ha producido un error al enviar tu feedback.",
			"retry": "Reintentar",
			"step1": {
				"welcome1": "¿Cómo de satisfecho estás",
				"welcome2": "con el Portal del comercio?",
				"emojiSelector": {
					"angry": "Muy insatisfecho",
					"unhappy": "Un poco insatisfecho",
					"neutral": "Neutral",
					"happy": "Satisfecho",
					"love": "Me encanta"
				},
				"remindMeText": "¿No quieres dar tu opinión?",
				"remindMeLink": "Recuérdame esta encuesta más tarde.",
				"dontShowAgainLink": "No volver a mostrar de nuevo."
			},
			"step2": {
				"textAreaLabel": "Otros (tu opinión)",
				"textAreaMaxLength": "Límite alcanzado",
				"submit": "Enviar",
				"content": {
					"negative": {
						"title": "Sentimos que opines así.",
						"description": "¿Qué aspectos del Portal del comercio deberíamos mejorar? (Selecciona todos los que apliquen)",
						"tags": {
							"loading": "Tiempo de carga",
							"menu": "Organización de los menús",
							"mobile": "Versión para móviles",
							"filter": "Filtro de datos",
							"complexity": "Eficiencia"
						}
					},
					"positive": {
						"title": "¡Qué bien!",
						"description": "Queremos seguir mejorando. ¿Qué aspectos mejorarías para disfrutar aún más de tu experiencia en el Portal del comercio?",
						"tags": {
							"views": "Vistas personalizadsa",
							"looks": "El diseño",
							"tours": "Tours guiados",
							"shortcuts": "Atajos para las tareas"
						}
					}
				}
			},
			"step3": {
				"title": "¡Gracias! Una última cosa.",
				"content1": "Nos gustaría invitarte a una entrevista para obtener más información sobre tu experiencia al usar nuestro Portal del comercio.",
				"content2": "¿Te apetece participar?",
				"content3": "En caso afirmativo, nos pondremos en contacto contigo a través del correo electrónico registrado para concertar la entrevista.",
				"yes": "Sí, por favor",
				"no": "No, gracias"
			},
			"step4": {
				"yesTitle": "¡Genial! Estaremos en contacto.",
				"noTitle": "Entendido. ¡Gracias!",
				"content1": "Gracias por responder estas preguntas.",
				"content2": "Ya puedes cerrar esta ventana."
			}
		},
		"boost": {
			"integrationTypeModal": {
				"title": "¿Cómo quieres configurar {productName}?",
				"labels": {
					"plugin": "Configurar con un plugin",
					"web": "Configurar con SDK web"
				}
			}
		}
	}
};