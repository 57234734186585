module.exports = {
	"cs": {
		"credentials": {
			"addOriginModal": {
				"buttonPrimaryText": "Registrovat",
				"buttonSecondaryText": "Zrušit",
				"description": "Zaregistrujte si prosím doménu původu, na které chcete používat Web SDK. Pokud potřebujete zaregistrovat více domén, budete tak moci učinit později.",
				"errorMessage": "Došlo k chybě. Zkuste to prosím později nebo kontaktujte podporu, pokud problém potrvá.",
				"inputLabel": "Zadejte zbytek adresy URL",
				"title": "Registrovat povolený původ"
			},
			"clientKeyBlock": {
				"mainText": "Identifikátor klienta je nastaven přímo v kódu s doménou původu, kterou jste zadali.",
				"secondaryText": "Pokud ji chcete dále spravovat, klikněte prosím na odkaz na",
				"linkDescription": "stránka s identifikátory klienta",
				"linkLabel": "Zavřít"
			},
			"clientKeyField": {
				"label": "Identifikátor klienta",
				"defaultErrorMessage": "Chybí identifikátor klienta"
			},
			"copyToClipboardButton": {
				"label": "Zkopírovat",
				"labelOnCopy": "Zkopírováno"
			},
			"originField": {
				"label": "Původní doména",
				"tooltipMainText": "Pokud chcete zaregistrovat další domény původu nebo spravovat stávající domény, přejděte na",
				"tooltipLinkLabel": "stránka s identifikátory klienta"
			},
			"originInputField": {
				"label": "Zadejte zbytek adresy URL",
				"validationError": "Zadejte prosím platnou adresu URL domény",
				"invalidProtocolError": "Adresa URL musí začínat http:// nebo https://"
			},
			"addApiKeySuccessModal": {
				"title": "",
				"description": "Následující přihlašovací údaje si uložte na svém zařízení nebo na jiném bezpečném místě.",
				"warning": "Heslo uvidíte jen tentokrát. Po zavření tohoto okna heslo zmizí.",
				"checkbox": "Uložil/a jsem si přihlašovací údaje do svého zařízení.",
				"download": "Stáhnout jako .txt"
			},
			"common": {
				"password": "Heslo",
				"username": "Uživatelské jméno",
				"close": "Zavřít"
			}
		}
	}
};