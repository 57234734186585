module.exports = {
	"cs": {
		"core": {
			"alert": {
				"message": "Něco se nepovedlo Zkuste to prosím znovu.",
				"correlationIdLabel": "ID korelace:"
			},
			"header": {
				"title": "Portál pro obchodníky"
			},
			"usermenu": {
				"language": "Jazyk",
				"country": "Země",
				"timezone": "Časové pásmo",
				"logout": "Odhlášení",
				"profile": "Profil",
				"changeProfile": "Upravit",
				"tooltip": "Váš profil"
			},
			"appsmenu": {
				"defaultTooltip": "Vaše aplikace",
				"initialTooltip": "Své aplikace najdete zde.",
				"newAppsTooltip": "Máme pro vás nové aplikace!"
			},
			"two-factor-opt-in": {
				"title": "Nastavení dvoufázového ověření",
				"intro-paragraph": "Na svém účtu na obchodním portálu nemáte povoleno dvoufaktorové ověřování (2FA). Od <b>16. ledna 2023</b> bude 2FA povinné pro všechny uživatele, aby mohli přistupovat k portálu obchodníka. Více informací o významu 2FA, o tom, jak jej nastavit, a odpovědi na časté otázky najdete <faqLink>zde</faqLink>.",
				"help-link-text": "Klikněte sem",
				"help-text": " a zjistěte více o nastavení tohoto ověření a o ochraně, kterou vám poskytuje.",
				"warning-text": "Než budete pokračovat, zkontrolujte, že máte chytrý telefon a přístup k internetu.",
				"continue-button": "Zahájit nastavení"
			},
			"kuasPrompter": {
				"title": "Vybral/a jste",
				"content": "{count} MID",
				"contentTooltip": "Kliknutím zkopírujete MID do schránky",
				"button": "Změnit vybraná MID"
			},
			"notification": {
				"title": "Oznámení",
				"content": {
					"uptodate": "Vše je aktuální!"
				}
			},
			"languages": {
				"cs": "Čeština",
				"da": "Dánština",
				"de": "Němčina",
				"el": "Řečtina",
				"en": "Angličtina",
				"en_GB": "Angličtina (VB)",
				"en_US": "Angličtina (US)",
				"es": "Španělština",
				"fi": "Finština",
				"fr": "Francouzština",
				"it": "Italština",
				"nl": "Holandština",
				"no": "Norština",
				"pl": "Polština",
				"pt": "Portugalština",
				"sv": "Švédština"
			},
			"countries": {
				"at": "Rakousko",
				"au": "Austrálie",
				"be": "Belgie",
				"ca": "Kanada",
				"ch": "Švýcarsko",
				"cz": "Česká republika",
				"de": "Německo",
				"dk": "Dánsko",
				"ee": "Estonsko",
				"es": "Španělsko",
				"fi": "Finsko",
				"fr": "Francie",
				"gb": "Velká Británie",
				"gr": "Řecko",
				"ie": "Irsko",
				"it": "Itálie",
				"mx": "Mexiko",
				"nl": "Nizozemsko",
				"no": "Norsko",
				"se": "Švédsko",
				"us": "Spojené státy",
				"nz": "Nový Zéland",
				"pl": "Polsko",
				"pt": "Portugalsko",
				"ro": "Rumunsko"
			},
			"selectLanguage": {
				"title": "Vyberte jazyk",
				"paragraph": "Vybraný jazyk bude nastaven jako výchozí.",
				"submit": "OK"
			},
			"selectCountry": {
				"title": "Vyberte zemi",
				"paragraph": "Vybraná země bude nastavena jako výchozí.",
				"submit": "OK"
			},
			"updateProfile": {
				"title": "Aktualizovat profil",
				"submit": "OK",
				"emailLabel": "E-mailová adresa",
				"firstNameLabel": "Jméno",
				"lastNameLabel": "Příjmení",
				"resetPasswordLabel": "Heslo",
				"resetPasswordSelectedValue": "Resetovat",
				"resetPasswordSuccessLabel": "E-mail s pokyny k resetování byl úspěšně odeslán.",
				"resetPasswordErrorLabel": "Chyby při odesílání e-mailu. Zkuste to znovu později.",
				"error": "Aktualizace podrobností se nezdařila",
				"changeCookiePolicyLabel": "Zásady používání souborů cookie",
				"changeCookiePolicySelectedValue": "Změnit",
				"securitySectionTitle": "Bezpečnost",
				"preferencesSectionTitle": "Preference",
				"mfaLabel": "Dvoufázové ověření",
				"mfaDescription": "Vyžaduje přihlášení pomocí časově omezeného jednorázového hesla. Aktivaci dvoufázového ověření vám doporučujeme.",
				"twoFactor": {
					"reset": "Změňte 2FA",
					"isEnabled": "Aktivované",
					"isDisabled": "Deaktivováno",
					"title": "Dvoufázové ověření (2FA)",
					"description": "Uživatelé se tak mohou přihlašovat s dodatečným zabezpečením pomocí jednorázového hesla.",
					"tooltip": "Musíte mít přinejmenším oprávnění {permission} pro aplikaci {app} pro každý obchod, ke kterému má uživatel přístup, abyste mohli přepnout nastavení dvoufaktorového ověřování.",
					"confirmationDialog": {
						"closeButton": "Zavřít",
						"confirmationButton": "Ano, pokračovat",
						"cancelButton": "Zrušit",
						"resetSuccess": {
							"title": "Reset zařízení 2FA proběhl úspěšně.",
							"description": "Při příštím přihlášení bude muset uživatel nastavit nové zařízení pro 2FA."
						},
						"resetConfirmation": {
							"title": "Opravdu chcete resetovat zařízení 2FA tohoto uživatele?",
							"description": "Pokračováním resetujete zařízení používané ke generování kódů 2FA pro tohoto uživatele. Při příštím přihlášení bude muset uživatel nastavit nové zařízení pro 2FA."
						}
					}
				}
			},
			"footer": {
				"copyright": {
					"eu": "Copyright © 2005-{currentYear} Klarna Bank AB (publ). Všechna práva vyhrazena.",
					"oc": "Copyright © 2005-{currentYear} Klarna Australia Pty Ltd, ABN # 82 635 912 579",
					"ap": "Copyright © 2005-{currentYear} Klarna Australia Pty Ltd, ABN # 82 635 912 579",
					"us": "Copyright © 2005-{currentYear} Klarna Inc. NMLS #1353190, 629 N. High Street, Third Floor, Columbus, OH 43215"
				},
				"terms": "Smluvní podmínky"
			},
			"accessDenied": {
				"title": "Potřebujete dodatečný přístup na tuto stránku.",
				"message": "Pokud máte nějaké otázky, kontaktujte svého správce nebo Klarnu."
			},
			"midSelector": {
				"label": "Obchod"
			},
			"storeSelector": {
				"label": "Obchod"
			},
			"storesSelector": {
				"closeText": "Hotovo",
				"closeTextWithSelection": "Použít následující počet výběrů: #",
				"deselectAllOptionText": "Vymazat výběr",
				"emptyStateText": "Obchod nebyl nalezen",
				"label": "Obchody",
				"searchFieldLabel": "Hledat podle obchodu",
				"selectAllOptionText": "Hledat všechny obchody",
				"selectedAllText": "Všechny obchody",
				"selectedText": "počet obchodů: #"
			},
			"pagination": {
				"ofLabel": "z"
			},
			"singleDatePicker": {
				"selectDate": "Vyberte datum"
			},
			"dateRangePicker": {
				"startLabel": "Datum zahájení",
				"endLabel": "Datum ukončení",
				"months": {
					"january": "Leden",
					"february": "Únor",
					"march": "Březen",
					"april": "Duben",
					"may": "Květen",
					"june": "Červen",
					"july": "Červenec",
					"august": "Srpen",
					"september": "Září",
					"october": "Říjen",
					"november": "Listopad",
					"december": "Prosinec"
				},
				"weekDaysShort": {
					"monday": "M",
					"tuesday": "Út",
					"wednesday": "St",
					"thursday": "Čt",
					"friday": "Pá",
					"saturday": "So",
					"sunday": "Ne"
				}
			},
			"regionSelector": {
				"noStores": "Žádné přiřazené obchody",
				"andMore": "a další",
				"regions": {
					"eu": "Evropa",
					"us": "Severní Amerika",
					"ap": "Asie a Tichomoří"
				}
			},
			"regionSelectorBar": {
				"modal": {
					"title": "Změnit region",
					"description": "Pro zobrazení relevantních dat vyberte region s příslušnými obchody.",
					"buttonText": "Potvrdit"
				},
				"selectedRegion": "Vybraný region",
				"changeButton": "Změnit"
			},
			"appIcon": {
				"newLabel": "NOVINKA"
			},
			"appCategory": {
				"dayToDay": "Pro každodenní život",
				"nko": "Klarna Online",
				"tools": "Nástroje",
				"preferences": "Preference"
			},
			"apps": {
				"account-information-fe": {
					"title": "Informace o účtu",
					"description": "Najdi své obchodní a bankovní údaje a smlouvy, které jsi podepsal/a."
				},
				"home-fe": {
					"title": "Domovská stránka plateb"
				},
				"boosters-home-fe": {
					"title": "Pusťte se do toho"
				},
				"marketing-home-fe": {
					"title": "Domovská stránka Marketingu"
				},
				"nko-app": {
					"title": "Online aplikace Klarna"
				},
				"orders-fe": {
					"title": "Manažer objednávky",
					"description": "Vyhledávejte a spravujte své objednávky od společnosti Klarna.",
					"nkoLinkText": "Získejte přístup ke starším objednávkám"
				},
				"nko-orders": {
					"title": "Objednávky (KO)",
					"description": "Vyhledávejte a spravujte objednávky vytvořené v aplikaci Klarna Online."
				},
				"settlements-fe": {
					"title": "Vypořádání",
					"description": "Získejte přehledy vytvořené na míru vašim obchodním potřebám.",
					"nkoLinkText": "Získejte přístup ke starším přehledům"
				},
				"nko-settlements": {
					"title": "Vypořádání (KO)",
					"description": "Najděte si přehledy objednávek a výplat z aplikace Klarna Online."
				},
				"logs-app-fe": {
					"title": "Protokoly vývojáře",
					"description": "Prohledávání protokolů API za účelem řešení problémů s integrací."
				},
				"users-fe": {
					"title": "Správce uživatele",
					"description": "Pozvěte nové uživatele a spravujte stávající uživatele na portálu."
				},
				"settings-fe": {
					"title": "Nastavení platby",
					"description": ""
				},
				"nko-settings": {
					"title": "Nastavení (KO)",
					"description": "Spravujte nastavení, přehledy a kontaktní údaje obchodu."
				},
				"seller-fe": {
					"title": "V obchodě",
					"description": "Vytvářejte nové objednávky pomocí řešení Klarna přímo na prodejně."
				},
				"products-fe": {
					"title": "Počáteční nastaven",
					"description": "Nastavte si účet a povolte platby."
				},
				"upstream-fe": {
					"title": "Zasílání zpráv na místě",
					"description": "Prodávejte více díky na míru šitým a přizpůsobeným finančním nabídkám."
				},
				"lending-fe": {
					"title": "Business Financing",
					"description": "Jednoduché financování podnikání."
				},
				"branding-fe": {
					"title": "Manažer značky",
					"description": "Převezměte kontrolu nad tím, jak je vaše značka prezentována v rámci společnosti Klarna."
				},
				"disputes-fe": {
					"title": "Spory",
					"description": "Řešte své spory."
				},
				"logistics-fe": {
					"title": "Integrace zasílání",
					"description": "Spravujte veškerou svou logistiku na jednom místě."
				},
				"developers": {
					"title": "Vývojáři"
				},
				"klarna-docs": {
					"title": "Klarna Docs"
				},
				"support": {
					"title": "Podpora",
					"description": "Sledujte a spravujte své žádosti o podporu."
				},
				"integration-guides-fe": {
					"title": "Průvodci integrací",
					"description": ""
				},
				"klarna-search-b2b-portal": {
					"title": "Hledat a porovnávat",
					"description": "Začněte s výkonným vyhledávačem a srovnáváním nákupů Klarna. Získejte přístup ke statistikám a nástrojům určeným ke zvýšení vaší viditelnosti."
				},
				"siwk-fe": {
					"title": "Přihlášení pomocí Klarny",
					"description": "Spravujte přihlašování pomocí integrace s Klarnou."
				},
				"kec-fe": {
					"title": "Funkce Express Checkout",
					"description": "Spravujte integraci služby Express Checkout."
				},
				"partner-self-service": {
					"title": "Manažer obchodu",
					"description": "Spravujte Klarna Store.",
					"nkoLinkText": "Zobrazení a správa nastavení vašeho obchodu"
				}
			},
			"ui": {
				"tabs": {
					"home": "Platby",
					"growth": "Růstový marketing",
					"boosters": "Posilovače konverze",
					"badge": "Novinka"
				},
				"buttons": {
					"backButton": "Zpět"
				}
			},
			"errorBoundary": {
				"contact": "Zkuste obnovit stránku. {br}Pokud problém přetrvává, kontaktujte <supportLink>podporu pro obchodníky</supportLink> a uveďte ID chyby {errorId}.",
				"errorDescription": "ID chyby – {errorId}",
				"reloadButton": "Aktualizovat",
				"title": "Bohužel došlo k chybě."
			},
			"maintenance": {
				"pageTitle": "Údržba",
				"title": "Portál obchodníka nyní prochází plánovanou údržbou.",
				"summary": "Brzy budeme zpět.",
				"scheduledSummary": "Budeme zpět do {until}."
			},
			"errorTemplates": {
				"errorDescription": "Chyba: {code}",
				"backButton": "Zpět",
				"reloadButton": "Aktualizovat",
				"default": {
					"errorDescription": "ID chyby – {errorId}",
					"title": "Bohužel došlo k chybě.",
					"contact": "Zkuste obnovit stránku. Pokud problém přetrvává, kontaktujte <supportLink>podporu pro obchodníky</supportLink> a uveďte ID chyby {errorId}."
				},
				"internalServerError": {
					"title": "Jejda, něco se pokazilo.",
					"message": "Z nějakého důvodu nebylo na naší straně možné provést váš požadavek. Zkuste to znovu nebo se sem vraťte později."
				},
				"notFound": {
					"title": "Nic tady není!",
					"message": "Tato stránka neexistuje nebo jste do prohlížeče zadal/a nesprávnou adresu. Zkontrolujte adresu a zkuste to znovu."
				},
				"forbidden": {
					"title": "Na tuto stránku nemáte přístup.",
					"message": "Vaše ID obchodníka nemá na tuto stránku přístup. Pokud si myslíte, že se jedná o chybu, kontaktujte podporu pro obchodníky nebo správce vašeho firemního účtu."
				}
			},
			"codeSnippet": {
				"clipboardButtonLabel": "Zkopírováno do schránky",
				"clipboardButtonLabelSuccess": "Zkopírováno!"
			}
		},
		"blackFriday": {
			"v1": {
				"title": "Black Friday",
				"intro": "Jeden z nejdůležitějších dnů pro maloobchodní prodej se blíží. Během Black Friday tady budeme, abychom vám poskytli okamžité statistiky vašeho prodeje. Mezitím se můžete začít připravovat na úspěch!",
				"countdown": "Začínáme za #dd #hh #mm #ss…",
				"box1": {
					"title": "3,5x vyšší útrata*",
					"text": "Vloni nakupující* s Klarnou utratili na Black Friday průměrně 3,5x více než v kterýkoli jiný den v roce.",
					"footnote": "*Průměr ze všech Klarna obchodních kategorií a regionů."
				},
				"box2": {
					"title": "2,5x vyšší objem prodeje*",
					"text": "Klarna obchodníci vloni na Black Friday vygenerovali 2,5x vyšší objem prodeje než v kterýkoli jiný den v roce.",
					"footnote": "*Průměr ze všech Klarna obchodních kategorií a regionů."
				},
				"sectionTitle": "Pojďme zvýšit váš Black Friday prodej",
				"marketing": {
					"title": "Rozpumpujte své podnikání před a během Black Friday",
					"text": "Přitáhněte do svého obchodu během nákupního období Black Friday více nakupujících pomocí těchto bezplatných zdrojů pro kampaně prostřednictvím sociálních médií a e-mailů."
				},
				"branding": {
					"title": "Buďte v aplikaci Klarna vidět",
					"text": "Aplikaci Klarna každý měsíc použije 12 milionů nakupujících. Přidejte a aktualizujte informace o své značce tady na portálu obchodníka a staňte se na Black Friday hvězdou této aplikace."
				},
				"cta": "Pusťte se do toho",
				"close": "Zavřít"
			},
			"v2": {
				"title": "Black week",
				"orderVolume": "Objem prodeje",
				"orderCountTitle": "Počet objednávek",
				"orderCount": "Počet objednávek: {count}",
				"box": {
					"title": "Chcete zvýšit svou konverzi?",
					"subtitle1": "Zasílání zpráv na místě",
					"subtitle2": "Reklamní sdělení, které vzdělává.",
					"text": "Náš nástroj zasílání zpráv na místě pomáhá pomocí reklamních sdělení zvyšovat vaše prodeje a průměrnou hodnotu objednávky. Dejte nakupujícím vědět, jaké mají možnosti platby, hned jak přijdou na vaši stránku a během jejich nákupní cesty.",
					"cta": "Pusťte se do toho"
				},
				"selector": {
					"done": "Hotovo",
					"applySelection": "Použít následující počet výběrů: #",
					"selectAll": "Vybrat vše",
					"allOptions": "Byly vybrány všechny možnosti",
					"someOptions": "Byl vybrán následující počet možností: #",
					"storeMultiSelectLabel": "Vybrat počet obchodů: #",
					"allStores": "Všechny obchody"
				},
				"currencyLabel": "Vybrat měnu",
				"storeLabel": "Zvolit obchod"
			},
			"v3": {
				"title": "Black Friday týden",
				"text": "Tohle jsou vaše prodeje během jednoho z nejdůležitějších prodejních týdnů v roce.",
				"summaryTitle": "Shrnutí",
				"orderVolume": "Celkový objem",
				"orderCount": "Celkový počet objednávek",
				"summaryRange": "23.–30. listopadu",
				"comparisonLastWeek": "v porovnání se stejným dnem, minulý týden",
				"tabs": {
					"monday": "Pondělí",
					"tuesday": "Úterý",
					"wednesday": "Středa",
					"thursday": "Čtvrtek",
					"blackFriday": "Black Friday",
					"saturday": "Sobota",
					"sunday": "Neděle",
					"cyberMonday": "Cyber Monday"
				}
			}
		},
		"mpSatSurvey": {
			"marble": {
				"topbarButtonLabel": "Zpětná vazba na náš nový vzhled",
				"step1": {
					"welcome": "Líbí se vám náš nový vzhled?",
					"satisfied": "Velmi spokojen/á",
					"unsatisfied": "Velmi nespokojen/á"
				},
				"step2": {
					"textAreaLabel": "Jiné (váš názor)",
					"textAreaMaxLength": "Bylo dosaženo maximální délky.",
					"submit": "Odeslat",
					"content": {
						"titlePositive": "Co se vám líbí nejvíce?",
						"titleNegative": "Co se vám nejvíce nelíbí?",
						"tags": {
							"color": "Barva a styl",
							"navigation": "Navigace",
							"names": "Název stránek",
							"structure": "Struktura stránek"
						}
					}
				},
				"step3": {
					"title": "Děkujeme!",
					"description": "Vážíme si vaší zpětné vazby."
				}
			},
			"buttonLabel": "Poskytnout zpětnou vazbu",
			"errorMessage": "Při odesílání zpětné vazby se vyskytla chyba.",
			"retry": "Zkuste to znovu",
			"step1": {
				"welcome1": "Jak jste spokojen/a s",
				"welcome2": "portálem pro obchodníky?",
				"emojiSelector": {
					"angry": "Velmi nespokojen/a",
					"unhappy": "Mírně nespokojen/a",
					"neutral": "Ani spokojen/a, ani nespokojen/a",
					"happy": "Spokojen/a",
					"love": "Nadšen/a"
				},
				"remindMeText": "Nepřejete si sdílet svůj názor?",
				"remindMeLink": "Připomeňte mi tento průzkum později.",
				"dontShowAgainLink": "Už mi průzkum nezobrazujte."
			},
			"step2": {
				"textAreaLabel": "Jiné (váš názor)",
				"textAreaMaxLength": "Bylo dosaženo maximální délky.",
				"submit": "Odeslat",
				"content": {
					"negative": {
						"title": "Je nám líto, že to slyšíme.",
						"description": "Které části portálu obchodníka bychom měli vylepšit? (Vyberte všechny platné možnosti.)",
						"tags": {
							"loading": "Doba načítání",
							"menu": "Uspořádání menu",
							"mobile": "Mobilní verze",
							"filter": "Filtrování údajů",
							"complexity": "Efektivnost činností"
						}
					},
					"positive": {
						"title": "To jsou dobré zprávy!",
						"description": "Pořád se snažíme dělat věci lépe. Co by vaši zkušenost s používáním portálu obchodníka ještě vylepšilo",
						"tags": {
							"views": "Přizpůsobené prohlížení",
							"looks": "Look & Feel",
							"tours": "Prohlídky s průvodcem",
							"shortcuts": "Zkratky k úkolům"
						}
					}
				}
			},
			"step3": {
				"title": "Děkujeme! Ještě jedna věc.",
				"content1": "Rádi bychom si s vámi popovídali, abychom zjistili více o vaší zkušenosti s používáním portálu obchodníka.",
				"content2": "Měl/a byste zájem o účast?",
				"content3": "Pokud souhlasíte, pošleme vám podrobnosti na váš registrovaný e-mail.",
				"yes": "Ano, prosím",
				"no": "Ne, děkuji"
			},
			"step4": {
				"yesTitle": "Skvělé! Ozveme se vám.",
				"noTitle": "Rozumím. Děkujeme!",
				"content1": "Děkujeme, že jste si udělal/a čas na zodpovězení těchto otázek.",
				"content2": "Tuto kartu teď můžete zavřít."
			}
		},
		"boost": {
			"integrationTypeModal": {
				"title": "Jak chcete nastavit {productName}?",
				"labels": {
					"plugin": "Nastavení pomocí pluginu",
					"web": "Nastavení pomocí Web SDK"
				}
			}
		}
	}
};