import { ActionIconClose, ButtonRoundTertiary } from '@klarna/bubble-ui';
import React from 'react';
import styled from 'styled-components';
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-12tya7y" }) `
  position: absolute;
  top: 16px;
  right: 16px;
`;
export default function CloseButton({ onClick }) {
    return (React.createElement(Container, null,
        React.createElement(ButtonRoundTertiary, { onClick: onClick, "data-testid": "btn-close" },
            React.createElement(ActionIconClose, null))));
}
