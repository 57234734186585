module.exports = {
	"fi": {
		"home-fe": {
			"boosters-home-v03": {
				"install-plugin": {
					"stepNumber": "1",
					"title": "Asenna lisäosa",
					"description": "Valitse verkkokauppa-alustasi, niin luomme linkin asennuksen nopeuttamiseksi",
					"selectorLabel": "Valitse alusta",
					"getPluginButtonLabel": "Lataa lisäosa",
					"klarnaDocsLinkLabel": "Lue lisää Klarna-dokumenteista"
				},
				"start-setup": {
					"stepNumber": "2",
					"title": "Ota lisäosa käyttöön",
					"description": "Luo ja kopioi asiakastunniste ja API-tunnus. Tarvitset niitä lisäosan käyttöönottamiseksi."
				},
				"promotion-banner": {
					"title": "Klarna alustoille",
					"subtitle": "Kasvata liiketoimintaasi yhdellä ja ainoalla lisäosalla, jolla pääset hyödyntämään maksutta useita ominaisuuksia myynnin kasvattamisesta parempien ostokokemusten luomiseen",
					"banner-cards": {
						"osm-title": "Kasvata tilauksen keskimääräistä arvoa <span>On-site messaging</span> -ominaisuuden avulla",
						"kec-title": "Nosta konversiota Express Checkout -pikakassan avulla",
						"siwk-title": "Hanki uusia asiakkaita <span>Kirjaudu Klarnalla</span> -toiminnon ansiosta"
					}
				},
				"configure-boost-features": {
					"stepNumber": "3",
					"title": "Räätälöi ominaisuuksien sijoitteluja",
					"description": "Tässä valinnaisessa vaiheessa voit muuttaa sitä, miltä ominaisuudet näyttävät kaupassasi. Jos haluat tehdä muutoksia ominaisuuksien ulkonäköön, vaihe 2 tulee olla suoritettuna.",
					"no-credentials": "Muista ensin luoda asiakastunniste ja API-tunnus, jotta voit edetä tähän vaiheeseen.",
					"apps": {
						"osm": {
							"link-label": "Räätälöi On-site messaging -ominaisuutta",
							"description": "Räätälöi On-site messaging -ominaisuutta sopivaksi brändiisi säätämällä teemaa ja valitsemalla sijoittelut"
						},
						"kec": {
							"link-label": "Räätälöi Express Checkout -pikakassaa",
							"description": "Räätälöi Express Checkout -pikakassapainiketta sopivaksi brändiisi säätämällä painikkeen teemaa ja muotoa ja valitsemalla sijoittelut"
						},
						"siwk": {
							"link-label": "Määritä Kirjaudu Klarnalla",
							"description": "Valitse Kirjaudu Klarnalla -ominaisuuden tarkoitus ja lisää uudelleenohjauksen URL. Tarkoitus viittaa tässä tietoihin, joita asiakkaaltasi kerätään, ja uudelleenohjauksen URL on osoite, jota käytetään, jos asiakkaat ovat estäneet ponnahdusikkunat"
						}
					}
				},
				"client-identifier-section": {
					"title": "Asiakastunniste",
					"description": "Uniikki tunniste määritystä varten",
					"manage-link": "Hallitse asiakastunnistetta ja lähdettä",
					"generate-button": "Luo",
					"tooltip": "Luo ja kopioi asiakastunniste. Tarvitset nämä lisäosan määrityksen aloittamiseksi alustasi portaalissa."
				},
				"api-key": {
					"warning-banner": {
						"content": "Sinulla on jo aktiiviset API-tunnukset. Ennen kuin luot uudet, varmista, että olet poistanut käytöstä kaikki API-tunnukset, joita et käytä."
					},
					"title": "Klarnan API-tunnus",
					"description": "API-tunnus määritystä varten",
					"tooltip": "Huomaathan, että kun olet luonut salaisen API-tunnuksesi, emme näytä sitä enää uudelleen.",
					"generate-button": "Luo",
					"manageApiKey": "Hallitse API-tunnusta"
				},
				"non-platform-integration": {
					"question": "Etkö käytä Klarnaa alustoilla?",
					"link": "Katso, kuinka voit integroida Klarnan suoraan"
				}
			},
			"boosters-home": {
				"welcome-banner": {
					"title": "Hyödynnä Klarnan koko voima",
					"text": "Ominaisuuksia, jotka on suunniteltu tekemään vierailijoista ostovoimaisia shoppailijoita. Paranna ostokokemuksen jokaista vaihetta – selaamisesta kassalle."
				},
				"plugin-carousel": {
					"title": "Ei koodin määritystä lisäosalla",
					"overviewLink": "Näytä kaikki alustat",
					"directIntegrationLabel": "Etkö käytä Klarnaa alustoilla?",
					"directIntegrationLink": "Katso, kuinka voit integroida Klarnan suoraan"
				},
				"booster-apps": {
					"title": "Lue lisää",
					"kec-fe": {
						"title": "Tee kassasta viisi kertaa nopeampi",
						"description": "Vähemmän klikkauksia tarkoittaa vähemmän keskenjättöjä. Paranna konversiota ja minimoi ostoskorin kesken jättämiset sijoittamalla pikakassa tuote- ja ostoskorisivuille.",
						"callToAction": "Lisää pikakassasta"
					},
					"siwk-fe": {
						"title": "Kirjautuminen ostoksille sosiaalisen median tileillä",
						"description": "Shoppailijoiden on mahdollista kirjautua sisään nopeasti ja turvallisesti yhdellä klikkauksella. Saat parempia, käytännöllisiä tietoja shoppailijoiden profiilista.",
						"callToAction": "Lisätietoa Kirjaudu Klarnalla -ominaisuudesta"
					},
					"upstream-fe": {
						"title": "Vierailijoista shoppailijoiksi",
						"description": "Maksimoi konversio dynaamisella kampanjaviestinnällä koko ostoprosessin ajan.",
						"callToAction": "Lisätietoa Viestintä sivulla -ominaisuudesta"
					},
					"branding-fe": {
						"title": "Paranna kauppasi näkyvyyttä",
						"description": "Hallitse brändiäsi Klarna-sovelluksessa lataamalla kaupan logo, kuvake ja URL.",
						"callToAction": "Ota Brändihallinta käyttöön"
					}
				}
			},
			"boosters-plugins": {
				"title": "Lisäosan määritykset",
				"description": "Pääse alkuun lisäosiemme kanssa ja varmista, että olet valmiina siirtymään live-tilaan."
			},
			"boosters-plugin": {
				"title": "Klarna {pluginName} -lisäosalle",
				"description": "Toimi alla olevien ohjeiden mukaan ottaaksesi Klarna-maksut käyttöön ja/tai tehostaaksesi ominaisuuksia määrityksillämme, jotka eivät vaadi koodaamista.",
				"access-warning": {
					"both": "Sinulla ei ole lupaa luoda API-tunnuksia ja asiakastunnistetta kaupalle {selectedStoreName} ({selectedMid}). Jatka ottamalla yhteyttä admin-käyttäjään ja pyydä pääsyä maksuasetuksiin.",
					"client": "Sinulla ei ole lupaa luoda asiakastunnistetta kaupalle {selectedStoreName} ({selectedMid}). Jatka ottamalla yhteyttä admin-käyttäjään ja pyydä pääsyä maksuasetuksiin.",
					"api": "Sinulla ei ole lupaa luoda API-tunnuksia kaupalle {selectedStoreName} ({selectedMid}). Jatka ottamalla yhteyttä admin-käyttäjään ja pyydä pääsyä maksuasetuksiin."
				},
				"back": "Takaisin",
				"instructions": {
					"api-key": {
						"title": "Ota Klarna-maksu käyttöön",
						"content": "Sinun tulee luoda ja kopioida API-tunnukset, jotta voit käyttää niitä määritysten yhteydessä.",
						"link-label": "Lue lisää Klarna-dokumenteista.",
						"generate": "Luo API-tunnukset",
						"active-warning": {
							"content": "Sinulla on jo aktiiviset API-tunnukset. Ennen kuin luot uudet, varmista, että olet poistanut käytöstä kaikki API-tunnukset, joita et käytä.",
							"link": "Hallitse API-tunnuksia"
						},
						"active-warning-api-keys": {
							"content": "Sinulla on jo aktiiviset API-tunnukset. Ennen kuin luot uudet, varmista, että olet poistanut käytöstä kaikki API-tunnukset, joita et käytä.",
							"link": "Hallitse API-tunnuksia"
						}
					},
					"client-id": {
						"title": "Ota käyttöön tehostusominaisuudet",
						"content": "Lisätäksesi Klarna-pikakassan ja Viestintä sivulla -ominaisuuden, sinun tulee luoda ja kopioida asiakastunniste, jotta voit käyttää sitä määritysten yhteydessä.",
						"generate": "Luo asiakastunniste",
						"manage": "Hallitse asiakastunnistetta"
					},
					"plugin-doc": {
						"title": "Määritä tehostusominaisuudet",
						"content": "Määrityksesi viimeistelemiseksi sinun tulee määrittää Klarna-pikakassa ja Viestintä sivulla -ominaisuus alustasi asetuksista."
					}
				},
				"error-messages": {
					"default": "Tapahtui virhe. Yritä myöhemmin uudelleen tai siirry tukeen.",
					"fetch-data": "Tietojesi noutamisessa tapahtui virhe. Yritä myöhemmin uudelleen tai siirry tukeen.",
					"create-api-key": "API-tunnustesi luomisessa tapahtui virhe. Yritä myöhemmin uudelleen tai siirry tukeen.",
					"create-client-id": "Asiakastunnisteesi luomisessa tapahtui virhe. Yritä myöhemmin uudelleen tai siirry tukeen."
				}
			},
			"marketing-home": {
				"description": "Tervetuloa markkinointituotteisiimme. Tutustu niihin alta.",
				"essentials": {
					"title": "Markkinointi",
					"cta-label": "Lue lisää",
					"branding-fe": {
						"description": "Määritä ja hallitse brändiäsi. Anna asiakkaidesi nähdä sinut parhaassa valossa Klarnan ekosysteemissä, smoooth-sovellus mukaan lukien."
					},
					"klarna-search-b2b-portal": {
						"description": "Aloita Klarnan tehokkaalla hakukoneella ja vertailushoppailulla. Pääset tilastoihin ja työkaluihin, jotka on suunniteltu näkyvyytesi parantamiseksi."
					}
				},
				"external-apps": {
					"title": "Sovellukset",
					"cta-label": "Avaa sovellus"
				},
				"popOvers": {
					"searchCompare": {
						"title": "Aloita haulla ja vertailulla",
						"content": "Täältä näet tilastot ja työkalut, joilla voit parantaa näkyvyyttäsi Klarnan vertailevassa ostopalvelussa."
					}
				}
			},
			"no-apps": {
				"title": "Sovelluksia ei ole saatavilla!",
				"subtitle": "Ota yhteyttä ylläpitäjääsi saadaksesi pääsyn tarvitsemiisi sovelluksiin."
			},
			"contact": {
				"title": "Tarvitsetko apua?",
				"paragraph": "Jos sinulla on kysyttävää, ota meihin yhteyttä, niin vastaamme mielellämme.",
				"label": "Ota meihin yhteyttä"
			},
			"important-banner": {
				"title": "",
				"content": "",
				"linkLabel": ""
			},
			"two-factor-home-banner": {
				"banner-text": "Tee tilistäsi turvallisempi kaksivaiheisella vahvistuksella.",
				"banner-link": "Ota käyttöön"
			},
			"two-factor-opt-in": {
				"title": "Ota kaksivaiheinen vahvistus käyttöön",
				"intro-paragraph": "Tilisi turvallisuuden parantamiseksi suosittelemme, että otat kaksivaiheisen vahvistuksen käyttöön tänään.",
				"help-link-text": "Klikkaamalla tästä",
				"help-text": " saat lisätietoja siitä, kuinka otat sen käyttöön ja kuinka se suojaa sinua.",
				"warning-text": "Ennen kuin jatkat, varmista että sinulla on älypuhelin ja pääsy internetiin",
				"continue-button": "Ota käyttöön"
			},
			"accept-invite-modal": {
				"title": "Hyväksy odottavat kutsut",
				"description": "Sinut on kutsuttu seuraaviin kauppoihin. Huomaathan, että valintojesi näkymisessä voi kestää muutaman minuutin.",
				"accept-all-button": "Hyväksy kaikki",
				"decline-all-button": "Hylkää kaikki",
				"save-button": "Tallenna",
				"error": "Tapahtui virhe. Yritä myöhemmin uudelleen!",
				"invite": {
					"accept-label": "Hyväksy",
					"decline-label": "Hylkää"
				}
			},
			"capture-orientation": {
				"title": "Käytätkö Klarnaa ensimmäistä kertaa?",
				"sub-title": "Toimi seuraavien ohjeiden mukaan:",
				"order-label": "Tilauksen vastaanottaminen",
				"capture-label": "Tilauksen aktivoiminen",
				"payout-label": "Maksun saaminen",
				"capture-cta": "Aktivoi tämä tilaus",
				"payout-cta": "Tilitykset-sovellus",
				"dismiss-cta": "Ohita",
				"order-step": "Ennen kuin voimme jatkaa, meidän pitää odottaa tilausta asiakkaaltasi.",
				"capture-step": "Onnittelut ensimmäisestä tilauksestasi.\nMaksun saamiseksi tilaus on seuraavaksi toimitettava ja aktivoitava.",
				"payout-step": "Onnittelut ensimmäisen tilauksesi aktivoinnista.\nSaat maksun Klarna-sopimuksessasi määriteltyjen ajankohtien mukaan.\nLisätietoja ensimmäisestä maksustasi saat menemällä",
				"completed-step": "Onnittelut ensimmäisestä maksustasi.\nTervetuloa upealle matkalle Klarnan kanssa.\nTutustu rauhassa muihin tarjoamiimme sovelluksiin alla."
			},
			"banner": {
				"common": {
					"branding-fe": {
						"content": "Lanseerasimme juuri uuden Branding-sovelluksen!",
						"linkLabel": "Ota oma brändisi haltuun nyt!"
					},
					"onSiteMessaging": {
						"title": "Tee vierailijoista shoppailijoita Viestintä sivulla -ominaisuuden avulla",
						"text": "Shoppailu alkaa jo ennen kuin asiakkaasi pääsevät ostoskoriin asti. Viestintä sivulla -ominaisuuden avulla parannat tietoisuutta Klarnan joustavista maksuvaihtoehdoista ja muista eduista jo myyntisuppilon varhaisessa vaiheessa.",
						"cta": "Tutustu"
					}
				}
			},
			"nko": {
				"dialog": {
					"title": "Tarjoamme liiketoiminnallesi uusia mielenkiintoisia ominaisuuksia.",
					"subtitle": "Ja haluaisimme tutustua sinuun paremmin.",
					"explore": "Kyllä, otetaan tästä selvää!",
					"name-field": "Miten haluat meidän kutsuvan sinua?",
					"terms-pre": "Olen lukenut ja hyväksyn",
					"terms-text": "Ehdot"
				}
			},
			"insightsWidget": {
				"title": "Myynti tänään",
				"orderCount": "Luodut tilaukset",
				"orderVolume": "Tilausmäärä",
				"orderVolumeAverage": "Tilausten keskimääräinen arvo",
				"currencyFootnote": "Näytetään {currency}",
				"link": "Katso tilaukset",
				"allStores": "Kaikki kaupat",
				"selectAllOptionText": "Valitse kaikki",
				"deselectAllOptionText": "Tyhjennä valinta",
				"closeText": "Valmis",
				"closeTextWithSelection": "Sovella # valintaan",
				"elapsedTime": "Viimeisin tilaus vastaanotettu {hours, plural, =0 {} =1 {tunti sitten} other {# tuntia sitten}} {minutes, plural, =0 {} =1 {minuutti} other {# minuuttia}} sitten",
				"error": {
					"content": "Tilaustilastosi eivät valitettavasti latautuneet oikein."
				},
				"relativeDate": {
					"today": "Tänään",
					"yesterday": "Eilen"
				},
				"weekDays": {
					"1": "Maanantai",
					"2": "Tiistai",
					"3": "Keskiviikko",
					"4": "Torstai",
					"5": "Perjantai",
					"6": "Lauantai",
					"7": "Sunnuntai"
				}
			},
			"blackFridayWidget": {
				"title": "Black Friday -viikko on täällä.",
				"text": "Seuraa myyntitilastoja lähes reaaliajassa.",
				"button": "Tarkastelele tilastoja"
			},
			"tasks": {
				"title": "Tehtävälista",
				"error": {
					"title": "Tehtävälistan lataaminen epäonnistui",
					"desc": "Lataa sivu uudelleen, jotta voimme yrittää uudelleen."
				},
				"empty": {
					"title": "Kaikki on kunnossa!",
					"desc": "Tehtävälistallasi ei ole tällä hetkellä mitään."
				},
				"single": {
					"products-fe:complete-recollection": "Pakollinen AML-lomake yrityksesi täytettäväksi rahanpesun estämiseksi",
					"products-fe:resume-onboarding": "Viimeistele alkuprosessisi",
					"settings-fe:compile-cs-info": "Lisää asiakaspalvelusi tiedot"
				},
				"aggregated": {
					"disputes-fe:unresolved": "{ count, plural, =1 {1 ratkaisematon kiista} other {# ratkaisematonta kiistaa}}",
					"orders-fe:expiring": "{ count, plural, =1 {1 vanhentuva tilaus} other {# vanhentuvaa tilausta}}",
					"orders-fe:tocapture": "{ count, plural, =1 {1 aktivoitava tilaus} other {# aktivoitavaa tilausta}}",
					"orders-fe:uncaptured": "{ count, plural, =1 {1 aktivoimaton tilaus} other {# aktivoimatonta tilausta}}",
					"users-fe:mid-added": "{ count, plural, =1 {1 uusi kauppa on lisätty} other {# uutta kauppaa on lisätty}}",
					"users-fe:mid-invited": "{ count, plural, =1 {1 kauppakutsu odottaa toimintaa} other {# kauppakutsua odottaa toimintaa}}"
				}
			}
		}
	}
};