import { __awaiter } from "tslib";
import { Themes, UIThemeProvider } from '@klarna/mp-ui';
import { useFeatures, useFetchFeatures } from '@merchant-portal/experimentation';
import { Framework as MpUiFramework } from '@merchant-portal/framework';
import { tokenHelperFactory } from '@mpp/token-helper';
import * as Sentry from '@sentry/react';
import { getLocation } from 'connected-react-router';
import { cookieHelper, utils as commonUtils } from 'mage-common';
import { actions, useAuthenticationContext } from 'mage-components';
import { selectors as configSelectors } from 'mage-config';
import { actions as coreActions, components as coreComponents, selectors as coreSelectors } from 'mage-core';
import { hooks as i18nHooks } from 'mage-i18n';
import { selectors as stateSelectors, thunks as stateThunks } from 'mage-state';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as pages from '../Pages';
import { isIgnoredPathForOneTrustScriptInjection } from './isIgnoredPathForOneTrustScriptInjection';
import useFetchMerchantsData from './useFetchMerchantData';
const checkKeycloak = (healthcheckUrl, { tries = 5, delay = 200 } = {}) => {
    if (!healthcheckUrl) {
        // for backwards-compatibility with missing keycloak healthcheck urls
        return Promise.resolve(true);
    }
    const probeAuthHealthcheck = triesLeft => {
        return fetch(healthcheckUrl, { cache: 'no-cache' })
            .then(response => {
            if (response.status === 200) {
                return true;
            }
            else {
                throw new Error(`Keycloak responded with ${response.status}`);
            }
        })
            .catch(error => {
            if (triesLeft - 1 === 0) {
                error.message = `Keycloak is not online - ${error.message}`;
                throw error;
            }
            else {
                return commonUtils.sleep(delay).then(() => probeAuthHealthcheck(triesLeft - 1));
            }
        });
    };
    return probeAuthHealthcheck(tries);
};
const configureSentry = (userId, Sentry) => {
    return Sentry.configureScope(scope => {
        scope.setUser({
            id: userId
        });
    });
};
const createLogger = Sentry => ({
    error: err => {
        Sentry.captureMessage(err.message);
    },
    warn: R.identity,
    info: R.identity,
    debug: R.identity
});
const Root = ({ App, Framework = MpUiFramework, hasCookieConsentBeenGiven = cookieHelper.hasCookieConsentBeenGiven, history, triggerGtmEvent }) => {
    const t = i18nHooks.useTranslator();
    const clientId = useSelector(coreSelectors.clientId);
    const designVersion = useSelector(coreSelectors.getDesignVersion);
    const environment = useSelector(configSelectors.environment);
    const isAuthenticated = useSelector(coreSelectors.isAuthenticated);
    const isOneTrustEnabled = useSelector(configSelectors.isOneTrustEnabled);
    const keycloakConfig = useSelector(stateSelectors.keycloakConfig);
    const location = useSelector(getLocation);
    const mpApps = useSelector(stateSelectors.getUserAccessibleApps);
    const region = useSelector(configSelectors.region);
    const slug = useSelector(coreSelectors.slug);
    const termsLink = useSelector(configSelectors.terms).url;
    const token = useSelector(coreSelectors.getAccessToken);
    const [error, setError] = useState(undefined);
    const [tokenHelper, setTokenHelper] = useState(undefined);
    const [oneTrustWasInjected, setOneTrustWasInjected] = useState(false);
    const { isAuthenticationRequired } = useAuthenticationContext();
    const logger = createLogger(Sentry);
    const currentPath = R.propOr('', 'pathname', location);
    const features = useFeatures();
    const isLoadingFeatures = features === null;
    const theme = Themes.Marble;
    const dispatch = useDispatch();
    const setMids = mids => dispatch(coreActions.mids.set(mids));
    const setMerchants = merchants => dispatch(coreActions.merchants.set(merchants));
    const login = options => dispatch(stateThunks.login(options));
    const logout = () => dispatch(stateThunks.logout());
    const refreshToken = options => dispatch(stateThunks.refreshToken(options));
    const fetchRequester = userId => dispatch(actions.fetchRequester(userId));
    const { merchantData } = useFetchMerchantsData(tokenHelper);
    useFetchFeatures();
    useEffect(() => {
        if (token) {
            const tokenUpdated = () => __awaiter(void 0, void 0, void 0, function* () {
                const tokenHelper = yield tokenHelperFactory(token, {
                    logger
                });
                setTokenHelper(tokenHelper);
                const clientMids = tokenHelper.getMidsForClient(clientId);
                setMids(clientMids);
                const userId = tokenHelper.getUserId();
                yield fetchRequester(userId);
                configureSentry(userId, Sentry);
            });
            tokenUpdated();
        }
    }, [token, clientId]);
    useEffect(() => {
        setMerchants(merchantData);
    }, [merchantData, setMerchants]);
    useEffect(() => {
        if (isAuthenticationRequired && !isAuthenticated) {
            const authenticate = () => __awaiter(void 0, void 0, void 0, function* () {
                try {
                    yield checkKeycloak(keycloakConfig.healthcheck, {
                        tries: keycloakConfig.connectAttempts,
                        delay: keycloakConfig.connectDelay
                    });
                    dispatch(stateThunks.initialiseKeycloak());
                    setInterval(refreshToken, 5000);
                }
                catch (err) {
                    setError(err);
                }
            });
            authenticate();
        }
    }, [isAuthenticationRequired, isAuthenticated, keycloakConfig]);
    useEffect(() => {
        const authenticationStillRequired = isAuthenticationRequired && (!isAuthenticated || !tokenHelper);
        if (oneTrustWasInjected || authenticationStillRequired)
            return;
        const onAllowedPage = !isIgnoredPathForOneTrustScriptInjection(currentPath);
        const cookieConsentHasAlreadyBeenGiven = hasCookieConsentBeenGiven();
        const oneTrustEvent = isOneTrustEnabled && (onAllowedPage || cookieConsentHasAlreadyBeenGiven)
            ? 'OneTrustEnabled'
            : 'OneTrustDisabled';
        const dataLayerVariables = { environment, region, slug };
        if (triggerGtmEvent) {
            triggerGtmEvent(oneTrustEvent, dataLayerVariables);
            setOneTrustWasInjected(true);
        }
    }, [currentPath, isAuthenticated, isAuthenticationRequired, tokenHelper]);
    const currentYear = new Date().getFullYear();
    const footerConfig = useMemo(() => {
        return {
            text: t(`core.footer.copyright.${region}`, { currentYear }, {}, ''),
            links: [{ linkText: t('core.footer.terms'), path: termsLink }]
        };
    }, [currentYear, region, termsLink, t]);
    if (error) {
        throw error;
    }
    else if (!isAuthenticationRequired) {
        return (React.createElement(UIThemeProvider, { theme: theme },
            React.createElement(pages.Public, { App: App, history: history, location: location, footerConfig: footerConfig, t: t, clientId: clientId, Framework: Framework })));
    }
    else if (!isAuthenticated || !tokenHelper) {
        return (React.createElement(UIThemeProvider, { theme: theme },
            React.createElement(pages.Unauthenticated, { footerConfig: footerConfig })));
    }
    else {
        return (React.createElement(coreComponents.TokenHelperContext.Provider, { value: tokenHelper }, !isLoadingFeatures &&
            React.createElement(UIThemeProvider, { theme: theme },
                React.createElement(pages.App, { App: App, mpApps: mpApps(tokenHelper), Framework: Framework, t: t, history: history, designVersion: designVersion, location: location, footerConfig: footerConfig, clientId: clientId, onLoginClick: login, onLogoutClick: logout }))));
    }
};
Root.propTypes = {
    App: PropTypes.any,
    Framework: PropTypes.any,
    hasCookieConsentBeenGiven: PropTypes.func,
    history: PropTypes.object,
    triggerGtmEvent: PropTypes.func
};
export default Root;
