module.exports = {
	"en": {
		"config": {
			"environments": {
				"development": "Development environment",
				"staging": "Staging environment",
				"playground": "Playground environment",
				"production": "Production environment"
			}
		}
	}
};