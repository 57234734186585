module.exports = {
	"es": {
		"home-fe": {
			"boosters-home-v03": {
				"install-plugin": {
					"stepNumber": "1",
					"title": "Instalar plugin",
					"description": "Selecciona tu plataforma de comercio electrónico para que podamos crear un enlace que agilice tu instalación",
					"selectorLabel": "Seleccionar plataforma",
					"getPluginButtonLabel": "Obtener plugin",
					"klarnaDocsLinkLabel": "Obtén más información sobre los documentos Klarna"
				},
				"start-setup": {
					"stepNumber": "2",
					"title": "Habilitar plugin",
					"description": "Genera y copia el identificador y la clave de la API, que necesitarás para habilitar el plugin."
				},
				"promotion-banner": {
					"title": "Klarna en plataformas",
					"subtitle": "Haz crecer tu negocio con un único plugin y accede a múltiples funcionalidades que aumentan las ventas y mejoran la experiencia de compra sin coste extra",
					"banner-cards": {
						"osm-title": "Aumenta el valor medio de los pedidos con <span>On-site messaging</span>",
						"kec-title": "Fomenta una mayor conversión con Pago exprés",
						"siwk-title": "Atrae nuevos clientes con <span>Inicio de sesión con Klarna</span>"
					}
				},
				"configure-boost-features": {
					"stepNumber": "3",
					"title": "Ubicaciones personalizadas",
					"description": "Este paso opcional te permite cambiar el aspecto de las funcionalidades en tu tienda. Para ello, necesitas completar primero el paso 2.",
					"no-credentials": "Antes de continuar con este paso tienes que generar el identificador del cliente y la clave de la API.",
					"apps": {
						"osm": {
							"link-label": "Personalizar On-site messaging",
							"description": "Personaliza On-site messaging para adaptarla a tu marca seleccionando la temática y la ubicación"
						},
						"kec": {
							"link-label": "Personaliza el el pago exprés",
							"description": "Personaliza el botón de pago exprés para adaptarlo a tu marca seleccionando la temática, la forma y la ubicación"
						},
						"siwk": {
							"link-label": "Configura el inicio de sesión con Klarna",
							"description": "Selecciona el alcance del inicio de sesión con Klarna y añade una URL que redireccione al usuario. El alcance de la información recopilada del cliente y la URL de redirección se usan si los clientes bloquean las ventanas emergentes"
						}
					}
				},
				"client-identifier-section": {
					"title": "Identificador de cliente",
					"description": "El identificador único para la configuración",
					"manage-link": "Gestiona el origen y el identificador del cliente",
					"generate-button": "Generar",
					"tooltip": "Genera y copia el identificador del cliente. Lo necesitarás para empezar a configurar el plugin en el portal de tu plataforma."
				},
				"api-key": {
					"warning-banner": {
						"content": "Ya cuentas con unas claves activas para la API. Antes de generar unas nuevas, asegúrate de que has desactivado las que no estás usando."
					},
					"title": "Clave de la API para Klarna",
					"description": "Clave de la API para la configuración",
					"tooltip": "Ten en cuenta que no volveremos a mostrar tu clave secreta de la API después de generarla.",
					"generate-button": "Generar",
					"manageApiKey": "Gestionar clave de la API"
				},
				"non-platform-integration": {
					"question": "¿No usas Klarna en plataformas?",
					"link": "Consulta cómo integrar Klarna directamente"
				}
			},
			"boosters-home": {
				"welcome-banner": {
					"title": "Libera todo el potencial de Klarna",
					"text": "Con funcionalidades diseñadas para convertir a los visitantes en grandes compradores. Potencia cada paso de la experiencia de compra, desde la exploración de productos hasta el pago."
				},
				"plugin-carousel": {
					"title": "Sin configuración de código con un complemento",
					"overviewLink": "Ver todas las plataformas",
					"directIntegrationLabel": "¿No usas Klarna en plataformas?",
					"directIntegrationLink": "Consulta cómo integrar Klarna directamente"
				},
				"booster-apps": {
					"title": "Learn more",
					"kec-fe": {
						"title": "Un pago 5 veces más rápido",
						"description": "Cuantos menos clics haya, menos se abandonarán las compras. Fomenta la conversión y minimiza los carritos abandonados con pagos exprés en las páginas del carrito y los productos.",
						"callToAction": "Más información sobre el pago exprés"
					},
					"siwk-fe": {
						"title": "Inicio de sesión desde redes sociales",
						"description": "Los compradores disfrutan de una opción para iniciar sesión con un clic, rápida y segura. Tú disfrutas de unos datos muy útiles sobre el perfil del cliente.",
						"callToAction": "Más información sobre el inicio de sesión con Klarna"
					},
					"upstream-fe": {
						"title": "Convierte a los visitantes en compradores",
						"description": "Maximiza la conversión con mensajes promocionales dinámicos a lo largo de todo el proceso de compra.",
						"callToAction": "Más información sobre On-site messaging"
					},
					"branding-fe": {
						"title": "Mejora la visibilidad de tu tienda",
						"description": "Gestiona tu marca en la app de Klarna subiendo un logo, un icono y una URL para tu tienda.",
						"callToAction": "Configura el administrador de marcas"
					}
				}
			},
			"boosters-plugins": {
				"title": "Configuración del complemento",
				"description": "Empieza a usar nuestros complementos y asegúrate de que estás listo para la activación."
			},
			"boosters-plugin": {
				"title": "Klarna para {pluginName}",
				"description": "Realiza las siguientes acciones para activar los pagos Klarna y/o las funcionalidades de mejora con nuestra configuración sin código.",
				"access-warning": {
					"both": "No tienes permiso para que {selectedStoreName} ({selectedMid}) genere unas claves de la API y un identificador de cliente. Ponte en contacto con tu usuario administrador y solicita acceso a la configuración de pagos para continuar.",
					"client": "No tienes permiso para que {selectedStoreName} ({selectedMid}) genere un identificador de cliente. Ponte en contacto con tu usuario administrador y solicita acceso a la configuración de pagos para continuar.",
					"api": "No tienes permiso para que {selectedStoreName} ({selectedMid}) genere claves de la API. Ponte en contacto con tu usuario administrador y solicita acceso a la configuración de pagos para continuar."
				},
				"back": "Atrás",
				"instructions": {
					"api-key": {
						"title": "Activar el pago con Klarna",
						"content": "Necesitas generar y copiar las claves de la API para usarlas durante la configuración.",
						"link-label": "Obtén más información sobre los documentos Klarna.",
						"generate": "Generar claves de la API",
						"active-warning": {
							"content": "Ya cuentas con unas claves activas para la API. Antes de generar unas nuevas, asegúrate de que has desactivado las que no estás usando.",
							"link": "Gestionar claves de la API"
						},
						"active-warning-api-keys": {
							"content": "Ya cuentas con unas claves activas para la API. Antes de generar unas nuevas, asegúrate de que has desactivado las que no estás usando.",
							"link": "Gestionar claves de la API"
						}
					},
					"client-id": {
						"title": "Activar funcionalidades de mejora",
						"content": "Para añadir el pago exprés con Klarna y On-site Messaging, necesitas generar y copiar el identificador de cliente para usarlo durante la configuración.",
						"generate": "Generar identificador de cliente",
						"manage": "Generar identificador de cliente"
					},
					"plugin-doc": {
						"title": "Configurar funcionalidades de mejora",
						"content": "Para completar la configuración, necesitas configurar el pago exprés con Klarna y On-site Messaging en los ajuste de tu plataforma."
					}
				},
				"error-messages": {
					"default": "Se ha producido un error. Vuelve a intentarlo más tarde o consulta la ayuda.",
					"fetch-data": "Se ha producido un error al recuperar tus datos. Vuelve a intentarlo más tarde o consulta la ayuda.",
					"create-api-key": "Se ha producido un error al crear tus claves de la API. Vuelve a intentarlo más tarde o consulta la ayuda.",
					"create-client-id": "Se ha producido un error al crear tus identificador de cliente. Vuelve a intentarlo más tarde o consulta la ayuda."
				}
			},
			"marketing-home": {
				"description": "Te damos la bienvenida a nuestros productos de marketing. Empieza a explorar a continuación.",
				"essentials": {
					"title": "Marketing",
					"cta-label": "Learn more",
					"branding-fe": {
						"description": "Establece y gestiona tu marca. Consigue máxima visibilidad entre los clientes del ecosistema Klarna, incluida la aplicación de compras smoooth."
					},
					"klarna-search-b2b-portal": {
						"description": "Empieza a usar el potente motor de búsqueda y las comparaciones de compras de Klarna. Obtén estadísticas y herramientas diseñadas para potenciar tu visibilidad."
					}
				},
				"external-apps": {
					"title": "Apps",
					"cta-label": "Abrir app"
				},
				"popOvers": {
					"searchCompare": {
						"title": "Empieza a usar la herramienta para buscar y comparar",
						"content": "Aquí encontrarás estadísticas y herramientas que pueden ayudar a potenciar tu visibilidad con el servicio de comparación de compras de Klarna."
					}
				}
			},
			"no-apps": {
				"title": "¡No hay aplicaciones disponibles!",
				"subtitle": "Contacta con tu administrador para que te dé acceso a las aplicaciones que necesites."
			},
			"contact": {
				"title": "¿Necesitas ayuda?",
				"paragraph": "Contacta con nosotros si tienes dudas y te ayudaremos a aclararlas.",
				"label": "Contactar"
			},
			"important-banner": {
				"title": "",
				"content": "",
				"linkLabel": ""
			},
			"two-factor-home-banner": {
				"banner-text": "Mejora la seguridad de tu cuenta con la autenticación de doble factor.",
				"banner-link": "Establecer"
			},
			"two-factor-opt-in": {
				"title": "Establece la autenticación de doble factor",
				"intro-paragraph": "Para mejorar la seguridad de tu cuenta es recomendable que establezcas hoy mismo la autenticación de doble factor.",
				"help-link-text": "Haz clic aquí",
				"help-text": "para más información sobre la forma de establecerla y la protección que te ofrece.",
				"warning-text": "Debes tener un móvil inteligente y conexión a internet para continuar",
				"continue-button": "Iniciar la configuración"
			},
			"accept-invite-modal": {
				"title": "Aceptar invitaciones pendientes",
				"description": "Te han invitado a acceder a las siguientes tiendas. Tus selecciones pueden tardar unos minutos en activarse.",
				"accept-all-button": "Aceptar todas",
				"decline-all-button": "Rechazar todas",
				"save-button": "Guardar",
				"error": "Se ha producido un problema. Vuelve a intentarlo más tarde.",
				"invite": {
					"accept-label": "Aceptar",
					"decline-label": "Rechazar"
				}
			},
			"capture-orientation": {
				"title": "¿Es la primera vez que usas Klarna?",
				"sub-title": "Sigue estos pasos para aprender lo esencial:",
				"order-label": "Recibir un pedido",
				"capture-label": "Capturar el pedido",
				"payout-label": "Recibir el pago",
				"capture-cta": "Capturar este pedido",
				"payout-cta": "Aplicación de liquidaciones",
				"dismiss-cta": "Omitir",
				"order-step": "Antes que nada, debemos esperar a que llegue un pedido de tus clientes.",
				"capture-step": "¡Enhorabuena, ya tienes tu primer pedido!\nEl paso siguiente para poder cobrarlo es expedir y capturar tu pedido.",
				"payout-step": "¡Enhorabuena, has capturado tu primer pedido!\nAhora haremos efectivo tu pago según los plazos establecidos en tu Acuerdo Klarna.\nPara más información sobre tu primer pago visita",
				"completed-step": "¡Enhorabuena por tu primer pago!\nTe damos la bienvenida a una bonita experiencia con Klarna.\nTe invitamos a investigar las demás aplicaciones que ofrecemos."
			},
			"banner": {
				"common": {
					"branding-fe": {
						"content": "¡Hemos puesto en marcha nuestra app Branding!",
						"linkLabel": "¡Controla tu marca desde ahora mismo!"
					},
					"onSiteMessaging": {
						"title": "Convierte a los visitantes en compradores On-site messaging",
						"text": "Las compras empiezan antes de llegar al carrito. Con On-site messaging, las opciones de pago flexible de Klarna y otros de sus muchos beneficios resultarán más visibles desde el principio de la venta.",
						"cta": "Descúbrelo"
					}
				}
			},
			"nko": {
				"dialog": {
					"title": "Estamos poniendo en marcha interesantes funciones para tu negocio.",
					"subtitle": "Y queremos ayudarte a conocerte mejor.",
					"explore": "¡Sí, veamos!",
					"name-field": "¿Cómo podemos dirigirnos a ti?",
					"terms-pre": "He leído y acepto",
					"terms-text": "las Condiciones generales"
				}
			},
			"insightsWidget": {
				"title": "Ventas hoy",
				"orderCount": "Pedidos creados",
				"orderVolume": "Volumen de ventas",
				"orderVolumeAverage": "Valor medio de pedido",
				"currencyFootnote": "Mostrado en {currency}",
				"link": "Ver pedidos",
				"allStores": "Todas las tiendas",
				"selectAllOptionText": "Seleccionar todo",
				"deselectAllOptionText": "Borrar selección",
				"closeText": "Hecho",
				"closeTextWithSelection": "Aplicar # selección(ones)",
				"elapsedTime": "El último pedido se recibió hace {hours, plural, =0 {} =1 {una hora} other {# horas}} {minutes, plural, =0 {} =1 {un minuto} other {# minutos}}",
				"error": {
					"content": "¡Qué pena! Las estadísticas de tus pedidos no se cargan correctamente."
				},
				"relativeDate": {
					"today": "Hoy",
					"yesterday": "Ayer"
				},
				"weekDays": {
					"1": "Lunes",
					"2": "Martes",
					"3": "Miércoles",
					"4": "Jueves",
					"5": "Viernes",
					"6": "Sábado",
					"7": "Domingo"
				}
			},
			"blackFridayWidget": {
				"title": "El Black Friday está aquí.",
				"text": "Sigue las estadísticas de tus pedidos en tiempo real.",
				"button": "Ver estadísticas"
			},
			"tasks": {
				"title": "Asuntos pendientes",
				"error": {
					"title": "No hemos podido cargar la lista de asuntos pendientes",
					"desc": "Vuelve a cargar la página y volveremos a intentarlo."
				},
				"empty": {
					"title": "¡Todo en orden!",
					"desc": "No tienes asuntos pendientes por ahora."
				},
				"single": {
					"products-fe:complete-recollection": "Es obligatorio rellenar el formulario AML para tu empresa, para prevenir el blanqueo de dinero",
					"products-fe:resume-onboarding": "Completa el proceso de incorporación",
					"settings-fe:compile-cs-info": "Añade tu información de atención al cliente"
				},
				"aggregated": {
					"disputes-fe:unresolved": "{ count, plural, =1 {1 disputa sin resolver} other {# disputas sin resolver}}",
					"orders-fe:expiring": "{ count, plural, =1 {1 pedido va a caducar} other {# pedidos van a caducar}}",
					"orders-fe:tocapture": "{ count, plural, =1 {1 pedido por capturar} other {# pedidos por capturar}}",
					"orders-fe:uncaptured": "{ count, plural, =1 {1 pedido no capturado} other {# pedidos no capturados}}",
					"users-fe:mid-added": "{ count, plural, =1 {1 nueva tienda añadida} other {# nuevas tiendas añadidas}}",
					"users-fe:mid-invited": "{ count, plural, =1 {1 invitación de tienda esperando tu respuesta} other {# invitaciones de tiendas esperando tu respuesta}}"
				}
			}
		}
	}
};