import { __rest } from "tslib";
import React, { useCallback, useRef, useState } from 'react';
import propTypes from 'prop-types';
import MenuItem, { menuItemPropTypes } from './MenuItem';
import { MenuStyled, SubMenuPopover, SubMenu } from './SubMenu';
const VISIBILITY_TIMEOUT = 200;
function Menu({ items, isOpen }) {
    const [subMenuPosition, setSubMenuPosition] = useState(0);
    const [activeSubMenu, setActiveSubMenu] = useState(null);
    const subMenuVisibilityTimeout = useRef();
    const clearVisibilityTimeout = useCallback(() => {
        if (subMenuVisibilityTimeout.current) {
            clearTimeout(subMenuVisibilityTimeout.current);
        }
    }, []);
    const handleEnter = useCallback(({ subMenu }, e) => {
        var _a;
        if ((_a = subMenu === null || subMenu === void 0 ? void 0 : subMenu.items) === null || _a === void 0 ? void 0 : _a.length) {
            const menuItemElement = e.target;
            clearVisibilityTimeout();
            setSubMenuPosition(menuItemElement.getBoundingClientRect().top);
            setActiveSubMenu(subMenu);
        }
    }, [clearVisibilityTimeout]);
    const handleLeave = useCallback(() => {
        clearVisibilityTimeout();
        subMenuVisibilityTimeout.current = setTimeout(() => {
            setActiveSubMenu(null);
        }, VISIBILITY_TIMEOUT);
    }, [clearVisibilityTimeout]);
    const handleSubMenuMouseOver = useCallback(() => {
        clearVisibilityTimeout();
    }, [clearVisibilityTimeout]);
    return (React.createElement(React.Fragment, null,
        React.createElement(MenuStyled, { role: 'menu' }, items.map((_a) => {
            var { subMenu } = _a, item = __rest(_a, ["subMenu"]);
            return (React.createElement(MenuItem, Object.assign({ key: item.id }, item, { isExpanded: isOpen, "aria-haspopup": !!subMenu, subMenu: subMenu, isFocused: (activeSubMenu === null || activeSubMenu === void 0 ? void 0 : activeSubMenu.parentId) === item.id, onEnter: handleEnter, onLeave: handleLeave }), !!subMenu && React.createElement(SubMenu, Object.assign({}, subMenu, { isOpen: isOpen }))));
        })),
        !isOpen && !!activeSubMenu && (React.createElement(SubMenuPopover, Object.assign({ top: subMenuPosition, onMouseOver: handleSubMenuMouseOver, onMouseLeave: handleLeave }, activeSubMenu)))));
}
Menu.propTypes = {
    items: propTypes.arrayOf(propTypes.shape(menuItemPropTypes)).isRequired,
    isOpen: propTypes.bool
};
export default Menu;
