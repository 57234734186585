module.exports = {
	"de": {
		"core": {
			"alert": {
				"message": "Ein Fehler ist aufgetreten. Versuchen Sie es bitte erneut.",
				"correlationIdLabel": "Korrelations-ID:"
			},
			"header": {
				"title": "Händlerportal"
			},
			"usermenu": {
				"language": "Sprache",
				"country": "Land",
				"timezone": "Zeitzone",
				"logout": "Abmelden",
				"profile": "Profil",
				"changeProfile": "Bearbeiten",
				"tooltip": "Ihr Profil"
			},
			"appsmenu": {
				"defaultTooltip": "Ihre Apps",
				"initialTooltip": "Finden Sie hier Ihre Apps.",
				"newAppsTooltip": "Wir haben neue Apps für Sie!"
			},
			"two-factor-opt-in": {
				"title": "Richten Sie die Zwei-Faktor-Authentifizierung ein",
				"intro-paragraph": "In Ihrem Händlerportal-Konto ist die Zwei-Faktor-Authentisierung (2FA) nicht aktiviert. Seit dem <b>16. Januar 2023</b> ist die 2FA für den Zugang zum Händlerportal verpflichtend vorgeschrieben. Erfahren Sie mehr, warum die 2FA wichtig ist, wie Sie diese einrichten und lesen Sie Antworten auf häufig gestellte Fragen, <faqLink>indem Sie hier klicken</faqLink>.",
				"help-link-text": "Klicken Sie hier",
				"help-text": ", um mehr darüber zu erfahren, wie Sie es einrichten und wie es Sie schützt.",
				"warning-text": "Stellen Sie sicher, dass Sie Ihr Smartphone haben und Zugang zum Internet haben, bevor Sie fortfahren",
				"continue-button": "Starten Sie das Setup"
			},
			"kuasPrompter": {
				"title": "Ihre Auswahl:",
				"content": "{count} MIDs",
				"contentTooltip": "Hier klicken, um Händler-IDs in die Zwischenablage zu kopieren",
				"button": "Die ausgewählten MIDs ändern"
			},
			"notification": {
				"title": "Ankündigung",
				"content": {
					"uptodate": "Sie sind auf dem neusten Stand!"
				}
			},
			"languages": {
				"cs": "Tschechisch",
				"da": "Dänisch",
				"de": "Deutsch",
				"el": "Griechisch",
				"en": "German",
				"en_GB": "Englisch (GB)",
				"en_US": "Englisch (US)",
				"es": "Spanisch",
				"fi": "Finnisch",
				"fr": "Französisch",
				"it": "Italienisch",
				"nl": "Niederländisch",
				"no": "Norwegisch",
				"pl": "Polnisch",
				"pt": "Portugiesisch",
				"sv": "Schwedisch"
			},
			"countries": {
				"at": "Österreich",
				"au": "Australien",
				"be": "Belgien",
				"ca": "Kanada",
				"ch": "Schweiz",
				"cz": "Tschechien",
				"de": "Deutschland",
				"dk": "Dänemark",
				"ee": "Estland",
				"es": "Spanien",
				"fi": "Finnland",
				"fr": "Frankreich",
				"gb": "Vereinigtes Königreich",
				"gr": "Griechenland",
				"ie": "Irland",
				"it": "Italien",
				"mx": "Mexiko",
				"nl": "Niederlande",
				"no": "Norwegen",
				"se": "Schweden",
				"us": "USA",
				"nz": "Neuseeland",
				"pl": "Polen",
				"pt": "Portugal",
				"ro": "Rumänien"
			},
			"selectLanguage": {
				"title": "Wählen Sie Ihre Sprache",
				"paragraph": "Dies ist Ihre Standardsprache.",
				"submit": "OK"
			},
			"selectCountry": {
				"title": "Wählen Sie Ihr Land",
				"paragraph": "Dies ist Ihr Standardland.",
				"submit": "OK"
			},
			"updateProfile": {
				"title": "Profil aktualisieren",
				"submit": "OK",
				"emailLabel": "E-Mail-Adresse",
				"firstNameLabel": "Vorname",
				"lastNameLabel": "Nachname",
				"resetPasswordLabel": "Passwort",
				"resetPasswordSelectedValue": "Zurücksetzen",
				"resetPasswordSuccessLabel": "E-Mail zum Zurücksetzen des Passworts erfolgreich gesendet.",
				"resetPasswordErrorLabel": "Fehler beim Senden der E-Mail. Bitte versuchen Sie es später noch einmal.",
				"error": "Aktualisierung der Details fehlgeschlagen",
				"changeCookiePolicyLabel": "Cookie-Richtlinie",
				"changeCookiePolicySelectedValue": "Ändern",
				"securitySectionTitle": "Sicherheit",
				"preferencesSectionTitle": "Einstellungen",
				"mfaLabel": "Zwei-Faktor-Authentifizierung",
				"mfaDescription": "Erfordert das Anmelden mit einem einmaligen, zeitlich befristeten Passwort. Wir empfehlen, diese Funktion zu aktivieren.",
				"twoFactor": {
					"reset": "Ihre 2FA ändern",
					"isEnabled": "Aktiviert.",
					"isDisabled": "Deaktiviert",
					"title": "Zwei-Faktor-Authentifizierung (2FA)",
					"description": "Damit melden sich Nutzer:innen über die zusätzliche Sicherheitsstufe eines zeitlich befristeten Passworts an.",
					"tooltip": "Sie benötigen mindestens die Berechtigung {permission} für die {app} App für jeden Shop, auf den Nutzer:innen Zugriff haben, um die Einstellung für die Zwei-Faktor-Authentisierung ändern zu können.",
					"confirmationDialog": {
						"closeButton": "Schließen",
						"confirmationButton": "Ja, fortfahren",
						"cancelButton": "Abbrechen",
						"resetSuccess": {
							"title": "2FA-Gerät erfolgreich zurückgesetzt.",
							"description": "Bei der nächsten Anmeldung müssen Nutzer:innen ein neues Gerät für die 2FA einrichten."
						},
						"resetConfirmation": {
							"title": "Sind Sie sicher, dass Sie das 2FA-Gerät dieses/dieser Nutzer:in zurücksetzen möchten?",
							"description": "Wenn Sie fortfahren, setzen Sie das Gerät zurück, mit dem 2FA-Codes für diese/n Nutzer:in generiert werden. Bei der nächsten Anmeldung müssen Nutzer:innen ein neues Gerät für die 2FA einrichten."
						}
					}
				}
			},
			"footer": {
				"copyright": {
					"eu": "Copyright © 2005-{currentYear} Klarna Bank AB (publ). Alle Rechte vorbehalten",
					"oc": "Copyright © 2005-{currentYear} Klarna Australia Pty Ltd, ABN # 82 635 912 579",
					"ap": "Copyright © 2005-{currentYear} Klarna Australia Pty Ltd, ABN # 82 635 912 579",
					"us": "Copyright © 2005-{currentYear} Klarna Inc. NMLS #1353190, 629 N. High Street, Third Floor, Columbus, OH 43215"
				},
				"terms": "AGB"
			},
			"accessDenied": {
				"title": "Sie benötigen zusätzliche Berechtigungen für diese Seite.",
				"message": "Bei Fragen wenden Sie sich bitte an Ihren Administrator oder an Klarna."
			},
			"midSelector": {
				"label": "Händler"
			},
			"storeSelector": {
				"label": "Händler"
			},
			"storesSelector": {
				"closeText": "Fertig",
				"closeTextWithSelection": "# Auswahl(en) anwenden",
				"deselectAllOptionText": "Auswahl löschen",
				"emptyStateText": "Keine Händler gefunden.",
				"label": "Händler",
				"searchFieldLabel": "Nach Händler suchen",
				"selectAllOptionText": "Alle Händler auswählen",
				"selectedAllText": "Alle Händler",
				"selectedText": "# Händler"
			},
			"pagination": {
				"ofLabel": "von"
			},
			"singleDatePicker": {
				"selectDate": "Datum auswählen"
			},
			"dateRangePicker": {
				"startLabel": "Startdatum",
				"endLabel": "Enddatum",
				"months": {
					"january": "Januar",
					"february": "Februar",
					"march": "März",
					"april": "April",
					"may": "Mai",
					"june": "Juni",
					"july": "Juli",
					"august": "August",
					"september": "September",
					"october": "Oktober",
					"november": "November",
					"december": "Dezember"
				},
				"weekDaysShort": {
					"monday": "M",
					"tuesday": "D",
					"wednesday": "M",
					"thursday": "D",
					"friday": "F",
					"saturday": "S",
					"sunday": "S"
				}
			},
			"regionSelector": {
				"noStores": "Keine Shops zugeordnet",
				"andMore": "und mehr",
				"regions": {
					"eu": "Europa",
					"us": "Nordamerika",
					"ap": "Asien-Pazifik"
				}
			},
			"regionSelectorBar": {
				"modal": {
					"title": "Region ändern",
					"description": "Bitte wählen Sie eine Region mit den entsprechenden Shops, für die Daten angezeigt werden sollen.",
					"buttonText": "Bestätigen"
				},
				"selectedRegion": "Gewählte Region",
				"changeButton": "Ändern"
			},
			"appIcon": {
				"newLabel": "NEU"
			},
			"appCategory": {
				"dayToDay": "Für das Tagesgeschäft",
				"nko": "Klarna Online",
				"tools": "Anwendungen",
				"preferences": "Einstellungen"
			},
			"apps": {
				"account-information-fe": {
					"title": "Kontodetails",
					"description": "Hier finden Ihre geschäftlichen Angaben, Bankverbindungen und von Ihnen signierte Verträge."
				},
				"home-fe": {
					"title": "Startbildschirm Zahlungen"
				},
				"boosters-home-fe": {
					"title": "Los geht's"
				},
				"marketing-home-fe": {
					"title": "Startbildschirm Marketing"
				},
				"nko-app": {
					"title": "Klarna Online Apps"
				},
				"orders-fe": {
					"title": "Bestellmanager",
					"description": "Suchen und Verwalten Ihrer Bestellungen über Klarna.",
					"nkoLinkText": "Ältere Bestellungen abrufen"
				},
				"nko-orders": {
					"title": "Bestellungen (KO)",
					"description": "Durchsuchen und Verwalten Ihrer Bestellungen über Klarna Online."
				},
				"settlements-fe": {
					"title": "Abrechnungen",
					"description": "Abrechnungs- und Auszahlungsberichte anpassen und herunterladen",
					"nkoLinkText": "Ältere Berichte abrufen"
				},
				"nko-settlements": {
					"title": "Abrechnungen (KO)",
					"description": "Berichte zu Bestellungen und Auszahlungen von Klarna Online finden."
				},
				"logs-app-fe": {
					"title": "Entwickler-Protokolle",
					"description": "API-Protokolle durchsuchen, um Integrationsprobleme zu beheben."
				},
				"users-fe": {
					"title": "Nutzerverwaltung",
					"description": "Neue Benutzer einladen und bestehende Benutzer im Portal verwalten."
				},
				"settings-fe": {
					"title": "Zahlungseinstellungen",
					"description": ""
				},
				"nko-settings": {
					"title": "Einstellungen (KO)",
					"description": "Ihre Shop-Einstellungen, Berichte und Kontaktdaten verwalten."
				},
				"seller-fe": {
					"title": "Im Ladengeschäft",
					"description": "Neue Bestellungen mit der Klarna In-Store-Lösung erstellen."
				},
				"products-fe": {
					"title": "Onboarding",
					"description": "Richten Sie Ihr Konto ein, um Zahlungen zu ermöglichen."
				},
				"upstream-fe": {
					"title": "On-site Messaging",
					"description": "Mehr verkaufen mit maßgeschneiderten und personalisierten Finanzierungsangeboten."
				},
				"lending-fe": {
					"title": "Business Financing",
					"description": "Unternehmensfinanzierung leicht gemacht."
				},
				"branding-fe": {
					"title": "Markenmanager",
					"description": "Bestimmen Sie, wie Ihre Marke innerhalb von Klarna präsentiert wird."
				},
				"disputes-fe": {
					"title": "Beanstandungen",
					"description": "Beanstandungen bearbeiten."
				},
				"logistics-fe": {
					"title": "Versand-Integrationen",
					"description": "Verwalten Sie Ihre gesamte Logistik an einem Ort."
				},
				"developers": {
					"title": "Entwickler"
				},
				"klarna-docs": {
					"title": "Klarna Docs"
				},
				"support": {
					"title": "Support",
					"description": "Ihre Support-Anfragen überprüfen und verwalten"
				},
				"integration-guides-fe": {
					"title": "Integrationsleitfäden",
					"description": ""
				},
				"klarna-search-b2b-portal": {
					"title": "Suche und Vergleich",
					"description": "Nutzen Sie Klarnas effektive Suchmaschine und das Vergleichsshopping. Erhalten Sie Zugriff auf Statistiken und Tools für verbesserte Sichtbarkeit."
				},
				"siwk-fe": {
					"title": "Mit Klarna anmelden",
					"description": "Verwalten Sie Ihre Anmeldung mit der Klarna Integration."
				},
				"kec-fe": {
					"title": "Schnelles Bezahlen",
					"description": "Verwalten Sie Ihre Integration für schnelles Bezahlen."
				},
				"partner-self-service": {
					"title": "Shop-Manager",
					"description": "Verwalten Sie Ihren Klarna Shop.",
					"nkoLinkText": "Einstellungen für Ihren Shop anzeigen und verwalten"
				}
			},
			"ui": {
				"tabs": {
					"home": "Zahlungen",
					"growth": "Growth Marketing",
					"boosters": "Booster für die Conversion-Rate",
					"badge": "Neu"
				},
				"buttons": {
					"backButton": "Zurück"
				}
			},
			"errorBoundary": {
				"contact": "Bitte versuchen Sie, die Seite zu aktualisieren. {br}Falls der Fehler weiterhin auftritt, kontaktieren Sie bitte den <supportLink>Händler-Support</supportLink> unter Angabe der ID {errorId}.",
				"errorDescription": "Fehler-ID - {errorId}",
				"reloadButton": "Aktualisieren",
				"title": "Leider ist ein Fehler aufgetreten"
			},
			"maintenance": {
				"pageTitle": "Wartungsarbeiten",
				"title": "Das Händlerportal befindet sich derzeit in einer geplanten Wartung.",
				"summary": "Wir sind in Kürze wieder da",
				"scheduledSummary": "Um {until} sind wir wieder da"
			},
			"errorTemplates": {
				"errorDescription": "Fehler: {code}",
				"backButton": "Zurück",
				"reloadButton": "Aktualisieren",
				"default": {
					"errorDescription": "Fehler-ID - {errorId}",
					"title": "Leider ist ein Fehler aufgetreten",
					"contact": "Bitte versuchen Sie, die Seite zu aktualisieren. Falls der Fehler weiterhin auftritt, kontaktieren Sie bitte den <supportLink>Händler-Support</supportLink> unter Angabe der ID {errorId}."
				},
				"internalServerError": {
					"title": "Hoppla! Da ist etwas schiefgelaufen",
					"message": "Aus irgendeinem Grund, konnten wir Ihre Anfrage auf unserer Seite nicht ausführen. Sie können es erneut versuchen oder später wiederkommen."
				},
				"notFound": {
					"title": "Keine Inhalte gefunden!",
					"message": "Diese Seite existiert nicht oder Sie haben sich bei der Eingabe der Adresse in Ihrem Browser vertippt. Bitte prüfen Sie die Adresse und versuchen es dann erneut."
				},
				"forbidden": {
					"title": "Sie haben keine Zugriffsberechtigung auf diese Seite",
					"message": "Ihre Händler-ID hat keine Zugriffsberechtigung auf diese Seite. Falls Sie glauben, dass es sich hierbei um einen Irrtum handelt, wenden Sie sich bitte an den Händler-Support oder kontaktieren Sie den Administrator Ihres Unternehmenskontos."
				}
			},
			"codeSnippet": {
				"clipboardButtonLabel": "In Zwischenablage kopieren",
				"clipboardButtonLabelSuccess": "Kopiert!"
			}
		},
		"blackFriday": {
			"v1": {
				"title": "Black Friday",
				"intro": "Einer der wichtigsten Tage für den Einzelhandel steht bevor. Am Black Friday sind wir für Sie da, um beinahe in Echtzeit Statistiken Ihrer Umsätze zu bieten. Bis dahin können wir uns schon mal auf den Erfolg vorbereiten!",
				"countdown": "Noch #dT #hS #mM #sS…",
				"box1": {
					"title": "3,5 mal mehr Ausgaben*",
					"text": "Im letzten Jahr haben Klarna-Kunden* am Black Friday im Durchschnitt 3,5 mal mehr ausgegeben als an jedem anderen Tag im Jahr.",
					"footnote": "*Durchschnitt aller Händlerkategorien und -regionen bei Klarna."
				},
				"box2": {
					"title": "2,5 mal höheres Verkaufsvolumen*",
					"text": "Im letzten Jahr haben Klarna-Händler am Black Friday ein 2,5 mal höheres Verkaufsvolumen verzeichnen können als an jedem anderen Tag im Jahr.",
					"footnote": "*Durchschnitt aller Händlerkategorien und -regionen bei Klarna."
				},
				"sectionTitle": "Sorgen wir am Black Friday für mehr Umsatz",
				"marketing": {
					"title": "Vermarkten Sie Ihr Geschäft vor dem und am Black Friday",
					"text": "Locken Sie mit diesen kostenlos nutzbaren Ressourcen für Social Media- und E-Mail-Kampagnen in der Shopping-Saison um den Black Friday mehr Kunden in Ihr Geschäft."
				},
				"branding": {
					"title": "Stellen Sie sich in der Klarna App hervor",
					"text": "12 Millionen Shopper nutzen die Klarna App jeden Monat. Machen Sie diesen Black Friday in der App auf sich aufmerksam, indem Sie hier im Händlerportal Informationen zu Ihrer Marke ergänzen und aktualisieren."
				},
				"cta": "Los geht's",
				"close": "Schließen"
			},
			"v2": {
				"title": "Black Friday week",
				"orderVolume": "Bestellumsatz",
				"orderCountTitle": "Erstellte Bestellungen",
				"orderCount": "{count} Bestellungen",
				"box": {
					"title": "Möchten Sie Ihre Conversion-Rate noch weiter steigern?",
					"subtitle1": "On-site messaging",
					"subtitle2": "Werbebotschaften, die bilden.",
					"text": "Unser On-site messaging-Tool hilft Ihnen, Ihre Verkäufe und durchschnittlichen Bestellwerte durch Werbebotschaften zu steigern. Informieren Sie Ihre Kunden, sobald sie auf Ihre Website kommen und während ihres gesamten Einkaufes über ihre Zahlungsoptionen.",
					"cta": "Los geht's"
				},
				"selector": {
					"done": "Fertig",
					"applySelection": "# Auswahl(en) anwenden",
					"selectAll": "Alle auswählen",
					"allOptions": "Alle Optionen ausgewählt",
					"someOptions": "# Optionen ausgewählt",
					"storeMultiSelectLabel": "# Shops auswählen",
					"allStores": "Alle Shops"
				},
				"currencyLabel": "Währung auswählen",
				"storeLabel": "Shop auswählen"
			},
			"v3": {
				"title": "Black-Friday-Woche",
				"text": "Dies sind Ihre Umsätze während einer der wichtigsten Verkaufswochen dieses Jahres.",
				"summaryTitle": "Zusammenfassung",
				"orderVolume": "Gesamtsumme",
				"orderCount": "Bestellungen insgesamt",
				"summaryRange": "23-30 November",
				"comparisonLastWeek": "im Vergleich zum gleichen Tag der Vorwoche",
				"tabs": {
					"monday": "Montag",
					"tuesday": "Dienstag",
					"wednesday": "Mittwoch",
					"thursday": "Donnerstag",
					"blackFriday": "Black Friday",
					"saturday": "Samstag",
					"sunday": "Sonntag",
					"cyberMonday": "Cyber Monday"
				}
			}
		},
		"mpSatSurvey": {
			"marble": {
				"topbarButtonLabel": "Feedback zu unserem neuen Look",
				"step1": {
					"welcome": "Gefällt Ihnen unsere neue Aufmachung?",
					"satisfied": "Sehr zufrieden",
					"unsatisfied": "Sehr unzufrieden"
				},
				"step2": {
					"textAreaLabel": "Sonstiges (Ihre Meinung)",
					"textAreaMaxLength": "Maximale Zeichenanzahl erreicht",
					"submit": "Absenden",
					"content": {
						"titlePositive": "Was gefällt Ihnen am besten?",
						"titleNegative": "Was gefällt Ihnen am wenigsten?",
						"tags": {
							"color": "Farbe und Stil",
							"navigation": "Die Navigation",
							"names": "Die Seitennamen",
							"structure": "Die Seitenstruktur"
						}
					}
				},
				"step3": {
					"title": "Danke!",
					"description": "Wir danken Ihnen für Ihr Feedback."
				}
			},
			"buttonLabel": "Feedback geben",
			"errorMessage": "Beim Versenden Ihres Feedbacks ist ein Fehler aufgetreten.",
			"retry": "Erneut versuchen",
			"step1": {
				"welcome1": "Wie zufrieden sind Sie mit dem",
				"welcome2": "Händlerportal?",
				"emojiSelector": {
					"angry": "Sehr unzufrieden",
					"unhappy": "Etwas unzufrieden",
					"neutral": "Weder noch",
					"happy": "Zufrieden",
					"love": "Bin äußerst zufrieden"
				},
				"remindMeText": "Sie möchten Ihre Meinung nicht äußern?",
				"remindMeLink": "Erinnern Sie mich zu einem späteren Zeitpunkt an diese Umfrage.",
				"dontShowAgainLink": "Nicht mehr anzeigen."
			},
			"step2": {
				"textAreaLabel": "Sonstiges (Ihre Meinung)",
				"textAreaMaxLength": "Maximale Zeichenanzahl erreicht",
				"submit": "Abschicken",
				"content": {
					"negative": {
						"title": "Tut uns leid, das zu hören.",
						"description": "Welche Aspekte im Händlerportal sollten verbessert werden? (Bitte alle zutreffenden auswählen)",
						"tags": {
							"loading": "Ladezeit",
							"menu": "Menüstruktur",
							"mobile": "Mobilversion",
							"filter": "Filterfunktionen",
							"complexity": "Effizienz der Aufgabe"
						}
					},
					"positive": {
						"title": "Das freut uns zu hören!",
						"description": "Dennoch möchten wir uns weiter verbessern. Was würde Ihnen die Benutzung des Händlerportals noch mehr erleichtern?",
						"tags": {
							"views": "Personalisierbare Ansichtsfunktion",
							"looks": "Look-and-Feel",
							"tours": "Geführte Überblickstouren",
							"shortcuts": "Verknüpfungen für Aufgaben"
						}
					}
				}
			},
			"step3": {
				"title": "Danke! Eine Sache noch.",
				"content1": "Wir würden Sie gerne zu einem Interview einladen, damit wir mehr Kenntnisse über Ihre Erfahrungen mit dem Händlerportal gewinnen können.",
				"content2": "Würden Sie gerne daran teilnehmen?",
				"content3": "Falls ja, würden wir uns per E-Mail an die hinterlegte Adresse für die weiteren Schritte an Sie wenden.",
				"yes": "Ja, bitte",
				"no": "Nein, danke"
			},
			"step4": {
				"yesTitle": "Super! Wir melden uns bei Ihnen.",
				"noTitle": "Alles klar. Danke!",
				"content1": "Vielen Dank, dass Sie sich Zeit für die Beantwortung dieser Fragen genommen haben.",
				"content2": "Sie können dieses Pop-up-Fenster nun schließen."
			}
		},
		"boost": {
			"integrationTypeModal": {
				"title": "Wie möchten Sie {productName} einrichten?",
				"labels": {
					"plugin": "Einrichtung per Plugin",
					"web": "Einrichtung per Web SDK"
				}
			}
		}
	}
};