import { useResponsiveContext } from '@klarna/mp-ui';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useSidebarContext } from '../Sidebar';
import { useTablet } from '../../../hooks/useMediaQuery';
import { TabButton } from './TabButton';
import useActiveTab from './useActiveTab';
import PopoverBoostersInfo from './PopOverBoostersInfo';
import useShouldShowTabs from './useShouldShowTabs';
const TabRowContainer = styled.div `
  grid-area: tabs;
  display: flex;
  padding-left: 0px;

  ${(props) => props.tabletMediaQuery} {
    padding-left: 12px;
  }
`;
const TabsContainer = styled.div `
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  background-color: ${(props) => (props.isHighlighted ? 'white' : 'none')};
  width: 100%;
  padding-left: 16px;
  ${(props) => props.tabletMediaQuery} {
    border-radius: ${(props) => (props.isHighlighted ? '8px' : '0px')};
    width: auto;
    padding-right: 8px;
    padding-left: 4px;
  }
`;
const TabItem = styled.div({
    display: 'flex',
    padding: 8
});
function Tabs({ items, sidebarItems, defaultTab }) {
    const { setSidebarConfig } = useSidebarContext();
    const { isMobile } = useResponsiveContext();
    const tabsRef = useRef();
    const boostersTabRef = useRef();
    const { shouldShowTabs } = useShouldShowTabs(items);
    const { activeTab, setLastActiveTab } = useActiveTab({
        tabs: items,
        sidebarItems,
        defaultTab
    });
    const groupedSidebarItems = items.reduce((acc, current) => (Object.assign(Object.assign({}, acc), { [current.id]: [
            current.homeApp,
            ...sidebarItems === null || sidebarItems === void 0 ? void 0 : sidebarItems.filter(({ tabs = [] }) => tabs.includes(current.id))
        ] })), {});
    useEffect(() => {
        if (groupedSidebarItems[activeTab]) {
            setSidebarConfig({ items: groupedSidebarItems[activeTab] });
        }
        setLastActiveTab(activeTab);
    }, [activeTab]);
    const tabletMediaQuery = useTablet();
    const handleOnClick = (tabId, onClick) => () => {
        const tab = items.find(tab => tab.id === tabId);
        onClick === null || onClick === void 0 ? void 0 : onClick(tabId);
        window.location.assign(tab.homeApp.path);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(PopoverBoostersInfo, { boostersTabRef: boostersTabRef }),
        shouldShowTabs && (React.createElement(TabRowContainer, { tabletMediaQuery: tabletMediaQuery },
            React.createElement(TabsContainer, { tabletMediaQuery: tabletMediaQuery, ref: tabsRef }, items.map((item) => {
                const isActiveTab = activeTab === item.id;
                const isInactiveTabAndMobile = !isActiveTab && isMobile;
                return (React.createElement(TabItem, { key: item.id, role: 'tab', "aria-selected": activeTab === item.id, ref: item.id === 'boosters' ? boostersTabRef : undefined },
                    React.createElement(TabButton, { "aria-label": item.label, active: isActiveTab, key: item.id, content: isInactiveTabAndMobile ? '' : item.label, onClick: handleOnClick(item.id, item.onClick), badgeContent: item.badgeContent, icon: item.icon })));
            }))))));
}
export default Tabs;
