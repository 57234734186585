module.exports = {
	"sv": {
		"core": {
			"alert": {
				"message": "Något gick fel! Vänligen försök igen.",
				"correlationIdLabel": "Korrelations-ID:"
			},
			"header": {
				"title": "Merchant Portal"
			},
			"usermenu": {
				"language": "Språk",
				"country": "Land",
				"timezone": "Tidszon",
				"logout": "Logga ut",
				"profile": "Profil",
				"changeProfile": "Ändra",
				"tooltip": "Din profil"
			},
			"appsmenu": {
				"defaultTooltip": "Dina appar",
				"initialTooltip": "Hitta dina appar här.",
				"newAppsTooltip": "Vi har nya appar till dig!"
			},
			"two-factor-opt-in": {
				"title": "Aktivera tvåfaktorautentisering",
				"intro-paragraph": "Du har inte tvåvägsautentisering (2FA) aktiverat i butiksportalen. Från och med den <b>16 january, 2023</b>, behöver alla användare ha 2FA för att komma åt butiksportalen. Du kan läsa mer om betydelsen av 2FA, hur du ställer in det och svar på alla vanliga frågor <faqLink>här</faqLink>.",
				"help-link-text": "Klicka här",
				"help-text": " för att lära dig mer om hur du aktiverar det och hur det skyddar dig.",
				"warning-text": "Se till att du har din smartphone redo och fungerande internetuppkoppling innan du fortsätter",
				"continue-button": "Aktivera"
			},
			"kuasPrompter": {
				"title": "Du har valt",
				"content": "{count} MIDs",
				"contentTooltip": "Klippa för att kopiera butiks-ID till urklipp",
				"button": "Ändra valda MIDs"
			},
			"notification": {
				"title": "Meddelande",
				"content": {
					"uptodate": "Du är uppdaterad!"
				}
			},
			"languages": {
				"cs": "Tjeckiska",
				"da": "Danska",
				"de": "Tyska",
				"el": "Grekiska",
				"en": "Engelska",
				"en_GB": "Engelska (GB)",
				"en_US": "Engelska (US)",
				"es": "Spanska",
				"fi": "Finska",
				"fr": "Franska",
				"it": "Italienska",
				"nl": "Nederländska",
				"no": "Norska",
				"pl": "Polska",
				"pt": "Portugisiska",
				"sv": "Svenska"
			},
			"countries": {
				"at": "Österrike",
				"au": "Australien",
				"be": "Belgien",
				"ca": "Kanada",
				"ch": "Schweiz",
				"cz": "Tjeckien",
				"de": "Tyskland",
				"dk": "Danmark",
				"ee": "Estland",
				"es": "Spanien",
				"fi": "Finland",
				"fr": "Frankrike",
				"gb": "England",
				"gr": "Grekland",
				"ie": "Irland",
				"it": "Italien",
				"mx": "Mexiko",
				"nl": "Nederländerna",
				"no": "Norge",
				"se": "Sverige",
				"us": "USA",
				"nz": "Nya Zeeland",
				"pl": "Polen",
				"pt": "Portugal",
				"ro": "Rumänien"
			},
			"selectLanguage": {
				"title": "Välj språk",
				"paragraph": "Det här kommer vara ditt förvalda språk. ",
				"submit": "OK"
			},
			"selectCountry": {
				"title": "Välj ditt land",
				"paragraph": "Det här kommer vara ditt förvalda land.",
				"submit": "OK"
			},
			"updateProfile": {
				"title": "Uppdatera profil",
				"submit": "OK",
				"emailLabel": "Mejladress",
				"firstNameLabel": "Förnamn",
				"lastNameLabel": "Efternamn",
				"resetPasswordLabel": "Lösenord",
				"resetPasswordSelectedValue": "Återställ",
				"resetPasswordSuccessLabel": "E-post för återställning av lösenord skickat",
				"resetPasswordErrorLabel": "Fel uppstod med e-post för återställning av lösenord",
				"error": "Uppdatering av uppgifter misslyckades",
				"changeCookiePolicyLabel": "Cookiepolicy",
				"changeCookiePolicySelectedValue": "Ändra",
				"securitySectionTitle": "Säkerhet",
				"preferencesSectionTitle": "Inställningar",
				"mfaLabel": "Tvåfaktorsautentisering",
				"mfaDescription": "Ett tidsbundet lösenord krävs för att logga in. Vi rekommenderar att du aktiverar det.",
				"twoFactor": {
					"reset": "Ändra din 2FA",
					"isEnabled": "Activated.",
					"isDisabled": "Avaktiverad",
					"title": "Tvåfaktorsautentisering (2FA)",
					"description": "Detta ger användare möjlighet att logga in med den extra säkerheten hos ett tidsbaserat engångslösenord.",
					"tooltip": "Se till att åtminstone ha tillstånd {permission} för appen {app} för varje butik där användaren har konto för att kunna växla till tvåvägsautentisering.",
					"confirmationDialog": {
						"closeButton": "Stäng",
						"confirmationButton": "Ja, fortsätt",
						"cancelButton": "Avbryt",
						"resetSuccess": {
							"title": "2FA-enhet lyckades återställas.",
							"description": "Nästa gång användaren loggar in behöver den ställa in en ny enhet för 2FA."
						},
						"resetConfirmation": {
							"title": "Är du säker på att du vill återställa denna användares 2FA-enhet?",
							"description": "Genom att fortsätta återställer du enheten som använts för att skapa 2FA-koder för denna användare. Nästa gång användaren loggar in behöver den ställa in en ny enhet för 2FA."
						}
					}
				}
			},
			"footer": {
				"copyright": {
					"eu": "Copyright © 2005-{currentYear} Klarna Bank AB (publ). Med ensamrätt",
					"oc": "Copyright © 2005-{currentYear} Klarna Australia Pty Ltd, ABN # 82 635 912 579",
					"ap": "Copyright © 2005-{currentYear} Klarna Australia Pty Ltd, ABN # 82 635 912 579",
					"us": "Copyright © 2005-{currentYear} Klarna Inc. NMLS #1353190, 629 N. High Street, Third Floor, Columbus, OH 43215"
				},
				"terms": "Villkor"
			},
			"accessDenied": {
				"title": "Du behöver utökad åtkomst för den här sidan. ",
				"message": "Kontakta din administratör eller Klarna vid frågor."
			},
			"midSelector": {
				"label": "Butik"
			},
			"storeSelector": {
				"label": "Butik"
			},
			"storesSelector": {
				"closeText": "Klar",
				"closeTextWithSelection": "Applicera # val",
				"deselectAllOptionText": "Rensa val",
				"emptyStateText": "Inga butiker hittades",
				"label": "Butiker",
				"searchFieldLabel": "Sök efter butik",
				"selectAllOptionText": "Välj alla butiker",
				"selectedAllText": "Alla butiker",
				"selectedText": "# butiker"
			},
			"pagination": {
				"ofLabel": "av"
			},
			"singleDatePicker": {
				"selectDate": "Välj datum"
			},
			"dateRangePicker": {
				"startLabel": "Startdatum",
				"endLabel": "Slutdatum",
				"months": {
					"january": "Januari",
					"february": "Februari",
					"march": "Mars",
					"april": "April",
					"may": "Maj",
					"june": "Juni",
					"july": "Juli",
					"august": "Augusti",
					"september": "September",
					"october": "Oktober",
					"november": "November",
					"december": "December"
				},
				"weekDaysShort": {
					"monday": "M",
					"tuesday": "T",
					"wednesday": "O",
					"thursday": "T",
					"friday": "F",
					"saturday": "S",
					"sunday": "S"
				}
			},
			"regionSelector": {
				"noStores": "Inga butiker tillskrivna",
				"andMore": "med mera",
				"regions": {
					"eu": "Europa",
					"us": "Nordamerika",
					"ap": "Asien-Stillahavsregionen"
				}
			},
			"regionSelectorBar": {
				"modal": {
					"title": "Ändra region",
					"description": "Välj en region med tillhörande butiker för att visa relevant uppgifter.",
					"buttonText": "Bekräfta"
				},
				"selectedRegion": "Vald region",
				"changeButton": "Ändra"
			},
			"appIcon": {
				"newLabel": "NYHET"
			},
			"appCategory": {
				"dayToDay": "För ditt dagliga arbete",
				"nko": "Klarna Online",
				"tools": "Verktyg",
				"preferences": "Inställningar"
			},
			"apps": {
				"account-information-fe": {
					"title": "Kontouppgifter",
					"description": "Hitta dina företags- och bankuppgifter samt de avtal du undertecknat."
				},
				"home-fe": {
					"title": "Betalningar Hem"
				},
				"boosters-home-fe": {
					"title": "Kom igång"
				},
				"marketing-home-fe": {
					"title": "Marknadsföring Hem"
				},
				"nko-app": {
					"title": "Klarna Online-appar"
				},
				"orders-fe": {
					"title": "Beställningshanterare",
					"description": "Sök och hantera dina beställningar från Klarna.",
					"nkoLinkText": "Se äldre beställningar"
				},
				"nko-orders": {
					"title": "Ordrar (KO)",
					"description": "Sök och hantera dina beställningar som gjorts genom Klarna Online."
				},
				"settlements-fe": {
					"title": "Avräkningar",
					"description": "Se dina transaktioner och generera rapporter för avstämning.",
					"nkoLinkText": "Se äldre rapporter"
				},
				"nko-settlements": {
					"title": "Avräkningar (KO)",
					"description": "Hitta rapporter på beställningar och utbetalningar från Klarna Online."
				},
				"logs-app-fe": {
					"title": "Utvecklares loggar",
					"description": "Sök API-loggar för att felsöka integrationsproblem."
				},
				"users-fe": {
					"title": "Användarinställningar",
					"description": "Bjud in nya och hantera befintliga användare i portalen."
				},
				"settings-fe": {
					"title": "Betalinställningar",
					"description": ""
				},
				"nko-settings": {
					"title": "Inställningar (KO)",
					"description": "Hantera dina butiksinställningar, rapporter och kontaktdetaljer."
				},
				"seller-fe": {
					"title": "I butik",
					"description": "Skapa nya beställningar med Klarnas lösning för köp i butik."
				},
				"products-fe": {
					"title": "Introduktion",
					"description": "Skapa ditt konto för att möjliggöra betalningar."
				},
				"upstream-fe": {
					"title": "On-site messaging",
					"description": "Sälj mer med skräddarsydda och personliga finansieringserbjudanden."
				},
				"lending-fe": {
					"title": "Business Financing",
					"description": "Företagslån, fast enklare."
				},
				"branding-fe": {
					"title": "Varumärkeshantering",
					"description": "Ta kontroll över hur ditt varumärke presenteras inom Klarna."
				},
				"disputes-fe": {
					"title": "Tvister",
					"description": "Hantera dina tvister."
				},
				"logistics-fe": {
					"title": "Fraktintegrering",
					"description": "Hantera all din logistik på ett ställe."
				},
				"developers": {
					"title": "Utvecklare"
				},
				"klarna-docs": {
					"title": "Klarna Docs"
				},
				"support": {
					"title": "Support",
					"description": "Kontrollera och hantera dina supportärenden."
				},
				"integration-guides-fe": {
					"title": "Guide till integreringar",
					"description": ""
				},
				"klarna-search-b2b-portal": {
					"title": "Search & Compare",
					"description": "Kom igång med Klarnas kraftfulla sökmotor och jämförelseshopping. Få åtkomst till statistik och verktyg som designats för att förbättra din synlighet."
				},
				"siwk-fe": {
					"title": "Logga in med Klarna",
					"description": "Hantera din integration av Logga in med Klarna."
				},
				"kec-fe": {
					"title": "Expressbetalning",
					"description": "Hantera integreringen av din expressbetalning."
				},
				"partner-self-service": {
					"title": "Butikschef",
					"description": "Hantera din Klarna-butik.",
					"nkoLinkText": "Visa och hantera inställningar för din butik"
				}
			},
			"ui": {
				"tabs": {
					"home": "Betalningar",
					"growth": "Tillväxtmarknadsföring",
					"boosters": "Konverteringsförbättrare",
					"badge": "Nytt"
				},
				"buttons": {
					"backButton": "Tillbaka"
				}
			},
			"errorBoundary": {
				"contact": "Pröva att uppdatera sidan. {br}Om felet kvarstår, kontakta <supportLink>Butikssupport</supportLink> och ange felkod {errorId}",
				"errorDescription": "Fel-ID - {errorId}",
				"reloadButton": "Uppdatera",
				"title": "Tyvärr uppstod ett fel"
			},
			"maintenance": {
				"pageTitle": "Underhåll",
				"title": "Portalen för handlare genomgår schemalagt underhåll för närvarande",
				"summary": "Vi är strax tillbaka",
				"scheduledSummary": "Vi är tillbaka {until}"
			},
			"errorTemplates": {
				"errorDescription": "Felkod: {code}",
				"backButton": "Tillbaka",
				"reloadButton": "Uppdatera",
				"default": {
					"errorDescription": "Fel-ID - {errorId}",
					"title": "Tyvärr uppstod ett fel",
					"contact": "Pröva att uppdatera sidan. Om felet kvarstår, kontakta <supportLink>Butikssupport</supportLink> och ange felkod {errorId}"
				},
				"internalServerError": {
					"title": "Oj, något gick fel",
					"message": "Av någon anledning kunde vi inte behandla din förfrågan. Försök igen eller kom tillbaka senare."
				},
				"notFound": {
					"title": "Här var det tomt!",
					"message": "Sidan finns inte eller så har du skrivit fel adress i din webbläsare. Kontrollera adressen och försök igen."
				},
				"forbidden": {
					"title": "Du har inte åtkomst till den här sidan",
					"message": "Ditt Merchant ID har inte åtkomst till den här sidan. Om du tror att ett fel har uppstått, kontakta Butikssupport eller administratören för ditt företags konto."
				}
			},
			"codeSnippet": {
				"clipboardButtonLabel": "Kopiera till urklipp",
				"clipboardButtonLabelSuccess": "Kopierad!"
			}
		},
		"blackFriday": {
			"v1": {
				"title": "Black Friday",
				"intro": "En av de viktigaste shoppingdagarna är snart här. Vi kommer att finnas här för att leverera den senaste försäljningsstatistiken under Black Friday, men nu är det dags att förbereda sig för framgång!",
				"countdown": "Startar om #dd #ht #mm #ss…",
				"box1": {
					"title": "3,5x högre spenderande*",
					"text": "Förra året spenderade Klarnas shoppare* i genomsnitt 3,5x mer under Black Friday än under någon annan dag på året.",
					"footnote": "*Genomsnitt för alla Klarnas regioner och kategorier av handlare."
				},
				"box2": {
					"title": "2,5x högre försäljningsvolym*",
					"text": "Förra året genererade Klarnas handlare i genomsnitt 2,5x högre försäljningsvolym under Black Friday än under någon annan dag på året.",
					"footnote": "*Genomsnitt för alla Klarnas regioner och kategorier av handlare."
				},
				"sectionTitle": "Öka din försäljning under Black Friday",
				"marketing": {
					"title": "Marknadsför din verksamhet före och under Black Friday",
					"text": "Få fler shoppare till din butik under Black Friday-säsongen med dessa gratisresurser för sociala medier och e-postkampanjer."
				},
				"branding": {
					"title": "Bli sedd i Klarna-appen",
					"text": "12 miljoner shoppare använder Klarna-appen varje månad. Se till att du syns i appen under Black Friday genom att lägga till och uppdatera din varumärkesinformation här i butiksportalen."
				},
				"cta": "Kom igång",
				"close": "Stäng"
			},
			"v2": {
				"title": "Black Friday week",
				"orderVolume": "Ordervolym",
				"orderCountTitle": "Skapade ordrar",
				"orderCount": "{count} ordrar",
				"box": {
					"title": "Vill du öka din konvertering ytterligare?",
					"subtitle1": "On-site Messaging",
					"subtitle2": "Kampanjmeddelanden som utbildar kunder.",
					"text": "Vårt On-site messaging-verktyg ökar din försäljning och dina genomsnittliga ordervärden med hjälp av kampanjmeddelanden. Berätta för shoppare vilka betalningsalternativ som finns tillgängliga när de kommer till din sida och under hela deras besök.",
					"cta": "Kom igång"
				},
				"selector": {
					"done": "Klar",
					"applySelection": "Applicera # val",
					"selectAll": "Välj alla",
					"allOptions": "Alla alternativ valda",
					"someOptions": "# alternativ valda",
					"storeMultiSelectLabel": "Välj # butiker",
					"allStores": "Alla butiker"
				},
				"currencyLabel": "Välj valuta",
				"storeLabel": "Välj butik"
			},
			"v3": {
				"title": "Black Friday week",
				"text": "Här är din försäljning från en av årets viktigaste försäljningsveckor.",
				"summaryTitle": "Sammanfattning ",
				"orderVolume": "Total ordervolym",
				"orderCount": "Totalt antal ordrar",
				"summaryRange": "23-30 November",
				"comparisonLastWeek": "jämfört med samma dag, förra veckan",
				"tabs": {
					"monday": "Måndag",
					"tuesday": "Tisdag",
					"wednesday": "Onsdag",
					"thursday": "Torsdag",
					"blackFriday": "Black Friday",
					"saturday": "Lördag",
					"sunday": "Söndag",
					"cyberMonday": "Cyber Monday"
				}
			}
		},
		"mpSatSurvey": {
			"marble": {
				"topbarButtonLabel": "Feedback om vårt nya utseende",
				"step1": {
					"welcome": "Gillar du vårt nya utseende och känsla?",
					"satisfied": "Väldigt nöjd",
					"unsatisfied": "Väldigt missnöjd"
				},
				"step2": {
					"textAreaLabel": "Annat (din åsikt)",
					"textAreaMaxLength": "Maximal längd har uppnåtts",
					"submit": "Skicka in",
					"content": {
						"titlePositive": "Vad gillar du mest?",
						"titleNegative": "Vad ogillar du mest?",
						"tags": {
							"color": "Färg och stil",
							"navigation": "Navigering",
							"names": "Namn på sidor",
							"structure": "Struktur på sidor"
						}
					}
				},
				"step3": {
					"title": "Tack!",
					"description": "Vi uppskattar din feedback."
				}
			},
			"buttonLabel": "Lämna feedback",
			"errorMessage": "Ett fel uppstod när din feedback skulle skickas.",
			"retry": "Försök igen",
			"step1": {
				"welcome1": "Hu nöjd är du med",
				"welcome2": "Butiksportalen?",
				"emojiSelector": {
					"angry": "Väldigt missnöjd",
					"unhappy": "Lite missnöjd",
					"neutral": "Neutral",
					"happy": "Nöjd",
					"love": "Jag älskar den"
				},
				"remindMeText": "Vill du inte dela med dig av din åsikt?",
				"remindMeLink": "Påminn mig om den här undersökningen senare.",
				"dontShowAgainLink": "Visa inte detta igen."
			},
			"step2": {
				"textAreaLabel": "Annat (din åsikt)",
				"textAreaMaxLength": "Maximal längd har uppnåtts",
				"submit": "Skicka",
				"content": {
					"negative": {
						"title": "Ledsen att höra det.",
						"description": "Vilka aspekter av Butiksportalen borde vi förbättra? (Välj alla som stämmer in)",
						"tags": {
							"loading": "Laddningstiden",
							"menu": "Hur menyn är organiserad",
							"mobile": "Mobilversionen",
							"filter": "Filtrering av uppgifter",
							"complexity": "Utföra åtgärder effektivt"
						}
					},
					"positive": {
						"title": "Det är goda nyheter!",
						"description": "Vi vill fortfarande bli bättre. Vilka är de saker som skulle göra din upplevelse av Butiksportalen ännu bättre",
						"tags": {
							"views": "Anpassade vyer",
							"looks": "Utseende och känsla",
							"tours": "Guidade turer",
							"shortcuts": "Genvägar för uppgifter"
						}
					}
				}
			},
			"step3": {
				"title": "Tack! Bara en sak kvar.",
				"content1": "Vi vill bjuda in dig till en intervju så att vi kan få veta mer om din upplevelse av att använda Butiksportalen.",
				"content2": "Skulle du vilja delta?",
				"content3": "Om du går med på det kommer vi att kontakta dig via din registrerade e-postadress för att arrangera detta.",
				"yes": "Ja tack",
				"no": "Nej tack"
			},
			"step4": {
				"yesTitle": "Bra! Vi hör av oss.",
				"noTitle": "Uppfattat. Tack!",
				"content1": "Tack för att du tog dig tid att svara på dessa frågor.",
				"content2": "Du kan nu stänga det här popup-fönstret."
			}
		},
		"boost": {
			"integrationTypeModal": {
				"title": "Hur vill du ställa in {productName}?",
				"labels": {
					"plugin": "Ställ in med en plugin",
					"web": "Ställ in med Web SDK"
				}
			}
		}
	}
};