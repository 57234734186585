import { ActionIconArrowRight, SpacerVertical, Typography } from '@klarna/bubble-ui';
import React from 'react';
import styled from 'styled-components';
import TrackableLink from '../../../../../../components/TrackableLink';
export const AppContainer = styled.div.withConfig({ displayName: "AppContainer", componentId: "sc-1u8va32" }) `
  display: grid;
`;
const IconContainer = styled.div.withConfig({ displayName: "IconContainer", componentId: "sc-jsx4u8" }) `
  padding: 2px 0 0 5px;
`;
export const LinkContainer = styled.div.withConfig({ displayName: "LinkContainer", componentId: "sc-8f5xwz" }) `
  display: flex;
  width: 100%;
  margin-bottom: 5px;
`;
export const NoWrapWrapper = styled.div.withConfig({ displayName: "NoWrapWrapper", componentId: "sc-17b1ppd" }) `
  white-space: nowrap;
`;
export default function BoostApp({ linkLabel, description, appId, redirectionUrl }) {
    return (React.createElement(AppContainer, null,
        React.createElement(LinkContainer, null,
            React.createElement(NoWrapWrapper, null,
                React.createElement(TrackableLink, { href: redirectionUrl, category: `v3/boost-home/config-boost/${appId}`, colorToken: 'colors/text/default' }, linkLabel)),
            React.createElement(IconContainer, null,
                React.createElement(ActionIconArrowRight, null)),
            React.createElement(SpacerVertical, { spaceToken: 'space.200' })),
        React.createElement(Typography, { textToken: 'text-style/text/paragraphs/default/regular' }, description)));
}
