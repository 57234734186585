module.exports = {
	"fr": {
		"shared": {
			"countries": {
				"all_countries": "Tous les pays",
				"AF": "Afghanistan",
				"AX": " Îles d'Åland",
				"AL": "Albanie",
				"DZ": "Algérie",
				"AS": "Samoa américaines",
				"AD": "Andorre",
				"AO": "Angola",
				"AI": "Anguilla",
				"AQ": "Antarctique",
				"AG": "Antigua-et-Barbuda",
				"AR": "Argentine",
				"AM": "Arménie",
				"AW": "Aruba",
				"AU": "Australie",
				"AT": "Autriche",
				"AZ": "Azerbaïdjan",
				"BS": "Bahamas",
				"BH": "Bahreïn",
				"BD": "Bangladesh",
				"BB": "Barbade",
				"BY": "Biélorussie",
				"BE": "Belgique",
				"BZ": "Belize",
				"BJ": "Bénin",
				"BM": "Bermudes",
				"BT": "Bhoutan",
				"BO": "Bolivie, État plurinational de",
				"BQ": "Bonaire, Saint-Eustache et Saba",
				"BA": "Bosnie-Herzégovine",
				"BW": "Botswana",
				"BV": "Île Bouvet",
				"BR": "Brésil",
				"IO": "Territoire britannique de l'océan Indien",
				"BN": "Brunei Darussalam",
				"BG": "Bulgarie",
				"BF": "Burkina Faso",
				"BI": "Burundi",
				"KH": "Cambodge",
				"CM": "Cameroun",
				"CA": "Canada",
				"CV": "Cap-Vert",
				"KY": "Îles Caïmans",
				"CF": "République centrafricaine",
				"TD": "Tchad",
				"CL": "Chili",
				"CN": "Chine",
				"CX": "Île Christmas",
				"CC": "Îles Cocos (Keeling)",
				"CO": "Colombie",
				"KM": "Comores",
				"CG": "Congo",
				"CD": "Congo, République démocratique du",
				"CK": "Îles Cook",
				"CR": "Costa Rica",
				"CI": "Côte d'Ivoire",
				"HR": "Croatie",
				"CU": "Cuba",
				"CW": "Curaçao",
				"CY": "Chypre",
				"CZ": "République tchéque",
				"DK": "Danemark",
				"DJ": "Djibouti",
				"DM": "Dominique",
				"DO": "République dominicaine",
				"EC": "Équateur",
				"EG": "Égypte",
				"SV": "El Salvador",
				"GQ": "Guinée équatoriale",
				"ER": "Erythrée",
				"EE": "Estonie",
				"ET": "Éthiopie",
				"FK": "Îles Malouines",
				"FO": "Îles Féroé",
				"FJ": "Fidji",
				"FI": "Finlande",
				"FR": "France",
				"GF": "Guyane française",
				"PF": "Polynésie française",
				"TF": "Terres australes et antarctiques françaises",
				"GA": "Gabon",
				"GM": "Gambie",
				"GE": "Géorgie",
				"DE": "Allemagne",
				"GH": "Ghana",
				"GI": "Gibraltar",
				"GR": "Grèce",
				"GL": "Groenland",
				"GD": "Grenade",
				"GP": "Guadeloupe",
				"GU": "Guam",
				"GT": "Guatemala",
				"GG": "Guernsey",
				"GN": "Guinée",
				"GW": "Guinée-Bissau",
				"GY": "Guyane",
				"HT": "Haïti",
				"HM": "Îles Heard-et-MacDonald",
				"VA": "Saint-Siège (État de la Cité du Vatican)",
				"HN": "Honduras",
				"HK": "Hong Kong",
				"HU": "Hongrie",
				"IS": "Islande",
				"IN": "Inde",
				"ID": "Indonésie",
				"IR": "Iran, République islamique d'",
				"IQ": "Irak",
				"IE": "Irlande",
				"IM": "Île de Man",
				"IL": "Israël",
				"IT": "Italie",
				"JM": "Jamaïque",
				"JP": "Japon",
				"JE": "Jersey",
				"JO": "Jordanie",
				"KZ": "Kazakhstan",
				"KE": "Kenya",
				"KI": "Kiribati",
				"KP": "Corée, République populaire démocratique de",
				"KR": "Corée, République de",
				"KW": "Koweït",
				"KG": "Kirghizistan",
				"LA": "République démocratique populaire lao",
				"LV": "Lettonie",
				"LB": "Liban",
				"LS": "Lesotho",
				"LR": "Liberia",
				"LY": "Libye",
				"LI": "Liechtenstein",
				"LT": "Lithuanie",
				"LU": "Luxembourg",
				"MO": "Macau",
				"MK": "Macédoine, ex-République yougoslave de",
				"MG": "Madagascar",
				"MW": "Malawi",
				"MY": "Malaisie",
				"MV": "Maldives",
				"ML": "Mali",
				"MT": "Malte",
				"MH": "Îles Marshall",
				"MQ": "Martinique",
				"MR": "Mauritanie",
				"MU": "Île Maurice",
				"YT": "Mayotte",
				"MX": "Mexique",
				"FM": "Micronésie, États fédérés de",
				"MD": "Moldavie, République de",
				"MC": "Monaco",
				"MN": "Mongolie",
				"ME": "Montenegro",
				"MS": "Montserrat",
				"MA": "Maroc",
				"MZ": "Mozambique",
				"MM": "Birmanie",
				"NA": "Namibie",
				"NR": "Nauru",
				"NP": "Népal",
				"NL": "Pays-Bas",
				"NC": "Nouvelle-Calédonie",
				"NZ": "Nouvelle-Zélande",
				"NI": "Nicaragua",
				"NE": "Niger",
				"NG": "Nigéria",
				"NU": "Niue",
				"NF": "Île Norfolk",
				"MP": "Îles Mariannes du Nord",
				"NO": "Norvège",
				"OM": "Oman",
				"PK": "Pakistan",
				"PW": "Palau",
				"PS": "Palestine, État de",
				"PA": "Panama",
				"PG": "Papouasie-Nouvelle-Guinée",
				"PY": "Paraguay",
				"PE": "Pérou",
				"PH": "Philippines",
				"PN": "Pitcairn",
				"PL": "Pologne",
				"PT": "Portugal",
				"PR": "Porto Rico",
				"QA": "Qatar",
				"RE": "Réunion",
				"RO": "Roumanie",
				"RU": "Fédération de Russie",
				"RW": "Rwanda",
				"BL": "Saint-Barthélemy",
				"SH": "Sainte-Hélène, Ascension et Tristan da Cunha",
				"KN": "Saint-Christophe-et-Niévès",
				"LC": "Sainte-Lucie",
				"MF": "Saint-Martin (partie française)",
				"PM": "Saint-Pierre-et-Miquelon",
				"VC": "Saint-Vincent-et-les-Grenadines",
				"WS": "Samoa",
				"SM": "Saint-Marin",
				"ST": "Sao Tomé-et-Principe",
				"SA": "Arabie Saoudite",
				"SN": "Sénégal",
				"RS": "Serbie",
				"SC": "Seychelles",
				"SL": "Sierra Leone",
				"SG": "Singapour",
				"SX": "Saint-Martin (partie néerlandaise)",
				"SK": "Slovaquie",
				"SI": "Slovénie",
				"SB": "Îles Salomon",
				"SO": "Somalie",
				"ZA": "Afrique du Sud",
				"GS": "Géorgie du Sud-et-les Îles Sandwich du Sud",
				"SS": "Sud-Soudan",
				"ES": "Espagne",
				"LK": "Sri Lanka",
				"SD": "Soudan",
				"SR": "Suriname",
				"SJ": "Svalbard et Jan Mayen",
				"SZ": "Swaziland",
				"SE": "Suède",
				"CH": "Suisse",
				"SY": "République arabe syrienne",
				"TW": "Taïwan, Province de Chine",
				"TJ": "Tajikistan",
				"TZ": "Tanzanie, République-Unie de",
				"TH": "Thaïlande",
				"TL": "Timor-Leste",
				"TG": "Togo",
				"TK": "Tokelau",
				"TO": "Tonga",
				"TT": "Trinité-et-Tobago",
				"TN": "Tunisie",
				"TR": "Turquie",
				"TM": "Turkménistan",
				"TC": "Îles Turques-et-Caïques",
				"TV": "Tuvalu",
				"UG": "Ouganda",
				"UA": "Ukraine",
				"AE": "Émirats Arabes Unis",
				"GB": "Royaume Uni",
				"US": "États-Unis",
				"UM": "Îles mineures éloignées des États-Unis",
				"UY": "Uruguay",
				"UZ": "Ouzbékistan",
				"VU": "Vanuatu",
				"VE": "Venezuela, République bolivarienne du",
				"VN": "Viet Nam",
				"VG": "Îles Vierges Britanniques",
				"VI": "Îles Vierges Américaines",
				"WF": "Wallis et Futuna",
				"EH": "Sahara occidental",
				"YE": "Yémen",
				"ZM": "Zambie",
				"ZW": "Zimbabwe"
			},
			"months": {
				"january": "Janvier",
				"february": "Février",
				"march": "Mars",
				"april": "Avril",
				"may": "Mai",
				"june": "Juin",
				"july": "Juillet",
				"august": "Août",
				"september": "Septembre",
				"october": "Octobre",
				"november": "Novembre",
				"december": "Décembre"
			},
			"weekDaysShort": {
				"monday": "L",
				"tuesday": "M",
				"wednesday": "M",
				"thursday": "J",
				"friday": "V",
				"saturday": "S",
				"sunday": "D"
			}
		}
	}
};