import { ColoredIllustrationShopping, SpacerVertical, Typography } from '@klarna/bubble-ui';
import { hooks as i18nHooks } from 'mage-i18n';
import React from 'react';
import styled from 'styled-components';
import useExternalAccessibleApps from '../../../hooks/useExternalAccessibleApps';
import ExternalAppCard from './ExternalAppCard';
const ExternalAppCardContainer = styled.div.withConfig({ displayName: "ExternalAppCardContainer", componentId: "sc-1f7okd6" }) `
  display: flex;
  width: 100%;
  align-items: stretch;
`;
export default function ExternalAppList() {
    const t = i18nHooks.useTranslator();
    const componentRef = React.useRef(null);
    const userExternalApps = useExternalAccessibleApps().map(app => ({
        buttonText: t('home-fe.marketing-home.external-apps.cta-label'),
        clientId: app.clientId,
        description: t(`home-fe.marketing-home.external-apps.${app.clientId}.description`),
        illustration: React.createElement(ColoredIllustrationShopping, { size: 50 }),
        redirectUrl: app.url,
        title: t(`core.apps.${app.clientId}.title`)
    }));
    if (userExternalApps.length === 0) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Typography, { textToken: 'text-style/headings/titles/bold/tertiary' }, t('home-fe.marketing-home.external-apps.title')),
        React.createElement(SpacerVertical, { spaceToken: 'space.300' }),
        userExternalApps.map(app => {
            return (React.createElement(ExternalAppCardContainer, { ref: componentRef, key: app.clientId },
                React.createElement(ExternalAppCard, { buttonText: app.buttonText, clientId: app.clientId, description: app.description, illustration: app.illustration, redirectUrl: app.redirectUrl, title: app.title })));
        }),
        React.createElement(SpacerVertical, { spaceToken: 'space.500' })));
}
