module.exports = {
	"sv": {
		"credentials": {
			"addOriginModal": {
				"buttonPrimaryText": "Registrera",
				"buttonSecondaryText": "Avbryt",
				"description": "Registrera valfri ursprungsdomän som du förväntar dig att använda Web SDK på. Om du behöver registrera fler än en kommer du att kunna göra det senare.",
				"errorMessage": "Ett fel inträffade. Försök igen senare eller kontakta support om problemet kvarstår.",
				"inputLabel": "Ange resten av din URL",
				"title": "Registrera tillåtet ursprung"
			},
			"clientKeyBlock": {
				"mainText": "Din kundidentifierare är konfigurerad direkt i koden med den ursprungliga domän du angav.",
				"secondaryText": "För att hantera den ytterligare, följ länken till",
				"linkDescription": "sida för kundidentifierare",
				"linkLabel": "Stäng"
			},
			"clientKeyField": {
				"label": "Kundidentifierare",
				"defaultErrorMessage": "Kundidentifierare saknas"
			},
			"copyToClipboardButton": {
				"label": "Kopiera",
				"labelOnCopy": "Kopierad"
			},
			"originField": {
				"label": "Ursprunglig domän",
				"tooltipMainText": "För att registrera fler ursprungsdomainer eller hantera befintliga, gå till",
				"tooltipLinkLabel": "sida för kundidentifierare"
			},
			"originInputField": {
				"label": "Ange resten av din URL",
				"validationError": "Ange en giltig domän-URL",
				"invalidProtocolError": "Webbadresser måste börja med http:// eller https://"
			},
			"addApiKeySuccessModal": {
				"title": "",
				"description": "Vänligen spara följande nycklar på din enhet eller på en annan säker plats.",
				"warning": "Lösenordet kommer endast att visas en gång och försvinna när du stänger det här fönstret.",
				"checkbox": "Jag sparade nycklarna på min enhet",
				"download": "Ladda ner som .txt"
			},
			"common": {
				"password": "Lösenord",
				"username": "Användarnamn",
				"close": "Stäng"
			}
		}
	}
};