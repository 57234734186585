import * as R from 'ramda';
const sortAlphabeticallyByLabel = R.sortBy(R.prop('label'));
const mapMidsToStoreSelectorOptions = (mids, merchants = [], { disabledMids = [], pendingMids = [] } = {}) => {
    const options = mids.map(mid => {
        const merchant = R.find(R.propEq(mid, 'merchantId'), merchants);
        const storeName = R.prop('storeName', merchant);
        const isPending = pendingMids.includes(mid);
        const baseOption = {
            disabled: disabledMids.includes(mid),
            value: mid
        };
        if (storeName) {
            return Object.assign(Object.assign({}, baseOption), { description: isPending ? `${mid} - PENDING` : mid, label: storeName });
        }
        else {
            const option = Object.assign(Object.assign({}, baseOption), { label: mid });
            if (isPending) {
                option.description = 'PENDING';
            }
            return option;
        }
    });
    return sortAlphabeticallyByLabel(options);
};
export { mapMidsToStoreSelectorOptions };
