import { AlertBanner, LinkPrimary, Typography } from '@klarna/bubble-ui'
import { components as coreComponents } from 'mage-core'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Fade from 'react-reveal/Fade'

import * as selectors from '../../../selectors'

const notificationPropType = PropTypes.shape({
  content: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  linkLabel: PropTypes.string.isRequired,
  Icon: PropTypes.func.isRequired
})

function NotificationBannerContent ({ notification }) {
  return (
    <div
      id='notification-banner'
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <Typography>{notification.content}</Typography>
      <LinkPrimary href={notification.link}>{notification.linkLabel}</LinkPrimary>
    </div>
  )
}

NotificationBannerContent.propTypes = {
  notification: notificationPropType
}

export function NotificationBanner ({ notification, show }) {
  return (
    <Fade when={show}>
      <AlertBanner
        content={<NotificationBannerContent notification={notification} />}
        iconType={notification.Icon}
      />
    </Fade>
  )
}

NotificationBanner.propTypes = {
  notification: notificationPropType,
  show: PropTypes.bool
}

const AnimatedNotificationBanners = ({ notifications, delay }) => {
  const [index, setIndex] = useState(0)
  const [show, setShow] = useState(false)

  useEffect(() => {
    setShow(true)
  }, [])

  useEffect(() => {
    const intervalID = setInterval(() => {
      setIndex(R.mathMod(index + 1, notifications.length))
      setShow(false)
      setShow(true)
    }, delay)

    return () => {
      clearInterval(intervalID)
    }
  }, [notifications, index])

  return <NotificationBanner notification={notifications[index]} show={show} />
}

AnimatedNotificationBanners.propTypes = {
  notifications: PropTypes.arrayOf(notificationPropType),
  delay: PropTypes.number
}

AnimatedNotificationBanners.defaultProps = {
  delay: 10000
}

const NotificationBannerWrapper = ({ notifications }) => {
  if (!notifications.length) {
    return null
  } else if (notifications.length === 1) {
    return <NotificationBanner notification={notifications[0]} show />
  } else {
    return <AnimatedNotificationBanners notifications={notifications} />
  }
}

NotificationBannerWrapper.propTypes = {
  notifications: PropTypes.array
}

NotificationBannerWrapper.defaultProps = {
  notifications: []
}

export default R.compose(
  coreComponents.withTokenHelper(tokenHelper => ({
    tokenHelper
  })),
  connect(
    (state, { tokenHelper }) => ({
      notifications: selectors.getNotificationBanners(state)(tokenHelper)
    })
  )
)(NotificationBannerWrapper)
