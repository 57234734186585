module.exports = {
	"fi": {
		"credentials": {
			"addOriginModal": {
				"buttonPrimaryText": "Rekisteröi",
				"buttonSecondaryText": "Keskeytä",
				"description": "Rekisteröi mahdollinen lähdeverkkotunnus, jolla aiot käyttää Web SDK -työkalua. Jos sinun tarvitsee rekisteröidä useampi kuin yksi verkkotunnus, voit tehdä sen myöhemminkin.",
				"errorMessage": "Tapahtui virhe. Yritä myöhemmin uudelleen tai ole yhteydessä tukeen ongelman jatkuessa. ",
				"inputLabel": "Kirjoita loput URL-osoitteestasi",
				"title": "Rekisteröi sallittu lähde"
			},
			"clientKeyBlock": {
				"mainText": "Asiakastunnisteesi on perustettu suoraan koodiin antamallasi lähdeverkkotunnuksella.",
				"secondaryText": "Voit hallita sitä",
				"linkDescription": "asiakastunnisteet-sivulla",
				"linkLabel": "Sulje"
			},
			"clientKeyField": {
				"label": "Asiakastunniste",
				"defaultErrorMessage": "Asiakastunniste puuttuu"
			},
			"copyToClipboardButton": {
				"label": "Kopio",
				"labelOnCopy": "Kopioitu"
			},
			"originField": {
				"label": "Lähdeverkkotunnus",
				"tooltipMainText": "Jos haluat rekisteröidä useampia lähdeverkkotunnuksia tai hallita olemassa olevia, siirry",
				"tooltipLinkLabel": "asiakastunnisteet-sivulla"
			},
			"originInputField": {
				"label": "Kirjoita loput URL-osoitteestasi",
				"validationError": "Kirjoita kelvollinen verkkotunnuksen URL",
				"invalidProtocolError": "URL-osoitteen alussa on oltava http:// tai https://"
			},
			"addApiKeySuccessModal": {
				"title": "",
				"description": "Tallenna seuraavat tunnukset laitteellesi tai muuhun turvalliseen paikkaan.",
				"warning": "Salasana näytetään vain tämän kerran, ja se häviää ikkunan sulkemisen jälkeen.",
				"checkbox": "Olen tallentanut tunnukset laitteelleni.",
				"download": "Lataa .txt-tiedostona"
			},
			"common": {
				"password": "Salasana",
				"username": "Käyttäjänimi",
				"close": "Sulje"
			}
		}
	}
};