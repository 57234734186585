module.exports = {
	"nl": {
		"framework": {
			"popOvers": {
				"boostersTab": {
					"title": "",
					"content": ""
				}
			}
		}
	}
};