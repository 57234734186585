module.exports = {
	"de": {
		"config": {
			"environments": {
				"development": "Entwicklungsumgebung",
				"staging": "Staging-Umgebung",
				"playground": "Playground-Umgebung",
				"production": "Produktionsumgebung"
			}
		}
	}
};