module.exports = {
	"el": {
		"shared": {
			"months": {
				"january": "Ιανουάριος",
				"february": "Φεβρουάριος",
				"march": "Μάρτιος",
				"april": "Απρίλιος",
				"may": "Μάιος",
				"june": "Ιούνιος",
				"july": "Ιούλιος",
				"august": "Αύγουστος",
				"september": "Σεπτέμβριος",
				"october": "Οκτώβριος",
				"november": "Νοέμβριος",
				"december": "Δεκέμβριος"
			},
			"weekDaysShort": {
				"monday": "Δ",
				"tuesday": "Τ",
				"wednesday": "Τ",
				"thursday": "Π",
				"friday": "Π",
				"saturday": "Σ",
				"sunday": "Κ"
			}
		}
	}
};