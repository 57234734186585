module.exports = {
	"fr": {
		"help-tool": {
			"button-text": "Aide",
			"support-button": "Obtenir de l’aide",
			"support-page": {
				"create-ticket": {
					"title": "Obtenir de l'aide",
					"instructions": "Envoyez votre demande ci-dessous et nous vous contacterons dans un délai d’un jour ouvrable.",
					"related-content": {
						"title": "Ils vous aideront peut-être à résoudre votre problème"
					},
					"fields": {
						"mid": {
							"label": "Sélectionner une boutique",
							"validation": "Boutique requise"
						},
						"phoneNumber": {
							"label": "Numéro de téléphone (facultatif)"
						},
						"topic": {
							"question": "Quel est le sujet de votre question ?",
							"label": "Choisir un sujet",
							"validation": "Sujet requis",
							"options": {
								"orders": "Commandes",
								"settlements": "Règlements",
								"agreements": "Accords",
								"others": "Autre"
							}
						},
						"subject": {
							"label": "Sujet",
							"validation": "Sujet requis"
						},
						"description": {
							"label": "Message",
							"validation": "Description requise"
						},
						"attachment": {
							"label": "Ajoutes des pièces jointes",
							"button": "Charger",
							"info": "Ajoutez des captures d'écran pour nous aider à résoudre le problème plus rapidement.",
							"supportedFileTypes": "Types de fichiers pris en charge : .png, .jpg, .pdf",
							"error": "La taille de tous les fichiers ne doit pas dépasser {maxSize} Mb"
						}
					},
					"submit": "Faire une demande",
					"service-unavailable-error": "Oups ! Une erreur s'est produite, réessayez dans quelques minutes. Si le problème persiste, {link} pour obtenir de l'aide.",
					"generic-error": "Nous n'avons pas pu traiter votre demande. Nos systèmes ont rompu toute communication pour le moment. Veuillez essayer de rafraîchir la page ou de revenir plus tard. Si le problème persiste, {link} pour obtenir de l'aide.",
					"contact-link": "contactez-nous"
				},
				"view-ticket": {
					"title": "C'est fait !",
					"details": "Nous avons bien reçu votre demande et nous vous contacterons dans un délai d’un jour ouvrable. Voici vos détails :",
					"ticket": "Demande n° {ticketId}",
					"email": "Adresse e-mail : {email}",
					"status": "Vous pouvez suivre le statut sur notre d'assistance.",
					"track": "Suivre le statut"
				}
			},
			"header": {
				"homeLink": "Réinitialiser"
			},
			"home": {
				"greeting": "Bonjour {givenName} ! En quoi pouvons-nous vous aider ?",
				"topics": "En quoi pouvons-nous vous aider ?"
			},
			"topics": {
				"expandButton": "Plus"
			},
			"feedback": {
				"title": "Cela vous a-t-il aidé(e) ?",
				"optionYes": "Oui, cela m'a aidé(e)",
				"optionNo": "Non, j'ai toujours besoin d'aide",
				"positiveFeedback": "Merci pour votre retour positif, cela nous aide beaucoup à vous proposer des articles plus pertinents à l'avenir."
			},
			"freeTextFeedback": {
				"title": "Aidez-nous à nous améliorer",
				"placeholder": "Pourquoi n'avez-vous pas trouvé ce contenu utile ?",
				"submitButton": "Envoyer",
				"feedbackMessage": "Merci pour votre retour, cela nous aide beaucoup à vous proposer des articles plus pertinents à l'avenir."
			},
			"relatedContent": {
				"title": "Contenu semblable"
			},
			"search": {
				"buttonLabel": "Rechercher",
				"input": "Rechercher des articles...",
				"loading": "Téléchargement en cours...",
				"results": {
					"contact": "Vous ne trouvez pas l'aide dont vous avez besoin ?",
					"empty": "Désolés, votre recherche n'a donné aucun résultat. Essayez de rechercher un terme différent.",
					"singleTopicFound": "Nous avons trouvé un sujet",
					"multipleTopicsFound": "Nous avons trouvé {count} sujets",
					"singleArticleFound": "Nous avons trouvé un article",
					"multipleArticlesFound": "Nous avons trouvé {count} articles"
				}
			},
			"contact": {
				"title": "Contactez-nous",
				"channelsIntro": "Contactez-nous pour obtenir de l'aide.",
				"businessDetailsTitle": "Vos informations commerciales",
				"businessDetailsName": "Nom de société",
				"businessDetailsEmail": "Adresse e-mail",
				"businessDetailsMid": "ID de marchand",
				"phone": {
					"title": "Appelez-nous",
					"paragraph": "Vous pouvez nous appeler les jours ouvrables durant nos heures d'ouverture. Si vous vous trouvez en Suède ou au Royaume-Uni, vous pouvez également nous appeler le week-end entre 10 h et 16 h."
				},
				"email": {
					"title": "Contactez-nous par e-mail",
					"paragraph": "Envoyez-nous un e-mail et nous vous répondrons dès que possible."
				}
			},
			"errorBoundary": {
				"title": "Une erreur s'est produite",
				"message": "Désolés, une erreur s'est produite. Vous pouvez cliquer sur le bouton « Retour » ci-dessous pour revenir à l'outils d'assistance ou sur « Obtenir de l'aide » pour nous contacter.",
				"resetButton": "Retour",
				"errorId": "ID d'erreur : {errorId}"
			},
			"tour": {
				"prev": "Précedent",
				"next": "Suivant",
				"done": "Terminer"
			},
			"notAvailableMobile": {
				"title": "Non disponible",
				"buttonBack": "Retour",
				"paragraph": "Ce contenu n'est pas disponible sur votre appareil. Essayez de l'ouvrir sur un appareil avec un écran plus grand, comme un ordinateur de bureau."
			},
			"shareButton": {
				"title": {
					"article": "Établir un lien profond avec cet article",
					"topic": "Établir un lien profond avec ce sujet",
					"tour": "Établir un lien profond avec ce voyage"
				},
				"radioItemHome": "Accueil de l'application",
				"descriptionHome": "Ouvre un article d'aide sur la page d'accueil du portail des marchands",
				"radioItemCurrent": "Page en cours",
				"descriptionCurrent": "Ouvre un article d'aide sur la page en cours"
			},
			"marketSelector": {
				"whichMarketToContact": "Quel marché souhaitez-vous contacter ?"
			}
		},
		"orientation": {
			"title": "Apprendre comment ..."
		},
		"helpbot": {
			"questions": {
				"whatDoYouNeedHelpWith": "Bonjour ! Dans quel domaine avez-vous besoin d’aide aujourd’hui ?",
				"selectSupportChannel": "Veuillez sélectionner un canal pour contacter notre service d’assistance.",
				"thatHelped": "Merci pour votre feedback positif, cela nous permettra de vous apporter une aide encore plus pertinente à l’avenir."
			},
			"orders": {
				"orderManagement": {
					"title": "Gestion des commandes",
					"createOrderPos": {
						"question": "Comment créer une commande à partir d’un point de vente ?",
						"answer": "1. Point de vente ouvert 2. Saisissez le nom de l’article, le prix du produit et la quantité. 3. Partagez le lien de paiement généré avec votre client par SMS, e-mail ou en demandant au client de scanner le code QR affiché sur votre écran de paiement. 4. Le client peut maintenant terminer l’achat depuis son appareil."
					},
					"extendExpiryReservation": {
						"question": "Comment puis-je prolonger la date d’expiration d’une réservation ?",
						"answer": "1. Allez dans le gestionnaire de commandes et sélectionnez une ou plusieurs commandes pour lesquelles vous souhaitez prolonger la date d’expiration. 2. Cliquez sur Prolonger la date d’expiration. 3. Confirmez votre choix.\nLes commandes sont prolongées en fonction des paramètres de temps de réservation de votre magasin (généralement 28 jours). Si une commande a expiré, elle ne peut plus être prolongée.\""
					},
					"howToCaptureOrders": {
						"question": "Comment enregistrer des commandes ?",
						"partially": {
							"question": "Je veux partiellement enregistrer une commande",
							"answer": "S’il n’est possible d’envoyer qu’une partie de la commande d’un client, vous pouvez partiellement enregistrer la commande par ligne de commande ou par montant. Enregistrer par ligne de commande 1. Accédez à la commande dans le gestionnaire de commandes et sélectionnez les articles de la page de la commande que vous avez actuellement en stock. N’oubliez pas de modifier les lignes de commande pour ajuster les quantités si nécessaire. 2. Cliquez sur enregistrer. Enregistrez le montant : 1. Accédez à la commande dans le gestionnaire de commandes. Vous pouvez enregistrer un montant en cliquant sur le bouton \" Tout enregistrer \". Saisissez ici le montant souhaité. 2. Cliquez sur Enregistrer.\nN’oubliez pas de compléter la commande lorsque cela est possible, soit en enregistrant les articles ou le montant restant une fois qu’ils sont disponibles à la livraison, soit en annulant la commande restante si vous n’êtes pas en mesure d’envoyer les marchandises."
						},
						"fully": {
							"question": "Je veux entièrement enregistrer les commandes",
							"answer": "1. Allez dans le gestionnaire de commandes puis cliquez sur le numéro de référence Klarna pour ouvrir la commande. 2. Enregistrez l’intégralité de la commande en suivant l’une des étapes suivantes : - Cliquez sur le bouton \" Enregistrer \" - Sélectionnez les lignes de commande et cliquez sur \" Enregistrer \". - Cliquez sur \" Tout enregistrer \" et saisissez le montant total de la commande. 3. Lors de l’enregistrement d’une commande, vous pouvez télécharger le bordereau de livraison afin de l’inclure à votre envoi.\nVeillez à enregistrer vos commandes avant leur date d’expiration (généralement sous 28 jours à compter de la création de la commande). Vous pouvez enregistrer plusieurs commandes en les sélectionnant dans la liste des commandes et en cliquant sur \" Enregistrement complet \" si vous souhaitez accélérer les choses."
						}
					},
					"canIUpdateCustomerInfo": {
						"question": "Puis-je mettre à jour les informations relatives aux clients ?",
						"answer": "Vous ne pouvez jamais changer le destinataire d’une commande. Mais il est possible de modifier l’adresse de livraison et de facturation pour les commandes non enregistrées ou partiellement enregistrées. Toutefois, si Klarna rejette la nouvelle adresse parce qu’elle n’est pas liée au client, l’adresse de facturation peut quand même être modifiée."
					},
					"extendDueDate": {
						"question": "Comment prolonger la date d’échéance d’une commande ?",
						"answer": "Vous pouvez prolonger la date d’échéance d’une commande en cliquant sur \" Prolonger la date d’échéance \" d’une commande enregistrée. Le report de la date d’échéance d’une commande est assorti de frais qui seront précisés avant que vous ne finalisiez l’action."
					},
					"refundOrder": {
						"question": "Comment rembourser une commande ?",
						"answer": "1. Allez dans le gestionnaire de commandes et localisez la commande que vous souhaitez rembourser. 2. Choisissez la méthode de remboursement : sélectionnez les éléments à rembourser, puis cliquez sur \" Rembourser \", ou bien cliquez d’abord sur \" Rembourser \" pour saisir le montant à rembourser. 3. Le montant remboursé sera déduit de votre prochain règlement et le client le recevra dans les 5 jours ouvrables.\nCela s’applique uniquement aux commandes enregistrées. Pour les commandes non enregistrées, annulez-les si vous n’allez envoyer aucun article. Si seule une partie de la commande a été enregistrée et envoyée, annulez la partie non enregistrée."
					},
					"cancelOrder": {
						"question": "Comment annuler une commande ?",
						"answer": "1. Allez dans le gestionnaire de commandes et localisez la commande que vous souhaitez annuler. 2. Cliquez sur \" Annuler la commande \". Cette action informe également votre client que la commande ne sera pas envoyée.\nVous pouvez recréer des commandes annulées le cas échéant. Vous ne pouvez pas annuler les commandes enregistrées, mais vous pouvez les rembourser."
					},
					"unableToTakeActions": {
						"question": "Pourquoi ne suis-je pas en mesure d’effectuer des actions sur une commande ?",
						"directPartner": {
							"question": "J’ai conclu un accord directement avec Klarna",
							"answer": "Les autorisations des utilisateurs déterminent ce qu’ils peuvent faire dans le portail des partenaires. Il existe deux types différents d’autorisations de l’utilisateur : Admin - utilisateurs disposant d’un accès complet ; par exemple, un administrateur peut accéder à toutes les fonctionnalités du portail des partenaires et les modifier. Spécifique à la section - les utilisateurs non-administrateurs ont accès à une ou plusieurs sections spécifiques du portail des partenaires.\nC’est peut-être la raison pour laquelle vous n’arrivez pas à effectuer des actions sur les commandes. Il existe différents rôles d’accès relatifs au gestionnaire des commandes. Consultez votre administrateur pour déterminer le rôle d’accès le mieux adapté aux actions que vous devez effectuer."
						},
						"distributionPartner": {
							"question": "J’ai conclu un accord directement avec Klarna",
							"answer": "Toutes les actions que vous souhaitez effectuer sur une commande ne peuvent être effectuées que via la plateforme fournie par le partenaire de distribution."
						}
					}
				},
				"orderModification": "Modifications et problèmes concernant les commandes",
				"orderProcessing": "Traitement des commandes",
				"refundIssues": {
					"title": "Problèmes de remboursement",
					"refundedOrderWhenCustomerFunds": {
						"question": "J’ai remboursé une commande. Quand mon client recevra-t-il les fonds ?",
						"answer": "Le client devrait recevoir les fonds dans les 5 jours ouvrés suivant le remboursement de la commande. Cela peut varier en fonction de la méthode de paiement utilisée par le client. En cas de question de la part du client, veuillez le rediriger vers le support client de Klarna."
					}
				},
				"reservations": {
					"title": "Réservations",
					"whyUnableToExtendReservation": {
						"question": "Pourquoi ne puis-je pas prolonger le délai de réservation pour une commande ?",
						"answer": "Certaines circonstances spécifiques peuvent empêcher la prolongation de la date d’expiration d’une commande :\n- Si la commande n’a pas encore expiré, vous devrez la recréer. Pour ce faire, allez sur la page de la commande, cliquez sur « Copier » et sélectionnez « Créer une nouvelle commande ».\n- Si la commande n’a pas été réglée par carte bancaire, le client doit placer une nouvelle commande, étant donné que les dates d’expiration pour ce type de commande ne peuvent pas être prolongées.\n"
					}
				},
				"paymentsForMyOrders": {
					"title": "Paiements pour mes commandes",
					"whenWillIGetPaid": {
						"question": "Quand suis-je payé(e) suite à une commande ?",
						"answer": "Pour recevoir un paiement pour une commande, vous devez d’abord l’enregistrer. Le montant du paiement, après déduction des frais, des retours et autres coûts, sera versé sur votre compte bancaire conformément à votre plan de règlement. Vous pouvez passer en revue votre plan de règlement dans la section Règlements."
					},
					"refundOrderReceivedPayment": {
						"question": "Que se passe-t-il si je rembourse une commande pour laquelle j’ai déjà reçu les fonds ?",
						"answer": "Tout montant remboursé sera déduit de votre prochain paiement programmé."
					},
					"referenceNotShowUp": {
						"question": "Le numéro de commande ou de référence ne s’affiche pas dans le rapport.",
						"answer": "Si vous ne parvenez pas à trouver un paiement pour une commande spécifique :\n- Vérifiez le numéro de commande ou de référence.\n- Assurez-vous que la commande a bien été enregistrée.\n- Vérifiez que la commande est effectivement incluse dans la période de référencement du rapport.\n- Veuillez noter que toute commande annulée avant le règlement n’apparaîtra pas dans le rapport.\n"
					},
					"gotPaidSpecificOrder": {
						"question": "Comment puis-je vérifier que j’ai reçu le paiement pour une commande spécifique ?",
						"answer": "Dans le gestionnaire des commandes du portail des partenaires, veuillez rechercher la commande en vous assurant que le numéro de référence ou de commande est correct. En dessous du numéro de référence, vérifiez que la commande a bien été enregistrée. Si la commande a été enregistrée, l’état indiquera En attente ou Réglée. Pour les commandes réglées, cliquez sur « Voir le règlement » pour afficher le rapport correspondant."
					}
				}
			},
			"disputes": {
				"disputeManagement": "Gestion des litiges",
				"contactInfoAndNotifications": "Informations de contact et paramètres de notifications",
				"timingAndDeadlines": "Délais et échéances des litiges",
				"resolutionProcess": "Processus de résolution des litiges",
				"merchantDisputesApp": {
					"title": "Application Litiges dédiée aux marchands",
					"respondToDispute": {
						"question": "Comment savoir si je dois répondre à une demande de litige ?",
						"answer": "Consultez la section « À faire » sur la page d’accueil du portail des partenaires pour vérifier s’il y a des litiges nécessitant votre attention."
					},
					"overviewDisputesCustomer": {
						"question": "Où puis-je trouver une vue d’ensemble de tous les litiges signalés par les clients ?",
						"answer": "Retrouvez tous les litiges dans la section Litiges, sur la page « Tous les litiges », dès que le litige a été signalé.\n\nVeuillez noter que les litiges sont disponibles sur la page « Tous les litiges » jusqu’à 180 jours après leur fermeture.\n"
					},
					"overviewDisputesResponse": {
						"question": "Où puis-je trouver une vue d’ensemble de tous les litiges qui requièrent une réponse ?",
						"answer": "Retrouvez tous les litiges nécessitant une réponse dans la section Litiges sur la page « Litiges en cours » sous « Réponse requise »."
					},
					"updateContactInformation": {
						"question": "Comment puis-je mettre à jour mes informations de contact pour les e-mails de rappel de litige ?",
						"answer": "1. Allez dans la section Litiges\n2. Cliquez sur la page « Paramètres des e-mails et des litiges »\n3. Mettez à jour vos informations de contact dans l’onglet « E-mail et notifications ».\n\nVeuillez noter que vous pouvez configurer des informations de contact différentes pour les « Litiges », « Achats non autorisés » et les « Commandes à risque élevé ». De plus, vous pouvez configurer la fréquence des notifications dans l’onglet « Notifications ».\n"
					},
					"updateCustomerServiceOnKlarnaApp": {
						"question": "Comment puis-je mettre à jour mes informations pour le Service client dans l’application Klarna ?",
						"answer": "1. Allez dans « Paramètres de paiement »\n2. Cliquez sur « Informations Service client »\n3. Sélectionnez « Marché » (si vous disposez d’une assistance spécifique pour chaque marché)\n\nVous pouvez saisir les informations de contact du Service client ainsi que la politique des retours à afficher dans l’application Klarna pour une expérience client simplifiée.\n\nVeuillez noter que vous aurez besoin des droits d’accès complets au portail des partenaires pour afficher ces informations.\n"
					},
					"improveDisputesPerformance": {
						"question": "Comment puis-je améliorer mon taux de performance pour les litiges ?",
						"answer": "Pour obtenir des conseils et astuces sur la façon d’améliorer votre taux de performance pour les litiges, veuillez vous référer au « Guide d’amélioration des litiges et des retours ». Vous pouvez y accéder via la page « Statistiques » en cliquant sur le bouton « Obtenir des informations avancées » dans le coin supérieur droit de la page.\n\nVeuillez noter que vous aurez besoin des droits d’accès complets au portail des partenaires pour afficher ces informations.\n"
					},
					"overviewDisputesPerformance": {
						"question": "Où puis-je obtenir un aperçu de mon taux de performance pour les litiges ?",
						"answer": "Pour afficher les statistiques détaillées des litiges, veuillez vous rendre sur la page « Statistiques » dans l’Appli litiges. Cette section fournit une vue d’ensemble des litiges mensuels, des résultats, des coûts et des pertes liés aux litiges.\n\nVeuillez noter que vous aurez besoin des droits d’accès complets au portail des partenaires pour afficher ces informations.\n"
					}
				},
				"disputesLifecycle": {
					"title": "Cycle de vie des litiges",
					"whenCanSeeDisputes": {
						"question": "Quand puis-je voir les litiges signalés par les clients ?",
						"answer": "Vous pouvez consulter tous les litiges dans l’Appli litiges, accessible depuis la page « Tous les litiges » immédiatement après que le client a signalé le litige."
					},
					"howLongToResolve": {
						"question": "Quelle est la période de résolution dont je dispose pour traiter directement le litige avec le client ?",
						"answer": "Vous disposez de 21 jours à partir de l’ouverture du litige pour le résoudre directement avec le client. Si vous ne parvenez pas à résoudre le litige dans les 21 jours, Klarna interviendra pour soutenir le processus de résolution entre les deux parties."
					},
					"howMuchTimeToRefund": {
						"question": "Quel est le délai dont je dispose pour procéder au remboursement d’un retour ?",
						"answer": "Vous disposez de 21 jours à partir de la date du retour pour traiter un remboursement avant que Klarna intervienne pour soutenir le processus de résolution entre les deux parties. Cela dit, il est toujours préférable de rembourser un retour dès que vous le recevez."
					},
					"howToResolve": {
						"question": "Comment puis-je résoudre les litiges et les retours ?",
						"answer": "Il y a plusieurs façons de résoudre un litige ou un retour.\n\n- Traitez le retour ou le remboursement dès que vous le recevez/trouvez une solution.\n- Configurez un seuil de rétrofacturation dans les « Paramètres des e-mails et des litiges »\n- Soyez proactif et vérifiez régulièrement la page « Tous les litiges »\n- Acceptez les pertes financières\n"
					},
					"whenToRespond": {
						"question": "Quand dois-je répondre à/défendre activement un litige ?",
						"answer": "Si vous ou le client ne parvenez pas à trouver de solution ensemble dans le délai donné (21 jours), Klarna interviendra pour vous aider dans le processus de résolution. Si le litige est valide, l’équipe d’assistance de Klarna vous contactera via l’Appli litiges en vous demandant de fournir davantage d’informations.\n\nÀ ce moment-là, vous serez invité(e) à répondre à/défendre votre litige.\n"
					},
					"howMuchTimeToRespond": {
						"question": "Quel est le délai imparti pour répondre à une demande de litige ?",
						"answer": "Le délai pour la première demande est de 14 jours quelle que soit la raison du litige, à l’exception des achats non autorisés (7 jours). Pour chaque demande de suivi* qui vous a été envoyée (dans la limite de 5 demandes), vous disposez d’un délai de 7 jours. Si vous souhaitez ajouter des informations à votre réponse (des pièces jointes ou des précisions, par exemple), vous disposez de 60 minutes à partir de l’envoi de la réponse. Une fois ce délai passé, vous ne pourrez plus apporter d’autres éléments de défense.\n\n* Des règles spéciales peuvent s’appliquer en fonction des termes de l’accord du partenariat.\n"
					},
					"howLongToSeeDispute": {
						"question": "Pendant combien de temps puis-je accéder au litige dans l’Appli litiges une fois le litige résolu ?",
						"answer": "Les litiges sont disponibles sur la page « Tous les litiges » jusqu’à 180 jours après leur fermeture."
					},
					"whenToGetCharged": {
						"question": "À quel moment suis-je facturé(e) des frais de litige ?",
						"answer": "Vous serez facturé(e) des frais de litige si :\n- Vous êtes responsable du litige.\n- Vous ne répondez pas avant l’échéance.\n- Vous émettez un remboursement après l’intervention de Klarna pour soutenir le processus de résolution.\n\nLes frais sont appliqués rétroactivement une fois que le résultat du litige a été déterminé. Vous ne serez pas facturé si le litige est résolu en votre faveur, ou s’il est annulé ou rejeté.\n"
					}
				},
				"disputesHandling": {
					"title": "Gestion des litiges",
					"whatIsDispute": {
						"question": "Quelles informations vais-je recevoir avec une demande de litige ?",
						"answer": "La demande de litige comprend :\n\n- La raison : catégorie de litige (par ex. : retours, articles non reçus).\n- Le vendeur : pertinent s’il y a plusieurs ID de vendeurs enregistrés avec Klarna.\n- Date du litige : la date à laquelle la demande de litige est créée.\n- Échéance : la date limite à laquelle vous devez répondre au litige.\n- Produits qui font l’objet de litiges : articles qui font l’objet de litiges.\n- Détails de la commande : comprend la référence du vendeur 1 et 2, la date de la commande, le montant du litige.\n"
					},
					"howManyTimesDefend": {
						"question": "Combien de fois puis-je défendre une demande de litige ?",
						"answer": "Vous pouvez recevoir jusqu’à cinq (5) demandes pour défendre votre litige, mais dans la plupart des cas, le problème est résolu en une ou deux demandes."
					},
					"doIReceiveNotification": {
						"question": "Est-ce que je recevrai une notification lorsque je devrai répondre à un litige ?",
						"answer": "Si vous avez une demande de litige en attente, celle-ci apparaîtra à plusieurs endroits :\n\n- Dans la section « À faire » de la page d’accueil du portail des partenaires.\n- Sous forme de notification dans la section Litiges sur la page « Litiges ouverts », indiquant le nombre de litiges nécessitant une réponse.\n\nVous pouvez aussi choisir de recevoir un e-mail pour chaque litige en ajustant vos paramètres dans les « Paramètres des e-mails et des litiges » et en sélectionnant « Recevoir une notification par e-mail pour chaque litige ».\n"
					},
					"howToAvoidDisputesFees": {
						"question": "Comment puis-je éviter des frais de litige ?",
						"answer": "Pour éviter les frais de litige :\n- Traitez les retours ou remboursements rapidement.\n- Configurez un seuil de rétrofacturation dans les « Paramètres des e-mails et des litiges ».\n- Vérifiez régulièrement « Tous les litiges ».\n- Répondez aux litiges en incluant les informations pertinentes.\n- Répondez avant que la date limite ne soit dépassée.\n"
					}
				}
			},
			"settlements": {
				"payoutNotFound": {
					"title": "Le paiement pour une commande spécifique est introuvable",
					"orderNumberNotFound": {
						"question": "Le numéro de la commande ou le numéro de référence ne s’affiche pas dans le rapport",
						"answer": "Si vous ne parvenez pas à trouver un paiement pour une commande spécifique :\n- Vérifiez le numéro de commande ou de référence.\n- Assurez-vous que la commande a bien été enregistrée.\n- Vérifiez que la commande est effectivement incluse dans la période de référencement du rapport.\n- Veuillez noter que toute commande annulée avant le règlement n’apparaîtra pas dans le rapport.\n"
					},
					"checkIfPaidForOrder": {
						"question": "Comment puis-je vérifier que j’ai reçu le paiement pour une commande spécifique ?",
						"answer": "Dans le gestionnaire des commandes du portail des partenaires, veuillez rechercher la commande en vous assurant que le numéro de référence ou de commande est correct. En-dessous du numéro de référence, vérifiez que la commande a bien été enregistrée. Si la commande a été enregistrée, l’état indiquera En attente ou Réglée. Pour les commandes réglées, cliquez sur « Voir le règlement » pour afficher le rapport correspondant."
					}
				},
				"payouts": {
					"title": "Règlements",
					"whenIsNext": {
						"question": "Quand est-ce que je recevrai mon prochain paiement ?",
						"answer": null
					},
					"whyCalculationNotMatch": {
						"question": "Pourquoi le paiement reçu ne correspond-il pas à mes propres calculs ?",
						"answer": "Les frais tels que les frais de retard, de retour ou de transaction sont déduits conformément aux termes du contrat du vendeur. Il se peut donc que les règlements de Klarna diffèrent de vos propres calculs. Pour voir les déductions, nous vous recommandons d’ouvrir la version PDF du Rapport de règlements."
					},
					"whenGetPaid": {
						"question": "Quand recevrai-je mon paiement ?",
						"answer": null
					},
					"missingPayout": {
						"question": "Je n’ai pas reçu mon paiement",
						"answer": "Avant de contacter notre canal d’assistance. Les paiements manqués peuvent être dus à l’une des raisons suivantes :\n\n- Klarna envoie le paiement le jour même où le rapport est généré. Cependant, il peut s’écouler quelques jours avant que l’argent n’apparaisse sur votre compte bancaire.\n- Vous n’avez pas enregistré de commandes pour cette période.\n- Vous avez récemment mis à jour vos Données du compte bancaire avec Klarna et le paiement a peut-être été versé sur votre ancien compte. Cela peut se produire si le changement n’est pas traité avant que le paiement ne soit réalisé.\n- Le numéro de commande ou de référence que vous recherchez semble incorrect. Veuillez vérifier votre numéro de référence ou de commande.\n- Vos paiements ont été retenus par Klarna. Vous auriez dû recevoir un e-mail (généralement envoyé par votre administrateur) vous informant de la retenue d’autorisation\n"
					},
					"howIsPayoutCalculated": {
						"question": "De quelle manière mon règlement est-il calculé ?",
						"answer": "Le paiement est calculé en fonction des commandes enregistrées au cours de la période d’enregistrement, déduction faite des frais, des retours et autres coûts. Un rapport de règlement fournit une décomposition détaillée de ces montants.\nEn cas de remboursements excédant les ventes pour une période d’enregistrement donnée, le montant excédentaire sera déduit du paiement suivant.\n"
					}
				},
				"howPayoutsWork": {
					"question": "Comment fonctionnent les paiements ?",
					"answer": "La section Règlements fournit une vue d’ensemble de la façon dont les paiements fonctionnent, et notamment la Programmation des paiements, les périodes d’enregistrement et les dates de paiement. Vous pouvez télécharger les rapports pour les transactions pour chaque paiement au format PDF ou CSV, et voir ou télécharger les détails pour des paiements spécifiques en cliquant sur les références de paiement."
				},
				"payoutSchedule": {
					"title": "Plan de règlement",
					"whyDelay": {
						"question": "Pourquoi y a-t-il un délai de règlement ?",
						"answer": "L’objectif principal est d’éviter que l’argent des factures ne fasse l’objet d’allers-retours entre Klarna et vous, et également de couvrir les cas où vous remboursez un retour enregistré."
					},
					"changePayoutDelay": {
						"question": "Je veux changer mon délai de règlement",
						"answer": "Nous disposons de vérifications internes ainsi que de critères généraux qui doivent être respectés. Ces critères sont :\n- Vous devez être entièrement intégré à Klarna\n- La date de votre première commande doit remonter à plus de 100 jours\n- Votre entreprise doit avoir plus de 6 mois.\n\nKlarna requiert également les informations suivantes de la part d’un administrateur :\n- Le nouveau délai de paiement souhaité et pourquoi ce changement est nécessaire\n- L’ID de vendeur concerné par le changement\nD’autres critères peuvent s’appliquer, chaque décision étant prise au cas par cas.\n"
					}
				},
				"generatingReports": {
					"title": "Générer des rapports",
					"howToSeeReports": {
						"question": "Comment puis-je consulter les rapports pour une période donnée ?",
						"answer": "Vous pouvez personnaliser vos rapports (pour une période spécifique) en utilisant la section Rapports personnalisés dans la section Règlements."
					},
					"convertCsvToExcel2": {
						"answer": "Suivez ces étapes :\n1. Ouvrez Excel, cliquez sur « Fichier » > « Nouveau classeur ».\n"
					},
					"convertCsvToExcel": {
						"question": "Comment convertir un rapport CSV en feuille de calcul Excel ?",
						"answer": "1. Ouvrez Excel, cliquez sur « Fichier » > « Nouveau classeur ».\n2. Cliquez sur l’onglet « Données » et sélectionnez « À partir d’un fichier texte ».\n3. Choisissez votre fichier .CSV et cliquez sur « Obtenir des données ».\n4. Dans l’assistant d’aide à l’importation, choisissez « Délimité » puis cliquez sur « Suivant ».\n5. Sous « Délimiteurs », sélectionnez « Point-virgule » et placez « Qualificateur de texte » sur « Aucun ». Cliquez sur « Terminer ».\n6. Dans la fenêtre contextuelle « Importer les données », cliquez sur « OK » pour placer les données à partir de la cellule A1.\n\nRemarque : les rapports CSV dans le Portail marchand utilisent un point-virgule (;) en tant que séparateur. Pensez à vérifier votre logiciel de feuilles de calcul pour savoir comment ajuster le délimiteur par défaut.\n"
					}
				},
				"payoutAndFeeCalculation": {
					"title": "Paiement et calcul des frais",
					"howAreFeesCalculated": {
						"question": "Comment sont calculés les frais ?",
						"answer": "Les frais déduits des paiements Klarna sont spécifiés dans le contrat vendeur, tandis que les frais supplémentaires tels que les retours tardifs sont détaillés dans la section « Frais » de vos rapports de règlements/versements.\n\nPour personnaliser les descriptions des frais dans vos rapports :\n1. Allez sur Rapports de règlements > Configurer les rapports > Ajouter une nouvelle configuration.\n2. Pour les rapports CSV, sélectionnez « Type de transaction détaillé », sous la liste des Transactions.\n3. Pour les rapports PDF, sélectionnez « Inclure les détails de la commande » dans les Paramètres de contenu et d’affichage.\n\nAssurez-vous d’ajuster les paramètres pour chaque ID de vendeur et canal (SFTP, Portail marchand, API) séparément.\n"
					},
					"howIsPayoutCalculated": {
						"question": "De quelle manière mon règlement est-il calculé ?",
						"answer": "Le paiement est calculé en fonction des commandes enregistrées au cours de la période d’enregistrement, déduction faite des frais, des retours et autres coûts. Un rapport de règlement fournit une décomposition détaillée de ces montants.\n\nEn cas de remboursements excédant les ventes pour une période d’enregistrement donnée, le montant excédentaire sera déduit du paiement suivant.\n"
					}
				}
			},
			"options": {
				"payout": "Règlement",
				"orders": "Commandes",
				"settlements": "Règlements",
				"disputes": "Litiges",
				"otherIssue": "Autres problèmes",
				"yes": "Oui",
				"no": "Non",
				"partially": "Partiellement",
				"iNeedMoreHelp": "Vous avez d’autres questions ?",
				"thatHelped": "Cela m’a aidé",
				"contactSupportChannel": "Contactez les canaux d’assistance"
			},
			"button": {
				"label": "Obtenir de l’aide"
			},
			"contact": {
				"chat": "Discuter avec un agent",
				"ticket": "Envoyer un ticket",
				"times": {
					"minutes": "Temps d’attente : {time} minute(s)",
					"hours": "Temps d’attente : {time} heure(s)",
					"days": "Temps d’attente : {time} jour(s)"
				}
			}
		}
	}
};