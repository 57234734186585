module.exports = {
	"pl": {
		"core": {
			"alert": {
				"message": "Wystąpił błąd! Spróbuj ponownie.",
				"correlationIdLabel": "Identyfikator korelacji:"
			},
			"header": {
				"title": "Portal dla handlowców"
			},
			"usermenu": {
				"language": "Język",
				"country": "Kraj",
				"timezone": "Strefa czasowa",
				"logout": "Wyloguj",
				"profile": "Profil",
				"changeProfile": "Edytuj",
				"tooltip": "Twój profil"
			},
			"appsmenu": {
				"defaultTooltip": "Twoje aplikacje",
				"initialTooltip": "Tu znajdziesz swoje aplikacje.",
				"newAppsTooltip": "Mamy dla Ciebie nowe aplikacje!"
			},
			"two-factor-opt-in": {
				"title": "Skonfiguruj uwierzytelnianie dwuskładnikowe",
				"intro-paragraph": "Dla Twojego konta na Portalu dla handlowców nie aktywowano uwierzytelnienia dwuskładnikowego (two-factor authentication, 2FA). Począwszy od <b>16 stycznia 2023 r.</b> 2FA będzie obowiązkowe dla wszystkich użytkowników chcących korzystać z Portalu dla handlowców. Więcej informacji na temat znaczenia 2FA i sposobu konfiguracji zabezpieczenia, a także odpowiedzi na często zadawane pytania, można znaleźć <faqLink>tutaj</faqLink>.",
				"help-link-text": "Kliknij tutaj,",
				"help-text": " aby dowiedzieć się, jak je skonfigurować i w jaki sposób Cię ono chroni.",
				"warning-text": "Zanim rozpoczniesz, upewnij się, że masz smartfon i dostęp do internetu",
				"continue-button": "Rozpocznij konfigurację"
			},
			"kuasPrompter": {
				"title": "Wybrano",
				"content": "{count} MID",
				"contentTooltip": "Kliknij, aby skopiować MID do schowka",
				"button": "Zmień wybrane MID"
			},
			"notification": {
				"title": "Ogłoszenie",
				"content": {
					"uptodate": "Jesteś na bieżąco!"
				}
			},
			"languages": {
				"cs": "Czeski",
				"da": "Duński",
				"de": "Niemiecki",
				"el": "Grecki",
				"en": "Angielski",
				"en_GB": "Angielski (GB)",
				"en_US": "Angielski (US)",
				"es": "Hiszpański",
				"fi": "Fiński",
				"fr": "Francuski",
				"it": "Włoski",
				"nl": "Niderlandzki",
				"no": "Norweski",
				"pl": "Polski",
				"pt": "portugalski",
				"sv": "Szwedzki"
			},
			"countries": {
				"at": "Austria",
				"au": "Australia",
				"be": "Belgia",
				"ca": "Kanada",
				"ch": "Szwajcaria",
				"cz": "Czechy",
				"de": "Niemcy",
				"dk": "Dania",
				"ee": "Estonia",
				"es": "Hiszpania",
				"fi": "Finlandia",
				"fr": "Francja",
				"gb": "Wielka Brytania",
				"gr": "Grecja",
				"ie": "Irlandia",
				"it": "Włochy",
				"mx": "Meksyk",
				"nl": "Holandia",
				"no": "Norwegia",
				"se": "Szwecja",
				"us": "Stany Zjednoczone",
				"nz": "Nowa Zelandia",
				"pl": "Polska",
				"pt": "Portugalia",
				"ro": "Rumunia"
			},
			"selectLanguage": {
				"title": "Wybierz swój język",
				"paragraph": "Będzie to Twój domyślny język.",
				"submit": "OK"
			},
			"selectCountry": {
				"title": "Wybierz swój kraj",
				"paragraph": "Będzie to Twój domyślny kraj.",
				"submit": "OK"
			},
			"updateProfile": {
				"title": "Zaktualizuj profil",
				"submit": "OK",
				"emailLabel": "Adres e-mail",
				"firstNameLabel": "Imię",
				"lastNameLabel": "Nazwisko",
				"resetPasswordLabel": "Hasło",
				"resetPasswordSelectedValue": "Zresetuj",
				"resetPasswordSuccessLabel": "E-mail resetujący został pomyślnie wysłany.",
				"resetPasswordErrorLabel": "Błąd wysyłania e-maila. Spróbuj ponownie później.",
				"error": "Aktualizacja danych nie powiodła się",
				"changeCookiePolicyLabel": "Polityka dotycząca plików cookie",
				"changeCookiePolicySelectedValue": "Zmień",
				"securitySectionTitle": "Bezpieczeństwo",
				"preferencesSectionTitle": "Preferencje",
				"mfaLabel": "Uwierzytelnianie dwuskładnikowe",
				"mfaDescription": "Wymaga zalogowania się za pomocą czasowego hasła jednorazowego. Zalecamy włączenie tej opcji.",
				"twoFactor": {
					"reset": "Zmień swoje 2FA",
					"isEnabled": "Aktywne",
					"isDisabled": "Dezaktywowane",
					"title": "Uwierzytelnianie dwuskładnikowe (2FA)",
					"description": "Pozwala to użytkownikom logować się z dodatkowym zabezpieczeniem w postaci hasła jednorazowego opartego na czasie.",
					"tooltip": "Upewnij się, że masz najnowsze pozwolenie {permission} dla aplikacji {app} dla każdego sklepu, do którego użytkownik ma dostęp, aby przełączać ustawienia uwierzytelnienia dwuskładnikowego.",
					"confirmationDialog": {
						"closeButton": "Zamknij",
						"confirmationButton": "Tak, kontynuuj",
						"cancelButton": "Anuluj",
						"resetSuccess": {
							"title": "Pomyślnie zresetowano urządzenie 2FA.",
							"description": "Przy kolejnym logowaniu użytkownik będzie musiał skonfigurować nowe urządzenie na potrzeby 2FA."
						},
						"resetConfirmation": {
							"title": "Czy na pewno chcesz zresetować urządzenie 2FA tego użytkownika?",
							"description": "Kontynuując, zresetujesz urządzenie wykorzystywane do generowania kodów 2FA dla tego użytkownika. Przy kolejnym logowaniu użytkownik będzie musiał skonfigurować nowe urządzenie na potrzeby 2FA."
						}
					}
				}
			},
			"footer": {
				"copyright": {
					"eu": "Copyright © 2005-{currentYear} Klarna Bank AB (publ). Wszelkie prawa zastrzeżone.",
					"oc": "Copyright © 2005-{currentYear} Klarna Australia Pty Ltd, ABN # 82 635 912 579",
					"ap": "Copyright © 2005-{currentYear} Klarna Australia Pty Ltd, ABN # 82 635 912 579",
					"us": "Copyright © 2005-{currentYear} Klarna Inc. NMLS #1353190, 629 N. High Street, Third Floor, Columbus, OH 43215"
				},
				"terms": "Warunki i regulamin"
			},
			"accessDenied": {
				"title": "Potrzebujesz dodatkowego dostępu do tej strony.",
				"message": "W przypadku jakichkolwiek pytań skontaktuj się z administratorem lub Klarna."
			},
			"midSelector": {
				"label": "Sklep"
			},
			"storeSelector": {
				"label": "Sklep"
			},
			"storesSelector": {
				"closeText": "Gotowe",
				"closeTextWithSelection": "Zastosuj wybory (#)",
				"deselectAllOptionText": "Wyczyść wybór",
				"emptyStateText": "Nie znaleziono sklepów",
				"label": "Sklepy",
				"searchFieldLabel": "Szukaj według sklepu",
				"selectAllOptionText": "Wybierz wszystkie sklepy",
				"selectedAllText": "Wszystkie sklepy",
				"selectedText": "Sklepy: #"
			},
			"pagination": {
				"ofLabel": "z"
			},
			"singleDatePicker": {
				"selectDate": "Wybierz datę"
			},
			"dateRangePicker": {
				"startLabel": "Data rozpoczęcia",
				"endLabel": "Data zakończenia",
				"months": {
					"january": "Styczeń",
					"february": "Luty",
					"march": "Marzec",
					"april": "Kwiecień",
					"may": "Maj",
					"june": "Czerwiec",
					"july": "Lipiec",
					"august": "Sierpień",
					"september": "Wrzesień",
					"october": "Październik",
					"november": "Listopad",
					"december": "Grudzień"
				},
				"weekDaysShort": {
					"monday": "P",
					"tuesday": "W",
					"wednesday": "Ś",
					"thursday": "C",
					"friday": "P",
					"saturday": "S",
					"sunday": "N"
				}
			},
			"regionSelector": {
				"noStores": "Nie przydzielono sklepów",
				"andMore": "i nie tylko",
				"regions": {
					"eu": "Europa ",
					"us": "Ameryka Północna",
					"ap": "Azja i Pacyfik"
				}
			},
			"regionSelectorBar": {
				"modal": {
					"title": "Zmień region",
					"description": "Wybierz region i związane z nim sklepy, aby wyświetlić odpowiednie dane.",
					"buttonText": "Potwierdź"
				},
				"selectedRegion": "Wybrany region",
				"changeButton": "Zmień"
			},
			"appIcon": {
				"newLabel": "NOWOŚĆ"
			},
			"appCategory": {
				"dayToDay": "Na co dzień",
				"nko": "Klarna Online",
				"tools": "Narzędzia",
				"preferences": "Preferencje"
			},
			"apps": {
				"account-information-fe": {
					"title": "Dane konta",
					"description": "Znajdź swoje dane firmy i banku oraz podpisane umowy."
				},
				"home-fe": {
					"title": "Strona główna – płatności"
				},
				"boosters-home-fe": {
					"title": "Zaczynamy"
				},
				"marketing-home-fe": {
					"title": "Strona główna – marketing"
				},
				"nko-app": {
					"title": "Aplikacje Klarna Online"
				},
				"orders-fe": {
					"title": "Kierownik ds. zamówień",
					"description": "Wyszukaj i zarządzaj zamówieniami w Klarna.",
					"nkoLinkText": "Dostęp do starszych zamówień"
				},
				"nko-orders": {
					"title": "Zamówienia (KO)",
					"description": "Wyszukaj swoje zamówienia i zarządzaj nimi z Klarna Online."
				},
				"settlements-fe": {
					"title": "Rozliczenia",
					"description": "Otrzymuj zestawienia dopasowane do Twoich potrzeb.",
					"nkoLinkText": "Dostęp do starszych raportów"
				},
				"nko-settlements": {
					"title": "Rozliczenia (KO)",
					"description": "Znajdź raporty z zamówień i wypłat w Klarna Online."
				},
				"logs-app-fe": {
					"title": "Logi dewelopera",
					"description": "Przeszukaj logi API, aby rozwiązać problemy z integracją."
				},
				"users-fe": {
					"title": "Kierownik ds. użytkowników",
					"description": "Zarządzaj i zapraszaj nowych użytkowników w Portalu."
				},
				"settings-fe": {
					"title": "Ustawienia płatności",
					"description": ""
				},
				"nko-settings": {
					"title": "Ustawienia (KO)",
					"description": "Zarządzaj ustawieniami sklepu, raportami i danymi kontaktowymi."
				},
				"seller-fe": {
					"title": "W sklepie",
					"description": "Tworzenie nowych zamówień za pomocą rozwiązania Klarna."
				},
				"products-fe": {
					"title": "Wdrożenie",
					"description": "Skonfiguruj swoje konto, aby uruchomić płatności."
				},
				"upstream-fe": {
					"title": "Wysyłanie wiadomości w witrynie",
					"description": "Sprzedawaj więcej dzięki dopasowanym i spersonalizowanym ofertom finansowania."
				},
				"lending-fe": {
					"title": "Business Financing",
					"description": "Finansowanie biznesu w prosty sposób."
				},
				"branding-fe": {
					"title": "Kierownik ds. marki",
					"description": "Decyduj o sposobie prezentowania Twojej marki w Klarna."
				},
				"disputes-fe": {
					"title": "Spory",
					"description": "Rozwiązuj swoje spory."
				},
				"logistics-fe": {
					"title": "Integracje wysyłki",
					"description": "Zarządzaj całą logistyką w jednym miejscu."
				},
				"developers": {
					"title": "Deweloperzy"
				},
				"klarna-docs": {
					"title": "Klarna Docs"
				},
				"support": {
					"title": "Wsparcie",
					"description": "Sprawdzaj swoje prośby o wsparcie i zarządzaj nimi."
				},
				"integration-guides-fe": {
					"title": "Wytyczne dotyczące integracji",
					"description": ""
				},
				"klarna-search-b2b-portal": {
					"title": "Szukaj i porównaj",
					"description": "Zacznij korzystać z zaawansowanej wyszukiwarki i porównywarki Klarna. Masz też do dyspozycji statystyki i narzędzia zaprojektowanyce w celu zwiększenia widoczności."
				},
				"siwk-fe": {
					"title": "Zaloguj się z Klarna",
					"description": "Zarządzaj swoją integracją logowania przez Klarna."
				},
				"kec-fe": {
					"title": "Kasa ekspresowa",
					"description": "Zarządzaj swoją integracją kasy ekspresowej."
				},
				"partner-self-service": {
					"title": "Menedżer sklepu",
					"description": "Zarządzaj swoim sklepem Klarna.",
					"nkoLinkText": "Wyświetl i zarządzaj ustawieniami Twojego sklepu"
				}
			},
			"ui": {
				"tabs": {
					"home": "Klarna Payments",
					"growth": "Growth Marketing",
					"boosters": "Boostery konwersji",
					"badge": "Nowość"
				},
				"buttons": {
					"backButton": "Wstecz"
				}
			},
			"errorBoundary": {
				"contact": "Spróbuj odświeżyć stronę. {br}Jeśli błąd będzie się powtarzał, skontaktuj się ze <supportLink>Wsparciem dla handlowców,</supportLink> podając identyfikator {errorId}.",
				"errorDescription": "Identyfikator błędu – {errorId}",
				"reloadButton": "Odśwież",
				"title": "Niestety, wystąpił błąd"
			},
			"maintenance": {
				"pageTitle": "Konserwacja",
				"title": "Portal dla handlowców przechodzi obecnie zaplanowaną konserwację.",
				"summary": "Wrócimy wkrótce",
				"scheduledSummary": "Wrócimy o {until}"
			},
			"errorTemplates": {
				"errorDescription": "Błąd: {code}",
				"backButton": "Wstecz",
				"reloadButton": "Odśwież",
				"default": {
					"errorDescription": "Identyfikator błędu – {errorId}",
					"title": "Niestety, wystąpił błąd",
					"contact": "Spróbuj odświeżyć stronę. Jeśli błąd będzie się powtarzał, skontaktuj się ze <supportLink>Wsparciem dla handlowców,</supportLink> podając identyfikator błędu {errorId}."
				},
				"internalServerError": {
					"title": "Wystąpił błąd.",
					"message": "Z jakiegoś powodu po naszej stronie, nie byliśmy w stanie zrealizować Twojego zgłoszenia. Możesz spróbować ponownie lub wrócić tu za jakiś czas."
				},
				"notFound": {
					"title": "Nic tu nie ma!",
					"message": "Ta strona nie istnieje lub wpisano błędny adres w przeglądarce. Sprawdź adres i spróbuj ponownie."
				},
				"forbidden": {
					"title": "Nie masz dostępu do tej strony",
					"message": "Twój ID sprzedawcy nie ma dostępu do tej strony. Jeśli uważasz, że jest to błąd, zwróć się do Wsparcia dla handlowców lub skontaktuj się z administratorem swojego konta firmowego."
				}
			},
			"codeSnippet": {
				"clipboardButtonLabel": "Skopiuj do schowka",
				"clipboardButtonLabelSuccess": "Skopiowano!"
			}
		},
		"blackFriday": {
			"v1": {
				"title": "Czarny piątek",
				"intro": "Jeden z najważniejszych dni w handlu detalicznym jest już blisko. W Czarny piątek będziemy Ci dostarczać statystyki sprzedaży w czasie prawie rzeczywistym. Tymczasem przygotujmy się na sukces!",
				"countdown": "Zacznie się za #dd #gg #mm #ss...",
				"box1": {
					"title": "3,5x większe wydatki*",
					"text": "W ubiegłym roku konsumenci Klarna* wydali w Czarny piątek średnio 3,5x więcej niż w jakikolwiek inny dzień roku.",
					"footnote": "* Średnia dla wszystkich kategorii sprzedawców i regionów Klarna."
				},
				"box2": {
					"title": "2,5x większy wolumen sprzedaży*",
					"text": "W ubiegłym roku sprzedawcy Klarna w Czarny piątek osiągnęli 2,5x większy wolumen sprzedaży niż w jakikolwiek inny dzień w roku.",
					"footnote": "* Średnia dla wszystkich kategorii sprzedawców i regionów Klarna."
				},
				"sectionTitle": "Zwiększmy Twoją sprzedaż w Czarny piątek",
				"marketing": {
					"title": "Promuj swój biznes przed Czarnym piątkiem i w Czarny piątek.",
					"text": "W Czarny piątek przyciągnij do swojego sklepu więcej konsumentów dzięki tym bezpłatnym materiałom do kampanii społecznościowych i e-mailowych."
				},
				"branding": {
					"title": "Niech Cię zobaczą w aplikacji Klarna",
					"text": "12 milionów konsumentów używa aplikacji Klarna każdego miesiąca. Zabłyśnij w aplikacji w ten Czarny piątek, dodając i aktualizując informacje o swojej marce tutaj, w Portalu dla handlowców."
				},
				"cta": "Zaczynamy",
				"close": "Zamknij"
			},
			"v2": {
				"title": "Czarny tydzień",
				"orderVolume": "Wolumen sprzedaży",
				"orderCountTitle": "Liczba zamówień",
				"orderCount": "{count} zamówień",
				"box": {
					"title": "Chcesz jeszcze bardziej zwiększyć liczbę konwersji?",
					"subtitle1": "Wysyłanie wiadomości w witrynie",
					"subtitle2": "Przekaz promocyjny, który uczy.",
					"text": "Nasze narzędzie do wysyłania wiadomości w witrynie pomaga zwiększyć sprzedaż i średnie wartości zamówień dzięki komunikatom promocyjnym. Niech konsumenci widzą dostępne opcje płatności od razu po wejściu na Twoją stronę i przez całą podróż zakupową.",
					"cta": "Zaczynamy"
				},
				"selector": {
					"done": "Gotowe",
					"applySelection": "Zastosuj wybory (#)",
					"selectAll": "Zaznacz wszystko",
					"allOptions": "Wybrano wszystkie opcje",
					"someOptions": "Wybrane opcje: #",
					"storeMultiSelectLabel": "Wybierz sklepy: #",
					"allStores": "Wszystkie sklepy"
				},
				"currencyLabel": "Wybierz walutę",
				"storeLabel": "Wybierz sklep"
			},
			"v3": {
				"title": "Czarny tydzień",
				"text": "Oto Twoja sprzedaż z jednego z najważniejszych tygodni handlowych w roku.",
				"summaryTitle": "Podsumowanie",
				"orderVolume": "Łączny wolumen",
				"orderCount": "Łączna liczba zamówień",
				"summaryRange": "23–30 listopada",
				"comparisonLastWeek": "w porównaniu z tym samym dniem w zeszłym tygodniu",
				"tabs": {
					"monday": "Poniedziałek",
					"tuesday": "Wtorek",
					"wednesday": "Środa",
					"thursday": "Czwartek",
					"blackFriday": "Czarny piątek",
					"saturday": "Sobota",
					"sunday": "Niedziela",
					"cyberMonday": "Cyberponiedziałek"
				}
			}
		},
		"mpSatSurvey": {
			"marble": {
				"topbarButtonLabel": "Opinia na temat naszego nowego wyglądu",
				"step1": {
					"welcome": "Podoba Ci się nasz nowy wygląd i styl?",
					"satisfied": "Bardzo zadowalające",
					"unsatisfied": "Bardzo niezadowalające"
				},
				"step2": {
					"textAreaLabel": "Inne (Twoja opinia)",
					"textAreaMaxLength": "Osiągnięto maksymalną długość",
					"submit": "Prześlij",
					"content": {
						"titlePositive": "Co najbardziej Ci się podoba?",
						"titleNegative": "Co najbardziej Ci się nie podoba?",
						"tags": {
							"color": "Kolor i styl",
							"navigation": "Nawigacja",
							"names": "Nazwa stron",
							"structure": "Struktura stron"
						}
					}
				},
				"step3": {
					"title": "Dziękujemy!",
					"description": "Doceniamy Twoją opinię."
				}
			},
			"buttonLabel": "Przekaż opinię",
			"errorMessage": "Podczas wysyłania opinii wystąpił błąd.",
			"retry": "Ponów",
			"step1": {
				"welcome1": "Jak bardzo podoba Ci się",
				"welcome2": "Portal dla handlowców?",
				"emojiSelector": {
					"angry": "W ogóle",
					"unhappy": "Nie bardzo",
					"neutral": "Trudno powiedzieć",
					"happy": "Podoba mi się",
					"love": "Uwielbiam go"
				},
				"remindMeText": "Nie chcesz podzielić się opinią?",
				"remindMeLink": "Przypomnij mi później o ankiecie.",
				"dontShowAgainLink": "Nie pokazuj tej informacji ponownie."
			},
			"step2": {
				"textAreaLabel": "Inne (Twoja opinia)",
				"textAreaMaxLength": "Osiągnięto maksymalną długość",
				"submit": "Prześlij",
				"content": {
					"negative": {
						"title": "Przykro nam.",
						"description": "Jakie aspekty Portalu dla handlowców powinniśmy poprawić? (Zaznacz wszystkie pasujące odpowiedzi)",
						"tags": {
							"loading": "Czas ładowania",
							"menu": "Układ menu",
							"mobile": "Wersja mobilna",
							"filter": "Filtrowanie danych",
							"complexity": "Sprawne działanie"
						}
					},
					"positive": {
						"title": "Świetna wiadomość!",
						"description": "Nadal chcemy się poprawiać. Co sprawiłoby, że Twoje wrażenia z korzystania z Portalu dla handlowców byłyby jeszcze lepsze?",
						"tags": {
							"views": "Niestandardowe widoki",
							"looks": "Wygląd i układ",
							"tours": "Przewodniki",
							"shortcuts": "Skróty do zadań"
						}
					}
				}
			},
			"step3": {
				"title": "Dziękujemy! Na koniec chcemy poruszyć jeszcze jedną kwestię.",
				"content1": "Chcielibyśmy zaprosić Cię na rozmowę, abyśmy mogli dowiedzieć się więcej o Twoich wrażeniach z korzystania z naszego Portalu dla handlowców.",
				"content2": "Czy zgodzisz się wziąć w niej udział?",
				"content3": "Jeśli tak, skontaktujemy się z Tobą za pośrednictwem zarejestrowanego adresu e-mail, aby ustalić szczegóły.",
				"yes": "Tak, poproszę",
				"no": "Nie, dziękuję"
			},
			"step4": {
				"yesTitle": "Świetnie! Będziemy w kontakcie.",
				"noTitle": "Rozumiem. Dziękujemy!",
				"content1": "Dziękujemy za poświęcenie czasu na udzielenie odpowiedzi na pytania.",
				"content2": "Możesz teraz zamknąć to okno."
			}
		},
		"boost": {
			"integrationTypeModal": {
				"title": "Jak chcesz skonfigurować {productName}?",
				"labels": {
					"plugin": "Skonfiguruj za pomocą wtyczki",
					"web": "Skonfiguruj za pomocą Web SDK"
				}
			}
		}
	}
};