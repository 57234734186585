module.exports = {
	"nl": {
		"credentials": {
			"addOriginModal": {
				"buttonPrimaryText": "Registreren",
				"buttonSecondaryText": "Annuleren",
				"description": "Registreer elk oorspronkelijk domein waarop u de Web SDK wilt gebruiken. Als u er meer dan één moet registreren, kunt u dit later doen.",
				"errorMessage": "Er is een fout opgetreden. Probeer het later opnieuw of neem contact op met ondersteuning als het probleem aanhoudt.",
				"inputLabel": "Voer de rest van uw URL in",
				"title": "Toegestane oorspronkelijke domein registreren"
			},
			"clientKeyBlock": {
				"mainText": "Uw cliëntidentificator is direct ingesteld in de code met het oorspronkelijke domein dat u heeft opgegeven.",
				"secondaryText": "Om het verder te beheren, volg dan de link naar de",
				"linkDescription": "pagina met cliëntidentificatoren",
				"linkLabel": "Afsluiten"
			},
			"clientKeyField": {
				"label": "Cliëntidentificator",
				"defaultErrorMessage": "Cliëntidentificator ontbreekt"
			},
			"copyToClipboardButton": {
				"label": "Kopiëren",
				"labelOnCopy": "Gekopieerd"
			},
			"originField": {
				"label": "Oorsprongkelijk domein",
				"tooltipMainText": "Om meer oorspronkelijke domeinen te registreren of bestaande te beheren, ga naar de",
				"tooltipLinkLabel": "pagina met cliëntidentificatoren"
			},
			"originInputField": {
				"label": "Voer de rest van uw URL in",
				"validationError": "Voer een geldige domein-URL in",
				"invalidProtocolError": "URL moet beginnen met http:// of https://"
			},
			"addApiKeySuccessModal": {
				"title": "",
				"description": "Sla de volgende inloggegevens op op uw apparaat of op een andere, veilige plek.",
				"warning": "Het wachtwoord wordt alleen nu getoond en is niet meer te zien zodra u dit venster sluit.",
				"checkbox": "Ik heb de inloggegevens opgeslagen op mijn apparaat.",
				"download": "Download als .txt"
			},
			"common": {
				"password": "Wachtwoord",
				"username": "Gebruikersnaam",
				"close": "Afsluiten"
			}
		}
	}
};