module.exports = {
	"es": {
		"shared": {
			"months": {
				"january": "Enero",
				"february": "Febrero",
				"march": "Marzo",
				"april": "Abril",
				"may": "Mayo",
				"june": "Junio",
				"july": "Julio",
				"august": "Agosto",
				"september": "Septiembre",
				"october": "Octubre",
				"november": "Noviembre",
				"december": "Diciembre"
			},
			"weekDaysShort": {
				"monday": "L",
				"tuesday": "M",
				"wednesday": "X",
				"thursday": "J",
				"friday": "V",
				"saturday": "S",
				"sunday": "D"
			}
		}
	}
};