import { selectors as experimentationSelectors } from '@merchant-portal/experimentation';
import { hooks as coreHooks } from 'mage-core';
import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { BoostHome, MarketingHome, StoreHome } from './pages';
import HomeInstallationGuide from './pages/BoostHome/pages/HomeInstallationGuide';
const BOOST_V03_EXPERIMENT_KEY = 'merchant-portal.boost.v03.enabled';
function Routes() {
    const { path } = coreHooks.useSanitizedRouteMatch();
    const isBoostV03Enabled = useSelector(experimentationSelectors.isFeatureToggleEnabled(BOOST_V03_EXPERIMENT_KEY));
    return (React.createElement(Switch, null,
        isBoostV03Enabled ?
            React.createElement(Route, { from: `${path}/boost`, component: HomeInstallationGuide }) :
            React.createElement(Route, { from: `${path}/boost`, component: BoostHome }),
        React.createElement(Route, { from: `${path}/growth`, component: MarketingHome }),
        React.createElement(Route, { from: `${path}/`, component: StoreHome })));
}
export default Routes;
