module.exports = {
	"fi": {
		"help-tool": {
			"button-text": "Apua",
			"support-button": "Pyydä tukea",
			"support-page": {
				"create-ticket": {
					"title": "Pyydä tukea",
					"instructions": "Lähetä alla oleva pyyntö, ja vastaamme sinulle 1 arkipäivän kuluessa.",
					"related-content": {
						"title": "Nämä saattavat auttaa sinua ratkaisemaan ongelman"
					},
					"fields": {
						"mid": {
							"label": "Valitse kauppa",
							"validation": "Kauppa on pakollinen"
						},
						"phoneNumber": {
							"label": "Puhelinnumero (valinnainen)"
						},
						"topic": {
							"question": "Mitä kysymyksesi koskee?",
							"label": "Valitse aihe",
							"validation": "Aihe on pakollinen",
							"options": {
								"orders": "Tilaukset",
								"settlements": "Tilitykset",
								"agreements": "Sopimukset",
								"others": "Muu"
							}
						},
						"subject": {
							"label": "Aiherivi",
							"validation": "Aiherivi on pakollinen"
						},
						"description": {
							"label": "Viesti",
							"validation": "Kuvaus on pakollinen"
						},
						"attachment": {
							"label": "Lisää liitteitä",
							"button": "Lataa",
							"info": "Näyttökaappauksen avulla selvitämme ongelman nopeammin.",
							"supportedFileTypes": "Tuetut tiedostomuodot: .png, .jpg, .pdf",
							"error": "Tiedostojen koko ei saa ylittää kokonaisuudessaan {maxSize} MB"
						}
					},
					"submit": "Lähetä pyyntö",
					"service-unavailable-error": "Hups! Jokin meni vikaan, yritä uudelleen muutaman minuutin kuluttua. Mikäli ongelma jatkuu, {link}.",
					"generic-error": "Emme pystyneet käsittelemään pyyntöäsi. Järjestelmiemme välillä on kommunikaatiokatkos. Päivitä sivu tai yritä myöhemmin uudelleen. Mikäli ongelma jatkuu, {link}.",
					"contact-link": "ota meihin yhteyttä"
				},
				"view-ticket": {
					"title": "Valmista on!",
					"details": "Olemme vastaanottaneet pyyntösi, ja vastaamme sinulle 1 arkipäivän kuluessa. Tässä ovat tietosi:",
					"ticket": "Pyynnön numero {ticketId}",
					"email": "Sähköposti: {email}",
					"status": "Voit seurata tilaa tukisivullamme.",
					"track": "Seuraa tilaa"
				}
			},
			"header": {
				"homeLink": "Nollaa"
			},
			"home": {
				"greeting": "Hei {givenName}! Miten voimme auttaa sinua?",
				"topics": "Missä asiassa tarvitset apua?"
			},
			"topics": {
				"expandButton": "Lisää"
			},
			"feedback": {
				"title": "Oliko tästä apua?",
				"optionYes": "Kyllä, tästä oli apua",
				"optionNo": "Ei, tarvitsen vielä apua",
				"positiveFeedback": "Kiitos positiivisesta palautteestasi! Se auttaa meitä tarjoamaan sinulle tulevaisuudessa sinua kiinnostavia artikkeleja."
			},
			"freeTextFeedback": {
				"title": "Auta meitä parantamaan toimintaamme",
				"placeholder": "Miksi tämä sisältö ei ollut mielestäsi hyödyllistä?",
				"submitButton": "Lähetä",
				"feedbackMessage": "Kiitos palautteestasi! Se auttaa meitä tarjoamaan sinulle tulevaisuudessa sinua kiinnostavia artikkeleja."
			},
			"relatedContent": {
				"title": "Asiaan liittyvä sisältö"
			},
			"search": {
				"buttonLabel": "Etsi",
				"input": "Hae artikkeleja...",
				"loading": "Ladataan...",
				"results": {
					"contact": "Etkö löydä apua ongelmaasi?",
					"empty": "Valitettavasti haullasi ei löytynyt yhtään tulosta. Yritä hakea toisella hakusanalla.",
					"singleTopicFound": "Löysimme 1 aiheen",
					"multipleTopicsFound": "Löysimme {count} aihetta",
					"singleArticleFound": "Löysimme 1 artikkelin",
					"multipleArticlesFound": "Löysimme {count} artikkelia"
				}
			},
			"contact": {
				"title": "Ota meihin yhteyttä",
				"channelsIntro": "Ota meihin yhteyttä, jotta voimme auttaa sinua.",
				"businessDetailsTitle": "Yrityksesi tiedot",
				"businessDetailsName": "Yrityksen nimi",
				"businessDetailsEmail": "Sähköposti",
				"businessDetailsMid": "Kaupan tunnus",
				"phone": {
					"title": "Soita meille",
					"paragraph": "Voit soittaa meille arkisin virka-aikaan. Jos olet Ruotsissa tai Yhdistyneessä kuningaskunnassa, voit soittaa meille myös viikonloppuisin klo 10–16."
				},
				"email": {
					"title": "Lähetä meille sähköpostia",
					"paragraph": "Lähetä meille sähköpostia ja otamme sinuun yhteyttä mahdollisimman pian."
				}
			},
			"errorBoundary": {
				"title": "Jotain meni pieleen",
				"message": "Pahoittelemme, mutta tapahtui virhe. Voit napsauttaa \"Palaa takaisin\"-painiketta alta, jolloin palaat takaisin Ohje-työkaluun. Voit myös ottaa meihin yhteyttä napsauttamalla \"Pyydä tukea\" -painiketta.",
				"resetButton": "Palaa takaisin",
				"errorId": "Virhetunnus: {errorId}"
			},
			"tour": {
				"prev": "Edellinen",
				"next": "Seuraava",
				"done": "Valmis"
			},
			"notAvailableMobile": {
				"title": "Ei saatavilla",
				"buttonBack": "Takaisin",
				"paragraph": "Tämä sisältö ei ole saatavilla laitteellasi. Yritä avata se laitteella, jossa on suurempi näyttö, kuten pöytätietokoneella."
			},
			"shareButton": {
				"title": {
					"article": "Jaa linkki tähän artikkeliin",
					"topic": "Jaa linkki tähän aiheeseen",
					"tour": "Jaa linkki tähän kierrokseen"
				},
				"radioItemHome": "Etusivusovellus",
				"descriptionHome": "Avaa tukiartikkelin Kauppiasportaalin etusivulla",
				"radioItemCurrent": "Nykyinen sivu",
				"descriptionCurrent": "Avaa tukiartikkelin nykyisellä sivulla"
			},
			"marketSelector": {
				"whichMarketToContact": "Mihin markkina-alueeseen haluat saada yhteyden?"
			}
		},
		"orientation": {
			"title": "Opi lisää aiheesta ..."
		},
		"helpbot": {
			"questions": {
				"whatDoYouNeedHelpWith": "Hei! Missä asiassa tarvitset tukea tänään?",
				"selectSupportChannel": "Ota yhteyttä tukipalveluumme valitsemalla kanava.",
				"thatHelped": "Kiitos positiivisesta palautteestasi. Se auttaa meitä jatkossakin tarjoamaan sinulle juuri oikeanlaista tukea. 😀"
			},
			"orders": {
				"orderManagement": {
					"title": "Tilaushallinta",
					"createOrderPos": {
						"question": "Kuinka luon tilauksen Point of Salesin kautta?",
						"answer": "1. Avaa Point of Sales 2. Kirjoita tuotteen nimi, hinta ja lukumäärä. 3. Jaa luomasi maksulinkki asiakkaallesi tekstiviestillä tai sähköpostilla tai pyydä asiakasta skannaamaan kassasivulla näkyvä QR-koodi. 4. Asiakas voi nyt viimeistellä oston laitteellaan."
					},
					"extendExpiryReservation": {
						"question": "Kuinka siirrän aktivoinnin eräpäivää?",
						"answer": "1. Siirry Tilaushallintaan ja valitse yksi tai useampi tilaus, joiden aktivoinnin eräpäivää haluat siirtää. 2. Napsauta Siirrä aktivoinnin eräpäivää. 3. Vahvista valintasi.\nTilausten aktivointia siirretään kauppasi aika-asetusten mukaisesti (tavallisesti 28 päivää). Jos tilaus on vanhentunut, sen aktivoinnin eräpäivää ei voi enää siirtää.\""
					},
					"howToCaptureOrders": {
						"question": "Miten aktivoin tilauksia?",
						"partially": {
							"question": "Haluan aktivoida tilauksen osittain.",
							"answer": "Jos asiakkaan tilauksesta on mahdollista lähettää vain osa, voit aktivoida tilauksen osittain tilausriveittäin tai summalla. Aktivoi tilausrivit 1. Siirry tilaukseen Tilaushallinnassa ja valitse tilaussivulta tuotteet, joita varastossasi tällä hetkellä on. Muista korjata tilausriveille tarvittaessa oikeat lukumäärät. 2. Napsauta aktivoi. Aktivoi summa: 1. Siirry tilaukseen Tilaushallinnassa. Voit aktivoida summan napsauttamalla \"Aktivoi kaikki\" -painiketta. Nyt voit kirjoittaa haluamasi summan. 2. Napsauta aktivoi.\nMuista viimeistellä tilaus, kun se on mahdollista, joko aktivoimalla jäljellä olevat tuotteet/summan, kun tuotteita on saatavilla lähetettäväksi – tai peruuttamalla jäljellä oleva tilaus, jos et pysty lähettämään tuotteita."
						},
						"fully": {
							"question": "Haluan aktivoida tilauksia kokonaan.",
							"answer": "1. Siirry Tilaushallintaan ja avaa tilaus klikkaamalla Klarnan viitenumeroa. 2. Aktivoi koko tilaus noudattamalla jotakin seuraavista ohjeista: - Napsauta \"Aktivoi\"-painiketta – Valitse tilausrivit ja napsauta \"Aktivoi\" - Napsauta \"Aktivoi kaikki\" ja kirjoita tilauksen kokonaissumma. 3. Kun olet aktivoimassa tilausta, voit ladata pakkausluettelon ja sisällyttää sen lähetykseen.\nMuistathan aktivoida tilaukset ennen niiden vanhenemispäivää (tavallisesti 28 päivän kuluttua tilauksen luonnista). Voit aktivoida useampia tilauksia valitsemalla ne tilausluettelosta ja napsauttamalla \"Aktivoi kokonaan\", jos haluat hoitaa asian nopeammin."
						}
					},
					"canIUpdateCustomerInfo": {
						"question": "Voinko päivittää asiakastietoja?",
						"answer": "Et voi koskaan muuttaa tilauksen vastaanottajaa. Voit kuitenkin muuttaa toimitus- ja laskutusosoitteita, jos tilaus on aktivoimaton tai osittain aktivoitu. Jos Klarna kuitenkin hylkää uuden osoitteen, koska sitä ei ole yhdistetty asiakkaaseen, laskutusosoitteen voi silti muuttaa."
					},
					"extendDueDate": {
						"question": "Miten tilauksen eräpäivää siirretään?",
						"answer": "Voit siirtää tilauksen eräpäivää napsauttamalla aktivoidusta tilauksesta \"Siirrä epäpäivää\". Tilauksen eräpäivän siirtämisestä aiheutuu kulu, joka määritellään ennen kuin suoritat toiminnon loppuun."
					},
					"refundOrder": {
						"question": "Miten hyvitän tilauksen?",
						"answer": "1. Siirry Tilaushallintaan ja etsi tilaus, jonka haluat hyvittää. 2. Valitse hyvitystapa: valitse joko hyvitettävät tuotteet ja napsauta sitten \"Hyvitä\" tai napsauta ensin \"Hyvitä\" ja kirjoita sitten summa, jonka haluat hyvittää. 3. Hyvityssumma vähennetään seuraavasta tilityksestäsi, ja asiakas saa sen 5 arkipäivän kuluessa.\nTämä koskee vain aktivoituja tilauksia. Jos tilaus on aktivoimaton, peruuta se, jos et aio lähettää tuotteita. Jos vain osa tilauksesta aktivoitiin ja lähetettiin, vapauta aktivoimaton osa."
					},
					"cancelOrder": {
						"question": "Miten peruutan tilauksen?",
						"answer": "1. Siirry Tilaushallintaan ja etsi tilaus, jonka haluat peruuttaa. 2. Napsauta \"Peruuta tilaus\". Tällä toiminnolla ilmoitetaan asiakkaalle, että tilausta ei tulla lähettämään.\nVoit tarvittaessa luoda peruutetut tilaukset uudelleen. Et voi peruuttaa aktivoituja tilauksia, mutta voit hyvittää ne."
					},
					"unableToTakeActions": {
						"question": "Miksi en pysty suorittamaan toimintoja tilaukseen liittyen?",
						"directPartner": {
							"question": "Minulla on sopimus suoraan Klarnan kanssa.",
							"answer": "Käyttäjäluvat määrittävät, mitä käyttäjät voivat tehdä Kumppaniportaalissa. Käyttäjäoikeuksia on kahta eri tyyppiä: Admin-käyttäjä – käyttäjät, joilla on täydelliset käyttöoikeudet; esim. admin-käyttäjä voi tarkastella ja muokata kaikkia Kumppaniportaalin toimintoja. Osiokohtainen – muut kuin admin-käyttäjät voivat tarkastella yhtä tai useampaa tiettyä osiota Kumppaniportaalissa.\nTämä voi olla syy siihen, että et pysty suorittamaan toimintoja tilauksille. Tilaushallinnassa on erilaisia käyttäjärooleja. Keskustele admin-käyttäjäsi kanssa määrittääksesi itsellesi sopivan roolin, jolla voit suorittaa tarpeellisia toimintoja."
						},
						"distributionPartner": {
							"question": "Minulla on sopimus suoraan Klarnan kanssa.",
							"answer": "Kaikki toimenpiteet, jotka haluat tilaukselle tehdä, voidaan tehdä ainoastaan jakelukumppanin tarjoaman alustan kautta."
						}
					}
				},
				"orderModification": "Tilauksen muokkaaminen ja ongelmat",
				"orderProcessing": "Tilauksen käsittely",
				"refundIssues": {
					"title": "Hyvitykset",
					"refundedOrderWhenCustomerFunds": {
						"question": "Olen hyvittänyt tilauksen, milloin asiakkaani saa rahansa?",
						"answer": "Asiakkaan pitäisi saada rahansa 5 arkipäivän kuluessa päivästä, jolloin tilaus hyvitettiin. Tämä voi vaihdella asiakkaan valitseman maksutavan mukaan. Jos asiakkaalla on kysyttävää tähän liittyen, ohjaa hänet Klarnan asiakaspalveluun."
					}
				},
				"reservations": {
					"title": "Varaukset",
					"whyUnableToExtendReservation": {
						"question": "Miksen pysty siirtämään tilauksen varausaikaa?",
						"answer": "Tietyissä tilanteissa tilauksen vanhenemispäivän siirtäminen ei ole mahdollista:\n- Jos tilaus on jo vanhentunut, sinun tulee luoda se uudelleen. Luo uusi tilaus siirtymällä tilaussivulle, napsauttamalla ”Kopioi” ja valitsemalla sitten ”Luo uusi tilaus”.\n- Jos tilaus maksettiin kortilla, asiakkaan on tehtävä uusi tilaus, sillä kortilla maksettujen tilausten vanhenemispäiviä ei voi siirtää.\n"
					}
				},
				"paymentsForMyOrders": {
					"title": "Maksut tilauksista",
					"whenWillIGetPaid": {
						"question": "Milloin tilaus maksetaan minulle?",
						"answer": "Jotta voit saada maksun tilauksesta, sinun on aktivoitava se ensin. Tilityssumma, josta on vähennetty kulut, palautukset ja muut veloitukset, lähetetään pankkitilillesi tilitysaikataulusi mukaisesti. Voit tarkistaa tilitysasetuksesi Tilitykset-osiossa."
					},
					"refundOrderReceivedPayment": {
						"question": "Mitä tapahtuu, jos hyvitän tilauksen, josta olen jo saanut rahaa?",
						"answer": "Kaikki hyvittämäsi summat vähennetään seuraavasta aikataulusi mukaisesta tilityksestä."
					},
					"referenceNotShowUp": {
						"question": "Tilausnumero tai viitenumero eivät näy raportissasi.",
						"answer": "Jos et löydä jonkin tietyn tilauksen tilitystä:\n- Tarkista, että tilauksen viitenumero on oikein.\n- Varmista, että tilaus on aktivoitu.\n- Tarkista, osuuko tilaus raportin aktivointijaksoon.\n- Huomaa, että tilaukset, jotka on peruutettu ennen tilitystä, eivät näy raportissa.\n"
					},
					"gotPaidSpecificOrder": {
						"question": "Mistä näen, onko jokin tietty tilaus maksettu minulle?",
						"answer": "Hae tilausta Kumppaniportaalin tilaussivulla ja varmista, että tilaus- tai viitenumero on oikein. Viitenumeron alta voit tarkistaa, onko tilaus aktivoitu. Jos tilaus on aktivoitu, tila on odottava tai tilitetty. Jos tilaus on maksettu, tarkastele vastaavaa raporttia napsauttamalla ”Näytä tilitys”."
					}
				}
			},
			"disputes": {
				"disputeManagement": "Kiistanhallinta",
				"contactInfoAndNotifications": "Yhteystiedot ja ilmoitusasetukset",
				"timingAndDeadlines": "Kiistan ajankohta ja määräajat",
				"resolutionProcess": "Kiistan ratkaisuprosessi",
				"merchantDisputesApp": {
					"title": "Twister-sovelluksen",
					"respondToDispute": {
						"question": "Mistä tiedän, onko minun vastattava kiistapyyntöön?",
						"answer": "Tarkista Kumppaniportaalin etusivulta ”Tehtävät”-osiosta, onko sinulla vastaustasi odottavia kiistoja."
					},
					"overviewDisputesCustomer": {
						"question": "Mistä löydän yleiskatsauksen kaikista asiakkaideni nostamista kiistoista?",
						"answer": "Löydät kaikki nostetut kiistat ”Kaikki kiistat” -sivulta Kiistat-osiosta. Kiistat näkyvät siellä välittömästi sen jälkeen, kun ne on nostettu.\n\nHuomaathan, että kiistat ovat saatavilla ”Kaikki kiistat” -sivulla 180 päivän ajan niiden sulkemisen jälkeen.\n"
					},
					"overviewDisputesResponse": {
						"question": "Mistä löydän yleiskatsauksen kaikista kiistoista, jotka edellyttävät vastausta?",
						"answer": "Löydät kaikki vastausta vaativat kiistat ”Avoimet kiistat” -sivulta Kiistat-osiosta kohdasta ”Vastausta vaaditaan”."
					},
					"updateContactInformation": {
						"question": "Miten päivitän yhteystietoni kiistojen muistutussähköposteja varten?",
						"answer": "1. Siirry Kiistat-osioon.\n2. Napsauta ”Sähköposti- ja kiista-asetukset” -sivua.\n3. Päivitä yhteystietosi ”Sähköposti ja ilmoitukset” -välilehdelle.\n\nHuomaa, että voit asettaa eri yhteystiedot ”kiistoille”, ”luvattomille ostoille” ja ”korkean riskin tilauksille”. Lisäksi voit muokata ilmoitusten lähetystiheyttä ”Ilmoitukset”-välilehdellä.\n"
					},
					"updateCustomerServiceOnKlarnaApp": {
						"question": "Miten päivitän asiakaspalveluni tiedot Klarna-sovelluksessa?",
						"answer": "1. Siirry ”Maksuasetuksiin”.\n2. Napsauta ”Asiakaspalvelun tiedot”.\n3. Valitse ”Markkina-alue” (jos tukesi on markkinakohtainen).\n\nVoit lisätä asiakasyhteystiedot sekä palautuskäytännön näytettäväksi Klarna-sovelluksessa asiakaskokemuksen parantamiseksi.\n\nJotta voit nähdä nämä tiedot, sinulla tulee olla Kumppaniportaalin täydet käyttöoikeudet.\n"
					},
					"improveDisputesPerformance": {
						"question": "Miten voin parantaa suorituskykyäni kiistoissa?",
						"answer": "Saat vinkkejä ja ohjeita kiistoihin liittyvän suorituskyvyn parantamiseen ”Palautukset ja kiistat -kehitysoppaasta”. Pääset oppaaseen ”Tilastot”-sivun kautta napsauttamalla ”Tietoja”-painiketta sivun oikeasta yläkulmasta.\n\nJotta voit nähdä nämä tiedot, sinulla tulee olla Kumppaniportaalin täydet käyttöoikeudet.\n"
					},
					"overviewDisputesPerformance": {
						"question": "Mistä näen yleiskatsauksen suorituskyvystäni kiistoissa?",
						"answer": "Nähdäksesi yksityiskohtaiset tilastot kiistoista siirry Kiistasovelluksessa ”Tilastot”-sivulle. Täältä saat yleiskatsauksen kiistoihin, ratkaisuihin, kuluihin ja kiistoihin liittyviin tappioihin kuukausitasolla.\n\nJotta voit nähdä nämä tiedot, sinulla tulee olla Kumppaniportaalin täydet käyttöoikeudet.\n"
					}
				},
				"disputesLifecycle": {
					"title": "Kiistojen elinkaari",
					"whenCanSeeDisputes": {
						"question": "Milloin näen asiakkaiden nostamat kiistat?",
						"answer": "Näet kaikki nostetut kiistat Kiistasovelluksessa ”Kaikki kiistat” -sivulla välittömästi kiistan nostamisen jälkeen."
					},
					"howLongToResolve": {
						"question": "Kuinka kauan minulla on aikaa ratkaista kiista suoraan asiakkaan kanssa?",
						"answer": "Sinulla on 21 päivää aikaa ratkaista kiista asiakkaan kanssa kiistan nostopäivästä alkaen. Jos ette ole saaneet kiistaa ratkaistua 21 päivän kuluessa kiistan nostamisesta, Klarna puuttuu asiaan löytääkseen ratkaisun osapuolten välillä."
					},
					"howMuchTimeToRefund": {
						"question": "Kuinka kauan minulla on aikaa palautusten hyvittämiseen?",
						"answer": "Sinulla on palautuspäivästä alkaen 21 päivää aikaa käsitellä hyvitys ennen kuin Klarna puuttuu asiaan löytääkseen ratkaisun osapuolten välillä. Näin ollen palautukset on aina hyvä hyvittää välittömästi niiden vastaanottamisen jälkeen."
					},
					"howToResolve": {
						"question": "Kuinka ratkaisen kiistat ja palautukset?",
						"answer": "On useita tapoja ratkaista kiista tai palautus.\n\n- Käsittele palautus tai hyvitys välittömästi sen vastaanottamisen jälkeen / sovi ratkaisusta.\n- Aseta takaisinveloituskynnys kohdassa ”Sähköposti- ja kiista-asetukset”.\n- Toimi ennakoivasti tarkistamalla ”Kaikki kiistat”.\n- Hyväksy tappio.\n"
					},
					"whenToRespond": {
						"question": "Milloin minun pitää aktiivisesti puolustaa/vastata kiistaan?",
						"answer": "Jos ette pääse asiakkaan kanssa ratkaisuun keskenänne määrätyssä ajassa (21 päivää), Klarna puuttuu asiaan löytääkseen ratkaisun osapuolten välillä. Jos kiista on oikeutettu, Klarnan tukitiimi ottaa sinuun yhteyttä Kiistasovelluksen kautta ja pyytää lisätietoja.\n\nTässä kohtaa sinua pyydetään puolustamaan/vastaamaan kiistaan.\n"
					},
					"howMuchTimeToRespond": {
						"question": "Kuinka kauan minulla on aikaa vastata kiistapyyntöön?",
						"answer": "Ensimmäisen pyynnön määräaika on 14 päivää kaikkien kiistasyiden osalta, poikkeuksena luvattomat ostot (7 päivää). Jokaisen sinulle lähetetyn seurantapyynnön* (enintään 5 pyyntöä) määräaika on 7 päivän kuluessa. Jos sinun tarvitsee lisätä vastaukseesi esimerkiksi tietoja tai liitteitä, voit tehdä sen 60 minuutin kuluessa alkuperäisen viestin lähettämisestä. Kun määräaika on kulunut, et pysty enää puolustamaan tapausta osaltasi.\n\n*Erityissääntöjä saatetaan soveltaa kumppanisopimuksesta riippuen.\n"
					},
					"howLongToSeeDispute": {
						"question": "Kuinka kauan voin nähdä kiistan Kiistasovelluksessa kiistan ratkaisemisen jälkeen?",
						"answer": "Kiista on saatavilla ”Kaikki kiistat” -sivulla 180 päivän ajan sen sulkemisen jälkeen."
					},
					"whenToGetCharged": {
						"question": "Milloin minulta veloitetaan kiistakulu?",
						"answer": "Sinulle voi aiheutua kiistakulu, jos:\n- kiista on sinun syytäsi\n- et vastaa määräaikaan mennessä\n- myönnät hyvityksen sen jälkeen, kun Klarna on puuttunut asiaan ratkaisun löytämiseksi.\n\nKulu veloitetaan sen jälkeen, kun kiistan tulos on päätetty. Sinulta ei veloiteta kiistakulua, jos kiista ratkaistaan sinun eduksesi tai jos se peruutetaan tai hylätään.\n"
					}
				},
				"disputesHandling": {
					"title": "Kiistojen käsittely",
					"whatIsDispute": {
						"question": "Mitä tietoja minulle lähetetään kiistapyynnön yhteydessä?",
						"answer": "Kiistapyyntö sisältää:\n\n- syyn: kiistaluokka, esim. palautukset, tuotteita ei vastaanotettu\n- kauppiaan: tämä koskee kauppiaita, joilla on useita kauppiastunnuksia (MID) Klarnalla\n- kiistapäivän: päivämäärä, jolloin asiakas on nostanut kiistan\n- määräajan: määräaika, johon mennessä sinun on vastattava kiistaan puolustaaksesi kantaasi\n- kiistanalaiset tuotteet: tuote/tuotteet, joita kiista koskee\n- tilaustiedot: sisältää kauppiaan viitteet 1 ja 2, tilauspäivän ja kiistasumman.\n"
					},
					"howManyTimesDefend": {
						"question": "Kuinka monta kertaa voin puolustautua yhtä kiistapyyntöä kohden?",
						"answer": "Sinulle lähetetään enintään viisi (5) pyyntöä kiistassa puolustautumiseksi. Useimmiten pyyntöjä tarvitaan vain yksi tai kaksi."
					},
					"doIReceiveNotification": {
						"question": "Saanko ilmoituksia, kun minun on vastattava kiistoihin?",
						"answer": "Jos sinulla on odottava kiistapyyntö, näet sen useissa kohdissa:\n\n- kumppaniportaalin etusivulla kohdassa ”Tehtävät”\n- ilmoituksena ”Avoimet kiistat” -sivulla Kiista-kohdassa, jossa näkyy vastausta odottavien kiistojen lukumäärä.\n\nVoit myös valita vastaanottaa sähköpostin jokaisesta kiistasta muokkaamalla asetuksiasi kohdassa ”Sähköposti- ja kiista-asetukset” > ”Sähköposti-ilmoitus jokaisesta kiistasta”.\n"
					},
					"howToAvoidDisputesFees": {
						"question": "Kuinka voin välttää kiistakulut?",
						"answer": "Voit välttää kiistakulut:\n- käsittelemällä palautukset tai hyvitykset hyvissä ajoin\n- asettamalla takaisinveloituskynnyksen kohdassa ”Sähköposti- ja kiista-asetukset”\n- tarkistamalla säännöllisesti ”Kaikki kiistat”\n- vastaamalla kiistoihin aina asianmukaisilla tiedoilla\n- vastaamalla ennen määräajan päättymistä.\n"
					}
				}
			},
			"settlements": {
				"payoutNotFound": {
					"title": "Tietyn tilauksen tilitystä ei löydy",
					"orderNumberNotFound": {
						"question": "Tilausnumero tai viitenumero eivät näy raportissasi",
						"answer": "Jos et löydä jonkin tietyn tilauksen tilitystä:\n- Tarkista, että tilauksen viitenumero on oikein.\n- Varmista, että tilaus on aktivoitu.\n- Tarkista, osuuko tilaus raportin aktivointijaksoon.\n- Huomaa, että tilaukset, jotka on peruutettu ennen tilitystä, eivät näy raportissa.\n"
					},
					"checkIfPaidForOrder": {
						"question": "Mistä näen, onko jokin tietty tilaus maksettu minulle?",
						"answer": "Hae tilausta Kumppaniportaalin tilaussivulla ja varmista, että tilaus- tai viitenumero on oikein. Viitenumeron alta voit tarkistaa, onko tilaus aktivoitu. Jos tilaus on aktivoitu, tila on odottava tai tilitetty. Jos tilaus on maksettu, tarkastele vastaavaa raporttia napsauttamalla ”Näytä tilitys”."
					}
				},
				"payouts": {
					"title": "Maksut",
					"whenIsNext": {
						"question": "Milloin on seuraava tilitykseni?",
						"answer": null
					},
					"whyCalculationNotMatch": {
						"question": "Miksi omat laskelmani eivät vastaa saamiani tilityksiä?",
						"answer": "Kauppiassopimuksen mukaisesti tilityksestä vähennetään kuluja, kuten kulut maksutapahtumista ja myöhästyneistä palautuksista. Näin ollen laskelmasi saattavat poiketa Klarnan tilityksistä. Nähdäksesi vähennykset selvästi, suosittelemme avaamaan tilitysraportin PDF-tiedostona."
					},
					"whenGetPaid": {
						"question": "Milloin tilitys maksetaan minulle?",
						"answer": null
					},
					"missingPayout": {
						"question": "En ole saanut tilitystäni",
						"answer": "Ennen kuin otat yhteyttä tukeemme, ota huomioon, että tilitysten puuttumisen syy voi olla jokin seuraavista:\n\n- Klarna lähettää tilityksen samana päivänä kuin raportti luotiin. Rahojen näkymisessä pankkitililläsi voi silti kestää muutama päivä.\n- Et ole aktivoinut yhtään tilausta tämän aktivointijakson aikana.\n- Olet hiljattain päivittänyt pankkitilitietosi Klarnalle, mikä on voinut aiheuttaa rahojen siirtymisen vanhalle pankkitilillesi. Näin voi käydä, jos muutosta ei ehditty käsitellä ennen maksun suorittamista.\n- Etsimäsi tilausnumero tai viitenumero on virheellinen. Tarkista tilaus- tai viitenumero.\n- Klarna pidättää maksusi. Sinun olisi pitänyt saada tieto maksun pidätyksestä erikseen sähköpostilla. Nämä sähköpostit lähetetään tavallisesti admin-käyttäjälle.\n"
					},
					"howIsPayoutCalculated": {
						"question": "Miten tilitykseni lasketaan?",
						"answer": "Tilitys lasketaan aktivointijakson aikana aktivoiduista tilauksista, ja summasta vähennetään kulut, palautukset ja muut veloitukset. Yksityiskohtainen erittely näistä summista näkyy tilitysraportissa.\nJos hyvitykset ylittävät myynnin aktivointijakson aikana, ylittänyt summa vähennetään seuraavasta tilityksestä.\n"
					}
				},
				"howPayoutsWork": {
					"question": "Kuinka tilitykset toimivat?",
					"answer": "Tilitykset-osio sisältää yleiskatsauksen siitä, kuinka tilitykset toimivat. Aiheita ovat muun muassa tilitysaikataulut, aktivointijaksot sekä tilityspäivät. Voit ladata tapahtumaraportteja kunkin tilityksen osalta PDF- tai CSV-muodossa ja tarkastella tai ladata tietoja jokaisesta maksusta napsauttamalla maksun maksuviitettä."
				},
				"payoutSchedule": {
					"title": "Maksuaikataulu",
					"whyDelay": {
						"question": "Mikä tarkoitus on tilitysviiveellä?",
						"answer": "Pääasiallinen tarkoitus on välttää rahan lähettämistä edestakaisin Klarnan ja sinun välillä sekä kattaa tapaukset, joissa hyvität aktivoidun tilauksen."
					},
					"changePayoutDelay": {
						"question": "Haluan muuttaa tilitysviivettäni",
						"answer": "Käytämme sisäisiä tarkistuksia sekä yleisiä ehtoja, jotka tulee täyttää. Yleisiä ehtoja ovat:\n- Klarnan perehdytysprosessi tulee olla täysin suoritettu\n- ensimmäisestä tilauspäivästäsi on yli 100 päivää\n- liiketoimintasi tulee olla yli 6 kuukautta vanha.\n\nKlarna edellyttää myös seuraavia tietoja admin-käyttäjältä:\n- uusi haluttu maksuviive, ja syy siihen, miksi maksuviiveen muuttaminen on tarpeen\n- mitä kauppiastunnusta (MID) muutos koskee.\nLisäehtoja saatetaan soveltaa, ja päätökset tehdään aina tapauskohtaisesti.\n"
					}
				},
				"generatingReports": {
					"title": "Raporttien luominen",
					"howToSeeReports": {
						"question": "Miten voin nähdä raportit tietyiltä jaksoilta?",
						"answer": "Voit mukauttaa raporttejasi kattamaan tiettyjä ajanjaksoja käyttämällä Tilitykset-osion Mukauta raportteja -kohtaa."
					},
					"convertCsvToExcel2": {
						"answer": "Toimi seuraavien ohjeiden mukaan\n1. Avaa Excel, napsauta ”Tiedosto” > ”Uusi työkirja”.\n"
					},
					"convertCsvToExcel": {
						"question": "Kuinka muunnan CSV-raportin Excel-laskentataulukoksi?",
						"answer": "1. Avaa Excel, napsauta ”Tiedosto” > ”Uusi työkirja”.\n2. Siirry ”Tiedot”-välilehdelle ja valitse ”Tekstistä”.\n3. Valitse haluamasi .CSV-tiedosto ja napsauta ”Hae tiedot”.\n4. Ohjatussa tekstin tuomisessa valitse ”Erotettu” ja napsauta sitten ”Seuraava”.\n5. Kohdassa ”Erottimet” valitse ”Puolipiste” ja aseta kohtaan ”Tekstierotin” ”ei mitään”. Napsauta ”Valmis”.\n6. ”Tuo tiedot” -dialogiruudussa napsauta ”OK”, jotta voit asettaa tiedot alkaen solusta A1.\n\nHuomaa: Kauppiasportaalin CSV-raportit käyttävät puolipistettä (;) erottimena. Voit halutessasi tarkistaa työkirjaohjelmastasi, kuinka oletusarvoista erotinta muokataan.\n"
					}
				},
				"payoutAndFeeCalculation": {
					"title": "Tilityksen ja kulujen laskeminen",
					"howAreFeesCalculated": {
						"question": "Miten kulut lasketaan?",
						"answer": "Klarna-tilityksistä vähennettävät kulut määritellään kauppiassopimuksessasi. Sopimuksessa on määritelty myös lisäkulut, kuten myöhäisistä palautuksista aiheutuneet kulut, jotka on eritelty tilitysraporttisi ”Kulu”-kohdassa.\n\nVoit muokata kulujen kuvauksia raporteissasi seuraavasti:\n1. Siirry kohtaan Tilitysraportit > Koosta raportteja > Lisää uusi määritys.\n2. Jos koostat CSV-raporttia, valitse ”Yksityiskohtainen maksutapahtumatyyppi” kohdassa Maksutapahtumaluettelo.\n3. Jos koostat PDF-raporttia, valitse ”Sisällytä tilaustiedot” kohdassa Asettelun ja sisällön asetukset.\n\nVarmista, että muokkaat asetukset kunkin kauppiastunnuksen ja kanavan osalta (SFTP, Kauppiasportaali, API) erikseen.\n"
					},
					"howIsPayoutCalculated": {
						"question": "Miten tilitykseni lasketaan?",
						"answer": "Tilitys lasketaan aktivointijakson aikana aktivoiduista tilauksista, ja summasta vähennetään kulut, palautukset ja muut veloitukset. Yksityiskohtainen erittely näistä summista näkyy tilitysraportissa.\n\nJos hyvitykset ylittävät myynnin aktivointijakson aikana, ylittänyt summa vähennetään seuraavasta tilityksestä.\n"
					}
				}
			},
			"options": {
				"payout": "Maksut",
				"orders": "Tilaukset",
				"settlements": "Tilitykset",
				"disputes": "Twister",
				"otherIssue": "Muut ongelmat",
				"yes": "Kyllä",
				"no": "Ei",
				"partially": "Osittain",
				"iNeedMoreHelp": "Tarvitsen lisää apua",
				"thatHelped": "Tästä oli apua",
				"contactSupportChannel": "Ota yhteyttä tukikanaviin"
			},
			"button": {
				"label": "Pyydä tukea"
			},
			"contact": {
				"chat": "Chattaa asiakaspalvelijan kanssa",
				"ticket": "Lähetä tukipyyntö",
				"times": {
					"minutes": "Jonotusaika: {time} minuutti(a)",
					"hours": "Jonotusaika: {time} tunti(a)",
					"days": "Jonotusaika: {time} päivä(ä)"
				}
			}
		}
	}
};