module.exports = {
	"fr": {
		"config": {
			"environments": {
				"development": "Environnement de développement",
				"staging": "Environnement de test",
				"playground": "Environnement d'expérience",
				"production": "Environnement de production"
			}
		}
	}
};