module.exports = {
	"da": {
		"core": {
			"alert": {
				"message": "Noget gik galt. Prøv igen.",
				"correlationIdLabel": "Korrelations-ID"
			},
			"header": {
				"title": "Butiksportal"
			},
			"usermenu": {
				"language": "Sprog",
				"country": "Land",
				"timezone": "Tidszone",
				"logout": "Log ud",
				"profile": "Profil",
				"changeProfile": "Rediger",
				"tooltip": "Din profil"
			},
			"appsmenu": {
				"defaultTooltip": "Dine apper",
				"initialTooltip": "Find dine apper her.",
				"newAppsTooltip": "Vi har nye apper til dig!"
			},
			"two-factor-opt-in": {
				"title": "Sæt op tofaktorautentisering",
				"intro-paragraph": "Du har ikke to-faktor-godkendelse (2FA) aktiveret på din butiksportalkonto. Fra den <b>16. januar 2023</b>, vil 2FA være obligatorisk for alle brugere for at kunne få adgang til butiksportalen. Du kan læse mere om vigtigheden af ​​2FA, hvordan du sætter det op og svar på almindelige spørgsmål <faqLink>her</faqLink>.",
				"help-link-text": "Klik her",
				"help-text": " for at lære mere om, hvordan du opsætter det, og hvordan det beskytter dig.",
				"warning-text": "Sørg for, at du har din smartphone og adgang til internettet, inden du fortsætter",
				"continue-button": "Start opsætningen"
			},
			"kuasPrompter": {
				"title": "Du har valgt",
				"content": "{count} MID'er",
				"contentTooltip": "Klik for at kopiere MID'er til udklipsholder",
				"button": "Ændr valgte MID'er"
			},
			"notification": {
				"title": "Bekendtgørelse",
				"content": {
					"uptodate": "Du er opdateret!"
				}
			},
			"languages": {
				"cs": "Tjekkisk",
				"da": "Dansk",
				"de": "Tysk",
				"el": "Græsk",
				"en": "Engelsk",
				"en_GB": "Engelsk (GB)",
				"en_US": "Engelsk (US)",
				"es": "Spansk",
				"fi": "Finsk",
				"fr": "Fransk",
				"it": "Italiensk",
				"nl": "Hollandsk",
				"no": "Norsk",
				"pl": "Polsk",
				"pt": "Portugisisk",
				"sv": "Svensk"
			},
			"countries": {
				"at": "Østrig",
				"au": "Australien",
				"be": "Belgien",
				"ca": "Canada",
				"ch": "Schweitz",
				"cz": "Tjekkiet",
				"de": "Tyskland",
				"dk": "Danmark",
				"ee": "Estland",
				"es": "Spanien",
				"fi": "Finland",
				"fr": "Frankrig",
				"gb": "Storbritannien",
				"gr": "Grækenland",
				"ie": "Irland",
				"it": "Italien",
				"mx": "Mexico",
				"nl": "Holland",
				"no": "Norge",
				"se": "Sverige",
				"us": "USA",
				"nz": "New Zealand",
				"pl": "Polen",
				"pt": "Portugal",
				"ro": "Rumænien"
			},
			"selectLanguage": {
				"title": "Vælg dit sprog",
				"paragraph": "Dette bliver dit standardsprog.",
				"submit": "OK"
			},
			"selectCountry": {
				"title": "Vælg dit land",
				"paragraph": "Dette bliver dit standardland.",
				"submit": "OK"
			},
			"updateProfile": {
				"title": "Opdatér profil",
				"submit": "OK",
				"emailLabel": "E-mailadresse",
				"firstNameLabel": "Fornavn",
				"lastNameLabel": "Efternavn",
				"resetPasswordLabel": "Adgangskode",
				"resetPasswordSelectedValue": "Nulstil",
				"resetPasswordSuccessLabel": "Nulstil e-mailen blev sendt",
				"resetPasswordErrorLabel": "Fejl under afsending af e-mail. Prøv igen senere.",
				"error": "Fejl i forbindelse med opdatering af oplysninger",
				"changeCookiePolicyLabel": "Cookiepolitik",
				"changeCookiePolicySelectedValue": "Skift",
				"securitySectionTitle": "Sikkerhed",
				"preferencesSectionTitle": "Præferencer",
				"mfaLabel": "Tofaktorautentificering",
				"mfaDescription": "Kræver, at der logges ind med en tidsbaseret engangskode. Vi anbefaler, at dette slås til.",
				"twoFactor": {
					"reset": "Skift din 2FA",
					"isEnabled": "Aktiveret",
					"isDisabled": "Deaktiveret",
					"title": "Tofaktorautentificering (2FA)",
					"description": "Dette giver brugere mulighed for at logge på med den ekstra sikkerhed som en tidsbaseret engangsadgangskode giver.",
					"tooltip": "Sørg for at du i det mindste har tilladelsen {permission} til, at {app} appen for hver butik, brugeren har adgang til for at skifte mellem to-faktor-godkendelsesindstillingen.",
					"confirmationDialog": {
						"closeButton": "Luk",
						"confirmationButton": "Ja, fortsæt",
						"cancelButton": "Annuller",
						"resetSuccess": {
							"title": "2FA-enheden blev nulstillet.",
							"description": "Næste gang brugeren logger på, skal de konfigurere en ny enhed til 2FA."
						},
						"resetConfirmation": {
							"title": "Er du sikker på, at du vil nulstille denne brugers 2FA-enhed?",
							"description": "Ved at fortsætte nulstiller du den enhed, der blev brugt til at generere 2FA-koder for denne bruger. Næste gang brugeren logger på, skal de konfigurere en ny enhed til 2FA."
						}
					}
				}
			},
			"footer": {
				"copyright": {
					"eu": "Copyright © 2005-{currentYear} Klarna Bank AB (publ). Alle rettigheder forbeholdes",
					"oc": "Copyright © 2005-{currentYear} Klarna Australia Pty Ltd, ABN # 82 635 912 579",
					"ap": "Copyright © 2005-{currentYear} Klarna Australia Pty Ltd, ABN # 82 635 912 579",
					"us": "Copyright © 2005-{currentYear} Klarna Inc. NMLS #1353190, 629 N. High Street, Third Floor, Columbus, OH 43215"
				},
				"terms": "Vilkår og betingelser"
			},
			"accessDenied": {
				"title": "Du skal have yderligere adgang for at se denne side.",
				"message": "Kontakt din administrator eller Klarna, hvis du har spørgsmål."
			},
			"midSelector": {
				"label": "Butik"
			},
			"storeSelector": {
				"label": "Butik"
			},
			"storesSelector": {
				"closeText": "Udført",
				"closeTextWithSelection": "Påfør # valg",
				"deselectAllOptionText": "Slet valg",
				"emptyStateText": "Ingen butikker blev fundet",
				"label": "Butikker",
				"searchFieldLabel": "Søg efter butik",
				"selectAllOptionText": "Vælg alle butikker",
				"selectedAllText": "Alle butikker",
				"selectedText": "# Antal butikker"
			},
			"pagination": {
				"ofLabel": "af"
			},
			"singleDatePicker": {
				"selectDate": "Vælg dato"
			},
			"dateRangePicker": {
				"startLabel": "Startdato",
				"endLabel": "Slutdato",
				"months": {
					"january": "Januar",
					"february": "Februar",
					"march": "Marts",
					"april": "April",
					"may": "Maj",
					"june": "Juni",
					"july": "Juli",
					"august": "August",
					"september": "September",
					"october": "Oktober",
					"november": "November",
					"december": "December"
				},
				"weekDaysShort": {
					"monday": "M",
					"tuesday": "T",
					"wednesday": "O",
					"thursday": "T",
					"friday": "F",
					"saturday": "S",
					"sunday": "S"
				}
			},
			"regionSelector": {
				"noStores": "Ingen butikker tildelt",
				"andMore": "og meget mere",
				"regions": {
					"eu": "Europa",
					"us": "Nordamerika",
					"ap": "Asien og Stillehavsområdet"
				}
			},
			"regionSelectorBar": {
				"modal": {
					"title": "Skift region",
					"description": "Vælg en region med tilhørende butikker for at vise relevante data.",
					"buttonText": "Bekræft"
				},
				"selectedRegion": "Valgte region",
				"changeButton": "Skift"
			},
			"appIcon": {
				"newLabel": "NYHED"
			},
			"appCategory": {
				"dayToDay": "Til dine daglige",
				"nko": "Klarna Online",
				"tools": "Værktøjer",
				"preferences": "Indstillinger"
			},
			"apps": {
				"account-information-fe": {
					"title": "Kontooplysninger",
					"description": "Find dine virksomheds- og bankoplysninger og de aftaler, du har underskrevet."
				},
				"home-fe": {
					"title": "Betalinger hjem"
				},
				"boosters-home-fe": {
					"title": "Kom godt i gang"
				},
				"marketing-home-fe": {
					"title": "Markedsføring hjem"
				},
				"nko-app": {
					"title": "Klarna online-apps"
				},
				"orders-fe": {
					"title": "Ordremanager",
					"description": "Søg og administrer dine ordrer fra Klarna.",
					"nkoLinkText": "Tilgå ældre ordrer"
				},
				"nko-orders": {
					"title": "Ordrer (KO)",
					"description": "Søg og administrer de ordrer, du har lagt gennem Klarna Online."
				},
				"settlements-fe": {
					"title": "Afregninger",
					"description": "Se dine transaktioner og generér rapporter til forsoning.",
					"nkoLinkText": "Tilgå ældre rapporter"
				},
				"nko-settlements": {
					"title": "Afregninger (KO)",
					"description": "Find rapporter om bestillinger og udbetalinger fra Klarna Online."
				},
				"logs-app-fe": {
					"title": "Udviklerlogfiler",
					"description": "Søg i API-logge for at fejlbehandle integrationsproblemer."
				},
				"users-fe": {
					"title": "Brugermanager",
					"description": "Inviter nye brugere, og administrer eksisterende brugere i portalen."
				},
				"settings-fe": {
					"title": "Betalingsindstillinger",
					"description": ""
				},
				"nko-settings": {
					"title": "Indstillinger (KO)",
					"description": "Administrer dine butiksindstillinger, rapporter og kontaktoplysninger."
				},
				"seller-fe": {
					"title": "I butikken",
					"description": "Opret nye ordrer med Klarnas løsning i butikken."
				},
				"products-fe": {
					"title": "Onboarding",
					"description": "Konfigurer din konto for at aktivere betalinger."
				},
				"upstream-fe": {
					"title": "On-site messaging",
					"description": "Sælg mere med tilpassede og personrettede finansieringstilbud."
				},
				"lending-fe": {
					"title": "Business Financing",
					"description": "Vi har gjort virksomhedsfinansiering så enkelt så muligt."
				},
				"branding-fe": {
					"title": "Brandmanager",
					"description": "Tag kontrol over, hvordan dit brand præsenteres med Klarna."
				},
				"disputes-fe": {
					"title": "Tvister",
					"description": "Håndter dine tvister."
				},
				"logistics-fe": {
					"title": "Forsendelsesintegrationer",
					"description": "Administrer al din logistik på ét sted."
				},
				"developers": {
					"title": "Udviklere"
				},
				"klarna-docs": {
					"title": "Klarna Docs"
				},
				"support": {
					"title": "Support",
					"description": "Tjek og administrer dine supportanmodninger."
				},
				"integration-guides-fe": {
					"title": "Integrationsvejledninger",
					"description": ""
				},
				"klarna-search-b2b-portal": {
					"title": "Search & Compare",
					"description": "Kom godt i gang med Klarnas kraftfulde søgemaskine og prissammenligning. Få adgang til statistik og værktøjer, der er beregnet til at forbedre din synlighed."
				},
				"siwk-fe": {
					"title": "Log ind med Klarna",
					"description": "Administrér dit login med Klarna-integration."
				},
				"kec-fe": {
					"title": "Express-betaling",
					"description": "Administrer din integration af Express-betaling."
				},
				"partner-self-service": {
					"title": "Butiksleder",
					"description": "Administrer din Klarna-butik.",
					"nkoLinkText": "Se og administrer indstillinger for din butik"
				}
			},
			"ui": {
				"tabs": {
					"home": "Betalinger",
					"growth": "Vækstmarkedsføring",
					"boosters": "Konverteringsforstærkere",
					"badge": "Nyhed"
				},
				"buttons": {
					"backButton": "Tilbage"
				}
			},
			"errorBoundary": {
				"contact": "Prøv at genindlæse siden. {br}Hvis fejlen vedvarer, så kontakt <supportLink>forhandlersupport</supportLink> og oplys ID'et {errorId}.",
				"errorDescription": "Fejl-ID - {errorId}",
				"reloadButton": "Genindlæs",
				"title": "Der er desværre opstået en fejl"
			},
			"maintenance": {
				"pageTitle": "Vedligeholdelse",
				"title": "Butiksportalen er i øjeblikket under planlagt vedligeholdelse",
				"summary": "Vi vender snart tilbage",
				"scheduledSummary": "Vi vender tilbage {until}"
			},
			"errorTemplates": {
				"errorDescription": "Fejl: {code}",
				"backButton": "Tilbage",
				"reloadButton": "Genindlæs",
				"default": {
					"errorDescription": "Fejl-ID - {errorId}",
					"title": "Der er desværre opstået en fejl",
					"contact": "Prøv at genindlæse siden. Hvis fejlen vedvarer, så kontakt <supportLink>forhandlersupport</supportLink> og oplys ID'et {errorId}."
				},
				"internalServerError": {
					"title": "Hov! Noget gik galt",
					"message": "Af en eller anden grund på vores side kunne vi ikke udføre din anmodning. Du kan prøve igen eller vende tilbage senere."
				},
				"notFound": {
					"title": "Der er intet her",
					"message": "Siden eksisterer ikke, eller du har skrevet en forkert adresse i din netlæser. Tjek adressen, og prøv igen."
				},
				"forbidden": {
					"title": "Du har ikke adgang til denne side",
					"message": "Dit forhandler-ID har ikke adgang til denne side. Hvis du tror, dette er en fejl, bedes du kontakte vores forhandlersupport eller administratoren af din virksomheds konto."
				}
			},
			"codeSnippet": {
				"clipboardButtonLabel": "Kopiér til udklipsholder",
				"clipboardButtonLabelSuccess": "Kopieret!"
			}
		},
		"blackFriday": {
			"v1": {
				"title": "Black friday",
				"intro": "En af de vigtigste salgsdage nærmer sig. I løbet af Black Friday vil vi være her for at levere nærtidsstatistik for dine salg. Lad os i mellemtiden berede os på succes.",
				"countdown": "Begynder om #dt #hh #mm #ss…",
				"box1": {
					"title": "3,5 gange højere forbrug*",
					"text": "Sidste år brugte Klarna-kunder* gennemsnitligt 3,5 gange mere på Black Friday end de gjorde på nogen anden dag i løbet af året.",
					"footnote": "*Gennemsnit på tværs af alle Klarna-forhandlerkategorier og regioner."
				},
				"box2": {
					"title": "2,5 gange højere salgsmængde*",
					"text": "Sidste år indbragte Klarna-forhandlere en 2,5 gange højere salgsmængde på Black Friday end på nogen anden dag i året.",
					"footnote": "*Gennemsnit på tværs af alle Klarna-forhandlerkategorier og regioner."
				},
				"sectionTitle": "Lad os øge din omsætning for Black Friday",
				"marketing": {
					"title": "Markedsfør din forretning før og på Black Friday",
					"text": "Få flere handlende i din forretning i løbet af Black Friday-sæsonen med disse gratis ressourcer til sociale medier og e-mailkampagner."
				},
				"branding": {
					"title": "Bliv set i Klarna-appen",
					"text": "12 millioner handlende bruger Klarna-appen hver måned. Sørg for, at du brillerer i appen denne Black Friday ved at tilføje og opdatere dine mærkeoplysninger her i forhandlerportalen."
				},
				"cta": "Kom i gang",
				"close": "Luk"
			},
			"v2": {
				"title": "Black Friday week",
				"orderVolume": "Ordremængde",
				"orderCountTitle": "Oprettede ordrer",
				"orderCount": "{count} ordrer",
				"box": {
					"title": "Ønsker du at øge din omsætning yderligere?",
					"subtitle1": "On-site messaging",
					"subtitle2": "Reklamemeddelelser der informerer.",
					"text": "Vores On-site messaging-værktøj hjælper med at øge dit salg og din gennemsnitlige ordreværdi med reklamemeddelelser. Fortæl dine kunder, hvad deres betalingsmuligheder er, så snart de ankommer på din side og hele vejen gennem deres købsoplevelse.",
					"cta": "Kom i gang"
				},
				"selector": {
					"done": "Udført",
					"applySelection": "Påfør # valg",
					"selectAll": "Vælg alle",
					"allOptions": "Alle muligheder valgt",
					"someOptions": "# muligheder valgt",
					"storeMultiSelectLabel": "Vælg # butikker",
					"allStores": "Alle butikker"
				},
				"currencyLabel": "Vælg valuta",
				"storeLabel": "Vælg butik"
			},
			"v3": {
				"title": "Black Friday week",
				"text": "Dette er dit salg fra en af årets vigtigste salgsuge.",
				"summaryTitle": "Sammenfatning",
				"orderVolume": "Samlet mængde",
				"orderCount": "Ordrer i alt",
				"summaryRange": "23-30 November",
				"comparisonLastWeek": "sammenlignet med samme dag i sidste uge",
				"tabs": {
					"monday": "Mandag",
					"tuesday": "Tirsdag",
					"wednesday": "Onsdag",
					"thursday": "Torsdag",
					"blackFriday": "Black Friday",
					"saturday": "Lørdag",
					"sunday": "Søndag",
					"cyberMonday": "Cyber Monday"
				}
			}
		},
		"mpSatSurvey": {
			"marble": {
				"topbarButtonLabel": "Feedback på dit nye look",
				"step1": {
					"welcome": "Kan du lide dit nye look, og hvordan det føles?",
					"satisfied": "Meget tilfreds",
					"unsatisfied": "Meget utilfreds"
				},
				"step2": {
					"textAreaLabel": "Andet (din holdning)",
					"textAreaMaxLength": "Makslængde nået",
					"submit": "Indsend",
					"content": {
						"titlePositive": "Hvad kunne du bedst lide?",
						"titleNegative": "Hvad kunne du mindst lide?",
						"tags": {
							"color": "Farve og stil",
							"navigation": "Navigation",
							"names": "Navn på sider",
							"structure": "Opbygning af sider"
						}
					}
				},
				"step3": {
					"title": "Tak!",
					"description": "Vi sætter pris på din feedback."
				}
			},
			"buttonLabel": "Giv Feedback",
			"errorMessage": "Der skete en fejl, da din feedback skulle behandles.",
			"retry": "Prøv igen",
			"step1": {
				"welcome1": "Hvor glad er du for",
				"welcome2": "Merchant Portal?",
				"emojiSelector": {
					"angry": "Meget utilfreds",
					"unhappy": "Lidt utilfreds",
					"neutral": "Neutral",
					"happy": "Tilfreds",
					"love": "Jeg er vild med den"
				},
				"remindMeText": "Ønsker ikke at dele min holdning",
				"remindMeLink": "Mind mig om undersøgelsen på et andet tidspunkt.",
				"dontShowAgainLink": "Vis ikke dette igen"
			},
			"step2": {
				"textAreaLabel": "Andet (din holdning)",
				"textAreaMaxLength": "Makslængde nået",
				"submit": "Indsend",
				"content": {
					"negative": {
						"title": "Det beklager vi.",
						"description": "Hvilken del a Partner portalen bør vi forbedre? (Vælg alle, der er gældende)",
						"tags": {
							"loading": "Indlæsningstid",
							"menu": "Menuorganisering",
							"mobile": "Mobilversion",
							"filter": "Filtering af data",
							"complexity": "Handle effektivt"
						}
					},
					"positive": {
						"title": "Det er gode nyheder!",
						"description": "Vi vil gerne forbedre os. Hvad kunne gøre din oplevelse med Merchant Portal endnu bedre.",
						"tags": {
							"views": "Skræddersyede visninger",
							"looks": "Udseende & Fornemmelse",
							"tours": "Guidede ture",
							"shortcuts": "Genveje til opgaver"
						}
					}
				}
			},
			"step3": {
				"title": "Tak! Lige én ting mere.",
				"content1": "Vi vil gerne invitere dig til et interview, så vi kan lære mere om din oplevelse med Merchant Portal.",
				"content2": "Vil du gerne deltage?",
				"content3": "Hvis du er enig, kontakter vi dig via din registrerede e-mail for at planlægge det.",
				"yes": "Ja, tak",
				"no": "Nej, tak"
			},
			"step4": {
				"yesTitle": "Godt! Vi tales ved.",
				"noTitle": "Forstået. Tak!",
				"content1": "Tak, fordi du tager dig tid til at besvare disse spørgsmål.",
				"content2": "Du kan nu lukke popup-vinduet."
			}
		},
		"boost": {
			"integrationTypeModal": {
				"title": "Hvordan ønsker du at opsætte {productName}?",
				"labels": {
					"plugin": "Opsæt med en programtilføjelse",
					"web": "Opsæt med Web SDK"
				}
			}
		}
	}
};