module.exports = {
	"en": {
		"core": {
			"alert": {
				"message": "Something went wrong! Please try again.",
				"correlationIdLabel": "Correlation ID:"
			},
			"header": {
				"title": "Merchant Portal"
			},
			"usermenu": {
				"language": "Language",
				"country": "Country",
				"timezone": "Timezone",
				"logout": "Logout",
				"profile": "Profile",
				"changeProfile": "Edit",
				"tooltip": "Your profile"
			},
			"appsmenu": {
				"defaultTooltip": "Your apps",
				"initialTooltip": "Find your apps here.",
				"newAppsTooltip": "We have new apps for you!"
			},
			"two-factor-opt-in": {
				"title": "Set up two-factor authentication",
				"intro-paragraph": "You do not have two-factor authentication (2FA) enabled on your Merchant Portal account. Starting <b>16th January, 2023</b>, 2FA will be mandatory for all users to be able to access Merchant Portal. You can read more about the importance of 2FA, how to set it up and answers to common questions <faqLink>here</faqLink>.",
				"help-link-text": "Click here",
				"help-text": " to learn more about how to set it up and how it protects you.",
				"warning-text": "Make sure you have your smartphone and access to the internet before you proceed",
				"continue-button": "Start the setup"
			},
			"kuasPrompter": {
				"title": "You've selected",
				"content": "{count} MIDs",
				"contentTooltip": "Click to copy MIDs to clipboard",
				"button": "Change selected MIDs"
			},
			"notification": {
				"title": "Announcement",
				"content": {
					"uptodate": "You're up to date!"
				}
			},
			"languages": {
				"cs": "Czech",
				"da": "Danish",
				"de": "German",
				"el": "Greek",
				"en": "English",
				"en_GB": "English (GB)",
				"en_US": "English (US)",
				"es": "Spanish",
				"fi": "Finnish",
				"fr": "French",
				"it": "Italian",
				"nl": "Dutch",
				"no": "Norwegian",
				"pl": "Polish",
				"pt": "Portuguese",
				"sv": "Swedish"
			},
			"countries": {
				"at": "Austria",
				"au": "Australia",
				"be": "Belgium",
				"ca": "Canada",
				"ch": "Switzerland",
				"cz": "Czechia",
				"de": "Germany",
				"dk": "Denmark",
				"ee": "Estonia",
				"es": "Spain",
				"fi": "Finland",
				"fr": "France",
				"gb": "Great Britain",
				"gr": "Greece",
				"ie": "Ireland",
				"it": "Italy",
				"mx": "Mexico",
				"nl": "Netherlands",
				"no": "Norway",
				"se": "Sweden",
				"us": "United States",
				"nz": "New Zealand",
				"pl": "Poland",
				"pt": "Portugal",
				"ro": "Romania"
			},
			"selectLanguage": {
				"title": "Select your language",
				"paragraph": "This will be your default language.",
				"submit": "OK"
			},
			"selectCountry": {
				"title": "Select your country",
				"paragraph": "This will be your default country.",
				"submit": "OK"
			},
			"updateProfile": {
				"title": "Update Profile",
				"submit": "OK",
				"emailLabel": "E-mail address",
				"firstNameLabel": "First name",
				"lastNameLabel": "Last name",
				"resetPasswordLabel": "Password",
				"resetPasswordSelectedValue": "Reset",
				"resetPasswordSuccessLabel": "Reset Email sent successfully.",
				"resetPasswordErrorLabel": "Error sending Email. Please try again later.",
				"error": "Details update failed",
				"changeCookiePolicyLabel": "Cookie Policy",
				"changeCookiePolicySelectedValue": "Change",
				"securitySectionTitle": "Security",
				"preferencesSectionTitle": "Preferences",
				"mfaLabel": "Two-factor authentication",
				"mfaDescription": "Requires logging in with a time-based one time password. We recommend enabling that.",
				"twoFactor": {
					"reset": "Change your 2FA",
					"isEnabled": "Activated",
					"isDisabled": "Deactivated",
					"title": "Two-factor authentication (2FA)",
					"description": "This lets users login with the added security of a time-based one time password.",
					"tooltip": "Make sure you have at least the permission {permission} for the {app} app for every store the user has access to in order to toggle the two-factor authentication setting.",
					"confirmationDialog": {
						"closeButton": "Close",
						"confirmationButton": "Yes, continue",
						"cancelButton": "Cancel",
						"resetSuccess": {
							"title": "2FA device reset successful.",
							"description": "The next time the user logs in, they will need to set up a new device for 2FA."
						},
						"resetConfirmation": {
							"title": "Are you sure you want to reset this user's 2FA device?",
							"description": "By continuing, you will reset the device used to generate 2FA codes for this user. The next time the user logs in, they will need to set up a new device for 2FA."
						}
					}
				}
			},
			"footer": {
				"copyright": {
					"eu": "Copyright © 2005-{currentYear} Klarna Bank AB (publ). All rights reserved",
					"oc": "Copyright © 2005-{currentYear} Klarna Australia Pty Ltd, ABN # 82 635 912 579",
					"ap": "Copyright © 2005-{currentYear} Klarna Australia Pty Ltd, ABN # 82 635 912 579",
					"us": "Copyright © 2005-{currentYear} Klarna Inc. NMLS #1353190, 629 N. High Street, Third Floor, Columbus, OH 43215"
				},
				"terms": "Terms & Conditions"
			},
			"accessDenied": {
				"title": "You need additional access for this page.",
				"message": "Contact your administrator or Klarna with any questions."
			},
			"midSelector": {
				"label": "Store"
			},
			"storeSelector": {
				"label": "Store"
			},
			"storesSelector": {
				"closeText": "Done",
				"closeTextWithSelection": "Apply # selection(s)",
				"deselectAllOptionText": "Clear selection",
				"emptyStateText": "No stores found",
				"label": "Stores",
				"searchFieldLabel": "Search by store",
				"selectAllOptionText": "Select all stores",
				"selectedAllText": "All stores",
				"selectedText": "# stores"
			},
			"pagination": {
				"ofLabel": "of"
			},
			"singleDatePicker": {
				"selectDate": "Select date"
			},
			"dateRangePicker": {
				"startLabel": "Start Date",
				"endLabel": "End Date",
				"months": {
					"january": "January",
					"february": "February",
					"march": "March",
					"april": "April",
					"may": "May",
					"june": "June",
					"july": "July",
					"august": "August",
					"september": "September",
					"october": "October",
					"november": "November",
					"december": "December"
				},
				"weekDaysShort": {
					"monday": "M",
					"tuesday": "T",
					"wednesday": "W",
					"thursday": "T",
					"friday": "F",
					"saturday": "S",
					"sunday": "S"
				}
			},
			"regionSelector": {
				"noStores": "No stores assigned",
				"andMore": "and more",
				"regions": {
					"eu": "Europe",
					"us": "North America",
					"ap": "Asia Pacific"
				}
			},
			"regionSelectorBar": {
				"modal": {
					"title": "Change Region",
					"description": "Please select a region with associated stores to display relevant data.",
					"buttonText": "Confirm"
				},
				"selectedRegion": "Selected region",
				"changeButton": "Change"
			},
			"appIcon": {
				"newLabel": "NEW"
			},
			"appCategory": {
				"dayToDay": "For your day-to-day",
				"nko": "Klarna Online",
				"tools": "Tools",
				"preferences": "Preferences"
			},
			"apps": {
				"account-information-fe": {
					"title": "Account information",
					"description": "Find your business and bank details and the agreements you signed."
				},
				"home-fe": {
					"title": "Payments home"
				},
				"boosters-home-fe": {
					"title": "Get started"
				},
				"marketing-home-fe": {
					"title": "Marketing home"
				},
				"nko-app": {
					"title": "Klarna online apps"
				},
				"orders-fe": {
					"title": "Order manager",
					"description": "Search and manage your orders from Klarna.",
					"nkoLinkText": "Access older orders"
				},
				"nko-orders": {
					"title": "Orders (KO)",
					"description": "Search and manage your orders made through Klarna Online."
				},
				"settlements-fe": {
					"title": "Settlements",
					"description": "Get reports tailored to your business needs.",
					"nkoLinkText": "Access older reports"
				},
				"nko-settlements": {
					"title": "Settlements (KO)",
					"description": "Find reports on orders and payouts from Klarna Online."
				},
				"logs-app-fe": {
					"title": "Developer logs",
					"description": "Search API logs to troubleshoot integration problems."
				},
				"users-fe": {
					"title": "User manager",
					"description": "Invite new users and manage existing users in the Portal."
				},
				"settings-fe": {
					"title": "Payment settings",
					"description": "Request your Klarna API keys and manage payment settings."
				},
				"nko-settings": {
					"title": "Settings (KO)",
					"description": "Manage your store settings, reports and contact details."
				},
				"seller-fe": {
					"title": "In-store",
					"description": "Create new orders with the Klarna in-store solution."
				},
				"products-fe": {
					"title": "Onboarding",
					"description": "Set up your account to enable payments."
				},
				"upstream-fe": {
					"title": "On-site messaging",
					"description": "Sell more with tailored and personalized financing offers."
				},
				"lending-fe": {
					"title": "Business Financing",
					"description": "Business financing, made simple."
				},
				"branding-fe": {
					"title": "Brand manager",
					"description": "Take control over how your brand is presented within Klarna."
				},
				"disputes-fe": {
					"title": "Disputes",
					"description": "Handle your disputes."
				},
				"logistics-fe": {
					"title": "Shipping integrations",
					"description": "Manage all your logistics in one place."
				},
				"developers": {
					"title": "Developers"
				},
				"klarna-docs": {
					"title": "Klarna Docs"
				},
				"support": {
					"title": "Support",
					"description": "Check and manage your support requests."
				},
				"integration-guides-fe": {
					"title": "Integration guides",
					"description": ""
				},
				"klarna-search-b2b-portal": {
					"title": "Search & Compare",
					"description": "Get started with Klarna’s powerful search engine and comparison shopping. Gain access to statistics and tools designed to enhance your visibility."
				},
				"siwk-fe": {
					"title": "Sign in with Klarna",
					"description": "Manage your Sign In With Klarna integration."
				},
				"kec-fe": {
					"title": "Express Checkout",
					"description": "Manage your Express Checkout integration."
				},
				"partner-self-service": {
					"title": "Store manager",
					"description": "Manage your Klarna Store.",
					"nkoLinkText": "View and manage settings for your store"
				}
			},
			"ui": {
				"tabs": {
					"home": "Payments",
					"growth": "Growth marketing",
					"boosters": "Conversion boosters",
					"badge": "New"
				},
				"buttons": {
					"backButton": "Back"
				}
			},
			"errorBoundary": {
				"contact": "Please try refreshing the page. {br}If the error persists, contact <supportLink>Merchant Support</supportLink> providing the id {errorId}.",
				"errorDescription": "Error ID - {errorId}",
				"reloadButton": "Refresh",
				"title": "Unfortunately there's been an error"
			},
			"maintenance": {
				"pageTitle": "Maintenance",
				"title": "The Merchant Portal is currently undergoing scheduled maintenance",
				"summary": "We’ll be back soon",
				"scheduledSummary": "We’ll be back at {until}"
			},
			"errorTemplates": {
				"errorDescription": "Error: {code}",
				"backButton": "Back",
				"reloadButton": "Refresh",
				"default": {
					"errorDescription": "Error ID - {errorId}",
					"title": "Unfortunately there's been an error",
					"contact": "Please try refreshing the page. If the error persists, contact <supportLink>Merchant Support</supportLink> providing the error id {errorId}."
				},
				"internalServerError": {
					"title": "Oops, something went wrong",
					"message": "For some reason on our side, we weren't able to run you request. You can try again or come back later."
				},
				"notFound": {
					"title": "There is nothing here!",
					"message": "This page doesn't exist or you typed a wrong address on your browser. Check the address and try again."
				},
				"forbidden": {
					"title": "You don't have access to this page",
					"message": "Your Merchant ID does not have access to this page. If you believe this is an error, please reach out to Merchant Support or contact the administrator of your company's account."
				}
			},
			"codeSnippet": {
				"clipboardButtonLabel": "Copy to clipboard",
				"clipboardButtonLabelSuccess": "Copied!"
			}
		},
		"blackFriday": {
			"v1": {
				"title": "Black Friday",
				"intro": "One of the most important retail days is almost upon us. During Black Friday, we’ll be here to deliver near-time statistics of your sales. In the meantime, let’s prepare for success!",
				"countdown": "Starting in #dd #hh #mm #ss…",
				"box1": {
					"title": "3.5x more spending*",
					"text": "Last year, Klarna shoppers* spent, on average, 3.5x more on Black Friday than they did on any other day of the year.",
					"footnote": "*Average across all Klarna merchant categories and regions."
				},
				"box2": {
					"title": "2.5x sales volume*",
					"text": "Last year, Klarna merchants generated 2.5x more sales volume on Black Friday than any other day of the year.",
					"footnote": "*Average across all Klarna merchant categories and regions."
				},
				"sectionTitle": "Let’s grow your Black Friday sales",
				"marketing": {
					"title": "Market your business before and on Black Friday",
					"text": "Drive more shoppers to your store during the Black Friday shopping season with these free-to-use resources for social media and email campaigns."
				},
				"branding": {
					"title": "Get seen in the Klarna app",
					"text": "12 million shoppers use the Klarna app every month. Make sure you shine in the app this Black Friday by adding and updating your brand information here in the Merchant Portal."
				},
				"cta": "Get started",
				"close": "Close"
			},
			"v2": {
				"title": "Black week",
				"orderVolume": "Sales volume",
				"orderCountTitle": "Number of orders",
				"orderCount": "{count} orders",
				"box": {
					"title": "Want to increase conversion further?",
					"subtitle1": "On-site Messaging",
					"subtitle2": "Promotional messaging that educates.",
					"text": "Our On-site Messaging tool helps boost your sales and average order values with promotional messaging. Let shoppers know what their payment options are as soon as they come to your site, and throughout their shopping journey.",
					"cta": "Get started"
				},
				"selector": {
					"done": "Done",
					"applySelection": "Apply # selection(s)",
					"selectAll": "Select all",
					"allOptions": "All options selected",
					"someOptions": "# options selected",
					"storeMultiSelectLabel": "Choose # stores",
					"allStores": "All stores"
				},
				"currencyLabel": "Choose currency",
				"storeLabel": "Choose store"
			},
			"v3": {
				"title": "Black Friday week",
				"text": "These are your sales from one of the most important sales week of the year.",
				"summaryTitle": "Summary",
				"orderVolume": "Total volume",
				"orderCount": "Total orders",
				"summaryRange": "23-30 November",
				"comparisonLastWeek": "compared to the same day, last week",
				"tabs": {
					"monday": "Monday",
					"tuesday": "Tuesday",
					"wednesday": "Wednesday",
					"thursday": "Thursday",
					"blackFriday": "Black Friday",
					"saturday": "Saturday",
					"sunday": "Sunday",
					"cyberMonday": "Cyber Monday"
				}
			}
		},
		"mpSatSurvey": {
			"marble": {
				"topbarButtonLabel": "Feedback on our new look",
				"step1": {
					"welcome": "How satisfied are you with the new look and feel?",
					"satisfied": "Very satisfied",
					"unsatisfied": "Very unsatisfied"
				},
				"step2": {
					"textAreaLabel": "Your opinion (optional)",
					"textAreaMaxLength": "Maximum length reached",
					"submit": "Submit",
					"content": {
						"titlePositive": "What do you like the most?",
						"titleNegative": "What do you dislike the most?",
						"tags": {
							"color": "Colour and style",
							"navigation": "Navigation",
							"names": "Name of pages",
							"structure": "Structure of pages"
						}
					}
				},
				"step3": {
					"title": "Thank you!",
					"description": "We appreciate your feedback."
				}
			},
			"buttonLabel": "Give Feedback",
			"errorMessage": "There was an error sending your feedback through.",
			"retry": "Retry",
			"step1": {
				"welcome1": "How happy are you with the",
				"welcome2": "Merchant Portal?",
				"emojiSelector": {
					"angry": "Very unhappy",
					"unhappy": "Slightly unhappy",
					"neutral": "Neutral",
					"happy": "Happy",
					"love": "I love it"
				},
				"remindMeText": "Don't want to share your opinion?",
				"remindMeLink": "Remind me later about this survey.",
				"dontShowAgainLink": "Don't show this again."
			},
			"step2": {
				"textAreaLabel": "Other (your opinion)",
				"textAreaMaxLength": "Maximum length reached",
				"submit": "Submit",
				"content": {
					"negative": {
						"title": "Sorry to hear that.",
						"description": "Which aspects of the Merchant Portal should we improve? (Select all that apply)",
						"tags": {
							"loading": "Loading time",
							"menu": "Menu organisation",
							"mobile": "Mobile version",
							"filter": "Filtering data",
							"complexity": "Doing actions efficiently"
						}
					},
					"positive": {
						"title": "That's good news!",
						"description": "We still want to do better. What are the things that would make your experience of the Merchant Portal even better",
						"tags": {
							"views": "Customised views",
							"looks": "Look & Feel",
							"tours": "Guided tours",
							"shortcuts": "Shortcuts for tasks"
						}
					}
				}
			},
			"step3": {
				"title": "Thank you! One last thing.",
				"content1": "We would like to invite you to an interview so that we can learn more about your experience using our Merchant Portal.",
				"content2": "Would you be happy to take part?",
				"content3": "If you agree, we will contact you via your registered e-mail to set this up.",
				"yes": "Yes, please",
				"no": "No, thanks"
			},
			"step4": {
				"yesTitle": "Great! We'll be in touch.",
				"noTitle": "Got it. Thank you!",
				"content1": "Thank you for taking the time to answer these questions.",
				"content2": "You can close this popup now."
			}
		},
		"boost": {
			"integrationTypeModal": {
				"title": "How do you want to set up {productName}?",
				"labels": {
					"plugin": "Set up with a plugin",
					"web": "Set up with Web SDK"
				}
			}
		}
	}
};