module.exports = {
	"es": {
		"help-tool": {
			"button-text": "Ayuda",
			"support-button": "Obtener ayuda",
			"support-page": {
				"create-ticket": {
					"title": "Obtener ayuda",
					"instructions": "Envía una petición a continuación y te contactaremos en un plazo de 1 día hábil.",
					"related-content": {
						"title": "Esto podría solucionar tu problema"
					},
					"fields": {
						"mid": {
							"label": "Seleccionar tienda",
							"validation": "La tienda es necesaria"
						},
						"phoneNumber": {
							"label": "Número de teléfono (opcional)"
						},
						"topic": {
							"question": "¿Cuál es el tema de tu pregunta?",
							"label": "Selecciona un tema",
							"validation": "El tema es necesario",
							"options": {
								"orders": "Pedidos",
								"settlements": "Liquidaciones",
								"agreements": "Acuerdos",
								"others": "Otros"
							}
						},
						"subject": {
							"label": "Asunto",
							"validation": "El asunto es necesario"
						},
						"description": {
							"label": "Mensaje",
							"validation": "La descripción es necesaria"
						},
						"attachment": {
							"label": "Añadir archivos adjuntos",
							"button": "Cargar",
							"info": "Si añades capturas podremos resolver el problema más rápido.",
							"supportedFileTypes": "Formatos admitidos: .png, .jpg, .pdf",
							"error": "El tamaño máximo del total de archivos es de {maxSize} MB"
						}
					},
					"submit": "Enviar petición",
					"service-unavailable-error": "¡Vaya! Se produjo un error, inténtalo de nuevo en unos minutos. Si el problema continúa, {link} y te ayudaremos.",
					"generic-error": "No se pudo procesar tu petición. No hay comunicación entre nuestros sistemas en este momento. Vuelve a cargar la página o inténtalo más tarde. Si el problema continúa, {link} y te ayudaremos.",
					"contact-link": "contáctanos"
				},
				"view-ticket": {
					"title": "¡Ya está!",
					"details": "Hemos recibido tu petición y te responderemos en un plazo de 1 día hábil. Estos son los detalles:",
					"ticket": "N.º de petición {ticketId}",
					"email": "Email: {email}",
					"status": "Puedes ver el estado en nuestra página de ayuda.",
					"track": "Seguimiento del estado"
				}
			},
			"header": {
				"homeLink": "Restablecer"
			},
			"home": {
				"greeting": "Hola, {givenName}: ¿Cómo podemos ayudarte?",
				"topics": "¿Con qué necesitas ayuda?"
			},
			"topics": {
				"expandButton": "Más"
			},
			"feedback": {
				"title": "¿Te ha resultado útil?",
				"optionYes": "Sí, ha sido útil",
				"optionNo": "No, necesito más ayuda",
				"positiveFeedback": "Gracias por tus comentarios positivos, nos ayudan a ofrecerte artículos más útiles en el futuro."
			},
			"freeTextFeedback": {
				"title": "Ayúdanos a mejorar",
				"placeholder": "¿Por qué no te ha resultado útil este contenido?",
				"submitButton": "Enviar",
				"feedbackMessage": "Gracias por tus comentarios, nos ayudan a ofrecerte artículos más útiles en el futuro."
			},
			"relatedContent": {
				"title": "Contenido relacionado"
			},
			"search": {
				"buttonLabel": "Buscar",
				"input": "Buscar artículos...",
				"loading": "Cargando...",
				"results": {
					"contact": "¿No encuentras lo que buscas?",
					"empty": "Lo sentimos, no hemos encontrado ningún resultado para tu búsqueda. Intenta buscar otro término.",
					"singleTopicFound": "Hemos encontrado 1 entrada",
					"multipleTopicsFound": "Hemos encontrado {count} entradas",
					"singleArticleFound": "Hemos encontrado 1 artículo",
					"multipleArticlesFound": "Hemos encontrado {count} artículos"
				}
			},
			"contact": {
				"title": "Contactar",
				"channelsIntro": "Ponte en contacto con nosotros para que te echemos una mano.",
				"businessDetailsTitle": "Los datos de tu empresa",
				"businessDetailsName": "Nombre de la empresa",
				"businessDetailsEmail": "Correo electrónico",
				"businessDetailsMid": "ID del comercio",
				"phone": {
					"title": "Llámanos",
					"paragraph": "Puedes llamarnos durante nuestro horario comercial entre semana. Si estás en Suecia o Reino Unido, también puedes llamarnos los fines de semana entre las 10:00 h y las 16:00 h."
				},
				"email": {
					"title": "Envíanos un e-mail",
					"paragraph": "Mándanos un e-mail y te responderemos lo antes posible."
				}
			},
			"errorBoundary": {
				"title": "Se ha producido un error",
				"message": "Lo sentimos, se produjo un error. Haz clic en el botón \"Atrás\" siguiente para regresar a la ayuda o en el botón \"Pedir ayuda\" para contactar con nosotros.",
				"resetButton": "Atrás",
				"errorId": "ID del error: {errorId}"
			},
			"tour": {
				"prev": "Anterior",
				"next": "Siguiente",
				"done": "Hecho"
			},
			"notAvailableMobile": {
				"title": "No disponible",
				"buttonBack": "Atrás",
				"paragraph": "Este contenido no está disponible en tu dispositivo. Intenta abrirlo en un dispositivo con una pantalla más grande, como un ordenador de sobremesa."
			},
			"shareButton": {
				"title": {
					"article": "Compartir un enlace en profundidad con este artículo",
					"topic": "Compartir un enlace en profundidad con este tema",
					"tour": "Compartir un enlace en profundidad con esta visita"
				},
				"radioItemHome": "Inicio de la app",
				"descriptionHome": "Abre el artículo de ayuda en la página de inicio del portal del comercio",
				"radioItemCurrent": "Página actual",
				"descriptionCurrent": "Abre el artículo de la ayuda en la página actual"
			},
			"marketSelector": {
				"whichMarketToContact": "¿Con qué mercado te gustaría ponerte en contacto?"
			}
		},
		"orientation": {
			"title": "Aprende a..."
		},
		"helpbot": {
			"questions": {
				"whatDoYouNeedHelpWith": "Hola: ¿Con qué necesitas ayuda hoy?",
				"selectSupportChannel": "Selecciona un canal para ponerte en contacto con nuestro servicio de soporte.",
				"thatHelped": "Gracias por tus comentarios positivos, nos ayudan a ofrecerte ayuda más relevante en el futuro. 😀"
			},
			"orders": {
				"orderManagement": {
					"title": "Administración de pedidos",
					"createOrderPos": {
						"question": "¿Cómo creo un pedido a través de Point of Sale?",
						"answer": "1. Abre Point of Sale. 2. Introduce el nombre del artículo, el precio del producto y la cantidad. 3. Comparte el enlace de pago generado con el cliente por SMS o email, o pide al cliente que escanee el código QR que aparecerá en la pantalla de tu caja. 4. El cliente ya puede terminar la compra desde su dispositivo."
					},
					"extendExpiryReservation": {
						"question": "¿Cómo amplío la fecha de caducidad de una reserva?",
						"answer": "1. Ve a la sección de administración de pedidos y selecciona el o los pedidos para los que quieres ampliar la fecha de caducidad. 2. Haz clic en Ampliar la fecha de caducidad. 3. Confirma tu selección.\nLos pedidos se amplían según la configuración de los plazos de reserva de tu tienda (normalmente 28 días). Si el pedido ha caducado, ya no se puede ampliar.\""
					},
					"howToCaptureOrders": {
						"question": "¿Cómo capturo pedidos?",
						"partially": {
							"question": "Quiero capturar parcialmente un pedido",
							"answer": "Si solo se puede enviar una parte del pedido de un cliente, puedes capturarlo parcialmente por línea de pedido o importe. Capturar por línea de pedido: 1. Ve a la sección de administración de pedidos y selecciona los productos para los que actualmente tienes existencias desde la página del pedido. Recuerda modificar las líneas del pedido para ajustar las cantidades si es necesario. 2. Haz clic en Capturar. Capturar por importe: 1. Ve al pedido en la sección de administración de pedidos. Puedes capturar un importe haciendo clic en el botón \"Capturar todo\". Aquí tendrás que introducir el importe deseado. 2. Haz clic en Capturar.\nRecuerda que debes terminar el pedido cuando sea posible, ya sea capturando el importe/los productos restantes una vez estén disponibles para su envío o cancelando el pedido restante si no podrás enviar los artículos."
						},
						"fully": {
							"question": "Quiero capturar totalmente los pedidos",
							"answer": "1. Ve a la sección de administración de pedidos y haz clic en el número de referencia de Klarna para abrir el pedido. 2. Captura todo el pedido mediante uno de estos pasos: - Haz clic en el botón \"Capturar\". - Selecciona las líneas de pedido y haz clic en \"Capturar\". - Haz clic en \"Capturar todo\" e introduce el importe total del pedido. 3. Cuando captures el pedido, puedes descargar el recibo de embalaje e incluirlo en tu envío.\nAsegúrate de capturar los pedidos antes de la fecha de caducidad (normalmente, 28 días desde la creación del pedido). Si quieres acelerar el procedimiento, puedes capturar varios pedidos seleccionándolos en la lista de pedidos y haciendo clic en \"Captura completa\"."
						}
					},
					"canIUpdateCustomerInfo": {
						"question": "¿Puedo actualizar la información del cliente?",
						"answer": "No es posible cambiar el destinatario de un pedido, pero sí que se puede cambiar la dirección de envío y facturación para pedidos sin capturar o parcialmente capturados. Si Klarna rechaza la nueva dirección porque no está vinculada con el cliente, la dirección de facturación puede cambiarse igualmente."
					},
					"extendDueDate": {
						"question": "¿Cómo puedo ampliar la fecha de vencimiento de un pedido?",
						"answer": "Puedes ampliar la fecha de vencimiento de un pedido haciendo clic en \"Pospón la fecha de vencimiento\" en un pedido capturado. Ampliar la fecha de vencimiento de un pedido conlleva una comisión que se especificará antes de completar la acción."
					},
					"refundOrder": {
						"question": "¿Cómo puedo reembolsar un pedido?",
						"answer": "1. Ve a la sección de administración de pedidos y encuentra el pedido que quieres reembolsar. 2. Elige el método de reembolso: selecciona los productos para reembolsar y haz clic en \"Reembolsar\" o haz clic en \"Reembolsar\" primero para introducir el importe que quieres reembolsar. 3. El importe reembolsado se restará de tu siguiente pago y el cliente lo recibirá en 5 días hábiles.\nEsto solo se aplica a los pedidos capturados. Para pedidos sin capturar, cancélalos si no vas a enviar ningún producto. Si solo se ha capturado y enviado una parte de pedido, libera la parte sin capturar."
					},
					"cancelOrder": {
						"question": "¿Cómo cancelo un pedido?",
						"answer": "1. Ve a la sección de administración de pedidos y encuentra el pedido que quieres cancelar. 2. Haz clic en \"Cancelar pedido\". Esta acción también informará al cliente de que el pedido no se va a enviar.\nPuedes volver a crear los pedidos cancelados si lo necesitas. No puedes cancelar pedidos capturados, pero puedes realizar un reembolso."
					},
					"unableToTakeActions": {
						"question": "¿Por qué no puedo realizar ninguna acción sobre un pedido?",
						"directPartner": {
							"question": "Tengo un acuerdo directo con Klarna",
							"answer": "Los permisos del usuario determinan lo que estos pueden hacer en el Portal de socios. Existen dos tipos diferentes tipos de permisos de usuario: Administrador: son usuarios con acceso total, por ejemplo, un administrador puede acceder y modificar todas las funciones del Portal de socios. Específicos para secciones: los usuarios que no son administradores tienen acceso a una o más secciones específicas del Portal de socios.\nEste puede ser el motivo por el que no puedas actuar sobre determinados pedidos. Hay distintos roles de acceso en relación con la administración de pedidos. Habla con tu administrador para determinar qué rol de acceso es el más adecuado para las acciones que tienes que realizar."
						},
						"distributionPartner": {
							"question": "Tengo un acuerdo directo con Klarna",
							"answer": "Todas las acciones que te gustaría realizar en un pedido solo pueden hacerse a través de la plataforma suministrada por el socio de distribución."
						}
					}
				},
				"orderModification": "Problemas y modificación de pedidos",
				"orderProcessing": "Procesamiento de pedidos",
				"refundIssues": {
					"title": "Temas relacionados con los reembolsos",
					"refundedOrderWhenCustomerFunds": {
						"question": "He reembolsado un pedido, ¿cuándo recibirá mi cliente su dinero?",
						"answer": "El cliente debería recibir su dinero en 5 días hábiles desde que se reembolsó el pedido. Esto podría variar dependiendo del método de pago que haya elegido el cliente. Si el cliente tiene alguna duda, recomiéndale que contacte con la Atención al cliente de Klarna."
					}
				},
				"reservations": {
					"title": "Reservas",
					"whyUnableToExtendReservation": {
						"question": "¿Por qué no puedo ampliar el plazo de reserva de un pedido?",
						"answer": "Hay determinadas circunstancias en las que no se puede ampliar la fecha de caducidad de un pedido:\n- Si el pedido ya ha caducado, necesitarás volver a crearlo. Para ello, ve a la página del pedido, haz clic en “Copiar” y selecciona “Crear un nuevo pedido”.\n- Si el pedido se ha pagado con tarjeta, el cliente debe realizar un nuevo pedido, porque las fechas de caducidad de estos pedidos no se pueden ampliar.\n"
					}
				},
				"paymentsForMyOrders": {
					"title": "Pagos de mis pedidos",
					"whenWillIGetPaid": {
						"question": "¿Cuándo se me pagará por un pedido?",
						"answer": "Para recibir el pago de un pedido, primero tienes que capturarlo. El importe del pago, después de restarle las comisiones, devoluciones y demás cargos, se enviará a tu cuenta bancaria según tu planificación de pagos. Puedes revisar la configuración de tus pagos en la sección de Liquidaciones."
					},
					"refundOrderReceivedPayment": {
						"question": "¿Qué pasa si reembolso un pedido para el que ya he recibido el dinero?",
						"answer": "Todo importe que reembolses se restará de tu siguiente pago programado."
					},
					"referenceNotShowUp": {
						"question": "El número de pedido o de referencia no aparece en el informe.",
						"answer": "Si no puedes encontrar el pago de un pedido concreto:\n- Comprueba si el número de pedido o referencia está bien escrito.\n- Comprueba que el pedido se haya capturado.\n- Comprueba si el pedido está dentro del periodo de captura del informe.\n- Ten en cuenta que los pedidos cancelados antes de la liquidación no aparecerán en el informe.\n"
					},
					"gotPaidSpecificOrder": {
						"question": "¿Cómo compruebo si se me ha pagado un pedido concreto?",
						"answer": "En la sección de administración de pedidos del Portal de socios, busca el pedido mediante el número de pedido o referencia correcto. Debajo del número de referencia, comprueba si el pedido se ha capturado. Si ya se ha capturado, el estado aparecerá como pendiente o pagado. Para los pedidos pagados, haz clic en “Ver liquidación”, para consultar el informe correspondiente."
					}
				}
			},
			"disputes": {
				"disputeManagement": "Gestión de reclamaciones",
				"contactInfoAndNotifications": "Información de contacto y Configuración de notificaciones",
				"timingAndDeadlines": "Plazos y fechas límite de las reclamaciones",
				"resolutionProcess": "Proceso de resolución de reclamaciones",
				"merchantDisputesApp": {
					"title": "Aplicación de disputas para comerciantes",
					"respondToDispute": {
						"question": "¿Cómo sé si tengo que responder a una solicitud de reclamación?",
						"answer": "Consulta la sección de tareas pendientes de la página de inicio del Portal de socios para ver si hay reclamaciones a las que tengas que responder."
					},
					"overviewDisputesCustomer": {
						"question": "¿Dónde encuentro un resumen de todas las reclamaciones presentadas por los clientes?",
						"answer": "Puedes encontrar todas las reclamaciones presentadas en la sección de reclamaciones, en la página “Todas las reclamaciones”, inmediatamente después de que se solicite una reclamación.\n\nTen en cuenta que todas las reclamaciones aparecerán en la página “Todas las reclamaciones” durante hasta 180 días después de su cierre.\n"
					},
					"overviewDisputesResponse": {
						"question": "¿Dónde encuentro un resumen de todas las reclamaciones a las que necesite responder?",
						"answer": "Encontrarás todas las reclamaciones que necesitan una respuesta en la sección de reclamaciones, en la página “Reclamaciones abiertas” debajo de “Necesita respuesta”."
					},
					"updateContactInformation": {
						"question": "¿Qué tengo que hacer para actualizar mi información de contacto para los emails de recordatorio de reclamaciones?",
						"answer": "1. Dirígete a la sección Reclamaciones\n2. Haz clic en la página “Ajustes de reclamaciones e emails”\n3. Actualiza tu información de contacto en la pestaña de “Emails y notificaciones”.\n\nRecuerda que puedes configurar una información de contacto distinta para “reclamaciones”, “compras no autorizadas” y “pedidos de alto riesgo”. Además, puedes establecer la frecuencia de las notificaciones en la pestaña “Notificaciones”.\n"
					},
					"updateCustomerServiceOnKlarnaApp": {
						"question": "¿Qué tengo que hacer para actualizar mi información de Atención al cliente en la app de Klarna?",
						"answer": "1. Dirígete a “Ajustes de pagos”\n2. Haz clic en “Información de Atención al cliente”\n3. Selecciona “Mercado” (si cuentas con ayuda específica para tu mercado)\n\nPuedes introducir la información de contacto del cliente así como la política de devoluciones que se debe mostrar en la app de Klarna, para ofrecer una experiencia más positiva al cliente.\n\nTen en cuenta que necesitarás derechos de acceso totales al Portal de socios para poder ver esta información.\n"
					},
					"improveDisputesPerformance": {
						"question": "¿Cómo puedo mejorar mi rendimiento respecto a las reclamaciones?",
						"answer": "Para conseguir trucos y consejos para mejorar tu rendimiento en reclamaciones, consulta la “Guía de mejoras para reembolsos y reclamaciones”. Para acceder a ella, dirígete a la página de “Estadísticas” haciendo clic en el botón “Conseguir datos” en la esquina superior derecha de la página.\n\nTen en cuenta que necesitarás derechos de acceso totales al Portal de socios para poder ver esta información.\n"
					},
					"overviewDisputesPerformance": {
						"question": "¿Dónde puedo encontrar un resumen de mi rendimiento en reclamaciones?",
						"answer": "Para consultar estadísticas detalladas sobre tus reclamaciones, ve a la página “Estadísticas” de la app de reclamaciones. Esta sección ofrece un resumen de las reclamaciones mensuales, resultados, costes y pérdidas en relación con ellas.\n\nTen en cuenta que necesitarás derechos de acceso totales al Portal de socios para poder ver esta información.\n"
					}
				},
				"disputesLifecycle": {
					"title": "Ciclo de reclamaciones",
					"whenCanSeeDisputes": {
						"question": "¿Dónde puedo ver las reclamaciones que presentan los clientes?",
						"answer": "En la página “Todas las reclamaciones” de la app de reclamaciones puedes ver todas las reclamaciones inmediatamente después de que se presenten."
					},
					"howLongToResolve": {
						"question": "¿Cuánto tiempo tengo para resolver la reclamación directamente con el cliente?",
						"answer": "Tienes 21 días a partir de que se presente una reclamación para resolverla con el cliente. Si no has logrado resolver la reclamación con el cliente en los 21 días siguientes a su presentación, Klarna intervendrá para ayudar en el proceso de resolución entre ambas partes."
					},
					"howMuchTimeToRefund": {
						"question": "¿Cuánto tiempo tengo para reembolsar las devoluciones?",
						"answer": "Tienes 21 días desde la fecha de devolución para procesar un reembolso antes de que Klarna intervenga para ayudar en el proceso de resolución entre ambas partes. Dicho esto, lo mejor es reembolsar las devoluciones en cuanto las recibas."
					},
					"howToResolve": {
						"question": "¿Cómo puedo resolver las reclamaciones y devoluciones?",
						"answer": "Hay varias maneras para resolver una reclamación o devolución.\n\n- Procesa la reclamación o devolución en cuanto la recibas/acuerdes una solución.\n- Establece un umbral de contracargos en “Ajustes de reclamaciones e emails”\n- Mantén una actitud proactiva y comprueba la sección “Todas las reclamaciones”\n- Acepta las pérdidas\n"
					},
					"whenToRespond": {
						"question": "¿Cuándo tengo que defender/responder a las reclamaciones de manera activa?",
						"answer": "Si no logras alcanzar una solución con el cliente dentro del plazo permitido (21 días), Klarna intervendrá para ayudar en el proceso de resolución entre ambas partes. Si la reclamación es válida, el equipo de ayuda de Klarna se pondrá en contacto contigo a través de la app de reclamaciones solicitándote más información.\n\nEn este punto se te pedirá que defiendas/respondas a una reclamación.\n"
					},
					"howMuchTimeToRespond": {
						"question": "¿Cuánto tiempo tengo para responder a una solicitud de reclamación?",
						"answer": "El plazo para la primera solicitud es de 14 días para todos los motivos de las reclamaciones, salvo para las compras no autorizadas (7 días). Para cada solicitud de seguimiento* que recibas (con un máximo de 5 solicitudes) tendrás un plazo de 7 días. Si necesitas ampliar tu respuesta, tienes 60 minutos desde el envío para añadir, por ejemplo, información o documentos adjuntos. Una vez que haya pasado la fecha límite, ya no podrás defender el caso.\n\n*Pueden aplicarse normas especiales dependiendo del acuerdo de colaboración.\n"
					},
					"howLongToSeeDispute": {
						"question": "¿Durante cuánto tiempo puedo ver una reclamación en la app de reclamaciones después de su resolución?",
						"answer": "Todas las reclamaciones aparecerán en la página “Todas las reclamaciones” durante hasta 180 días después de su cierre."
					},
					"whenToGetCharged": {
						"question": "¿Cuándo se me cobra una comisión por reclamación?",
						"answer": "Puedes tener que pagar una comisión por reclamación si:\n- Tienes la culpa de la reclamación.\n- No respondes antes de que acabe el plazo.\n- Emites un reembolso después de que Klarna intervenga en el proceso de resolución.\n\nLa comisión se aplica con carácter retroactivo después de que se determine el resultado de la reclamación. No se te cobrará nada si la reclamación se resuelve a tu favor o si se cancela o se rechaza.\n"
					}
				},
				"disputesHandling": {
					"title": "Gestión de reclamaciones",
					"whatIsDispute": {
						"question": "¿Qué información recibo con una solicitud de reclamación?",
						"answer": "La solicitud de reclamación incluye:\n\n- El motivo: la categoría de la reclamación, p. ej., devoluciones, artículos no recibidos.\n- El comercio: necesario si hay varios ID del comerciante (MID) registrados con Klarna.\n- La fecha de la reclamación: cuándo ha presentado la reclamación el cliente.\n- La fecha límite: la fecha límite que tienes para responder y defender el caso.\n- Los productos incluidos en la reclamación: los artículos de la reclamación.\n- Los detalles del pedido: Incluye “referencia 1 y 2 del comercio”, “fecha de compra”, “importe reclamado”.\n"
					},
					"howManyTimesDefend": {
						"question": "¿Cuántas veces puedo defender una solicitud de reclamación?",
						"answer": "Recibirás un máximo de cinco (5) solicitudes para defender tu reclamación pero la mayoría de las veces no son necesarias más de una o dos."
					},
					"doIReceiveNotification": {
						"question": "¿Recibo una notificación cuando tengo que responder a una reclamación?",
						"answer": "Si tienes una solicitud de reclamación pendiente, aparecerá en varios sitios:\n\n- En la sección de tareas pendientes de la página de inicio del Portal de socios.\n- Como una notificación de la sección de reclamaciones en la página “Reclamaciones abiertas”, con el número de reclamaciones que necesitan una respuesta.\n\nTambién puedes optar por recibir un email para cada reclamación modificando tu configuración en “Ajustes de reclamaciones e emails” y seleccionando “Notificación de email para cada reclamación”.\n"
					},
					"howToAvoidDisputesFees": {
						"question": "¿Cómo puedo evitar las comisiones por reclamación?",
						"answer": "Para evitar las comisiones por reclamación:\n- Procesa las devoluciones o reembolsos lo antes posible.\n- Establece un umbral de contracargos en “Ajustes de reclamaciones e emails”.\n- Consulta con frecuencia la sección “Todas las reclamaciones”.\n- Responde a las reclamaciones con toda la información necesaria.\n- Responde antes de que venza la fecha límite.\n"
					}
				}
			},
			"settlements": {
				"payoutNotFound": {
					"title": "No encuentro el pago de un pedido concreto",
					"orderNumberNotFound": {
						"question": "El número de pedido o de referencia no aparece en el informe",
						"answer": "Si no puedes encontrar el pago de un pedido concreto:\n- Comprueba si el número de pedido o referencia está bien escrito.\n- Comprueba que el pedido se haya capturado.\n- Comprueba si el pedido está dentro del periodo de captura del informe.\n- Ten en cuenta que los pedidos cancelados antes de la liquidación no aparecerán en el informe.\n"
					},
					"checkIfPaidForOrder": {
						"question": "¿Cómo compruebo si se me ha pagado un pedido concreto?",
						"answer": "En la sección de administración de pedidos del Portal de socios, busca el pedido mediante el número de pedido o referencia correcto. Debajo del número de referencia, comprueba si el pedido se ha capturado. Si ya se ha capturado, el estado aparecerá como pendiente o pagado. Para los pedidos pagados, haz clic en “Ver liquidación”, para consultar el informe correspondiente."
					}
				},
				"payouts": {
					"title": "Pagos",
					"whenIsNext": {
						"question": "¿Cuándo recibiré mi siguiente pago?",
						"answer": null
					},
					"whyCalculationNotMatch": {
						"question": "¿Por qué mis cálculos son distintos a los de los pagos recibidos?",
						"answer": "Según el contrato del comercio, se restan comisiones como las de transacciones y devoluciones tardías. Por ello, tus cálculos pueden ser distintos a las liquidaciones que recibas de Klarna. Para recibir un desglose de lo que se ha descontado, te recomendamos que abras la versión en PDF del informe de liquidación."
					},
					"whenGetPaid": {
						"question": "¿Cuándo recibiré el pago?",
						"answer": null
					},
					"missingPayout": {
						"question": "No he recibido el pago",
						"answer": "Antes de contactar con tu canal de ayuda, ten en cuenta que puede faltarte un pago por alguno de los siguientes motivos:\n\n- Klarna envía el pago el mismo día en que se genera el informe, pero el dinero puede tardar varios días en aparecer en tu cuenta bancaria.\n- No has capturado ningún pedido en este periodo de captura.\n- Has actualizado los detalles de la cuenta bancaria con Klarna recientemente y el pago puede haberse enviado a tu antigua cuenta bancaria. Esto puede producirse si el cambio no se procesa antes de que se ejecute el pago.\n- El número de pedido o de referencia que estás buscando no es correcto y necesitas revisarlo.\n- Klarna está reteniendo tus pagos. Deberías haber recibido una notificación de la retención en un email enviado por separado, normalmente enviado por un administrador.\n"
					},
					"howIsPayoutCalculated": {
						"question": "¿Cómo se calcula el pago?",
						"answer": "El pago se calcula a partir de los pedidos capturados dentro de un periodo de tiempo a los que se restan las comisiones, devoluciones y otros cargos. El informe de liquidación ofrece un desglose de estas cantidades.\nSi los reembolsos superan a las ventas en un periodo de captura concreto, la diferencia se deducirá del siguiente pago.\n"
					}
				},
				"howPayoutsWork": {
					"question": "¿Cómo funcionan los pagos?",
					"answer": "La sección de Liquidaciones ofrece un resumen de cómo funcionan los pagos, incluidos los plazos de pago, los periodos de captura y las fechas de los pagos. Puedes descargar informes de transacción para cada uno de los pagos en formatos PDF o CSV, y visualizar o descargar detalles de pagos concretos haciendo clic en las referencias de sus pagos."
				},
				"payoutSchedule": {
					"title": "Planificación de pagos",
					"whyDelay": {
						"question": "¿Por qué existe un Plazo de pago?",
						"answer": "Su principal objetivo es evitar que el dinero de las facturas se transfiera de ida y vuelta entre Klarna y tú, además de cubrir los casos en que debas reembolsar un pedido capturado."
					},
					"changePayoutDelay": {
						"question": "Quiero cambiar mi plazo de pago",
						"answer": "Contamos con comprobaciones internas y criterios generales que necesitan cumplirse. Los criterios generales incluyen:\n- Debes haber completado la incorporación en Klarna\n- Tu primera fecha de compra supera 100 días\n- Tu negocio debe tener más de 6 meses\n\nKlarna también requiere información de un administrador sobre…\n- El nuevo plazo de pago deseado y por qué es necesario ese cambio\n- La ID del comerciante (MID) al que se aplica el cambio\nPueden aplicarse más criterios y tenemos en cuenta las condiciones específicas de cada caso.\n"
					}
				},
				"generatingReports": {
					"title": "Generación de informes",
					"howToSeeReports": {
						"question": "¿Cómo puedo ver informes de periodos específicos?",
						"answer": "Puedes personalizar los informes para periodos de tiempo específicos mediante la sección Informes personalizados en Liquidaciones."
					},
					"convertCsvToExcel2": {
						"answer": "Sigue estos pasos\n1. Abre Excel, haz clic en “Archivo” > “Nuevo libro”.\n"
					},
					"convertCsvToExcel": {
						"question": "¿Cómo convierto un informe CSV en una hora de Excel?",
						"answer": "1. Abre Excel, haz clic en “Archivo” > “Nuevo libro”.\n2. Haz clic en la pestaña “Datos” y selecciona “Desde texto”.\n3. Elige el archivo .CSV deseado y haz clic en “Obtener datos”.\n4. En el asistente de importación de texto, elige “Delimitado” y luego haz clic en “Siguiente”.\n5. En “Delimitadores”, selecciona “Punto y coma” y marca “Calificador de texto” como “ninguno”. Haz clic en finalizar.\n6. En el cuadro de diálogo “Importar datos”, haz clic en “Aceptar” para colocar los datos a partir de la celda A1.\n\nNota: Los informes CSV en el portal del comercio usan un punto y coma (;) como separador. Comprueba el software de tu hoja de datos para ver cómo modificar el separador predeterminado.\n"
					}
				},
				"payoutAndFeeCalculation": {
					"title": "Cálculo del pago y las comisiones",
					"howAreFeesCalculated": {
						"question": "¿Cómo se calculan las comisiones?",
						"answer": "Las comisiones que se deducen de los pagos Klarna están reflejadas en tu contrato de comercio, y otras comisiones como las derivadas de devoluciones tardías aparecen en la sección “Comisiones” de tus informes de liquidaciones/pagos.\n\nPara personalizar las descripciones de las comisiones en tus informes:\n1. Navega hasta Informes de liquidación > Configurar informes > Añadir nueva configuración.\n2. Para informes en formato CSV selecciona “Tipo de transacción detallada” en la lista de transacciones.\n3. Para informes en formato PDF, selecciona “Incluir detalles del pedido” en la configuración de contenido y diseño.\n\nAsegúrate de modificar la configuración de cada ID del comerciante y canal (SFTP, Portal del comercio, API) por separado.\n"
					},
					"howIsPayoutCalculated": {
						"question": "¿Cómo se calcula el pago?",
						"answer": "El pago se calcula a partir de los pedidos capturados dentro de un periodo de tiempo a los que se restan las comisiones, devoluciones y otros cargos. El informe de liquidación ofrece un desglose de estas cantidades.\n\nSi los reembolsos superan a las ventas en un periodo de captura concreto, la diferencia se deducirá del siguiente pago.\n"
					}
				}
			},
			"options": {
				"payout": "Pago",
				"orders": "Pedidos",
				"settlements": "Liquidaciones",
				"disputes": "Conflictos",
				"otherIssue": "Otros problemas",
				"yes": "Sí",
				"no": "No",
				"partially": "Parcialmente",
				"iNeedMoreHelp": "Necesito más ayuda",
				"thatHelped": "Esto me ha ayudado",
				"contactSupportChannel": "Contacta con los canales de soporte"
			},
			"button": {
				"label": "Contactar con el servicio de soporte"
			},
			"contact": {
				"chat": "Chatear con un agente",
				"ticket": "Enviar una incidencia",
				"times": {
					"minutes": "Tiempo de espera: {time} minuto(s)",
					"hours": "Tiempo de espera: {time} hora(s)",
					"days": "Tiempo de espera: {time} días(s)"
				}
			}
		}
	}
};