module.exports = {
	"cs": {
		"config": {
			"environments": {
				"development": "",
				"staging": "",
				"playground": "",
				"production": ""
			}
		}
	}
};