module.exports = {
	"el": {
		"help-tool": {
			"button-text": "Βοήθεια",
			"support-button": "Λάβετε υποστήριξη",
			"support-page": {
				"create-ticket": {
					"title": "Λάβετε υποστήριξη",
					"instructions": "Υποβάλετε ένα αίτημα παρακάτω και θα επικοινωνήσουμε μαζί σας εντός μίας εργάσιμης ημέρας.",
					"related-content": {
						"title": "Αυτά μπορεί να βοηθήσουν στην επίλυση του προβλήματός σας"
					},
					"fields": {
						"mid": {
							"label": "Επιλογή καταστήματος",
							"validation": "Απαιτείται κατάστημα"
						},
						"phoneNumber": {
							"label": "Αριθμός τηλεφώνου (προαιρετικό)"
						},
						"topic": {
							"question": "Με τι σχετίζεται η ερώτησή σας;",
							"label": "Επιλέξτε ένα θέμα",
							"validation": "Απαιτείται θέμα",
							"options": {
								"orders": "Παραγγελίες",
								"settlements": "Διακανονισμοί",
								"agreements": "Συμφωνίες",
								"others": "Άλλα"
							}
						},
						"subject": {
							"label": "Θέμα",
							"validation": "Απαιτείται θέμα"
						},
						"description": {
							"label": "Μήνυμα",
							"validation": "Απαιτείται περιγραφή"
						},
						"attachment": {
							"label": "Προσθήκη επισυνάψεων",
							"button": "Μεταφόρτωση",
							"info": "Η προσθήκη στιγμιότυπων οθόνης θα μας βοηθήσει να επιλύσουμε το θέμα ευκολότερα.",
							"supportedFileTypes": "Υποστηριζόμενοι τύποι αρχείων: .png, .jpg, .pdf",
							"error": "Το σύνολο των αρχείων δεν πρέπει να υπερβαίνει τα {maxSize} MB"
						}
					},
					"submit": "Υποβολή αιτήματος",
					"service-unavailable-error": "Σφάλμα! Κάτι πήγε στραβά, προσπαθήστε ξανά σε μερικά λεπτά. Αν αυτό συνεχιστεί, μεταβείτε στο {link} για βοήθεια.",
					"generic-error": "Δεν μπορέσαμε να επεξεργαστούμε το αίτημά σας. Τα συστήματά μας δεν επικοινωνούν μεταξύ τους αυτήν τη στιγμή. Προσπαθήστε να κάνετε ανανέωση της σελίδας ή επιστρέψτε αργότερα. Αν αυτό συνεχιστεί, μεταβείτε στο {link} για βοήθεια.",
					"contact-link": "επικοινωνήστε μαζί μας"
				},
				"view-ticket": {
					"title": "Ολοκληρώθηκε!",
					"details": "Λάβαμε το αίτημά σας και θα σας απαντήσουμε εντός μίας εργάσιμης ημέρας. Ακολουθούν τα στοιχεία σας:",
					"ticket": "Αρ. αιτήματος{ticketId}",
					"email": "Email: {email}",
					"status": "Μπορείτε να παρακολουθήσετε την κατάσταση στη σελίδα «Υποστήριξη».",
					"track": "Παρακολούθηση κατάστασης"
				}
			},
			"header": {
				"homeLink": "Επαναφορά"
			},
			"home": {
				"greeting": "Γεια σας {givenName}! Πώς μπορούμε να σας βοηθήσουμε;",
				"topics": "Πώς μπορούμε να βοηθήσουμε;"
			},
			"topics": {
				"expandButton": "Περισσότερα"
			},
			"feedback": {
				"title": "Ήταν αυτό χρήσιμο;",
				"optionYes": "Ναι, αυτό ήταν χρήσιμο",
				"optionNo": "Όχι, χρειάζομαι περισσότερη βοήθεια",
				"positiveFeedback": "Σας ευχαριστούμε για τα θετικά σχόλια, μας βοηθούν ιδιαίτερα στο να σας παρέχουμε πιο σχετικά άρθρα στο μέλλον."
			},
			"freeTextFeedback": {
				"title": "Βοηθήστε μας να βελτιωθούμε",
				"placeholder": "Γιατί δεν βρήκατε χρήσιμο αυτό το περιεχόμενο;",
				"submitButton": "Αποστολή",
				"feedbackMessage": "Σας ευχαριστούμε για τα σχόλιά σας, μας βοηθούν ιδιαίτερα στο να σας παρέχουμε πιο σχετικά άρθρα στο μέλλον."
			},
			"relatedContent": {
				"title": "Σχετικό περιεχόμενο"
			},
			"search": {
				"buttonLabel": "Αναζήτηση",
				"input": "Αναζήτηση για άρθρα…",
				"loading": "Φόρτωση…",
				"results": {
					"contact": "Δεν μπορέσατε να βρείτε τη βοήθεια που χρειάζεστε;",
					"empty": "Λυπούμαστε, δεν βρέθηκαν αποτελέσματα για το αίτημά σας. Προσπαθήστε να κάνετε αναζήτηση για διαφορετικό όρο.",
					"singleTopicFound": "Βρήκαμε 1 θέμα",
					"multipleTopicsFound": "Βρήκαμε {count} θέματα",
					"singleArticleFound": "Βρήκαμε 1 άρθρο",
					"multipleArticlesFound": "Βρήκαμε {count} άρθρα"
				}
			},
			"contact": {
				"title": "Επικοινωνήστε μαζί μας",
				"channelsIntro": "Επικοινωνήστε μαζί μας για να σας βοηθήσουμε.",
				"businessDetailsTitle": "Τα επαγγελματικά σας στοιχεία",
				"businessDetailsName": "Όνομα επιχείρησης",
				"businessDetailsEmail": "Email",
				"businessDetailsMid": "Αναγνωριστικό εμπόρου",
				"phone": {
					"title": "Καλέστε μας",
					"paragraph": "Μπορείτε να μας καλέσετε κατά τη διάρκεια των εργάσιμων ωρών και ημερών. Αν βρίσκεστε στη Σουηδία ή το Ηνωμένο Βασίλειο, μπορείτε επίσης να μας καλέσετε το σαββατοκύριακο μεταξύ 10 π.μ. και 4 μ.μ."
				},
				"email": {
					"title": "Στείλτε μας email",
					"paragraph": "Στείλτε μας ένα email και θα επικοινωνήσουμε μαζί σας το συντομότερο δυνατόν."
				}
			},
			"errorBoundary": {
				"title": "Προέκυψε κάποιο σφάλμα",
				"message": "Λυπούμαστε, αλλά υπήρξε κάποιο σφάλμα. Μπορείτε να κάνετε κλικ στο κουμπί «Επιστροφή» παρακάτω για να επιστρέψετε στο εργαλείο βοήθειας, ή στο κουμπί «Λάβετε υποστήριξη» για να επικοινωνήσετε μαζί μας.",
				"resetButton": "Επιστροφή",
				"errorId": "Αναγνωριστικό σφάλματος: {errorId}"
			},
			"tour": {
				"prev": "Προηγούμενο",
				"next": "Επόμενο",
				"done": "Ολοκληρώθηκε"
			},
			"notAvailableMobile": {
				"title": "Μη διαθέσιμο",
				"buttonBack": "Επιστροφή",
				"paragraph": "Αυτό το περιεχόμενο δεν είναι διαθέσιμο στη συσκευή σας. Δοκιμάστε να το ανοίξετε σε μια συσκευή με μεγαλύτερο μέγεθος οθόνης, όπως έναν επιτραπέζιο υπολογιστή."
			},
			"shareButton": {
				"title": {
					"article": "Μοιραστείτε έναν βαθύ σύνδεσμο (deep link) προς αυτό το άρθρο",
					"topic": "Μοιραστείτε έναν βαθύ σύνδεσμο (deep link) προς αυτό το θέμα",
					"tour": "Μοιραστείτε έναν βαθύ σύνδεσμο (deep link) προς αυτήν την περιήγηση"
				},
				"radioItemHome": "Εφαρμογή αρχικής σελίδας",
				"descriptionHome": "Ανοίγει το άρθρο βοήθειας στην αρχική σελίδα της Πύλης Εμπόρων",
				"radioItemCurrent": "Τρέχουσα σελίδα",
				"descriptionCurrent": "Ανοίγει το άρθρο βοήθειας στην τρέχουσα σελίδα"
			},
			"marketSelector": {
				"whichMarketToContact": "Με ποια αγορά θέλετε να επικοινωνήσετε;"
			}
		},
		"orientation": {
			"title": "Μάθετε πώς…"
		},
		"helpbot": {
			"questions": {
				"whatDoYouNeedHelpWith": "Γεια σας! Για ποιο θέμα χρειάζεστε υποστήριξη σήμερα;",
				"selectSupportChannel": "Επιλέξτε ένα κανάλι για να επικοινωνήσετε με την υπηρεσία υποστήριξής μας.",
				"thatHelped": "Σας ευχαριστούμε για τα θετικά σχόλια, μας βοηθούν ιδιαίτερα στο να σας παρέχουμε πιο σχετική βοήθεια στο μέλλον. 😀"
			},
			"orders": {
				"orderManagement": {
					"title": "Διαχείριση Παραγγελιών",
					"createOrderPos": {
						"question": "Πώς μπορώ να δημιουργήσω μια παραγγελία μέσω του Σημείου Πωλήσεων;",
						"answer": "1. Ανοίξτε το Σημείο Πωλήσεων 2. Καταχωρίστε την ονομασία, την τιμή και την ποσότητα του προϊόντος. 3. Κοινοποιήστε τον σύνδεσμο πληρωμής που δημιουργήθηκε στον πελάτη σας μέσω SMS, email ή ζητώντας από τον πελάτη να σαρώσει τον κωδικό QR που εμφανίζεται στην οθόνη του ταμείου σας. 4. Ο πελάτης μπορεί τώρα να ολοκληρώσει την αγορά από τη συσκευή του."
					},
					"extendExpiryReservation": {
						"question": "Πώς παρατείνω την ημερομηνία λήξης μιας κράτησης;",
						"answer": "1. Μεταβείτε στη Διαχείριση Παραγγελιών και επιλέξτε μία ή πολλές παραγγελίες των οποίων την ημερομηνία λήξης θέλετε να παρατείνετε. 2. Κάντε κλικ στην επιλογή Παράταση ημερομηνίας λήξης. 3. Επιβεβαιώστε την επιλογή σας.\nΟι παραγγελίες παρατείνονται σύμφωνα με τις ρυθμίσεις χρόνου κράτησης για το κατάστημά σας (συνήθως 28 ημέρες). Αν μια παραγγελία έχει λήξει, δεν μπορεί πλέον να παραταθεί."
					},
					"howToCaptureOrders": {
						"question": "Πώς αποτυπώνω παραγγελίες;",
						"partially": {
							"question": "Θέλω να αποτυπώσω μερικώς μια παραγγελία",
							"answer": "Αν υπάρχει η δυνατότητα μόνο μερικής αποστολής μιας παραγγελίας πελάτη, μπορείτε να αποτυπώσετε μερικώς την παραγγελία ανά γραμμές ή ποσό παραγγελίας. Αποτυπώστε τις γραμμές παραγγελίας 1. Μεταβείτε στην παραγγελία στη Διαχείριση Παραγγελιών και επιλέξτε τα είδη που έχετε αυτή τη στιγμή σε απόθεμα από τη σελίδα παραγγελιών. Θυμηθείτε να επεξεργαστείτε τις γραμμές παραγγελίας για να προσαρμόσετε τις ποσότητες εάν χρειάζεται. 2. Κάντε κλικ στην Αποτύπωση. Αποτύπωση ποσού: 1. Μεταβείτε στην παραγγελία στη Διαχείριση Παραγγελιών. Μπορείτε να αποτυπώσετε ένα ποσό κάνοντας κλικ στο κουμπί «Αποτύπωση όλων». Εδώ εισάγετε το επιθυμητό ποσό. 2. Κάντε κλικ στην Αποτύπωση.\nΘυμηθείτε να ολοκληρώσετε την παραγγελία όταν είναι δυνατόν, είτε αποτυπώνοντας τα υπόλοιπα είδη/το ποσό μόλις είναι διαθέσιμα προς αποστολή - είτε ακυρώνοντας την υπόλοιπη παραγγελία αν δεν μπορείτε να αποστείλετε τα αγαθά."
						},
						"fully": {
							"question": "Θέλω να αποτυπώσω πλήρως τις παραγγελίες",
							"answer": "1. Μεταβείτε στη Διαχείριση Παραγγελιών και κάντε κλικ στον αριθμό αναφοράς Klarna για να ανοίξετε την παραγγελία. 2. Αποτυπώστε την πλήρη παραγγελία χρησιμοποιώντας ένα από τα παρακάτω βήματα: - Κάνοντας κλικ στο κουμπί «Αποτύπωση» - Επιλέγοντας τις γραμμές παραγγελίας και κάνοντας κλικ στην «Αποτύπωση». - Κάνοντας κλικ στην επιλογή «Αποτύπωση όλων» και εισάγοντας το πλήρες ποσό της παραγγελίας. 3. Όταν αποτυπώνετε την παραγγελία, μπορείτε να πραγματοποιήσετε λήψη του δελτίου συσκευασίας ώστε να το συμπεριλάβετε στην αποστολή σας.\nΒεβαιωθείτε ότι αποτυπώνετε τις παραγγελίες πριν από την ημερομηνία λήξης τους (συνήθως 28 ημέρες από τη δημιουργία της παραγγελίας). Μπορείτε να αποτυπώσετε πολλές παραγγελίες επιλέγοντάς τις στη λίστα παραγγελιών και κάνοντας κλικ στην επιλογή «Πλήρης αποτύπωση» αν θέλετε να επιταχύνετε τα πράγματα."
						}
					},
					"canIUpdateCustomerInfo": {
						"question": "Μπορώ να ενημερώσω τα στοιχεία του πελάτη;",
						"answer": "Δεν μπορείτε ποτέ να αλλάξετε τον παραλήπτη μιας παραγγελίας. Αλλά μπορείτε να αλλάξετε τη διεύθυνση αποστολής και χρέωσης για μη αποτυπωμένες ή μερικώς αποτυπωμένες παραγγελίες. Ωστόσο, αν η Klarna απορρίψει τη νέα διεύθυνση επειδή δεν είναι συνδεδεμένη με τον πελάτη, η διεύθυνση χρέωσης μπορεί να αλλάξει."
					},
					"extendDueDate": {
						"question": "Πώς παρατείνω την προθεσμία μιας παραγγελίας;",
						"answer": "Μπορείτε να παρατείνετε την προθεσμία μιας παραγγελίας κάνοντας κλικ στην επιλογή «Παράταση προθεσμίας» σε μια αποτυπωμένη παραγγελία. Η παράταση της προθεσμίας μιας παραγγελίας συνοδεύεται από χρέωση που θα καθοριστεί πριν ολοκληρώσετε την ενέργεια."
					},
					"refundOrder": {
						"question": "Πώς κάνω επιστροφή χρημάτων για μία παραγγελία;",
						"answer": "1. Μεταβείτε στη Διαχείριση Παραγγελιών και εντοπίστε την παραγγελία για την οποία θέλετε να κάνετε επιστροφή χρημάτων. 2. Επιλέξτε τη μέθοδο επιστροφής χρημάτων: είτε επιλέξτε τα είδη για επιστροφή χρημάτων και μετά κάντε κλικ στην επιλογή «Επιστροφή χρημάτων» είτε κάντε πρώτα κλικ στην «Επιστροφή χρημάτων» για να εισαγάγετε το ποσό που θέλετε να επιστρέψετε. 3. Το επιστρεφόμενο ποσό θα αφαιρεθεί από την επόμενη αποπληρωμή σας και ο πελάτης θα το λάβει εντός 5 εργάσιμων ημερών.\nΑυτό ισχύει μόνο για τις αποτυπωμένες παραγγελίες. Για τις μη αποτυπωμένες παραγγελίες, ακυρώστε τις αν δεν αποστείλετε κανένα είδος. Αν μόνο ένα μέρος της παραγγελίας αποτυπώθηκε και απεστάλη, αποδεσμεύστε το μη αποτυπωμένο μέρος."
					},
					"cancelOrder": {
						"question": "Πώς ακυρώνω μια παραγγελία;",
						"answer": "1. Μεταβείτε στη Διαχείριση Παραγγελιών και εντοπίστε την παραγγελία την οποία θέλετε να ακυρώσετε. 2. Κάντε κλικ στην επιλογή «Ακύρωση Παραγγελίας». Αυτή η ενέργεια ενημερώνει επίσης τον πελάτη σας ότι η παραγγελία δεν θα αποσταλεί.\nΜπορείτε να δημιουργήσετε εκ νέου μια ακυρωμένη παραγγελία αν χρειαστεί. Δεν μπορείτε να ακυρώνετε αποτυπωμένες παραγγελίες, μπορείτε όμως να κάνετε επιστροφή χρημάτων."
					},
					"unableToTakeActions": {
						"question": "Γιατί δεν μπορώ να προβώ σε ενέργειες σε μια παραγγελία;",
						"directPartner": {
							"question": "Έχω μια συμφωνία απευθείας με την Klarna",
							"answer": "Οι άδειες χρήστη καθορίζουν τι μπορούν να κάνουν οι χρήστες στην Πύλη Συνεργατών. Υπάρχουν δύο διαφορετικοί τύποι αδειών χρήστη: Διαχειριστής - χρήστες με πλήρη πρόσβαση. Για παράδειγμα, ένας διαχειριστής μπορεί να έχει πρόσβαση και να επεξεργαστεί όλες τις λειτουργίες στην Πύλη Συνεργατών. Για συγκεκριμένες ενότητες - Χρήστες εκτός του διαχειριστή έχουν πρόσβαση σε μία ή περισσότερες συγκεκριμένες ενότητες στην Πύλη Συνεργατών.\nΑυτός μπορεί να είναι ο λόγος που δεν μπορείτε να κάνετε ενέργειες σε παραγγελίες. Υπάρχουν διάφοροι ρόλοι πρόσβασης σε σχέση με τη Διαχείριση Παραγγελιών. Μιλήστε με τον διαχειριστή σας για να καθορίσετε ποιος ρόλος πρόσβασης είναι πιο κατάλληλος για τις ενέργειες που πρέπει να εκτελέσετε."
						},
						"distributionPartner": {
							"question": "Έχω μια συμφωνία απευθείας με την Klarna",
							"answer": "Τυχόν ενέργειες που θέλετε να κάνετε σε μια παραγγελία μπορούν να γίνουν μόνο μέσω της πλατφόρμας που παρέχεται από τον Συνεργάτη Διανομής"
						}
					}
				},
				"orderModification": "Τροποποίηση και προβλήματα παραγγελιών",
				"orderProcessing": "Επεξεργασία παραγγελιών",
				"refundIssues": {
					"title": "Προβλήματα επιστροφής χρημάτων",
					"refundedOrderWhenCustomerFunds": {
						"question": "Έχω δώσει επιστροφή χρημάτων για μια παραγγελία, πότε θα λάβει ο πελάτης μου τα χρήματά του;",
						"answer": "Ένας πελάτης θα πρέπει να λάβει τα χρήματά του εντός 5 εργάσιμων ημερών από την ημερομηνία επιστροφής των χρημάτων της παραγγελίας. Αυτό μπορεί να διαφέρει ανάλογα με τη μέθοδο πληρωμής που επέλεξε ο πελάτης. Αν ο πελάτης έχει σχετικές ερωτήσεις, παρακαλείστε να τον παραπέμψετε στην Υποστήριξη Πελατών της Klarna."
					}
				},
				"reservations": {
					"title": "Κρατήσεις",
					"whyUnableToExtendReservation": {
						"question": "Γιατί δεν μπορώ να παρατείνω τον χρόνο κράτησης μιας παραγγελίας;",
						"answer": "Υπάρχουν συγκεκριμένες περιστάσεις που δεν καθιστούν δυνατή την παράταση της ημερομηνίας λήξης μιας παραγγελίας:\n- Αν η παραγγελία έχει ήδη λήξει, θα χρειαστεί να την ξαναδημιουργήσετε. Για να το κάνετε αυτό, μεταβείτε στη σελίδα παραγγελιών, κάντε κλικ στην «Αντιγραφή» και επιλέξτε «Δημιουργία νέας παραγγελίας».\n- Αν η παραγγελία πληρώθηκε με κάρτα, ο πελάτης πρέπει να υποβάλει νέα παραγγελία, καθώς δεν είναι δυνατή η παράταση των ημερομηνιών λήξης για αυτές τις παραγγελίες.\n"
					}
				},
				"paymentsForMyOrders": {
					"title": "Πληρωμές για τις παραγγελίες μου",
					"whenWillIGetPaid": {
						"question": "Πότε θα πληρωθώ για μια παραγγελία;",
						"answer": "Για να λάβετε πληρωμή για μια παραγγελία, πρέπει πρώτα να την αποτυπώσετε. Το ποσό εκταμίευσης, μετά την αφαίρεση τελών, επιστροφών και άλλων χρεώσεων, θα σταλεί στον τραπεζικό σας λογαριασμό με βάση το πρόγραμμα αποπληρωμών σας. Μπορείτε να ελέγξετε τη ρύθμιση αποπληρωμής σας στην ενότητα Διακανονισμοί."
					},
					"refundOrderReceivedPayment": {
						"question": "Τι θα συμβεί αν επιστρέψω τα χρήματα μιας παραγγελίας για την οποία έχω ήδη λάβει χρήματα;",
						"answer": "Οποιοδήποτε ποσό επιστρέψετε θα αφαιρεθεί από την επόμενη προγραμματισμένη αποπληρωμή σας."
					},
					"referenceNotShowUp": {
						"question": "Ο αριθμός παραγγελίας ή ο αριθμός αναφοράς δεν εμφανίζεται στην αναφορά.",
						"answer": "Αν δεν μπορείτε να βρείτε μια πληρωμή για μια συγκεκριμένη παραγγελία:\n- Επαληθεύστε την ακρίβεια του αριθμού παραγγελίας ή αναφοράς.\n- Βεβαιωθείτε ότι η παραγγελία έχει αποτυπωθεί.\n- Ελέγξτε αν η παραγγελία εμπίπτει στην περίοδο αποτύπωσης της αναφοράς.\n- Έχετε υπόψη ότι οι παραγγελίες που ακυρώθηκαν πριν από τον διακανονισμό δεν θα εμφανίζονται στην αναφορά.\n"
					},
					"gotPaidSpecificOrder": {
						"question": "Πώς μπορώ να δω αν έχω πληρωθεί για μια συγκεκριμένη παραγγελία;",
						"answer": "Στη διαχείριση παραγγελιών της Πύλης Συνεργατών, αναζητήστε την παραγγελία αφού βεβαιωθείτε ότι ο αριθμός παραγγελίας ή αναφοράς είναι ακριβής. Κάτω από τον αριθμό αναφοράς, ελέγξτε αν η παραγγελία έχει αποτυπωθεί. Αν έχει αποτυπωθεί, θα εμφανίζεται η κατάσταση «σε εκκρεμότητα» ή «πληρωμένη». Για αποπληρωμένες παραγγελίες, κάντε κλικ στην «Προβολή διακανονισμού» για να δείτε την αντίστοιχη αναφορά."
					}
				}
			},
			"disputes": {
				"disputeManagement": "Διαχείριση αμφισβήτησης συναλλαγής",
				"contactInfoAndNotifications": "Στοιχεία επικοινωνίας και ρυθμίσεις ειδοποιήσεων",
				"timingAndDeadlines": "Χρονοδιάγραμμα και προθεσμίες αμφισβήτησης συναλλαγής",
				"resolutionProcess": "Διαδικασία επίλυσης αμφισβήτησης συναλλαγής",
				"merchantDisputesApp": {
					"title": "Εφαρμογή αμφισβήτησης συναλλαγής εμπόρου",
					"respondToDispute": {
						"question": "Πώς θα ξέρω αν πρέπει να απαντήσω σε ένα αίτημα αμφισβήτησης συναλλαγής;",
						"answer": "Ελέγξτε την ενότητα «Εκκρεμότητες» στην αρχική σας σελίδα της Πύλης Συνεργατών για να δείτε αν υπάρχουν αμφισβητήσεις συναλλαγών που απαιτούν την απάντησή σας."
					},
					"overviewDisputesCustomer": {
						"question": "Πού μπορώ να βρω μια επισκόπηση όλων των αμφισβητήσεων συναλλαγών που έχουν υποβληθεί από πελάτες;",
						"answer": "Μπορείτε να βρείτε όλες τις αμφισβητήσεις συναλλαγών που έχουν υποβληθεί στην ενότητα Αμφισβητήσεις Συναλλαγών, κάτω από τη σελίδα «Όλες οι αμφισβητήσεις συναλλαγών», αμέσως μετά την υποβολή της αμφισβήτησης συναλλαγής.\n\nΛάβετε υπόψη ότι όλες οι αμφισβητήσεις συναλλαγών είναι διαθέσιμες στη σελίδα «Όλες οι αμφισβητήσεις συναλλαγών» για έως 180 ημέρες μετά το κλείσιμο.\n"
					},
					"overviewDisputesResponse": {
						"question": "Πού μπορώ να βρω μια επισκόπηση όλων των αμφισβητήσεων συναλλαγών που απαιτούν απάντηση;",
						"answer": "Μπορείτε να βρείτε όλες τις αμφισβητήσεις συναλλαγών που απαιτούν απάντηση στην ενότητα Αμφισβητήσεις Συναλλαγών στη σελίδα «Ανοιχτές αμφισβητήσεις συναλλαγών» κάτω από την επικεφαλίδα «Απαιτείται απάντηση»."
					},
					"updateContactInformation": {
						"question": "Πώς μπορώ να ενημερώσω τα στοιχεία επικοινωνίας μου για email υπενθύμισης αμφισβήτησης συναλλαγής;",
						"answer": "1. Μεταβείτε στην ενότητα Αμφισβητήσεις Συναλλαγών\n2. Κάντε κλικ στη σελίδα «Ρυθμίσεις email και αμφισβητήσεων συναλλαγών»\n3. Ενημερώστε τα στοιχεία επικοινωνίας σας στην καρτέλα «Email και ειδοποιήσεις».\n\nΛάβετε υπόψη ότι μπορείτε να ορίσετε διαφορετικά στοιχεία επικοινωνίας για «αμφισβητήσεις συναλλαγών», «μη εξουσιοδοτημένες αγορές» και «παραγγελίες υψηλού κινδύνου». Επιπλέον, μπορείτε να ορίσετε τη συχνότητα των ειδοποιήσεων στην καρτέλα «Ειδοποιήσεις».\n"
					},
					"updateCustomerServiceOnKlarnaApp": {
						"question": "Πώς μπορώ να ενημερώσω τα στοιχεία Εξυπηρέτησης Πελατών μου στην εφαρμογή Klarna;",
						"answer": "1. Μεταβείτε στις «Ρυθμίσεις πληρωμής»\n2. Κάντε κλικ στα «Στοιχεία εξυπηρέτησης πελατών»\n3. Επιλέξτε «Αγορά» (αν έχετε υποστήριξη συγκεκριμένης αγοράς)\n\nΜπορείτε να καταχωρίσετε τα στοιχεία επικοινωνίας Πελατών, και να εμφανίζεται η πολιτική επιστροφών στην εφαρμογή Klarna για καλύτερη εμπειρία πελατών.\n\nΛάβετε υπόψη ότι χρειάζεστε πλήρη δικαιώματα πρόσβασης για την Πύλη Συνεργατών για την προβολή αυτών των στοιχείων.\n"
					},
					"improveDisputesPerformance": {
						"question": "Πώς μπορώ να βελτιώσω την απόδοσή μου ως προς τις αμφισβητήσεις συναλλαγών;",
						"answer": "Για να λάβετε συμβουλές και υποδείξεις για τον τρόπο βελτίωσης της απόδοσής σας ως προς τις αμφισβητήσεις συναλλαγών, μεταβείτε στον «Οδηγό βελτίωσης επιστροφών και αμφισβητήσεων συναλλαγών». Μπορείτε να τον προσπελάσετε μέσω της σελίδας «Στατιστικά» κάνοντας κλικ στο κουμπί «Λήψη ανάλυσης» στην επάνω δεξιά πλευρά της σελίδας.\n\nΛάβετε υπόψη ότι χρειάζεστε πλήρη δικαιώματα πρόσβασης για την Πύλη Συνεργατών για την προβολή αυτών των στοιχείων.\n"
					},
					"overviewDisputesPerformance": {
						"question": "Πού μπορώ να δω μια επισκόπηση της απόδοσής μου ως προς τις αμφισβητήσεις συναλλαγών;",
						"answer": "Για να δείτε λεπτομερή στατιστικά σχετικά με τις αμφισβητήσεις συναλλαγών, μεταβείτε στη σελίδα «Στατιστικά» στην εφαρμογή αμφισβητήσεων συναλλαγών. Αυτή η ενότητα παρέχει μια επισκόπηση των μηνιαίων αμφισβητήσεων συναλλαγών, εκβάσεων, εξόδων και ζημιών που σχετίζονται με αμφισβητήσεις συναλλαγών.\n\nΛάβετε υπόψη ότι χρειάζεστε πλήρη δικαιώματα πρόσβασης για την Πύλη Συνεργατών για την προβολή αυτών των στοιχείων.\n"
					}
				},
				"disputesLifecycle": {
					"title": "Διάρκεια αμφισβητήσεων συναλλαγών",
					"whenCanSeeDisputes": {
						"question": "Πότε μπορώ να δω τις αμφισβητήσεις συναλλαγών που υποβάλλονται από πελάτες;",
						"answer": "Μπορείτε να δείτε όλες τις αμφισβητήσεις συναλλαγών που υποβάλλονται στην εφαρμογή αμφισβητήσεων συναλλαγών στη σελίδα «Όλες οι αμφισβητήσεις συναλλαγών» αμέσως μετά την υποβολή τους."
					},
					"howLongToResolve": {
						"question": "Πόσο χρόνο έχω για να επιλύσω την αμφισβήτηση συναλλαγής απευθείας με τον πελάτη;",
						"answer": "Έχετε 21 ημέρες από την ημέρα υποβολής μιας αμφισβήτησης συναλλαγής για να την επιλύσετε με τον πελάτη. Αν εσείς και ο πελάτης δεν έχετε επιλύσει την αμφισβήτηση συναλλαγής εντός 21 ημερών από την υποβολή της, η Klarna θα παρέμβει για να υποστηρίξει τη διαδικασία επίλυσης μεταξύ των δύο μερών."
					},
					"howMuchTimeToRefund": {
						"question": "Πόσο χρόνο έχω για να δώσω επιστροφή χρημάτων για επιστροφές προϊόντων;",
						"answer": "Έχετε 21 ημέρες από την ημερομηνία επιστροφής για να επεξεργαστείτε μια επιστροφή χρημάτων προτού η Klarna παρέμβει για να υποστηρίξει τη διαδικασία επίλυσης μεταξύ των δύο μερών. Ωστόσο, είναι πάντα προτιμότερο να δίνετε επιστροφή χρημάτων για επιστροφές προϊόντων αμέσως μόλις τις λαμβάνετε."
					},
					"howToResolve": {
						"question": "Πώς μπορώ να επιλύσω αμφισβητήσεις συναλλαγών και επιστροφές προϊόντων;",
						"answer": "Υπάρχουν διάφοροι τρόποι επίλυσης μιας αμφισβήτησης συναλλαγής ή επιστροφής προϊόντων.\n\n- Επεξεργαστείτε την επιστροφή προϊόντων ή επιστροφή χρημάτων αμέσως μόλις τη λάβετε / συμφωνήσετε σε μια λύση.\n- Καθορίστε ένα όριο επιστροφής χρέωσης στις «Ρυθμίσεις email και αμφισβητήσεων συναλλαγών»\n- Να είστε προνοητικοί ελέγχοντας «Όλες τις αμφισβητήσεις συναλλαγών»\n- Να αποδεχτείτε τη ζημία\n"
					},
					"whenToRespond": {
						"question": "Πότε πρέπει να υπερασπιστώ ενεργά τη θέση μου/να απαντήσω σε αμφισβητήσεις συναλλαγών;",
						"answer": "Αν εσείς και ο πελάτης δεν μπορείτε να καταλήξετε μόνοι σας σε μια λύση εντός του δεδομένου χρονικού διαστήματος (21 ημέρες), η Klarna θα παρέμβει για να υποστηρίξει τη διαδικασία επίλυσης μεταξύ των δύο μερών. Αν η αμφισβήτηση συναλλαγής είναι έγκυρη, η ομάδα υποστήριξης της Klarna θα επικοινωνήσει μαζί σας μέσω της εφαρμογής αμφισβητήσεων συναλλαγών με ένα αίτημα για περισσότερες πληροφορίες.\n\nΣε αυτό το σημείο θα σας ζητηθεί να υπερασπιστείτε τη θέση σας/να απαντήσετε σε μια αμφισβήτηση συναλλαγής.\n"
					},
					"howMuchTimeToRespond": {
						"question": "Πόσο χρόνο έχω για να απαντήσω σε ένα αίτημα αμφισβήτησης συναλλαγής;",
						"answer": "Η προθεσμία για το πρώτο αίτημα είναι 14 ημέρες για όλους τους λόγους αμφισβήτησης συναλλαγής, με εξαίρεση τις μη εξουσιοδοτημένες αγορές (7 ημέρες). Για κάθε επακόλουθο αίτημα* που αποστέλλεται σε εσάς (με μέγιστο αριθμό 5 αιτημάτων) έχετε προθεσμία 7 ημερών. Αν χρειαστεί να προσθέσετε κάτι στην απάντησή σας, έχετε 60 λεπτά από την υποβολή για να προσθέσετε, για παράδειγμα, πληροφορίες ή συνημμένα. Μόλις περάσει η προθεσμία, δεν θα μπορείτε πλέον να υπερασπιστείτε τη θέση σας στην υπόθεση.\n\n*Ενδέχεται να ισχύουν ειδικοί κανόνες ανάλογα με τη συμφωνία συνεργασίας.\n"
					},
					"howLongToSeeDispute": {
						"question": "Για πόσο καιρό μπορώ να βλέπω μια αμφισβήτηση συναλλαγής στην εφαρμογή αμφισβήτησης συναλλαγών μετά την επίλυσή της;",
						"answer": "Μια αμφισβήτηση συναλλαγής είναι διαθέσιμη στη σελίδα «Όλες οι αμφισβητήσεις συναλλαγών» για έως 180 ημέρες μετά το κλείσιμο."
					},
					"whenToGetCharged": {
						"question": "Πότε χρεώνομαι με τέλος αμφισβήτησης συναλλαγής;",
						"answer": "Μπορεί να επιβαρυνθείτε με τέλος αμφισβήτησης συναλλαγής εάν:\n- Η αμφισβήτηση συναλλαγής οφείλεται σε δική σας υπαιτιότητα.\n- Παραλείψετε να απαντήσετε πριν από την προθεσμία.\n- Εκδώσετε επιστροφή χρημάτων αφότου η Klarna παρέμβει για να υποστηρίξει τη διαδικασία επίλυσης.\n\nΤο τέλος εφαρμόζεται αναδρομικά μετά τον καθορισμό της έκβασης της διαφοράς. Δεν χρεώνεστε εάν η αμφισβήτηση συναλλαγής επιλυθεί υπέρ σας ή εάν ακυρωθεί ή απορριφθεί.\n"
					}
				},
				"disputesHandling": {
					"title": "Διαχείριση αμφισβήτησης συναλλαγής",
					"whatIsDispute": {
						"question": "Ποιες πληροφορίες λαμβάνω με ένα αίτημα αμφισβήτησης συναλλαγής;",
						"answer": "Το αίτημα αμφισβήτησης συναλλαγής περιλαμβάνει:\n\n- Λόγος: Κατηγορία αμφισβήτησης συναλλαγής, π.χ. επιστροφές προϊόντων, μη παραλαβή αγαθών.\n- Έμπορος: Ισχύει εάν πολλά αναγνωριστικά εμπόρου (MID) έχουν καταχωρηθεί στην Klarna.\n- Ημερομηνία αμφισβήτησης συναλλαγής: Όταν ο πελάτης υπέβαλε την αμφισβήτηση συναλλαγής.\n- Προθεσμία: Η προθεσμία μέχρι την οποία πρέπει να απαντήσετε για να υπερασπιστείτε τη θέση σας στην υπόθεση.\n- Προϊόντα της αμφισβήτησης συναλλαγής: Τα προϊόντα που αποτελούν το αντικείμενο της αμφισβήτησης συναλλαγής.\n- Λεπτομέρειες παραγγελίας: Περιλαμβάνουν «αναφορά εμπόρου 1 και 2», «ημερομηνία παραγγελίας», «ποσό αμφισβήτησης συναλλαγής».\n"
					},
					"howManyTimesDefend": {
						"question": "Πόσες φορές μπορώ να υπερασπιστώ τη θέση μου σε ένα αίτημα αμφισβήτησης συναλλαγής;",
						"answer": "Μπορείτε να υπερασπιστείτε τη θέση σας για μια αμφισβήτηση συναλλαγής σε έως πέντε (5) αιτήματα, αλλά συνήθως δεν απαιτούνται πάνω από ένα ή δύο."
					},
					"doIReceiveNotification": {
						"question": "Λαμβάνω ειδοποιήσεις όταν πρέπει να απαντήσω σε αμφισβητήσεις συναλλαγών;",
						"answer": "Αν έχετε ένα αίτημα αμφισβήτησης συναλλαγής σε εκκρεμότητα, εμφανίζεται σε πολλές τοποθεσίες:\n\n- Στην ενότητα «Εκκρεμότητες» στην αρχική σας σελίδα της Πύλης Συνεργατών.\n- Ως ειδοποίηση στην ενότητα Αμφισβητήσεις Συναλλαγών στη σελίδα «Ανοιχτές αμφισβητήσεις συναλλαγών», υποδεικνύοντας τον αριθμό αμφισβητήσεων συναλλαγών που χρειάζονται απάντηση.\n\nΜπορείτε επίσης να επιλέξετε να λαμβάνετε email για κάθε αμφισβήτηση συναλλαγής προσαρμόζοντας τις ρυθμίσεις σας στις «Ρυθμίσεις email και αμφισβητήσεων συναλλαγών» σε «Ειδοποίηση email για κάθε αμφισβήτηση συναλλαγής».\n"
					},
					"howToAvoidDisputesFees": {
						"question": "Πώς μπορώ να αποφύγω τα τέλη αμφισβήτησης συναλλαγής;",
						"answer": "Για να αποφύγετε τα τέλη αμφισβήτησης συναλλαγής:\n- Να επεξεργάζεστε εγκαίρως τις επιστροφές προϊόντων ή επιστροφές χρημάτων.\n- Καθορίστε ένα όριο επιστροφής χρέωσης στις «Ρυθμίσεις email και αμφισβητήσεων συναλλαγών».\n- Να ελέγχετε τακτικά «Όλες τις αμφισβητήσεις συναλλαγών».\n- Να απαντάτε στις αμφισβητήσεις συναλλαγών με σχετικές πληροφορίες.\n- Να απαντάτε πριν λήξει η προθεσμία.\n"
					}
				}
			},
			"settlements": {
				"payoutNotFound": {
					"title": "Η αποπληρωμή για συγκεκριμένη παραγγελία δεν βρέθηκε",
					"orderNumberNotFound": {
						"question": "Ο αριθμός παραγγελίας ή ο αριθμός αναφοράς δεν εμφανίζεται στην αναφορά",
						"answer": "Αν δεν μπορείτε να βρείτε μια πληρωμή για μια συγκεκριμένη παραγγελία:\n- Επαληθεύστε την ακρίβεια του αριθμού παραγγελίας ή αναφοράς.\n- Βεβαιωθείτε ότι η παραγγελία έχει αποτυπωθεί.\n- Ελέγξτε αν η παραγγελία εμπίπτει στην περίοδο αποτύπωσης της αναφοράς.\n- Έχετε υπόψη ότι οι παραγγελίες που ακυρώθηκαν πριν από τον διακανονισμό δεν θα εμφανίζονται στην αναφορά.\n"
					},
					"checkIfPaidForOrder": {
						"question": "Πώς μπορώ να δω αν έχω πληρωθεί για μια συγκεκριμένη παραγγελία;",
						"answer": "Στη διαχείριση παραγγελιών της Πύλης Συνεργατών, αναζητήστε την παραγγελία αφού βεβαιωθείτε ότι ο αριθμός παραγγελίας ή αναφοράς είναι ακριβής. Κάτω από τον αριθμό αναφοράς, ελέγξτε αν η παραγγελία έχει αποτυπωθεί. Αν έχει αποτυπωθεί, θα εμφανίζεται η κατάσταση «σε εκκρεμότητα» ή «πληρωμένη». Για αποπληρωμένες παραγγελίες, κάντε κλικ στην «Προβολή διακανονισμού» για να δείτε την αντίστοιχη αναφορά."
					}
				},
				"payouts": {
					"title": "Εκταμιεύσεις",
					"whenIsNext": {
						"question": "Πότε είναι η επόμενη αποπληρωμή μου;",
						"answer": null
					},
					"whyCalculationNotMatch": {
						"question": "Γιατί ο δικός μου υπολογισμός δεν αντιστοιχεί στις αποπληρωμές που έλαβα;",
						"answer": "Σύμφωνα με τη σύμβαση εμπόρου, τα τέλη όπως τέλη συναλλαγής και καθυστερημένης επιστροφής αφαιρούνται. Κατά συνέπεια, οι υπολογισμοί σας μπορεί να διαφέρουν από τους διακανονισμούς της Klarna. Για να δείτε μια σαφή περιγραφή των μειώσεων, συνιστούμε να ανοίξετε την έκδοση PDF της αναφοράς διακανονισμού."
					},
					"whenGetPaid": {
						"question": "Πότε θα πληρωθώ;",
						"answer": null
					},
					"missingPayout": {
						"question": "Δεν έχω λάβει την αποπληρωμή μου",
						"answer": "Πριν επικοινωνήσετε με το κανάλι υποστήριξης. Λάβετε υπόψη ότι οι αποπληρωμές που λείπουν μπορεί να οφείλονται στους παρακάτω λόγους:\n\n- Η Klarna στέλνει την πληρωμή την ίδια ημέρα με τη δημιουργία της αναφοράς. Μπορεί να χρειαστούν λίγες ημέρες για να εμφανιστούν τα χρήματα στον τραπεζικό σας λογαριασμό.\n- Δεν έχετε αποτυπώσει παραγγελίες για αυτήν την περίοδο αποτύπωσης.\n- Ενημερώσατε πρόσφατα τα στοιχεία του τραπεζικού σας λογαριασμού στην Klarna, και ενδέχεται, ως αποτέλεσμα, η πληρωμή να πάει στον προηγούμενο τραπεζικό σας λογαριασμό. Αυτό μπορεί να συμβεί αν η αλλαγή δεν υποβληθεί σε επεξεργασία πριν από την εκτέλεση της πληρωμής.\n- Ο αριθμός παραγγελίας ή ο αριθμός αναφοράς που ψάχνετε είναι λανθασμένος. Φροντίστε να ελέγξετε τον αριθμό παραγγελίας ή αναφοράς.\n- Οι πληρωμές σας δεσμεύονται από την Klarna. Θα πρέπει να έχετε ενημερωθεί για τη δέσμευση ξεχωριστά μέσω email. Αυτά τα email αποστέλλονται συνήθως σε έναν διαχειριστή\n"
					},
					"howIsPayoutCalculated": {
						"question": "Πώς υπολογίζεται η αποπληρωμή μου;",
						"answer": "Η αποπληρωμή υπολογίζεται από τις παραγγελίες που έχουν αποτυπωθεί εντός της περιόδου αποτύπωσης μείον τα τέλη, τις επιστροφές προϊόντων και άλλες χρεώσεις. Η αναφορά διακανονισμού παρέχει μια λεπτομερή ανάλυση αυτών των ποσών.\nΑν οι επιστροφές χρημάτων υπερβαίνουν τις πωλήσεις σε μια περίοδο αποτύπωσης, το επιπλέον ποσό θα αφαιρεθεί από την επόμενη αποπληρωμή.\n"
					}
				},
				"howPayoutsWork": {
					"question": "Πώς λειτουργούν οι αποπληρωμές;",
					"answer": "Η ενότητα Διακανονισμοί παρέχει μια επισκόπηση του τρόπου λειτουργίας των αποπληρωμών, συμπεριλαμβανομένων των χρονοδιαγραμμάτων πληρωμών, των περιόδων αποτύπωσης και των ημερομηνιών αποπληρωμής. Μπορείτε να πραγματοποιήσετε λήψη των αναφορών συναλλαγών για κάθε αποπληρωμή σε μορφές PDF ή CSV και να προβάλετε ή να πραγματοποιήσετε λήψη των λεπτομερειών για συγκεκριμένες πληρωμές κάνοντας κλικ στις σχετικές αναφορές πληρωμής."
				},
				"payoutSchedule": {
					"title": "Πρόγραμμα αποπληρωμών",
					"whyDelay": {
						"question": "Γιατί υπάρχει καθυστέρηση αποπληρωμής;",
						"answer": "Ο κύριος σκοπός της είναι να αποφεύγεται η αποστολή χρημάτων για τιμολόγια μπρος πίσω μεταξύ της Klarna και της εταιρείας σας, καθώς και η κάλυψη περιπτώσεων που θα παρείχατε επιστροφή χρημάτων για μια αποτυπωμένη παραγγελία."
					},
					"changePayoutDelay": {
						"question": "Θέλω να αλλάξω την καθυστέρηση της αποπληρωμής μου",
						"answer": "Έχουμε εσωτερικούς ελέγχους, καθώς και γενικά κριτήρια που πρέπει να πληρούνται. Αυτά τα γενικά κριτήρια περιλαμβάνουν:\n- Πρέπει να έχετε ενσωματωθεί πλήρως στην Klarna\n- Η ημερομηνία της πρώτης σας παραγγελίας υπερβαίνει τις 100 ημέρες\n- Η επιχείρησή σας πρέπει να είναι άνω των 6 μηνών.\n\nΗ Klarna απαιτεί επίσης πληροφορίες από έναν διαχειριστή σχετικά με...\n- Την επιθυμητή νέα καθυστέρηση πληρωμής και γιατί η αλλαγή της καθυστέρησης πληρωμής είναι απαραίτητη\n- Ποιο αναγνωριστικό εμπόρου (MID) αφορά η αλλαγή\nΕνδέχεται να ισχύουν πρόσθετα κριτήρια, με τις αποφάσεις να λαμβάνονται σε ατομική βάση.\n"
					}
				},
				"generatingReports": {
					"title": "Δημιουργία αναφορών",
					"howToSeeReports": {
						"question": "Πώς μπορώ να δω τις αναφορές για συγκεκριμένες περιόδους;",
						"answer": "Μπορείτε να προσαρμόσετε τις αναφορές σας για να καλύπτουν συγκεκριμένες χρονικές περιόδους χρησιμοποιώντας την ενότητα Προσαρμοσμένες Αναφορές στην ενότητα Διακανονισμοί."
					},
					"convertCsvToExcel2": {
						"answer": "Ακολουθήστε αυτά τα βήματα\n1. Ανοίξτε το Excel και κάντε κλικ στο «Αρχείο» > «Νέο βιβλίο εργασίας».\n"
					},
					"convertCsvToExcel": {
						"question": "Πώς μπορώ να μετατρέψω μια αναφορά CSV σε υπολογιστικό φύλλο Excel;",
						"answer": "1. Ανοίξτε το Excel και κάντε κλικ στο «Αρχείο» > «Νέο βιβλίο εργασίας».\n2. Κάντε κλικ στην καρτέλα «Δεδομένα» και επιλέξτε «Από κείμενο».\n3. Επιλέξτε το επιθυμητό αρχείο .CSV και κάντε κλικ στη «Λήψη δεδομένων».\n4. Στον Οδηγό εισαγωγής κειμένου, επιλέξτε «Οριοθετημένο» και μετά κάντε κλικ στο «Επόμενο».\n5. Στους «Οριοθέτες», επιλέξτε «Ερωτηματικό» και ορίστε το «Προσδιοριστικό κειμένου» σε «κανένα». Κάντε κλικ στο «Τέλος».\n6. Στο πλαίσιο διαλόγου «Εισαγωγή δεδομένων», κάντε κλικ στο «OK» για να τοποθετήσετε τα δεδομένα ξεκινώντας από το κελί A1.\n\nΣημείωση: Οι αναφορές CSV στην Πύλη Εμπόρων χρησιμοποιούν ένα ερωτηματικό (;) ως διαχωριστικό. Σκεφτείτε να ελέγξετε το λογισμικό υπολογιστικών φύλλων σας για να μάθετε πώς να προσαρμόζετε το προεπιλεγμένο διαχωριστικό.\n"
					}
				},
				"payoutAndFeeCalculation": {
					"title": "Υπολογισμός αποπληρωμών και τελών",
					"howAreFeesCalculated": {
						"question": "Πώς υπολογίζονται τα τέλη;",
						"answer": "Τα τέλη που αφαιρούνται από τις αποπληρωμές της Klarna περιγράφονται στη σύμβαση εμπόρου σας, με τα πρόσθετα τέλη όπως αυτά για καθυστερημένες επιστροφές προϊόντων που αναφέρονται λεπτομερώς στην ενότητα «Τέλη» των αναφορών αποπληρωμών/διακανονισμών σας.\n\nΓια να προσαρμόσετε τις περιγραφές τελών στις αναφορές σας:\n1. Πλοηγηθείτε στις αναφορές Διακανονισμών > Διαμόρφωση αναφορών > Προσθήκη νέας διαμόρφωσης.\n2. Για τις αναφορές CSV, επιλέξτε «Λεπτομερής τύπος συναλλαγής» στη λίστα Συναλλαγών.\n3. Για τις αναφορές PDF, επιλέξτε «Συμπεριλάβετε τα στοιχεία της παραγγελίας» στις ρυθμίσεις διάταξης και περιεχομένου.\n\nΦροντίστε να προσαρμόσετε τις ρυθμίσεις για κάθε αναγνωριστικό εμπόρου και κανάλι (SFTP, Πύλη Εμπόρων, API) ξεχωριστά.\n"
					},
					"howIsPayoutCalculated": {
						"question": "Πώς υπολογίζεται η αποπληρωμή μου;",
						"answer": "Η αποπληρωμή υπολογίζεται από τις παραγγελίες που έχουν αποτυπωθεί εντός της περιόδου αποτύπωσης μείον τα τέλη, τις επιστροφές προϊόντων και άλλες χρεώσεις. Η αναφορά διακανονισμού παρέχει μια λεπτομερή ανάλυση αυτών των ποσών.\n\nΑν οι επιστροφές χρημάτων υπερβαίνουν τις πωλήσεις σε μια περίοδο αποτύπωσης, το επιπλέον ποσό θα αφαιρεθεί από την επόμενη αποπληρωμή.\n"
					}
				}
			},
			"options": {
				"payout": "Αποπληρωμή",
				"orders": "Παραγγελίες",
				"settlements": "Διακανονισμοί",
				"disputes": "Αμφισβητήσεις συναλλαγών",
				"otherIssue": "Άλλα προβλήματα",
				"yes": "Ναι",
				"no": "Όχι",
				"partially": "Μερική",
				"iNeedMoreHelp": "Χρειάζομαι περισσότερη βοήθεια",
				"thatHelped": "Αυτό βοήθησε",
				"contactSupportChannel": "Επικοινωνήστε με τα κανάλια υποστήριξης"
			},
			"button": {
				"label": "Λάβετε υποστήριξη"
			},
			"contact": {
				"chat": "Συνομιλήστε με έναν εκπρόσωπό μας",
				"ticket": "Υποβολή εισιτηρίου",
				"times": {
					"minutes": "Χρόνος αναμονής: {time} λεπτό(ά)",
					"hours": "Χρόνος αναμονής: {time} ώρα(ες)",
					"days": "Χρόνος αναμονής: {time} μέρα(ες)"
				}
			}
		}
	}
};