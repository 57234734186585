module.exports = {
	"da": {
		"home-fe": {
			"boosters-home-v03": {
				"install-plugin": {
					"stepNumber": "1",
					"title": "Installer programtilføjelse",
					"description": "Vælg din e-handelsplatform, så vi kan oprette et link for at sætte fart i din installation",
					"selectorLabel": "Vælg platform",
					"getPluginButtonLabel": "Få programtilføjelse",
					"klarnaDocsLinkLabel": "Få mere at vide på Klarna Docs"
				},
				"start-setup": {
					"stepNumber": "2",
					"title": "Aktivér programtilføjelse",
					"description": "Opret og kopiér kundeidentifikatoren og API-nøglen. Du skal bruge disse for at aktivere programtilføjelsen."
				},
				"promotion-banner": {
					"title": "Klarna for platforme",
					"subtitle": "Vækst din forretning med en enkelt programtilføjelse, der giver dig adgang til forskellige funktioner for øget salg og forbedrede handleoplevelser uden ekstra omkostninger",
					"banner-cards": {
						"osm-title": "Øg den gennemsnitlige ordreværdi med <span>meddelelser på netstedet</span>",
						"kec-title": "Få højere omsætning med ekspresbetaling",
						"siwk-title": "Tiltræk nye kunder med <span>indlogning med Klarna</span>"
					}
				},
				"configure-boost-features": {
					"stepNumber": "3",
					"title": "Tilpas placering af funktioner",
					"description": "Dette er et valgfrit trin, hvor du kan ændre, hvordan funktionerne ser ud i din butik. For at gøre dette skal du allerede have gennemført trin 2.",
					"no-credentials": "Sørg for først at oprette kundeidentifikatoren og API-nøglen for at fortsætte dette trin.",
					"apps": {
						"osm": {
							"link-label": "Tilpas meddelelser på netstedet",
							"description": "Tilpas meddelelser på netstedet, så de passer til dit mærke, ved at justere temaet og vælge placeringer"
						},
						"kec": {
							"link-label": "Tilpas ekspresbetaling",
							"description": "Tilpas ekspresbetalingskanppen, så den passer til dit mærke, ved at justere tema og form og vælge placering"
						},
						"siwk": {
							"link-label": "Opsæt indlogning med Klarna",
							"description": "Vælg omfanget for indlogning med Klarna, og tilføj en omdirigerings-URL. Omfanget er de oplysninger, der indsamles fra dine kunder, og omdirigerings-URL'en er det, der bruges, hvis pop op-vinduer blokeres af kunder"
						}
					}
				},
				"client-identifier-section": {
					"title": "Kundeidentifikator",
					"description": "Den unikke identifikator for opsætning",
					"manage-link": "Administrer kundeidentifikator og oprindelse",
					"generate-button": "Opret",
					"tooltip": "Opret og kopiér kundeidentifikatoren. Du skal bruge disse for at starte opsætningen af programtilføjelsen på din platforms portal."
				},
				"api-key": {
					"warning-banner": {
						"content": "Du har allerede aktive API-nøgler. Før du opretter nye, skal du aktivere alle dem, du ikke bruger."
					},
					"title": "Klarna API-nøgle",
					"description": "API-nøgle til opsætning",
					"tooltip": "Bemærk, at vi ikke viser API-nøglen igen, efter du opretter den.",
					"generate-button": "Opret",
					"manageApiKey": "Administrer API-nøgle"
				},
				"non-platform-integration": {
					"question": "Bruger du ikke Klarna til platforme?",
					"link": "Se hvordan, du kan integrere direkte med Klarna"
				}
			},
			"boosters-home": {
				"welcome-banner": {
					"title": "Åbn op for alle mulighederne med Klarna",
					"text": "Funktioner designet til at gøre besøgende til superkøbere. Gør hvert eneste trin af købsoplevelsen bedre – lige fra at gennemse til at betale."
				},
				"plugin-carousel": {
					"title": "Ingen kodeopsætning med en programtilføjelse",
					"overviewLink": "Se alle platforme",
					"directIntegrationLabel": "Bruger du ikke Klarna til platforme?",
					"directIntegrationLink": "Se hvordan, du kan integrere direkte med Klarna"
				},
				"booster-apps": {
					"title": "Få mere at vide",
					"kec-fe": {
						"title": "Gør betalingen 5x hurtigere",
						"description": "Færre klik betyder færre frafald. Øg konverteringen, og minimér de tilfælde, hvor indkøbskurven forlades, ved hjælp af express-betaling på produktsider og i indkøbskurven.",
						"callToAction": "Mere om Express-betaling"
					},
					"siwk-fe": {
						"title": "Socialt login til indkøb",
						"description": "De handlende får en hurtig og sikker login-mulighed med ét enkelt klik. Du får bedre data om kundernes profiler, som du kan handle ud fra.",
						"callToAction": "Mere om login med Klarna"
					},
					"upstream-fe": {
						"title": "Gør besøgende til handlende",
						"description": "Maksimér konverteringen med dynamiske og salgsfremmende beskeder gennem hele købsrejsen.",
						"callToAction": "Mere om beskeder på hjemmesiden"
					},
					"branding-fe": {
						"title": "Gør din butik mere synlig",
						"description": "Administrér dit brand i Klarna-appen ved at uploade et butikslogo, et ikon og en URL.",
						"callToAction": "Opsæt Brandmanager"
					}
				}
			},
			"boosters-plugins": {
				"title": "Opsætning af programtilføjelse",
				"description": "Få begyndt med vores programtilføjelser, og sørg for, at du er klar til at blive aktiveret."
			},
			"boosters-plugin": {
				"title": "Klarna for {pluginName}",
				"description": "Følg trinnene herunder for at aktivere betaling med Klarna og/eller forbedringsfunktioner med vores opsætning uden kode.",
				"access-warning": {
					"both": "Du har ikke tilladelse for {selectedStoreName} ({selectedMid}) til at oprette API-nøgler og kundeidentifikator. Kontakt din administrator for at få adgang til betalingsindstillinger for at fortsætte.",
					"client": "Du har ikke tilladelse for {selectedStoreName} ({selectedMid}) til at oprette en kundeidentifikator. Kontakt din administrator for at få adgang til betalingsindstillinger for at fortsætte.",
					"api": "Du har ikke tilladelse for {selectedStoreName} ({selectedMid}) til at oprette API-nøgler. Kontakt din administrator for at få adgang til betalingsindstillinger for at fortsætte."
				},
				"back": "Tilbage",
				"instructions": {
					"api-key": {
						"title": "Aktivér Klarna-betaling",
						"content": "Du skal oprette og kopiere API-nøgler, så du kan bruge dem under opsætningen.",
						"link-label": "Få mere at vide på Klarna Docs",
						"generate": "Opret API-nøgler",
						"active-warning": {
							"content": "Du har allerede aktive API-nøgler. Før du opretter nye, skal du aktivere alle dem, du ikke bruger.",
							"link": "Administrer API-nøgler"
						},
						"active-warning-api-keys": {
							"content": "Du har allerede aktive API-nøgler. Før du opretter nye, skal du aktivere alle dem, du ikke bruger.",
							"link": "Administrer API-nøgler"
						}
					},
					"client-id": {
						"title": "Aktivér forbedringsfunktioner",
						"content": "For at tilføje Klarnas ekspresbetaling og beskeder på hjemmesiden skal du oprette og kopiere din kundeidentifikator, så du kan bruge dem under opsætningen.",
						"generate": "Opret kundeidentifikator",
						"manage": "Administrer kundeidentifikator"
					},
					"plugin-doc": {
						"title": "Konfigurer forbedringsfunktioner",
						"content": "For at afslutte din opsætning skal du konfigurere Klarnas ekspresbetaling og beskeder på hjemmesiden i din platforms indstillinger."
					}
				},
				"error-messages": {
					"default": "Der opstod en fejl. Prøv igen senere, eller gå til hjælp.",
					"fetch-data": "Der opstod en fejl under hentning af dine data. Prøv igen senere, eller gå til hjælp.",
					"create-api-key": "Der opstod en fejl under oprettelsen af dine API-nøgler. Prøv igen senere, eller gå til hjælp.",
					"create-client-id": "Der opstod en fejl under oprettelsen af din kundeidentifikator. Prøv igen senere, eller gå til hjælp."
				}
			},
			"marketing-home": {
				"description": "Velkommen til vores markedsføringsprodukter. Kom i gang med at udforske nedenfor.",
				"essentials": {
					"title": "Markedsføring",
					"cta-label": "Få mere at vide",
					"branding-fe": {
						"description": "Opsæt og administrer dit mærke. Lad dine kunder se dig i det bedste lys i Klarnas økosystem inklusive vores gnidningsfri app."
					},
					"klarna-search-b2b-portal": {
						"description": "Kom godt i gang med Klarnas kraftfulde søgemaskine og prissammenligning. Få adgang til statistik og værktøjer, der er beregnet til at forbedre din synlighed."
					}
				},
				"external-apps": {
					"title": "Apps",
					"cta-label": "Åbn appen"
				},
				"popOvers": {
					"searchCompare": {
						"title": "Kom godt i gang med Søg og sammenlign",
						"content": "Se her for statistik og værktøjer, der kan hjælpe med at øge din synlighed på Klarnas prissammenligningstjeneste."
					}
				}
			},
			"no-apps": {
				"title": "Ingen apper er tilgængelige!",
				"subtitle": "Kontakt din administrator for at få hjælp med at få adgang til de apper, du har brug for."
			},
			"contact": {
				"title": "Har du brug for hjælp?",
				"paragraph": "Hvis du har spørgsmål, skal du ikke tøve med at henvende dig. Vi hjælper gerne med at besvare dem.",
				"label": "Kontakt os"
			},
			"important-banner": {
				"title": "",
				"content": "",
				"linkLabel": ""
			},
			"two-factor-home-banner": {
				"banner-text": "Gør din konto mere sikker med tofaktorautentisering",
				"banner-link": "Sæt det op"
			},
			"two-factor-opt-in": {
				"title": "Sæt op tofaktorautentisering",
				"intro-paragraph": "For at øge din kontos sikkerhed anbefaler vi at du opsæter tofaktorautentisering i dag.",
				"help-link-text": "Klik her",
				"help-text": " for at lære mere om, hvordan du opsætter det, og hvordan det beskytter dig.",
				"warning-text": "Sørg for, at du har din smartphone og adgang til internettet, inden du fortsætter",
				"continue-button": "Start opsætningen"
			},
			"accept-invite-modal": {
				"title": "Accepter afventende invitationer",
				"description": "Du er blevet inviteret til at få adgang til følgende butikker. Bemærk, at det kan tage nogle minutter, før dine valg træder i kraft.",
				"accept-all-button": "Accepter alle",
				"decline-all-button": "Afvis alle",
				"save-button": "Gem",
				"error": "Der opstod et problem. Prøv igen senere!",
				"invite": {
					"accept-label": "Accepter",
					"decline-label": "Afvis"
				}
			},
			"capture-orientation": {
				"title": "Er det første gang, du bruger Klarna?",
				"sub-title": "Følg disse simple trin for at komme i gang:",
				"order-label": "Modtag en ordre",
				"capture-label": "Aktivér en ordre",
				"payout-label": "Få betaling",
				"capture-cta": "Aktivér denne ordre",
				"payout-cta": "Appen Afregninger",
				"dismiss-cta": "Afvis",
				"order-step": "Før alt andet bør vi afvente en indkommende ordre fra dine kunder.",
				"capture-step": "Tillykke med din første ordre.\nFor at få din betaling er det næste trin at sende og aktivere din ordre.",
				"payout-step": "Tillykke med aktiveringen af din nye ordre.\nDu vil nu modtage en udbetaling fra os i overensstemmelse med tidsfristerne i din Klarna-aftale.\nFor yderligere oplysninger om din første udbetaling bedes du gå til",
				"completed-step": "Tillykke med din første udbetaling.\nVelkommen til en smuk rejse med Klarna.\nDu er velkommen til at se på de andre apper vi tilbyder herunder."
			},
			"banner": {
				"common": {
					"branding-fe": {
						"content": "Vi har lige lanceret vores nye brandingapp!",
						"linkLabel": "Tag kontrol over dit brand nu!"
					},
					"onSiteMessaging": {
						"title": "Gør besøgende til handlende med beskeder på hjemmesiden",
						"text": "Købet begynder, inden dine kunder når kurven. Med beskeder på webstedet øger du tidligt i salgsprocessen bevidstheden om Klarnas fleksible betalingsmuligheder og andre fordele.",
						"cta": "Tjek det ud"
					}
				}
			},
			"nko": {
				"dialog": {
					"title": "Vi lancerer nogle spændende funktioner for din forretning.",
					"subtitle": "Og vi vil gerne lære at kende dig bedre.",
					"explore": "Ja, lad os se på det her.",
					"name-field": "Hvad må vi kalde dig?",
					"terms-pre": "Jeg har læst og accepterer",
					"terms-text": "vilkårene og betingelserne"
				}
			},
			"insightsWidget": {
				"title": "Salg i dag",
				"orderCount": "Oprettede ordrer",
				"orderVolume": "Ordremængde",
				"orderVolumeAverage": "Gennemsnitlig ordreværdi",
				"currencyFootnote": "Vist i {currency}",
				"link": "Se bestillinger",
				"allStores": "Alle butikker",
				"selectAllOptionText": "Vælg alle",
				"deselectAllOptionText": "Slet valg",
				"closeText": "Udført",
				"closeTextWithSelection": "Anvend # valg",
				"elapsedTime": "Sidste ordre modtaget for {hours, plural, =0 {} =1 {en time} other {# timer}} {minutes, plural, =0 {} =1 {et minut} other {# minutter}} siden",
				"error": {
					"content": "Desværre blev din ordrestatistik ikke indlæst korrekt."
				},
				"relativeDate": {
					"today": "I dag",
					"yesterday": "I går"
				},
				"weekDays": {
					"1": "Mandag",
					"2": "Tirsdag",
					"3": "Onsdag",
					"4": "Torsdag",
					"5": "Fredag",
					"6": "Lørdag",
					"7": "Søndag"
				}
			},
			"blackFridayWidget": {
				"title": "Black friday-ugen er her.",
				"text": "Følg din ordrestatistik i den nærmeste fremtid.",
				"button": "Se statistik"
			},
			"tasks": {
				"title": "Huskeliste",
				"error": {
					"title": "Vi kunne ikke indlæse huskelisten",
					"desc": "Genindlæs siden, så vi kan prøve igen."
				},
				"empty": {
					"title": "Du er klar!",
					"desc": "Du skal ikke gøre noget lige nu."
				},
				"single": {
					"products-fe:complete-recollection": "Obligatorisk AML-formular skal udfyldes for din forretning for at hindre hvidvaskning",
					"products-fe:resume-onboarding": "Gennemfør din opstartsperiode",
					"settings-fe:compile-cs-info": "Tilføj dine kundeserviceoplysninger"
				},
				"aggregated": {
					"disputes-fe:unresolved": "{ count, plural, =1 {1 uløst tvist} other {# uløste tvister}}",
					"orders-fe:expiring": "{ count, plural, =1 {1 udløbende ordre} other {# udløbende ordrer}}",
					"orders-fe:tocapture": "{ count, plural, =1 {1 ordre til aktivering} other {# ordrer til aktivering}}",
					"orders-fe:uncaptured": "{ count, plural, =1 {1 ikke aktiveret ordre} other {# ikke aktiverede ordrer}}",
					"users-fe:mid-added": "{ count, plural, =1 {1 ny butik er blevet tilføjet} other {# nye butikker er blevet tilføjet}}",
					"users-fe:mid-invited": "{ count, plural, =1 {1 butiksinvitation afventer handling} other {# butiksinvitationer afventer handling}}"
				}
			}
		}
	}
};