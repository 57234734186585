import { ButtonSecondary, Link, Typography } from '@klarna/bubble-ui';
import { hooks as i18nHooks } from 'mage-i18n';
import insights, { TrackImpression } from 'mage-insights';
import React from 'react';
import styled from 'styled-components';
import { useMid } from '../../hooks/useMid';
import { usePlugin } from '../../hooks/usePlugin';
const PluginsContent = styled.div.withConfig({ displayName: "PluginsContent", componentId: "sc-1v3ihin" }) `
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-items: center;
  padding-top: 16px;
`;
const KlarnaDocsLinkLabel = styled.div.withConfig({ displayName: "KlarnaDocsLinkLabel", componentId: "sc-12lhy96" }) `
  text-decoration-line: underline;
  display: flex;
  padding-left: 16px;
  align-items: center;
`;
export default function GetPluginAndKlarnaDocsLink() {
    const t = i18nHooks.useTranslator();
    const { selectedMid } = useMid();
    const { pluginId, pluginDocUrl, pluginLink } = usePlugin();
    const getPluginButtonAndDocsImpressionEvent = {
        category: 'v3/boost-home/install-plugin/get-plugin-and-docs',
        action: 'impression',
        label: selectedMid !== null && selectedMid !== void 0 ? selectedMid : undefined
    };
    const getPluginButtonClickEvent = {
        category: 'v3/boost-home/install-plugin/get-plugin',
        action: 'click',
        label: selectedMid !== null && selectedMid !== void 0 ? selectedMid : undefined
    };
    const klarnaDocsLinkClickEvent = {
        category: 'v3/boost-home/install-plugin/klarna-docs',
        action: 'click',
        label: selectedMid !== null && selectedMid !== void 0 ? selectedMid : undefined
    };
    const onClickKlarnaDocsLink = () => {
        insights.event(klarnaDocsLinkClickEvent);
    };
    const onClickGetPluginButton = () => {
        window.open(pluginLink, '_blank');
        insights.event(getPluginButtonClickEvent);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(PluginsContent, null,
            React.createElement(TrackImpression, { event: getPluginButtonAndDocsImpressionEvent },
                React.createElement(ButtonSecondary, { size: 'medium', onClick: onClickGetPluginButton }, t('home-fe.boosters-home-v03.install-plugin.getPluginButtonLabel'))),
            React.createElement(KlarnaDocsLinkLabel, null,
                React.createElement(Link, { id: pluginId, onPress: onClickKlarnaDocsLink, href: pluginDocUrl, target: '_blank' },
                    React.createElement(Typography, { textToken: "text-style/text/paragraphs/body/regular" }, t('home-fe.boosters-home-v03.install-plugin.klarnaDocsLinkLabel')))))));
}
