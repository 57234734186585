module.exports = {
	"cs": {
		"help-tool": {
			"button-text": "Podpora",
			"support-button": "Získejte podporu",
			"support-page": {
				"create-ticket": {
					"title": "Získejte podporu",
					"instructions": "Odešlete níže uvedenou žádost a my se vám ozveme do 1 pracovního dne.",
					"related-content": {
						"title": "Mohou vám pomoci vyřešit váš problém."
					},
					"fields": {
						"mid": {
							"label": "Vybrat obchod",
							"validation": "Obchod je povinný údaj."
						},
						"phoneNumber": {
							"label": "Telefonní číslo (nepovinné)"
						},
						"topic": {
							"question": "Čeho se vaše otázka týká?",
							"label": "Vyberte téma",
							"validation": "Téma je povinný údaj.",
							"options": {
								"orders": "Objednávky",
								"settlements": "Vypořádání",
								"agreements": "Smlouvy",
								"others": "Jiné"
							}
						},
						"subject": {
							"label": "Předmět",
							"validation": "Předmět je povinný údaj."
						},
						"description": {
							"label": "Zpráva",
							"validation": "Popis je povinný údaj."
						},
						"attachment": {
							"label": "Připojit přílohy",
							"button": "Nahrát",
							"info": "Snímky obrazovky nám tento problém pomohou vyřešit rychleji.",
							"supportedFileTypes": "Typy podporovaných souborů: .png, .jpg, .pdf",
							"error": "Maximální velikost všech souborů je {maxSize} MB."
						}
					},
					"submit": "Odeslat žádost",
					"service-unavailable-error": "Jejda! Něco se nepovedlo, zkuste to znovu za pár minut. V případě pokračování, požádejte o pomoc na {link}.",
					"generic-error": "Vaši žádost se nám nepodařilo zpracovat. Naše systémy spolu momentálně nekomunikují. Zkuste obnovit stránku, nebo se vraťte později. V případě pokračování, požádejte o pomoc na {link}.",
					"contact-link": "kontaktujte nás"
				},
				"view-ticket": {
					"title": "Vše je hotovo!",
					"details": "Vaši žádost jsme obdrželi a odpověď vám zašleme do 1 pracovního dne. Zde jsou detaily:",
					"ticket": "Žádost #{ticketId}",
					"email": "E-mail: {email}",
					"status": "Stav žádosti můžete sledovat na stránce naší podpory.",
					"track": "Sledovat stav"
				}
			},
			"header": {
				"homeLink": "Resetovat"
			},
			"home": {
				"greeting": "Dobrý den, {givenName}! Jak vám můžeme pomoci?",
				"topics": "S čím potřebujete pomoct?"
			},
			"topics": {
				"expandButton": "Více"
			},
			"feedback": {
				"title": "Pomohlo vám to?",
				"optionYes": "Ano, pomohlo mi to",
				"optionNo": "Ne, potřebuji další pomoc",
				"positiveFeedback": "Děkujeme vám za kladnou zpětnou vazbu. Pomůže nám to, abychom vám v budoucnu poskytovali více relevantních článků."
			},
			"freeTextFeedback": {
				"title": "Pomozte nám zlepšovat se",
				"placeholder": "Proč tento obsah nepovažujete za užitečný?",
				"submitButton": "Odeslat",
				"feedbackMessage": "Děkujeme vám za zpětnou vazbu. Pomůže nám to, abychom vám v budoucnu poskytovali více relevantních článků."
			},
			"relatedContent": {
				"title": "Související obsah"
			},
			"search": {
				"buttonLabel": "Hledat",
				"input": "Hledat články…",
				"loading": "Nahrávání…",
				"results": {
					"contact": "Nedaří se vám najít potřebnou nápovědu?",
					"empty": "Litujeme, ale k vašemu dotazu nebyly nalezeny žádné výsledky. Zkuste zadat jiný dotaz.",
					"singleTopicFound": "Našli jsme 1 téma",
					"multipleTopicsFound": "Počet nalezených témat: {count}",
					"singleArticleFound": "Našli jsme 1 článek",
					"multipleArticlesFound": "Počet nalezených článků: {count}"
				}
			},
			"contact": {
				"title": "Kontaktujte nás",
				"channelsIntro": "Obraťte se na nás, abychom vám mohli pomoci.",
				"businessDetailsTitle": "Vaše obchodní údaje",
				"businessDetailsName": "Obchodní jméno",
				"businessDetailsEmail": "E-mail",
				"businessDetailsMid": "ID obchodníka",
				"phone": {
					"title": "Zavolejte nám",
					"paragraph": "Můžete nám zavolat během naší provozní doby ve všední dny. Pokud se nacházíte ve Švédsku nebo ve Spojeném království, můžete nám také zavolat o víkendech od 10:00 do 16:00."
				},
				"email": {
					"title": "Pošlete nám e-mail",
					"paragraph": "Napište nám e-mail a my se vám co nejdříve ozveme."
				}
			},
			"errorBoundary": {
				"title": "Něco se pokazilo",
				"message": "Je nám to moc líto, ale vyskytla se chyba. Vraťte se do nástroje nápovědy tak, že níže kliknete na tlačítko „zpět“, nebo nás kontaktujte pomocí tlačítka „získat podporu“.",
				"resetButton": "Zpět",
				"errorId": "ID chyby: {errorId}"
			},
			"tour": {
				"prev": "Předchozí",
				"next": "Další",
				"done": "Hotovo"
			},
			"notAvailableMobile": {
				"title": "Není k dispozici",
				"buttonBack": "Zpět",
				"paragraph": "Tento obsah není na vašem zařízení dostupný. Zkuste jej otevřít na zařízení s větší obrazovkou, například na počítači."
			},
			"shareButton": {
				"title": {
					"article": "Sdílet odkaz na tento článek",
					"topic": "Sdílet odkaz na toto téma",
					"tour": "Sdílet odkaz na tuto prohlídku"
				},
				"radioItemHome": "Domovská stránka aplikace",
				"descriptionHome": "Otevře článek s nápovědou na domovské stránce portálu obchodníka",
				"radioItemCurrent": "Aktuální stránka",
				"descriptionCurrent": "Otevře článek s nápovědou na aktuální stránce"
			},
			"marketSelector": {
				"whichMarketToContact": "Který trh byste chtěli kontaktovat?"
			}
		},
		"orientation": {
			"title": "Zjistěte, jak…"
		},
		"helpbot": {
			"questions": {
				"whatDoYouNeedHelpWith": "Vážená paní / Vážený pane, s čím dnes potřebujete pomoc?",
				"selectSupportChannel": "Vyberte prosím postup kontaktování našich služeb podpory.",
				"thatHelped": "Děkujeme vám za kladnou zpětnou vazbu. Pomůže nám, abychom vám v budoucnu poskytovali relevantnější pomoc. 😀"
			},
			"orders": {
				"orderManagement": {
					"title": "Správa objednávek",
					"createOrderPos": {
						"question": "Jak vytvořím objednávku prostřednictvím prodejního místa?",
						"answer": "1. Otevřete prodejní místo 2. Zadejte název výrobku, cenu a množství. 3. Sdílejte vygenerovaný odkaz na platbu se zákazníkem prostřednictvím SMS, e-mailu nebo tak, že zákazník naskenuje QR kód zobrazený na obrazovce pokladny. 4. Zákazník nyní může dokončit nákup ze svého zařízení."
					},
					"extendExpiryReservation": {
						"question": "Jak mám prodloužit datum vypršení platnosti rezervace?",
						"answer": "1. Přejděte do správce objednávek a vyberte jednu nebo více objednávek, u kterých chcete prodloužit datum vypršení platnosti. 2. Klikněte na Prodloužit datum konce platnosti. 3. Svou volbu potvrďte.\nObjednávky se prodlužují podle nastavení doby rezervace pro váš obchod (obvykle 28 dní). Pokud platnost objednávky vypršela, nelze ji již prodloužit."
					},
					"howToCaptureOrders": {
						"question": "Jak mám zaznamenat objednávky?",
						"partially": {
							"question": "Chci částečně zaznamenat objednávku.",
							"answer": "Pokud je možné odeslat pouze část objednávky zákazníka, můžete objednávku částečně zaznamenat podle řádků objednávky nebo částky. Zaznamenání řádků objednávky 1 Přejděte na objednávku v nástroji Správce objednávek a na stránce objednávky vyberte položky, které máte aktuálně na skladě. V případě potřeby nezapomeňte upravit řádky objednávky a množství. 2. Klikněte na Zaznamenat. Zaznamenání částky: 1. Přejděte na objednávku v nástroji Správce objednávek. Množství můžete zaznamenat kliknutím na tlačítko Zaznamenat vše. Zde zadejte požadovanou částku. 2. Klikněte na Zaznamenat.\nNezapomeňte objednávku dokončit, pokud je to možné, a to buď zaznamenáním zbývajících položek/množství, které jsou k dispozici pro odeslání, nebo zrušením zbývající objednávky, pokud nebudete moci zboží odeslat."
						},
						"fully": {
							"question": "Chci plně zaznamenat objednávky",
							"answer": "1. Přejděte do nástroje Správce objednávek a kliknutím na referenční číslo Klarna otevřete objednávku. 2. Celou objednávku zaznamenejte pomocí jednoho z následujících kroků: - Klikněte na tlačítko Zaznamenat – vyberte řádky objednávky a klikněte na Zaznamenat. - Klikněte na Zaznamenat vše a zadejte celou částku objednávky. 3. Při zaznamenání objednávky si můžete stáhnout balicí list a přiložit jej k zásilce.\nDbejte na to, abyste objednávky zaznamenali před vypršením jejich platnosti (obvykle 28 dní od vytvoření objednávky). Pokud si chcete urychlit práci, můžete zaznamenat více objednávek, které vyberete v seznamu objednávek, a kliknout na možnost Úplné zaznamenání."
						}
					},
					"canIUpdateCustomerInfo": {
						"question": "Můžu aktualizovat informace o zákazníkovi?",
						"answer": "Příjemce objednávky nelze nikdy změnit. U nezaznamenaných nebo částečně zaznamenaných objednávek je však možné změnit dodací a fakturační adresu. Pokud však Klarna novou adresu odmítne, protože není spojena se zákazníkem, může být fakturační adresa přesto změněna."
					},
					"extendDueDate": {
						"question": "Jak se dá prodloužit datum splatnosti objednávky?",
						"answer": "Datum splatnosti objednávky můžete prodloužit kliknutím na Prodloužený konečný termín v zaznamenané objednávce. Prodloužení data splatnosti objednávky je spojeno s poplatkem, který bude upřesněn před dokončením akce."
					},
					"refundOrder": {
						"question": "Jak mohu objednávku refundovat?",
						"answer": "1. Přejděte do nástroje Správce objednávek a vyhledejte objednávku, kterou chcete refundovat. 2. Zvolte způsob refundace: buď vyberte zboží, které chcete refundovat, a poté klikněte na Refundovat, nebo nejprve klikněte na Refundovat a zadejte částku, kterou chcete refundovat. 3. Refundovaná částka bude odečtena z příští výplaty a zákazník ji obdrží do 5 pracovních dnů.\nTo platí pouze pro zaznamenané objednávky. U nezaznamenaných objednávek je zrušte, pokud nebudete odesílat žádné položky. Pokud byla zaznamenána a dodána pouze část objednávky, vydejte nezaznamenanou část."
					},
					"cancelOrder": {
						"question": "Jak mám zrušit objednávku?",
						"answer": "1. Přejděte do nástroje Správce objednávek a vyhledejte objednávku, kterou chcete zrušit. 2. Klikněte na Zrušit objednávku. Tato akce také informuje zákazníka, že objednávka nebude zaslána.\nV případě potřeby můžete zrušené objednávky obnovit. Zaznamenané objednávky nelze zrušit, ale můžete je refundovat."
					},
					"unableToTakeActions": {
						"question": "Proč nemohu provést akce na objednávce?",
						"directPartner": {
							"question": "Mám dohodu přímo se společností Klarna.",
							"answer": "Uživatelská oprávnění určují, jaké akce mohou uživatelé provádět na portálu pro partnery. Existují dva různé typy uživatelských oprávnění: Správce – uživatelé s úplným přístupem, jako je například správce, mají přístup ke všem funkcím portálu pro partnery a mohou je upravovat. Specifické podle oddílu – uživatelé, kteří nemají oprávnění správce, mají přístup k jednomu nebo více konkrétním oddílům portálu pro partnery.\nTo může být příčinou toho, že nemůžete provádět objednávky. Existují různé přístupové role týkající se nástroje Správce objednávek. Abyste zjistili, která přístupová role je nejvhodnější pro vámi požadované akce, poraďte se se správcem."
						},
						"distributionPartner": {
							"question": "Mám dohodu přímo se společností Klarna.",
							"answer": "Veškeré akce, které chcete v souvislosti s objednávkou provést, lze provádět pouze prostřednictvím platformy poskytované distribučním partnerem."
						}
					}
				},
				"orderModification": "Změna objednávky a problémy s ní",
				"orderProcessing": "Zpracování objednávky",
				"refundIssues": {
					"title": "Problémy s refundací",
					"refundedOrderWhenCustomerFunds": {
						"question": "Vrátil(a) jsem peníze za objednávku, kdy zákazník obdrží peníze?",
						"answer": "Zákazník by měl obdržet finanční prostředky do 5 pracovních dnů od vrácení peněz za objednávku. To se může lišit v závislosti na způsobu platby, který si zákazník zvolil. V případě dotazů se zákazník může obrátit na zákaznickou podporu společnosti Klarna."
					}
				},
				"reservations": {
					"title": "Rezervace",
					"whyUnableToExtendReservation": {
						"question": "Proč se mi nedaří u objednávky prodloužit dobu rezervace?",
						"answer": "Existují specifické okolnosti, kdy prodloužení doby platnosti příkazu není možné:\n- Pokud platnost objednávky již vypršela, budete ji muset vytvořit znovu. Za tímto účelem přejděte na stránku objednávky, klikněte na Kopírovat a vyberte Vytvořit novou objednávku.\n- Pokud byla objednávka zaplacena kartou, musí zákazník zadat novou objednávku, protože data konce platnosti těchto objednávek nelze prodloužit.\n"
					}
				},
				"paymentsForMyOrders": {
					"title": "Platby za mé objednávky",
					"whenWillIGetPaid": {
						"question": "Kdy dostanu za objednávku zaplaceno?",
						"answer": "Pokud chcete obdržet platbu za objednávku, musíte ji nejprve zaznamenat. Výplata bude po odečtení poplatků, vratek a dalších srážek zaslána na váš bankovní účet na základě vašeho plánu plateb. Nastavení výplaty si můžete prohlédnout v sekci Vypořádání."
					},
					"refundOrderReceivedPayment": {
						"question": "Co se stane, když vrátím peníze za objednávku, za kterou jsem již obdržel(a) peníze?",
						"answer": "Případná refundovaná částka bude odečtena od příští plánované výplaty."
					},
					"referenceNotShowUp": {
						"question": "Číslo objednávky nebo referenční číslo se v sestavě nezobrazuje.",
						"answer": "Pokud nemůžete najít platbu za konkrétní objednávku, postupujte takto:\n- Ověřte přesnost objednávky nebo referenční číslo.\n- Ujistěte se, že je objednávka zaznamenaná.\n- Zkontrolujte, zda objednávka spadá do období zaznamenání sestavy.\n- Vezměte na vědomí, že objednávky zrušené před vypořádáním se v sestavě nezobrazí.\n"
					},
					"gotPaidSpecificOrder": {
						"question": "Jak zjistím, zda jsem dostal(a) zaplaceno za konkrétní objednávku?",
						"answer": "V nástroji Správce objednávek na portálu pro partnery vyhledejte objednávku a ujistěte se, že číslo objednávky nebo referenční číslo je správné. Pod referenčním číslem zkontrolujte, zda je objednávka zaznamenána. Pokud je zaznamenaná, stav se ukáže jako čekající nebo zaplaceno. U zaplacených objednávek zobrazte příslušnou sestavu kliknutím na Zobrazit vypořádání."
					}
				}
			},
			"disputes": {
				"disputeManagement": "Správa sporů",
				"contactInfoAndNotifications": "Kontaktní údaje a nastavení upozornění",
				"timingAndDeadlines": "Načasování a konečné termíny sporů",
				"resolutionProcess": "Proces řešení sporů",
				"merchantDisputesApp": {
					"title": "Aplikace Disputes pro obchodníky",
					"respondToDispute": {
						"question": "Jak zjistím, zda musím reagovat na žádost o spor?",
						"answer": "V části Úkoly na domovské stránce portálu pro partnery zkontrolujte, zda neexistují nějaké spory vyžadující vaši reakci."
					},
					"overviewDisputesCustomer": {
						"question": "Kde najdu přehled všech sporů vznesených zákazníky?",
						"answer": "Všechny vznesené spory najdete v sekci Spory na stránce Všechny spory bezprostředně po vznesení sporu.\n\nUpozorňujeme, že spory jsou k dispozici na stránce Všechny spory až 180 dní po uzavření.\n"
					},
					"overviewDisputesResponse": {
						"question": "Kde najdu přehled všech sporů, které vyžadují reakci?",
						"answer": "Všechny spory, na které je třeba reagovat, najdete v sekci Spory na stránce Otevřené spory v části Požadovaná odpověď."
					},
					"updateContactInformation": {
						"question": "Jak můžu aktualizovat své kontaktní údaje pro e-maily připomenutí sporu?",
						"answer": "1. Přejděte do části Spory.\n2. Klikněte na stránku Nastavení e-mailu a sporů\n3. Aktualizujte své kontaktní údaje v části E-mail a upozornění.\n\nVezměte prosím na vědomí, že můžete nastavit různé kontaktní údaje pro spory, neautorizované nákupy a rizikové objednávky. Dále můžete na kartě Upozornění nastavit četnost upozornění.\n"
					},
					"updateCustomerServiceOnKlarnaApp": {
						"question": "Jak můžu aktualizovat informace o zákaznickém servisu v aplikaci Klarna?",
						"answer": "1. Přejděte na Nastavení plateb.\n2. Klikněte na informace o zákaznickém servisu\n3. Vyberte Trh (pokud máte podporu specifickou pro daný trh)\n\nV aplikaci Klarna můžete zadat kontaktní údaje zákazníka a také zásady pro vracení zboží, které se budou zobrazovat v aplikaci Klarna, aby se zákazníkům lépe pracovalo.\n\nUpozorňujeme, že pro zobrazení těchto informací potřebujete plná přístupová práva k portálu pro partnery.\n"
					},
					"improveDisputesPerformance": {
						"question": "Jak můžu zlepšit svou výkonnost při řešení sporu?",
						"answer": "Chcete-li získat tipy a triky, jak zlepšit výkonnost při řešení sporů, přejděte na stránku Průvodce zlepšením vracení zboží a řešení sporů. Přístup k němu získáte na stránce Statistiky kliknutím na tlačítko Získat analýzu v pravém horním rohu stránky.\n\nUpozorňujeme, že pro zobrazení těchto informací potřebujete plná přístupová práva k portálu pro partnery.\n"
					},
					"overviewDisputesPerformance": {
						"question": "Kde si můžu prohlédnout přehled výkonnosti mých sporů?",
						"answer": "Pokud chcete zobrazit podrobné statistiky o sporech, přejděte na stránku Statistika v aplikaci Disputes. Tato část obsahuje přehled měsíčních sporů, výsledků, nákladů a ztrát souvisejících se spory.\n\nUpozorňujeme, že pro zobrazení těchto informací potřebujete plná přístupová práva k portálu pro partnery.\n"
					}
				},
				"disputesLifecycle": {
					"title": "Životní cyklus sporu",
					"whenCanSeeDisputes": {
						"question": "Kdy se můžu podívat na spory vznesené zákazníky?",
						"answer": "Všechny spory vyvolané v aplikaci Disputes si můžete prohlédnout na stránce Všechny spory ihned po jejich vyvolání."
					},
					"howLongToResolve": {
						"question": "Kolik času mám na vyřešení sporu přímo se zákazníkem?",
						"answer": "Na vyřešení sporu se zákazníkem máte 21 dní ode dne, kdy byl spor vznesen. Pokud se vám a zákazníkovi nepodaří spor vyřešit do 21 dnů od jeho vznesení, společnost Klarna se zapojí do procesu řešení sporu mezi oběma stranami."
					},
					"howMuchTimeToRefund": {
						"question": "Kolik času mám na refundaci vráceného zboží?",
						"answer": "Na vyřízení refundace máte 21 dní od data vrácení zboží, než společnost Klarna vstoupí do procesu řešení mezi oběma stranami. Vzhledem k tomu je vždy nejlepší refundovat vrácené zboží ihned po jeho převzetí."
					},
					"howToResolve": {
						"question": "Jak můžu řešit spory a vrácení zboží?",
						"answer": "Spor nebo vrácení zboží lze vyřešit několika způsoby.\n\n- Vrácení zboží nebo refundaci zpracujte, jakmile je obdržíte / dohodnete se na řešení.\n- Nastavte prahovou hodnotu pro zpětné vyúčtování v části Nastavení e-mailu a sporů.\n- Buďte aktivní a zaškrtněte políčko Všechny spory.\n- Přijměte ztrátu.\n"
					},
					"whenToRespond": {
						"question": "Kdy se musím aktivně bránit/reagovat na spory?",
						"answer": "Pokud se vám se zákazníkem nepodaří dosáhnout řešení na základě vlastní dohody ve stanovené lhůtě (21 dní), společnost Klarna se zapojí do procesu řešení mezi oběma stranami. Pokud je spor platný, tým podpory společnosti Klarna se s vámi spojí prostřednictvím aplikace Disputes a požádá vás o další informace.\n\nV tomto okamžiku budete vyzváni k obhajobě/reakci na spor.\n"
					},
					"howMuchTimeToRespond": {
						"question": "Kolik času mám na reakci na žádost o spor?",
						"answer": "Lhůta pro první žádost je 14 dní pro důvod všech sporů, s výjimkou neoprávněných nákupů (7 dní). Na každou následnou žádost*, která vám byla zaslána (maximálně 5 žádostí), máte lhůtu 7 dní. Pokud potřebujete svou odpověď doplnit, například o informace nebo přílohy, máte na to 60 minut od odeslání. Jakmile lhůta vyprší, již nebudete moci hájit svůj případ.\n\n*V závislosti na smlouvě o partnerství mohou platit zvláštní pravidla.\n"
					},
					"howLongToSeeDispute": {
						"question": "Jak dlouho mohu spor vidět v aplikaci Disputes po jeho vyřešení?",
						"answer": "Spor je na stránce „Všechny spory“ k dispozici až 180 dní po uzavření."
					},
					"whenToGetCharged": {
						"question": "Kdy mi bude účtován poplatek za spor?",
						"answer": "Poplatek za spor vám může vzniknout v následujících případech:\n- Za spor nesete vinu vy.\n- Nereagujete před uplynutím lhůty.\n- Refundaci provedete po zásahu společnosti Klarna, abyste podpořili proces řešení.\n\nPoplatek se uplatňuje zpětně po určení výsledku sporu. Pokud je spor vyřešen ve váš prospěch nebo je zrušen či zamítnut, nejsou vám účtovány žádné poplatky.\n"
					}
				},
				"disputesHandling": {
					"title": "Řešení sporů",
					"whatIsDispute": {
						"question": "Jaké informace obdržím spolu s žádostí o spor?",
						"answer": "Žádost o spor obsahuje tyto položky:\n\n- Důvod: Kategorie sporu, například vrácení zboží, nepřijaté zboží.\n- Obchodník: Relevantní, pokud je u společnosti Klarna registrováno více ID obchodníků (MID).\n- Datum sporu: Kdy zákazník vytvořil spor.\n- Lhůta: Lhůta, do které musíte reagovat, abyste případ obhájili.\n- Sporné produkty: Zboží, které je předmětem sporu.\n- Detaily objednávky: Zahrnuje referenci objednávky 1 a 2, datum objednávky a spornou částku.\n"
					},
					"howManyTimesDefend": {
						"question": "Kolikrát můžu obhájit jednu žádost o spor?",
						"answer": "Na obhajobu sporu dostanete maximálně pět (5) žádostí, ale většinou není potřeba více než jedna nebo dvě."
					},
					"doIReceiveNotification": {
						"question": "Dostávám oznámení, když mám reagovat na spory?",
						"answer": "Pokud máte čekající žádost o spor, zobrazí se na více místech:\n\n- V části Úkol na domovské stránce portálu pro partnery.\n- Formou upozornění v sekci Spory na stránce Otevřené spory, s indikací počtu sporů, na něž je třeba reagovat.\n\nMůžete se také rozhodnout, že budete dostávat e-mail ke každému sporu, a to úpravou nastavení v části „Nastavení e-mailu a sporů“ na možnost „E-mailové upozornění pro každý spor“.\n"
					},
					"howToAvoidDisputesFees": {
						"question": "Jak se mám vyhnout poplatkům za spory?",
						"answer": "Abyste se vyhnuli poplatkům za spory, postupujte následovně:\n- Rychle zpracovávejte vrácené zboží nebo refundace.\n- Nastavte prahovou hodnotu pro zpětné vyúčtování v části Nastavení e-mailu a sporů.\n- Pravidelně kontrolujte Všechny spory.\n- Na spory reagujte relevantními informacemi.\n- Reagujte před vypršením termínu plnění.\n"
					}
				}
			},
			"settlements": {
				"payoutNotFound": {
					"title": "Výplata pro konkrétní objednávku nebyla nalezena.",
					"orderNumberNotFound": {
						"question": "Číslo objednávky nebo referenční číslo se v sestavě nezobrazuje.",
						"answer": "Pokud nemůžete najít platbu za konkrétní objednávku, postupujte takto:\n- Ověřte přesnost objednávky nebo referenční číslo.\n- Ujistěte se, že je objednávka zaznamenaná.\n- Zkontrolujte, zda objednávka spadá do období zaznamenání sestavy.\n- Vezměte na vědomí, že objednávky zrušené před vypořádáním se v sestavě nezobrazí.\n"
					},
					"checkIfPaidForOrder": {
						"question": "Jak zjistím, zda jsem dostal(a) zaplaceno za konkrétní objednávku?",
						"answer": "V nástroji Správce objednávek na portálu pro partnery vyhledejte objednávku a ujistěte se, že číslo objednávky nebo referenční číslo je správné. Pod referenčním číslem zkontrolujte, zda je objednávka zaznamenána. Pokud je zaznamenaná, stav se ukáže jako čekající nebo zaplaceno. U zaplacených objednávek zobrazte příslušnou sestavu kliknutím na Zobrazit vypořádání."
					}
				},
				"payouts": {
					"title": "Vyplacené částky",
					"whenIsNext": {
						"question": "Kdy mi bude vyplacena příští výplata?",
						"answer": null
					},
					"whyCalculationNotMatch": {
						"question": "Proč se můj vlastní výpočet neshoduje s přijatými výplatami?",
						"answer": "Podle smlouvy s obchodníkem se odečítají poplatky za transakce a poplatky za pozdní vrácení. Proto se vaše výpočty mohou lišit od vypořádání společnosti Klarna. Pokud se chcete podívat na jasně definované srážky, doporučujeme otevřít PDF verzi sestavy vypořádání."
					},
					"whenGetPaid": {
						"question": "Kdy dostanu zaplaceno?",
						"answer": null
					},
					"missingPayout": {
						"question": "Chybí mi výplata",
						"answer": "Než se obrátíte na náš kanál podpory. Uvažte, že chybějící výplaty mohou mít následující příčiny:\n\n- Klarna odešle platbu ve stejný den, kdy je vygenerována sestava, přesto může trvat několik dní, než se peníze objeví na vašem bankovním účtu.\n- V tomto období zaznamenání jste nezaznamenali žádné objednávky.\n- Nedávno jste u společnosti Klarna aktualizovali údaje o svém bankovním účtu, což mohlo způsobit, že platba byla zaslána na váš původní bankovní účet. K tomu může dojít, pokud není změna zpracována před provedením platby.\n- Číslo objednávky nebo referenční číslo, které hledáte, je nesprávné, zkontrolujte je.\n- Společnost Klarna zadržela vaše platby. O zadržení jste měli být informováni samostatně prostřednictvím e-mailu, tyto e-maily jsou nejčastěji zasílány správci.\n"
					},
					"howIsPayoutCalculated": {
						"question": "Jak se vypočítává moje výplata?",
						"answer": "Výplata se vypočítává z objednávek zaznamenaných v období zaznamenání po odečtení poplatků, vratek a dalších poplatků. Sestava vypořádání obsahuje podrobný rozpis těchto částek.\nPokud refundace převýší tržby v období zaznamenání, bude přebytečná částka odečtena od příští výplaty.\n"
					}
				},
				"howPayoutsWork": {
					"question": "Jak platby fungují?",
					"answer": "V části Vypořádání najdete přehled o tom, jak fungují platby, včetně harmonogramů plateb, období zaznamenání a dat výplat. Sestavy transakcí pro každou platbu si můžete stáhnout ve formátech PDF nebo CSV a zobrazit nebo stáhnout podrobnosti o konkrétních platbách kliknutím na jejich odkaz na platbu."
				},
				"payoutSchedule": {
					"title": "Plán plateb",
					"whyDelay": {
						"question": "Proč dochází ke zpoždění platby?",
						"answer": "Hlavním důvodem je, aby nedocházelo k zasílání peněz za faktury mezi vámi a společností Klarna sem a tam, a také, aby se pokryly případy, kdy byste vraceli peníze za zaznamenanou objednávku."
					},
					"changePayoutDelay": {
						"question": "Chci změnit zpoždění vyplacení",
						"answer": "Máme interní kontroly i obecná kritéria, která musí být splněna. Tato obecná kritéria zahrnují:\n- Musíte plně spolupracovat se společností Klarna.\n- Datum vaší první objednávky přesahuje 100 dní.\n- Vaše firma musí být starší než 6 měsíců.\n\nKlarna také vyžaduje od správce informace o následujícím…\n- Požadovaný nový odklad platby a důvody, proč je změna odkladu platby nutná.\n- Kterého ID obchodníka (MID) se změna týká.\nMohou se uplatnit další kritéria, o nichž se rozhoduje individuálně.\n"
					}
				},
				"generatingReports": {
					"title": "Generování sestav",
					"howToSeeReports": {
						"question": "Jak zobrazím sestavy za konkrétní období?",
						"answer": "Pomocí sekce Vlastní sestavy v části Zúčtování si můžete upravit sestavy pro konkrétní časová období."
					},
					"convertCsvToExcel2": {
						"answer": "Postupujte podle následujících kroků\n1. Otevřete Excel a klikněte na Soubor > Nový sešit\n"
					},
					"convertCsvToExcel": {
						"question": "Jak převedu sestavu CSV na Excelovou tabulku?",
						"answer": "1. Otevřete Excel a klikněte na Soubor > Nový sešit\n2. Přejděte na kartu Data a vyberte Z textu.\n3. Vyberte požadovaný soubor .CSV a klikněte na Získat data.\n4. V Průvodci importem textu vyberte možnost S oddělovači a klikněte na Další.\n5. V části Oddělovače vyberte Středník a nastavte Kvalifikátor textu na žádný. Klikněte na Dokončit.\n6. V dialogovém okně Importovat data kliknutím na OK umístěte data počínaje buňkou A1.\n\nPoznámka: V sestavách CSV na portálu obchodníka se jako oddělovač používá středník (;). Podívejte se do svého tabulkového procesoru a zjistěte, jak upravit výchozí oddělovač.\n"
					}
				},
				"payoutAndFeeCalculation": {
					"title": "Výpočet platby a poplatku",
					"howAreFeesCalculated": {
						"question": "Jak se vypočítávají poplatky?",
						"answer": "Poplatky odečítané z plateb Klarna jsou uvedeny ve smlouvě s obchodníkem a další poplatky, například za pozdní vrácení zboží, jsou podrobně popsány v části Poplatky v sestavách plateb/vypořádání.\n\nPřizpůsobení popisů poplatku ve zprávách:\n1. Přejděte na Sestavy vypořádání > Konfigurovat sestavy > Přidat novou konfiguraci.\n2. Pro sestavy CSV vyberte v seznamu transakcí možnost Podrobný typ transakce.\n3. Pro sestavy PDF vyberte Zahrnout detaily objednávky v nastavení rozložení a obsahu.\n\nNezapomeňte upravit nastavení pro každé ID obchodníka a kanál (SFTP, portál pro obchodníky, API) zvlášť.\n"
					},
					"howIsPayoutCalculated": {
						"question": "Jak se vypočítává moje výplata?",
						"answer": "Výplata se vypočítává z objednávek zaznamenaných v období zaznamenání po odečtení poplatků, vratek a dalších poplatků. Sestava vypořádání obsahuje podrobný rozpis těchto částek.\n\nPokud refundace převýší tržby v období zaznamenání, bude přebytečná částka odečtena od příští výplaty.\n"
					}
				}
			},
			"options": {
				"payout": "Vyplacená částka",
				"orders": "Objednávky",
				"settlements": "Vypořádání",
				"disputes": "Spory",
				"otherIssue": "Jiné problémy",
				"yes": "Ano",
				"no": "Ne",
				"partially": "Částečně",
				"iNeedMoreHelp": "Potřebuji další pomoc",
				"thatHelped": "Pomohlo to",
				"contactSupportChannel": "Kontaktovat kanály podpory"
			},
			"button": {
				"label": "Získejte podporu"
			},
			"contact": {
				"chat": "Chat se zástupcem",
				"ticket": "Odeslat lístek",
				"times": {
					"minutes": "Doba čekání: {time} minut",
					"hours": "Doba čekání: {time} hodin",
					"days": "Doba čekání: {time} dní"
				}
			}
		}
	}
};