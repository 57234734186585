module.exports = {
	"fi": {
		"config": {
			"environments": {
				"development": "Kehitysympäristö",
				"staging": "Lavastusympäristö",
				"playground": "Leikkiympäristö",
				"production": "Tuotantoympäristö"
			}
		}
	}
};