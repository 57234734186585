module.exports = {
	"nl": {
		"config": {
			"environments": {
				"development": "Ontwikkelingsomgeving",
				"staging": "Faseringsomgeving",
				"playground": "Speeltuinomgeving",
				"production": "Productieomgeving"
			}
		}
	}
};