module.exports = {
	"nl": {
		"shared": {
			"countries": {
				"all_countries": "Alle landen",
				"AF": "Afghanistan",
				"AX": "Åland-eilanden",
				"AL": "Albanië",
				"DZ": "Algerije",
				"AS": "Amerikaans Samoa",
				"AD": "Andorra",
				"AO": "Angola",
				"AI": "Anguilla",
				"AQ": "Antarctica",
				"AG": "Antigua en Barbuda",
				"AR": "Argentinië",
				"AM": "Armenië",
				"AW": "Aruba",
				"AU": "Australië",
				"AT": "Oostenrijk",
				"AZ": "Azerbeidzjan",
				"BS": "Bahama's",
				"BH": "Bahrein",
				"BD": "Bangladesh",
				"BB": "Barbados",
				"BY": "Wit-Rusland",
				"BE": "België",
				"BZ": "Belize",
				"BJ": "Benin",
				"BM": "Bermuda",
				"BT": "Bhutan",
				"BO": "Bolivia, Plurinationale Staat",
				"BQ": "Bonaire, Sint Eustatius en Saba",
				"BA": "Bosnië en Herzegovina",
				"BW": "Botswana",
				"BV": "Bouveteiland",
				"BR": "Brazilië",
				"IO": "Brits Indische Oceaanterritorium",
				"BN": "Brunei Darussalam",
				"BG": "Bulgarije",
				"BF": "Burkina Faso",
				"BI": "Burundi",
				"KH": "Cambodja",
				"CM": "Kameroen",
				"CA": "Canada",
				"CV": "Kaapverdië",
				"KY": "Kaaimaneilanden",
				"CF": "Centraal Afrikaanse Republiek",
				"TD": "Tsjaad",
				"CL": "Chili",
				"CN": "China",
				"CX": "Christmaseiland",
				"CC": "Cocoseilanden",
				"CO": "Colombia",
				"KM": "Comoren",
				"CG": "Congo",
				"CD": "Congo, Democratische Republiek Congo",
				"CK": "Cookeilanden",
				"CR": "Costa Rica",
				"CI": "Ivoorkust",
				"HR": "Kroatië",
				"CU": "Cuba",
				"CW": "Curaçao",
				"CY": "Cyprus",
				"CZ": "Tsjechië",
				"DK": "Denemarken",
				"DJ": "Djibouti",
				"DM": "Dominica",
				"DO": "Dominicaanse Republiek",
				"EC": "Ecuador",
				"EG": "Egypte",
				"SV": "El Salvador",
				"GQ": "Equatoriaal-Guinea",
				"ER": "Eritrea",
				"EE": "Estland",
				"ET": "Ethiopië",
				"FK": "Falklandeilanden (Islas Malvinas)",
				"FO": "Faeröer",
				"FJ": "Fiji",
				"FI": "Finland",
				"FR": "Frankrijk",
				"GF": "Frans-Guyana",
				"PF": "Frans-Polynesië",
				"TF": "Franse Zuidelijke en Antarctische Gebieden",
				"GA": "Gabon",
				"GM": "Gambia",
				"GE": "Georgië",
				"DE": "Duitsland",
				"GH": "Ghana",
				"GI": "Gibraltar",
				"GR": "Griekenland",
				"GL": "Groenland",
				"GD": "Grenada",
				"GP": "Guadeloupe",
				"GU": "Guam",
				"GT": "Guatemala",
				"GG": "Guernsey",
				"GN": "Guinee",
				"GW": "Guinee-Bissau",
				"GY": "Guyana",
				"HT": "Haïti",
				"HM": "Heard en MacDonaldeilanden",
				"VA": "Heilige Stoel (Staat Vaticaanstad)",
				"HN": "Honduras",
				"HK": "Hongkong",
				"HU": "Hongarije",
				"IS": "IJsland",
				"IN": "India",
				"ID": "Indonesië",
				"IR": "Iran, Islamitische Republiek",
				"IQ": "Irak",
				"IE": "Ierland",
				"IM": "Isle Of Man",
				"IL": "Israël",
				"IT": "Italië",
				"JM": "Jamaica",
				"JP": "Japan",
				"JE": "Jersey",
				"JO": "Jordanië",
				"KZ": "Kazachstan",
				"KE": "Kenia",
				"KI": "Kiribati",
				"KP": "Noord-Korea, Democratische Volksrepubliek",
				"KR": "Zuid-Korea, Republiek",
				"KW": "Koeweit",
				"KG": "Kirgistan",
				"LA": "Laos, Democratische Volksrepubliek",
				"LV": "Letland",
				"LB": "Libanon",
				"LS": "Lesotho",
				"LR": "Liberia",
				"LY": "Libië",
				"LI": "Liechtenstein",
				"LT": "Litouwen",
				"LU": "Luxemburg",
				"MO": "Macau",
				"MK": "Noord-Macedonië, Voormalige Joegoslavische Republiek",
				"MG": "Madagaskar",
				"MW": "Malawi",
				"MY": "Maleisië",
				"MV": "Malediven",
				"ML": "Mali",
				"MT": "Malta",
				"MH": "Marshalleilanden",
				"MQ": "Martinique",
				"MR": "Mauritanië",
				"MU": "Mauritius",
				"YT": "Mayotte",
				"MX": "Mexico",
				"FM": "Micronesia, Federale Staten van",
				"MD": "Moldavië, Republiek",
				"MC": "Monaco",
				"MN": "Mongolië",
				"ME": "Montenegro",
				"MS": "Montserrat",
				"MA": "Marokko",
				"MZ": "Mozambique",
				"MM": "Myanmar",
				"NA": "Namibië",
				"NR": "Nauru",
				"NP": "Nepal",
				"NL": "Nederland",
				"NC": "Nieuw-Caledonië",
				"NZ": "Nieuw-Caledonië",
				"NI": "Nicaragua",
				"NE": "Niger",
				"NG": "Nigeria",
				"NU": "Niue",
				"NF": "Norfolkeiland",
				"MP": "Noordelijke Marianen",
				"NO": "Noorwegen",
				"OM": "Oman",
				"PK": "Pakistan",
				"PW": "Palau",
				"PS": "Palestina, Staat",
				"PA": "Panama",
				"PG": "Papoea-Nieuw-Guinea",
				"PY": "Paraguay",
				"PE": "Peru",
				"PH": "Filipijnen",
				"PN": "Pitcairneilanden",
				"PL": "Polen",
				"PT": "Portugal",
				"PR": "Puerto Rico",
				"QA": "Qatar",
				"RE": "Réunion",
				"RO": "Roemenië",
				"RU": "Russische Federatie",
				"RW": "Rwanda",
				"BL": "Saint-Barthélemy",
				"SH": "Sint-Helena, Ascension en Tristan da Cunha",
				"KN": "Saint Kitts en Nevis",
				"LC": "Saint Lucia",
				"MF": "Sint Maarten (Frans deel)",
				"PM": "Saint-Pierre en Miquelon",
				"VC": "Saint Vincent en de Grenadines",
				"WS": "Samoa",
				"SM": "San Marino",
				"ST": "Sao Tomé en Principe",
				"SA": "Saudi-Arabië",
				"SN": "Senegal",
				"RS": "Servië",
				"SC": "Seychellen",
				"SL": "Sierra Leone",
				"SG": "Singapore",
				"SX": "Sint Maarten (Nederlands deel)",
				"SK": "Slowakije",
				"SI": "Slovenië",
				"SB": "Solomoneilanden",
				"SO": "Somalië",
				"ZA": "Zuid-Afrika",
				"GS": "Zuid-Georgia en de Zuidelijke Sandwicheilanden",
				"SS": "Zuid-Soedan",
				"ES": "Spanje",
				"LK": "Sri Lanka",
				"SD": "Soedan",
				"SR": "Suriname",
				"SJ": "Spitsbergen en Jan Mayen",
				"SZ": "Swaziland",
				"SE": "Zweden",
				"CH": "Zwitserland",
				"SY": "Syrië, Arabische Republiek",
				"TW": "Taiwan, Republiek China",
				"TJ": "Tadzjikistan",
				"TZ": "Tanzania, Verenigde Republiek",
				"TH": "Thailand",
				"TL": "Oost-Timor",
				"TG": "Togo",
				"TK": "Tokelau",
				"TO": "Tonga",
				"TT": "Trinidad en Tobago",
				"TN": "Tunesië",
				"TR": "Turkije",
				"TM": "Turkmenistan",
				"TC": "Turks- en Caicoseilanden",
				"TV": "Tuvalu",
				"UG": "Oeganda",
				"UA": "Oekraïne",
				"AE": "Verenigde Arabische Emiraten",
				"GB": "Verenigd Koninkrijk",
				"US": "Verenigde Staten",
				"UM": "Kleine Afgelegen Eilanden van de Verenigde Staten",
				"UY": "Uruguay",
				"UZ": "Oezbekistan",
				"VU": "Vanuatu",
				"VE": "Venezuela, Bolivariaanse Republiek",
				"VN": "Vietnam",
				"VG": "Britse Maagdeneilanden",
				"VI": "Amerikaanse Maagdeneilanden",
				"WF": "Wallis en Futuna",
				"EH": "Westelijke Sahara",
				"YE": "Jemen",
				"ZM": "Zambia",
				"ZW": "Zimbabwe"
			},
			"months": {
				"january": "Januari",
				"february": "Februari",
				"march": "Maart",
				"april": "April",
				"may": "Mei",
				"june": "Juni",
				"july": "Juli",
				"august": "Augustus",
				"september": "September",
				"october": "Oktober",
				"november": "November",
				"december": "December"
			},
			"weekDaysShort": {
				"monday": "M",
				"tuesday": "D",
				"wednesday": "W",
				"thursday": "D",
				"friday": "V",
				"saturday": "Z",
				"sunday": "Z"
			}
		}
	}
};