import { usePageContext } from '@merchant-portal/framework';
import React, { useEffect } from 'react';
import Page from './components/Page';
import { AccessProvider } from './hooks/useAccess';
import { ActiveBoostProductsProvider } from './hooks/useActiveBoostProducts';
import { ApiKeyProvider } from './hooks/useApiKey';
import { ClientIdProvider } from './hooks/useClientId';
import { CollapsibleStepsProvider } from './hooks/useCollapsibleSteps';
import { ErrorProvider } from './hooks/useError';
import { MidProvider } from './hooks/useMid';
import { PluginProvider } from './hooks/usePlugin';
import { ShowMidSelectorProvider } from './hooks/useShowMidSelector';
export default function BoostPluginPage() {
    const { setPageContext = () => { } } = usePageContext();
    useEffect(() => {
        setPageContext({
            title: ''
        });
    }, [setPageContext]);
    return (React.createElement(ErrorProvider, null,
        React.createElement(PluginProvider, { mustExtractPluginIdFromUrl: false },
            React.createElement(MidProvider, null,
                React.createElement(ShowMidSelectorProvider, null,
                    React.createElement(AccessProvider, null,
                        React.createElement(ApiKeyProvider, null,
                            React.createElement(ClientIdProvider, null,
                                React.createElement(ActiveBoostProductsProvider, null,
                                    React.createElement(CollapsibleStepsProvider, null,
                                        React.createElement(Page, null)))))))))));
}
