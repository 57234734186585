import { AlertBannerWarning } from '@klarna/bubble-ui';
import { hooks as i18nHooks } from 'mage-i18n';
import { TrackImpression } from 'mage-insights';
import React from 'react';
import styled from 'styled-components';
import { useAccess } from '../hooks/useAccess';
import { useMid } from '../hooks/useMid';
const MainContainer = styled.div.withConfig({ displayName: "MainContainer", componentId: "sc-b7nxd0" }) `
  margin: 40px 0 0 0;
`;
export default function AccessBanner() {
    const { selectedMid, selectedStoreName } = useMid();
    const t = i18nHooks.useTranslator();
    const { canCreateApiKey, canCreateClientId, warningMessageKey, accessWarningType } = useAccess();
    const mustDisplayBanner = !canCreateApiKey || !canCreateClientId;
    const event = {
        category: `v3/boost-home/access-warning-banner/${accessWarningType}`,
        action: 'impression',
        label: selectedMid !== null && selectedMid !== void 0 ? selectedMid : undefined
    };
    return (React.createElement(React.Fragment, null, mustDisplayBanner &&
        React.createElement(MainContainer, null,
            React.createElement(TrackImpression, { event: event },
                React.createElement(AlertBannerWarning, { content: t(warningMessageKey, {
                        selectedMid,
                        selectedStoreName
                    }) })))));
}
