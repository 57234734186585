module.exports = {
	"de": {
		"shared": {
			"countries": {
				"all_countries": "Alle Länder",
				"AF": "Afghanistan",
				"AX": "Ålandinseln",
				"AL": "Albanien",
				"DZ": "Algerien",
				"AS": "Amerikanisch-Samoa",
				"AD": "Andorra",
				"AO": "Angola",
				"AI": "Anguilla",
				"AQ": "Antarktis",
				"AG": "Antigua und Barbuda",
				"AR": "Argentinien",
				"AM": "Armenien",
				"AW": "Aruba",
				"AU": "Australien",
				"AT": "Österreich",
				"AZ": "Aserbaidschan",
				"BS": "Bahamas",
				"BH": "Bahrain",
				"BD": "Bangladesch",
				"BB": "Barbados",
				"BY": "Weißrussland",
				"BE": "Belgien",
				"BZ": "Belize",
				"BJ": "Benin",
				"BM": "Bermuda",
				"BT": "Bhutan",
				"BO": "Bolivien, Plurinationaler Staat",
				"BQ": "Bonaire, Sint Eustatius und Saba",
				"BA": "Bosnien und Herzegowina",
				"BW": "Botsuana",
				"BV": "Bouvet-Insel",
				"BR": "Brasilien",
				"IO": "Britisches Territorium im Indischen Ozean",
				"BN": "Brunei Darussalam",
				"BG": "Bulgarien",
				"BF": "Burkina Faso",
				"BI": "Burundi",
				"KH": "Kambodscha",
				"CM": "Kamerun",
				"CA": "Kanada",
				"CV": "Kap Verde",
				"KY": "Caymaninseln",
				"CF": "Zentralafrikanische Republik",
				"TD": "Tschad",
				"CL": "Chile",
				"CN": "China",
				"CX": "Weihnachtsinsel",
				"CC": "Kokosinseln (Keelinginseln)",
				"CO": "Kolumbien",
				"KM": "Komoren",
				"CG": "Kongo",
				"CD": "Kongo, Demokratische Republik",
				"CK": "Cookinseln",
				"CR": "Costa Rica",
				"CI": "Côte d'Ivoire, Republik",
				"HR": "Kroatien",
				"CU": "Kuba",
				"CW": "Curaçao",
				"CY": "Zypern",
				"CZ": "Tschechische Republik",
				"DK": "Dänemark",
				"DJ": "Dschibuti",
				"DM": "Dominica",
				"DO": "Dominikanische Republik",
				"EC": "Ecuador",
				"EG": "Ägypten",
				"SV": "El Salvador",
				"GQ": "Äquatorialguinea",
				"ER": "Eritrea",
				"EE": "Estland",
				"ET": "Äthiopien",
				"FK": "Falkland-Inseln (Malwinen)",
				"FO": "Färöer-Inseln",
				"FJ": "Fidschi",
				"FI": "Finnland",
				"FR": "Frankreich",
				"GF": "Französisch-Guayana",
				"PF": "Französisch-Polynesien",
				"TF": "Französische Südgebiete",
				"GA": "Gabon",
				"GM": "Gambia",
				"GE": "Georgien",
				"DE": "Deutschland",
				"GH": "Ghana",
				"GI": "Gibraltar",
				"GR": "Griechenland",
				"GL": "Grönland",
				"GD": "Grenada",
				"GP": "Guadeloupe",
				"GU": "Guam",
				"GT": "Guatemala",
				"GG": "Guernsey",
				"GN": "Guinea",
				"GW": "Guinea-Bissau",
				"GY": "Guyana",
				"HT": "Haiti",
				"HM": "Heard und die McDonaldinseln",
				"VA": "Heiliger Stuhl (Vatikanstadt)",
				"HN": "Honduras",
				"HK": "Hongkong",
				"HU": "Ungarn",
				"IS": "Island",
				"IN": "Indien",
				"ID": "Indonesien",
				"IR": "Iran, Islamische Republik",
				"IQ": "Irak",
				"IE": "Irland",
				"IM": "Isle of Man",
				"IL": "Israel",
				"IT": "Italien",
				"JM": "Jamaika",
				"JP": "Japan",
				"JE": "Jersey",
				"JO": "Jordan",
				"KZ": "Kasachstan",
				"KE": "Kenia",
				"KI": "Kiribati",
				"KP": "Korea, Demokratische Volksrepublik",
				"KR": "Korea, Republik",
				"KW": "Kuwait",
				"KG": "Kirgisistan",
				"LA": "Laos, Demokratische Volksrepublik",
				"LV": "Lettland",
				"LB": "Libanon",
				"LS": "Lesotho",
				"LR": "Liberia",
				"LY": "Libyen",
				"LI": "Liechtenstein",
				"LT": "Litauen",
				"LU": "Luxemburg",
				"MO": "Macau",
				"MK": "Nordmazedonien, Republik",
				"MG": "Madagaskar",
				"MW": "Malawi",
				"MY": "Malaysia",
				"MV": "Malediven",
				"ML": "Mali",
				"MT": "Malta",
				"MH": "Marshallinseln",
				"MQ": "Martinique",
				"MR": "Mauretanien",
				"MU": "Mauritius",
				"YT": "Mayotte",
				"MX": "Mexiko",
				"FM": "Mikronesien, Föderierte Staaten von",
				"MD": "Moldau, Republik",
				"MC": "Monaco",
				"MN": "Mongolien",
				"ME": "Montenegro",
				"MS": "Montserrat",
				"MA": "Marokko",
				"MZ": "Mosambik",
				"MM": "Myanmar",
				"NA": "Namibia",
				"NR": "Nauru",
				"NP": "Nepal",
				"NL": "Niederlande",
				"NC": "Neukaledonien",
				"NZ": "Neuseeland",
				"NI": "Nicaragua",
				"NE": "Niger",
				"NG": "Nigeria",
				"NU": "Niue",
				"NF": "Norfolkinsel",
				"MP": "Nördliche Marianen",
				"NO": "Norwegen",
				"OM": "Oman",
				"PK": "Pakistan",
				"PW": "Palau",
				"PS": "Palästina, Staat",
				"PA": "Panama",
				"PG": "Papua-Neuguinea",
				"PY": "Paraguay",
				"PE": "Peru",
				"PH": "Philippinen",
				"PN": "Pitcairn",
				"PL": "Polen",
				"PT": "Portugal",
				"PR": "Puerto Rico",
				"QA": "Katar",
				"RE": "Réunion",
				"RO": "Rumänien",
				"RU": "Russische Föderation",
				"RW": "Ruanda",
				"BL": "Saint-Barthélemy",
				"SH": "St. Helena, Ascension und Tristan da Cunha",
				"KN": "St. Kitts und Nevis",
				"LC": "St. Lucia",
				"MF": "St. Martin (französischer Teil)",
				"PM": "St. Pierre und Miquelon",
				"VC": "St. Vincent und die Grenadinen",
				"WS": "Samoa",
				"SM": "San Marino",
				"ST": "São Tomé und Príncipe",
				"SA": "Saudi-Arabien",
				"SN": "Senegal",
				"RS": "Serbien",
				"SC": "Seychellen",
				"SL": "Sierra Leone",
				"SG": "Singapur",
				"SX": "Sint Maarten (niederländischer Teil)",
				"SK": "Slowakei",
				"SI": "Slowenien",
				"SB": "Salomonen",
				"SO": "Somalia",
				"ZA": "Südafrika",
				"GS": "Südgeorgien und die Südlichen Sandwichinseln",
				"SS": "Südsudan",
				"ES": "Spanien",
				"LK": "Sri Lanka",
				"SD": "Sudan",
				"SR": "Suriname",
				"SJ": "Svalbard und Jan Mayen",
				"SZ": "Swasiland",
				"SE": "Schweden",
				"CH": "Schweiz",
				"SY": "Syrische Arabische Republik",
				"TW": "Taiwan, chinesische Provinz",
				"TJ": "Tadschikistan",
				"TZ": "Tansania, Vereinigte Republik",
				"TH": "Thailand",
				"TL": "Timor-Leste",
				"TG": "Togo",
				"TK": "Tokelau",
				"TO": "Tonga",
				"TT": "Trinidad und Tobago",
				"TN": "Tunesien",
				"TR": "Türkei",
				"TM": "Turkmenistan",
				"TC": "Turks- und Caicosinseln",
				"TV": "Tuvalu",
				"UG": "Uganda",
				"UA": "Ukraine",
				"AE": "Vereinigte Arabische Emirate",
				"GB": "Großbritannien",
				"US": "Vereinigte Staaten",
				"UM": "United States Minor Outlying Islands",
				"UY": "Uruguay",
				"UZ": "Usbekistan",
				"VU": "Vanuatu",
				"VE": "Venezuela, Bolivarische Republik",
				"VN": "Vietnam",
				"VG": "Jungferninseln, Britische",
				"VI": "US-amerikanische Jungferninseln",
				"WF": "Wallis und Futuna",
				"EH": "Westsahara",
				"YE": "Jemen",
				"ZM": "Sambia",
				"ZW": "Simbabwe"
			},
			"months": {
				"january": "Januar",
				"february": "Februar",
				"march": "März",
				"april": "April",
				"may": "Mai",
				"june": "Juni",
				"july": "Juli",
				"august": "August",
				"september": "September",
				"october": "Oktober",
				"november": "November",
				"december": "Dezember"
			},
			"weekDaysShort": {
				"monday": "M",
				"tuesday": "D",
				"wednesday": "M",
				"thursday": "D",
				"friday": "F",
				"saturday": "S",
				"sunday": "S"
			}
		}
	}
};