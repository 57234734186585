module.exports = {
	"it": {
		"shared": {
			"months": {
				"january": "Gennaio",
				"february": "Febbraio",
				"march": "Marzo",
				"april": "Aprile",
				"may": "Maggio",
				"june": "Giugno",
				"july": "Luglio",
				"august": "Agosto",
				"september": "Settembre",
				"october": "Ottobre",
				"november": "Novembre",
				"december": "Dicembre"
			},
			"weekDaysShort": {
				"monday": "L",
				"tuesday": "M",
				"wednesday": "M",
				"thursday": "G",
				"friday": "V",
				"saturday": "S",
				"sunday": "D"
			}
		}
	}
};