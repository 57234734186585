module.exports = {
	"el": {
		"credentials": {
			"addOriginModal": {
				"buttonPrimaryText": "Καταχώριση",
				"buttonSecondaryText": "Ακύρωση",
				"description": "Καταχωρίστε οποιονδήποτε τομέα προέλευσης στον οποίο περιμένετε να χρησιμοποιήσετε το Web SDK. Αν πρέπει να καταχωρίσετε περισσότερους από έναν, θα μπορείτε να το κάνετε αργότερα.",
				"errorMessage": "Προέκυψε σφάλμα. Προσπαθήστε ξανά αργότερα ή επικοινωνήστε με την Υποστήριξη σε περίπτωση που το πρόβλημα επιμένει.",
				"inputLabel": "Πληκτρολογήστε την υπόλοιπη διεύθυνση URL",
				"title": "Καταχώριση επιτρεπόμενης προέλευσης"
			},
			"clientKeyBlock": {
				"mainText": "Το αναγνωριστικό πελάτη σας ρυθμίζεται απευθείας στον κώδικα με τον τομέα προέλευσης που καταχωρίσατε.",
				"secondaryText": "Για περαιτέρω διαχείριση, ακολουθήστε τον σύνδεσμο για τη",
				"linkDescription": "σελίδα αναγνωριστικών πελατών",
				"linkLabel": "Κλείσιμο"
			},
			"clientKeyField": {
				"label": "αναγνωριστικό πελάτη",
				"defaultErrorMessage": "Το αναγνωριστικό πελάτη λείπει"
			},
			"copyToClipboardButton": {
				"label": "Αντιγραφή",
				"labelOnCopy": "Αντιγράφηκε"
			},
			"originField": {
				"label": "Τομέας προέλευσης",
				"tooltipMainText": "Για να καταχωρίσετε περισσότερους τομείς προέλευσης ή να διαχειριστείτε τους υφιστάμενους, μεταβείτε στη",
				"tooltipLinkLabel": "σελίδα αναγνωριστικών πελατών"
			},
			"originInputField": {
				"label": "Πληκτρολογήστε την υπόλοιπη διεύθυνση URL",
				"validationError": "Καταχωρίστε μια έγκυρη διεύθυνση URL τομέα",
				"invalidProtocolError": "Η διεύθυνση URL πρέπει να αρχίζει με http:// ή https://"
			},
			"addApiKeySuccessModal": {
				"title": "",
				"description": "Αποθηκεύστε τα παρακάτω διαπιστευτήρια στη συσκευή σας ή σε άλλη ασφαλή τοποθεσία.",
				"warning": "Ο κωδικός πρόσβασης θα αποκαλυφθεί μόνο αυτήν τη φορά και θα εξαφανιστεί αφού κλείσετε αυτό το παράθυρο.",
				"checkbox": "Αποθήκευσα τα διαπιστευτήρια στη συσκευή μου.",
				"download": "Λήψη ως .txt"
			},
			"common": {
				"password": "Κωδικός πρόσβασης",
				"username": "Όνομα χρήστη",
				"close": "Κλείσιμο"
			}
		}
	}
};