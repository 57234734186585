import * as R from 'ramda';
import { createActions } from 'redux-actions';
const actions = createActions({
    CORE: {
        TOKEN: {
            SET: undefined
        },
        KEYCLOAK: {
            REALM: {
                SET: undefined,
                CLEAR: undefined
            }
        },
        PROFILE: {
            UPDATING: undefined,
            UPDATE: {
                SUCCEEDED: undefined,
                FAILED: undefined
            }
        },
        PASSWORD: {
            RESETTING: undefined,
            RESET: {
                SUCCEEDED: undefined,
                FAILED: undefined
            }
        },
        REQUESTER: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            FETCH: (client, userId) => {
                return client
                    .request({
                    url: `/users/${userId}?with_capabilities=true&with_pending_entities=true`
                })
                    .then(R.prop('data'));
            },
            // eslint-disable-next-line @typescript-eslint/naming-convention
            UPDATE: (client, userId, data) => {
                return client
                    .request({
                    method: 'PUT',
                    url: `/users/${userId}`,
                    data
                })
                    .then(R.prop('data'));
            }
        },
        MERCHANTS: {
            SET: undefined
        },
        MIDS: {
            SET: undefined
        },
        MID: {
            SET: undefined
        },
        IS_PARTNER: {
            SET: undefined
        }
    }
}).core;
export default actions;
