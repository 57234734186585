import { Typography } from '@klarna/bubble-ui';
import { hooks as i18nHooks } from 'mage-i18n';
import { TrackImpression } from 'mage-insights';
import React from 'react';
import TrackableLink from '../../../../../components/TrackableLink';
export default function NonPlatformIntegrationMessage() {
    const t = i18nHooks.useTranslator();
    const redirectTo = 'https://docs.klarna.com/klarna-payments/before-you-start/?utm_source=merchant%20portal';
    const impressionEvent = {
        category: 'v3/boost-home/direct-merchants-klarna-doc',
        action: 'impression'
    };
    return (React.createElement(TrackImpression, { event: impressionEvent },
        React.createElement(Typography, null,
            t('home-fe.boosters-home-v03.non-platform-integration.question') + ' ',
            React.createElement(TrackableLink, { href: redirectTo, openInNewWindow: true, colorToken: "colors/text/default", category: "v3/boost-home/direct-merchants-klarna-doc" }, t('home-fe.boosters-home-v03.non-platform-integration.link')))));
}
