module.exports = {
	"el": {
		"home-fe": {
			"boosters-home-v03": {
				"install-plugin": {
					"stepNumber": "1",
					"title": "Εγκατάσταση πρόσθετου",
					"description": "Επιλέξτε την πλατφόρμα ηλεκτρονικού εμπορίου σας ώστε να δημιουργήσουμε έναν σύνδεσμο για να επιταχύνουμε την εγκατάστασή σας",
					"selectorLabel": "Επιλογή πλατφόρμας",
					"getPluginButtonLabel": "Λήψη πρόσθετου",
					"klarnaDocsLinkLabel": "Μάθετε περισσότερα στα έγγραφα της Klarna"
				},
				"start-setup": {
					"stepNumber": "2",
					"title": "Ενεργοποίηση πρόσθετου",
					"description": "Δημιουργήστε και αντιγράψτε το αναγνωριστικό πελάτη και το κλειδί API. Θα τα χρειαστείτε για να ενεργοποιήσετε το πρόσθετο."
				},
				"promotion-banner": {
					"title": "Klarna για πλατφόρμες",
					"subtitle": "Αναπτύξτε την επιχείρησή σας με ένα μόνο πρόσθετο, που σας δίνει πρόσβαση σε πολλές λειτουργίες για αύξηση των πωλήσεων και βελτίωση της εμπειρίας αγορών χωρίς επιπλέον κόστος",
					"banner-cards": {
						"osm-title": "Αυξήστε τη μέση αξία παραγγελίας με το <span>On-site messaging</span>",
						"kec-title": "Αυξήστε τις πωλήσεις με το Express Checkout",
						"siwk-title": "Προσελκύστε νέους πελάτες με την επιλογή <span>Σύνδεση με Klarna</span>"
					}
				},
				"configure-boost-features": {
					"stepNumber": "3",
					"title": "Προσαρμόστε την τοποθέτηση λειτουργιών",
					"description": "Αυτό είναι ένα προαιρετικό βήμα για να αλλάξετε τον τρόπο που φαίνονται οι λειτουργίες στο κατάστημά σας. Για να το κάνετε αυτό θα πρέπει να έχετε ολοκληρώσει ήδη το βήμα 2.",
					"no-credentials": "Μην ξεχάσετε να δημιουργήσετε πρώτα το αναγνωριστικό πελάτη και το κλειδί API προκειμένου να συνεχίσετε αυτό το βήμα.",
					"apps": {
						"osm": {
							"link-label": "Προσαρμόστε το on-site messaging",
							"description": "Προσαρμόστε το on-site messaging για να ταιριάζει στην επωνυμία σας προσαρμόζοντας το θέμα και επιλέγοντας τις τοποθετήσεις"
						},
						"kec": {
							"link-label": "Προσαρμόστε το Express checkout",
							"description": "Προσαρμόστε το κουμπί express checkout για να ταιριάζει στην επωνυμία σας προσαρμόζοντας το κουμπί θέματος, το σχήμα και επιλέγοντας τις τοποθετήσεις"
						},
						"siwk": {
							"link-label": "Ρυθμίστε τη Σύνδεση με Klarna",
							"description": "Επιλέξτε το πεδίο εφαρμογής για τη σύνδεση με Klarna και προσθέστε ένα URL ανακατεύθυνσης. Το πεδίο εφαρμογής είναι οι πληροφορίες που συλλέγονται από τον πελάτη σας και το URL ανακατεύθυνσης είναι αυτό που χρησιμοποιείται εάν τα αναδυόμενα παράθυρο αποκλειστούν από τους πελάτες"
						}
					}
				},
				"client-identifier-section": {
					"title": "Αναγνωριστικό πελάτη",
					"description": "Το μοναδικό αναγνωριστικό για ρύθμιση",
					"manage-link": "Διαχειριστείτε το αναγνωριστικό πελάτη και την προέλευση",
					"generate-button": "Δημιουργία",
					"tooltip": "Δημιουργήστε και αντιγράψτε το αναγνωριστικό πελάτη. Τα χρειάζεστε για να ξεκινήσετε τη ρύθμιση πρόσθετου στην πύλη της πλατφόρμας σας."
				},
				"api-key": {
					"warning-banner": {
						"content": "Έχετε ήδη ενεργά κλειδιά API. Πριν δημιουργήσετε νέα, βεβαιωθείτε ότι έχετε απενεργοποιήσει αυτά που δεν χρησιμοποιείτε."
					},
					"title": "Κλειδί Klarna API",
					"description": "Κλειδί API για ρύθμιση",
					"tooltip": "Σημειώστε ότι δεν εμφανίζουμε ξανά το μυστικό σας κλειδί API αφότου το δημιουργήσετε.",
					"generate-button": "Δημιουργία",
					"manageApiKey": "Διαχείριση κλειδιού API"
				},
				"non-platform-integration": {
					"question": "Δεν χρησιμοποιείτε την Klarna για πλατφόρμες;",
					"link": "Δείτε πώς μπορείτε να κάνετε απευθείας ενσωμάτωση με την Klarna"
				}
			},
			"boosters-home": {
				"welcome-banner": {
					"title": "Ξεκλειδώστε όλη τη δύναμη της Klarna",
					"text": "Χαρακτηριστικά που έχουν σχεδιαστεί για να μετατρέπουν τους επισκέπτες σε δυναμικούς αγοραστές. Βελτιώστε κάθε βήμα της εμπειρίας αγορών - από την περιήγηση μέχρι το ταμείο."
				},
				"plugin-carousel": {
					"title": "Δεν χρειάζεται ρύθμιση κωδικού με ένα πρόσθετο",
					"overviewLink": "Δείτε όλες τις πλατφόρμες",
					"directIntegrationLabel": "Δεν χρησιμοποιείτε την Klarna για πλατφόρμες;",
					"directIntegrationLink": "Δείτε πώς μπορείτε να κάνετε απευθείας ενσωμάτωση με την Klarna"
				},
				"booster-apps": {
					"title": "Μάθετε περισσότερα",
					"kec-fe": {
						"title": "Κάντε την ολοκλήρωση αγορών 5 φορές ταχύτερη",
						"description": "Λιγότερα κλικ σημαίνουν λιγότερα ποσοστά αποχώρησης. Αυξήστε τις πωλήσεις και ελαχιστοποιήστε την εγκατάλειψη του καλαθιού με ταχεία ολοκλήρωση αγορών στις σελίδες προϊόντων και καλαθιού.",
						"callToAction": "Περισσότερα σχετικά με το Express Checkout"
					},
					"siwk-fe": {
						"title": "Σύνδεση από τα κοινωνικά μέσα για αγορές",
						"description": "Οι αγοραστές έχουν μια γρήγορη και ασφαλή επιλογή σύνδεσης με ένα κλικ. Εσείς λαμβάνετε βελτιωμένα, αξιοποιήσιμα δεδομένα προφίλ αγοραστών.",
						"callToAction": "Περισσότερα σχετικά με τη Σύνδεση με Klarna"
					},
					"upstream-fe": {
						"title": "Μετατρέψτε τους επισκέπτες σε αγοραστές",
						"description": "Μεγιστοποιήστε τις πωλήσεις με δυναμικά προωθητικά μηνύματα σε ολόκληρη τη διαδικασία αγορών.",
						"callToAction": "Περισσότερα σχετικά με το On-site messaging"
					},
					"branding-fe": {
						"title": "Βελτιώστε την προβολή του καταστήματός σας",
						"description": "Διαχειριστείτε την επωνυμία σας στην εφαρμογή Klarna μεταφορτώνοντας ένα λογότυπο, εικονίδιο και URL καταστήματος.",
						"callToAction": "Ρυθμίστε τον Διαχειριστή επωνυμίας"
					}
				}
			},
			"boosters-plugins": {
				"title": "Ρύθμιση πρόσθετου",
				"description": "Ξεκινήστε με τα πρόσθετά μας και βεβαιωθείτε ότι είστε έτοιμοι για έναρξη λειτουργίας. "
			},
			"boosters-plugin": {
				"title": "Klarna για {pluginName}",
				"description": "Κάντε τις παρακάτω ενέργειες για να ενεργοποιήσετε τις πληρωμές Klarna ή/και λειτουργίες ενίσχυσης με τη ρύθμιση χωρίς κωδικό.",
				"access-warning": {
					"both": "Δεν έχετε άδεια για το {selectedStoreName} ({selectedMid}) για τη δημιουργία κλειδιών API και αναγνωριστικού πελάτη. Επικοινωνήστε με τον διαχειριστή σας και ζητήστε πρόσβαση στις ρυθμίσεις Πληρωμής για να προχωρήσετε.",
					"client": "Δεν έχετε άδεια για το {selectedStoreName} ({selectedMid}) για τη δημιουργία αναγνωριστικού πελάτη. Επικοινωνήστε με τον διαχειριστή σας και ζητήστε πρόσβαση στις ρυθμίσεις Πληρωμής για να προχωρήσετε.",
					"api": "Δεν έχετε άδεια για το {selectedStoreName} ({selectedMid}) για τη δημιουργία κλειδιών API. Επικοινωνήστε με τον διαχειριστή σας και ζητήστε πρόσβαση στις ρυθμίσεις Πληρωμής για να προχωρήσετε."
				},
				"back": "Επιστροφή",
				"instructions": {
					"api-key": {
						"title": "Ενεργοποίηση πληρωμής Klarna",
						"content": "Πρέπει να δημιουργήσετε και να αντιγράψετε τα κλειδιά API ώστε να μπορείτε να τα χρησιμοποιήσετε κατά τη ρύθμιση.",
						"link-label": "Μάθετε περισσότερα στα έγγραφα της Klarna.",
						"generate": "Δημιουργία κλειδιών API",
						"active-warning": {
							"content": "Έχετε ήδη ενεργά διαπιστευτήρια API. Πριν δημιουργήσετε νέα, βεβαιωθείτε ότι έχετε απενεργοποιήσει αυτά που δεν χρησιμοποιείτε.",
							"link": "Διαχείριση κλειδιών API"
						},
						"active-warning-api-keys": {
							"content": "Έχετε ήδη ενεργά διαπιστευτήρια API. Πριν δημιουργήσετε νέα, βεβαιωθείτε ότι έχετε απενεργοποιήσει αυτά που δεν χρησιμοποιείτε.",
							"link": "Διαχείριση κλειδιών API"
						}
					},
					"client-id": {
						"title": "Ενεργοποίηση λειτουργιών ενίσχυσης",
						"content": "Για να προσθέσετε το Klarna Express Checkout και το On-site messaging πρέπει να δημιουργήσετε και να αντιγράψετε το αναγνωριστικό πελάτη σας ώστε να μπορείτε να το χρησιμοποιήσετε κατά τη ρύθμιση.",
						"generate": "Δημιουργία αναγνωριστικού πελάτη",
						"manage": "Διαχείριση αναγνωριστικού πελάτη"
					},
					"plugin-doc": {
						"title": "Διαμόρφωση λειτουργιών ενίσχυσης",
						"content": "Για να οριστικοποιήσετε τη ρύθμισή σας, πρέπει να διαμορφώσετε το Klarna Express Checkout και το On-site messaging στις ρυθμίσεις της πλατφόρμας σας."
					}
				},
				"error-messages": {
					"default": "Προέκυψε σφάλμα. Προσπαθήστε ξανά ή μεταβείτε στη βοήθεια.",
					"fetch-data": "Προέκυψε σφάλμα κατά την παροχή των δεδομένων σας. Προσπαθήστε ξανά ή μεταβείτε στη βοήθεια.",
					"create-api-key": "Προέκυψε σφάλμα κατά τη δημιουργία των κλειδιών API. Προσπαθήστε ξανά ή μεταβείτε στη βοήθεια.",
					"create-client-id": "Προέκυψε σφάλμα κατά τη δημιουργία του αναγνωριστικού πελάτη σας. Προσπαθήστε ξανά ή μεταβείτε στη βοήθεια."
				}
			},
			"marketing-home": {
				"description": "Καλώς ήρθατε στα προϊόντα μάρκετινγκ. Αρχίστε την εξερεύνηση παρακάτω.",
				"essentials": {
					"title": "Μάρκετινγκ",
					"cta-label": "Μάθετε περισσότερα",
					"branding-fe": {
						"description": "Ρύθμιση και διαχείριση της επωνυμίας σας. Κάντε τους πελάτες να δούνε το καλύτερό σας πρόσωπο σε όλο το οικοσύστημα της Klarna, καθώς και στη smoooth εφαρμογή μας."
					},
					"klarna-search-b2b-portal": {
						"description": "Ξεκινήστε με την ισχυρή μηχανή αναζήτησης και τη σύγκριση αγορών της Klarna. Αποκτήστε πρόσβαση σε στατιστικά στοιχεία και εργαλεία σχεδιασμένα να αυξήσουν την προβολή σας."
					}
				},
				"external-apps": {
					"title": "Εφαρμογές",
					"cta-label": "Άνοιγμα εφαρμογής"
				},
				"popOvers": {
					"searchCompare": {
						"title": "Ξεκινήστε με την Αναζήτηση και Σύγκριση",
						"content": "Ελέγξτε εδώ για στατιστικά στοιχεία και εργαλεία που μπορούν να βοηθήσουν στην αύξηση της προβολής σας στην υπηρεσία σύγκρισης αγορών της Klarna."
					}
				}
			},
			"no-apps": {
				"title": "Καμία διαθέσιμη εφαρμογή!",
				"subtitle": "Επικοινωνήστε με τον διαχειριστή σας για να σας βοηθήσει να προσπελάσετε τις εφαρμογές που χρειάζεστε."
			},
			"contact": {
				"title": "Χρειάζεστε βοήθεια με κάτι;",
				"paragraph": "Αν έχετε τυχόν ερωτήσεις, μην διστάσετε να επικοινωνήσετε μαζί μας και θα προσπαθήσουμε να τις απαντήσουμε.",
				"label": "Επικοινωνήστε μαζί μας"
			},
			"important-banner": {
				"title": "",
				"content": "",
				"linkLabel": ""
			},
			"two-factor-home-banner": {
				"banner-text": "Κάντε τον λογαριασμό σας ασφαλέστερο με την επαλήθευση δυο βημάτων.",
				"banner-link": "Ρυθμίστε τη"
			},
			"two-factor-opt-in": {
				"title": "Ρυθμίστε την επαλήθευση δύο βημάτων",
				"intro-paragraph": "Για να αυξήσετε την ασφάλεια του λογαριασμού σας, σας ενθαρρύνουμε να ρυθμίσετε σήμερα την επαλήθευση δύο βημάτων.",
				"help-link-text": "Κάντε κλικ εδώ",
				"help-text": " για να μάθετε περισσότερα για το πώς να την ρυθμίσετε και το πώς σας προστατεύει.",
				"warning-text": "Βεβαιωθείτε ότι έχετε το smartphone σας, καθώς και πρόσβαση στο διαδίκτυο, πριν προχωρήσετε",
				"continue-button": "Εκκινήστε την ρύθμιση"
			},
			"accept-invite-modal": {
				"title": "Αποδοχή εκκρεμών προσκλήσεων",
				"description": "Έχετε προσκληθεί να αποκτήσετε πρόσβαση στα παρακάτω καταστήματα. Λάβετε υπόψη ότι μπορεί να χρειαστούν λίγα λεπτά για να εφαρμοστούν οι επιλογές σας.",
				"accept-all-button": "Αποδοχή όλων",
				"decline-all-button": "Απόρριψη όλων",
				"save-button": "Αποθήκευση",
				"error": "Προέκυψε πρόβλημα. Προσπαθήστε ξανά αργότερα!",
				"invite": {
					"accept-label": "Αποδοχή",
					"decline-label": "Απόρριψη"
				}
			},
			"capture-orientation": {
				"title": "Πρώτη φορά χρήστης της Klarna;",
				"sub-title": "Ακολουθήστε αυτά τα βήματα για να μάθετε τα βασικά:",
				"order-label": "Λάβετε μια παραγγελία",
				"capture-label": "Αποτυπώστε την παραγγελία",
				"payout-label": "Πληρωθείτε",
				"capture-cta": "Αποτυπώστε αυτήν την παραγγελία",
				"payout-cta": "Εφαρμογή διακανονισμών",
				"dismiss-cta": "Απόρριψη",
				"order-step": "Πριν από οτιδήποτε άλλο, θα χρειαστεί να περιμένουμε για μια εισερχόμενη παραγγελία από τους πελάτες σας.",
				"capture-step": "Συγχαρητήρια για την πρώτη σας παραγγελία.\nΓια να πληρωθείτε, το επόμενο βήμα είναι η αποστολή και η αποτύπωση της παραγγελίας σας.",
				"payout-step": "Συγχαρητήρια για την αποτύπωση της πρώτης σας παραγγελίας.\nΠλέον θα λαμβάνετε αποπληρωμή από εμάς σύμφωνα με το χρονοδιάγραμμα που ορίζεται στη σύμβασή σας με την Klarna.\nΓια περισσότερες πληροφορίες για την πρώτη σας αποπληρωμή μεταβείτε στο",
				"completed-step": "Συγχαρητήρια για την πρώτη σας αποπληρωμή.\nΚαλώς ήρθατε σε ένα όμορφο ταξίδι με την Klarna.\nΜπορείτε να εξερευνήσετε τις άλλες εφαρμογές που προσφέρουμε παρακάτω."
			},
			"banner": {
				"common": {
					"branding-fe": {
						"content": "Μόλις κυκλοφορήσαμε τη νέα μας εφαρμογή για την Προώθηση επωνυμίας!",
						"linkLabel": "Αποκτήστε τον έλεγχο της προώθησης της επωνυμίας σας τώρα!"
					},
					"onSiteMessaging": {
						"title": "Μετατρέψτε τους επισκέπτες σε αγοραστές με το On-site messaging",
						"text": "Οι αγορές ξεκινούν πριν φτάσουν οι πελάτες σας στο καλάθι. Με το On-site messaging, θα επισημάνετε τις ευέλικτες επιλογές πληρωμής και τα άλλα οφέλη της Klarna έγκαιρα στη διαδικασία πωλήσεων.",
						"cta": "Δείτε το"
					}
				}
			},
			"nko": {
				"dialog": {
					"title": "Προσφέρουμε πλέον μερικές συναρπαστικές λειτουργίες για την επιχείρησή σας.",
					"subtitle": "Και θα θέλαμε να σας γνωρίσουμε καλύτερα.",
					"explore": "Ναι, ας εξερευνήσουμε!",
					"name-field": "Πώς πρέπει να σας αποκαλούμε;",
					"terms-pre": "Έχω διαβάσει και συμφωνώ με τους ",
					"terms-text": "όρους & προϋποθέσεις"
				}
			},
			"insightsWidget": {
				"title": "Πωλήσεις σήμερα",
				"orderCount": "Παραγγελίες που δημιουργήθηκαν",
				"orderVolume": "Όγκος πωλήσεων",
				"orderVolumeAverage": "Μέση αξία παραγγελίας",
				"currencyFootnote": "Εμφανίζεται σε {currency}",
				"link": "Προβολή παραγγελιών",
				"allStores": "Όλα τα καταστήματα",
				"selectAllOptionText": "Επιλογή όλων",
				"deselectAllOptionText": "Απαλοιφή επιλογής",
				"closeText": "Ολοκληρώθηκε",
				"closeTextWithSelection": "Εφαρμογή # επιλογής(ών)",
				"elapsedTime": "Η τελευταία παραγγελία λήφθηκε πριν από {hours, plural, =0 {} =1 {μία ώρα} other {# ώρες}} {minutes, plural, =0 {} =1 {ένα λεπτό} other {# λεπτά}}",
				"error": {
					"content": "Δυστυχώς, τα στατιστικά των παραγγελιών σας δεν φορτώθηκαν σωστά."
				},
				"relativeDate": {
					"today": "Σήμερα",
					"yesterday": "Χθες"
				},
				"weekDays": {
					"1": "Δευτέρα",
					"2": "Τρίτη",
					"3": "Τετάρτη",
					"4": "Πέμπτη",
					"5": "Παρασκευή",
					"6": "Σάββατο",
					"7": "Κυριακή"
				}
			},
			"blackFridayWidget": {
				"title": "Η εβδομάδα της Black Friday έφτασε.",
				"text": "Παρακολουθήστε τα στατιστικά των παραγγελιών σας σε σχεδόν πραγματικό χρόνο.",
				"button": "Προβολή στατιστικών"
			},
			"tasks": {
				"title": "Τι πρέπει να κάνετε",
				"error": {
					"title": "Δεν μπορέσαμε να φορτώσουμε τη λίστα με αυτά που πρέπει να κάνετε",
					"desc": "Φορτώστε ξανά τη σελίδα για να προσπαθήσουμε ξανά."
				},
				"empty": {
					"title": "Είστε μια χαρά!",
					"desc": "Δεν έχετε ανοιχτές εργασίες αυτήν τη στιγμή."
				},
				"single": {
					"products-fe:complete-recollection": "Υποχρεωτική φόρμα AML προς συμπλήρωση, για την πρόληψη του ξεπλύματος χρήματος από την εταιρεία σας",
					"products-fe:resume-onboarding": "Ολοκληρώστε την ενσωμάτωσή σας",
					"settings-fe:compile-cs-info": "Προσθέστε τα στοιχεία εξυπηρέτησης πελατών σας"
				},
				"aggregated": {
					"disputes-fe:unresolved": "{ count, plural, =1 {1 αμφισβήτηση συναλλαγής χωρίς επίλυση} other {# αμφισβητήσεις συναλλαγών χωρίς επίλυση}}",
					"orders-fe:expiring": "{ count, plural, =1 {1 παραγγελία που λήγει} other {# παραγγελίες που λήγουν}}",
					"orders-fe:tocapture": "{ count, plural, =1 {1 παραγγελία προς αποτύπωση} other {# παραγγελίες προς αποτύπωση}}",
					"orders-fe:uncaptured": "{ count, plural, =1 {1 παραγγελία χωρίς αποτύπωση} other {# παραγγελίες χωρίς αποτύπωση}}",
					"users-fe:mid-added": "{ count, plural, =1 {1 νέο κατάστημα έχει προστεθεί} other {# νέα καταστήματα έχουν προστεθεί}}",
					"users-fe:mid-invited": "{ count, plural, =1 {Αναμένεται ενέργεια για 1 πρόσκληση καταστήματος} other {Αναμένεται ενέργεια για # προσκλήσεις καταστήματος}}"
				}
			}
		}
	}
};