module.exports = {
	"fr": {
		"core": {
			"alert": {
				"message": "Une erreur s'est produite ! Veuillez réessayer.",
				"correlationIdLabel": "ID de corrélation :"
			},
			"header": {
				"title": "Portail des marchands"
			},
			"usermenu": {
				"language": "Langue",
				"country": "Pays",
				"timezone": "Fuseau horaire",
				"logout": "Se déconnecter",
				"profile": "Profil",
				"changeProfile": "Modifier",
				"tooltip": "Votre profil"
			},
			"appsmenu": {
				"defaultTooltip": "Vos applications",
				"initialTooltip": "Retrouvez vos applications ici.",
				"newAppsTooltip": "Nous avons de nouvelles applications à vous présenter !"
			},
			"two-factor-opt-in": {
				"title": "Configurer l'authentification à deux facteurs",
				"intro-paragraph": "Vous n'avez pas activé l'authentification à deux facteurs (2FA) sur votre compte portail marchand. À partir du <b>16 janvier 2023</b>, 2FA sera obligatoire pour que tous les utilisateurs puissent accéder au portail marchand. Een savoir plus sur l'importance du 2FA, comment le configurer et les réponses aux questions courantes.​ <faqLink>ici</faqLink>.",
				"help-link-text": "Cliquez ici",
				"help-text": " pour en savoir plus sur la configuration et la protection.",
				"warning-text": "Assurez-vous d'avoir votre smartphone et accès à Internet avant de continuer",
				"continue-button": "Lancer l'installation"
			},
			"kuasPrompter": {
				"title": "Vous avez sélectionné",
				"content": "{count} MID",
				"contentTooltip": "Cliquez pour copier les IDs de vendeur dans le presse-papiers",
				"button": "Modifier les MID sélectionnés"
			},
			"notification": {
				"title": "Annonce",
				"content": {
					"uptodate": "Vous êtes à jour !"
				}
			},
			"languages": {
				"cs": "République Tchèque",
				"da": "Danois",
				"de": "Allemand",
				"el": "Grec",
				"en": "Anglais",
				"en_GB": "Anglais (GB)",
				"en_US": "Anglais (US)",
				"es": "Espagnol",
				"fi": "Finnois",
				"fr": "Français",
				"it": "Italien",
				"nl": "Néerlandais",
				"no": "Norvégien",
				"pl": "Polonais",
				"pt": "Portugais",
				"sv": "Suédois"
			},
			"countries": {
				"at": "Autriche",
				"au": "Australie",
				"be": "Belgique",
				"ca": "Canada",
				"ch": "Suisse",
				"cz": "République Tchèque",
				"de": "Allemagne",
				"dk": "Danemark",
				"ee": "Estonie",
				"es": "Espagne",
				"fi": "Finlande",
				"fr": "France",
				"gb": "Grande-Bretagne",
				"gr": "Grèce",
				"ie": "Irlande",
				"it": "Italie",
				"mx": "Mexique",
				"nl": "Pays-Bas",
				"no": "Norvège",
				"se": "Suède",
				"us": "États-Unis",
				"nz": "Nouvelle-Zélande",
				"pl": "Pologne",
				"pt": "Portugal",
				"ro": "Roumanie"
			},
			"selectLanguage": {
				"title": "Sélectionnez votre langue",
				"paragraph": "Il s'agira de votre langue par défaut",
				"submit": "OK"
			},
			"selectCountry": {
				"title": "Sélectionnez votre pays",
				"paragraph": "Il s'agira de votre pays par défaut.",
				"submit": "OK"
			},
			"updateProfile": {
				"title": "Mettre à jour votre profil",
				"submit": "OK",
				"emailLabel": "Adresse électronique",
				"firstNameLabel": "Prénom",
				"lastNameLabel": "Nom",
				"resetPasswordLabel": "Mot de passe",
				"resetPasswordSelectedValue": "Réinitialiser",
				"resetPasswordSuccessLabel": "E-mail de réinitialisation envoyé avec succès.",
				"resetPasswordErrorLabel": "Une erreur s'est produite lors de l'envoi de l'e-mail. Veuillez réessayer plus tard.",
				"error": "La mise à jour des informations a échoué",
				"changeCookiePolicyLabel": "Politique en matière de cookies",
				"changeCookiePolicySelectedValue": "Changer",
				"securitySectionTitle": "Sécurité",
				"preferencesSectionTitle": "Préférences",
				"mfaLabel": "Authentification à deux facteurs",
				"mfaDescription": "Requiert un mot de passe unique à validité limitée pour se connecter. Nous recommandons d'activer cette option.",
				"twoFactor": {
					"reset": "Modifier la carte",
					"isEnabled": "Activé",
					"isDisabled": "Désactivé",
					"title": "Authentification à deux facteurs (2FA)",
					"description": "Cela permet aux utilisateurs de se connecter avec une sécurité supplémentaire étant un mot de passe à usage unique et limité dans le temps.",
					"tooltip": "Assurez-vous d'avoir au moins l'autorisation d'accéder {permission} à l'application {app} pour chaque magasin auquel l'utilisateur a accès afin de basculer le paramètre d'authentification à deux facteurs.",
					"confirmationDialog": {
						"closeButton": "Fermer",
						"confirmationButton": "Oui, continuer",
						"cancelButton": "Annuler",
						"resetSuccess": {
							"title": "Réinitialisation de l'appareil 2FA réussie.",
							"description": "La prochaine fois que l'utilisateur se connectera, il devra configurer un nouvel appareil pour 2FA."
						},
						"resetConfirmation": {
							"title": "Êtes-vous sûr de vouloir réinitialiser l'appareil 2FA de cet utilisateur ?",
							"description": "En continuant, vous réinitialiserez l'appareil utilisé pour générer les codes 2FA pour cet utilisateur. La prochaine fois que l'utilisateur se connectera, il devra configurer un nouvel appareil pour 2FA."
						}
					}
				}
			},
			"footer": {
				"copyright": {
					"eu": "Copyright © 2005-{currentYear} Klarna Bank AB (publ). Tous droits réservés",
					"oc": "Copyright © 2005-{currentYear} Klarna Australia Pty Ltd, ABN # 82 635 912 579",
					"ap": "Copyright © 2005-{currentYear} Klarna Australia Pty Ltd, ABN # 82 635 912 579",
					"us": "Copyright © 2005-{currentYear} Klarna Inc. NMLS #1353190, 629 N. High Street, 3e étage, Columbus, OH 43215"
				},
				"terms": "Conditions générales"
			},
			"accessDenied": {
				"title": "Vous avez besoin d'une autorisation d'accès supplémentaire pour cette page.",
				"message": "Contactez votre administrateur ou Klarna pour toute question."
			},
			"midSelector": {
				"label": "Boutique"
			},
			"storeSelector": {
				"label": "Boutique"
			},
			"storesSelector": {
				"closeText": "Terminé",
				"closeTextWithSelection": "Appliquer la/les # sélection(s)",
				"deselectAllOptionText": "Effacer la sélection",
				"emptyStateText": "Aucune boutique trouvée",
				"label": "Boutiques",
				"searchFieldLabel": "Rechercher par boutique",
				"selectAllOptionText": "Sélectionner toutes les boutiques",
				"selectedAllText": "Toutes les boutiques",
				"selectedText": "# boutiques"
			},
			"pagination": {
				"ofLabel": "sur"
			},
			"singleDatePicker": {
				"selectDate": "Sélectionner la date"
			},
			"dateRangePicker": {
				"startLabel": "Date de début",
				"endLabel": "Date de fin",
				"months": {
					"january": "Janvier",
					"february": "Février",
					"march": "Mars",
					"april": "Avril",
					"may": "Mai",
					"june": "Juin",
					"july": "Juillet",
					"august": "Août",
					"september": "Septembre",
					"october": "Octobre",
					"november": "Novembre",
					"december": "Décembre"
				},
				"weekDaysShort": {
					"monday": "L",
					"tuesday": "M",
					"wednesday": "M",
					"thursday": "J",
					"friday": "V",
					"saturday": "S",
					"sunday": "D"
				}
			},
			"regionSelector": {
				"noStores": "Aucune boutique attribuée",
				"andMore": "et plus encore",
				"regions": {
					"eu": "Europe",
					"us": " Amérique du Nord",
					"ap": "Asie-Pacifique"
				}
			},
			"regionSelectorBar": {
				"modal": {
					"title": "Changer de région",
					"description": "Veuillez choisir une région à laquelle sont associées des boutiques pour afficher les données pertinentes.",
					"buttonText": "Confirmer"
				},
				"selectedRegion": "Région sélectionnée",
				"changeButton": "Changer"
			},
			"appIcon": {
				"newLabel": "NOUVEAU"
			},
			"appCategory": {
				"dayToDay": "À utiliser au quotidien",
				"nko": "Klarna en ligne",
				"tools": "Outils",
				"preferences": "Préférences"
			},
			"apps": {
				"account-information-fe": {
					"title": "Informations du compte",
					"description": "Retrouvez vos informations commerciales et bancaires, ainsi que les accords que vous avez signés."
				},
				"home-fe": {
					"title": "Accueil Paiements"
				},
				"boosters-home-fe": {
					"title": "C’est parti"
				},
				"marketing-home-fe": {
					"title": "Accueil Marketing"
				},
				"nko-app": {
					"title": "Applications en ligne Klarna"
				},
				"orders-fe": {
					"title": "Gestionnaire de commande",
					"description": "Cherchez et gérez vos commandes passées avec Klarna.",
					"nkoLinkText": "Accéder aux commandes précédentes"
				},
				"nko-orders": {
					"title": "Commandes (KO)",
					"description": "Cherchez et gérez vos commandes passées avec Klarna En ligne."
				},
				"settlements-fe": {
					"title": "Règlements",
					"description": "Consultez vos transactions et générez des rapports pour effectuer vos rapprochements.",
					"nkoLinkText": "Accéder aux anciens rapports"
				},
				"nko-settlements": {
					"title": "Règlements (KO)",
					"description": "Trouvez les rapports sur les commandes et les paiements effectués avec Klarna En ligne."
				},
				"logs-app-fe": {
					"title": "Journaux de développeurs",
					"description": "Utilisez les journaux de l'API pour résoudre les problèmes d'intégration."
				},
				"users-fe": {
					"title": "Gestionnaire utilisateur",
					"description": "Invitez de nouveaux utilisateurs et gérez les utilisateurs existants sur le Portail."
				},
				"settings-fe": {
					"title": "Paramètres de paiement",
					"description": ""
				},
				"nko-settings": {
					"title": "Paramètres (KO)",
					"description": "Gérez les paramètres, rapports et coordonnées de votre boutique."
				},
				"seller-fe": {
					"title": "En boutique",
					"description": "Créez de nouvelles commandes avec la solution en boutique de Klarna."
				},
				"products-fe": {
					"title": "S’enregistrer",
					"description": "Configurez votre compte pour permettre les paiements."
				},
				"upstream-fe": {
					"title": "Messages intégrés",
					"description": "Vendez plus grâce à des offres de financement adaptées et personnalisées."
				},
				"lending-fe": {
					"title": "Financement des entreprises",
					"description": "Le financement professionnel, en toute simplicité."
				},
				"branding-fe": {
					"title": "Manager de marque",
					"description": "Contrôlez la manière dont votre marque est présentée dans Klarna."
				},
				"disputes-fe": {
					"title": "Litiges",
					"description": "Gérez vos litiges."
				},
				"logistics-fe": {
					"title": "Paramètres d'expédition",
					"description": "Gérez l'ensemble de votre chaîne logistique au même endroit."
				},
				"developers": {
					"title": "Développeurs"
				},
				"klarna-docs": {
					"title": "Klarna Docs"
				},
				"support": {
					"title": "Assistance",
					"description": "Vérifier et gérer vos demandes d'assistance."
				},
				"integration-guides-fe": {
					"title": "Guides d'intégration",
					"description": ""
				},
				"klarna-search-b2b-portal": {
					"title": "Search & Compare",
					"description": "Lancez-vous avec le puissant moteur de recherche et de comparaison de Klarna. Accédez à des statistiques et à des outils conçus pour améliorer votre visibilité."
				},
				"siwk-fe": {
					"title": "Se connecter avec Klarna",
					"description": "Gérer votre intégration de Se connecter avec Klarna."
				},
				"kec-fe": {
					"title": "Paiement express",
					"description": "Gérer votre intégration de Paiement express."
				},
				"partner-self-service": {
					"title": "Gérant du magasin",
					"description": "Gérer votre boutique Klarna.",
					"nkoLinkText": "Affichez et gérez les paramètres de votre boutique"
				}
			},
			"ui": {
				"tabs": {
					"home": "Paiements",
					"growth": "Marketing de croissance",
					"boosters": "Boosters de conversion",
					"badge": "Nouveautés"
				},
				"buttons": {
					"backButton": "Retour"
				}
			},
			"errorBoundary": {
				"contact": "Veuillez rafraîchir la page. {br}Si l'erreur persiste, contactez <supportLink>le service d'assistance</supportLink> en fournissant l'ID {errorId}.",
				"errorDescription": "ID d'erreur -  {errorId}",
				"reloadButton": "Rafraîchir",
				"title": "Malheureusement, une erreur s'est produite"
			},
			"maintenance": {
				"pageTitle": "En cours de maintenance",
				"title": "Le portail des marchands est actuellement en cours de maintenance planifiée",
				"summary": "Nous serons bientôt de retour",
				"scheduledSummary": "Nous serons de retour à {until}"
			},
			"errorTemplates": {
				"errorDescription": "Erreur : {code}",
				"backButton": "Retour",
				"reloadButton": "Rafraîchir",
				"default": {
					"errorDescription": "ID d'erreur -  {errorId}",
					"title": "Malheureusement, une erreur s'est produite",
					"contact": "Veuillez rafraîchir la page. Si l'erreur persiste, contactez <supportLink>le service d'assistance</supportLink> en fournissant l'ID {errorId}."
				},
				"internalServerError": {
					"title": "Oups, quelque chose ne s’est pas passé comme prévu",
					"message": "Nous n’avons pas pu exécuter votre demande pour une raison que nous ignorons. Vous pouvez réessayer ou revenir plus tard."
				},
				"notFound": {
					"title": "Il n'y a rien à voir ici !",
					"message": "Cette page n'existe pas ou vous avez tapé une adresse incorrecte dans votre navigateur. Vérifiez l'adresse puis réessayez."
				},
				"forbidden": {
					"title": "Vous n'avez pas accès à cette page",
					"message": "Votre ID de marchand n'a pas accès à cette page. Si vous pensez qu'il s'agit d'une erreur, veuillez contacter le service d'assistance ou contacter l'administrateur du compte de votre entreprise."
				}
			},
			"codeSnippet": {
				"clipboardButtonLabel": "Copier vers le presse-papiers",
				"clipboardButtonLabelSuccess": "Copié!"
			}
		},
		"blackFriday": {
			"v1": {
				"title": "Black Friday",
				"intro": "L’une des journées de vente les plus importantes est en approche. Lors du Black Friday, nous serons disponibles pour vous fournir des statistiques en temps quasi réel de vos ventes. En attendant, préparons-nous à des ventes réussies!",
				"countdown": "Compte à rebours : #dj #hh #mm #ss…",
				"box1": {
					"title": "Des dépenses 3,5 fois plus élevées*",
					"text": "L’année dernière, les acheteurs* Klarna ont dépensé en moyenne 3,5 fois plus lors du Black Friday que lors de toute autre journée de l’année.",
					"footnote": "* Moyenne réalisée sur l’ensemble des catégories et des régions des marchands Klarna."
				},
				"box2": {
					"title": "Volume de ventes 2,5 fois plus élevé*",
					"text": "L’année dernière, les marchands Klarna ont généré 2,5 fois plus de volume de ventes lors du Black Friday que lors de toute autre journée de l’année.",
					"footnote": "* Moyenne réalisée sur l’ensemble des catégories et des régions des marchands Klarna."
				},
				"sectionTitle": "Stimulez vos ventes lors du Black Friday",
				"marketing": {
					"title": "Faites promouvoir votre entreprise avant et pendant le Black Friday",
					"text": "Attirez davantage d’acheteurs dans votre boutique au cours de la saison de vente du Black Friday avec ces ressources libres d’utilisation pour les réseaux sociaux et les campagnes e-mail."
				},
				"branding": {
					"title": "Démarquez-vous dans l’application Klarna",
					"text": "12 millions d’acheteurs utilisent l’application Klarna tous les mois. Assurez-vous de vous démarquer dans l’application au cours de ce Black Friday en ajoutant et en mettant à jour vos informations de marque dans le Portail marchand."
				},
				"cta": "C’est parti",
				"close": "Fermer"
			},
			"v2": {
				"title": "Black Friday week",
				"orderVolume": "Volume des commandes",
				"orderCountTitle": "Commandes créées",
				"orderCount": "{count} commandes",
				"box": {
					"title": "Vous souhaitez augmenter votre taux de conversion?",
					"subtitle1": "Messages intégrés",
					"subtitle2": "Des messages promotionnels qui instruisent.",
					"text": "Notre outil Messages intégrés vous aide à accroître vos ventes et la valeur moyenne des commandes grâce à des messages promotionnels. Indiquez à vos clients les différentes options de paiement dont ils disposent lors de leur arrivée sur votre site et tout au long de leur navigation.",
					"cta": "C’est parti"
				},
				"selector": {
					"done": "Terminé",
					"applySelection": "Appliquer la/les # sélection(s)",
					"selectAll": "Tout sélectionner",
					"allOptions": "Toutes les options sélectionnées",
					"someOptions": "# options sélectionnées",
					"storeMultiSelectLabel": "Choisir # boutiques",
					"allStores": "Toutes les boutiques"
				},
				"currencyLabel": "Choisir la devise",
				"storeLabel": "Choisir la boutique"
			},
			"v3": {
				"title": "Black Friday week",
				"text": "Voici vos ventes réalisées pendant l'une des semaines les plus importantes de l'année.",
				"summaryTitle": "Synthèse",
				"orderVolume": "Volume total",
				"orderCount": "Total des commandes",
				"summaryRange": "23-30 November",
				"comparisonLastWeek": "par rapport au même jour, la semaine dernière",
				"tabs": {
					"monday": "Lundi",
					"tuesday": "Mardi",
					"wednesday": "Mercredi",
					"thursday": "Jeudi",
					"blackFriday": "Black Friday",
					"saturday": "Samedi",
					"sunday": "Dimanche",
					"cyberMonday": "Cyber Monday"
				}
			}
		},
		"mpSatSurvey": {
			"marble": {
				"topbarButtonLabel": "Commentaires sur notre nouveau look",
				"step1": {
					"welcome": "Que pensez-vous de notre nouveau look ?",
					"satisfied": "Très satisfait",
					"unsatisfied": "Très insatisfait"
				},
				"step2": {
					"textAreaLabel": "Autre (votre avis)",
					"textAreaMaxLength": "Longueur maximale atteinte",
					"submit": "Envoyer",
					"content": {
						"titlePositive": "Qu'est-ce que vous aimez le plus?",
						"titleNegative": "Qu’est-ce qui vous déplaît le plus ?",
						"tags": {
							"color": "Couleur et style",
							"navigation": "Navigation :",
							"names": "Nom des pages",
							"structure": "Structure des pages"
						}
					}
				},
				"step3": {
					"title": "Merci !",
					"description": "Votre avis est apprécié."
				}
			},
			"buttonLabel": "Donner son avis",
			"errorMessage": "Une erreur s’est produite lors de l’envoi de vos commentaires.",
			"retry": "Réessayer",
			"step1": {
				"welcome1": "Dans quelle mesure êtes-vous satisfait(e) du",
				"welcome2": "Portail des marchands ?",
				"emojiSelector": {
					"angry": "Très insatisfait(e)",
					"unhappy": "Relativement insatisfait(e)",
					"neutral": "Sans avis",
					"happy": "Satisfait(e)",
					"love": "Très satisfait(e)"
				},
				"remindMeText": "Vous ne souhaitez pas partager votre avis ?",
				"remindMeLink": "Me rappeler cette enquête plus tard.",
				"dontShowAgainLink": "Ne plus montrer cela."
			},
			"step2": {
				"textAreaLabel": "Autre (votre avis)",
				"textAreaMaxLength": "Longueur maximale atteinte",
				"submit": "Envoyer",
				"content": {
					"negative": {
						"title": "Nous sommes désolés d’apprendre cela.",
						"description": "Selon vous, quels aspects du Portail des commerçants devrions-nous améliorer ? (Sélectionnez tout ce qui s’applique)",
						"tags": {
							"loading": "Délai de chargement",
							"menu": "Organisation du menu",
							"mobile": "Version mobile",
							"filter": "Filtrage des données",
							"complexity": "Efficacité des actions"
						}
					},
					"positive": {
						"title": "Très bonne nouvelle !",
						"description": "Cependant, nous cherchons toujours à nous améliorer. Quels éléments pourraient rendre votre expérience sur le Portail des commerçants encore meilleure ?",
						"tags": {
							"views": "Vues personnalisées",
							"looks": "Modification dans la présentation et le fonctionnement",
							"tours": "Visites guidées",
							"shortcuts": "Raccourcis vers les tâches"
						}
					}
				}
			},
			"step3": {
				"title": "Merci ! Une dernière chose.",
				"content1": "Nous souhaiterions vous inviter à participer à un entretien pour nous parler de votre expérience sur notre Portail des commerçants.",
				"content2": "Souhaitez-vous participer ?",
				"content3": "Si la réponse est oui, nous vous contacterons par le biais de votre adresse e-mail enregistrée pour organiser l’entretien.",
				"yes": "Oui, avec plaisir",
				"no": "Non, merci"
			},
			"step4": {
				"yesTitle": "Génial ! Nous vous contacterons bientôt.",
				"noTitle": "C’est compris. Merci !",
				"content1": "Merci d’avoir pris le temps de répondre à ces questions.",
				"content2": "Vous pouvez maintenant fermer cette fenêtre."
			}
		},
		"boost": {
			"integrationTypeModal": {
				"title": "Comment souhaitez-vous configurer {productName} ?",
				"labels": {
					"plugin": "Configuration à l'aide d'un plugin",
					"web": "Configuration à l'aide de Web SDK"
				}
			}
		}
	}
};