module.exports = {
	"pt": {
		"credentials": {
			"addOriginModal": {
				"buttonPrimaryText": "Registar",
				"buttonSecondaryText": "Cancelar",
				"description": "Registe qualquer domínio de origem em que espera usar o Web SDK. Se precisar de registar mais do que um, poderá fazê-lo mais tarde.",
				"errorMessage": "Ocorreu um erro. Tente novamente mais tarde ou contacte o apoio se o problema persistir.",
				"inputLabel": "Introduza o resto do seu URL",
				"title": "Registar origem permitida"
			},
			"clientKeyBlock": {
				"mainText": "O seu identificador de cliente é configurado diretamente no código com o domínio de origem que forneceu.",
				"secondaryText": "Para continuar a geri-lo, siga a ligação para a",
				"linkDescription": "página de identificadores de clientes",
				"linkLabel": "Fechar"
			},
			"clientKeyField": {
				"label": "Identificador de cliente",
				"defaultErrorMessage": "Identificador de cliente em falta"
			},
			"copyToClipboardButton": {
				"label": "Copiar",
				"labelOnCopy": "Copiado"
			},
			"originField": {
				"label": "Domínio de origem",
				"tooltipMainText": "Para registar mais domínios de origem ou gerir os que existem, vá à",
				"tooltipLinkLabel": "página de identificadores de clientes"
			},
			"originInputField": {
				"label": "Introduza o resto do seu URL",
				"validationError": "Introduza um URL de domínio válido",
				"invalidProtocolError": "O URL tem de começar com http:// ou https://"
			},
			"addApiKeySuccessModal": {
				"title": "",
				"description": "Guarde as credenciais seguintes no seu dispositivo ou noutra localização segura.",
				"warning": "A palavra-passe será revelada apenas desta vez e desaparecerá assim que fechar esta janela.",
				"checkbox": "Guardei as credenciais no meu dispositivo.",
				"download": "Transferir como .txt"
			},
			"common": {
				"password": "Palavra-passe",
				"username": "Nome de utilizador",
				"close": "Fechar"
			}
		}
	}
};