import { ButtonPrimary } from '@klarna/bubble-ui';
import { hooks as i18nHooks } from 'mage-i18n';
import { TrackImpression } from 'mage-insights';
import React from 'react';
import useGenerateButton from './useGenerateButton';
export default function GenerateButton() {
    const { handleClick, areActionsEnabled, event } = useGenerateButton();
    const t = i18nHooks.useTranslator();
    return (React.createElement(TrackImpression, { event: event },
        React.createElement(ButtonPrimary, { size: 'medium', onClick: handleClick, disabled: !areActionsEnabled, "data-testid": 'generate-clientid-button' }, t('home-fe.boosters-home-v03.client-identifier-section.generate-button'))));
}
