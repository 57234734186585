export const PLUGINS = [
    {
        id: 'adobe-commerce',
        name: 'Adobe commerce',
        logo: {
            imageName: 'adobe.svg',
            height: '20px'
        },
        docUrl: 'https://docs.klarna.com/platform-solutions/adobe-commerce/adobe-commerce-244-and-above/kp-overview/?utm_source=merchant%20portal',
        pluginLink: 'https://commercemarketplace.adobe.com/klarna-m2-klarna.html'
    },
    {
        id: 'woo-commerce',
        name: 'WooCommerce',
        logo: {
            imageName: 'woo.svg',
            height: '24px'
        },
        docUrl: 'https://docs.klarna.com/platform-solutions/woocommerce/how-to-install-klarna-payments-on-woocommerce/?utm_source=merchant%20portal',
        pluginLink: 'https://woocommerce.com/products/klarna-payments/'
    },
    {
        id: 'prestashop',
        name: 'PrestaShop',
        logo: {
            imageName: 'prestashop.svg',
            height: '24px'
        },
        docUrl: 'https://docs.klarna.com/platform-solutions/prestashop/?utm_source=merchant%20portal',
        pluginLink: 'https://addons.prestashop.com/en/other-payment-methods/43440-klarna-payments-official.html'
    },
    {
        id: 'salesforce-commerce',
        name: 'Salesforce Commerce',
        logo: {
            imageName: 'salesforce.svg',
            height: '24px'
        },
        docUrl: 'https://github.com/klarna/sfcc-klarna-payments/tree/master/documentation',
        pluginLink: 'https://github.com/klarna/sfcc-klarna-payments'
    },
    {
        id: 'sap-commerce',
        name: 'SAP Commerce',
        logo: {
            imageName: 'sap.png',
            height: '16px'
        },
        docUrl: 'https://github.com/klarna/SAP-Commerce-klarna-payments/tree/master/docs',
        pluginLink: 'https://github.com/klarna/SAP-Commerce-klarna-payments'
    }
];
