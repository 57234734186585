module.exports = {
	"it": {
		"config": {
			"environments": {
				"development": "Ambiente di sviluppo",
				"staging": "Ambiente di allestimento",
				"playground": "Ambiente di test",
				"production": "Ambiente di produzione"
			}
		}
	}
};