import { IconInformation, SpacerVertical, Typography } from '@klarna/bubble-ui';
import { Tooltip } from '@klarna/mp-ui';
import { AddApiKeySuccessModal } from '@merchant-portal/credentials';
import { hooks as i18nHooks } from 'mage-i18n';
import React from 'react';
import styled from 'styled-components';
import { useApiKey } from '../../hooks/useApiKey';
import ApiKeyBanner from './ApiKeyBanner';
import GenerateButton from './GenerateButton';
import ManageApiKeyLink from './ManageApiKeyLink';
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-j96lc7" }) `
  border-radius: 16px;
  border: 1px solid #E5E5E5;
  background-color: rgb(255, 255, 255);
  padding: 24px;
  text-align: left;  
  position: relative;
`;
const TooltipContainer = styled.div.withConfig({ displayName: "TooltipContainer", componentId: "sc-o3ntbt" }) `
  position: absolute;
  top: 24px;
  right: 24px;
`;
const TRANSLATION_PREFIX = 'home-fe.boosters-home-v03.api-key';
export default function ApiKeySection() {
    const { isSuccessModalOpen, createApiKeyData, hasAlreadyActiveKeys, onSuccessModalClose } = useApiKey();
    const t = i18nHooks.useTranslator();
    return (React.createElement(Container, null,
        React.createElement(TooltipContainer, null,
            React.createElement(Tooltip, { text: t(`${TRANSLATION_PREFIX}.tooltip`), placement: 'bottom-end' },
                React.createElement(IconInformation, null))),
        React.createElement(Typography, { textToken: 'text-style/headings/titles/bold/grande' }, t(`${TRANSLATION_PREFIX}.title`)),
        React.createElement(SpacerVertical, { spaceToken: 'space.100' }),
        React.createElement(Typography, { textToken: 'text-style/text/paragraphs/body/regular' }, t(`${TRANSLATION_PREFIX}.description`)),
        React.createElement(SpacerVertical, { spaceToken: 'space.300' }),
        hasAlreadyActiveKeys &&
            React.createElement(React.Fragment, null,
                React.createElement(ApiKeyBanner, null),
                React.createElement(SpacerVertical, { spaceToken: 'space.300' })),
        React.createElement(GenerateButton, null),
        hasAlreadyActiveKeys &&
            React.createElement(React.Fragment, null,
                React.createElement(SpacerVertical, { spaceToken: 'space.300' }),
                React.createElement(ManageApiKeyLink, null)),
        isSuccessModalOpen &&
            React.createElement(AddApiKeySuccessModal, { data: createApiKeyData, onClose: onSuccessModalClose })));
}
