import { Separator, SpacerVertical } from '@klarna/bubble-ui';
import { Layout, useResponsiveContext } from '@klarna/mp-ui';
import { TrackImpression } from 'mage-insights';
import React from 'react';
import styled from 'styled-components';
import AccessBanner from '../components/AccessBanner';
import LoaderOverlay from '../components/LoaderOverlay';
import MidSelector from '../components/MidSelector';
import { useShowMidSelector } from '../hooks/useShowMidSelector';
import { ApiKeySection } from './ApiKeySection';
import ClientIdSection from './ClientIdSection/ClientIdSection';
import ErrorBanner from './ErrorBanner';
import NonPlatformIntegrationMessage from './NonPlatformIntegrationMessage';
import { PromotionBanner } from './PromotionBanner';
import StepConfigureBoostFeatures from './StepConfigureBoostFeatures';
import { StepInstallPlugin } from './StepInstallPlugin';
import StepStartSetup from './StepStartSetup';
const Box = styled.div.withConfig({ displayName: "Box", componentId: "sc-mtbbt9" }) `
  text-align: center;
`;
const StepsContainer = styled.div.withConfig({ displayName: "StepsContainer", componentId: "sc-78i1pa" }) `
  margin-right: 24px;
`;
const analyticsEvent = {
    category: 'v3/boost-home/boost-dashboard-page',
    action: 'impression'
};
export default function Page() {
    const { isMobile, isTablet } = useResponsiveContext();
    const { mustShowMidSelector } = useShowMidSelector();
    return (React.createElement(TrackImpression, { event: analyticsEvent },
        React.createElement("div", { id: 'boost-installation-guide' },
            React.createElement(Layout.Grid, null,
                mustShowMidSelector && (React.createElement(Layout.Section, null,
                    React.createElement(Layout.Column, { mobileWidth: 12, tabletWidth: 12, desktopWidth: 12 },
                        React.createElement(MidSelector, { maxWidth: '320px' })))),
                React.createElement(Layout.Section, null,
                    React.createElement(Layout.Column, { mobileWidth: 12, tabletWidth: 12, desktopWidth: 12 },
                        React.createElement(PromotionBanner, null))),
                React.createElement(Layout.Section, null,
                    React.createElement(Layout.Column, { mobileWidth: 12, tabletWidth: 12, desktopWidth: 12 },
                        React.createElement(ErrorBanner, null),
                        React.createElement(AccessBanner, null))),
                React.createElement(SpacerVertical, { spaceToken: 'space.500' }),
                React.createElement(Layout.Section, null,
                    React.createElement(Layout.Column, { mobileWidth: 12, tabletWidth: 12, desktopWidth: 8, tabletOrder: 2, mobileOrder: 2, desktopOrder: 1 },
                        React.createElement(Box, null,
                            React.createElement(StepsContainer, null,
                                React.createElement(StepInstallPlugin, null),
                                React.createElement(SpacerVertical, { spaceToken: 'space.500' }),
                                React.createElement(Separator, { colorToken: 'colors/borders/subtle-floating' }),
                                React.createElement(SpacerVertical, { spaceToken: 'space.500' }),
                                React.createElement(StepStartSetup, null),
                                React.createElement(SpacerVertical, { spaceToken: 'space.500' }),
                                React.createElement(Separator, { colorToken: 'colors/borders/subtle-floating' }),
                                React.createElement(SpacerVertical, { spaceToken: 'space.500' }),
                                React.createElement(StepConfigureBoostFeatures, null),
                                React.createElement(SpacerVertical, { spaceToken: 'space.500' }),
                                React.createElement(Separator, { colorToken: 'colors/borders/subtle-floating' })))),
                    React.createElement(Layout.Column, { mobileWidth: 12, tabletWidth: 12, desktopWidth: 4, tabletOrder: 1, mobileOrder: 1, desktopOrder: 2 },
                        React.createElement(Box, null,
                            React.createElement(Box, null,
                                React.createElement(ClientIdSection, null)),
                            React.createElement(SpacerVertical, { spaceToken: "space.300" }),
                            React.createElement(Box, null,
                                React.createElement(ApiKeySection, null))),
                        (isMobile || isTablet) && React.createElement(SpacerVertical, { spaceToken: 'space.500' }))),
                React.createElement(Layout.Section, null,
                    React.createElement(Layout.Column, { mobileWidth: 12, tabletWidth: 12, desktopWidth: 12 },
                        React.createElement(SpacerVertical, { spaceToken: 'space.500' }),
                        React.createElement(NonPlatformIntegrationMessage, null)))),
            React.createElement(LoaderOverlay, null))));
}
