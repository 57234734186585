import styled from 'styled-components';
export const StepContainer = styled.div.withConfig({ displayName: "StepContainer", componentId: "sc-1xnwagz" }) `
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
`;
export const StepContent = styled.div.withConfig({ displayName: "StepContent", componentId: "sc-hco7ok" }) `
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;
