module.exports = {
	"sv": {
		"config": {
			"environments": {
				"development": "Utvecklingsmiljö",
				"staging": "Kontrollmiljö",
				"playground": "Testmiljö",
				"production": "Produktionsmiljö"
			}
		}
	}
};