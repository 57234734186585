module.exports = {
	"pt": {
		"home-fe": {
			"boosters-home-v03": {
				"install-plugin": {
					"stepNumber": "1",
					"title": "Instalar plugin",
					"description": "Selecione a sua plataforma de comércio eletrónico para podermos criar uma ligação para acelerar a sua instalação",
					"selectorLabel": "Selecionar plataforma",
					"getPluginButtonLabel": "Obter plugin",
					"klarnaDocsLinkLabel": "Saiba mais nos documentos Klarna"
				},
				"start-setup": {
					"stepNumber": "2",
					"title": "Ativar plugin",
					"description": "Gere e copie o identificador de cliente e a chave API; precisará deles para ativar o plugin."
				},
				"promotion-banner": {
					"title": "Klarna para plataformas",
					"subtitle": "Faça crescer o seu negócio com um único plugin, dando-lhe acesso a várias funcionalidades, para aumentar as vendas e melhorar experiências de compras sem custos extra",
					"banner-cards": {
						"osm-title": "Aumente o valor médio da encomenda com <span>Mensagem no local</span>",
						"kec-title": "Impulsione uma maior conversão com a Finalização de compra expresso",
						"siwk-title": "Atraia novos clientes com <span>Iniciar sessão com Klarna</span>"
					}
				},
				"configure-boost-features": {
					"stepNumber": "3",
					"title": "Personalizar a colocação de funcionalidades",
					"description": "Este é um passo opcional para alterar o aspeto das funcionalidades na sua loja. Para fazer isto, precisa de ter concluído o passo 2.",
					"no-credentials": "Certifique-se de gerar primeiro o identificador do cliente e a chave API para continuar com este passo.",
					"apps": {
						"osm": {
							"link-label": "Personalizar a mensagem no local",
							"description": "Personalize a mensagem no local para se adequar à sua marca, ajustando o tema e selecionando colocações"
						},
						"kec": {
							"link-label": "Personalizar a Finalização de compra expresso",
							"description": "Personalize o botão de Finalização de compra expresso para se adequar à sua marca, ajustando o tema e a forma e selecionando colocações"
						},
						"siwk": {
							"link-label": "Configurar o início de sessão com Klarna",
							"description": "Selecione o âmbito para o início de sessão com Klarna e adicione um URL de redirecionamento. O âmbito são as informações recolhidas dos seus clientes e o URL de direcionamento é o que é utilizado se os pop-ups forem bloqueados pelos clientes."
						}
					}
				},
				"client-identifier-section": {
					"title": "Identificador de cliente",
					"description": "O identificador único para configuração",
					"manage-link": "Gerir identificador de cliente e origem",
					"generate-button": "Gerar",
					"tooltip": "Gere e copie o identificador do cliente. Precisa dele para iniciar a configuração do plugin no portal da sua plataforma."
				},
				"api-key": {
					"warning-banner": {
						"content": "Já tem credenciais de API ativas. Antes de gerar outras novas, certifique-se de que desativou as que não estiver a usar."
					},
					"title": "Chave API Klarna",
					"description": "Chave API para configuração",
					"tooltip": "Tenha em atenção que não mostramos a sua chave API secreta novamente depois de a gerar.",
					"generate-button": "Gerar",
					"manageApiKey": "Gerir Chave API"
				},
				"non-platform-integration": {
					"question": "Não está a utilizar Klarna para plataformas?",
					"link": "Veja como integrar diretamente com a Klarna"
				}
			},
			"boosters-home": {
				"welcome-banner": {
					"title": "Liberte todo o poder da Klarna",
					"text": "Recursos concebidos para tornar os visitantes em compradores poderosos. Melhore cada passo da experiência de compra, desde a pesquisa à finalização da compra."
				},
				"plugin-carousel": {
					"title": "Configuração sem código com um plugin",
					"overviewLink": "Ver todas as plataformas",
					"directIntegrationLabel": "Não está a utilizar Klarna para plataformas?",
					"directIntegrationLink": "Veja como integrar diretamente com a Klarna"
				},
				"booster-apps": {
					"title": "Mais informações",
					"kec-fe": {
						"title": "Torne a finalização de compra 5x mais rápida",
						"description": "Menos cliques significa menos abandonos. Impulsione a conversão e minimize o abandono do carrinho com finalização de compra expresso nas páginas do produto e do carrinho.",
						"callToAction": "Mais sobre a finalização de compra expresso"
					},
					"siwk-fe": {
						"title": "Início de sessão em redes sociais para fazer compras",
						"description": "Os compradores têm uma opção de início de sessão rápida e segura, com um clique. Você obtém dados de perfil de compradores melhorados e práticos.",
						"callToAction": "Mais sobre Iniciar sessão com a Klarna"
					},
					"upstream-fe": {
						"title": "Torne os visitantes em compradores",
						"description": "Maximize a conversão com mensagens promocionais dinâmicas durante todo o percurso de compra.",
						"callToAction": "Mais sobre Mensagem no local"
					},
					"branding-fe": {
						"title": "Melhore a visibilidade da sua loja",
						"description": "Efetue a gestão da sua marca na aplicação Klarna carregando um logótipo da loja, um ícone e um URL.",
						"callToAction": "Configurar o Gestor de marcas"
					}
				}
			},
			"boosters-plugins": {
				"title": "Configuração do plugin",
				"description": "Comece om os nossos plugins e certifique-se de que está tudo pronto para ficar disponível."
			},
			"boosters-plugin": {
				"title": "Klarna para {pluginName}",
				"description": "Realize as ações abaixo para ativar pagamentos Klarna e/ou impulsionar funcionalidades com a nossa configuração sem código.",
				"access-warning": {
					"both": "Não tem permissão para {selectedStoreName} ({selectedMid}) para gerar chaves API e identificador de clientes. Contacte o seu utilizador administrador e solicite acesso às definições de pagamento para continuar.",
					"client": "Não tem permissão para {selectedStoreName} ({selectedMid}) para gerar o identificador de clientes. Contacte o seu utilizador administrador e solicite acesso às definições de pagamento para continuar.",
					"api": "Não tem permissão para {selectedStoreName} ({selectedMid}) para gerar chaves API. Contacte o seu utilizador administrador e solicite acesso às definições de pagamento para continuar."
				},
				"back": "Voltar",
				"instructions": {
					"api-key": {
						"title": "Ativar Pagamento Klarna",
						"content": "Precisa de gerar e copiar chaves API para poder utilizá-las durante a configuração.",
						"link-label": "Saiba mais nos documentos Klarna.",
						"generate": "Gerar chaves API",
						"active-warning": {
							"content": "Já tem credenciais de API ativas. Antes de gerar outras novas, certifique-se de que desativou as que não estiver a usar.",
							"link": "Gerir Chaves API"
						},
						"active-warning-api-keys": {
							"content": "Já tem credenciais de API ativas. Antes de gerar outras novas, certifique-se de que desativou as que não estiver a usar.",
							"link": "Gerir Chaves API"
						}
					},
					"client-id": {
						"title": "Ativar funcionalidades de impulsionar",
						"content": "Para adicionar a finalização de compra expresso e as mensagens no local, precisa de gerar e copiar o seu identificador de clientes, para poder utilizá-los durante a configuração.",
						"generate": "Gerar identificador de cliente",
						"manage": "Gerir identificador de cliente"
					},
					"plugin-doc": {
						"title": "Configurar funcionalidades de impulsionar",
						"content": "Para finalizar a sua configuração, precisa de configurar a finalização de compra expresso e mensagens no local nas definições da sua plataforma."
					}
				},
				"error-messages": {
					"default": "Ocorreu um erro. Tente novamente ou procure ajuda.",
					"fetch-data": "Ocorreu um erro ao obter os seus dados. Tente novamente ou procure ajuda.",
					"create-api-key": "Ocorreu um erro ao criar as suas chaves API. Tente novamente ou procure ajuda.",
					"create-client-id": "Ocorreu um erro ao criar o identificador dos seus clientes. Tente novamente ou procure ajuda."
				}
			},
			"marketing-home": {
				"description": "Boas-vindas aos nossos produtos de marketing. Comece a explorar abaixo.",
				"essentials": {
					"title": "Marketing",
					"cta-label": "Mais informações",
					"branding-fe": {
						"description": "Configure e faça a gestão da sua marca. Consiga que os clientes tenham a melhor impressão possível de si em todo o ecossistema da Klarna, incluindo a nossa aplicação smoooth."
					},
					"klarna-search-b2b-portal": {
						"description": "Comece com o motor de busca poderoso e a comparação de compras da Klarna. Ganhe acesso a estatísticas e ferramentas concebidas para melhorar a sua visibilidade."
					}
				},
				"external-apps": {
					"title": "Aplicações",
					"cta-label": "Abrir aplicação"
				},
				"popOvers": {
					"searchCompare": {
						"title": "Começar com Pesquisar e Comparar",
						"content": "Encontre aqui estatísticas e ferramentas que podem ajudar a aumentar a sua visibilidade no serviço de comparação de compras de Klarna."
					}
				}
			},
			"no-apps": {
				"title": "Sem aplicações disponíveis!",
				"subtitle": "Contacte o seu administrador para o(a) ajudar a aceder às aplicações de que precisa."
			},
			"contact": {
				"title": "Precisa de ajuda com alguma coisa?",
				"paragraph": "Se tiver quaisquer dúvidas, não hesite em contactar-nos e nós ajudaremos a esclarecê-las.",
				"label": "Contacte-nos"
			},
			"important-banner": {
				"title": "",
				"content": "",
				"linkLabel": ""
			},
			"two-factor-home-banner": {
				"banner-text": "Torne a sua conta mais segura com a autenticação de dois fatores.",
				"banner-link": "Configurar"
			},
			"two-factor-opt-in": {
				"title": "Configurar a autenticação de dois fatores",
				"intro-paragraph": "Para aumentar a segurança da sua conta, encorajamo-lo(a) a configurar a autenticação de dois fatores hoje mesmo.",
				"help-link-text": "Clique aqui",
				"help-text": " para saber mais sobre como a configurar e sobre como o(a) protege.",
				"warning-text": "Certifique-se de que tem o seu smartphone e acesso à internet antes de prosseguir",
				"continue-button": "Começar a configuração"
			},
			"accept-invite-modal": {
				"title": "Aceitar convites pendentes",
				"description": "Recebeu um convite para aceder às lojas seguintes. Tenha em atenção que pode demorar alguns minutos para a sua escolha ter efeito.",
				"accept-all-button": "Aceitar todos",
				"decline-all-button": "Declinar todos",
				"save-button": "Guardar",
				"error": "Ocorreu um problema. Tente novamente mais tarde!",
				"invite": {
					"accept-label": "Aceitar",
					"decline-label": "Declinar"
				}
			},
			"capture-orientation": {
				"title": "É a primeira vez que está a utilizar a Klarna?",
				"sub-title": "Siga estes passos para aprender as noções básicas:",
				"order-label": "Receber uma encomenda",
				"capture-label": "Capturar a encomenda",
				"payout-label": "Receber pagamento",
				"capture-cta": "Capturar esta encomenda",
				"payout-cta": "Aplicação de Liquidações",
				"dismiss-cta": "Ignorar",
				"order-step": "Antes de mais nada, devemos aguardar por uma encomenda efetuada pelos seus clientes.",
				"capture-step": "Parabéns pela sua primeira encomenda.\nPara receber o pagamento, o próximo passo é enviar e capturar a sua encomenda.",
				"payout-step": "Parabéns por capturar a sua primeira encomenda.\nAgora, receberá um pagamento da nossa parte em linha com os prazos definidos no seu Contrato com a Klarna.\nPara obter mais detalhes sobre o seu primeiro pagamento, vá a",
				"completed-step": "Parabéns pelo seu primeiro pagamento.\nBem-vindo(a) a uma bela jornada com a Klarna.\nSinta-se à vontade para explorar as outras aplicações que oferecemos abaixo."
			},
			"banner": {
				"common": {
					"branding-fe": {
						"content": "Acabámos de lançar a nossa nova aplicação de Imagem de marca!",
						"linkLabel": "Assuma o controlo da sua marca agora mesmo!"
					},
					"onSiteMessaging": {
						"title": "Torne os visitantes em compradores com Mensagem no local",
						"text": "As compras começam antes de os seus clientes chegarem ao carrinho. Com Mensagem no local, aumentará o conhecimento sobre as opções de pagamento flexíveis da Klarna e outros benefícios no início do funil de compras.",
						"cta": "Ver"
					}
				}
			},
			"nko": {
				"dialog": {
					"title": "Estamos a implementar algumas funcionalidades empolgantes para a sua empresa.",
					"subtitle": "E gostaríamos de o(a) conhecer melhor.",
					"explore": "Sim, vamos explorar isto!",
					"name-field": "Então, como gostaria de ser chamado(a)?",
					"terms-pre": "Li e concordo com os ",
					"terms-text": "Termos e Condições"
				}
			},
			"insightsWidget": {
				"title": "Vendas hoje",
				"orderCount": "Encomendas criadas",
				"orderVolume": "Volume de vendas",
				"orderVolumeAverage": "Valor médio por encomenda",
				"currencyFootnote": "Apresentado em {currency}",
				"link": "Ver encomendas",
				"allStores": "Todas as lojas",
				"selectAllOptionText": "Selecionar tudo",
				"deselectAllOptionText": "Limpar seleção",
				"closeText": "Concluído",
				"closeTextWithSelection": "Aplicar # seleção(ões)",
				"elapsedTime": "Última encomenda recebida há {hours, plural, =0 {} =1 {uma hora} other {# horas}} {minutes, plural, =0 {} =1 {um minuto} other {# minutos}}",
				"error": {
					"content": "Infelizmente, as estatísticas da sua encomenda não foram carregadas corretamente."
				},
				"relativeDate": {
					"today": "Hoje",
					"yesterday": "Ontem"
				},
				"weekDays": {
					"1": "Segunda-feira",
					"2": "Terça-feira",
					"3": "Quarta-feira",
					"4": "Quinta-feira",
					"5": "Sexta-feira",
					"6": "Sábado",
					"7": "Domingo"
				}
			},
			"blackFridayWidget": {
				"title": "Chegou a semana da Black Friday.",
				"text": "Siga as estatísticas da sua encomenda em tempo próximo.",
				"button": "Ver estatísticas"
			},
			"tasks": {
				"title": "Ações a efetuar",
				"error": {
					"title": "Não foi possível carregar as ações a efetuar",
					"desc": "Recarregue a página para podermos tentar novamente."
				},
				"empty": {
					"title": "Está tudo pronto!",
					"desc": "Não tem tarefas abertas neste momento."
				},
				"single": {
					"products-fe:complete-recollection": "Formulário AML obrigatório para preencher, para a sua empresa prevenir a lavagem de dinheiro",
					"products-fe:resume-onboarding": "Conclua a sua integração",
					"settings-fe:compile-cs-info": "Adicionar as suas informações de apoio ao cliente"
				},
				"aggregated": {
					"disputes-fe:unresolved": "{ count, plural, =1 {1 disputa não resolvida} other {# disputas não resolvidas}}",
					"orders-fe:expiring": "{ count, plural, =1 {1 encomenda a expirar} other {# encomendas a expirar}}",
					"orders-fe:tocapture": "{ count, plural, =1 {1 encomenda para capturar} other {# encomendas para capturar}}",
					"orders-fe:uncaptured": "{ count, plural, =1 {1 encomenda não capturada} other {# encomendas não capturadas}}",
					"users-fe:mid-added": "{ count, plural, =1 {1 nova loja foi adicionada} other {# novas lojas foram adicionadas}}",
					"users-fe:mid-invited": "{ count, plural, =1 {1 convite da loja a aguardar ação} other {# convites da loja a aguardar ação}}"
				}
			}
		}
	}
};