import { EXP_CHAT_TRANSLATION_KEY } from './constants';
const isTranslationEnabledForMarket = (ctx) => {
    var _a, _b;
    const marketKey = `${EXP_CHAT_TRANSLATION_KEY}.${ctx.market.toLowerCase()}`;
    return ((_b = (_a = ctx.features) === null || _a === void 0 ? void 0 : _a[marketKey]) === null || _b === void 0 ? void 0 : _b.is_enabled) === 'true';
};
function generateTag(ctx) {
    const { market } = ctx;
    if (!market || typeof market !== 'string') {
        throw Error('A correct market is required to open a new chat window.');
    }
    if (isTranslationEnabledForMarket(ctx))
        return `partner-support-${market.toLowerCase()}`;
    return `merchantportal-${market.toLowerCase()}`;
}
export default generateTag;
