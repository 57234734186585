module.exports = {
	"pl": {
		"config": {
			"environments": {
				"development": "Środowisko programistyczne",
				"staging": "Środowisko publikacyjne",
				"playground": "Środowisko testowe",
				"production": "Środowisko produkcyjne"
			}
		}
	}
};