module.exports = {
	"ro": {
		"home-fe": {
			"marketing-home": {
				"title": "",
				"description": "",
				"essentials": {
					"title": "Începe",
					"cta-label": "Aflați mai multe",
					"grab-and-go": {
						"description": ""
					},
					"ads-manager": {
						"description": ""
					},
					"branding": {
						"description": ""
					}
				},
				"external-apps": {
					"title": "",
					"cta-label": "",
					"klarna-search-b2b-portal": {
						"description": ""
					}
				}
			},
			"no-apps": {
				"title": "",
				"subtitle": ""
			},
			"contact": {
				"title": "",
				"paragraph": "",
				"label": "Contactați-ne"
			},
			"important-banner": {
				"title": "",
				"content": "",
				"linkLabel": ""
			},
			"two-factor-home-banner": {
				"banner-text": "",
				"banner-link": ""
			},
			"two-factor-opt-in": {
				"title": "Configurați autentificarea cu doi factori",
				"intro-paragraph": "",
				"help-link-text": "",
				"help-text": "",
				"warning-text": "",
				"continue-button": "Începeți configurarea"
			},
			"accept-invite-modal": {
				"title": "",
				"description": "",
				"accept-all-button": "",
				"decline-all-button": "",
				"save-button": "Salvează",
				"error": "",
				"invite": {
					"accept-label": "",
					"decline-label": ""
				}
			},
			"capture-orientation": {
				"title": "",
				"sub-title": "",
				"order-label": "",
				"capture-label": "",
				"payout-label": "",
				"capture-cta": "",
				"payout-cta": "",
				"dismiss-cta": "",
				"order-step": "",
				"capture-step": "",
				"payout-step": "",
				"completed-step": ""
			},
			"banner": {
				"common": {
					"businessFinancing": {
						"title": "",
						"text": "",
						"wePartnered": "",
						"qualifiedFor": "",
						"applicationCTA": ""
					},
					"adsManager": {
						"title": "",
						"text": "",
						"cta": ""
					},
					"branding-fe": {
						"content": "",
						"linkLabel": ""
					}
				}
			},
			"nko": {
				"dialog": {
					"title": "",
					"subtitle": "",
					"explore": "",
					"name-field": "",
					"terms-pre": "",
					"terms-text": "Termeni și condiții"
				}
			},
			"insightsWidget": {
				"title": "",
				"orderCount": "",
				"orderVolume": "",
				"orderVolumeAverage": "",
				"currencyFootnote": "",
				"link": "",
				"allStores": "",
				"selectAllOptionText": "",
				"deselectAllOptionText": "Șterge selecția",
				"closeText": "Efectuat",
				"closeTextWithSelection": "",
				"elapsedTime": "",
				"error": {
					"content": ""
				},
				"relativeDate": {
					"today": "Astăzi",
					"yesterday": ""
				},
				"weekDays": {
					"1": "",
					"2": "",
					"3": "",
					"4": "",
					"5": "",
					"6": "",
					"7": ""
				}
			},
			"blackFridayWidget": {
				"title": "",
				"text": "",
				"button": ""
			},
			"tasks": {
				"title": "",
				"error": {
					"title": "",
					"desc": ""
				},
				"empty": {
					"title": "",
					"desc": ""
				},
				"single": {
					"products-fe:complete-recollection": "",
					"products-fe:resume-onboarding": "",
					"settings-fe:compile-cs-info": "Adaugă informațiile privind serviciul clienți"
				},
				"aggregated": {
					"disputes-fe:unresolved": "",
					"orders-fe:expiring": "",
					"orders-fe:tocapture": "",
					"orders-fe:uncaptured": "",
					"users-fe:mid-added": "",
					"users-fe:mid-invited": ""
				}
			}
		}
	}
};