module.exports = {
	"pl": {
		"credentials": {
			"addOriginModal": {
				"buttonPrimaryText": "Zarejestruj",
				"buttonSecondaryText": "Anuluj",
				"description": "Zarejestruj każdą domenę źródła, w której zamierzasz używać Web SDK. Jeśli chcesz zarejestrować więcej niż jedną, będzie to możliwe później.",
				"errorMessage": "Wystąpił błąd. Jeśli problem będzie się powtarzał, spróbuj ponownie lub skontaktuj się z działem wsparcia.",
				"inputLabel": "Wprowadź resztę adresu URL",
				"title": "Zarejestruj dozwolone źródło"
			},
			"clientKeyBlock": {
				"mainText": "Twój identyfikator klienta jest ustawiony bezpośrednio w kodzie z domeną źródła, którą podano.",
				"secondaryText": "Aby zarządzać nią dalej, przejdź za pomocą linku do",
				"linkDescription": "strony identyfikatorów klienta",
				"linkLabel": "Zamknij"
			},
			"clientKeyField": {
				"label": "Identyfikatory klienta",
				"defaultErrorMessage": "Brak identyfikatora klienta"
			},
			"copyToClipboardButton": {
				"label": "Skopiuj",
				"labelOnCopy": "Skopiowano"
			},
			"originField": {
				"label": "Domena źródła",
				"tooltipMainText": "Aby zarejestrować więcej domen źródła lub zarządzać istniejącymi, przejdź do",
				"tooltipLinkLabel": "strony identyfikatorów klienta"
			},
			"originInputField": {
				"label": "Wprowadź resztę adresu URL",
				"validationError": "Podaj prawidłowy adres URL domeny",
				"invalidProtocolError": "Adres URL musi zaczynać się od http:// lub https://"
			},
			"addApiKeySuccessModal": {
				"title": "",
				"description": "Zachowaj poniższe dane uwierzytelniające na swoim urządzeniu lub w innym bezpiecznym miejscu.",
				"warning": "Hasło zostanie wyświetlone tylko raz i zniknie po zamknięciu tego okna.",
				"checkbox": "Dane uwierzytelniające są zachowane na moim urządzeniu.",
				"download": "Pobierz jako .txt"
			},
			"common": {
				"password": "Hasło",
				"username": "Nazwa użytkownika",
				"close": "Zamknij"
			}
		}
	}
};