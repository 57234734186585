import 'whatwg-fetch';
import { translations as helpToolTranslations } from '@merchant-portal/help-tool';
import componentsTranslations from '../translations';
import * as actions from './actions';
import * as components from './components';
import * as constants from './constants';
/**
 * This is a workaround so we don't have to import every support library we're extracting
 * from mage-components (as part of the Merchant Portal Engineering Initiative)
 * into mage-app to provide their translations to the application.
 */
const translations = [componentsTranslations, helpToolTranslations];
export const { AccessDenied, AppIcon, CenteredContent, Code, CodeSnippet, CodeSnippetNoCopy, Column, DateRangePicker, Dialog, Error, GridContainer, GridOverlayHelper, StoreSelector, MultiFactorModal, Pagination, RegionSelector, RegionSelectorBar, RouterLink, Row, VerticalRhythm, View, withResponsive, AuthenticationRequired, AuthenticationContextProvider, useAuthenticationContext, ManageMfaSection, IntegrationTypeModal } = components;
export { default as useLocalStorage } from './hooks/useLocalStorage';
export { actions, components, constants, translations };
