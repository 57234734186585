import cs from './cs.yml';
import da from './da.yml';
import de from './de.yml';
import el from './el.yml';
import en from './en.yml';
import es from './es.yml';
import fi from './fi.yml';
import fr from './fr.yml';
import it from './it.yml';
import nl from './nl.yml';
import no from './no.yml';
import pl from './pl.yml';
import pt from './pt.yml';
import sv from './sv.yml';
export default Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, cs), da), de), el), en), es), fi), fr), it), nl), no), pl), pt), sv);
