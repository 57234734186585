import { memoizeWithTokenHelper } from '@mpp/token-helper'
import * as Sentry from '@sentry/react'
import axios from 'axios'
import { utils as commonUtils } from 'mage-common'
import { selectors as configSelectors } from 'mage-config'
import { selectors as i18nSelectors } from 'mage-i18n'
import * as R from 'ramda'
import { createSelector } from 'reselect'
import uuid from 'uuid/v4'

import { init } from './reducer'
import * as utils from './utils'

const normaliseMerchant = R.pipe(
  ({ partners = [], transacting_regions: transactingRegions = [], ...merchant }) => ({
    ...commonUtils.renameKeys({
      created_at: 'createdAt',
      merchant_id: 'merchantId',
      onboarding_region: 'onboardingRegion',
      store_name: 'storeName'
    }, merchant),
    partners: partners.map(commonUtils.renameKeys({
      merchant_portal_role: 'merchantPortalRole'
    })),
    transactingRegions: transactingRegions.map(commonUtils.renameKeys({
      latest_order_date: 'latestOrderDate'
    }))
  }),
  commonUtils.cleanObject
)

const coreState = R.propOr({}, 'core')

const clientId = R.pipe(
  coreState,
  R.prop('clientId')
)

const title = createSelector(
  clientId,
  i18nSelectors.translatorFactory,
  (clientId, createTranslator) => {
    const t = createTranslator('en') // hard-code to English
    const title = t(`core.apps.${clientId}.title`)
    return title || clientId
  }
)

const slug = R.pipe(
  coreState,
  R.propOr('', 'slug')
)

const generateGetBackendUrl = createSelector(
  clientId,
  configSelectors.generateGetAppBackendName,
  (clientId, getAppBackendName) => (backendName = getAppBackendName(clientId)) => `/api/{region}/${backendName}`
)

const generateGetBackendUrlForRegion = createSelector(
  configSelectors.region,
  generateGetBackendUrl,
  (merchantPortalRegion, getBackendUrl) => ({ backendName, region = merchantPortalRegion } = {}) => {
    return getBackendUrl(backendName).replace('{region}', region.toLowerCase())
  }
)

const getBasename = createSelector(
  clientId,
  configSelectors.generateGetAppUrl,
  (clientId, getAppUrl) => getAppUrl(clientId) || ''
)

const keycloakRealm = R.pipe(
  coreState,
  R.prop('realm')
)

const isKlarnaRealm = R.pipe(
  keycloakRealm,
  R.equals('klarna')
)

const isMerchantsRealm = R.pipe(
  keycloakRealm,
  R.equals('merchants')
)

const isPartner = R.pipe(
  coreState,
  R.propEq(true, 'isPartner')
)

const sessionId = R.pipe(
  coreState,
  R.prop('sessionId')
)

const authenticated = R.pipe(
  coreState,
  R.prop('authenticated')
)

const isAuthenticated = createSelector(
  authenticated,
  R.equals(true)
)

const getSelectedMid = createSelector(
  coreState,
  R.prop('selectedMid')
)

const getAccessToken = R.pipe(
  coreState,
  R.prop('accessToken')
)

const getMerchants = createSelector(
  coreState,
  R.pipe(
    R.propOr(init.merchants, 'merchants'),
    R.map(normaliseMerchant)
  )
)

const getMerchantsByMids = createSelector(
  getMerchants,
  merchants => R.memoizeWith(
    R.identity,
    mids => R.filter(({ merchantId }) => R.includes(merchantId, mids), merchants)
  )
)

const getMerchantsGroupedByRegion = createSelector(
  getMerchants,
  R.groupBy(R.propOr('UNKNOWN', 'onboardingRegion'))
)

const getMerchant = createSelector(
  getMerchants,
  merchants => R.memoizeWith(
    R.identity,
    mid => R.find(R.propEq(mid, 'merchantId'), merchants)
  )
)

const getMerchantRegion = createSelector(
  getMerchant,
  getMerchant => R.memoizeWith(
    R.identity,
    mid => {
      const merchant = getMerchant(mid)
      if (merchant) {
        return merchant.onboardingRegion
      }
    }
  )
)

/**
 * !!! DEPRECATED !!!
 * Use the token-helper to get this data, as this selector will be removed soon(-ish)
 * https://jira.int.klarna.net/jira/browse/MPP-5080
 */
const getMerchantIds = createSelector(
  coreState,
  R.propOr(init.mids, 'mids')
)

const getClientRoles = createSelector(
  clientId,
  getSelectedMid,
  (clientId, mid) => memoizeWithTokenHelper(
    tokenHelper => tokenHelper.getPermissionsForMid(mid, clientId)
  )
)

const isUpdateProfileLoading = R.pipe(
  coreState,
  R.propOr(false, 'isUpdateProfileLoading')
)

const isUpdateProfileFailed = R.pipe(
  coreState,
  R.propOr(false, 'isUpdateProfileFailed')
)

const getDesignVersion = R.pipe(
  coreState,
  R.prop('designVersion')
)

const getRequester = R.pipe(
  coreState,
  R.prop('requester')
)

const isRequesterMfaSetupInitiated = R.pipe(
  getRequester,
  R.prop('mfa')
)

const getMfaMethods = R.pipe(
  getRequester,
  R.prop('mfa_methods')
)

const isRequesterMfaEnabled = createSelector(
  isRequesterMfaSetupInitiated,
  getMfaMethods,
  (isRequesterMfaSetupInitiated, mfaMethods) => (isRequesterMfaSetupInitiated && mfaMethods?.length > 0)
)

const isRequesterDeepLinkUser = createSelector(
  getRequester,
  requester => !!(requester && requester.deep_link_user_reference)
)

const isRequesterKred = R.pipe(
  getRequester,
  R.propOr(false, 'is_kred')
)

const isRequesterPartner = R.pipe(
  getRequester,
  R.propOr(false, 'is_partner')
)

const getRequesterAttributes = R.pipe(
  getRequester,
  R.prop('attributes')
)

const getPendingMerchantIds = createSelector(
  getRequester,
  R.pipe(
    R.pathOr({}, ['entity_roles_pending', 'merchant']),
    R.keys
  )
)

const isRequesterUsingMfa = createSelector(
  isRequesterMfaEnabled,
  isKlarnaRealm,
  (isRequesterMfaEnabled, isKlarnaRealm) => isRequesterMfaEnabled || isKlarnaRealm
)

const clientFactory = createSelector(
  getAccessToken,
  configSelectors.region,
  configSelectors.getMerchantPortalBaseUrls,
  (accessToken, merchantPortalRegion, merchantPortalBaseUrls) => R.memoizeWith(
    R.identity,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    (baseURL, { Sentry: _Sentry = Sentry } = {}) => {
      const client = axios.create({
        baseURL,
        timeout: 60000,
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
      client.interceptors.request.use(config => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { region = merchantPortalRegion, baseURL = '', url = '' } = config
        config.region = region.toLowerCase()
        config.baseURL = baseURL.replace('{region}', config.region)
        config.url = url.replace('{region}', config.region)
        if (!config.baseURL || baseURL.startsWith('/')) {
          const merchantPortalBaseUrl = merchantPortalBaseUrls[config.region]
          config.baseURL = `${merchantPortalBaseUrl}${config.baseURL}`
        }
        config.headers.common['Klarna-Correlation-Id'] = uuid()
        return config
      })
      client.interceptors.response.use(
        response => response,
        error => {
          if (axios.isCancel(error)) {
            return Promise.reject(error)
          }
          _Sentry.withScope(scope => {
            if (!error.config) return
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const { baseURL = '', url = '', method, region } = error.config
            const upperCaseMethod = method.toUpperCase()
            const normalisedBaseUrl = baseURL.replace(/\/$/, '')
            const normalisedUrl = url.replace(/^\//, '')
            const fullUrl = `${normalisedBaseUrl}${normalisedUrl ? `/${normalisedUrl}` : ''}`
            // mask parts of the URL (so similar URLs are grouped in Sentry)
            const maskedUrl = utils.maskUrl(merchantPortalBaseUrls[region])(fullUrl)
            const statusCode = error.response ? error.response.status : 0
            const backendNameMatch = maskedUrl.match(/\/api\/[^/]*\/([^/]*)\//)
            scope.setFingerprint([upperCaseMethod, maskedUrl, String(statusCode)])
            error.originalMessage = error.message
            error.message = `Unable to ${upperCaseMethod} ${maskedUrl} - ${statusCode}`
            _Sentry.captureException(error, {
              extra: {
                error
              },
              tags: {
                backendName: backendNameMatch ? backendNameMatch[1] : undefined,
                backendRegion: region
              }
            })
          })
          return Promise.reject(error)
        }
      )
      return client
    }
  )
)

const createBackendClient = createSelector(
  clientFactory,
  generateGetBackendUrl,
  (factory, getBackendUrl) => R.memoizeWith(
    R.identity,
    backendName => factory(getBackendUrl(backendName))
  )
)

const createMerchantPortalApiClient = createSelector(
  createBackendClient,
  generateGetBackendUrl,
  createBackendClient => createBackendClient('merchant-portal')
)

const createClient = createSelector(
  createBackendClient,
  createBackendClient => createBackendClient()
)

export {
  authenticated,
  clientFactory,
  clientId,
  coreState,
  createBackendClient,
  createClient,
  createMerchantPortalApiClient,
  generateGetBackendUrl,
  generateGetBackendUrlForRegion,
  getAccessToken,
  getBasename,
  getClientRoles,
  getDesignVersion,
  getMerchant,
  getMerchantIds,
  getMerchantRegion,
  getMerchants,
  getMerchantsByMids,
  getMerchantsGroupedByRegion,
  getPendingMerchantIds,
  getRequester,
  getRequesterAttributes,
  getSelectedMid,
  isAuthenticated,
  isKlarnaRealm,
  isMerchantsRealm,
  isPartner,
  isRequesterDeepLinkUser,
  isRequesterKred,
  isRequesterMfaEnabled,
  isRequesterMfaSetupInitiated,
  isRequesterPartner,
  isRequesterUsingMfa,
  isUpdateProfileFailed,
  isUpdateProfileLoading,
  keycloakRealm,
  sessionId,
  slug,
  title
}
