import React from 'react';
import styled from 'styled-components';
import isTouchDevice from './isTouchDevice';
const Wrapper = styled.div.withConfig({ displayName: "Wrapper", componentId: "sc-1j8ew" }) `
  display: block;
  width: 100%;
  box-sizing: border-box;
  border: none;
  padding: 0;
  margin: 0;
  background: none;
  cursor: ${({ onClick }) => (isTouchDevice() && onClick ? 'pointer' : 'auto')};
`;
export default function CardWrapper({ children, onClick }) {
    return (React.createElement(Wrapper, { onClick: !isTouchDevice() ? onClick : undefined, as: !isTouchDevice() ? 'button' : undefined }, children));
}
