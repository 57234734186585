module.exports = {
	"pl": {
		"help-tool": {
			"button-text": "Pomoc",
			"support-button": "Uzyskaj wsparcie",
			"support-page": {
				"create-ticket": {
					"title": "Uzyskaj wsparcie",
					"instructions": "Wyślij zapytanie poniżej, a my skontaktujemy się z Tobą w ciągu jednego dnia roboczego.",
					"related-content": {
						"title": "To może pomóc rozwiązać Twój problem"
					},
					"fields": {
						"mid": {
							"label": "Wybierz sklep",
							"validation": "Wymagany sklep"
						},
						"phoneNumber": {
							"label": "Numer telefonu (opcjonalnie)"
						},
						"topic": {
							"question": "Czego dotyczy Twoje pytanie?",
							"label": "Wybierz temat",
							"validation": "Wymagany temat",
							"options": {
								"orders": "Zamówienia",
								"settlements": "Rozliczenia",
								"agreements": "Umowy",
								"others": "Inny"
							}
						},
						"subject": {
							"label": "Temat",
							"validation": "Wymagany temat"
						},
						"description": {
							"label": "Wiadomość",
							"validation": "Wymagany opis"
						},
						"attachment": {
							"label": "Dodaj załączniki",
							"button": "Prześlij",
							"info": "Dodanie zrzutów ekranu pomoże nam szybciej rozwiązać problem.",
							"supportedFileTypes": "Obsługiwane typy plików: .png, .jpg, .pdf",
							"error": "Łączny rozmiar plików nie może przekraczać {maxSize} MB"
						}
					},
					"submit": "Wyślij zapytanie",
					"service-unavailable-error": "Ups! Wystąpił błąd. Spróbuj ponownie za kilka minut. Jeśli to będzie się powtarzać, {link} w celu uzyskania pomocy.",
					"generic-error": "Nie mogliśmy przetworzyć Twojego zapytania. Nasze systemy nie komunikują się ze sobą w tej chwili. Spróbuj odświeżyć lub wróć później. Jeśli to będzie się powtarzać, {link} w celu uzyskania pomocy.",
					"contact-link": "skontaktuj się z nami"
				},
				"view-ticket": {
					"title": "Wszystko gotowe!",
					"details": "Otrzymaliśmy Twoje zapytanie – odpowiemy w ciągu jednego dnia roboczego. Oto szczegóły:",
					"ticket": "Zapytanie #{ticketId}",
					"email": "E-mail: {email}",
					"status": "Możesz śledzić status na naszej stronie Wsparcia.",
					"track": "Śledź status"
				}
			},
			"header": {
				"homeLink": "Zresetuj"
			},
			"home": {
				"greeting": "Witamy, {givenName}! Jak możemy Ci pomóc?",
				"topics": "W czym potrzebujesz pomocy?"
			},
			"topics": {
				"expandButton": "Więcej"
			},
			"feedback": {
				"title": "Czy to pomogło?",
				"optionYes": "Tak, pomogło",
				"optionNo": "Nie, nadal potrzebuję pomocy",
				"positiveFeedback": "Dziękujemy za pozytywną opinię, dzięki temu w przyszłości będziemy mogli proponować Ci lepiej dopasowane artykuły."
			},
			"freeTextFeedback": {
				"title": "Pomóż nam się poprawić",
				"placeholder": "Dlaczego te treści nie były przydatne?",
				"submitButton": "Wyślij",
				"feedbackMessage": "Dziękujemy za Twoją opinię, dzięki temu w przyszłości będziemy mogli proponować Ci lepiej dopasowane artykuły."
			},
			"relatedContent": {
				"title": "Powiązane treści"
			},
			"search": {
				"buttonLabel": "Szukaj",
				"input": "Szukaj artykułów...",
				"loading": "Ładowanie...",
				"results": {
					"contact": "Nie możesz znaleźć potrzebnej pomocy?",
					"empty": "Przepraszamy, brak wyników dla Twojego zapytania. Spróbuj wyszukać inny wyraz.",
					"singleTopicFound": "Znalezione tematy: 1",
					"multipleTopicsFound": "Znalezione tematy: {count}",
					"singleArticleFound": "Znalezione artykuły: 1",
					"multipleArticlesFound": "Znalezione artykuły: {count}"
				}
			},
			"contact": {
				"title": "Skontaktuj się z nami",
				"channelsIntro": "Zwróć się do nas, abyśmy mogli Ci pomóc.",
				"businessDetailsTitle": "Dane dotyczące Twojej firmy",
				"businessDetailsName": "Nazwa firmy",
				"businessDetailsEmail": "E-mail",
				"businessDetailsMid": "ID sprzedawcy",
				"phone": {
					"title": "Zadzwoń do nas",
					"paragraph": "Możesz do nas dzwonić w godzinach pracy w dni powszednie. Jeśli jesteś w Szwecji lub Zjednoczonym Królestwie, możesz do nas dzwonić także w weekendy w godzinach 10–16."
				},
				"email": {
					"title": "Napisz do nas e-mail",
					"paragraph": "Wyślij nam e-mail, a my odezwiemy się do Ciebie jak najszybciej."
				}
			},
			"errorBoundary": {
				"title": "Wystąpił błąd",
				"message": "Bardzo przepraszamy, ale wystąpił błąd. Możesz kliknąć przycisk „Wstecz” poniżej, aby powrócić do narzędzia pomocy lub „Uzyskaj wsparcie”, aby skontaktować się z nami,",
				"resetButton": "Wstecz",
				"errorId": "Identyfikator błędu: {errorId}"
			},
			"tour": {
				"prev": "Wstecz",
				"next": "Dalej",
				"done": "Gotowe"
			},
			"notAvailableMobile": {
				"title": "Niedostępne",
				"buttonBack": "Wstecz",
				"paragraph": "Ta treść nie jest dostępna na Twoim urządzeniu. Spróbuj otworzyć na urządzeniu o większym ekranie, np. na komputerze stacjonarnym."
			},
			"shareButton": {
				"title": {
					"article": "Udostępnij głęboki link do tego artykułu",
					"topic": "Udostępnij głęboki link do tego tematu",
					"tour": "Udostępnij głęboki link do tego przewodnika"
				},
				"radioItemHome": "Strona główna aplikacji",
				"descriptionHome": "Otwiera artykuł pomocy na stronie głównej Portalu dla handlowców",
				"radioItemCurrent": "Bieżąca strona",
				"descriptionCurrent": "Otwiera artykuł pomocy na bieżącej stronie"
			},
			"marketSelector": {
				"whichMarketToContact": "Z którym rynkiem chcesz się skontaktować?"
			}
		},
		"orientation": {
			"title": "Dowiedz się, jak..."
		},
		"helpbot": {
			"questions": {
				"whatDoYouNeedHelpWith": "Witamy! Jak możemy Ci dzisiaj pomóc?",
				"selectSupportChannel": "Wybierz kanał, aby skontaktować się z naszym działem wsparcia.",
				"thatHelped": "Dziękujemy za pozytywną opinię, dzięki temu w przyszłości będziemy mogli proponować Ci lepiej dopasowaną pomoc. 😀"
			},
			"orders": {
				"orderManagement": {
					"title": "Zarządzanie zamówieniem",
					"createOrderPos": {
						"question": "Jak utworzyć zamówienie za pośrednictwem Point of Sales?",
						"answer": "1. Otwórz Point of sales 2. Wprowadź nazwę artykułu, cenę produktu i ilość. 3. Udostępnij wygenerowany link do płatności klientowi za pośrednictwem wiadomości SMS, e-mailem lub poprzez zeskanowanie kodu QR wyświetlanego na ekranie kasy. 4. Klient może teraz sfinalizować zakup na swoim urządzeniu."
					},
					"extendExpiryReservation": {
						"question": "Jak przedłużyć datę wygaśnięcia rezerwacji?",
						"answer": "1. Przejdź do menedżera zamówień i wybierz jedno lub kilka zamówień, dla których chcesz przedłużyć datę wygaśnięcia. 2. Kliknij opcję „Przedłuż datę wygaśnięcia”. 3. Potwierdź swój wybór.\nZamówienia są przedłużane zgodnie z ustawieniami czasu rezerwacji dla Twojego sklepu (zwykle jest to 28 dni). Jeśli zamówienie wygasło, nie można go już przedłużyć."
					},
					"howToCaptureOrders": {
						"question": "Jak przyjąć zamówienie?",
						"partially": {
							"question": "Chcę częściowo przyjąć zamówienie",
							"answer": "Jeśli możliwe jest wysłanie tylko części zamówienia klienta, możesz częściowo przyjąć zamówienie według wiersza zamówienia lub kwoty. Przyjęcie wiersza zamówienia 1. Przejdź do zamówienia w Menedżerze zamówień i wybierz artykuły, które masz obecnie w magazynie na stronie zamówienia. Pamiętaj, aby edytować wiersze zamówienia w celu dostosowania ich ilości, jeśli zachodzi taka razie potrzeba. 2. Kliknij „Przyjmij”. Kwota przyjęta: 1. Przejdź do zamówienia w Menedżerze zamówień. Możesz przyjąć kwotę, klikając przycisk „Przyjmij wszystko”. Tutaj należy wprowadzić żądaną kwotę. 2. Kliknij „Przyjmij”.\nPamiętaj, aby zrealizować zamówienie, gdy jest to możliwe, albo poprzez przejęcie pozostałych pozycji/kwoty, kiedy będzie dostępna do wysyłki, albo poprzez anulowanie pozostałego zamówienia, o ile nie możesz wysłać towarów."
						},
						"fully": {
							"question": "Chcę przyjąć całość zamówień",
							"answer": "1. Przejdź do Menedżera zamówień i kliknij numer referencyjny Klarna, aby otworzyć zamówienie. 2. Przyjmij pełne zamówienie, wykonując jeden z poniższych kroków: - Kliknij przycisk „Przyjmij” – poprzez zaznaczenie wierszy zamówienia i kliknięcie przycisku „Przyjmij”. - Kliknij „Przyjmij wszystko” i wprowadź pełną kwotę zamówienia. 3. Podczas przyjmowania zamówienia możesz pobrać list przewozowy, aby dołączyć go do przesyłki.\nUpewnij się, że zamówienia zostały przyjęte przed upływem terminu ich ważności (zwykle 28 dni od utworzenia zamówienia). Możesz przyjąć wiele zamówień, zaznaczając je na liście zamówień i klikając „Przyjęcie całości”, jeśli chcesz przyspieszyć działanie."
						}
					},
					"canIUpdateCustomerInfo": {
						"question": "Czy mogę zaktualizować dane klienta?",
						"answer": "Nigdy nie można zmienić odbiorcy zamówienia. Istnieje jednak możliwość zmiany adresu wysyłki i adresu rozliczeniowego w przypadku zamówień, które nie zostały przyjęte lub zostały przyjęte częściowo. Jeśli jednak Klarna odrzuci nowy adres, ponieważ nie jest on powiązany z klientem, adres rozliczeniowy może zostać zmieniony."
					},
					"extendDueDate": {
						"question": "Jak przedłużyć termin realizacji zamówienia?",
						"answer": "Możesz przedłużyć termin realizacji zamówienia, klikając „Wydłuż termin płatności” na przyjętym zamówieniu. Przedłużenie terminu realizacji zamówienia wiąże się z opłatą, która zostanie określona przed sfinalizowaniem akcji."
					},
					"refundOrder": {
						"question": "Jak dokonać zwrotu pieniędzy za zamówienie?",
						"answer": "1. Przejdź do Menedżera zamówień i znajdź zamówienie, za które chcesz zwrócić środki. 2. Wybierz metodę zwrotu: wybierz pozycje do zwrotu, a następnie kliknij „Zwrot pieniędzy” lub najpierw kliknij „Zwrot pieniędzy”, aby wprowadzić kwotę, którą chcesz zwrócić. 3. Zwrócona kwota zostanie odjęta od następnej wypłaty, a klient otrzyma ją w ciągu 5 dni roboczych.\nDotyczy to tylko przyjętych zamówień. W przypadku nieprzyjętych zamówień anuluj je, jeśli nie będziesz wysyłać żadnych produktów. Jeśli tylko część zamówienia została przyjęta i wysłana, zwolnij pozostałą nieprzyjętą część."
					},
					"cancelOrder": {
						"question": "Jak anulować zamówienie?",
						"answer": "1. Przejdź do Menedżera zamówień i znajdź zamówienie, które chcesz anulować. 2. Kliknij „Anuluj zamówienie”. Czynność ta informuje również klienta, że zamówienie nie zostanie wysłane.\nJeśli jest to wymagane, zawsze możesz odtworzyć anulowane zamówienia. Nie możesz anulować przyjętych zamówień, ale możesz dokonać zwrotu pieniędzy."
					},
					"unableToTakeActions": {
						"question": "Dlaczego nie mogę podjąć działań związanych z zamówieniem?",
						"directPartner": {
							"question": "Mam umowę bezpośrednio z Klarna",
							"answer": "Uprawnienia użytkowników określają, co użytkownicy mogą robić w Portalu partnera. Są dwa różne typy uprawnień użytkownika: Administrator – użytkownicy z pełnym dostępem; na przykład administrator może uzyskać dostęp i edytować wszystkie funkcje w Portalu partnera. Konkretne dla danej sekcji – użytkownicy niebędący administratorami mają dostęp do jednej lub więcej określonych sekcji w Portalu partnera.\nTo może być przyczyną braku możliwości realizacji zamówień. Są różne role dostępu związane z Menedżerem zamówień. Porozmawiaj z administratorem, aby ustalić, która rola dostępu jest najbardziej odpowiednia dla czynności, które masz do wykonania."
						},
						"distributionPartner": {
							"question": "Mam umowę bezpośrednio z Klarna",
							"answer": "Wszelkie działania, które chcesz podjąć w związku z zamówieniem, można wykonać wyłącznie za pośrednictwem platformy dostarczonej przez Partnera dystrybucyjnego."
						}
					}
				},
				"orderModification": "Modyfikacja zamówienia i kwestie sporne",
				"orderProcessing": "Przetwarzanie zamówienia",
				"refundIssues": {
					"title": "Sprawy związane ze zwrotem pieniędzy",
					"refundedOrderWhenCustomerFunds": {
						"question": "Pieniądze za zamówienie zostały przez nas zwrócone. Kiedy nasz klient otrzyma środki?",
						"answer": "Klient powinien otrzymać pieniądze w ciągu 5 dni roboczych od momentu wykonania zwrotu środków. Termin może się różnić w zależności od wybranej przez klienta metody płatności. Jeśli klient ma pytania na ten temat, prosimy o kontakt z działem obsługi klienta Klarna."
					}
				},
				"reservations": {
					"title": "Rezerwacje",
					"whyUnableToExtendReservation": {
						"question": "Dlaczego nie mogę przedłużyć czasu rezerwacji zamówienia?",
						"answer": "Istnieją szczególne okoliczności, w których przedłużenie daty wygaśnięcia zamówienia nie jest możliwe:\n- Jeśli zamówienie już wygasło, konieczne będzie jego ponowne utworzenie. Aby to zrobić, należy przejść do strony zamówienia, kliknąć „Kopiuj” i wybrać „Utwórz nowe zamówienie”.\n- Jeśli zamówienie zostało opłacone kartą, klient musi złożyć nowe zamówienie, ponieważ daty ważności tych zamówień nie można przedłużyć.\n"
					}
				},
				"paymentsForMyOrders": {
					"title": "Płatności za moje zamówienia",
					"whenWillIGetPaid": {
						"question": "Kiedy otrzymam zapłatę za zamówienie?",
						"answer": "Aby otrzymać płatność za zamówienie, należy je najpierw przyjąć. Kwota wypłaty, po odliczeniu prowizji, zwrotów i innych opłat, zostanie przesłana na konto bankowe zgodnie z harmonogramem wypłat. Konfigurację wypłat można sprawdzić w sekcji „Rozliczenia”."
					},
					"refundOrderReceivedPayment": {
						"question": "Co się stanie, jeśli zwrócę środki za zamówienie, za które już otrzymano środki?",
						"answer": "Każda zwrócona kwota zostanie odjęta od następnej zaplanowanej wypłaty."
					},
					"referenceNotShowUp": {
						"question": "Numer zamówienia lub numer referencyjny nie pojawia się w raporcie.",
						"answer": "Jeśli nie możesz znaleźć płatności za konkretne zamówienie:\n- Sprawdź poprawność numeru zamówienia lub numeru referencyjnego.\n- Upewnij się, że zamówienie zostało przyjęte.\n- Sprawdź, czy zamówienie mieści się w okresie raportu przyjęć.\n- Należy pamiętać, że zlecenia anulowane przed rozliczeniem nie pojawią się w raporcie.\n"
					},
					"gotPaidSpecificOrder": {
						"question": "Jak sprawdzić, czy otrzymaliśmy zapłatę za konkretne zamówienie?",
						"answer": "W menedżerze zamówień Portalu partnera wyszukaj zamówienie, upewniając się, że numer zamówienia lub numer referencyjny jest prawidłowy. Poniżej numeru referencyjnego sprawdź, czy zamówienie zostało przyjęte. W przypadku przyjęcia zamówienia status będzie wyświetlany jako „oczekujące” lub „opłacone”. W przypadku zamówień opłaconych kliknij „Zobacz rozliczenie”, aby wyświetlić odpowiedni raport."
					}
				}
			},
			"disputes": {
				"disputeManagement": "Zarządzanie sporami",
				"contactInfoAndNotifications": "Informacje kontaktowe i ustawienia powiadomień",
				"timingAndDeadlines": "Czas i terminy rozstrzygania sporów",
				"resolutionProcess": "Proces rozstrzygania sporów",
				"merchantDisputesApp": {
					"title": "Aplikacja do zarządzania sporami dla sprzedawców.",
					"respondToDispute": {
						"question": "Skąd mam wiedzieć, czy muszę odpowiedzieć na wezwanie do rozstrzygnięcia sporu?",
						"answer": "Sprawdź sekcję „Do zrobienia” na stronie głównej Portalu partnera, aby sprawdzić, czy jakieś spory wymagają Twojej odpowiedzi."
					},
					"overviewDisputesCustomer": {
						"question": "Gdzie mogę znaleźć zestawienie wszystkich sporów zgłoszonych przez klientów?",
						"answer": "Wszystkie zgłoszone spory można znaleźć w sekcji „Spory”, na stronie „Wszystkie spory”, bezpośrednio po zgłoszeniu sporu.\n\nNależy pamiętać, że spory są dostępne na stronie „Wszystkie spory” do 180 dni po ich zamknięciu.\n"
					},
					"overviewDisputesResponse": {
						"question": "Gdzie mogę znaleźć zestawienie wszystkich sporów wymagających odpowiedzi?",
						"answer": "Wszystkie spory wymagające odpowiedzi można znaleźć w sekcji „Spory” na stronie „Otwarte spory” w sekcji „Wymagana odpowiedź”."
					},
					"updateContactInformation": {
						"question": "Jak mogę zaktualizować moje dane do otrzymywania wiadomości e-mail z przypomnieniem o sporze?",
						"answer": "1. Przejdź do sekcji „Spory”\n2. Kliknij stronę „E-mail i ustawienia sporów”.\n3. Zaktualizuj swoje dane kontaktowe w zakładce „E-mail i powiadomienia”.\n\nNależy pamiętać, że można ustawić różne dane kontaktowe dla „sporów”, „nieautoryzowanych zakupów” i „zamówień wysokiego ryzyka”. Dodatkowo można ustawić częstotliwość powiadomień w zakładce „Powiadomienia”.\n"
					},
					"updateCustomerServiceOnKlarnaApp": {
						"question": "Jak mogę zaktualizować dane Obsługi klienta w aplikacji Klarna?",
						"answer": "1. Przejdź do sekcji „Ustawienia płatności”.\n2. Kliknij „Informacje o obsłudze klienta”.\n3. Wybierz „Rynek” (jeśli masz dział obsługi dla konkretnego rynku).\n\nMożesz wprowadzić dane kontaktowe klienta, a także zasady zwrotów, które będą wyświetlane w aplikacji Klarna, aby zapewnić płynniejszą obsługę.\n\nNależy pamiętać, że do wyświetlenia tych informacji potrzebne są pełne prawa dostępu do Portalu partnera.\n"
					},
					"improveDisputesPerformance": {
						"question": "Jak mogę poprawić swoje wyniki w sporach?",
						"answer": "Aby zapoznać się ze wskazówkami, jak poprawić wyniki w sporach, przejdź do „Przewodnika na temat usprawniania zwrotów i sporów”. Dostęp do nich można uzyskać za pośrednictwem strony „Statystyki”, klikając przycisk „Uzyskaj wgląd” w prawym górnym rogu strony.\n\nNależy pamiętać, że do wyświetlenia tych informacji potrzebne są pełne prawa dostępu do Portalu partnera.\n"
					},
					"overviewDisputesPerformance": {
						"question": "Gdzie mogę zobaczyć przegląd wyników moich sporów?",
						"answer": "Aby wyświetlić szczegółowe statystyki dotyczące sporów, przejdź do strony „Statystyki” w Aplikacji do zarządzania sporami. Ta sekcja zawiera zestawienie miesięcznych sporów, wyników, kosztów i strat związanych ze sporami.\n\nNależy pamiętać, że do wyświetlenia tych informacji potrzebne są pełne prawa dostępu do Portalu partnera.\n"
					}
				},
				"disputesLifecycle": {
					"title": "Cykl życia sporów",
					"whenCanSeeDisputes": {
						"question": "Kiedy mogę zobaczyć spory zgłaszane przez klientów?",
						"answer": "Możesz zobaczyć wszystkie spory zgłoszone w Aplikacji do zarządzania sporami na stronie „Wszystkie spory”, natychmiast po ich zgłoszeniu."
					},
					"howLongToResolve": {
						"question": "Ile mam czasu na rozwiązanie sporu bezpośrednio z klientem?",
						"answer": "Na rozwiązanie sporu z klientem masz 21 dni od dnia jego zgłoszenia. Jeśli wspólnie z klientem nie rozwiążecie sporu w ciągu 21 dni od jego zgłoszenia, Klarna dołączy jako strona, aby wesprzeć proces rozwiązywania sporu między Wami."
					},
					"howMuchTimeToRefund": {
						"question": "Ile mam czasu na zwrot pieniędzy?",
						"answer": "Masz 21 dni od daty zwrotu na jego przetworzenie, zanim Klarna dołączy jako strona, aby wesprzeć proces rozstrzygania między Wami. Mając to na uwadze, zawsze najlepiej jest dokonywać zwrotów środków natychmiast po ich otrzymaniu."
					},
					"howToResolve": {
						"question": "Jak mogę rozwiązać spory lub kwestie związane ze zwrotami?",
						"answer": "Istnieje kilka sposobów rozwiązania sporu lub zwrotu.\n\n- Przetwórz zwrot towaru lub środków, gdy tylko je otrzymasz / uzgodnisz rozwiązanie.\n- Ustaw próg obciążenia zwrotnego w sekcji „E-maile i ustawienia sporów”.\n- Zachowaj proaktywność, sprawdzając sekcję „Wszystkie spory”.\n- Uznaj stratę\n"
					},
					"whenToRespond": {
						"question": "Kiedy muszę aktywnie bronić się w sporze / odpowiadać na niego?",
						"answer": "Jeśli Ty i klient nie możecie samodzielnie znaleźć rozwiązania w wyznaczonym czasie (21 dni), Klarna dołączy jako strona, aby wesprzeć proces rozwiązywania sporów między Wami. Jeśli spór jest zasadny, zespół wsparcia Klarna skontaktuje się z Tobą za pośrednictwem Aplikacji do zarządzania sporami z prośbą o dodatkowe informacje.\n\nW tym momencie poprosimy Cię o obronę w sporze lub odpowiedź na niego.\n"
					},
					"howMuchTimeToRespond": {
						"question": "Ile mam czasu na udzielenie odpowiedzi na wezwanie do rozstrzygnięcia sporu?",
						"answer": "Termin na złożenie pierwszego wniosku wynosi 14 dni dla wszystkich przyczyn sporu, z wyjątkiem nieautoryzowanych zakupów (7 dni). Na każdą wysłaną prośbę o podjęcie działań następczych* (maksymalnie 5 próśb) masz 7 dni. W przypadku konieczności uzupełnienia odpowiedzi, masz 60 minut od jej przesłania na dodanie np. informacji lub załączników. Po upływie tego czasu nie możesz już odeprzeć zarzutów w sprawie.\n\n* W zależności od warunków umowy partnerskiej mogą obowiązywać specjalne zasady.\n"
					},
					"howLongToSeeDispute": {
						"question": "Jak długo mogę widzieć spór w Aplikacji do zarządzania sporami po jego rozwiązaniu?",
						"answer": "Spory są dostępne na stronie „Wszystkie spory” do 180 dni po ich zamknięciu."
					},
					"whenToGetCharged": {
						"question": "Kiedy zostanie mi naliczona opłata za spór?",
						"answer": "Opłata za spór może zostać naliczona, jeśli:\n- Ty ponosisz winę za spór.\n- nie udzielisz odpowiedzi przed upływem terminu.\n- zwrot pieniędzy nastąpi po interwencji Klarna w celu wsparcia rozwiązania.\n\nOpłata jest naliczana z mocą wsteczną po rozstrzygnięciu sporu. Nie obciążymy Cię opłatą, jeśli spór zostanie rozstrzygnięty na Twoją korzyść, anulowany lub odrzucony.\n"
					}
				},
				"disputesHandling": {
					"title": "Obsługa sporów",
					"whatIsDispute": {
						"question": "Jakie informacje otrzymam wraz z wnioskiem o rozstrzygnięcie sporu?",
						"answer": "Wniosek o rozstrzygnięcie sporu obejmuje następujące elementy:\n\n- Powód: kategoria sporu, np. zwroty, nieotrzymane towary.\n- Sprzedawca: ma znaczenie, jeśli w Klarna zarejestrowanych jest wiele identyfikatorów sprzedawcy (MID).\n- Data sporu: kiedy klient zgłosił spór.\n- Termin: termin, w którym należy odpowiedzieć w celu odparcia zarzutów w sprawie.\n- Sporne produkty: artykuły będące przedmiotem sporu.\n- Szczegóły zamówienia: Obejmują „numer referencyjny sprzedawcy 1 i 2”, „datę zamówienia” oraz „kwotę sporną”.\n"
					},
					"howManyTimesDefend": {
						"question": "Ile razy mogę bronić się w ramach jednego wniosku o spór?",
						"answer": "Możesz otrzymać maksymalnie pięć (5) wniosków o obronę w sporze, ale najczęściej nie wymaga to więcej niż jednego lub dwóch."
					},
					"doIReceiveNotification": {
						"question": "Czy gdy muszę odpowiedzieć na zgłoszenie sporu, otrzymam powiadomienia?",
						"answer": "Jeśli masz oczekujące żądanie odpowiedzi na zgłoszenie sporu, pojawi się ono w wielu lokalizacjach:\n\n- W sekcji „Do zrobienia” na stronie głównej Portalu partnera.\n- Jako powiadomienie w sekcji Spór na stronie „Otwarte spory”, wskazujące liczbę sporów wymagających odpowiedzi.\n\nMożesz również zdecydować się na otrzymywanie wiadomości e-mail dla każdego sporu, dostosowując ustawienia w sekcji „E-maile i ustawienia sporu” na opcję „Powiadomienie e-mail dla każdego sporu”.\n"
					},
					"howToAvoidDisputesFees": {
						"question": "Jak mogę uniknąć opłat za spory?",
						"answer": "Aby uniknąć opłat za spory, należy:\n- niezwłocznie rozpatrzyć zwroty artykułów lub pieniędzy.\n- ustawić próg obciążenia zwrotnego w sekcji „E-maile i ustawienia sporów”.\n- regularnie sprawdzać sekcję „Wszystkie spory”.\n- reagować na spory za pomocą odpowiednich informacji.\n- odpowiadać przed upływem terminu.\n"
					}
				}
			},
			"settlements": {
				"payoutNotFound": {
					"title": "Nie znaleziono wypłaty dla określonego zamówienia",
					"orderNumberNotFound": {
						"question": "Numer zamówienia lub numer referencyjny nie pojawia się w raporcie",
						"answer": "Jeśli nie możesz znaleźć płatności za konkretne zamówienie:\n- Sprawdź poprawność numeru zamówienia lub numeru referencyjnego.\n- Upewnij się, że zamówienie zostało przyjęte.\n- Sprawdź, czy zamówienie mieści się w okresie raportu przyjęć.\n- Należy pamiętać, że zlecenia anulowane przed rozliczeniem nie pojawią się w raporcie.\n"
					},
					"checkIfPaidForOrder": {
						"question": "Jak sprawdzić, czy otrzymaliśmy zapłatę za konkretne zamówienie?",
						"answer": "W menedżerze zamówień Portalu partnera wyszukaj zamówienie, upewniając się, że numer zamówienia lub numer referencyjny jest prawidłowy. Poniżej numeru referencyjnego sprawdź, czy zamówienie zostało przyjęte. W przypadku przyjęcia zamówienia status będzie wyświetlany jako „oczekujące” lub „opłacone”. W przypadku zamówień opłaconych kliknij „Zobacz rozliczenie”, aby wyświetlić odpowiedni raport."
					}
				},
				"payouts": {
					"title": "Wypłaty",
					"whenIsNext": {
						"question": "Kiedy nastąpi moja kolejna wypłata?",
						"answer": null
					},
					"whyCalculationNotMatch": {
						"question": "Dlaczego moje własne obliczenia nie zgadzają się z otrzymanymi wypłatami?",
						"answer": "Zgodnie z umową handlową odliczamy opłaty, takie jak opłaty transakcyjne i należności za opóźniony zwrot. W związku z tym Twoje obliczenia mogą różnić się od rozliczeń Klarna. Aby zapoznać się z wyraźnie opisanymi potrąceniami, zalecamy otwarcie raportu rozliczeniowego w wersji PDF."
					},
					"whenGetPaid": {
						"question": "Kiedy otrzymam wypłatę?",
						"answer": null
					},
					"missingPayout": {
						"question": "Brakuje mi wypłaty",
						"answer": "Przed skontaktowaniem się z naszym kanałem wsparcia. Weź pod uwagę, że brakujące wypłaty mogą być spowodowane następującymi przyczynami:\n\n- Klarna wysyła płatność tego samego dnia, w którym generowany jest raport, ale może minąć kilka dni, zanim pieniądze pojawią się na koncie bankowym.\n- W danym okresie nie zarejestrowano żadnych zleceń.\n- Niedawno zaktualizowano dane konta bankowego w Klarna, co mogło spowodować, że płatność trafiła na Twoje poprzednie konto bankowe. Może się zdarzyć, że zmiana nie zostanie uwzględniona przed realizacją płatności.\n- Numer zamówienia lub numer referencyjny, którego szukasz, jest nieprawidłowy. W takim wypadku sprawdź zamówienie lub numer referencyjny.\n- Twoje płatności są wstrzymane przez Klarna. Informacja o wstrzymaniu powinna przyjść do Ciebie osobno w wiadomości e-mail – wiadomości te są najczęściej wysyłane do administratora.\n"
					},
					"howIsPayoutCalculated": {
						"question": "Jak obliczana jest moja wypłata?",
						"answer": "Wypłata jest obliczana na podstawie zamówień zarejestrowanych w danym okresie przyjęcia, z pomniejszeniem o opłaty, zwroty i inne potrącenia. Raport rozliczeniowy zawiera szczegółowe zestawienie tych kwot.\nJeśli zwroty przekroczą sprzedaż w okresie przyjęcia, nadwyżka zostanie odjęta od następnej wypłaty.\n"
					}
				},
				"howPayoutsWork": {
					"question": "Jak działają wypłaty?",
					"answer": "Sekcja „Rozliczenia” zawiera informacje na temat działania wypłat, w tym harmonogramy płatności, okresy przyjęcia i daty wypłat. Możesz pobrać raporty transakcji dla każdej wypłaty w formacie PDF lub CSV, a także wyświetlić lub pobrać szczegóły dotyczące konkretnych płatności, klikając ich numery referencyjne."
				},
				"payoutSchedule": {
					"title": "Harmonogram wypłat",
					"whyDelay": {
						"question": "Dlaczego występuje opóźnienie w wypłacie?",
						"answer": "Głównym celem jest zapobieganie przesyłaniu pieniędzy za faktury tam i z powrotem między Tobą a Klarna, a także uwzględnienie przypadków, w których możesz zwrócić środki za przyjęte zamówienie."
					},
					"changePayoutDelay": {
						"question": "Chcę zmienić czas opóźnienia wypłaty",
						"answer": "Prowadzimy wewnętrzne kontrole, a także stosujemy ogólne kryteria, które w takim wypadku muszą zostać spełnione. Te ogólne kryteria obejmują poniższe wymagania:\n- Musisz mieć pełną integrację z Klarna.\n- Data pierwszego zamówienia przekracza 100 dni.\n- Twoja firma musi być starsza niż 6 miesięcy.\n\nKlarna wymaga również od administratora poniższych informacji:\n- Pożądane nowe opóźnienie płatności i powody, dla których zmiana opóźnienia płatności jest konieczna.\n- Którego identyfikatora sprzedawcy (MID) dotyczy zmiana?\nMogą obowiązywać również dodatkowe kryteria, a decyzje będą podejmowane indywidualnie.\n"
					}
				},
				"generatingReports": {
					"title": "Generowanie raportów",
					"howToSeeReports": {
						"question": "Jak wyświetlić raporty dla poszczególnych okresów?",
						"answer": "Raporty można dostosować do określonych okresów, korzystając z sekcji „Raporty niestandardowe” w dziale „Rozliczenia”."
					},
					"convertCsvToExcel2": {
						"answer": "Wystarczy wykonać następujące kroki:\n1. Otwórz program Excel, kliknij „Plik” > „Nowy skoroszyt”.\n"
					},
					"convertCsvToExcel": {
						"question": "Jak przekonwertować raport CSV na arkusz kalkulacyjny Excel?",
						"answer": "1. Otwórz program Excel, kliknij „Plik” > „Nowy skoroszyt”.\n2. Kliknij kartę „Dane” i wybierz opcję „Z pliku tekstowego / CSV”.\n3. Wybierz żądany plik CSV i kliknij „Pobierz dane”.\n4. W kreatorze importu tekstu wybierz opcję „Rozdzielony przecinkami”, a następnie kliknij przycisk „Dalej”.\n5. W sekcji „Ogranicznik” wybierz „Średnik” i ustaw opcję „Wykrywanie typu danych” na „Nie wykrywaj”. Kliknij „Zakończ”.\n6. W oknie dialogowym „Importuj dane” kliknij „OK”, aby umieścić dane zaczynające się w komórce A1.\n\nUwaga: W raportach CSV w Portalu dla handlowców separatorem jest średnik (;). Sprawdź ustawienia domyślnego separatora w swoim arkuszu kalkulacyjnym.\n"
					}
				},
				"payoutAndFeeCalculation": {
					"title": "Kalkulacja wypłat i opłat",
					"howAreFeesCalculated": {
						"question": "Jak obliczane są opłaty?",
						"answer": "Opłaty potrącane z wypłat Klarna są określone w umowie handlowej, a dodatkowe opłaty, takie jak należności za opóźnione zwroty, są wyszczególnione w sekcji „Opłaty” w raportach wypłat i rozliczeń.\n\nAby dostosować opisy opłat w raportach:\n1. Przejdź do sekcji Raporty rozliczeniowe > Konfiguruj raporty > Dodaj nową konfigurację.\n2. W przypadku raportów CSV wybierz „Szczegółowy typ transakcji” na liście transakcji.\n3. W przypadku raportów PDF wybierz opcję „Uwzględnij szczegóły zamówienia” w ustawieniach układu i zawartości.\n\nUpewnij się, że z osobna dostosowano ustawienia dla każdego identyfikatora sprzedawcy i kanału (SFTP, Merchant Portal, API).\n"
					},
					"howIsPayoutCalculated": {
						"question": "Jak obliczana jest moja wypłata?",
						"answer": "Wypłata jest obliczana na podstawie zamówień zarejestrowanych w danym okresie przyjęcia, z pomniejszeniem o opłaty, zwroty i inne potrącenia. Raport rozliczeniowy zawiera szczegółowe zestawienie tych kwot.\n\nJeśli zwroty przekroczą sprzedaż w okresie przyjęcia, nadwyżka zostanie odjęta od następnej wypłaty.\n"
					}
				}
			},
			"options": {
				"payout": "Wypłata",
				"orders": "Zamówienia",
				"settlements": "Rozliczenia",
				"disputes": "Spory",
				"otherIssue": "Inne sprawy",
				"yes": "Tak",
				"no": "Nie",
				"partially": "Częściowo",
				"iNeedMoreHelp": "Potrzebuję jeszcze pomocy",
				"thatHelped": "To było pomocne",
				"contactSupportChannel": "Skontaktuj się z kanałami wsparcia"
			},
			"button": {
				"label": "Uzyskaj wsparcie"
			},
			"contact": {
				"chat": "Czat z agentem",
				"ticket": "Prześlij zgłoszenie",
				"times": {
					"minutes": "Czas oczekiwania: {time} min.",
					"hours": "Czas oczekiwania: {time} godz.",
					"days": "Czas oczekiwania: {time} d."
				}
			}
		}
	}
};