import { MultiSelector, Selector } from '@klarna/mp-ui';
import { selectors as coreSelectors } from 'mage-core';
import { hooks as i18nHooks } from 'mage-i18n';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { mapMidsToStoreSelectorOptions } from './utils';
const StoreSelector = ({ borderless, children, closeText, closeTextWithSelection, deselectAllOptionText, disabled, disabledMids = [], emptyStateText, fullWidthTrigger, id, isMultiselect, label, loading, maxHeight, maxWidth, mids = [], onChange = () => null, onClose = () => null, pendingMids = [], searchFieldLabel, selectAllOptionText, selectedAllText, selectedText, value, values }) => {
    const t = i18nHooks.useTranslator();
    // @ts-ignore: FIXME - core selectors types
    const merchants = useSelector(coreSelectors.getMerchants);
    const options = mapMidsToStoreSelectorOptions(mids, merchants, { disabledMids, pendingMids });
    return (React.createElement(React.Fragment, null, isMultiselect
        ? (React.createElement(MultiSelector, { borderless: borderless, closeLabel: closeText || t('core.storesSelector.closeText'), closeWithSelectionLabel: closeTextWithSelection || t('core.storesSelector.closeTextWithSelection'), deselectAllOptionLabel: deselectAllOptionText || t('core.storesSelector.deselectAllOptionText'), disabled: disabled, emptyStateLabel: emptyStateText || t('core.storesSelector.emptyStateText'), isFullWidthTrigger: fullWidthTrigger, id: id, label: label || t('core.storesSelector.label'), loading: loading, maxHeight: maxHeight, maxWidth: maxWidth, onChange: onChange, onClose: onClose, options: options, searchLabel: searchFieldLabel || t('core.storesSelector.searchFieldLabel'), selectAllOptionLabel: selectAllOptionText || t('core.storesSelector.selectAllOptionText'), selectedAllLabel: selectedAllText || t('core.storesSelector.selectedAllText'), selectedLabel: selectedText || t('core.storesSelector.selectedText'), values: values || [], withSearch: mids.length > 4, withSelectAll: mids.length > 1 }, children))
        : (React.createElement(Selector, { borderless: borderless, closeLabel: closeText || t('core.storesSelector.closeText'), disabled: disabled, isFullWidthTrigger: fullWidthTrigger, id: id, label: label || t('core.storeSelector.label'), loading: loading, maxHeight: maxHeight, maxWidth: maxWidth, onChange: onChange, onClose: onClose, options: options, value: typeof value === 'undefined' ? '' : value }, children))));
};
StoreSelector.propTypes = {
    borderless: PropTypes.bool,
    children: PropTypes.node,
    closeText: PropTypes.string,
    closeTextWithSelection: PropTypes.string,
    deselectAllOptionText: PropTypes.string,
    disabled: PropTypes.bool,
    disabledMids: PropTypes.arrayOf(PropTypes.string),
    emptyStateText: PropTypes.string,
    fullWidthTrigger: PropTypes.bool,
    id: PropTypes.string,
    isMultiselect: PropTypes.bool,
    label: PropTypes.string,
    loading: PropTypes.bool,
    maxHeight: PropTypes.number,
    maxWidth: PropTypes.number,
    mids: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    pendingMids: PropTypes.arrayOf(PropTypes.string),
    searchFieldLabel: PropTypes.string,
    selectAllOptionText: PropTypes.string,
    selectedAllText: PropTypes.string,
    selectedText: PropTypes.string,
    value: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.string)
};
export default StoreSelector;
