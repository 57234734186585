import { DirectSelector } from '@klarna/mp-ui';
import { hooks as i18nHooks } from 'mage-i18n';
import insights, { TrackImpression } from 'mage-insights';
import React from 'react';
import styled from 'styled-components';
import { PLUGINS } from '../../../../constants';
import { useMid } from '../../hooks/useMid';
import { usePlugin } from '../../hooks/usePlugin';
import PluginLogo from './PluginLogo';
const FullWidthSelector = styled.div.withConfig({ displayName: "FullWidthSelector", componentId: "sc-1tj0n58" }) `
  padding-top: 16px;
`;
const StyledDirectSelector = styled(DirectSelector).withConfig({ displayName: "StyledDirectSelector", componentId: "sc-uwm8an" }) `
  width: 100%;
`;
export default function SelectPlatform() {
    const t = i18nHooks.useTranslator();
    const { setPluginId } = usePlugin();
    const { selectedMid } = useMid();
    const pluginOptions = [
        ...PLUGINS.map(plugin => ({
            value: plugin.id,
            label: plugin.name,
            icon: React.createElement(PluginLogo, { imageName: plugin.logo.imageName, height: plugin.logo.height })
        }))
    ];
    const dropdownImpressionEvent = {
        category: 'v3/boost-home/install-plugin/dropdown-menu',
        action: 'impression',
        label: selectedMid !== null && selectedMid !== void 0 ? selectedMid : undefined
    };
    const dropdownClickEvent = {
        category: 'v3/boost-home/install-plugin/dropdown-menu',
        action: 'click',
        label: selectedMid !== null && selectedMid !== void 0 ? selectedMid : undefined
    };
    const platformClickEvent = (id) => ({
        category: `v3/boost-home/install-plugin/${id}`,
        action: 'click',
        label: selectedMid !== null && selectedMid !== void 0 ? selectedMid : undefined
    });
    const onChangeDropdown = (id) => {
        if (!setPluginId || typeof id != 'string')
            return;
        setPluginId(id);
        insights.event(platformClickEvent(id));
    };
    const onOpenDropdown = () => {
        insights.event(dropdownClickEvent);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(TrackImpression, { event: dropdownImpressionEvent },
            React.createElement(FullWidthSelector, null,
                React.createElement(StyledDirectSelector, { onChange: onChangeDropdown, onOpen: onOpenDropdown, label: t('home-fe.boosters-home-v03.install-plugin.selectorLabel'), options: pluginOptions })))));
}
