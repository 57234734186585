module.exports = {
	"en": {
		"framework": {
			"popOvers": {
				"boostersTab": {
					"title": "New features available",
					"content": "Click for features designed to turn visitors into power shoppers."
				}
			}
		}
	}
};