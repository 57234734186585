module.exports = {
	"da": {
		"framework": {
			"popOvers": {
				"boostersTab": {
					"title": "",
					"content": ""
				}
			}
		}
	}
};