import { BlockPlain, Loader, View } from '@klarna/bubble-ui'
import React from 'react'

const LoadingComponent = () => (
  <View style={{ width: '100%', marginBottom: '25px' }}>
    <BlockPlain blockMode>
      <div data-testid='widget_loading' style={{ minHeight: '80px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Loader />
      </div>
    </BlockPlain>
  </View>
)

export default LoadingComponent
