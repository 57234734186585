module.exports = {
	"no": {
		"config": {
			"environments": {
				"development": "Utviklingsmiljø",
				"staging": "Staging-miljø",
				"playground": "Playground-miljø",
				"production": "Produksjonsmiljø"
			}
		}
	}
};