module.exports = {
	"sv": {
		"home-fe": {
			"boosters-home-v03": {
				"install-plugin": {
					"stepNumber": "1",
					"title": "Installera plugin",
					"description": "Välj din e-handelsplattform så att vi kan skapa en länk för att påskynda din installation",
					"selectorLabel": "Välj plattform",
					"getPluginButtonLabel": "Skaffa plugin",
					"klarnaDocsLinkLabel": "Läs mer på Klarna docs"
				},
				"start-setup": {
					"stepNumber": "2",
					"title": "Aktivera plugin",
					"description": "Generera och kopiera kundidentifieraren och API-nyckeln, du kommer att behöva dessa för att aktivera din plugin"
				},
				"promotion-banner": {
					"title": "Klarna för plattformar",
					"subtitle": "Få ditt företag att växa med en enda plugin som ger dig tillgång till flera funktioner för ökad försäljning och förbättrade shoppingupplevelser utan extra kostnad",
					"banner-cards": {
						"osm-title": "Öka det genomsnittliga ordervärdet med <span>meddelanden på webbplatsen</span> ",
						"kec-title": "Öka konverteringsgraden med expressbetalning",
						"siwk-title": "Locka nya kunder med <span>Logga in med Klarna</span>"
					}
				},
				"configure-boost-features": {
					"stepNumber": "3",
					"title": "Skräddarsy funktionsplaceringar",
					"description": "Detta är ett valfritt steg för att ändra hur funktionerna ser ut i din butik. För att göra detta ska du redan ha slutfört steg två.",
					"no-credentials": "Se till att först generera kundidentifieraren och API-nyckeln för att fortsätta detta steg.",
					"apps": {
						"osm": {
							"link-label": "Skräddarsy meddelanden på webbplatsen",
							"description": "Skräddarsy meddelanden på webbplatsen så att de passar ditt varumärke genom att justera temat och välja placeringar"
						},
						"kec": {
							"link-label": "Skräddarsy expressbetalning",
							"description": "Skräddarsy expressbetalningsknappen så att den passar ditt varumärke genom att justera knappens tema och form samt välja  placeringar"
						},
						"siwk": {
							"link-label": "Ställ in inloggning med Klarna",
							"description": "Välj omfattningen för inloggning med Klarna och lägg till en URL för omdirigering. Omfattningen är informationen som samlas in från din kund och URL:en för omdirigering är det som används om kunder blockerar popup-fönster"
						}
					}
				},
				"client-identifier-section": {
					"title": "Kundidentifierare",
					"description": "Den unika identifieraren för installation",
					"manage-link": "Hantera kundidentifierare och ursprung",
					"generate-button": "Generera",
					"tooltip": "Generera och kopiera kundidentifieraren. Du behöver dessa för att starta plugin-installationen på din plattforms portal."
				},
				"api-key": {
					"warning-banner": {
						"content": "Du har redan aktiva API-nycklar. Innan du genererar nya, se till att du har avaktiverat alla du inte använder."
					},
					"title": "Klarna API-nyckel",
					"description": "API-nyckel för inställning",
					"tooltip": "Observera tt vi inte kommer att visa din hemliga API-nyckel igen efter att du genererar den.",
					"generate-button": "Generera",
					"manageApiKey": "Hantera API-nyckel"
				},
				"non-platform-integration": {
					"question": "Använder du inte Klarna-plattformar",
					"link": "Se hur du interagerar direkt med Klarna"
				}
			},
			"boosters-home": {
				"welcome-banner": {
					"title": "Öppna upp hela kraften i Klarna",
					"text": "Funktioner utformade för att förvandla besökare till kraftfulla shoppare. Förbättra varje steg av shoppingupplevelsen — från sökning till betalning."
				},
				"plugin-carousel": {
					"title": "Ingen kodinstallation med en plugin",
					"overviewLink": "Visa alla plattformar",
					"directIntegrationLabel": "Använder du inte Klarna-plattformar",
					"directIntegrationLink": "Se hur du interagerar direkt med Klarna"
				},
				"booster-apps": {
					"title": "Läs mer.",
					"kec-fe": {
						"title": "Gör betalningen fem gånger snabbare",
						"description": "Färre klick innebär färre avhopp. Öka antalet slutförda köp och minimera antalet övergivna varukorgar med snabb betalning på produkt- och varukorgssidor.",
						"callToAction": "Mer om expressbetalning"
					},
					"siwk-fe": {
						"title": "Social inloggning för shopping",
						"description": "Shoppers får ett snabbt och säkert alternativ för att logga in med ett klick. Du får förbättrade, köpprofiluppgifter som man kan handla utifrån.",
						"callToAction": "Mer om Logga in med Klarna"
					},
					"upstream-fe": {
						"title": "Omvandla besökare till kunder",
						"description": "Maximera antalet besökare som blir kunder med dynamiska kampanjmeddelanden under hela shoppingresan.",
						"callToAction": "Mer om meddelanden på webbplatsen"
					},
					"branding-fe": {
						"title": "Förbättra synligheten för din butik",
						"description": "Hantera ditt varumärke i Klarna-appen genom att ladda upp butikslogga, ikon och URL.",
						"callToAction": "Ställ in varumärkeshanterare"
					}
				}
			},
			"boosters-plugins": {
				"title": "Installation av plugin",
				"description": "Kom igång med våra plugins och se till att du är redo att lansera."
			},
			"boosters-plugin": {
				"title": "Klarna för {pluginName}",
				"description": "Vidta åtgärderna nedan för att aktivera Klarna-betalningar och/eller boost-funktioner utan vår installation utan kod.",
				"access-warning": {
					"both": "Du behöver inget tillstånd för {selectedStoreName} ({selectedMid}) för att generera API-nycklar och kundidentifierare. Kontakta din administratörsanvändare och fråga efter åtkomst till betalningsinställningar för att fortsätta.",
					"client": "Du behöver inget tillstånd för {selectedStoreName} ({selectedMid}) för att generera kundidentifierare. Kontakta din administratörsanvändare och fråga efter åtkomst till betalningsinställningar för att fortsätta.",
					"api": "Du behöver inget tillstånd för {selectedStoreName} ({selectedMid}) för att generera API-nycklar. Kontakta din administratörsanvändare och fråga efter åtkomst till betalningsinställningar för att fortsätta."
				},
				"back": "Tillbaka",
				"instructions": {
					"api-key": {
						"title": "Aktivera Klarna-betalning",
						"content": "Du måste skapa och generera API-nycklar så att du kan använda dem under din installation.",
						"link-label": "Läs mer på Klarna docs",
						"generate": "Generera API-nycklar",
						"active-warning": {
							"content": "Du har redan aktiva API-nycklar. Innan du genererar nya, se till att du har avaktiverat alla du inte använder.",
							"link": "Hantera API-nycklar"
						},
						"active-warning-api-keys": {
							"content": "Du har redan aktiva API-nycklar. Innan du genererar nya, se till att du har avaktiverat alla du inte använder.",
							"link": "Hantera API-nycklar"
						}
					},
					"client-id": {
						"title": "Aktivera boost-funktioner",
						"content": "För att lägga till Klarna snabbkassa och meddelande på webbplatsen behöver du generera och kopiera din kundidentifierare så att du kan använda den under installationen.",
						"generate": "Generera kundidentifierare",
						"manage": "Hantera kundidentifierare"
					},
					"plugin-doc": {
						"title": "Konfigurera boost-funktioner",
						"content": "För att slutföra din installation måste du konfigurera Klarna expressbetalning och meddelanden på webbplatsen i plattformens inställningar."
					}
				},
				"error-messages": {
					"default": "Ett fel har uppstått. Försök igen senare eller gå till hjälp.",
					"fetch-data": "Ett fel har inträffat när du hämtade dina uppgifter. Försök igen senare eller gå till hjälp.",
					"create-api-key": "Ett fel inträffade när du skapade dina API-nycklar. Försök igen senare eller gå till hjälp.",
					"create-client-id": "Ett fel har inträffat vis skapande av din kundidentifierare. Försök igen senare eller gå till hjälp."
				}
			},
			"marketing-home": {
				"description": "Välkommen till våra marknadsföringsprodukter. Börja utforska nedan.",
				"essentials": {
					"title": "Marknadsföring",
					"cta-label": "Läs mer.",
					"branding-fe": {
						"description": "Här kan du skapa och konfigurera ditt varumärke Visa upp dess bästa sida i Klarna's ekosystem, inklusive vår smoooth app."
					},
					"klarna-search-b2b-portal": {
						"description": "Kom igång med Klarnas kraftfulla sökmotor och jämförelseshopping. Få åtkomst till statistik och verktyg som designats för att förbättra din synlighet."
					}
				},
				"external-apps": {
					"title": "Appar",
					"cta-label": "Öppna app"
				},
				"popOvers": {
					"searchCompare": {
						"title": "Kom igång med sök och jämför",
						"content": "Kolla här för statistik och verktyg som kan hjälpa dig att öka sin synlighet på Klarnas tjänst för jämförelseshopping."
					}
				}
			},
			"no-apps": {
				"title": "Inga appar tillgängliga!",
				"subtitle": "Kontakta din administratör för att få tillgång till apparna du behöver."
			},
			"contact": {
				"title": "Behöver du hjälp med något?",
				"paragraph": "Tveka inte att höra av dig om du har frågor så hjälper vi dig hitta svar.",
				"label": "Kontakta oss"
			},
			"important-banner": {
				"title": "",
				"content": "",
				"linkLabel": ""
			},
			"two-factor-home-banner": {
				"banner-text": "Gör ditt konto säkrare med tvåfaktorautentisering.",
				"banner-link": "Aktivera"
			},
			"two-factor-opt-in": {
				"title": "Aktivera tvåfaktorautentisering",
				"intro-paragraph": "För att öka säkerheten kring ditt konto rekommenderar vi dig att aktivera tvåfaktorautentisering idag.",
				"help-link-text": "Klicka här",
				"help-text": " för att lära dig mer om hur du aktiverar det och hur det skyddar dig.",
				"warning-text": "Se till att du har din smartphone redo och fungerande internetuppkoppling innan du fortsätter",
				"continue-button": "Aktivera"
			},
			"accept-invite-modal": {
				"title": "Godkänn väntande inbjudningar",
				"description": "Du har blivit inbjuden till följande butiker. Observera att det kan ta några få minuter innan dina val genomförts.",
				"accept-all-button": "Godkänn alla",
				"decline-all-button": "Neka alla",
				"save-button": "Spara",
				"error": "Ett problem har inträffat. Försök igen senare!",
				"invite": {
					"accept-label": "Godkänn",
					"decline-label": "Neka"
				}
			},
			"capture-orientation": {
				"title": "Förstagångsanvändare hos Klarna?",
				"sub-title": "Följ dessa steg för att lära dig grunderna:",
				"order-label": "Ta emot en order",
				"capture-label": "Aktivera ordern",
				"payout-label": "Få betalt",
				"capture-cta": "Aktivera denna order",
				"payout-cta": "App för Avräkningar",
				"dismiss-cta": "Avvisa",
				"order-step": "Först av allt ska vi vänta på en inkommande order från dina kunder.",
				"capture-step": "Grattis till din första order.\nFör att få betalt är nästa steg att skicka och registrera din order.",
				"payout-step": "Grattis till registreringen av din första order.\nDu kommer nu få en utbetalning från oss i linje med de tidpunkter som definieras i ditt avtal med Klarna.\nFör mer detaljer kring din första utbetalning, gå till",
				"completed-step": "Grattis till din första utbetalning.\nVälkommen till en underbar resa tillsammans med Klarna.\nUtforska gärna de andra apparna vi erbjuder nedan."
			},
			"banner": {
				"common": {
					"branding-fe": {
						"content": "Vi släppte precis vår nya varumärkesapp!",
						"linkLabel": "Ta kontroll över ditt varumärke nu!"
					},
					"onSiteMessaging": {
						"title": "Förvandla besökare till shoppare med personliga meddelanden",
						"text": "Shopping börjar långt innan dina kunder ska betala. Med personliga meddelanden ökar du medvetenheten om Klarnas flexibla betalningsmöjligheter och andra förmåner tidigt i försäljningstratten.",
						"cta": "Kolla in det"
					}
				}
			},
			"nko": {
				"dialog": {
					"title": "Vi lanserar några nya spännande funktioner för ditt företag.",
					"subtitle": "Och vi skulle vilja lära känna dig bättre.",
					"explore": "Ja, vi går vidare!",
					"name-field": "Så, vad vad heter du?",
					"terms-pre": "Jag har läst och accepterar",
					"terms-text": "Villkoren"
				}
			},
			"insightsWidget": {
				"title": "Försäljning idag",
				"orderCount": "Skapade ordrar",
				"orderVolume": "Ordervolym",
				"orderVolumeAverage": "Genomsnittligt ordervärde",
				"currencyFootnote": "Visas i {currency}",
				"link": "Se beställningar",
				"allStores": "Alla butiker",
				"selectAllOptionText": "Välj alla",
				"deselectAllOptionText": "Rensa val",
				"closeText": "Klar",
				"closeTextWithSelection": "Applicera # val",
				"elapsedTime": "Senaste ordern mottagen för {hours, plural, =0 {} =1 {en timme} other {# timmar}} {minutes, plural, =0 {} =1 {en minut} other {# minuter}} sen",
				"error": {
					"content": "Tyvärr laddades inte din orderstatistik korrekt."
				},
				"relativeDate": {
					"today": "Idag",
					"yesterday": "Igår"
				},
				"weekDays": {
					"1": "Måndag",
					"2": "Tisdag",
					"3": "Onsdag",
					"4": "Torsdag",
					"5": "Fredag",
					"6": "Lördag",
					"7": "Söndag"
				}
			},
			"blackFridayWidget": {
				"title": "Black Friday week är här. ",
				"text": "Följ din försäljning, timme för timme.",
				"button": "Se statistik"
			},
			"tasks": {
				"title": "Att göra-lista",
				"error": {
					"title": "Vi kunde inte ladda att göra-listan",
					"desc": "Vänligen ladda om den här sidan så att vi kan försöka igen."
				},
				"empty": {
					"title": "Allt är under kontroll.",
					"desc": "Du har inga uppgifter att göra just nu."
				},
				"single": {
					"products-fe:complete-recollection": "Obligatoriskt AML formulär att fylla i om åtgärder mot penningtvätt",
					"products-fe:resume-onboarding": "Slutför din onboarding",
					"settings-fe:compile-cs-info": "Lägg till din kundtjänstinformation"
				},
				"aggregated": {
					"disputes-fe:unresolved": "{ count, plural, =1 {1 olöst tvist} other {# olösta tvister}}",
					"orders-fe:expiring": "{ count, plural, =1 {1 utgående order} other {# utgående ordrar}}",
					"orders-fe:tocapture": "{ count, plural, =1 {1 order att aktivera} other {# ordrar att aktivera}}",
					"orders-fe:uncaptured": "{ count, plural, =1 {1 oaktiverad order} other {# oaktiverade ordrar}}",
					"users-fe:mid-added": "{ count, plural, =1 {1 ny butik har lagts till} other {# nya butiker har lagts till}}",
					"users-fe:mid-invited": "{ count, plural, =1 {1 butiksinbjudan väntar på åtgärd} other {# butiksinbjudningar väntar på åtgärd}}"
				}
			}
		}
	}
};