module.exports = {
	"no": {
		"help-tool": {
			"button-text": "Hjelp",
			"support-button": "Få hjelp",
			"support-page": {
				"create-ticket": {
					"title": "Få støtte",
					"instructions": "Send inn en forespørsel nedenfor, så kommer vi tilbake til deg innen 1 virkedag.",
					"related-content": {
						"title": "Disse kan hjelpe deg med å løse problemet"
					},
					"fields": {
						"mid": {
							"label": "Velg butikk",
							"validation": "Butikk kreves"
						},
						"phoneNumber": {
							"label": "Telefonnummer (valgfritt)"
						},
						"topic": {
							"question": "Hva er spørsmålet ditt relatert til?",
							"label": "Velg et emne",
							"validation": "Emne kreves",
							"options": {
								"orders": "Ordre",
								"settlements": "Oppgjør",
								"agreements": "Avtaler",
								"others": "Andre"
							}
						},
						"subject": {
							"label": "Emne",
							"validation": "Tema kreves"
						},
						"description": {
							"label": "Beskjed",
							"validation": "Beskrivelse kreves"
						},
						"attachment": {
							"label": "Legg til vedlegg",
							"button": "Last opp",
							"info": "Å legge til skjermbilder vil hjelpe oss med å løse problemet raskere.",
							"supportedFileTypes": "Støttede filtyper: .png, .jpg, .pdf",
							"error": "Total filstørrelse kan ikke overstige {maxSize} MB"
						}
					},
					"submit": "Send forespørsel",
					"service-unavailable-error": "Oisann! Noe gikk galt, prøv igjen om noen minutter. Hvis problemet vedvarer, {link} for hjelp.",
					"generic-error": "Vi kunne ikke behandle forespørselen din. Systemene våre snakker ikke med hverandre akkurat nå. Prøv å oppdatere, eller kom tilbake senere. Hvis dette fortsetter, {link} for hjelp.",
					"contact-link": "kontakt oss"
				},
				"view-ticket": {
					"title": "Ferdig!",
					"details": "Vi har mottatt forespørselen din, og vi vil gi deg svar innen 1 virkedag. Her er detaljene dine:",
					"ticket": "Be om #{ticketId}",
					"email": "E-post: {email}",
					"status": "Du kan spore statusen på supportsiden vår.",
					"track": "Spor status"
				}
			},
			"header": {
				"homeLink": "Tilbakestill"
			},
			"home": {
				"greeting": "Hei {givenName}! Hvordan kan vi hjelpe deg?",
				"topics": "Hva trenger du hjelp med?"
			},
			"topics": {
				"expandButton": "Mer"
			},
			"feedback": {
				"title": "Var dette til hjelp?",
				"optionYes": "Ja, dette var nyttig",
				"optionNo": "Nei, jeg trenger mer hjelp",
				"positiveFeedback": "Takk for positiv tilbakemelding, dette hjelper oss med å gi deg mer relevante artikler i fremtiden."
			},
			"freeTextFeedback": {
				"title": "Hjelp oss å bli bedre",
				"placeholder": "Hvorfor synes du ikke at dette innholdet var nyttig?",
				"submitButton": "Send",
				"feedbackMessage": "Takk for din tilbakemelding, dette hjelper oss med å gi deg mer relevante artikler i fremtiden."
			},
			"relatedContent": {
				"title": "Relatert innhold"
			},
			"search": {
				"buttonLabel": "Søk",
				"input": "Søk etter artikler ...",
				"loading": "Laster inn ...",
				"results": {
					"contact": "Finner du ikke hjelpen du trenger?",
					"empty": "Beklager, vi fant ingen resultater for søket ditt. Prøv å søke etter et annet begrep.",
					"singleTopicFound": "Vi fant 1 emne",
					"multipleTopicsFound": "Vi fant {count} emner",
					"singleArticleFound": "Vi fant 1 artikkel",
					"multipleArticlesFound": "Vi fant {count} artikler"
				}
			},
			"contact": {
				"title": "Kontakt oss",
				"channelsIntro": "Kontakt oss slik at vi kan hjelpe deg.",
				"businessDetailsTitle": "Forretningsinformasjonen din",
				"businessDetailsName": "Firmanavn",
				"businessDetailsEmail": "E-post",
				"businessDetailsMid": "Butikk-ID",
				"phone": {
					"title": "Ring oss",
					"paragraph": "Du kan ringe oss i arbeidstiden på hverdager. Hvis du befinner deg i Sverige eller Storbritannia, kan du også ringe oss i helgen mellom kl. 10.00 og 16.00."
				},
				"email": {
					"title": "Send oss en e-post",
					"paragraph": "Send oss en e-post, så kommer vi tilbake til deg så snart vi kan."
				}
			},
			"errorBoundary": {
				"title": "Noe gikk galt",
				"message": "Vi beklager, men det oppsto en feil. Du kan klikke på \"Gå tilbake\"-knappen nedenfor for å gå tilbake til hjelpeverktøyet eller \"Få hjelp\"-knappen for å kontakte oss.",
				"resetButton": "Gå tilbake",
				"errorId": "Feil-ID: {errorId}"
			},
			"tour": {
				"prev": "Forrige",
				"next": "Neste",
				"done": "Ferdig"
			},
			"notAvailableMobile": {
				"title": "Ikke tilgjengelig",
				"buttonBack": "Tilbake",
				"paragraph": "Dette innholdet er ikke tilgjengelig på enheten din. Prøv å åpne på en enhet med større skjermstørrelse, for eksempel en stasjonær datamaskin."
			},
			"shareButton": {
				"title": {
					"article": "Del en dyp lenke til denne artikkelen",
					"topic": "Del en dyp lenke til dette emnet",
					"tour": "Del en dyp lenke til denne turen"
				},
				"radioItemHome": "Hjem-app",
				"descriptionHome": "Åpner hjelpeartikkelen på Merchant Portal-hjemmesiden",
				"radioItemCurrent": "Nåværende side",
				"descriptionCurrent": "Åpner hjelpeartikkel på gjeldende side"
			},
			"marketSelector": {
				"whichMarketToContact": "Hvilket marked ønsker du å kontakte?"
			}
		},
		"orientation": {
			"title": "Lær hvordan man lager ..."
		},
		"helpbot": {
			"questions": {
				"whatDoYouNeedHelpWith": "Hei! Hva trenger du hjelp med i dag?",
				"selectSupportChannel": "Velg en kanal for å kontakte vår supporttjeneste.",
				"thatHelped": "Takk for den positive tilbakemeldingen, dette hjelper oss mye med å gi deg mer relevant hjelp i fremtiden. 😀"
			},
			"orders": {
				"orderManagement": {
					"title": "Ordrebehandling",
					"createOrderPos": {
						"question": "Hvordan oppretter jeg en ordre gjennom Point of Sale?",
						"answer": "1. Åpne Point of Sale 2. Skriv inn varenavn, produktpris og antall. 3. Del den genererte betalingslenken med kunden din via SMS, e-post, eller ved å la kunden skanne QR-koden som vises på betalingsskjermen. 4. Kunden kan nå fullføre kjøpet fra enheten sin."
					},
					"extendExpiryReservation": {
						"question": "Hvordan forlenger jeg utløpsdatoen på en reservasjon?",
						"answer": "1. Gå til ordrebehandleren og velg én eller flere ordrer du vil forlenge utløpsdatoen for. 2. Klikk på Forleng utløpsdato. 3. Bekreft valget ditt.\nOrdrer forlenges i henhold til reservasjonstidsinnstillingene for butikken din (vanligvis 28 dager). Hvis en ordre har utløpt, kan den ikke lenger forlenges.\""
					},
					"howToCaptureOrders": {
						"question": "Hvordan leverer jeg ordrer?",
						"partially": {
							"question": "Jeg vil delvis levere en ordre",
							"answer": "Hvis det bare er mulig å sende deler av en kundeordre, kan du delvis levere ordren etter ordrelinjer eller beløp. Lever ordrelinjer 1. Gå til ordren i ordrebehandlingen og velg varene du har på lager fra ordresiden. Husk å redigere ordrelinjer for å justere antall om nødvendig. 2. Klikk på lever. Leveransebeløp: 1. Gå til ordren i Ordrebehandleren. Du kan levere et beløp ved å klikke på \"Lever alle\"-knappen. Her legger du inn ønsket beløp. 2. Klikk på Lever.\nHusk å fullføre ordren når det er mulig, enten ved å levere gjenværende varer/beløp når det er tilgjengelig for å sendes - eller ved å kansellere den gjenværende ordren hvis du ikke får sendt varene."
						},
						"fully": {
							"question": "Jeg ønsker å levere ordrer i sin helhet",
							"answer": "1. Gå til Ordrebehandleren og klikk på Klarna-referansenummeret for å åpne ordren. 2. Lever hele ordren ved å bruke ett av følgende trinn: - Klikk på \"Lever\"-knappen – Velg ordrelinjene og klikk på \"Lever\". - Klikk på \"Lever alle\" og angi hele ordrebeløpet. 3. Mens du leverer ordren kan du laste ned pakkseddelen for å inkludere den i forsendelsen.\nSørg for å levere ordrer før utløpsdatoen (vanligvis 28 dager etter opprettelsen av ordren). Du kan levere flere ordrer ved å velge dem i ordrelisten og klikke på \"Full levering\" hvis du vil øke hastigheten."
						}
					},
					"canIUpdateCustomerInfo": {
						"question": "Kan jeg oppdatere kundeinformasjon?",
						"answer": "Du kan aldri endre mottaker av en ordre. Men det er mulig å endre leverings- og faktureringsadressen for ordrer som ikke er levert eller er delvis leverte. Men hvis Klarna avviser den nye adressen fordi den ikke er koblet til kunden, kan faktureringsadressen fortsatt endres."
					},
					"extendDueDate": {
						"question": "Hvordan kan jeg forlenge utløpsdatoen på en ordre?",
						"answer": "Du kan forlenge forfallsdatoen på en ordre ved å klikke på \"Forleng forfallsdatoen\" på en levert ordre. Forlengelse av forfallsdatoen for en ordre kommer med et gebyr som vil bli spesifisert før du fullfører handlingen."
					},
					"refundOrder": {
						"question": "Hvordan refunderer jeg en ordre?",
						"answer": "1. Gå til ordrebehandleren og finn ordren du ønsker å refundere. 2. Velg refusjonsmetode: Velg enten varer som skal refunderes og klikk deretter på \"Refusjon\" eller klikk på \"Refusjon\" først for å angi beløpet du vil refundere. 3. Det refunderte beløpet vil bli trukket fra din neste utbetaling, og kunden vil motta beløpet innen 5 virkedager.\nDette gjelder kun for leverte ordrer. For ordrer som ikke er levert, kansellerer du dem hvis du ikke skal sende noen varer. Hvis bare en del av ordren ble levert og sendt, frigi den uleverte delen."
					},
					"cancelOrder": {
						"question": "Hvordan kansellerer jeg en ordre?",
						"answer": "1. Gå til ordrebehandleren og finn ordren du ønsker å kansellere. 2. Klikk på \"Kanseller ordre\". Denne handlingen informerer også kunden om at ordren ikke vil bli sendt.\nDu kan alltid gjenskape kansellerte ordrer hvis du trenger det. Du kan ikke kansellere leverte ordrer, men du kan refundere dem."
					},
					"unableToTakeActions": {
						"question": "Hvorfor kan jeg ikke utføre handlinger på en ordre?",
						"directPartner": {
							"question": "Jeg har avtale direkte med Klarna",
							"answer": "Brukertillatelser bestemmer hva brukere kan gjøre i partnerportalen. Det finnes to forskjellige typer brukertillatelser: Admin - brukere med full tilgang; for eksempel kan en administrator få tilgang til og redigere all funksjonalitet i partnerportalen. Seksjonsspesifikk – Ikke-adminbrukere har tilgang til én eller flere spesifikke seksjoner i partnerportalen.\nDette kan være årsaken til hvorfor du ikke kan utføre handlinger på ordrer. Det er forskjellige tilgangsroller knyttet til ordrebehandleren, så snakk med administratoren din for å finne ut hvilken tilgangsrolle som er best egnet for handlingene du må utføre."
						},
						"distributionPartner": {
							"question": "Jeg har avtale direkte med Klarna",
							"answer": "Eventuelle handlinger du ønsker å utføre på en ordre kan bare gjøres gjennom plattformen levert av distribusjonspartneren"
						}
					}
				},
				"orderModification": "Ordremodifisering og problemer",
				"orderProcessing": "Ordrebehandling",
				"refundIssues": {
					"title": "Refusjonsproblemer",
					"refundedOrderWhenCustomerFunds": {
						"question": "Jeg har tilbakebetalt en ordre, når vil kunden min motta midlene sine?",
						"answer": "En kunde bør motta midlene sine innen 5 virkedager fra når en ordre ble tilbakebetalt. Dette kan variere avhengig av hvilken betalingsmetode kunden har valgt. Hvis kunden har spørsmål om dette, henvis dem til Klarnas kundestøtte."
					}
				},
				"reservations": {
					"title": "Reservasjoner",
					"whyUnableToExtendReservation": {
						"question": "Hvorfor er jeg ikke i stand til å forlenge reservasjonstiden på en ordre?",
						"answer": "Det er spesifikke omstendigheter der det ikke er mulig å forlenge utløpsdatoen for en ordre:\n- Hvis ordren allerede har utløpt, må du opprette den på nytt. For å gjøre dette, gå til ordresiden, klikk på «Kopier» og velg «Opprett ny ordre».\n- Hvis ordren ble betalt med kort, må kunden legge inn en ny ordre, da utløpsdatoer for disse ordrene ikke kan forlenges.\n"
					}
				},
				"paymentsForMyOrders": {
					"title": "Betalinger for ordrene mine",
					"whenWillIGetPaid": {
						"question": "Når vil jeg bli betalt for en ordre?",
						"answer": "For å motta betaling for en ordre må du først fange den opp. Utbetalingsbeløpet, etter fratrekk av gebyrer, returer og andre belastninger, vil sendes til bankkontoen din basert på utbetalingsplanen din. Du kan gå gjennom utbetalingsoppsettet ditt i Oppgjør-delen."
					},
					"refundOrderReceivedPayment": {
						"question": "Hva skjer hvis jeg tilbakebetaler en ordre jeg allerede har mottatt midler for?",
						"answer": "Ethvert beløp du tilbakebetaler vil trekkes fra den neste planlagte utbetalingen din."
					},
					"referenceNotShowUp": {
						"question": "Ordrenummer og referansenummer vises ikke i rapporten.",
						"answer": "Hvis du ikke kan finne en betaling for en spesifikk ordre:\n- Verifiser nøyaktigheten av ordre- eller referansenummeret.\n- Sikre at ordren er fanget opp.\n- Kontroller om ordren faller inn under rapportens oppfangingsperiode.\n- Merk at ordrer som er kansellert før oppgjør ikke vil vises i rapporten.\n"
					},
					"gotPaidSpecificOrder": {
						"question": "Hvordan ser jeg om jeg har blitt betalt for en spesifikk ordre?",
						"answer": "I Partner Portals ordreadministrator, søk etter ordren og sikre at ordre- eller referansenummeret er nøyaktig. Under referansenummeret, kontroller om ordren er fanget opp. Hvis den er fanget opp, vil status vises som avventer eller utbetalt. For utbetalte ordrer, klikk på «Se oppgjør» for å vise den korresponderende rapporten."
					}
				}
			},
			"disputes": {
				"disputeManagement": "Tvisteadministrasjon",
				"contactInfoAndNotifications": "Kontaktinformasjon og varslingsinnstillinger",
				"timingAndDeadlines": "Timing og tidsfrister for tvister",
				"resolutionProcess": "Tvisteløsningsprosess",
				"merchantDisputesApp": {
					"title": "Tvist-appen",
					"respondToDispute": {
						"question": "Hvordan vet jeg om jeg må svare på en tvisteforespørsel?",
						"answer": "Kontroller «Gjøremål»-delen på Partner Portal-hjemmesiden din for å se om noen tvister krever svar fra deg."
					},
					"overviewDisputesCustomer": {
						"question": "Hvor kan jeg finne en oversikt over alle tvister fremmet av kunder?",
						"answer": "Du kan finne alle fremmede tvister i Tvister-delen, under «Alle tvister»-siden, umiddelbart etter at tvisten er fremmet.\n\nMerk at tvister er tilgjengelige på «Alle tvister»-siden i opptil 180 dager etter at de er avsluttet.\n"
					},
					"overviewDisputesResponse": {
						"question": "Hvor kan jeg finne en oversikt over alle tvister som krever et svar?",
						"answer": "Du kan finne alle tvister som krever et svar i Tvister-delen på «Åpne tvister»-siden under «Svar påkrevd»."
					},
					"updateContactInformation": {
						"question": "Hvordan kan jeg oppdatere kontaktinformasjonen min for e-poster med påminnelse om tvister?",
						"answer": "1. Gå til Tvister-delen\n2. Klikk deg inn på «E-post- og tvist-innstillinger»-siden\n3. Oppdater kontaktinformasjonen din i «E-post og varsling»-fanen\n\nMerk at du kan angi forskjellig kontaktinformasjon for «tvister», «uautoriserte kjøp» og «høyrisikoordrer». I tillegg kan du angi frekvensen av varslinger i fanen «Varslinger».\n"
					},
					"updateCustomerServiceOnKlarnaApp": {
						"question": "Hvordan kan jeg oppdatere Kundeservice-informasjonen min i Klarna-appen?",
						"answer": "1. Gå til «Betalingsinnstillinger»\n2. Klikk på «Kundeservice-informasjon»\n3. Velg «Marked» (hvis du har markedsspesifikk støtte)\n\nDu kan angi Kundekontakt-informasjonen så vel som returpolicyen for å vises i Klarna-appen for en enklere kundeopplevelse.\n\nMerk at du trenger fullstendige tilgangsrettigheter til Partner Portal for å vise denne informasjonen.\n"
					},
					"improveDisputesPerformance": {
						"question": "Hvordan kan jeg forbedre tvisteytelsen min?",
						"answer": "For å få tips og triks om hvordan du forbedrer tvisteytelsen din, gå til «Forbedringsveiledning for returer og tvister». Du kan få tilgang til den via «Statistikk»-siden ved å klikke på «Få innsikt»-knappen øverst til høyre på siden.\n\nMerk at du trenger fullstendige tilgangsrettigheter til Partner Portal for å vise denne informasjonen.\n"
					},
					"overviewDisputesPerformance": {
						"question": "Hvor kan jeg se en oversikt over tvisteytelsen min?",
						"answer": "For å se detaljert statistikk om tvister, gå til «Statistikk»-siden in Tvister-appen. Denne delen gir en oversikt over månedlige tvister, utfall, kostnader og tap relatert til tvister.\n\nMerk at du trenger fullstendige tilgangsrettigheter til Partner Portal for å vise denne informasjonen.\n"
					}
				},
				"disputesLifecycle": {
					"title": "Livssyklus for tvister",
					"whenCanSeeDisputes": {
						"question": "Når er jeg i stand til å se tvister som blir fremmet av kunder?",
						"answer": "Du er i stand til å se alle tvister fremmet i Tvister-appen på «Alle tvister»-siden umiddelbart etter at de har blitt fremmet."
					},
					"howLongToResolve": {
						"question": "Hvor lang tid har jeg til å løse tvisten direkte med kunden?",
						"answer": "Du har 21 dager fra dagen en tvist blir fremmet til å løse den med kunden. Hvis du og kunden ikke har løst tvisten innen 21 dager etter at den ble fremmet, vil Klarna tre inn for å støtte løsningsprosessen mellom begge parter."
					},
					"howMuchTimeToRefund": {
						"question": "Hvor lang tid har jeg til å tilbakebetale returer?",
						"answer": "Du har 21 dager fra returdatoen til å behandle en tilbakebetaling før Klarna vil tre inn for å støtte løsningsprosessen mellom begge parter. Med det sagt, er det alltid best å tilbakebetale returer så snart du mottar dem."
					},
					"howToResolve": {
						"question": "Hvordan kan jeg løse tvister og returer?",
						"answer": "Det er flere måter å løse en tvist eller retur på.\n\n- Behandle returen eller tilbakebetalingen så snart du mottar den / kommer til enighet om en løsning.\n- Sett opp en Tilbakebetalingsterskel under «E-post og tvist-innstillinger»\n- Vær proaktiv ved å sjekke «Alle tvister»\n- Aksepter tap\n"
					},
					"whenToRespond": {
						"question": "Når må jeg aktivt forsvare / svare på tvister?",
						"answer": "Hvis du og kunden ikke kan nå en løsning alene innen den gitte tiden (21 dager) vil Klarna tre inn for å støtte løsningsprosessen mellom begge parter. Hvis tvisten er gyldig vil Klarnas supportteam ta kontakt med deg via Tvister-appen med en forespørsel om mer informasjon.\n\nDet er på dette tidspunktet du vil bli bedt om å forsvare / svare på en tvist.\n"
					},
					"howMuchTimeToRespond": {
						"question": "Hvor lang tid har jeg for å svare på en tvisteforespørsel?",
						"answer": "Tidsfristen for den første forespørselen er 14 dager for alle tvisteårsaker, med unntak av Uautoriserte kjøp (7 dager). For hver oppfølgingsforespørsel* sendt til deg (med maksimalt 5 forespørsler) har du en tidsfrist på 7 dager. Skulle du trenge å legge noe til svaret ditt, har du 60 minutter fra innsending til å legge til for eksempel informasjon eller vedlegg. Etter at tidsfristen har utløpt vil du ikke lenger kunne forsvare saken.\n\n*Spesielle regler kan gjelde avhengig av partnerskapsavtale.\n"
					},
					"howLongToSeeDispute": {
						"question": "Hvor lenge kan jeg se en tvist i Tvister-appen etter at den er løst?",
						"answer": "En tvist er tilgjengelige på «Alle tvister»-siden i opptil 180 dager etter at den er avsluttet."
					},
					"whenToGetCharged": {
						"question": "Når blir jeg belastet et tvistegebyr?",
						"answer": "Du kan pådra deg et tvistegebyr hvis:\n- Du har skyld i tvisten.\n- Du unnlater å svare før tidsfristen.\n- Du utsteder en tilbakebetaling etter at Klarna griper inn for å støtte i løsningsprosessen.\n\nGebyret pålegges retroaktivt etter at tvisteutfallet er avgjort. Du blir ikke belastet hvis tvisten løses i din favør, eller hvis den blir kansellert eller avvist.\n"
					}
				},
				"disputesHandling": {
					"title": "Tvistehåndtering",
					"whatIsDispute": {
						"question": "Hva slags informasjon mottar jeg med en tvisteforespørsel?",
						"answer": "Tvisteforespørselen inkluderer:\n\n- Årsak: Kategori av tvist, f.eks. returer, varer ikke mottatt.\n- Forhandler: Relevant hvis flere butikk-ID-er (MID-er) er registrert hos Klarna.\n- Tvistedato: Når kunden fremmet tvisten.\n- Tidsfrist: Tidsfristen du må svare innen for å forsvare saken.\n- Omstridte produkter: Vare(r) som blir omstridt.\n- Ordredetaljer: Inkluderer «forhandlerreferanse 1 og 2», «ordredato», «omstridt beløp».\n"
					},
					"howManyTimesDefend": {
						"question": "Hvor mange ganger kan jeg forsvare én tvisteforespørsel?",
						"answer": "Du får maksimalt fem (5) forespørsler for å forsvare tvisten din, men oftest trenger det ikke mer enn én eller to."
					},
					"doIReceiveNotification": {
						"question": "Mottar jeg varslinger når jeg må svare på tvister?",
						"answer": "Hvis du har en avventende tvisteforespørsel, dukker den opp på flere steder:\n\n- I «Gjøremål»-delen på Partner Portal-hjemmesiden din.\n- Som en varsling i Tviste-delen på «Åpne tvister»-siden, med indikasjon om antall tvister som trenger et svar.\n\nDu kan også velge å motta en e-post for hver tvist ved å justere innstillingene dine i «E-post og tvist-instillinger» til «E-postvarsling for hver tvist».\n"
					},
					"howToAvoidDisputesFees": {
						"question": "Hvordan kan jeg unngå tvistegebyrer?",
						"answer": "For å unngå tvistegebyrer:\n- Behandle returer eller tilbakebetalinger raskt.\n- Sett opp en Tilbakebetalingsterskel under «E-post og tvist-innstillinger».\n- Sjekk «Alle tvister» regelmessig.\n- Svar på tvister med relevant informasjon.\n- Svar før tidsfristen utløper.\n"
					}
				}
			},
			"settlements": {
				"payoutNotFound": {
					"title": "Utbetaling for spesifikk ordre ikke funnet",
					"orderNumberNotFound": {
						"question": "Ordrenummer og referansenummer vises ikke i rapporten",
						"answer": "Hvis du ikke kan finne en betaling for en spesifikk ordre:\n- Verifiser nøyaktigheten av ordre- eller referansenummeret.\n- Sikre at ordren er fanget opp.\n- Kontroller om ordren faller inn under rapportens oppfangingsperiode.\n- Merk at ordrer som er kansellert før oppgjør ikke vil vises i rapporten.\n"
					},
					"checkIfPaidForOrder": {
						"question": "Hvordan ser jeg om jeg har blitt betalt for en spesifikk ordre?",
						"answer": "I Partner Portals ordreadministrator, søk etter ordren og sikre at ordre- eller referansenummeret er nøyaktig. Under referansenummeret, kontroller om ordren er fanget opp. Hvis den er fanget opp, vil status vises som avventer eller utbetalt. For utbetalte ordrer, klikk på «Se oppgjør» for å vise den korresponderende rapporten."
					}
				},
				"payouts": {
					"title": "Utbetalinger",
					"whenIsNext": {
						"question": "Når er min neste utbetaling?",
						"answer": null
					},
					"whyCalculationNotMatch": {
						"question": "Hvorfor samsvarer ikke min egen beregning med utbetalingene mottatt?",
						"answer": "Under forhandlerkontrakten trekkes gebyrer som transaksjons- og sen retur-gebyrer fra. Derfor kan beregningene dine være annerledes enn Klarnas oppgjør. For å se klart beskrevede fratrekk, anbefaler vi å åpne PDF-versjonen av oppgjørsrapporten."
					},
					"whenGetPaid": {
						"question": "Når vil jeg bli betalt?",
						"answer": null
					},
					"missingPayout": {
						"question": "Jeg mangler utbetalingen min",
						"answer": "Før du tar kontakt med supportkanalen vår. Ta i betraktning at manglende utbetalinger kan være på grunn av følgende:\n\n- Klarna sender betalingen i løpet av den samme dagen som rapporten blir generert, det kan fortsatt ta noen dager før pengene dukker opp på bankkontoen din.\n- Du har ikke fanget opp noen ordrer for denne oppfangingsperioden.\n- Du har nylig oppdatert bankkontoopplysningene dine hos Klarna, noe som kan ha forårsaket at betalingen har gått til den tidligere bankkontoen din. Dette kan skje hvis endringen ikke er behandlet før betalingen utføres.\n- Ordrenummeret eller referansenummeret du søker etter er feil, sørg for å gå gjennom ordre- eller referansenummeret.\n- Betalingene dine blir holdt av Klarna. Du bør ha blitt informert om holdet separat gjennom en e-post. Disse e-postene sendes vanligvis til en administrator.\n"
					},
					"howIsPayoutCalculated": {
						"question": "Hvordan beregnes utbetalingen min?",
						"answer": "Utbetalingen beregnes fra ordrer oppfanget innen oppfangingsperioden, minus gebyrer, returer og andre belastninger. En oppgjørsrapport gir en detaljert oversikt over disse beløpene.\nHvis tilbakebetalinger overstiger salg i oppfangingsperioden, vil det overstigende beløpet trekkes fra den neste utbetalingen.\n"
					}
				},
				"howPayoutsWork": {
					"question": "Hvordan fungerer utbetalingene?",
					"answer": "Oppgjør-delen gir en oversikt over hvordan utbetalinger fungerer, inkludert betalingstidsplaner, oppfangingsperioder og utbetalingsdatoer. Du kan laste ned transaksjonsrapporter for hver utbetaling i PDF- eller CSV-formater, og vise eller laste ned detaljer for spesifikke betalinger ved å klikke på betalingsreferansene deres."
				},
				"payoutSchedule": {
					"title": "Utbetalingsplan",
					"whyDelay": {
						"question": "Hvorfor er det en utbetalingsforsinkelse?",
						"answer": "Hovedformålet er å forhindre at penger til fakturaer sendes frem og tilbake mellom Klarna og deg, og også å dekke saker der du ville tilbakebetale en oppfanget ordre."
					},
					"changePayoutDelay": {
						"question": "Jeg vil endre utbetalingsforsinkelsen min",
						"answer": "Vi har interne kontroller samt generelle kriterier som må oppfylles. Disse generelle kriteriene inkluderer:\n- Du må være fullstendig onboardet hos Klarna\n- Den første ordredatoen din overstiger 100 dager\n- Virksomheten din må være eldre enn 6 måneder.\n\nKlarna trenger også informasjon fra en administrator om …\n- Den ønskede nye betalingsforsinkelsen og hvorfor den endrede betalingsforsinkelsen er nødvendig\n- Hvilken butikk-ID (MID) endringen gjelder\nYtterligere kriterier kan gjelde, med avgjørelser gjort på individuelt grunnlag.\n"
					}
				},
				"generatingReports": {
					"title": "Generere rapporter",
					"howToSeeReports": {
						"question": "Hvordan ser jeg rapportene for spesifikke perioder?",
						"answer": "Du kan tilpasse rapportene dine for å imøtekomme spesifikke tidsperioder ved å bruke Tilpassede rapporter-delen i Oppgjør-delen."
					},
					"convertCsvToExcel2": {
						"answer": "Følg disse trinnene\n1. Åpne Excel, klikk på «Fil» > «Ny arbeidsbok».\n"
					},
					"convertCsvToExcel": {
						"question": "Hvordan konverterer jeg en CSV-rapport til et Excel regneark?",
						"answer": "1. Åpne Excel, klikk på «Fil» > «Ny arbeidsbok».\n2. Klikk på «Data»-fanen og velg «Fra tekst».\n3. Gå til den ønskede .CSV-filen og klikk på «Få data».\n4. I veiviseren for tekstimport, velg «Avgrenset», og klikk deretter på «Neste».\n5. Under «Avgrensere», velg «Semikolon» og angi «Tekstkvalifiserer» til «ingen». Klikk på «Avslutt».\n6. I «Importer data»-dialogboksen, klikk på «OK», for å plassere dataene med start i celle A1.\n\nMerk: CSV-rapporter i Forhandlerportalen bruker et semikolon (;) som separatoren. Vurder å sjekke regnearkprogramvaren din for å finne ut hvordan du justerer standardseparatoren.\n"
					}
				},
				"payoutAndFeeCalculation": {
					"title": "Beregning av utbetaling og gebyr",
					"howAreFeesCalculated": {
						"question": "Hvordan beregnes gebyrer?",
						"answer": "Gebyrer trukket fra Klarna-utbetalinger er beskrevet i forhandlerkontrakten din, med ytterligere gebyrer så som de for sene returer detaljert i «Gebyr»-delen i utbetalings-/oppgjørsrapportene dine.\n\nFor å tilpasse gebyrbeskrivelser i rapportene dine:\n1. Naviger til Oppgjørsrapporter > Konfigurer rapporter > Legg til ny konfigurasjon.\n2. For CSV-rapporter, velg «Detaljert transaksjonstype» under Transaksjonsliste.\n3. For PDF-rapporter, velg «Inkluder ordredetaljer» i Oppsett og innholdsinnstillingene.\n\nSikre at du justerer innstillinger for hver butikk-ID og kanal (SFTP, Forhandlerportal, API) separat.\n"
					},
					"howIsPayoutCalculated": {
						"question": "Hvordan beregnes utbetalingen min?",
						"answer": "Utbetalingen beregnes fra ordrer oppfanget innen oppfangingsperioden, minus gebyrer, returer og andre belastninger. En oppgjørsrapport gir en detaljert oversikt over disse beløpene.\n\nHvis tilbakebetalinger overstiger salg i oppfangingsperioden, vil det overstigende beløpet trekkes fra den neste utbetalingen.\n"
					}
				}
			},
			"options": {
				"payout": "Utbetaling",
				"orders": "Ordre",
				"settlements": "Oppgjør",
				"disputes": "Tvister",
				"otherIssue": "Andre utfordringer",
				"yes": "Ja",
				"no": "Nei",
				"partially": "Delvis",
				"iNeedMoreHelp": "Jeg trenger mer hjelp",
				"thatHelped": "Det hjalp",
				"contactSupportChannel": "Kontakt supportkanaler"
			},
			"button": {
				"label": "Få hjelp"
			},
			"contact": {
				"chat": "Chat med agent",
				"ticket": "Send inn billett",
				"times": {
					"minutes": "Ventetid: {time} minutt(er)",
					"hours": "Ventetid: {time} time(r)",
					"days": "Ventetid: {time} dag(er)"
				}
			}
		}
	}
};