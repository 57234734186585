module.exports = {
	"no": {
		"credentials": {
			"addOriginModal": {
				"buttonPrimaryText": "Registrer",
				"buttonSecondaryText": "Avbryt",
				"description": "Vennligst registrer ethvert opprinnelsesdomene du forventer å bruke Web SDK på. Hvis du skulle ha behov for å registrere mer enn én vil du kunne gjøre det senere.",
				"errorMessage": "En feil oppstod. Prøv igjen senere eller kontakt kundestøtte dersom problemet vedvarer.",
				"inputLabel": "Skriv inn resten av URL-en din",
				"title": "Registrer tillatt opprinnelse"
			},
			"clientKeyBlock": {
				"mainText": "Kundeidentifikatoren din settes opp direkte i koden med opprinnelsesdomenet du oppga.",
				"secondaryText": "For å administrere det videre, følg lenken til",
				"linkDescription": "kundeidentifikatorsiden",
				"linkLabel": "Lukk"
			},
			"clientKeyField": {
				"label": "Kundeidentifikator",
				"defaultErrorMessage": "Kundeidentifikator mangler"
			},
			"copyToClipboardButton": {
				"label": "Kopi",
				"labelOnCopy": "Kopiert"
			},
			"originField": {
				"label": "Opprinnelsesdomene",
				"tooltipMainText": "For å registrere flere opprinnelsesdomener eller administrere eksisterende, gå til",
				"tooltipLinkLabel": "kundeidentifikatorsiden"
			},
			"originInputField": {
				"label": "Skriv inn resten av URL-en din",
				"validationError": "Skriv inn en gyldig domene-URL",
				"invalidProtocolError": "URL må starte med http:// eller https://"
			},
			"addApiKeySuccessModal": {
				"title": "",
				"description": "Det er lurt å lagre disse legitimasjonene på enheten din, eller et annet sikkert sted.",
				"warning": "Passordet vises kun denne ene gangen, og det forsvinner når du lukker dette vinduet.",
				"checkbox": "Jeg har lagret legitimasjonen på enheten min.",
				"download": "Last ned som .txt"
			},
			"common": {
				"password": "Passord",
				"username": "Brukernavn",
				"close": "Lukk"
			}
		}
	}
};