module.exports = {
	"cs": {
		"home-fe": {
			"boosters-home-v03": {
				"install-plugin": {
					"stepNumber": "1",
					"title": "Instalovat plugin",
					"description": "Vyberte platformu e-obchodu, abychom mohli vytvořit odkaz a urychlit instalaci.",
					"selectorLabel": "Vybrat platformu",
					"getPluginButtonLabel": "Získat plugin",
					"klarnaDocsLinkLabel": "Více informací najdete v dokumentaci Klarna."
				},
				"start-setup": {
					"stepNumber": "2",
					"title": "Povolit plugin",
					"description": "Vygenerujte a zkopírujte identifikátor klienta a klíč API, které budete potřebovat k povolení pluginu."
				},
				"promotion-banner": {
					"title": "Klarna pro platformy",
					"subtitle": "Rozšiřte své podnikání pomocí jediného pluginu, který vám umožní přístup k mnoha funkcím pro zvýšení prodeje a vylepšení nákupních zkušeností bez dalších nákladů.",
					"banner-cards": {
						"osm-title": "Zvyšte průměrnou hodnotu objednávky pomocí <span>místních zpráv</span>",
						"kec-title": "Generujte vyšší konverzi díky službě Express Checkout",
						"siwk-title": "Přilákejte nové zákazníky pomocí <span>přihlašování s Klarnou</span>"
					}
				},
				"configure-boost-features": {
					"stepNumber": "3",
					"title": "Umisťování funkcí na míru",
					"description": "Toto je volitelný krok, kterým můžete změnit vzhled funkcí ve svém obchodě. Pokud to chcete provést, měli byste mít dokončený krok 2.",
					"no-credentials": "Abyste mohli pokračovat v tomto kroku, nezapomeňte nejprve vygenerovat identifikátor klienta a klíč API.",
					"apps": {
						"osm": {
							"link-label": "Přizpůsobení zasílání zpráv na webu",
							"description": "Přizpůsobte zasílání zpráv na webu své značce úpravou tématu a výběrem umístění."
						},
						"kec": {
							"link-label": "Přizpůsobit expresní pokladnu na míru",
							"description": "Přizpůsobte tlačítko expresní pokladny své značce úpravou motivu tlačítka, tvaru a výběrem umístění."
						},
						"siwk": {
							"link-label": "Nastavit přihlašování pomocí Klarny",
							"description": "Vyberte rozsah přihlašování pomocí Klarny a přidejte adresu URL přesměrování. Rozsah je informace získaná od vašeho zákazníka a adresa URL pro přesměrování se použije v případě, že zákazníci zablokují vyskakovací okna."
						}
					}
				},
				"client-identifier-section": {
					"title": "Identifikátor klienta",
					"description": "Jedinečný identifikátor pro nastavení",
					"manage-link": "Spravovat identifikátor klienta a původ",
					"generate-button": "Vygenerovat",
					"tooltip": "Vygenerujte a zkopírujte identifikátor klienta. Ty potřebujete ke spuštění nastavení pluginu na portálu vaší platformy."
				},
				"api-key": {
					"warning-banner": {
						"content": "Už máte aktivní klíče API. Před vygenerováním nových deaktivujte všechny, které nepoužíváte."
					},
					"title": "Klíč Klarna API",
					"description": "Klíč API pro nastavení",
					"tooltip": "Vezměte prosím na vědomí, že váš tajný klíč API po vygenerování již nezobrazujeme.",
					"generate-button": "Vygenerovat",
					"manageApiKey": "Spravovat klíč API"
				},
				"non-platform-integration": {
					"question": "Nepoužíváte Klarnu pro platformy?",
					"link": "Podívejte se, jak se přímo integrovat s Klarnou"
				}
			},
			"boosters-home": {
				"welcome-banner": {
					"title": "Odemkněte plný výkon Klarny",
					"text": "Funkce navržené tak, aby se z návštěvníků stali nakupující. Vylepšete každý krok při nakupování – od prohlížení až po odbavení."
				},
				"plugin-carousel": {
					"title": "Žádné nastavení kódu pomocí pluginu",
					"overviewLink": "Zobrazit všechny platformy",
					"directIntegrationLabel": "Nepoužíváte Klarnu pro platformy?",
					"directIntegrationLink": "Podívejte se, jak se přímo integrovat s Klarnou"
				},
				"booster-apps": {
					"title": "Další informace",
					"kec-fe": {
						"title": "Pětkrát rychlejší odbavení",
						"description": "Méně kliknutí znamená menší úbytek zákazníků. Zvyšte konverzi a minimalizujte počet opuštění košíku pomocí expresního odbavení na stránkách produktu a košíku.",
						"callToAction": "Více informací o funkci Express Checkout"
					},
					"siwk-fe": {
						"title": "Registrace v sociální síti pro nakupování",
						"description": "Nakupující se mohou rychle a bezpečně přihlásit jedním kliknutím. Získáte vylepšené a využitelné údaje o profilu nakupujícího.",
						"callToAction": "Více informací o přihlášení pomocí Klarny"
					},
					"upstream-fe": {
						"title": "Proměňte návštěvníky v nakupující",
						"description": "Maximalizujte konverzi pomocí dynamických propagačních sdělení v průběhu nákupní cesty.",
						"callToAction": "Další informace o zasílání zpráv na webu"
					},
					"branding-fe": {
						"title": "Zlepšete viditelnost svého obchodu",
						"description": "V aplikaci Klarna můžete spravovat svou značku nahráním loga, ikony a adresy URL obchodu.",
						"callToAction": "Nastavit manažera značky"
					}
				}
			},
			"boosters-plugins": {
				"title": "Nastavení pluginu",
				"description": "Začněte používat naše pluginy a ujistěte se, že jste připraveni na ostrý provoz."
			},
			"boosters-plugin": {
				"title": "Klarna pro {pluginName}",
				"description": "Proveďte níže uvedené akce a povolte platby Klarna a/nebo funkce boost pomocí našeho nastavení bez kódu.",
				"access-warning": {
					"both": "Nemáte oprávnění pro {selectedStoreName} ({selectedMid}) ke generování klíčů API a identifikátoru klienta. Kontaktujte prosím svého uživatele typu správce a požádejte ho o přístup k nastavení plateb, abyste mohli pokračovat.",
					"client": "Nemáte oprávnění pro {selectedStoreName} ({selectedMid}) generovat identifikátor klienta. Kontaktujte prosím svého administrátorského uživatele a požádejte ho o přístup k nastavení plateb, abyste mohli pokračovat.",
					"api": "Nemáte oprávnění pro {selectedStoreName} ({selectedMid}) ke generování klíčů API. Kontaktujte prosím svého uživatele typu správce a požádejte ho o přístup k nastavení plateb, abyste mohli pokračovat."
				},
				"back": "Zpět",
				"instructions": {
					"api-key": {
						"title": "Povolit platbu Klarna",
						"content": "Je třeba vygenerovat a zkopírovat klíče API, abyste je mohli použít během nastavení.",
						"link-label": "Více informací najdete v dokumentaci Klarna.",
						"generate": "Generovat klíče API",
						"active-warning": {
							"content": "Už máte aktivní klíče API. Před vygenerováním nových deaktivujte všechny, které nepoužíváte.",
							"link": "Spravovat klíče API"
						},
						"active-warning-api-keys": {
							"content": "Už máte aktivní klíče API. Před vygenerováním nových deaktivujte všechny, které nepoužíváte.",
							"link": "Spravovat klíče API"
						}
					},
					"client-id": {
						"title": "Povolit funkce boost",
						"content": "Pokud chcete přidat funkce Klarna express checkout a On-site messaging, musíte si vygenerovat a zkopírovat identifikátor klienta, abyste je mohli použít během nastavení.",
						"generate": "Generovat identifikátor klienta",
						"manage": "Spravovat identifikátor klienta"
					},
					"plugin-doc": {
						"title": "Konfigurovat funkce boost",
						"content": "Pro dokončení nastavení je třeba v nastavení platformy nakonfigurovat funkce Klarna express checkout a On-site messaging."
					}
				},
				"error-messages": {
					"default": "Došlo k chybě. Zkuste to prosím znovu nebo se obraťte na naši podporu.",
					"fetch-data": "Při načítání dat došlo k chybě. Zkuste to prosím znovu nebo se obraťte na naši podporu.",
					"create-api-key": "Při vytváření klíčů API došlo k chybě. Zkuste to prosím znovu nebo se obraťte na naši podporu.",
					"create-client-id": "Při vytváření identifikátoru klienta došlo k chybě. Zkuste to prosím později nebo přejděte do nápovědy."
				}
			},
			"marketing-home": {
				"description": "Vítejte u našich marketingových produktů. Začněte s prozkoumáváním níže.",
				"essentials": {
					"title": "Marketing",
					"cta-label": "Další informace",
					"branding-fe": {
						"description": "Nastavení a správa značky. Ukažte se svým zákazníkům v tom nejlepším světle v rámci ekosystému Klarna, který zahrnuje naši smoooth aplikaci."
					},
					"klarna-search-b2b-portal": {
						"description": "Začněte s výkonným vyhledávačem a srovnáváním nákupů Klarna. Získejte přístup ke statistikám a nástrojům určeným ke zvýšení vaší viditelnosti."
					}
				},
				"external-apps": {
					"title": "Aplikace",
					"cta-label": "Otevřít aplikaci"
				},
				"popOvers": {
					"searchCompare": {
						"title": "Začínáme s funkcí Vyhledávání a porovnání",
						"content": "Podívejte se sem na statistiky a nástroje, které vám pomohou zvýšit viditelnost ve srovnávací nákupní službě společnosti Klarna."
					}
				}
			},
			"no-apps": {
				"title": "Žádné dostupné aplikace!",
				"subtitle": "Kontaktujte správce, aby vám pomohl získat přístup k aplikacím, které potřebujete."
			},
			"contact": {
				"title": "Potřebujete s něčím pomoci?",
				"paragraph": "Pokud máte nějaké otázky, neváhejte nás kontaktovat a my vám rádi odpovíme.",
				"label": "Kontaktujte nás"
			},
			"important-banner": {
				"title": "",
				"content": "",
				"linkLabel": ""
			},
			"two-factor-home-banner": {
				"banner-text": "Zabezpečte svůj účet ještě lépe pomocí dvoufázového ověření.",
				"banner-link": "Nastavte si to"
			},
			"two-factor-opt-in": {
				"title": "Nastavení dvoufázového ověření",
				"intro-paragraph": "Doporučujeme vám nastavení dvoufázového ověření, které zvýší zabezpečení vašeho účtu.",
				"help-link-text": "Klikněte sem",
				"help-text": " a zjistěte více o nastavení tohoto ověření a o ochraně, kterou vám poskytuje.",
				"warning-text": "Než budete pokračovat, zkontrolujte, že máte chytrý telefon a přístup k internetu.",
				"continue-button": "Zahájit nastavení"
			},
			"accept-invite-modal": {
				"title": "Přijmout čekající pozvánky",
				"description": "Byli jste pozváni k přístupu do následujících obchodů. Upozorňujeme, že může trvat několik minut, než se vaše volby projeví.",
				"accept-all-button": "Přijmout vše",
				"decline-all-button": "Odmítnout vše",
				"save-button": "Uložit",
				"error": "Vyskytl se problém. Zkuste to prosím později!",
				"invite": {
					"accept-label": "Přijmout",
					"decline-label": "Odmítnout"
				}
			},
			"capture-orientation": {
				"title": "Jste novým uživatelem Klarny?",
				"sub-title": "Základy se naučíte následovně:",
				"order-label": "Přijmout objednávku",
				"capture-label": "Zaznamenat objednávku",
				"payout-label": "Dostat zaplaceno",
				"capture-cta": "Zaznamenat tuto objednávku",
				"payout-cta": "Vypořádání aplikace",
				"dismiss-cta": "Odmítnout",
				"order-step": "Nejprve musíme počkat na příchozí objednávku od některého z vašich zákazníků.",
				"capture-step": "Gratulujeme vám k první objednávce.\nAbyste dostali zaplaceno, musíte objednávku odeslat a zaznamenat.",
				"payout-step": "Gratulujeme vám k zaznamenání první objednávky.\nNyní od nás obdržíte vyplacení podle časového harmonogramu uvedeného ve vaší smlouvě s Klarnou.\nPodrobné informace o vašem prvním vyplacení najdete na",
				"completed-step": "Gratulujeme vám k prvnímu vyplacení.\nVítáme vás na úžasné cestě s Klarnou.\nNeváhejte vyzkoušet další aplikace, které nabízíme níže."
			},
			"banner": {
				"common": {
					"branding-fe": {
						"content": "Právě jsme spustili naši novou aplikaci Branding!",
						"linkLabel": "Vezměte svou značku do vlastních rukou!"
					},
					"onSiteMessaging": {
						"title": "Proměňte návštěvníka v nakupujícího pomocí zpráv na webu",
						"text": "Nakupování začíná ještě předtím, než se zákazníci dostanou do košíku. Pomocí zpráv na webu zvýšíte povědomí o flexibilních možnostech platby a dalších výhodách společnosti Klarna již v rané fázi prodejního trychtýře.",
						"cta": "Zjistěte to"
					}
				}
			},
			"nko": {
				"dialog": {
					"title": "Pro vaše podnikání máme připraveny nové vzrušující funkce.",
					"subtitle": "A rádi bychom vás lépe poznali.",
					"explore": "Ano, pojďme se na to podívat!",
					"name-field": "Takže, jak vás máme oslovovat?",
					"terms-pre": "Přečetl/a jsem si a souhlasím s ",
					"terms-text": "Smluvní podmínky"
				}
			},
			"insightsWidget": {
				"title": "Dnešní prodeje",
				"orderCount": "Vytvořené objednávky",
				"orderVolume": "Objem prodeje",
				"orderVolumeAverage": "Průměrná hodnota objednávky",
				"currencyFootnote": "Zobrazeno v {currency}",
				"link": "Viz objednávky",
				"allStores": "Všechny obchody",
				"selectAllOptionText": "Vybrat vše",
				"deselectAllOptionText": "Vymazat výběr",
				"closeText": "Hotovo",
				"closeTextWithSelection": "Použít následující počet výběrů: #",
				"elapsedTime": "Poslední objednávka byla přijata před {hours, plural, =0 {} =1 {jednou hodinou} other {# hodinami}} {minutes, plural, =0 {} =1 {jednou minutou} other {# minutami}}",
				"error": {
					"content": "Statistiky vašeho prodeje se bohužel nenačetly správně."
				},
				"relativeDate": {
					"today": "Dnes",
					"yesterday": "Včera"
				},
				"weekDays": {
					"1": "Pondělí",
					"2": "Úterý",
					"3": "Středa",
					"4": "Čtvrtek",
					"5": "Pátek",
					"6": "Sobota",
					"7": "Neděle"
				}
			},
			"blackFridayWidget": {
				"title": "Týden Black Friday je tady.",
				"text": "Sledujte statistiky prodeje v reálném čase.",
				"button": "Sledovat statistiky"
			},
			"tasks": {
				"title": "Seznam úkolů",
				"error": {
					"title": "Seznam úkolů se nám nepodařilo načíst.",
					"desc": "Obnovte stránku, abychom to mohli zkusit znovu."
				},
				"empty": {
					"title": "Vše je v pořádku!",
					"desc": "Teď nemáte žádné nedokončené úkoly."
				},
				"single": {
					"products-fe:complete-recollection": "Povinný AML formulář sloužící k prevenci praní špinavých peněz, který musí vaše firma vyplnit.",
					"products-fe:resume-onboarding": "Dokončit zapracování",
					"settings-fe:compile-cs-info": "Přidejte informace o zákaznickém servisu"
				},
				"aggregated": {
					"disputes-fe:unresolved": "{ count, plural, =1 {1 nevyřešený spor} other {# nevyřešených sporů}}",
					"orders-fe:expiring": "{ count, plural, =1 {1 téměř prošlá objednávka} other {# téměř prošlých objednávek}}",
					"orders-fe:tocapture": "{ count, plural, =1 {1 objednávka k zaznamenání} other {# objednávek k zaznamenání}}",
					"orders-fe:uncaptured": "{ count, plural, =1 {1 nezaznamenaná objednávka} other {# nezaznamenaných objednávek}}",
					"users-fe:mid-added": "{ count, plural, =1 {1 nový obchod byl přidán } other {# nových obchodů bylo přidáno}}",
					"users-fe:mid-invited": "{ count, plural, =1 {1 pozvánka do obchodu, která čeká na vyřízení} other {# pozvánky/pozvánek do obchodu, které čekají na vyřízení}}"
				}
			}
		}
	}
};