module.exports = {
	"cs": {
		"shared": {
			"months": {
				"january": "Leden",
				"february": "Únor",
				"march": "Březen",
				"april": "Duben",
				"may": "Květen",
				"june": "Červen",
				"july": "Červenec",
				"august": "Srpen",
				"september": "Září",
				"october": "Říjen",
				"november": "Listopad",
				"december": "Prosinec"
			},
			"weekDaysShort": {
				"monday": "M",
				"tuesday": "Út",
				"wednesday": "St",
				"thursday": "Čt",
				"friday": "Pá",
				"saturday": "So",
				"sunday": "Ne"
			}
		}
	}
};