module.exports = {
	"it": {
		"credentials": {
			"addOriginModal": {
				"buttonPrimaryText": "Registra",
				"buttonSecondaryText": "Cancella",
				"description": "Registra tutti i domini originariamente previsti per l’utilizzo del Web SDK. Se devi registrarne più di uno, potrai farlo in seguito.",
				"errorMessage": "Si è verificato un errore. Riprova più tardi o contatta l’assistenza qualora il problema persista.",
				"inputLabel": "Inserisci il resto del tuo URL",
				"title": "Registra origine autorizzata"
			},
			"clientKeyBlock": {
				"mainText": "L’identificativo del tuo cliente viene configurato direttamente nel codice con il dominio di origine che hai fornito.",
				"secondaryText": "Per gestirlo ulteriormente, segui il link alla",
				"linkDescription": "pagina degli identificativi client",
				"linkLabel": "Chiudi"
			},
			"clientKeyField": {
				"label": "Identificatore client",
				"defaultErrorMessage": "L’identificatore client è mancante"
			},
			"copyToClipboardButton": {
				"label": "Copia",
				"labelOnCopy": "Copiata"
			},
			"originField": {
				"label": "Dominio di origine",
				"tooltipMainText": "Per registrare uno o più domini o gestire quelli già esistenti vai alla",
				"tooltipLinkLabel": "pagina degli identificativi client"
			},
			"originInputField": {
				"label": "Inserisci il resto del tuo URL",
				"validationError": "Inserisci un URL valido del dominio",
				"invalidProtocolError": "L’URL deve iniziare con http:// o https://"
			},
			"addApiKeySuccessModal": {
				"title": "",
				"description": "Salva queste credenziali sul tuo dispositivo o in un altro posto sicuro.",
				"warning": "La password verrà mostrata unicamente questa volta e scomparirà dopo che hai chiuso questa finestra.",
				"checkbox": "Ho salvato le credenziali sul mio dispositivo.",
				"download": "Scarica come .txt"
			},
			"common": {
				"password": "Password",
				"username": "Nome utente",
				"close": "Chiudi"
			}
		}
	}
};