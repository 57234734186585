module.exports = {
	"fr": {
		"home-fe": {
			"boosters-home-v03": {
				"install-plugin": {
					"stepNumber": "1",
					"title": "Installer le plugin",
					"description": "Sélectionnez votre plateforme de commerce électronique afin que nous puissions créer un lien afin d'accélérer votre installation",
					"selectorLabel": "Sélectionner la plateforme",
					"getPluginButtonLabel": "Obtenir le plugin",
					"klarnaDocsLinkLabel": "En savoir plus sur les documents Klarna"
				},
				"start-setup": {
					"stepNumber": "2",
					"title": "Activer le plugin",
					"description": "Générez et copiez l'identifiant du client et la clé d'API, car vous en aurez besoin pour activer le plugin."
				},
				"promotion-banner": {
					"title": "Klarna pour plateformes",
					"subtitle": "Développez vos activités avec un seul plugin vous donnant accès à de nombreuses fonctionnalités pour augmenter vos ventes et améliorer l'expérience d'achat sans frais supplémentaires.",
					"banner-cards": {
						"osm-title": "Augmentez le panier moyen avec <span>les messages intégrés</span>",
						"kec-title": "Augmentez le taux de conversion avec le Paiement express",
						"siwk-title": "Attirez de nouveaux clients avec <span>Se connecter avec Klarna</span>"
					}
				},
				"configure-boost-features": {
					"stepNumber": "3",
					"title": "Placements de fonctionnalités personnalisés",
					"description": "Il s'agit d'une étape facultative pour modifier l'apparence des fonctionnalités dans votre boutique. Pour ce faire, vous devriez déjà avoir complété l'étape 2.",
					"no-credentials": "Assurez-vous de générer d'abord l'identifiant du client et la clé d'API afin de continuer cette étape.",
					"apps": {
						"osm": {
							"link-label": "Messages intégrés personnalisés",
							"description": "Personnalisez vos messages intégrés pour qu'ils correspondent à votre marque, en ajustant le thème et les placements sélectionnés"
						},
						"kec": {
							"link-label": "Paiement express personnalisé",
							"description": "Personnalisez votre bouton de paiement express pour qu'il corresponde à votre marque, en ajustant le thème, la forme et les placements sélectionnés"
						},
						"siwk": {
							"link-label": "Mettre en place Se connecter avec Klarna",
							"description": "Sélectionnez le champ d'application de Se connecter avec Klarna et ajoutez une URL de redirection. Le champ d'application correspond aux informations rassemblées par votre client, et l'URL de redirection est utilisé si les fenêtres contextuelles sont bloquées par le client"
						}
					}
				},
				"client-identifier-section": {
					"title": "Identifiant du client",
					"description": "L'identifiant unique pour la mise en place",
					"manage-link": "Gérer l'identifiant et l'origine du client",
					"generate-button": "Générer",
					"tooltip": "Générez et copiez l'identifiant du client. Vous en avez besoin pour commencer l'installation du plugin sur le portail de votre plateforme."
				},
				"api-key": {
					"warning-banner": {
						"content": "Vous avez déjà des clés d'API actives. Avant d'en générer de nouveaux, assurez-vous d'avoir désactivé ceux que vous n'utilisez pas."
					},
					"title": "Clé d'API Klarna",
					"description": "Clé d'API pour la mise en place",
					"tooltip": "Veuillez noter que votre clé d'API secrète ne sera affichée qu'une seule fois lorsqu'elle est générée.",
					"generate-button": "Générer",
					"manageApiKey": "Gérer la clé d'API"
				},
				"non-platform-integration": {
					"question": "Vous n'utilisez pas Klarna pour plateformes ?",
					"link": "Découvrez comment intégrer Klarna directement"
				}
			},
			"boosters-home": {
				"welcome-banner": {
					"title": "Débloquez tout le pouvoir de Klarna",
					"text": "Des fonctionnalités conçues pour que les visiteurs deviennent des acheteurs puissants. Améliorez chaque étape de leur expérience de shopping, de la navigation au paiement."
				},
				"plugin-carousel": {
					"title": "Pas de configuration de code avec un plugin",
					"overviewLink": "Voir toutes les plateformes",
					"directIntegrationLabel": "Vous n'utilisez pas Klarna pour plateformes ?",
					"directIntegrationLink": "Découvrez comment intégrer Klarna directement"
				},
				"booster-apps": {
					"title": "En savoir plus",
					"kec-fe": {
						"title": "Rendez le paiement 5 fois plus rapide",
						"description": "Moins de clics signifient moins d'abandon. Augmentez le taux conversion et réduisez l'abandon de panier avec le paiement express sur les pages de produit et sur le panier.",
						"callToAction": "En savoir plus sur le paiement express"
					},
					"siwk-fe": {
						"title": "Connexion sociale pour faire des achats",
						"description": "Les acheteurs bénéficient d'une option de connexion en un clic rapide et sécurisée. Vous obtenez des données de profil d'acheteur améliorées et exploitables.",
						"callToAction": "En savoir plus sur la connexion avec Klarna"
					},
					"upstream-fe": {
						"title": "Transformez les visiteurs en acheteurs",
						"description": "Maximisez la conversion avec des messages promotionnels dynamiques tout au long du parcours d'achat.",
						"callToAction": "En savoir plus sur les messages intégrés"
					},
					"branding-fe": {
						"title": "Améliorez la visibilité de votre boutique",
						"description": "Gérez votre marque dans l'application Klarna en téléchargeant un logo de boutique, une icône et une URL.",
						"callToAction": "Configurer le Manager de marque"
					}
				}
			},
			"boosters-plugins": {
				"title": "Configuration du plugin",
				"description": "Installez nos plugins afin d'être prêt(e) à vous lancer."
			},
			"boosters-plugin": {
				"title": "Klarna pour {pluginName}",
				"description": "Suivez les étapes ci-dessous pour activer les paiements avec Klarna et/ou les fonctionnalités coup de boost grâce à notre configuration sans code.",
				"access-warning": {
					"both": "Vous n'avez pas l'autorisation de générer les clés d'API et l'identifiant client pour {selectedStoreName} ({selectedMid}). Veuillez contacter votre administrateur et lui demander l'accès aux paramètres de paiement pour continuer.",
					"client": "Vous n'avez pas l'autorisation de générer l'identifiant client pour {selectedStoreName} ({selectedMid}). Veuillez contacter votre administrateur et lui demander l'accès aux paramètres de paiement pour continuer.",
					"api": "Vous n'avez pas l'autorisation de générer les clés d'API pour {selectedStoreName} ({selectedMid}). Veuillez contacter votre administrateur et lui demander l'accès aux paramètres de paiement pour continuer."
				},
				"back": "Retour",
				"instructions": {
					"api-key": {
						"title": "Activer les paiements avec Klarna",
						"content": "Vous devez générer et copier les clés d'API afin de pouvoir les utiliser lors de la configuration.",
						"link-label": "Apprenez-en plus sur les documents Klarna.",
						"generate": "Générer les clés d'API",
						"active-warning": {
							"content": "Vous avez déjà des clés d'API actives. Avant d'en générer de nouveaux, assurez-vous d'avoir désactivé ceux que vous n'utilisez pas.",
							"link": "Gérer les clés d'API"
						},
						"active-warning-api-keys": {
							"content": "Vous avez déjà des clés d'API actives. Avant d'en générer de nouveaux, assurez-vous d'avoir désactivé ceux que vous n'utilisez pas.",
							"link": "Gérer les clés d'API"
						}
					},
					"client-id": {
						"title": "Activer les fonctionnalités coup de boost",
						"content": "Pour ajouter le paiement express et la messagerie intégrée de Klarna, vous devez générer et copier votre identifiant client afin de pouvoir l'utiliser lors de la configuration.",
						"generate": "Générer un identifiant client",
						"manage": "Gérer votre identifiant client"
					},
					"plugin-doc": {
						"title": "Configurer les fonctionnalités coup de boost",
						"content": "Pour finaliser l'installation, vous devez configurer le paiement express et la messagerie intégrée de Klarna dans les paramètres de votre plateforme."
					}
				},
				"error-messages": {
					"default": "Une erreur s'est produite. Veuillez réessayer plus tard ou consulter la rubrique d'aide.",
					"fetch-data": "Une erreur s'est produite lors de l'extraction de vos données. Veuillez réessayer plus tard ou consulter la rubrique d'aide.",
					"create-api-key": "Une erreur s'est produite lors de la création de vos clés d'API. Veuillez réessayer plus tard ou consulter la rubrique d'aide.",
					"create-client-id": "Une erreur s'est produite lors de la création de votre identifiant client. Veuillez réessayer plus tard ou consulter la rubrique d'aide."
				}
			},
			"marketing-home": {
				"description": "Bienvenue dans nos produits marketing. Commencez à explorer ci-dessous.",
				"essentials": {
					"title": "Marketing",
					"cta-label": "En savoir plus",
					"branding-fe": {
						"description": "Configurez et gérez votre marque pour permettre aux clients de vous voir sous le meilleur jour possible dans le système Klarna ainsi que notre application pratique."
					},
					"klarna-search-b2b-portal": {
						"description": "Lancez-vous avec le puissant moteur de recherche et de comparaison de Klarna. Accédez à des statistiques et à des outils conçus pour améliorer votre visibilité."
					}
				},
				"external-apps": {
					"title": "Applications",
					"cta-label": "Ouvrir l'application"
				},
				"popOvers": {
					"searchCompare": {
						"title": "Lancez-vous avec Search and Compare",
						"content": "Vérifiez ici les statistiques et les outils qui peuvent aider à augmenter votre visibilité sur le service de comparaison d'achats de Klarna."
					}
				}
			},
			"no-apps": {
				"title": "Aucune appli disponible !",
				"subtitle": "Contactez votre administrateur pour obtenir l'accès aux applications dont vous avez besoin."
			},
			"contact": {
				"title": "Avez-vous besoin d'aide ?",
				"paragraph": "Si vous avez une question, n'hésitez pas à nous contacter pour obtenir de l'aide.",
				"label": "Contactez-nous"
			},
			"important-banner": {
				"title": "",
				"content": "",
				"linkLabel": ""
			},
			"two-factor-home-banner": {
				"banner-text": "Rendez votre compte plus sécurisé avec une authentification à deux facteurs.",
				"banner-link": "Mettre en place"
			},
			"two-factor-opt-in": {
				"title": "Configurer l'authentification à deux facteurs",
				"intro-paragraph": "Pour augmenter la sécurité de votre compte, nous vous encourageons à configurer une authentification à deux facteurs dès aujourd'hui.",
				"help-link-text": "Cliquez ici",
				"help-text": " pour en savoir plus sur la configuration et la protection.",
				"warning-text": "Assurez-vous d'avoir votre smartphone et accès à Internet avant de continuer",
				"continue-button": "Lancer l'installation"
			},
			"accept-invite-modal": {
				"title": "Accepter les invitations en attente",
				"description": "Vous avez été invité à accéder aux boutiques suivantes. Veuillez noter que quelques minutes peuvent être nécessaires pour que vos choix prennent effet.",
				"accept-all-button": "Tout accepter",
				"decline-all-button": "Tout refuser",
				"save-button": "Enregistrer",
				"error": "Un problème est survenu. Veuillez réessayer plus tard !",
				"invite": {
					"accept-label": "Accepter",
					"decline-label": "Refuser"
				}
			},
			"capture-orientation": {
				"title": "Vous êtes un nouvel utilisateur Klarna ?",
				"sub-title": "Suivez ces étapes pour découvrir les bases :",
				"order-label": "Recevoir une commande",
				"capture-label": "Enregistrer la commande",
				"payout-label": "Être payé",
				"capture-cta": "Enregistrer cette commande",
				"payout-cta": "Appli de règlements",
				"dismiss-cta": "Exclure",
				"order-step": "Avant de commencer, vous devez attendre une commande de vos clients.",
				"capture-step": "Félicitations pour votre première commande !\nPour obtenir votre paiement, passez à l'étape suivante : vous allez expédier et enregistrer votre commande.",
				"payout-step": "Félicitations pour l'enregistrement de votre première commande !\nVous allez maintenant recevoir un règlement de notre part selon les délais définis dans votre Accord Klarna.\nPour plus d'informations sur votre premier règlement, rendez-vous sur",
				"completed-step": "Félicitations pour votre premier paiement !\nVous avez embarqué pour un voyage merveilleux en compagnie de Klarna.\nN'hésitez pas à explorer les autres applications que nous proposons ci-dessous."
			},
			"banner": {
				"common": {
					"branding-fe": {
						"content": "Nous venons de lancer notre nouvelle application Marque !",
						"linkLabel": "Prenez le contrôle de votre marque dès à présent !"
					},
					"onSiteMessaging": {
						"title": "Transformez les visiteurs en acheteurs avec les messages intégrés",
						"text": "Le shopping commence avant même que vos clients n'atteignent le panier. Avec la messagerie intégrée, vous augmenterez la sensibilisation aux options de paiement flexibles de Klarna et aux autres avantages dès le début de l'entonnoir de vente.",
						"cta": "Consultez"
					}
				}
			},
			"nko": {
				"dialog": {
					"title": "Nous lançons de nouvelles fonctionnalités très intéressantes pour votre entreprise.",
					"subtitle": "Et nous aimerions mieux vous connaître.",
					"explore": "Oui, je veux en savoir plus !",
					"name-field": "Alors, comment devrions-nous vous appeler ?",
					"terms-pre": "J'ai lu et j'accepte les",
					"terms-text": "Conditions générales"
				}
			},
			"insightsWidget": {
				"title": "Ventes aujourd'hui",
				"orderCount": "Commandes créées",
				"orderVolume": "Volume des commandes",
				"orderVolumeAverage": "Valeur moyenne des commandes",
				"currencyFootnote": "Affiché en {currency}",
				"link": "Voir les commandes",
				"allStores": "Toutes les boutiques",
				"selectAllOptionText": "Tout sélectionner",
				"deselectAllOptionText": "Effacer la sélection",
				"closeText": "Terminé",
				"closeTextWithSelection": "Appliquer la/les # sélection(s)",
				"elapsedTime": "Dernière commande reçue il y a {hours, plural, =0 {} =1 {une heure} other {# heures}} {minutes, plural, =0 {} =1 {une minute} other {# minutes}}",
				"error": {
					"content": "Malheureusement, le chargement de vos statistiques de commandes ne s'est pas passé comme prévu."
				},
				"relativeDate": {
					"today": "Aujourd'hui",
					"yesterday": "Hier"
				},
				"weekDays": {
					"1": "Lundi",
					"2": "Mardi",
					"3": "Mercredi",
					"4": "Jeudi",
					"5": "Vendredi",
					"6": "Samedi",
					"7": "Dimanche"
				}
			},
			"blackFridayWidget": {
				"title": "La semaine du Black Friday est lancée.",
				"text": "Suivez les statistiques de vos commandes en temps réel.",
				"button": "Voir les statistiques"
			},
			"tasks": {
				"title": "À faire",
				"error": {
					"title": "Nous n'avons pas pu télécharger votre liste de choses à faire",
					"desc": "Veuillez rafraîchir la page pour réessayer."
				},
				"empty": {
					"title": "Rien à signaler !",
					"desc": "Vous n'avez rien à faire pour le moment."
				},
				"single": {
					"products-fe:complete-recollection": "Formulaire AML à remplir obligatoirement par votre entreprise pour empêcher le blanchiment d'argent",
					"products-fe:resume-onboarding": "Finalisez votre intégration",
					"settings-fe:compile-cs-info": "Ajoutez vos informations de service client"
				},
				"aggregated": {
					"disputes-fe:unresolved": "{ count, plural, =1 {un litige non résolu} other {# litiges non résolus}}",
					"orders-fe:expiring": "{ count, plural, =1 {1 commande sur le point d'expirer} other {# commandes sur le point d'expirer}}",
					"orders-fe:tocapture": "{count, plural, =1 {1 commande à enregistrer} other {# commandes à enregistrer}}",
					"orders-fe:uncaptured": "{ count, plural, =1 {une commande non enregistrée} other {# commandes non enregistrées}}",
					"users-fe:mid-added": "{count, plural, =1 {1 nouvelle boutique a été ajoutée} other {# nouvelles boutiques ont été ajoutées}}",
					"users-fe:mid-invited": "{ count, plural, =1 {1 invitation de boutique en attente d'action} other {# invitations de boutique en attente d'action}}"
				}
			}
		}
	}
};