module.exports = {
	"fi": {
		"shared": {
			"countries": {
				"all_countries": "Kaikki maat",
				"AF": "Afganistan",
				"AX": "Ahvenanmaa",
				"AL": "Albania",
				"DZ": "Algeria",
				"AS": "Amerikan Samoa",
				"AD": "Andorra",
				"AO": "Angola",
				"AI": "Anguilla",
				"AQ": "Etelämanner",
				"AG": "Antigua ja Barbuda",
				"AR": "Argentiina",
				"AM": "Armenia",
				"AW": "Aruba",
				"AU": "Australia",
				"AT": "Itävalta",
				"AZ": "Azerbaidžan",
				"BS": "Bahamasaaret",
				"BH": "Bahrain",
				"BD": "Bangladesh",
				"BB": "Barbados",
				"BY": "Valko-Venäjä",
				"BE": "Belgia",
				"BZ": "Belize",
				"BJ": "Benin",
				"BM": "Bermuda",
				"BT": "Bhutan",
				"BO": "Bolivian monikansainen valtio",
				"BQ": "Bonaire, Sint Eustatius ja Saba",
				"BA": "Bosnia ja Hertsegovina",
				"BW": "Botswana",
				"BV": "Bouvet’nsaari",
				"BR": "Brasilia",
				"IO": "Brittiläinen Intian valtameren alue",
				"BN": "Brunei Darussalam",
				"BG": "Bulgaria",
				"BF": "Burkina Faso",
				"BI": "Burundi",
				"KH": "Kambodža",
				"CM": "Kamerun",
				"CA": "Kanada",
				"CV": "Kap Verde",
				"KY": "Caymansaaret",
				"CF": "Keski-Afrikan tasavalta",
				"TD": "Tšad",
				"CL": "Chile",
				"CN": "Kiina",
				"CX": "Joulusaari",
				"CC": "Kookossaaret (Keelingsaaret)",
				"CO": "Kolumbia",
				"KM": "Komorit",
				"CG": "Kongo",
				"CD": "Kongon demokraattinen tasavalta",
				"CK": "Cookinsaaret ",
				"CR": "Costa Rica",
				"CI": "Norsunluurannikko",
				"HR": "Kroatia",
				"CU": "Kuuba",
				"CW": "Curaçao",
				"CY": "Kypros",
				"CZ": "Tšekin tasavalta",
				"DK": "Tanska",
				"DJ": "Djibouti",
				"DM": "Dominica",
				"DO": "Dominikaaninen tasavalta",
				"EC": "Ecuador",
				"EG": "Egypti",
				"SV": "El Salvador",
				"GQ": "Päiväntasaajan Guinea",
				"ER": "Eritrea",
				"EE": "Viro",
				"ET": "Etiopia",
				"FK": "Falklandinsaaret (Malvinassaaret)",
				"FO": "Färsaaret",
				"FJ": "Fidzi",
				"FI": "Suomi",
				"FR": "Ranska",
				"GF": "Ranskan Guayana",
				"PF": "Ranskan Polynesia",
				"TF": "Ranskan eteläiset alueet",
				"GA": "Gabon",
				"GM": "Gambia",
				"GE": "Georgia",
				"DE": "Saksa",
				"GH": "Ghana",
				"GI": "Gibraltar",
				"GR": "Kreikka",
				"GL": "Grönlanti",
				"GD": "Grenada",
				"GP": "Guadeloupe",
				"GU": "Guam",
				"GT": "Guatemala",
				"GG": "Guernsey",
				"GN": "Guinea",
				"GW": "Guinea-Bissau",
				"GY": "Guyana",
				"HT": "Haiti",
				"HM": "Heard ja McDonaldinsaaret",
				"VA": "Pyhä istuin (Vatikaanivaltio)",
				"HN": "Honduras",
				"HK": "Hongkong",
				"HU": "Unkari",
				"IS": "Islanti",
				"IN": "Intia",
				"ID": "Indonesia",
				"IR": "Iranin islamilainen tasavalta",
				"IQ": "Irak",
				"IE": "Irlanti",
				"IM": "Mansaari",
				"IL": "Israel",
				"IT": "Italia",
				"JM": "Jamaika",
				"JP": "Japani",
				"JE": "Jersey",
				"JO": "Jordania",
				"KZ": "Kazakstan",
				"KE": "Kenia",
				"KI": "Kiribati",
				"KP": "Korean demokraattinen kansantasavalta",
				"KR": "Korean tasavalta",
				"KW": "Kuwait",
				"KG": "Kirgisia",
				"LA": "Laosin demokraattinen kansantasavalta",
				"LV": "Latvia",
				"LB": "Libanon",
				"LS": "Lesotho",
				"LR": "Liberia",
				"LY": "Libya",
				"LI": "Liechtenstein",
				"LT": "Liettua",
				"LU": "Luxemburg ",
				"MO": "Macao",
				"MK": "Pohjois-Makedonian tasavalta",
				"MG": "Madagaskar",
				"MW": "Malawi",
				"MY": "Malesia",
				"MV": "Malediivit",
				"ML": "Mali",
				"MT": "Malta",
				"MH": "Marshallinsaaret",
				"MQ": "Martinique",
				"MR": "Mauritania",
				"MU": "Mauritius",
				"YT": "Mayotte",
				"MX": "Meksiko",
				"FM": "Mikronesian liittovaltio",
				"MD": "Moldovan tasavalta",
				"MC": "Monaco",
				"MN": "Mongolia",
				"ME": "Montenegro",
				"MS": "Montserrat",
				"MA": "Marokko",
				"MZ": "Mosambik",
				"MM": "Myanmar",
				"NA": "Namibia",
				"NR": "Nauru",
				"NP": "Nepal",
				"NL": "Alankomaat",
				"NC": "Uusi-Kaledonia",
				"NZ": "Uusi-Seelanti",
				"NI": "Nicaragua",
				"NE": "Niger",
				"NG": "Nigeria",
				"NU": "Niue",
				"NF": "Norfolkinsaari",
				"MP": "Pohjois-Mariaanit",
				"NO": "Norja",
				"OM": "Oman",
				"PK": "Pakistan",
				"PW": "Palau",
				"PS": "Palestiinan valtio",
				"PA": "Panama",
				"PG": "Papua-Uusi-Guinea",
				"PY": "Paraguay",
				"PE": "Peru",
				"PH": "Filippiinit",
				"PN": "Pitcairnsaaret",
				"PL": "Puola",
				"PT": "Portugali",
				"PR": "Puerto Rico",
				"QA": "Qatar",
				"RE": "Réunion",
				"RO": "Romania",
				"RU": "Venäjän federaatio",
				"RW": "Ruanda",
				"BL": "Saint-Barthélemy",
				"SH": "Saint Helena, Ascension ja Tristan da Cunha",
				"KN": "Saint Kitts ja Nevis",
				"LC": "Saint Lucia",
				"MF": "Saint-Martin (Ranska)",
				"PM": "Saint-Pierre ja Miquelon",
				"VC": "Saint Vincent ja Grenadiinit",
				"WS": "Samoa",
				"SM": "San Marino",
				"ST": "São Tomé ja Príncipe",
				"SA": "Saudi-Arabia",
				"SN": "Senegal",
				"RS": "Serbia",
				"SC": "Seychellit",
				"SL": "Sierra Leone",
				"SG": "Singapore",
				"SX": "Sint Maarten (Alankomaat)",
				"SK": "Slovakia",
				"SI": "Slovenia",
				"SB": "Salomonsaaret",
				"SO": "Somalia",
				"ZA": "Etelä-Afrikka",
				"GS": "Etelä-Georgia ja Eteläiset Sandwichsaaret",
				"SS": "Etelä-Sudan",
				"ES": "Espanja",
				"LK": "Sri Lanka",
				"SD": "Sudan",
				"SR": "Surinam",
				"SJ": "Huippuvuoret ja Jan Mayen",
				"SZ": "Swazimaa",
				"SE": "Ruotsi",
				"CH": "Sveitsi",
				"SY": "Syyrian arabitasavalta",
				"TW": "Taiwan, Kiinan tasavalta",
				"TJ": "Tadžikistan",
				"TZ": "Tansanian yhdistynyt tasavalta",
				"TH": "Thaimaa",
				"TL": "Itä-Timor",
				"TG": "Togo",
				"TK": "Tokelau",
				"TO": "Tonga",
				"TT": "Trinidad ja Tobago",
				"TN": "Tunisia",
				"TR": "Turkki",
				"TM": "Turkmenistan",
				"TC": "Turks- ja Caicossaaret",
				"TV": "Tuvalu",
				"UG": "Uganda",
				"UA": "Ukraina",
				"AE": "Yhdistyneet arabiemiirikunnat",
				"GB": "Yhdistynyt kuningaskunta",
				"US": "Yhdysvallat",
				"UM": "Yhdysvaltain pienet erillissaaret",
				"UY": "Uruguay",
				"UZ": "Uzbekistan",
				"VU": "Vanuatu",
				"VE": "Venezuelan bolivariaaninen tasavalta",
				"VN": "Vietnam",
				"VG": "Brittiläiset Neitsytsaaret",
				"VI": "Yhdysvaltain Neitsytsaaret",
				"WF": "Wallis ja Futuna",
				"EH": "Länsi-Sahara",
				"YE": "Jemen",
				"ZM": "Sambia",
				"ZW": "Zimbabwe"
			},
			"months": {
				"january": "Tammikuu",
				"february": "Helmikuu",
				"march": "Maaliskuu",
				"april": "Huhtikuu",
				"may": "Toukokuu",
				"june": "Kesäkuu",
				"july": "Heinäkuu",
				"august": "Elokuu",
				"september": "Syyskuu",
				"october": "Lokakuu",
				"november": "Marraskuu",
				"december": "Joulukuu"
			},
			"weekDaysShort": {
				"monday": "M",
				"tuesday": "T",
				"wednesday": "K",
				"thursday": "T",
				"friday": "P",
				"saturday": "S",
				"sunday": "S"
			}
		}
	}
};