module.exports = {
	"da": {
		"config": {
			"environments": {
				"development": "Udviklingsmiljø",
				"staging": "Lanceringsmiljø",
				"playground": "Legemiljø",
				"production": "Produktionsmiljø"
			}
		}
	}
};