module.exports = {
	"pl": {
		"shared": {
			"months": {
				"january": "Styczeń",
				"february": "Luty",
				"march": "Marzec",
				"april": "Kwiecień",
				"may": "Maj",
				"june": "Czerwiec",
				"july": "Lipiec",
				"august": "Sierpień",
				"september": "Wrzesień",
				"october": "Październik",
				"november": "Listopad",
				"december": "Grudzień"
			},
			"weekDaysShort": {
				"monday": "P",
				"tuesday": "W",
				"wednesday": "Ś",
				"thursday": "C",
				"friday": "P",
				"saturday": "S",
				"sunday": "N"
			}
		}
	}
};