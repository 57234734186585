module.exports = {
	"no": {
		"shared": {
			"countries": {
				"all_countries": "Alle land",
				"AF": "Afghanistan",
				"AX": "Åland",
				"AL": "Albania",
				"DZ": "Algerie",
				"AS": "Amerikansk Samoa",
				"AD": "Andorra",
				"AO": "Angola",
				"AI": "Anguilla",
				"AQ": "Antarktis",
				"AG": "Antigua og Barbuda",
				"AR": "Argentina",
				"AM": "Armenia",
				"AW": "Aruba",
				"AU": "Australia",
				"AT": "Østerrike",
				"AZ": "Azerbaijan",
				"BS": "Bahamas",
				"BH": "Bahrain",
				"BD": "Bangladesh",
				"BB": "Barbados",
				"BY": "Hviterussland",
				"BE": "Belgia",
				"BZ": "Belize",
				"BJ": "Benin",
				"BM": "Bermuda",
				"BT": "Bhutan",
				"BO": "Bolivia, flernasjonal stat",
				"BQ": "Bonaire, Sint Eustatius og Saba",
				"BA": "Bosnia og Herzegovina",
				"BW": "Botswana",
				"BV": "Bouvetøya",
				"BR": "Brasil",
				"IO": "Britisk territorium i Indiahavet",
				"BN": "Brunei Darussalam",
				"BG": "Bulgaria",
				"BF": "Burkina Faso",
				"BI": "Burundi",
				"KH": "Kambodsja",
				"CM": "Kamerun",
				"CA": "Canada",
				"CV": "Kapp Verde",
				"KY": "Caymanøyene",
				"CF": "Den sentralafrikanske republikk",
				"TD": "Chad",
				"CL": "Chile",
				"CN": "Kina",
				"CX": "Juleøya",
				"CC": "Kokosøyene",
				"CO": "Colombia",
				"KM": "Komorene",
				"CG": "Kongo",
				"CD": "Kongo, Den demokratiske republikken",
				"CK": "Cookøyene",
				"CR": "Costa Rica",
				"CI": "Elfenbenskysten",
				"HR": "Kroatia",
				"CU": "Cuba",
				"CW": "Curaçao",
				"CY": "Kypros",
				"CZ": "Tsjekkia",
				"DK": "Danmark",
				"DJ": "Djibouti",
				"DM": "Dominica",
				"DO": "Den dominikanske republikk",
				"EC": "Ecuador",
				"EG": "Egypt",
				"SV": "El Salvador",
				"GQ": "Ekvatorial-Guinea",
				"ER": "Eritrea",
				"EE": "Estland",
				"ET": "Etiopia",
				"FK": "Falklandsøyene",
				"FO": "Færøyene",
				"FJ": "Fiji",
				"FI": "Finland",
				"FR": "Frankrike",
				"GF": "Fransk Guyana",
				"PF": "Fransk polynesia",
				"TF": "De franske sørterritorier",
				"GA": "Gabon",
				"GM": "Gambia",
				"GE": "Georgia",
				"DE": "Tyskland",
				"GH": "Ghana",
				"GI": "Gibraltar",
				"GR": "Hellas",
				"GL": "Grønland",
				"GD": "Grenada",
				"GP": "Guadeloupe",
				"GU": "Guam",
				"GT": "Guatemala",
				"GG": "Guernsey",
				"GN": "Guinea",
				"GW": "Guinea-Bissau",
				"GY": "Guyana",
				"HT": "Haiti",
				"HM": "Heard Island og McDonald-øyene",
				"VA": "Vatikanstaten",
				"HN": "Honduras",
				"HK": "Hong Kong",
				"HU": "Ungarn",
				"IS": "Island",
				"IN": "India",
				"ID": "Indonesia",
				"IR": "Iran, Den islamske republikk",
				"IQ": "Irak",
				"IE": "Irland",
				"IM": "Isle Of Man",
				"IL": "Israel",
				"IT": "Italia",
				"JM": "Jamaica",
				"JP": "Japan",
				"JE": "Jersey",
				"JO": "Jordan",
				"KZ": "Kazakhstan",
				"KE": "Kenya",
				"KI": "Kiribati",
				"KP": "Korea, Den demokratiske folkerepublikken",
				"KR": "Korea, Republikken",
				"KW": "Kuwait",
				"KG": "Kirgisistan",
				"LA": "Laos, Den demokratiske folkerepublikken",
				"LV": "Latvia",
				"LB": "Libanon",
				"LS": "Lesotho",
				"LR": "Liberia",
				"LY": "Libya",
				"LI": "Liechtenstein",
				"LT": "Litauen",
				"LU": "Luxembourg",
				"MO": "Macao",
				"MK": "Makedonia, Den tidligere jugoslaviske republikken",
				"MG": "Madagaskar",
				"MW": "Malawi",
				"MY": "Malaysia",
				"MV": "Maldivene",
				"ML": "Mali",
				"MT": "Malta",
				"MH": "Marshalløyene",
				"MQ": "Martinique",
				"MR": "Mauritania",
				"MU": "Mauritius",
				"YT": "Mayotte",
				"MX": "Mexico",
				"FM": "Mikronesia, Forente stater av",
				"MD": "Moldova, Republikken",
				"MC": "Monaco",
				"MN": "Mongolia",
				"ME": "Montenegro",
				"MS": "Montserrat",
				"MA": "Marokko",
				"MZ": "Mosambik",
				"MM": "Myanmar",
				"NA": "Namibia",
				"NR": "Nauru",
				"NP": "Nepal",
				"NL": "Nederland",
				"NC": "Ny Caledonia",
				"NZ": "New Zealand",
				"NI": "Nicaragua",
				"NE": "Niger",
				"NG": "Nigeria",
				"NU": "Niue",
				"NF": "Norfolk Island",
				"MP": "Nord-Marianene",
				"NO": "Norge",
				"OM": "Oman",
				"PK": "Pakistan",
				"PW": "Palau",
				"PS": "Palestina",
				"PA": "Panama",
				"PG": "Papua Ny-Guinea",
				"PY": "Paraguay",
				"PE": "Peru",
				"PH": "Filippinene",
				"PN": "Pitcairn",
				"PL": "Polen",
				"PT": "Portugal",
				"PR": "Puerto Rico",
				"QA": "Qatar",
				"RE": "Réunion",
				"RO": "Romania",
				"RU": "Russland",
				"RW": "Rwanda",
				"BL": "Saint Barthélemy",
				"SH": "St. Helena, Ascension og Tristan da Cunha",
				"KN": "Saint Kitts og Nevis",
				"LC": "Saint Lucia",
				"MF": "Saint Martin (fransk del)",
				"PM": "Saint Pierre og Miquelon",
				"VC": "Saint Vincent og Grenadinene",
				"WS": "Samoa",
				"SM": "San Marino",
				"ST": "Sao Tome og Principe",
				"SA": "Saudi-Arabia",
				"SN": "Senegal",
				"RS": "Serbia",
				"SC": "Seychellene",
				"SL": "Sierra Leone",
				"SG": "Singapore",
				"SX": "Sint Maarten (nederlandsk del)",
				"SK": "Slovakia",
				"SI": "Slovenia",
				"SB": "Solomonøyene",
				"SO": "Somalia",
				"ZA": "Sør-Afrika",
				"GS": "Sør-Georgia og Sør-Sandwichøyene",
				"SS": "Sør-Sudan",
				"ES": "Spania",
				"LK": "Sri Lanka",
				"SD": "Sudan",
				"SR": "Surinam",
				"SJ": "Svalbard og Jan Mayen",
				"SZ": "Swaziland",
				"SE": "Sverige",
				"CH": "Sveits",
				"SY": "Syria",
				"TW": "Taiwan",
				"TJ": "Tadsjikistan",
				"TZ": "Tanzania",
				"TH": "Thailand",
				"TL": "Øst-Timor",
				"TG": "Togo",
				"TK": "Tokelau",
				"TO": "Tonga",
				"TT": "Trinidad og Tobago",
				"TN": "Tunisia",
				"TR": "Tyrkia",
				"TM": "Turkmenistan",
				"TC": "Turks- og Caicosøyene",
				"TV": "Tuvalu",
				"UG": "Uganda",
				"UA": "Ukraina",
				"AE": "De forente arabiske emirater",
				"GB": "Storbritannia",
				"US": "USA",
				"UM": "USAs ytre småøyer",
				"UY": "Uruguay",
				"UZ": "Uzbekistan",
				"VU": "Vanuatu",
				"VE": "Venezuela",
				"VN": "Vietnam",
				"VG": "Jomfruøyene, Britiske",
				"VI": "Jomfruøyene, USA",
				"WF": "Wallis og Futuna",
				"EH": "Vestre Sahara",
				"YE": "Yemen",
				"ZM": "Zambia",
				"ZW": "Zimbabwe"
			},
			"months": {
				"january": "Januar",
				"february": "Februar",
				"march": "Mars",
				"april": "April",
				"may": "Mai",
				"june": "Juni",
				"july": "Juli",
				"august": "August",
				"september": "September",
				"october": "Oktober",
				"november": "November",
				"december": "Desember"
			},
			"weekDaysShort": {
				"monday": "M",
				"tuesday": "T",
				"wednesday": "O",
				"thursday": "T",
				"friday": "F",
				"saturday": "S",
				"sunday": "S"
			}
		}
	}
};