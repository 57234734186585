module.exports = {
	"es": {
		"config": {
			"environments": {
				"development": "Entorno de desarrollo",
				"staging": "Entorno de ensayo",
				"playground": "Entorno recreativo",
				"production": "Entorno de producción"
			}
		}
	}
};