module.exports = {
	"pl": {
		"home-fe": {
			"boosters-home-v03": {
				"install-plugin": {
					"stepNumber": "1",
					"title": "Zainstaluj wtyczkę",
					"description": "Wybierz swoją platformę e-commerce, abyśmy mogli utworzyć link i przyspieszyć instalację.",
					"selectorLabel": "Wybierz platformę",
					"getPluginButtonLabel": "Pobierz wtyczkę",
					"klarnaDocsLinkLabel": "Więcej informacji znajdziesz w dokumentacji Klarna"
				},
				"start-setup": {
					"stepNumber": "2",
					"title": "Włącz wtyczkę",
					"description": "Wygeneruj i skopiuj identyfikator klienta i klucz API, które będą potrzebne do włączenia wtyczki."
				},
				"promotion-banner": {
					"title": "Klarna dla platform",
					"subtitle": "Rozwijaj swoją firmę, korzystając z jednej wtyczki, dającej dostęp do wielu funkcji zwiększających sprzedaż i poprawiających wrażenia zakupowe bez dodatkowych kosztów.",
					"banner-cards": {
						"osm-title": "Podnieś średnią wartość zamówienia dzięki <span>wiadomościom w witrynie</span>",
						"kec-title": "Zwiększ konwersję dzięki kasie ekspresowej",
						"siwk-title": "Przyciągnij nowych klientów, korzystając z opcji <span>logowania z Klarna</span>"
					}
				},
				"configure-boost-features": {
					"stepNumber": "3",
					"title": "Dostosuj rozmieszczenie funkcji",
					"description": "Jest to opcjonalny krok umożliwiający zmianę wyglądu funkcji w Twoim sklepie. Aby to zrobić, nalezy ukończyć krok 2.",
					"no-credentials": "Upewnij się, aby wcześniej wygenerować identyfikator klienta i klucz API, aby kontynuować ten krok.",
					"apps": {
						"osm": {
							"link-label": "Dostosuj wiadomości w witrynie",
							"description": "Ustaw wiadomości w witrynie pasujące do swojej marki, dostosowując motyw i wybierając miejsca docelowe"
						},
						"kec": {
							"link-label": "Dostosuj kasę ekspresową",
							"description": "Ustaw przycisk kasy ekspresowej pasujący do swojej marki, dostosowując motyw przycisku, jego kształt i wybierając miejsca docelowe"
						},
						"siwk": {
							"link-label": "Skonfiguruj logowanie z Klarna",
							"description": "Wybierz zakres logowania z Klarna i dodaj przekierowujący adres URL. Zakres to informacje zebrane od Twojego klienta, a przekierowujący adres URL jest używany, jeśli wyskakujące okienka są blokowane przez klientów"
						}
					}
				},
				"client-identifier-section": {
					"title": "Identyfikator klienta",
					"description": "Unikalny identyfikator na potrzeby konfiguracji",
					"manage-link": "Zarządzaj identyfikatorem klienta i źródłem",
					"generate-button": "Wygeneruj",
					"tooltip": "Wygeneruj i skopiuj identyfikator klienta. Potrzebujesz go, aby rozpocząć konfigurację wtyczki na portalu swojej platformy."
				},
				"api-key": {
					"warning-banner": {
						"content": "Twoje klucze API są już aktywne. Przed wygenerowaniem nowych upewnij się, że te, których nie używasz, zostały wyłączone."
					},
					"title": "Klucz API Klarna",
					"description": "Klucz API do konfiguracji",
					"tooltip": "Pamiętaj, że po wygenerowaniu tajnego klucza API nie wyświetlimy go ponownie.",
					"generate-button": "Wygeneruj",
					"manageApiKey": "Zarządzaj kluczem API"
				},
				"non-platform-integration": {
					"question": "Nie korzystasz z Klarna dla platform?",
					"link": "Zobacz, jak bezpośrednio zintegrować się z Klarna"
				}
			},
			"boosters-home": {
				"welcome-banner": {
					"title": "Uwolnij pełną moc Klarna",
					"text": "Funkcje zaprojektowane, aby zmienić odwiedzających w wartościowych kupujących. Usprawnij każdy etap zakupów – od przeglądania do kasy."
				},
				"plugin-carousel": {
					"title": "Wtyczka umożliwiająca konfigurację bez kodowania",
					"overviewLink": "Zobacz wszystkie platformy",
					"directIntegrationLabel": "Nie korzystasz z Klarna dla platform?",
					"directIntegrationLink": "Zobacz, jak bezpośrednio zintegrować się z Klarna"
				},
				"booster-apps": {
					"title": "Dowiedz się więcej",
					"kec-fe": {
						"title": "Przyspiesz proces płatności 5-krotnie",
						"description": "Mniej kliknięć oznacza mniej porzuceń. Zwiększ konwersję i zminimalizuj liczbę porzuceń koszyka kasie ekspresowej na stronach produktów i koszyka.",
						"callToAction": "Więcej informacji o Kasie ekspresowej"
					},
					"siwk-fe": {
						"title": "Zakupy z logowaniem społecznościowym",
						"description": "Kupujący dostają szybką i bezpieczną opcję logowania jednym kliknięciem. Ty dostajesz ulepszone, wartościowe dane profilu kupującego.",
						"callToAction": "Więcej na temat logowania z Klarna"
					},
					"upstream-fe": {
						"title": "Zamień odwiedzających w kupujących",
						"description": "Zmaksymalizuj konwersję za pomocą dynamicznych komunikatów promocyjnych w trakcie ścieżki zakupowej.",
						"callToAction": "Więcej na temat Wiadomości w witrynie"
					},
					"branding-fe": {
						"title": "Zwiększ widoczność swojego sklepu",
						"description": "Aplikacja Klarna daje Ci możliwość zarządzania marką. Możesz przesłać do niej logo sklepu, ikonę i URL.",
						"callToAction": "Skonfiguruj Menedźera marki"
					}
				}
			},
			"boosters-plugins": {
				"title": "Konfiguracja wtyczki",
				"description": "Zacznij korzystać z naszych wtyczek i upewnij się, że możesz zacząć."
			},
			"boosters-plugin": {
				"title": "Klarna dla {pluginName}",
				"description": "Wykonaj poniższe czynności, aby włączyć płatności Klarna lub funkcje usprawniające dzięki naszej konfiguracji bez użycia kodu.",
				"access-warning": {
					"both": "Nie masz uprawnień dla {selectedStoreName} ({selectedMid}) do generowania kluczy API i identyfikatora klienta. Skontaktuj się z administratorem i poproś o dostęp do ustawień płatności, aby kontynuować.",
					"client": "Nie masz uprawnień dla {selectedStoreName} ({selectedMid}) do wygenerowania identyfikatora klienta. Skontaktuj się z administratorem i poproś o dostęp do ustawień płatności, aby kontynuować.",
					"api": "Nie masz uprawnień dla {selectedStoreName} ({selectedMid}) do wygenerowania kluczy API. Skontaktuj się z administratorem i poproś o dostęp do ustawień płatności, aby kontynuować."
				},
				"back": "Wstecz",
				"instructions": {
					"api-key": {
						"title": "Włącz płatności Klarna",
						"content": "Musisz wygenerować i skopiować klucze API, aby móc ich użyć podczas konfiguracji.",
						"link-label": "Więcej informacji znajdziesz w dokumentacji Klarna.",
						"generate": "Wygeneruj klucze API",
						"active-warning": {
							"content": "Twoje klucze API są już aktywne. Przed wygenerowaniem nowych upewnij się, że te, których nie używasz, zostały wyłączone.",
							"link": "Zarządzanie kluczami API"
						},
						"active-warning-api-keys": {
							"content": "Twoje klucze API są już aktywne. Przed wygenerowaniem nowych upewnij się, że te, których nie używasz, zostały wyłączone.",
							"link": "Zarządzanie kluczami API"
						}
					},
					"client-id": {
						"title": "Włącz funkcje usprawniające",
						"content": "Aby dodać ekspresowe płatności Klarna i wiadomości w witrynie, musisz wygenerować i skopiować identyfikator klienta, aby móc go użyć podczas konfiguracji.",
						"generate": "Wygeneruj identyfikator klienta",
						"manage": "Zarządzaj identyfikatorem klienta"
					},
					"plugin-doc": {
						"title": "Skonfiguruj funkcje usprawniające",
						"content": "Aby sfinalizować konfigurację, musisz skonfigurować ekspresowe płatności Klarna i wiadomości w witrynie w ustawieniach platformy."
					}
				},
				"error-messages": {
					"default": "Wystąpił błąd. Spróbuj ponownie później lub skorzystaj z pomocy.",
					"fetch-data": "Wystąpił błąd podczas pobierania Twoich danych. Spróbuj ponownie później lub skorzystaj z pomocy.",
					"create-api-key": "Wystąpił błąd podczas generowania Twoich kluczy API. Spróbuj ponownie później lub skorzystaj z pomocy.",
					"create-client-id": "Wystąpił błąd podczas tworzenia Twojego identyfikatora klienta. Spróbuj ponownie później lub skorzystaj z pomocy."
				}
			},
			"marketing-home": {
				"description": "Przedstawiamy nasze produkty marketingowe. Wszystko znajdziesz poniżej ",
				"essentials": {
					"title": "Marketing",
					"cta-label": "Dowiedz się więcej",
					"branding-fe": {
						"description": "Skonfiguruj i zarządzaj swoją marką. Pokaż się klientom z jak najlepszej strony w całym ekosystemie Klarna, w tym w naszej smoooth aplikacji."
					},
					"klarna-search-b2b-portal": {
						"description": "Zacznij korzystać z zaawansowanej wyszukiwarki i porównywarki Klarna. Masz też do dyspozycji statystyki i narzędzia zaprojektowanyce w celu zwiększenia widoczności."
					}
				},
				"external-apps": {
					"title": "Aplikacje",
					"cta-label": "Otwórz aplikację"
				},
				"popOvers": {
					"searchCompare": {
						"title": "Wprowadzeni do funkcji Wyszukaj i Porównaj",
						"content": "Tutaj znajdziesz statystyki i narzędzia, które pomogą Ci zwiększyć widoczność w porównywarce Klarna."
					}
				}
			},
			"no-apps": {
				"title": "Brak dostępnych aplikacji!",
				"subtitle": "Skontaktuj się z administratorem, który pomoże Ci uzyskać dostęp do potrzebnych aplikacji."
			},
			"contact": {
				"title": "Potrzebujesz w czymś pomocy?",
				"paragraph": "Jeśli masz jakieś pytania, śmiało pytaj, a my spróbujemy na nie odpowiedzieć.",
				"label": "Skontaktuj się z nami"
			},
			"important-banner": {
				"title": "",
				"content": "",
				"linkLabel": ""
			},
			"two-factor-home-banner": {
				"banner-text": "Zabezpiecz lepiej swoje konto dzięki uwierzytelnianiu dwuskładnikowemu.",
				"banner-link": "Skonfiguruj"
			},
			"two-factor-opt-in": {
				"title": "Skonfiguruj uwierzytelnianie dwuskładnikowe",
				"intro-paragraph": "Aby zwiększyć bezpieczeństwo swojego konta, zachęcamy do skonfigurowania dwuskładnikowego uwierzytelniania już dziś.",
				"help-link-text": "Kliknij tutaj",
				"help-text": ", aby dowiedzieć się, jak je skonfigurować i w jaki sposób Cię ono chroni.",
				"warning-text": "Zanim rozpoczniesz, upewnij się, że masz smartfon i dostęp do internetu",
				"continue-button": "Rozpocznij konfigurację"
			},
			"accept-invite-modal": {
				"title": "Zaakceptuj oczekujące zaproszenia",
				"description": "Zaproszono Cię do następujących sklepów. Pamiętaj, że może upłynąć kilka minut, zanim dokonane wybory zaczną obowiązywać.",
				"accept-all-button": "Zaakceptuj wszystkie",
				"decline-all-button": "Odrzuć wszystkie",
				"save-button": "Zapisz",
				"error": "Pojawił się błąd. Spróbuj ponownie później.",
				"invite": {
					"accept-label": "Zaakceptuj",
					"decline-label": "Odrzuć"
				}
			},
			"capture-orientation": {
				"title": "Pierwszy raz korzystasz z Klarna?",
				"sub-title": "Poniższe kroki pozwolą Ci poznać podstawy:",
				"order-label": "Otrzymaj zamówienie",
				"capture-label": "Przyjmij zamówienie",
				"payout-label": "Otrzymaj płatność",
				"capture-cta": "Przyjmij to zamówienie",
				"payout-cta": "Aplikacja do rozliczeń",
				"dismiss-cta": "Odrzuć",
				"order-step": "Najpierw musimy poczekać na przychodzące zamówienie od Twoich klientów.",
				"capture-step": "Gratulujemy pierwszego zamówienia.\nAby otrzymać płatność, następnym krokiem jest wysyłka i przyjęcie Twojego zamówienia.",
				"payout-step": "Gratulujemy przyjęcia pierwszego zamówienia.\nTeraz otrzymasz od nas wypłatę zgodnie z harmonogramem określonym w umowie z Klarna.\nPo więcej szczegółów na temat pierwszej wypłaty przejdź do",
				"completed-step": "Gratulujemy pierwszej wypłaty.\nWitamy w pięknej podróży w towarzystwie Klarna.\nŚmiało zapoznaj się z innymi aplikacjami, które oferujemy poniżej."
			},
			"banner": {
				"common": {
					"branding-fe": {
						"content": "Właśnie uruchomiliśmy naszą nową aplikację Branding!",
						"linkLabel": "Przejmij kontrolę nad swoją marką już teraz!"
					},
					"onSiteMessaging": {
						"title": "Zamień odwiedzających w klientów za pomocą funkcji Wiadomości w witrynie",
						"text": "Zakupy zaczynają się, zanim jeszcze klienci dotrą do koszyka. Dzięki wiadomościom w witrynie zwiększysz świadomość elastycznych opcji płatności Klarna i innych korzyści już na wczesnym etapie lejka sprzedaży.",
						"cta": "Wypróbuj"
					}
				}
			},
			"nko": {
				"dialog": {
					"title": "Wprowadzamy ciekawe funkcje dla Twojego biznesu.",
					"subtitle": "I chcielibyśmy lepiej Cię poznać.",
					"explore": "Tak, odkryjmy to!",
					"name-field": "Jak zatem mamy się do Ciebie zwracać?",
					"terms-pre": "Znam i akceptuję ",
					"terms-text": "Warunki i regulamin"
				}
			},
			"insightsWidget": {
				"title": "Sprzedaż dzisiaj",
				"orderCount": "Utworzone zamówienia",
				"orderVolume": "Wolumen sprzedaży",
				"orderVolumeAverage": "Średnia wartość zamówienia",
				"currencyFootnote": "Wyświetlane w {currency}",
				"link": "Zobacz zamówienia",
				"allStores": "Wszystkie sklepy",
				"selectAllOptionText": "Zaznacz wszystko",
				"deselectAllOptionText": "Wyczyść wybór",
				"closeText": "Gotowe",
				"closeTextWithSelection": "Zastosuj wybory (#)",
				"elapsedTime": "Ostatnie zamówienie otrzymano {hours, plural, =0 {} =1 {godzinę} other {# godz.}} {minutes, plural, =0 {} =1 {1 minutę} other {# min.}} temu",
				"error": {
					"content": "Niestety, Twoje statystyki zamówień nie załadowały się poprawnie."
				},
				"relativeDate": {
					"today": "Dzisiaj",
					"yesterday": "Wczoraj"
				},
				"weekDays": {
					"1": "Poniedziałek",
					"2": "Wtorek",
					"3": "Środa",
					"4": "Czwartek",
					"5": "Piątek",
					"6": "Sobota",
					"7": "Niedziela"
				}
			},
			"blackFridayWidget": {
				"title": "Nadszedł tydzień Black Friday.",
				"text": "Śledź swoje statystyki zamówień w czasie prawie rzeczywistym.",
				"button": "Przeglądaj statystyki"
			},
			"tasks": {
				"title": "Zadania",
				"error": {
					"title": "Nie mogliśmy załadować listy zadań",
					"desc": "Przeładuj stronę, abyśmy mogli spróbować ponownie."
				},
				"empty": {
					"title": "Wszystko gra!",
					"desc": "Nie masz teraz żadnych otwartych zadań."
				},
				"single": {
					"products-fe:complete-recollection": "Obowiązkowy formularz AML dla Twojej firmy w celu zapobiegania praniu pieniędzy",
					"products-fe:resume-onboarding": "Zakończ wdrożenie",
					"settings-fe:compile-cs-info": "Dodaj informacje dotyczące obsługi klienta"
				},
				"aggregated": {
					"disputes-fe:unresolved": "{ count, plural, =1 {1 nierozwiązany spór} other {nierozwiązanych sporów: #}}",
					"orders-fe:expiring": "{ count, plural, =1 {1 wygasłe zamówienie} other {wygasłych zamówień: #}}",
					"orders-fe:tocapture": "{ count, plural, =1 {1 zamówienie do przyjęcia} other {zamówienia do przyjęcia: #}}",
					"orders-fe:uncaptured": "{ count, plural, =1 {1 nieprzyjęte zamówienie} other {nieprzyjętych zamówień: #}}",
					"users-fe:mid-added": "{ count, plural, =1 {dodano 1 nowy sklep} other {nowo dodane sklepy: #}}",
					"users-fe:mid-invited": "{ count, plural, =1 {1 zaproszenie do sklepu oczekuje działania} few {# zaproszenia do sklepu oczekują działań} other {# zaproszenie do sklepu oczekuje działań}}"
				}
			}
		}
	}
};