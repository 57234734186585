import insights from 'mage-insights';
import { useApiKey } from '../../hooks/useApiKey';
import { useMid } from '../../hooks/useMid';
export default function useGenerateButton() {
    const { areActionsEnabled, createApiKey } = useApiKey();
    const { selectedMid } = useMid();
    const event = {
        category: 'v3/boost-home/authenticate-and-connect/generate-api-key',
        action: 'impression',
        label: selectedMid !== null && selectedMid !== void 0 ? selectedMid : undefined
    };
    function handleClick() {
        insights.event(Object.assign(Object.assign({}, event), { action: 'click' }));
        createApiKey();
    }
    return {
        event,
        handleClick,
        areActionsEnabled
    };
}
