module.exports = {
	"da": {
		"shared": {
			"countries": {
				"all_countries": "Alle lande",
				"AF": "Afghanistan",
				"AX": "Åland",
				"AL": "Albanien",
				"DZ": "Algeriet",
				"AS": "Amerikansk Samoa",
				"AD": "Andorra",
				"AO": "Angola",
				"AI": "Anguilla",
				"AQ": "Antarktis",
				"AG": "Antigua og Barbuda",
				"AR": "Argentina",
				"AM": "Armenien",
				"AW": "Aruba",
				"AU": "Australien",
				"AT": "Østrig",
				"AZ": "Aserbajdsjan",
				"BS": "Bahamas",
				"BH": "Bahrain",
				"BD": "Bangladesh",
				"BB": "Barbados",
				"BY": "Hviderusland",
				"BE": "Belgien",
				"BZ": "Belize",
				"BJ": "Benin",
				"BM": "Bermuda",
				"BT": "Bhutan",
				"BO": "Bolivia",
				"BQ": "Bonaire, Sint Eustatius og Saba",
				"BA": "Bosnien-Herzegovina",
				"BW": "Botswana",
				"BV": "Bouvetøen",
				"BR": "Brasilien",
				"IO": "Det britiske territorium i Det Indiske Ocean",
				"BN": "Brunei Darussalam",
				"BG": "Bulgarien",
				"BF": "Burkina Faso",
				"BI": "Burundi",
				"KH": "Cambodja",
				"CM": "Cameroon",
				"CA": "Canada",
				"CV": "Kap Verde",
				"KY": "Caymanøerne",
				"CF": "Den Centralafrikanske Republik",
				"TD": "Chad",
				"CL": "Chile",
				"CN": "Kina",
				"CX": "Juleøen",
				"CC": "Cocosøerne (Keelingøerne)",
				"CO": "Colombia",
				"KM": "Comoros",
				"CG": "Congo",
				"CD": "Congo, Den Demokratiske Republik",
				"CK": "Cookøerne",
				"CR": "Costa Rica",
				"CI": "Elfenbenskysten",
				"HR": "Kroatien",
				"CU": "Cuba",
				"CW": "Curaçao",
				"CY": "Cypern",
				"CZ": "Tjekkiet",
				"DK": "Danmark",
				"DJ": "Djibouti",
				"DM": "Dominica",
				"DO": "Dominikanske Republik",
				"EC": "Ecuador",
				"EG": "Egypten",
				"SV": "El Salvador",
				"GQ": "Ækvatorisk Guinea",
				"ER": "Eritrea",
				"EE": "Estland",
				"ET": "Etiopien",
				"FK": "Falklandsøerne (Malvinas)",
				"FO": "Færøerne",
				"FJ": "Fiji",
				"FI": "Finland",
				"FR": "Frankrig",
				"GF": "Fransk Guiana",
				"PF": "Fransk Polynesien",
				"TF": "De franske besiddelser i det sydlige Indiske Ocean",
				"GA": "Gabon",
				"GM": "Gambia",
				"GE": "Georgien",
				"DE": "Tyskland",
				"GH": "Ghana",
				"GI": "Gibraltar",
				"GR": "Grækenland",
				"GL": "Grønland",
				"GD": "Grenada",
				"GP": "Guadeloupe",
				"GU": "Guam",
				"GT": "Guatemala",
				"GG": "Guernsey",
				"GN": "Guinea",
				"GW": "Guinea-Bissau",
				"GY": "Guyana",
				"HT": "Haiti",
				"HM": "Heard-øen og McDonald-øerne",
				"VA": "Vatikanstaten",
				"HN": "Honduras",
				"HK": "Hongkong",
				"HU": "Ungarn",
				"IS": "Island",
				"IN": "Indien",
				"ID": "Indonesien",
				"IR": "Iran",
				"IQ": "Irak",
				"IE": "Irland",
				"IM": "Isle of Man",
				"IL": "Israel",
				"IT": "Italien",
				"JM": "Jamaica",
				"JP": "Japan",
				"JE": "Jersey",
				"JO": "Jordan",
				"KZ": "Kazakhstan",
				"KE": "Kenya",
				"KI": "Kiribati",
				"KP": "Nordkorea",
				"KR": "Sydkorea",
				"KW": "Kuwait",
				"KG": "Kyrgyzstan",
				"LA": "Laos",
				"LV": "Letland",
				"LB": "Libanon",
				"LS": "Lesotho",
				"LR": "Liberia",
				"LY": "Libyen",
				"LI": "Liechtenstein",
				"LT": "Litauen",
				"LU": "Luxembourg",
				"MO": "Macao",
				"MK": "Makedonien",
				"MG": "Madagaskar",
				"MW": "Malawi",
				"MY": "Malaysia",
				"MV": "Maldiverne",
				"ML": "Mali",
				"MT": "Malta",
				"MH": "Marshalløerne",
				"MQ": "Martinique",
				"MR": "Mauretanien",
				"MU": "Mauritius",
				"YT": "Mayotte",
				"MX": "Mexico",
				"FM": "Mikronesien",
				"MD": "Moldova",
				"MC": "Monaco",
				"MN": "Mongoliet",
				"ME": "Montenegro",
				"MS": "Montserrat",
				"MA": "Marokko",
				"MZ": "Mozambique",
				"MM": "Myanmar",
				"NA": "Namibia",
				"NR": "Nauru",
				"NP": "Nepal",
				"NL": "Holland",
				"NC": "Ny Kaledonien",
				"NZ": "New Zealand",
				"NI": "Nicaragua",
				"NE": "Niger",
				"NG": "Nigeria",
				"NU": "Niue",
				"NF": "Norfolkøerne",
				"MP": "Nordmarianerne",
				"NO": "Norge",
				"OM": "Oman",
				"PK": "Pakistan",
				"PW": "Palau",
				"PS": "Palæstina",
				"PA": "Panama",
				"PG": "Papua New Guinea",
				"PY": "Paraguay",
				"PE": "Peru",
				"PH": "Filippinerne",
				"PN": "Pitcairn",
				"PL": "Polen",
				"PT": "Portugal",
				"PR": "Puerto Rico",
				"QA": "Qatar",
				"RE": "Réunion",
				"RO": "Rumænien",
				"RU": "Rusland",
				"RW": "Rwanda",
				"BL": "Saint-Barthélemy",
				"SH": "Saint Helena, Ascension og Tristan da Cunha",
				"KN": "Saint Kitts og Nevis",
				"LC": "Saint Lucia",
				"MF": "Saint Martin (franske del)",
				"PM": "Saint-Pierre og Miquelon",
				"VC": "Saint Vincent og Grenadinerne",
				"WS": "Samoa",
				"SM": "San Marino",
				"ST": "Sao Tome og Principe",
				"SA": "Saudi-Arabien",
				"SN": "Senegal",
				"RS": "Serbien",
				"SC": "Seychellerne",
				"SL": "Sierra Leone",
				"SG": "Singapore",
				"SX": "Sint Maarten (hollandsk del)",
				"SK": "Slovakiet",
				"SI": "Slovenien",
				"SB": "Solomonøerne",
				"SO": "Somalia",
				"ZA": "Sydafrika",
				"GS": "Det sydlige Georgia og de sydlige Sandwichøer",
				"SS": "Sydsudan",
				"ES": "Spanien",
				"LK": "Sri Lanka",
				"SD": "Sudan",
				"SR": "Suriname",
				"SJ": "Svalbard og Jan Mayen",
				"SZ": "Swaziland",
				"SE": "Sverige",
				"CH": "Schweitz",
				"SY": "Syrien",
				"TW": "Taiwan",
				"TJ": "Tadsjikistan",
				"TZ": "Tanzania",
				"TH": "Thailand",
				"TL": "Østtimor",
				"TG": "Togo",
				"TK": "Tokelau",
				"TO": "Tonga",
				"TT": "Trinidad og Tobago",
				"TN": "Tunisien",
				"TR": "Tyrkiet",
				"TM": "Turkmenistan",
				"TC": "Turks- og Caicosøerne",
				"TV": "Tuvalu",
				"UG": "Uganda",
				"UA": "Ukraine",
				"AE": "De Forenede Arabiske Emirater",
				"GB": "Storbritannien",
				"US": "USA",
				"UM": "Amerikanske oversøiske øer",
				"UY": "Uruguay",
				"UZ": "Uzbekistan",
				"VU": "Vanuatu",
				"VE": "Venezuela",
				"VN": "Vietnam",
				"VG": "Britiske Jomfruøer",
				"VI": "Amerikanske Jomfruøer",
				"WF": "Wallis og Futuna",
				"EH": "Vestsahara",
				"YE": "Yemen",
				"ZM": "Zambia",
				"ZW": "Zimbabwe"
			},
			"months": {
				"january": "Januar",
				"february": "Februar",
				"march": "Marts",
				"april": "April",
				"may": "Maj",
				"june": "Juni",
				"july": "Juli",
				"august": "August",
				"september": "September",
				"october": "Oktober",
				"november": "November",
				"december": "December"
			},
			"weekDaysShort": {
				"monday": "M",
				"tuesday": "T",
				"wednesday": "O",
				"thursday": "T",
				"friday": "F",
				"saturday": "S",
				"sunday": "S"
			}
		}
	}
};