module.exports = {
	"da": {
		"help-tool": {
			"button-text": "Hjælp",
			"support-button": "Få hjælp",
			"support-page": {
				"create-ticket": {
					"title": "Få hjælp",
					"instructions": "Send en anmodning nedenfor, og vi vender tilbage til dig inden for 1 hverdag.",
					"related-content": {
						"title": "Disse kan måske hjælpe dig med at løse dit problem"
					},
					"fields": {
						"mid": {
							"label": "Vælg butik",
							"validation": "Butik skal udfyldes"
						},
						"phoneNumber": {
							"label": "Telefonnummer (valgfrit)"
						},
						"topic": {
							"question": "Hvad drejer dit spørgsmål sig om?",
							"label": "Vælg et emne",
							"validation": "Emne skal vælges",
							"options": {
								"orders": "Ordrer",
								"settlements": "Afregninger",
								"agreements": "Aftaler",
								"others": "Andet"
							}
						},
						"subject": {
							"label": "Genstand",
							"validation": "Genstand skal udfyldes"
						},
						"description": {
							"label": "Meddelelse",
							"validation": "Beskrivelse skal udfyldes"
						},
						"attachment": {
							"label": "Tilføjede vedhæftninger",
							"button": "Overfør",
							"info": "Skærmbilleder vil hjælpe os med at løse problemet hurtigere.",
							"supportedFileTypes": "Understøttede filtyper: .png, .jpg, .pdf",
							"error": "Den samlede filstørrelse må ikke overskride {maxSize} MB"
						}
					},
					"submit": "Indsend anmodning",
					"service-unavailable-error": "Hov! Noget gik galt. Prøv igen om et øjeblik. {link} for hjælp, hvis dette fortsætter.",
					"generic-error": "Vi kunne ikke behandle din anmodning. Vores systemer er ikke på talefod med hinanden i øjeblikket. Prøv at genindlæse, eller kom tilbage senere. {link} for hjælp, hvis dette fortsætter.",
					"contact-link": "kontakt os"
				},
				"view-ticket": {
					"title": "Udført!",
					"details": "Vi har modtaget din anmodning, og vi vender tilbage med et svar inden for 1 hverdag. Her er dine oplysninger:",
					"ticket": "Anmodningsnr. {ticketId}",
					"email": "E-mail: {email}",
					"status": "Du kan spore status på vores supportside.",
					"track": "Spor status"
				}
			},
			"header": {
				"homeLink": "Nulstil"
			},
			"home": {
				"greeting": "Hej {givenName}! Hvordan kan vi hjælpe dig?",
				"topics": "Hvad har du brug for hjælp med?"
			},
			"topics": {
				"expandButton": "Mere"
			},
			"feedback": {
				"title": "Hjalp dette?",
				"optionYes": "Ja, det hjalp",
				"optionNo": "Nej, jeg har brug for mere hjælp",
				"positiveFeedback": "Tak for den positive feedback. Det hjælper os med at give dig mere relevante artikler i fremtiden."
			},
			"freeTextFeedback": {
				"title": "Hjælp os med at blive bedre",
				"placeholder": "Hvorfor fandt du ikke dette indhold hjælpsomt?",
				"submitButton": "Send",
				"feedbackMessage": "Tak for din feedback. Det hjælper os med at give dig mere relevante artikler i fremtiden."
			},
			"relatedContent": {
				"title": "Forbundet indhold"
			},
			"search": {
				"buttonLabel": "Søg",
				"input": "Søg efter artikler ...",
				"loading": "Indlæser ...",
				"results": {
					"contact": "Kan du ikke finde det indhold, du har brug for?",
					"empty": "Beklager, vi fandt ikke nogen resultater for din forespørgsel. Prøv at søge igen efter et andet udtryk.",
					"singleTopicFound": "Vi fandt ét emne",
					"multipleTopicsFound": "Vi fandt {count} emner",
					"singleArticleFound": "Vi fandt ét artikel",
					"multipleArticlesFound": "Vi fandt {count} artikler"
				}
			},
			"contact": {
				"title": "Kontakt os",
				"channelsIntro": "Kontakt os, så vi kan hjælpe dig.",
				"businessDetailsTitle": "Dine forretningsoplysninger",
				"businessDetailsName": "Forretningsnavn",
				"businessDetailsEmail": "E-mail",
				"businessDetailsMid": "Butik-ID",
				"phone": {
					"title": "Ring til os",
					"paragraph": "Du kan ringe til os i åbningstiden på hverdage. Hvis du befinder dig i Sverige eller Storbritannien, kan du også ringe til os i weekenden mellem 10.00-16.00."
				},
				"email": {
					"title": "Send os en e-mail",
					"paragraph": "Send os en e-mail, og vi vil vende tilbage til dig, så snart vi kan."
				}
			},
			"errorBoundary": {
				"title": "Der opstod en fejl",
				"message": "Vi beklager meget, men der opstod en fejl. Du kan klikke på knappen 'Gå tilbage' nedenfor for at gå tilbage til hjælpeværktøjet eller knappen 'Få support' for at kontakte os.",
				"resetButton": "Gå tilbage",
				"errorId": "Fejl-ID: {errorId}"
			},
			"tour": {
				"prev": "Forrige",
				"next": "Næste",
				"done": "Udført"
			},
			"notAvailableMobile": {
				"title": "Ikke tilgængelig",
				"buttonBack": "Tilbage",
				"paragraph": "Dette indhold er ikke tilgængelig på denne enhed. Prøv at åbne på en enhed med en større skærmstørrelse såsom en computer."
			},
			"shareButton": {
				"title": {
					"article": "Del et dybt link til denne artikel",
					"topic": "Del et dybt link til denne emne",
					"tour": "Del et dybt link til denne tur"
				},
				"radioItemHome": "Hjemmeapp",
				"descriptionHome": "Åbner hjælpeartiklen på forhandlerportalens hjemmeside",
				"radioItemCurrent": "Aktuel side",
				"descriptionCurrent": "Åbner hjælpeartiklen på den nuværende side"
			},
			"marketSelector": {
				"whichMarketToContact": "Hvilket marked ønsker du at kontakte?"
			}
		},
		"orientation": {
			"title": "Lær hvordan man laver ..."
		},
		"helpbot": {
			"questions": {
				"whatDoYouNeedHelpWith": "Hej! Hvad har du brug for support til i dag?",
				"selectSupportChannel": "Vælg venligst en kanal, du kan kontakte vores supporttjeneste på.",
				"thatHelped": "Tak for den positive tilbagemelding, dette hjælper os med at give dig mere relevant hjælp i fremtiden. 😀"
			},
			"orders": {
				"orderManagement": {
					"title": "Ordreadministration",
					"createOrderPos": {
						"question": "Hvordan opretter jeg en ordre gennem Point of Sales?",
						"answer": "1. Åbn Point of Sales 2. Indtast artikelnavn, produktpris og antal. 3. Del det genererede betalingslink med din kunde via SMS eller e-mail eller ved at få kunden til at scanne den QR-kode, der vises på skærmen ved kassen. 4. Kunden kan nu gennemføre købet fra deres enhed."
					},
					"extendExpiryReservation": {
						"question": "Hvordan forlænger jeg udløbsdatoen for en reservation?",
						"answer": "1. Gå til Ordremanager, og vælg en eller flere ordrer, som du ønsker at forlænge udløbsdatoen for. 2. Klik på \"Ryk udløbsdatoen frem\". 3. Bekræft dit valg.\nOrdrer rykkes frem ifølge din butiks indstillinger for reservationstid (normalt 28 dage). Hvis en ordre er udløbet, kan den forlænges."
					},
					"howToCaptureOrders": {
						"question": "Hvordan aktiverer jeg ordrer?",
						"partially": {
							"question": "Jeg ønsker at aktivere en ordre delvist",
							"answer": "Hvis det kun er muligt at sende en del af en kundeordre, kan du aktivere ordren delvist efter ordrelinje eller beløb. Ordrelinjer til aktivering 1. Gå til ordren i Ordremanager, og vælg de varer, du aktuelt har på lager fra ordresiden. Husk at redigere ordrelinjer for at justere antallet, hvis der er behov for dette. 2. Klik på \"Aktivér\". Aktivér beløb: 1. Gå til ordren i Ordremanager. Du kan aktivere et beløb ved at trykke på knappen \"Aktivér alle\". Her skal du indtaste det ønskede beløb. 2. Klik på \"Aktivér\".\nHusk at gennemføre ordren, når det er muligt, enten ved at aktivere resterende varer/beløb, når de er klar til at blive sendt – eller ved at annullere den resterende ordre, hvis du ikke kan sende varerne."
						},
						"fully": {
							"question": "Jeg ønsker at aktivere den fulde ordre",
							"answer": "1. Gå til ordreadministratoren, og klik på Klarna-referencenummeret for at åbne ordren. 2. Aktivér den fulde ordre via et af følgende trin: - tryk på knappen \"Aktivér\", vælg ordrelinjerne og klik på \"Aktivér\". - tryk på \"Aktivér alle\", og indtast det fulde beløb for ordren. 3. Mens du aktiverer ordren, kan du downloade pakkesedlen for at inkludere den i din forsendelse.\nSørg for at aktivere ordrer inden deres udløbsdato (normalt 28 dage efter oprettelse). Du kan aktivere flere ordrer ved at vælge dem i ordrelisten og trykke på \"Fuld aktivering\", hvis du ønsker fremskynde processen."
						}
					},
					"canIUpdateCustomerInfo": {
						"question": "Kan jeg opdatere kundeoplysninger?",
						"answer": "Du kan aldrig ændre modtageren af en ordre. Men det er muligt at ændre forsendelses- og faktureringsadressen for ordrer, der ikke er aktiveret eller kun er delvist aktiveret. Hvis Klarna afviser den nye adresse, fordi den ikke er forbundet med kunden, kan faktureringsadressen dog stadig ændres."
					},
					"extendDueDate": {
						"question": "Hvordan forlænger jeg ordrens forfaldsdato?",
						"answer": "Du kan forlænge ordrens forfaldsdato ved at klikke på \"Ryk forfaldsdato frem\" for en aktiveret ordre. Forlængelse af forfaldsdatoen betyder opkrævning af det gebyr, der vil blive angivet, før du afslutter handlingen."
					},
					"refundOrder": {
						"question": "Hvordan refunderes en ordre?",
						"answer": "1. Gå til Ordremanager, og find den ordre, du ønsker at refundere. 2. Vælg en refunderingsmetode: vælg enten de varer, der skal refunderes, og tryk derefter på \"Refunder\", eller tryk på \"Refunder\" først for at indtaste det beløb, du vil refundere. 3. Det refunderede beløb vil blive fratrukket fra din næste udbetaling, og kunden vil modtage det inden for 5 hverdage.\nDette gælder kun for aktiverede ordrer. For ordrer, der ikke er aktiveret, skal du annullere dem, hvis du ikke sender varerne. Hvis kun en del af ordren blev aktiveret og sendt, skal du frigive den del, der ikke blev aktiveret."
					},
					"cancelOrder": {
						"question": "Hvordan annullerer jeg en ordre?",
						"answer": "1. Gå til Ordremanager, og find den ordre, du ønsker at annullere. 2. Klik på \"Annuller ordre\". Denne handling vil også informere din kunde om, at ordren ikke vil blive sendt.\nDu kan genskabe annullerede ordrer, hvis du har behov for det. Du kan ikke annullere aktiverede ordrer, men du kan refundere dem."
					},
					"unableToTakeActions": {
						"question": "Hvorfor kan jeg ikke udføre handlinger for en ordre?",
						"directPartner": {
							"question": "Jeg har en aftale direkte med Klarna",
							"answer": "Brugertilladelser fastlægger, hvad brugere kan gøre i partnerportalen. Der er to forskellige typer brugertilladelser: Administratorer – brugere med fuld adgang; for eksempel kan en administrator tilgå og redigere alle funktioner i partnerportalen. Afsnitsspecifik – brugere uden administratorrettigheder har adgang til ét eller flere specifikke afsnit i partnerportalen.\nDette kan være grunden til, at du ikke kan udføre handlinger for ordrer. Der er forskellige adgangsroller relateret til Ordremanager. Tal med din administrator for at bestemme hvilken adgangsrolle, der er bedst egnet til de handlinger, du ønsker at udføre."
						},
						"distributionPartner": {
							"question": "Jeg har en aftale direkte med Klarna",
							"answer": "Enhver handling, du ønsker at udføre for en ordre, kan kun udføres gennem den platform, der stilles til rådighed af distributionspartneren"
						}
					}
				},
				"orderModification": "Ændringer og problemer ifm. bestilling",
				"orderProcessing": "Ordrebehandling",
				"refundIssues": {
					"title": "Problemer med tilbagebetalinger",
					"refundedOrderWhenCustomerFunds": {
						"question": "Jeg har refunderet en bestilling. Hvornår vil min kunde modtage pengene?",
						"answer": "En kunde burde modtage sine penge inden for 5 hverdage, fra da bestillingen blev refunderet. Dette kan variere afhængigt af, hvilken betalingsmetode kunden har valgt. Hvis kunden har spørgsmål om dette, kan du henvise vedkommende til Klarnas kundesupport."
					}
				},
				"reservations": {
					"title": "Reservationer",
					"whyUnableToExtendReservation": {
						"question": "Hvorfor kan jeg ikke forlænge reservationstiden for en bestilling?",
						"answer": "Der er specifikke omstændigheder, hvor en forlængelse af udløbsdatoen for en bestilling ikke er mulig:\n- Hvis bestillingen allerede er udløbet, skal du genoprette den. For at gøre dette skal du gå til bestillingssiden, klikke “Kopiér” og vælge “Opret ny bestilling”.\n- Hvis bestillingen blev betalt med kort, skal kunden afgive en ny bestilling, da udløbsdatoer for disse bestillinger ikke kan forlænges.\n"
					}
				},
				"paymentsForMyOrders": {
					"title": "Betalinger for mine ordrer",
					"whenWillIGetPaid": {
						"question": "Hvornår bliver jeg betalt for en bestilling?",
						"answer": "For at modtage betaling for en bestilling skal du først aktivere den. Udbetalingsbeløbet efter fratrækning af gebyrer, returneringer og andre omkostninger vil blive sendt til din bankkonto baseret på din udbetalingsplan. Du kan gennemgå din udbetalingsplan i afsnittet “Afregninger”."
					},
					"refundOrderReceivedPayment": {
						"question": "Hvad sker der, hvis jeg refunderer en bestilling, som jeg allerede har modtaget penge for?",
						"answer": "Ethvert beløb, du refunderer, vil blive fratrukket din næste planlagte udbetaling."
					},
					"referenceNotShowUp": {
						"question": "Bestillingsnummer eller referencenummer vises ikke i rapporten.",
						"answer": "Hvis du ikke kan finde en betaling for en specifik bestilling:\n- Kontrollér bestillingens eller referencenummerets nøjagtighed.\n- Sørg for, at bestillingen er aktiveret.\n- Kontrollér, om bestillingen falder inden for rapportens aktiveringsperiode.\n- Bemærk, at bestillinger, der annulleres før afregning, ikke vil blive vist i rapporten.\n"
					},
					"gotPaidSpecificOrder": {
						"question": "Hvordan ser jeg, om jeg er blevet betalt for en specifik bestilling?",
						"answer": "I partnerportalens ordremanager kan du søge efter bestillingen for at sikre, at bestillingen og referencenummeret er nøjagtig. Under referencenummeret kan du se, om bestillingen er aktiveret. Hvis den er aktiveret, vil status være afventende eller udbetalt. For udbetalte bestillinger kan du klikke på “Se afregning” for at se den tilsvarende rapport."
					}
				}
			},
			"disputes": {
				"disputeManagement": "Tvisthåndtering",
				"contactInfoAndNotifications": "Kontaktoplysninger og meddelelsesindstillinger",
				"timingAndDeadlines": "Tidsramme og frister for tvister",
				"resolutionProcess": "Løsningsproces for tvister",
				"merchantDisputesApp": {
					"title": "Tvister-appen",
					"respondToDispute": {
						"question": "Hvordan ved jeg, om jeg skal svare på en tvistanmodning?",
						"answer": "Se afsnittet “Opgaver” på din partnerportals hjemmeside for at se, om der er tvister, der afventer et svar fra din side."
					},
					"overviewDisputesCustomer": {
						"question": "Hvor kan jeg finde et overblik over alle tvister, der er oprettet af kunder?",
						"answer": "Du kan finde alle oprettede tvister i afsnittet “Tvister” under siden “Alle tvister”, umiddelbart efter tvisten er blevet oprettet.\n\nBemærk, at tvister kan ses på siden “Alle tvister” i op til 180 dage efter, at de lukkes.\n"
					},
					"overviewDisputesResponse": {
						"question": "Hvor kan jeg finde et overblik over alle tvister, der afventer et svar?",
						"answer": "Du kan finde alle tvister, der kræver et svar, i afsnittet “Tvister” på siden “Åbne tvister” under “Svar afventes”."
					},
					"updateContactInformation": {
						"question": "Hvordan kan jeg opdatere min kontaktoplysninger for e-mail med tvistpåmindelser?",
						"answer": "1. Gå til afsnittet “Tvister”\n2. Klik ind på siden “Indstillinger for e-mail og tvister”\n3. Opdater dine kontaktoplysninger på fanebladet “E-mail og notifikationer”.\n\nBemærk, at du kan vælge forskellige kontaktoplysninger for “tvister”, “uautoriserede køb” og “højrisikoordrer”. Ydermere kan du indstille hyppigheden af notifikationer på fanebladet “Notifikationer”.\n"
					},
					"updateCustomerServiceOnKlarnaApp": {
						"question": "Hvordan kan jeg opdatere mine kundeserviceoplysninger i Klarna-appen?",
						"answer": "1. Gå til “Betalingsindstillinger”\n2. Klik på “Kundeserviceoplysninger”\n3. Vælg “Marked” (hvis du har markedsspecifik support)\n\nDu kan indtaste kundekontaktoplysningerne samt returneringspolitikken, der skal vises i Klarna-appen, så du skaber en gnidningsfri kundeoplevelse.\n\nBemærk, at du skal have fulde adgangsrettigheder for partnerportalen for at se disse oplysninger.\n"
					},
					"improveDisputesPerformance": {
						"question": "Hvordan kan jeg forbedre min håndtering af tvister?",
						"answer": "For at få tip og råd om, hvordan du kan forbedre din håndtering af tvister, kan du gå til “Forbedringsguide til returnering & indsigelser”. Du kan tilgå den via siden “Statistik” ved at trykke på knappen “Få indsigt” øverst til højre på siden.\n\nBemærk, at du skal have fulde adgangsrettigheder for partnerportalen for at se disse oplysninger.\n"
					},
					"overviewDisputesPerformance": {
						"question": "Hvor kan jeg se et overblik over min håndtering af tvister?",
						"answer": "For at se detaljeret statistik om tvister skal du gå til siden “Statistik” i appen Tvister. Dette afsnit giver et overblik over månedlige tvister, udfald, omkostninger og tab i forbindelse med tvister.\n\nBemærk, at du skal have fulde adgangsrettigheder for partnerportalen for at se disse oplysninger.\n"
					}
				},
				"disputesLifecycle": {
					"title": "Tvisters livscyklus",
					"whenCanSeeDisputes": {
						"question": "Hvornår kan jeg se tvister, der bliver oprettet af kunder?",
						"answer": "Du kan se alle oprettede tvister i appen Tvister på siden “Alle tvister” umiddelbart efter, de er blevet oprettet."
					},
					"howLongToResolve": {
						"question": "Hvor lang tid har jeg til at løse tvisten med kunden direkte?",
						"answer": "Du har 21 dage fra den dag, hvor en tvist bliver oprettet, til at løse den med kunden. Hvis du og kunden ikke har løst tvisten inden for 21 dage, efter den blev oprettet, vil Klarna skride ind for at støtte løsningsprocessen mellem begge parter."
					},
					"howMuchTimeToRefund": {
						"question": "Hvor lang tid har jeg til at refundere returneringer?",
						"answer": "Du har 21 dage fra returneringsdatoen til at behandle en refusion, før Klarna vil skride ind for at støtte løsningsprocessen mellem begge parter. Når det er sagt, er det altid bedst at refundere returneringer så snart, du modtager dem."
					},
					"howToResolve": {
						"question": "Hvordan kan jeg ordne tvister og returneringer?",
						"answer": "Der er mange måder at ordne en tvist eller returnering på.\n\n- Du bør behandle returneringen eller refusionen, så snart du modtager den, eller I er blevet enige om en løsning.\n- Indstil en tilbagebetalingstærskel under “Indstillinger for e-mail og tvister”\n- Vær proaktiv ved at markere “Alle tvister”\n- Accepter, at der kan forekomme tab\n"
					},
					"whenToRespond": {
						"question": "Hvornår skal jeg aktivt forsvare/svare på tvister?",
						"answer": "Hvis du og kunden ikke selv kan nå frem til en løsning inden for den givne tid (21 dage), vil Klarna skride ind for at støtte løsningsprocessen mellem begge parter. Hvis tvisten er gyldig, vil Klarnas supportteam kontakte dig via appen Tvister med en anmodning om yderligere oplysninger.\n\nDet er på dette tidspunkt, du vil blive bedt om at forsvare/svare på en tvist.\n"
					},
					"howMuchTimeToRespond": {
						"question": "Hvor lang tid har jeg til at svare på en tvistanmodning?",
						"answer": "Fristen for den første anmodning er 14 dage for tvister af alle årsager med undtagelse af uautoriserede køb (7 dage). For hver opfølgningsanmodning*, der sendes til dig (med et maksimum på 5 anmodninger), har du en frist på 7 dage. Hvis du har brug for at tilføje noget til dit svar, har du 60 minutter fra indsendelsen til eksempelvis at tilføje oplysninger eller vedhæftninger. Når fristen er udløbet, vil du ikke længere kunne forsvare sagen.\n\n*Særlige regler kan gælde afhængigt af partnerskabsaftalen.\n"
					},
					"howLongToSeeDispute": {
						"question": "Hvor længe kan jeg se en tvist i appen Tvister, efter den er løst?",
						"answer": "En tvist er tilgængelig på siden “Alle tvister” i op til 180 dage efter, at den lukkes."
					},
					"whenToGetCharged": {
						"question": "Hvornår vil jeg blive pålagt et tvistgebyr?",
						"answer": "Du kan blive pålagt et tvistgebyr, hvis:\n- du står til ansvar for tvisten\n- du undlod at svare før fristens udløb\n- du udstedte en refusion, efter Klarna skred ind for at yde støtte i løsningsprocessen.\n\nGebyret påføres med tilbagevirkende kraft, efter tvistens udfald er afgjort. Du bliver ikke pålagt et gebyr, hvis tvisten løses til din fordel, eller hvis den bliver tilbagetrukket eller afvist.\n"
					}
				},
				"disputesHandling": {
					"title": "Handling ifm. tvister",
					"whatIsDispute": {
						"question": "Hvilke oplysninger vil jeg modtage med en tvistanmodning?",
						"answer": "Tvistanmodningen omfatter:\n\n- Årsag: Tvistens kategori, f.eks., returneringer, varer ikke modtaget.\n- Forhandler: Relevant hvis flere forhandler-ID’er er registreret hos Klarna.\n- Dato for tvist: Da kunden oprettede tvisten.\n- Frist: Fristen du har til at svare for at forsvare din sag.\n- Omstridte produkter: De omstridte varer.\n- Ordredetaljer: Omfatter “forhandlerreference 1 og 2”, “ordredato” og “omstridt beløb”.\n"
					},
					"howManyTimesDefend": {
						"question": "Hvor mange gange kan jeg forsvare en tvistanmodning?",
						"answer": "Du får et maksimum på fem anmodninger til at forsvare din tvist, men som regel kræver det ikke mere end én eller to."
					},
					"doIReceiveNotification": {
						"question": "Vil jeg blive underrettet, når jeg skal svare på tvister?",
						"answer": "Hvis du har en afventende tvistanmodning, vil den blive vist flere steder:\n\n- I afsnittet “Opgaver” på din partnerportals hjemmeside.\n- Som en underretning i afsnittet “Tvister” på siden “Åbne tvister” med angivelse af antallet af tvister, der afventer svar.\n\nDu kan også vælge at modtage en e-mail for hver tvist ved at justere dine indstillinger i “Indstillinger for e-mail og tvister” til “E-mailmeddelelse for hver tvist”.\n"
					},
					"howToAvoidDisputesFees": {
						"question": "Hvordan kan jeg undgå tvistgebyrer?",
						"answer": "Gør følgende for at undgå tvistgebyrer:\n- Du bør behandle returneringer eller refusioner omgående.\n- Indstil en tilbagebetalingstærskel under “Indstillinger for e-mail og tvister”.\n- Kontrollér “All tvister” regelmæssigt.\n- Svar på tvister med relevante oplysninger.\n- Svar, før fristen udløber.\n"
					}
				}
			},
			"settlements": {
				"payoutNotFound": {
					"title": "Udbetaling for specifik bestilling kunne ikke findes",
					"orderNumberNotFound": {
						"question": "Bestillingsnummer eller referencenummer vises ikke i rapporten",
						"answer": "Hvis du ikke kan finde en betaling for en specifik bestilling:\n- Kontrollér bestillingens eller referencenummerets nøjagtighed.\n- Sørg for, at bestillingen er aktiveret.\n- Kontrollér, om bestillingen falder inden for rapportens aktiveringsperiode.\n- Bemærk, at bestillinger, der annulleres før afregning, ikke vil blive vist i rapporten.\n"
					},
					"checkIfPaidForOrder": {
						"question": "Hvordan ser jeg, om jeg er blevet betalt for en specifik bestilling?",
						"answer": "I partnerportalens ordremanager kan du søge efter bestillingen for at sikre, at bestillingen og referencenummeret er nøjagtig. Under referencenummeret kan du se, om bestillingen er aktiveret. Hvis den er aktiveret, vil status være afventende eller udbetalt. For udbetalte bestillinger kan du klikke på “Se afregning” for at se den tilsvarende rapport."
					}
				},
				"payouts": {
					"title": "Udbetalinger",
					"whenIsNext": {
						"question": "Hvornår er min næste udbetaling?",
						"answer": null
					},
					"whyCalculationNotMatch": {
						"question": "Hvorfor stemmer mine beregning ikke overens med de modtagne udbetalinger?",
						"answer": "Ifølge forhandlerkontrakten fratrækkes gebyrer som transaktionsgebyrer og gebyrer for sen returnering. Derfor kan dine beregning være anderledes end Klarnas afregninger. For at se klart definerede fradrag anbefaler vi, at du åbner PDF-versionen af afregningsrapporten."
					},
					"whenGetPaid": {
						"question": "Hvornår bliver jeg betalt?",
						"answer": null
					},
					"missingPayout": {
						"question": "Jeg mangler min udbetaling",
						"answer": "Før du kontakter vores supportkanal. Overvej, om manglende udbetalinger kan være forårsaget af følgende:\n\n- Klarna sender betalingen i løbet af samme dag, som rapporten oprettes. Det kan stadig tage et par dage, før pengene dukker op på din bankkonto.\n- Du har ikke aktiveret nogen bestillinger for denne aktiveringsperiode.\n- Du har for nyligt opdateret dine bankkontooplysninger hos Klarna, hvilket kan have forårsaget, at betalingen er overført til din tidligere bankkonto. Dette kan forekomme, hvis ændringen ikke behandles, før betalingen udføres.\n- Det ordrenummer eller referencenummer, du søger efter, er forkert. Sørg for at kontrollere ordre- eller referencenummeret.\n- Dine betalinger er tilbageholdt af Klarna. Du skulle være blevet separat informeret om tilbageholdelsen gennem en e-mail. Disse e-mail sendes som regel til en administrator\n"
					},
					"howIsPayoutCalculated": {
						"question": "Hvordan bliver min udbetaling beregnet?",
						"answer": "Udbetalingen beregnes fra bestillinger, der aktiveres inden for aktiveringsperioden, minus gebyrer, returneringer og andre omkostninger. En afregningsrapport giver en detaljeret oversigt over disse beløb.\nHvis refusioner overstiger salg i en aktiveringsperiode, vil det overskydende beløb blive fratrukket den næste udbetaling.\n"
					}
				},
				"howPayoutsWork": {
					"question": "Hvordan fungerer udbetalingerne?",
					"answer": "Afsnittet “Afregninger” giver et overblik over, hvordan udbetalinger fungerer, inklusive betalingsplaner, aktiveringsperioder og udbetalingsdatoer. Du kan hente transaktionsrapporter for hver udbetaling i PDF- eller CSV-format og se eller hente detaljer for specifikke betalinger ved at klikke på deres betalingsreferencer."
				},
				"payoutSchedule": {
					"title": "Udbetalingsplan",
					"whyDelay": {
						"question": "Hvorfor er der en udbetalingsforsinkelse?",
						"answer": "Det primære formål er at forhindre, at penge for fakturaer sendes frem og tilbage mellem Klarna og dig, og også at dække tilfælde, hvor du ville refundere en aktiveret bestillinger."
					},
					"changePayoutDelay": {
						"question": "Jeg ønsker at ændre min udbetalingsforsinkelse",
						"answer": "Vi har interne kontroller såvel som generelle kriterier, der skal opfyldes. Disse generelle kriterier omfatter:\n- Du skal være fuldt indrulleret hos Klarna\n- Din første ordredato overskrider 100 dage\n- Din forretning skal være ældre end 6 måneder.\n\nKlarna skal også have oplysninger fra en administrator om følgende:\n- Den ønskede nye betalingsforsinkelse og grunden til, at den ændrede betalingsforsinkelse er nødvendig\n- Hvilken forhandler-ID ændringen angår\nYderligere kriterier kan gælde, og beslutningen træffes på individuel basis.\n"
					}
				},
				"generatingReports": {
					"title": "Oprettelse af rapporter",
					"howToSeeReports": {
						"question": "Hvordan jeg se rapporterne for specifikke perioder?",
						"answer": "Du kan tilpasse dine rapporter til at dække specifikke perioder vha. afsnittet “Tilpassede rapporter” i afsnittet “Afregninger”."
					},
					"convertCsvToExcel2": {
						"answer": "Følg disse skridt\n1. Åbn Excel, klik på “Fil” > “Nyt regneark”.\n"
					},
					"convertCsvToExcel": {
						"question": "Hvordan konverterer jeg en CSV-rapport til et Excel-ark?",
						"answer": "1. Åbn Excel, klik på “Fil” > “Nyt regneark”.\n2. Klik på fanen “Data”, og vælg “Fra tekst”.\n3. Vælg den ønskede .CSV-fil, og klik på “Hent data”.\n4. Vælg “Med skilletegn” i tekstimportguiden, og klik så på “Næste”.\n5. Vælg “Semikolon” under “Skilletegn”, og indstil “Tekstkvalifikation” til “Ingen”. Klik på “Afslut”.\n6. Klik på “OK” i dialogboksen “Importér data” for at placere dataene startende i celle A1.\n\nBemærk: CSV-rapporter i Portal for butikker bruger et semikolon (;) som skilletegn. Overvej at kontrollere din regnearkssoftware for at lære, hvordan man justerer standardskilletegnet.\n"
					}
				},
				"payoutAndFeeCalculation": {
					"title": "Beregning af udbetalinger og gebyrer",
					"howAreFeesCalculated": {
						"question": "Hvordan beregnes gebyrer?",
						"answer": "Gebyrer, der fratrækkes Klarna-udbetalinger, er skitseret i din forhandlerkontrakt, med yderligere gebyrer såsom dem for sene returneringer detaljeret i afsnittet “Gebyr” i dine udbetalings-/afregningsrapporter.\n\nGør følgende for at tilpasse gebyrbeskrivelser i din rapporter:\n1. Naviger til “Afregningsrapporter” > “Konfigurer rapporter” > “Tilføj ny konfiguration”.\n2. For CSV-rapporter skal du vælge “Detaljeret transaktionstype” under “Transaktionsliste”.\n3. For PDF-rapporter skal du vælge “Inkluder ordredetaljer” i indstillingerne for layout og indhold.\n\nSørg for at justere indstillingerne for hvert forhandler-ID og hver kanal (SFTP, Portal for butikker, API) separat.\n"
					},
					"howIsPayoutCalculated": {
						"question": "Hvordan bliver min udbetaling beregnet?",
						"answer": "Udbetalingen beregnes fra bestillinger, der aktiveres inden for aktiveringsperioden, minus gebyrer, returneringer og andre omkostninger. En afregningsrapport giver en detaljeret oversigt over disse beløb.\n\nHvis refusioner overstiger salg i en aktiveringsperiode, vil det overskydende beløb blive fratrukket den næste udbetaling.\n"
					}
				}
			},
			"options": {
				"payout": "Udbetaling",
				"orders": "Ordrer",
				"settlements": "Afregninger",
				"disputes": "Tvister",
				"otherIssue": "Andre problemer",
				"yes": "Ja",
				"no": "Nej",
				"partially": "Delvist",
				"iNeedMoreHelp": "Jeg har brug for mere hjælp",
				"thatHelped": "Det hjalp",
				"contactSupportChannel": "Kontakt supportkanaler"
			},
			"button": {
				"label": "Få hjælp"
			},
			"contact": {
				"chat": "Chat med en medarbejder",
				"ticket": "Indgiv en anmodning",
				"times": {
					"minutes": "Ventetid: {time} minut(ter)",
					"hours": "Ventetid: {time} time(r)",
					"days": "Ventetid: {time} dag(e)"
				}
			}
		}
	}
};