module.exports = {
	"pt": {
		"help-tool": {
			"button-text": "Ajuda",
			"support-button": "Obter assistência",
			"support-page": {
				"create-ticket": {
					"title": "Obter assistência",
					"instructions": "Envie um pedido abaixo e entraremos em contacto consigo dentro de 1 dia útil.",
					"related-content": {
						"title": "Estes podem ajudar a resolver o seu problema"
					},
					"fields": {
						"mid": {
							"label": "Selecionar loja",
							"validation": "Loja necessária"
						},
						"phoneNumber": {
							"label": "Número de telefone (opcional)"
						},
						"topic": {
							"question": "Com que se relaciona a sua pergunta?",
							"label": "Selecionar um tópico",
							"validation": "Tópico necessário",
							"options": {
								"orders": "Encomendas",
								"settlements": "Liquidações",
								"agreements": "Acordos",
								"others": "Outro"
							}
						},
						"subject": {
							"label": "Assunto",
							"validation": "Assunto obrigatório"
						},
						"description": {
							"label": "Mensagem",
							"validation": "Descrição obrigatória"
						},
						"attachment": {
							"label": "Adicionar anexos",
							"button": "Carregar",
							"info": "Adicionar capturas de ecrã irá ajudar-nos a resolver o problema mais rapidamente.",
							"supportedFileTypes": "Tipos de ficheiros suportados: .png, .jpg, .pdf",
							"error": "O tamanho total dos ficheiros não pode exceder {maxSize} MB"
						}
					},
					"submit": "Enviar pedido",
					"service-unavailable-error": "Ups! Ocorreu um erro, tente novamente dentro de alguns minutos. Se isto continuar, {link} para obter ajuda.",
					"generic-error": "Não foi possível processar o seu pedido. Os nossos sistemas não estão a comunicar uns com os outros neste momento. Tente recarregar ou volte mais tarde. Se isto continuar, {link} para obter ajuda.",
					"contact-link": "contacte-nos"
				},
				"view-ticket": {
					"title": "Tudo feito!",
					"details": "Recebemos o seu pedido e iremos responder-lhe em breve dentro de 1 dia útil. Aqui estão os seus detalhes:",
					"ticket": "Pedido n.º {ticketId}",
					"email": "E-mail: {email}",
					"status": "Pode seguir o estado na nossa página de Apoio ao cliente.",
					"track": "Seguir o estado"
				}
			},
			"header": {
				"homeLink": "Repor"
			},
			"home": {
				"greeting": "Olá, {givenName}! Como podemos ajudá-lo(a)?",
				"topics": "Em que precisa de ajuda?"
			},
			"topics": {
				"expandButton": "Mais"
			},
			"feedback": {
				"title": "Isto foi útil?",
				"optionYes": "Sim, isto foi útil",
				"optionNo": "Não, preciso de mais ajuda",
				"positiveFeedback": "Obrigado pelo feedback positivo; isto ajuda-nos a fornecer-lhe mais artigos relevantes no futuro."
			},
			"freeTextFeedback": {
				"title": "Ajude-nos a melhorar",
				"placeholder": "Porque não achou este conteúdo útil?",
				"submitButton": "Enviar",
				"feedbackMessage": "Obrigado pelo feedback; isto ajuda-nos a fornecer-lhe mais artigos relevantes no futuro."
			},
			"relatedContent": {
				"title": "Conteúdo relacionado"
			},
			"search": {
				"buttonLabel": "Procurar",
				"input": "Procurar artigos…",
				"loading": "A carregar…",
				"results": {
					"contact": "Não consegue encontrar a ajuda de que precisa?",
					"empty": "Lamentamos, mas não foram encontrados resultados para a sua questão. Experimente pesquisar com um termo diferente.",
					"singleTopicFound": "Encontrámos 1 tópico",
					"multipleTopicsFound": "Encontrámos {count} tópicos",
					"singleArticleFound": "Encontrámos 1 artigo",
					"multipleArticlesFound": "Encontrámos {count} artigos"
				}
			},
			"contact": {
				"title": "Contacte-nos",
				"channelsIntro": "Contacte-nos para o(a) podermos ajudar.",
				"businessDetailsTitle": "Os dados da sua empresa",
				"businessDetailsName": "Nome da empresa",
				"businessDetailsEmail": "E-mail",
				"businessDetailsMid": "ID do comerciante",
				"phone": {
					"title": "Telefone-nos",
					"paragraph": "Pode telefonar-nos durante o horário de funcionamento nos dias úteis. Se estiver na Suécia ou no Reino Unido, também pode telefonar-nos ao fim de semana entre as 10:00 e as 16:00."
				},
				"email": {
					"title": "Enviar-nos um e-mail",
					"paragraph": "Envie-nos um e-mail e entraremos em contacto consigo assim que pudermos."
				}
			},
			"errorBoundary": {
				"title": "Ocorreu um erro",
				"message": "Lamentamos imenso, mas ocorreu um erro. Pode clicar no botão \"Voltar\" abaixo para voltar à ferramenta de ajuda ou no botão \"Obter assistência\" para nos contactar.",
				"resetButton": "Voltar",
				"errorId": "ID do erro: {errorId}"
			},
			"tour": {
				"prev": "Anterior",
				"next": "Seguinte",
				"done": "Concluído"
			},
			"notAvailableMobile": {
				"title": "Não disponível",
				"buttonBack": "Voltar",
				"paragraph": "Este conteúdo não está disponível no seu dispositivo. Experimente abrir num dispositivo com ecrã maior, tal como um computador de secretária."
			},
			"shareButton": {
				"title": {
					"article": "Partilhar uma ligação avançada para este artigo",
					"topic": "Partilhar uma ligação avançada para este tópico",
					"tour": "Partilhar uma ligação avançada para esta visita"
				},
				"radioItemHome": "Página inicial da Aplicação",
				"descriptionHome": "Abre o artigo de ajuda na Página inicial do portal do comerciante",
				"radioItemCurrent": "Página atual",
				"descriptionCurrent": "Abre o artigo na página atual"
			},
			"marketSelector": {
				"whichMarketToContact": "Que mercado gostaria de contactar?"
			}
		},
		"orientation": {
			"title": "Descubra como…"
		},
		"helpbot": {
			"questions": {
				"whatDoYouNeedHelpWith": "Olá! Com que precisa de apoio hoje?",
				"selectSupportChannel": "Selecione um canal para contactar o nosso serviço de apoio.",
				"thatHelped": "Obrigado pelo feedback positivo; isto ajuda-nos a fornecer-lhe mais ajuda relevante no futuro. 😀"
			},
			"orders": {
				"orderManagement": {
					"title": "Gestão de encomendas",
					"createOrderPos": {
						"question": "Como crio uma encomenda através do Point of Sales?",
						"answer": "1. Abra o Point of Sales 2. Insira o nome do artigo, o preço e a quantidade do produto. 3. Partilhe a ligação de pagamento gerada com o seu cliente por SMS, por e-mail ou solicitando ao cliente que leia o código QR exibido no ecrã de finalização de compra. 4. Agora, o cliente pode concluir a compra a partir do seu dispositivo."
					},
					"extendExpiryReservation": {
						"question": "Como posso prolongar a data de validade de uma reserva?",
						"answer": "1. Vá ao Gestor de encomendas e selecione uma ou mais encomendas para as quais deseja prolongar a data de validade. 2. Clique em Prolongar a data de validade. 3. Confirme a sua escolha.\nAs encomendas são prolongadas de acordo com as definições de tempo de reserva para a sua loja (normalmente 28 dias). Se a validade de uma encomenda tiver expirado, já não pode ser prolongada.\""
					},
					"howToCaptureOrders": {
						"question": "Como capturo as encomendas?",
						"partially": {
							"question": "Quero capturar parcialmente uma encomenda",
							"answer": "Se só for possível enviar parte de uma encomenda de um cliente, pode capturar parcialmente a encomenda por linhas de encomenda ou valor. Capturar linhas de encomenda 1. Vá para a encomenda no Gestor de encomendas e selecione os itens que tem atualmente em stock a partir da página da encomenda. Lembre-se de editar as linhas da encomenda para ajustar as quantidades, se necessário. 2. Clique em Capturar. Capturar valor: 1. Vá para a encomenda no Gestor de encomendas. Pode capturar um valor clicando no botão \"Capturar tudo\". Aqui pode inserir o valor desejado. 2. Clique em Capturar.\nLembre-se de concluir a encomenda quando possível, seja capturando os restantes artigos/valor assim que estiverem disponíveis para serem enviados ou cancelando o resto da encomenda se não conseguir enviar os artigos."
						},
						"fully": {
							"question": "Quero capturar encomendas completas",
							"answer": "1. Vá a Gestor de encomendas e clique no número de referência Klarna para abrir a encomenda. 2. Capture a encomenda completa através de um dos passos seguintes: - Clique no botão \"Capturar\" - Selecione as linhas de encomenda e clique em \"Capturar\". - Clique em \"Capturar tudo\" e insira o valor total da encomenda. 3. Enquanto captura a encomenda, pode transferir o recibo de entrega para o incluir no seu envio.\nCertifique-se de que captura as encomendas antes que a sua data de validade expire (normalmente 28 dias após a criação da encomenda). Pode capturar várias encomendas selecionando-as na lista de encomendas e clicando em \"Capturar totalmente\" se desejar acelerar as coisas."
						}
					},
					"canIUpdateCustomerInfo": {
						"question": "Posso atualizar as informações para os clientes?",
						"answer": "Nunca pode alterar o destinatário de uma encomenda. Contudo, é possível alterar o endereço de envio e o endereço de faturação para encomendas não capturadas ou capturadas parcialmente. No entanto, se a Klarna rejeitar o novo endereço por não estar associado ao cliente, o endereço de faturação ainda pode ser alterado."
					},
					"extendDueDate": {
						"question": "Como posso prolongar a data de vencimento de uma encomenda?",
						"answer": "Pode prolongar a data de vencimento de uma encomenda clicando em \"Prolongar a data de vencimento\" numa encomenda capturada. O prolongamento da data de vencimento de uma encomenda está sujeito a uma taxa, que será especificada antes de concluir a ação."
					},
					"refundOrder": {
						"question": "Como posso reembolsar uma encomenda?",
						"answer": "1. Vá ao Gestor de encomendas e localize a encomenda que deseja reembolsar. 2. Escolha o método de reembolso: selecione itens a reembolsar e clique em \"Reembolsar\" ou clique em \"Reembolsar\" primeiro para inserir o valor que quer reembolsar. 3. O valor reembolsado será deduzido do seu próximo pagamento e o cliente irá recebê-lo no prazo de 5 dias úteis.\nIsto aplica-se apenas a encomendas Capturadas. Para encomendas não capturadas, cancele-as se não for enviar nenhum dos artigos. Se apenas uma parte da encomenda foi capturada e enviada, liberte a parte não capturada."
					},
					"cancelOrder": {
						"question": "Como cancelo uma encomenda?",
						"answer": "1. Vá ao Gestor de encomendas e localize a encomenda que deseja cancelar. 2. Clique em \"Cancelar encomenda\". Esta ação também informa o seu cliente de que a encomenda não será enviada.\nPode recriar encomendas canceladas, se precisar. Não pode cancelar encomendas capturadas, mas pode reembolsá-las."
					},
					"unableToTakeActions": {
						"question": "Porque não consigo realizar ações numa encomenda?",
						"directPartner": {
							"question": "Tenho um contrato diretamente com a Klarna",
							"answer": "As permissões de utilizador determinam o que os utilizadores podem fazer no Portal de Parceiro. Existem dois tipos diferentes de permissões do utilizador: Administrador (admin) - utilizadores com acesso total; por exemplo, um administrador pode aceder e editar todas as funcionalidades no Portal de Parceiro. Específico da secção - utilizadores não administradores que têm acesso a uma ou mais secções específicas no Portal de Parceiro.\nEste pode ser o motivo pelo qual não pode realizar ações em encomendas. Existem diferentes funções de acesso relacionadas com o Gestor de encomendas. Fale com o seu administrador para determinar que função de acesso é mais adequada para as ações que tem de realizar."
						},
						"distributionPartner": {
							"question": "Tenho um contrato diretamente com a Klarna",
							"answer": "Quaisquer ações que gostaria de realizar numa encomenda só podem ser realizadas através da plataforma fornecida pelo Parceiro de Distribuição"
						}
					}
				},
				"orderModification": "Alteração da encomenda e problemas",
				"orderProcessing": "Processamento da encomenda",
				"refundIssues": {
					"title": "Problemas com reembolsos",
					"refundedOrderWhenCustomerFunds": {
						"question": "Reembolsei uma encomenda. Quando é que o cliente recebe os fundos?",
						"answer": "O cliente deve receber os fundos no prazo de 5 dias úteis a partir de quando a encomenda foi reembolsada. Isto pode variar dependendo da forma de pagamento que o cliente escolheu. Se o cliente tiver perguntas sobre isto, encaminhe-o para o Apoio ao Cliente da Klarna."
					}
				},
				"reservations": {
					"title": "Reservas",
					"whyUnableToExtendReservation": {
						"question": "Porque não posso prolongar o tempo de reserva de uma encomenda?",
						"answer": "Existem circunstâncias específicas em que não é possível prolongar a data de validade de uma encomenda:\n- Se a encomenda já tiver expirado, precisa de a criar novamente. Para tal, vá à página da encomenda, clique em Copiar e seleciona Criar nova encomenda.\n- Se a encomenda foi paga com cartão, o cliente tem de efetuar uma nova encomenda, pois as datas de validade destas encomendas não podem ser prolongadas.\n"
					}
				},
				"paymentsForMyOrders": {
					"title": "Pagamentos pelas minhas encomendas",
					"whenWillIGetPaid": {
						"question": "Quando recebo o pagamento de uma encomenda?",
						"answer": "Para receber o pagamento de uma encomenda, primeiro tem de a capturar. O valor pago, após a dedução das taxas, devoluções e outras despesas, será devolvido para a sua conta bancária com base no seu agendamento de pagamento. Pode rever as configurações de pagamento na secção Liquidações."
					},
					"refundOrderReceivedPayment": {
						"question": "O que acontece se reembolsar uma encomenda para a qual já recebi fundos?",
						"answer": "Qualquer valor que reembolse será deduzido do seu próximo pagamento agendado."
					},
					"referenceNotShowUp": {
						"question": "O número da encomenda ou o número de referência não aparece no relatório.",
						"answer": "Se não conseguir encontrar um pagamento para uma encomenda específica:\n- Verifique a precisão da encomenda ou o número de referência.\n- Certifique-se de que a encomenda é capturada.\n- Verifique se a encomenda está no período de captura do relatório.\n- Tenha em atenção que as encomendas canceladas antes da liquidação não aparecerão no relatório.\n"
					},
					"gotPaidSpecificOrder": {
						"question": "Como posso ver se me pagaram uma encomenda específica?",
						"answer": "No gestor de encomendas do Portal do Comerciante, pesquise a encomenda, assegurando que a encomenda ou o número de referência estão corretos. Abaixo do número de referência, verifique se a encomenda foi capturada. Se foi capturada, o seu estado aparecerá como pendente ou pago. Para encomendas pagas, clique em Ver liquidação para ver o relatório correspondente."
					}
				}
			},
			"disputes": {
				"disputeManagement": "Gestão de disputas",
				"contactInfoAndNotifications": "Informações de contacto e definições de notificação",
				"timingAndDeadlines": "Tempo e prazos das disputas",
				"resolutionProcess": "Processo de resolução das disputas",
				"merchantDisputesApp": {
					"title": "Aplicação de disputas do comerciante",
					"respondToDispute": {
						"question": "Como sei se tenho de responder a um pedido de disputa?",
						"answer": "Consulte a secção Para fazer na Página inicial do seu Portal do Parceiro para ver se há disputas que precisam da sua resposta."
					},
					"overviewDisputesCustomer": {
						"question": "Onde posso encontrar uma visão geral de todas as disputas levantadas por clientes?",
						"answer": "Pode encontrar todas as disputas levantadas na secção de Disputas, na página Todas as Disputas, imediatamente depois de a disputa ser levantada.\n\nTenha em atenção que as disputas estão disponíveis na página Todas as Disputas durante até 180 dias após o respetivo encerramento.\n"
					},
					"overviewDisputesResponse": {
						"question": "Onde posso encontrar uma visão geral de todas as disputas que requerem uma resposta?",
						"answer": "Pode encontrar todas as disputas que precisam de uma resposta na secção Disputas na página Abrir disputas, em Resposta necessária."
					},
					"updateContactInformation": {
						"question": "Como posso atualizar as minhas informações de contacto para e-mails de lembrete de disputas?",
						"answer": "1. Vá à secção de Disputas\n2. Clique na página Definições de e-mail e disputas\n3. Atualize as suas informações de contacto no separador E-mail e notificações.\n\nTenha em atenção que pode definir diferentes informações de contacto para disputas, compras não autorizadas e encomendas de alto risco. Além disso, pode definir a frequência de notificações no separador Notificações.\n"
					},
					"updateCustomerServiceOnKlarnaApp": {
						"question": "Como posso atualizar as minhas informações de Apoio a Clientes na Aplicação Klarna?",
						"answer": "1. Vá a Definições de pagamento\n2. Clique em Informações de Apoio a Clientes\n3. Selecione Mercado (se tiver apoio específico do mercado)\n\nPode inserir as informações de Contacto de clientes, bem como a política de devoluções a serem apresentadas na Aplicação Klarna, para uma experiência de cliente mais tranquila.\n\nTenha em atenção que precisa de direitos de acesso total ao Portal do Comerciante para ver esta informação.\n"
					},
					"improveDisputesPerformance": {
						"question": "Como posso melhorar o meu desempenho em disputas?",
						"answer": "Para receber dicas e truques sobre como melhorar o seu desempenho em disputas, vá a Guia de melhoria de devoluções e disputas. Pode aceder através da página de Estatísticas, clicando no botão Obter análise no canto superior direito da página.\n\nTenha em atenção que precisa de direitos de acesso total ao Portal do Comerciante para ver esta informação.\n"
					},
					"overviewDisputesPerformance": {
						"question": "Onde posso ver uma visão geral do meu desempenho em disputas?",
						"answer": "Para ver estatísticas detalhadas sobre as disputas, vá à página Estatísticas na aplicação de Disputas. Esta secção fornece uma visão geral de disputas mensais, resultados, custos e perdas relacionadas com as disputas.\n\nTenha em atenção que precisa de direitos de acesso total ao Portal do Comerciante para ver esta informação.\n"
					}
				},
				"disputesLifecycle": {
					"title": "Ciclo de vida das disputas",
					"whenCanSeeDisputes": {
						"question": "Quando posso ver as disputas a serem levantadas pelos clientes?",
						"answer": "Pode ver todas as disputas a serem levantadas na aplicação de Disputas na página Todas as disputas imediatamente depois de terem sido levantadas."
					},
					"howLongToResolve": {
						"question": "Quanto tempo tenho para resolver a disputa diretamente com o cliente?",
						"answer": "A partir do dia em que a disputa foi levantada, tem 21 dias para a resolver com o cliente. Se o comerciante e o cliente não resolverem a disputa no prazo de 21 dias após ter sido levantada, a Klarna intervirá para apoiar o processo de resolução entre ambas as partes."
					},
					"howMuchTimeToRefund": {
						"question": "Quanto tempo tenho para reembolsar devoluções?",
						"answer": "Tem 21 dias a partir da data da devolução para processar um reembolso, antes de a Klarna intervir para apoiar o processo de resolução entre ambas as partes. Assim sendo, é sempre melhor reembolsar as devoluções assim que as receber."
					},
					"howToResolve": {
						"question": "Como posso resolver disputas e devoluções?",
						"answer": "Existem várias maneiras de resolver uma disputa ou uma devolução.\n\n- Processe a devolução ou o reembolso assim que o receber / concordar com uma solução.\n- Defina um Limite de estorno em Definições de e-mail e disputas\n- Seja proativo/a verificando Todas as disputas\n- Aceitar perda\n"
					},
					"whenToRespond": {
						"question": "- Quando tenho de defender ativamente/responder a disputas?",
						"answer": "Se o comerciante e o cliente não conseguirem chegar a uma solução dentro do tempo estabelecido (21 dias), a Klarna intervirá para apoiar o processo de resolução entre ambas as partes. Se a disputa for válida, a equipa de apoio da Klarna entrará em contacto consigo através da aplicação de Disputas com um pedido de mais informações.\n\nÉ neste ponto que lhe será pedido para defender / responder a uma disputa.\n"
					},
					"howMuchTimeToRespond": {
						"question": "Quanto tempo tenho para responder a um pedido de disputa?",
						"answer": "O prazo para o primeiro pedido é de 14 dias para todos os motivos de disputa, com exceção de Compras não autorizadas (7 dias). Para cada pedido de seguimento* enviado para si (com um máximo de 5 pedidos) tem um prazo de 7 dias. Caso precise de acrescentar algo à sua resposta, tem 60 minutos a partir do envio; pode acrescentar por exemplo informações ou anexos. Após o prazo expirar, deixará de poder defender o caso.\n\n*Podem aplicar-se regras especiais dependendo do acordo de parceria.\n"
					},
					"howLongToSeeDispute": {
						"question": "Durante quanto tempo posso ver uma disputa na aplicação de Disputas depois de ter sido resolvida?",
						"answer": "Uma disputa fica disponível na página Todas as Disputas durante até 180 dias após o respetivo encerramento."
					},
					"whenToGetCharged": {
						"question": "Quando me é cobrada uma taxa de disputa?",
						"answer": "Pode incorrer numa taxa de disputa se:\n- Tiver culpa da disputa.\n- Não responder antes do final do prazo.\n- Emitir um reembolso depois de a Klarna intervir para apoiar o processo de resolução.\n\nA taxa é aplicada retroativamente depois de o resultado da disputa ser determinado. Não lhe será cobrada uma taxa se a disputa for resolvida a seu favor ou se for cancelada ou rejeitada.\n"
					}
				},
				"disputesHandling": {
					"title": "Tratamento de disputas",
					"whatIsDispute": {
						"question": "Que informações recebo com um pedido de disputa?",
						"answer": "O pedido de disputa inclui:\n\n- Motivo: Categoria da disputa, por ex., devoluções, mercadorias não recebidas.\n- Comerciante: Relevante se houver várias ID de comerciante (MID) registadas na Klarna.\n- Data da disputa: Quando o cliente levantou a disputa.\n- Prazo: O fim do prazo até ao qual tem de responder para defender o caso.\n- Produtos disputados: Item(ns) a serem disputados.\n- Detalhes da encomenda: Inclui Referência de comerciante 1 e 2, Data da encomenda, Valor da disputa.\n"
					},
					"howManyTimesDefend": {
						"question": "Quantas vezes posso defender um pedido de disputa?",
						"answer": "Tem um máximo de cinco (5) pedidos para defender a sua disputa, mas muitas vezes não é preciso mais do que um ou dois."
					},
					"doIReceiveNotification": {
						"question": "Recebo notificações quando tiver de responder às disputas?",
						"answer": "Se tiver um pedido de disputa pendente, aparece em vários locais:\n\n- Na secção Para fazer na Página inicial do Portal do parceiro.\n- Como uma notificação na secção Disputas na página de Disputas abertas, indicando o número de disputas que precisam de uma resposta.\n\nTambém pode optar por receber um e-mail por cada disputa, ajustando as suas definições em Definições de e-mail e disputas para Notificação por e-mail para cada disputa.\n"
					},
					"howToAvoidDisputesFees": {
						"question": "Como posso evitar taxas de disputas?",
						"answer": "Para evitar taxas de disputas:\n- Processe as devoluções ou os reembolsos de imediato.\n- Defina um Limite de estorno em Definições de e-mail e disputas.\n- Verifique regulamente Todas as disputas.\n- Responda às disputas com informações relevantes.\n- Responda antes de o prazo expirar.\n"
					}
				}
			},
			"settlements": {
				"payoutNotFound": {
					"title": "Pagamento para uma encomenda específica não encontrado",
					"orderNumberNotFound": {
						"question": "O número da encomenda ou o número de referência não aparece no relatório",
						"answer": "Se não conseguir encontrar um pagamento para uma encomenda específica:\n- Verifique a precisão da encomenda ou o número de referência.\n- Certifique-se de que a encomenda é capturada.\n- Verifique se a encomenda está no período de captura do relatório.\n- Tenha em atenção que as encomendas canceladas antes da liquidação não aparecerão no relatório.\n"
					},
					"checkIfPaidForOrder": {
						"question": "Como posso ver se me pagaram uma encomenda específica?",
						"answer": "No gestor de encomendas do Portal do Comerciante, pesquise a encomenda, assegurando que a encomenda ou o número de referência estão corretos. Abaixo do número de referência, verifique se a encomenda foi capturada. Se foi capturada, o seu estado aparecerá como pendente ou pago. Para encomendas pagas, clique em Ver liquidação para ver o relatório correspondente."
					}
				},
				"payouts": {
					"title": "Pagamentos",
					"whenIsNext": {
						"question": "Quando é o meu próximo pagamento?",
						"answer": null
					},
					"whyCalculationNotMatch": {
						"question": "Porque é que os meus cálculos não correspondem aos pagamentos recebidos?",
						"answer": "De acordo com o contrato de comerciante, taxas como a de transação e de devolução tardia são deduzidas. Por isso, os seus cálculos podem diferir das liquidações da Klarna. Para ver as deduções descritas claramente, recomendamos que abra a versão PDF do relatório de liquidação."
					},
					"whenGetPaid": {
						"question": "Quando me pagarão?",
						"answer": null
					},
					"missingPayout": {
						"question": "O meu pagamento está em falta",
						"answer": "Antes de contactar o nosso canal de apoio, considere que os pagamentos em falta podem ser devido ao seguinte:\n\n- A Klarna envia o pagamento durante o mesmo dia em que o relatório é gerado; pode demorar alguns dias até que o dinheiro apareça na sua conta bancária.\n- Não tem nenhumas encomendas capturadas para este período.\n- Atualizou recentemente os dados da sua conta bancária registados na Klarna, o que pode ter sido a causa de o seu pagamento ir para a sua conta bancária anterior. Isto pode acontecer se a alteração não for processada antes de o pagamento ser executado.\n- O número da encomenda ou o número de referência que procura está incorreto, certifique-se de rever o número da encomenda ou o número de referência.\n- Os seus pagamentos estão a ser retidos pela Klarna. Deve ter sido informado/a sobre a retenção através de um e-mail separado; estes e-mails são normalmente enviados para um administrador\n"
					},
					"howIsPayoutCalculated": {
						"question": "Como é calculado o meu pagamento?",
						"answer": "O pagamento é calculado a partir de encomendas capturadas dentro do período de captura, menos taxas, devoluções e outras despesas. Um relatório de liquidação fornece uma lista detalhada destes valores.\nSe os reembolsos excederem as vendas num período de captura, o valor em excesso será deduzido do pagamento seguinte.\n"
					}
				},
				"howPayoutsWork": {
					"question": "Como funcionam os pagamentos?",
					"answer": "A secção Liquidações fornece uma visão geral de como funcionam os pagamentos, incluindo agendamento de pagamentos, períodos de captura e datas de pagamento. Pode transferir relatórios de transação para cada pagamento em formatos PDF ou CSV e ver ou transferir dados de pagamentos específicos clicando nas suas referências de pagamento."
				},
				"payoutSchedule": {
					"title": "Calendário de pagamentos",
					"whyDelay": {
						"question": "Porque há um atraso de pagamento?",
						"answer": "O objetivo principal é impedir que o dinheiro das faturas ande para trás e para a frente entre a Klarna e o comerciante e também para cobrir os casos em que reembolsa uma encomenda capturada."
					},
					"changePayoutDelay": {
						"question": "Quero alterar o meu atraso de pagamento",
						"answer": "Temos verificações internas, bem como critérios gerais que precisam de ser cumpridos. Estes critérios gerais incluem:\n- Tem de estar totalmente integrado/a com a Klarna\n- A sua primeira Data da encomenda tem mais de 100 dias\n- A sua empresa precisa de ter mais de 6 meses.\n\nA Klarna também requer informações de um administrador sobre…\n- O novo atraso de pagamento desejado e porque é necessário um atraso de pagamento\n- A que ID de comerciante (MID) a alteração se refere\nPodem aplicar-se critérios adicionais, com decisões a serem tomadas numa base individual.\n"
					}
				},
				"generatingReports": {
					"title": "Gerar relatórios",
					"howToSeeReports": {
						"question": "Como posso ver os relatórios para períodos específicos?",
						"answer": "Pode personalizar os seus relatórios para apresentarem períodos de tempo específicos utilizando a secção Personalizar relatórios na secção Liquidações."
					},
					"convertCsvToExcel2": {
						"answer": "Siga estes passos\n1. Abra o Excel e clique em Ficheiro > Novo Livro.\n"
					},
					"convertCsvToExcel": {
						"question": "Como é que converto um relatório para uma folha de cálculo?",
						"answer": "1. Abra o Excel e clique em Ficheiro > Novo Livro.\n2. Vá ao separador Dados e selecione Do texto.\n3. Escolha o ficheiro .CSV desejado e clique em Obter dados.\n4. No Assistente de importação de texto, escolha Delimitado e depois clique em Seguinte.\n5. Em Delimitadores, selecione Ponto e vírgula e defina Qualificador de texto para Nenhum. Clique em Concluir.\n6. Na caixa de diálogo Importar dados, clique em OK para colocar os dados a começar na célula A1.\n\nNota: Os relatórios CSV no Portal do Comerciante usam ponto e vírgula (;) como separador. Considere verificar o seu software de folha de cálculo para saber como ajustar o separador predefinido.\n"
					}
				},
				"payoutAndFeeCalculation": {
					"title": "Calculo de pagamentos e taxas",
					"howAreFeesCalculated": {
						"question": "Como é que as taxas são calculadas?",
						"answer": "As taxas deduzidas a partir de pagamentos com Klarna estão descritas no seu contrato de comerciante, com as taxas adicionais, tais como as taxas por devolução tardia detalhadas na secção Taxa dos seus relatórios de pagamento/liquidação.\n\nPara personalizar as descrições de taxas nos seus relatórios:\n1. Navegue para Relatórios de liquidações > Configurar relatórios > Adicionar nova configuração.\n2. Para relatórios CSV, selecione Tipo de transações detalhado em Lista de transações.\n3. Para relatórios em PDF, selecione Incluir dados da encomenda nas Definições de disposição e conteúdo.\n\nCertifique-se de que ajusta as definições para cada ID de comerciante e canal (SFTP, Portal do comerciante, API) separadamente.\n"
					},
					"howIsPayoutCalculated": {
						"question": "Como é calculado o meu pagamento?",
						"answer": "O pagamento é calculado a partir de encomendas capturadas dentro do período de captura, menos taxas, devoluções e outras despesas. Um relatório de liquidação fornece uma lista detalhada destes valores.\n\nSe os reembolsos excederem as vendas num período de captura, o valor em excesso será deduzido do pagamento seguinte.\n"
					}
				}
			},
			"options": {
				"payout": "Pagamento",
				"orders": "Encomendas",
				"settlements": "Liquidações",
				"disputes": "Disputas",
				"otherIssue": "Outros problemas",
				"yes": "Sim",
				"no": "Não",
				"partially": "Parcialmente",
				"iNeedMoreHelp": "Preciso de mais ajuda",
				"thatHelped": "Isso ajudou",
				"contactSupportChannel": "Contactar canais de assistência"
			},
			"button": {
				"label": "Obter assistência"
			},
			"contact": {
				"chat": "Conversar por chat com um agente",
				"ticket": "Enviar um bilhete",
				"times": {
					"minutes": "Tempo de espera: {time} minuto(s)",
					"hours": "Tempo de espera: {time} hora(s)",
					"days": "Tempo de espera: {time} dia(s)"
				}
			}
		}
	}
};