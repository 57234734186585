export default [
    'v3/boost-home/boost-dashboard-page',
    'v3/boost-home/install-plugin/dropdown-menu',
    'v3/boost-home/install-plugin/adobe-commerce',
    'v3/boost-home/install-plugin/woo-commerce',
    'v3/boost-home/install-plugin/prestashop',
    'v3/boost-home/install-plugin/salesforce-commerce',
    'v3/boost-home/install-plugin/sap-commerce',
    'v3/boost-home/install-plugin/get-plugin-and-docs',
    'v3/boost-home/install-plugin/get-plugin',
    'v3/boost-home/authenticate-and-connect/generate-api-key',
    'v3/boost-home/authenticate-and-connect/manage-api-key',
    'v3/boost-home/authenticate-and-connect/generate-client-id',
    'v3/boost-home/authenticate-and-connect/copy-client-id',
    'boost-home/plugin-carousel',
    'boost-home/plugin-carousel/all-platforms',
    'boost-home/plugin-carousel/directly-integrated',
    'boost-home/plugin-carousel/platform/adobe-commerce',
    'boost-home/plugin-carousel/platform/woo-commerce',
    'boost-home/plugin-carousel/platform/prestashop',
    'boost-home/plugin-carousel/platform/salesforce-commerce',
    'boost-home/plugin-carousel/platform/sap-commerce',
    'boost-home/plugin-overview',
    'boost-home/plugin-overview/adobe-commerce',
    'boost-home/plugin-overview/woo-commerce',
    'boost-home/plugin-overview/prestashop',
    'boost-home/plugin-overview/salesforce-commerce',
    'boost-home/plugin-overview/sap-commerce',
    'boost-home/plugin-guide/adobe-commerce',
    'boost-home/plugin-guide/woo-commerce',
    'boost-home/plugin-guide/prestashop',
    'boost-home/plugin-guide/salesforce-commerce',
    'boost-home/plugin-guide/sap-commerce',
    'boost-home/plugin-guide/adobe-commerce/generate-api-key',
    'boost-home/plugin-guide/woo-commerce/generate-api-key',
    'boost-home/plugin-guide/prestashop/generate-api-key',
    'boost-home/plugin-guide/salesforce-commerce/generate-api-key',
    'boost-home/plugin-guide/sap-commerce/generate-api-key',
    'boost-home/plugin-guide/adobe-commerce/generate-client-id',
    'boost-home/plugin-guide/woo-commerce/generate-client-id',
    'boost-home/plugin-guide/prestashop/generate-client-id',
    'boost-home/plugin-guide/salesforce-commerce/generate-client-id',
    'boost-home/plugin-guide/sap-commerce/generate-client-id',
    'kec-fe/add-origin-user-flow/landing-page',
    'siwk-fe/add-origin-user-flow/landing-page',
    'home-fe/add-origin-user-flow/landing-page',
    'upstream-fe/add-origin-user-flow/landing-page'
];
