/* eslint-disable deprecation/deprecation */
import { ContextMenuMain, ContextMenuNavigationItem, ContextMenuSeparator, ContextMenuSettingsItem, IconClock, IconProfile, IconSpeechBubble, SpacerHorizontal } from '@klarna/bubble-ui';
import { format } from 'date-fns-tz';
import { hooks as coreHooks } from 'mage-core';
import { hooks as i18nHooks } from 'mage-i18n';
import React from 'react';
const styleSheet = {
    menu: {
        position: 'relative',
        display: 'block',
        width: 300
    }
};
export default ({ onLanguageClick, onLogoutClick, onProfileClick }) => {
    const tokenHelper = coreHooks.useTokenHelper();
    const shouldShowProfileMenu = tokenHelper ? (tokenHelper.isInMerchantsRealm() && !tokenHelper.getLoginSource()) : false;
    const t = i18nHooks.useTranslator();
    const language = i18nHooks.useLanguage();
    return (React.createElement("div", { id: 'usermenu-menu', style: styleSheet.menu },
        React.createElement(SpacerHorizontal, { spaceToken: 'space.100' }),
        React.createElement(ContextMenuMain, null,
            React.createElement(ContextMenuSettingsItem, { id: 'usermenu-menu-language', onClick: onLanguageClick, icon: IconSpeechBubble, label: t('core.usermenu.language'), value: t(`core.languages.${language}`) }),
            React.createElement(ContextMenuSettingsItem, { id: 'usermenu-menu-tz', icon: IconClock, label: t('core.usermenu.timezone'), value: format(new Date(), 'z', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }) }),
            shouldShowProfileMenu && (React.createElement(ContextMenuSettingsItem, { id: 'usermenu-menu-profile', onClick: onProfileClick, icon: IconProfile, label: t('core.usermenu.profile'), value: t('core.usermenu.changeProfile') })),
            React.createElement(ContextMenuSeparator, { key: 'separator' }),
            React.createElement(ContextMenuNavigationItem, { id: 'usermenu-menu-logout', onClick: onLogoutClick, label: t('core.usermenu.logout') }))));
};
