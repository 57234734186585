import { __awaiter } from "tslib";
import { ButtonSecondary, SpacerHorizontal, SpacerVertical, Typography } from '@klarna/bubble-ui';
import { Chip } from '@klarna/mp-ui';
import { selectors as experimentationSelectors } from '@merchant-portal/experimentation';
import { selectors as coreSelectors } from 'mage-core';
import { hooks as i18nHooks } from 'mage-i18n';
import insights from 'mage-insights';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ResetMfaConfirmationDialog from './ResetMfaConfirmationDialog';
const ManageMfaSection = ({ isCurrentUser, isMfaEnabled, onResetMfa }) => {
    const t = i18nHooks.useTranslator();
    const title = t('core.updateProfile.twoFactor.title');
    const subtitle = t('core.updateProfile.twoFactor.description');
    const realm = useSelector(coreSelectors.keycloakRealm);
    const [activeResetDialog, setActiveResetDialog] = useState(false);
    const disableUserManagementExperiment = useSelector(experimentationSelectors.isFeatureToggleEnabled('merchant-portal.users.disable-user-management'));
    function handleResetMfaDevice() {
        setActiveResetDialog(true);
    }
    function executeResetMfaDevice() {
        return __awaiter(this, void 0, void 0, function* () {
            yield onResetMfa();
            if (isCurrentUser) {
                window.location.reload();
            }
            insights.event({
                category: '2fa', // Is called 2fa for historic reasons - we now refer to 2FA/TOTP/etc. as MFA
                action: 'reset-mfa-device',
                label: realm
            });
        });
    }
    function handleClose() {
        setActiveResetDialog(false);
    }
    return (React.createElement("div", null,
        activeResetDialog && (React.createElement(ResetMfaConfirmationDialog, { onConfirm: executeResetMfaDevice, onClose: handleClose })),
        React.createElement(Typography, { textToken: 'text-style/text/paragraphs/body/bold' },
            React.createElement("span", { style: { display: 'flex' } },
                title,
                React.createElement(SpacerHorizontal, { spaceToken: 'space.300' }),
                isMfaEnabled ? (React.createElement(Chip, { className: 'chip-mfa-enabled', label: t('core.updateProfile.twoFactor.isEnabled') })) : (React.createElement(Chip, { className: 'chip-mfa-disabled', hasWarning: true, label: t('core.updateProfile.twoFactor.isDisabled') })))),
        React.createElement(Typography, { textToken: 'text-style/text/paragraphs/body/regular' }, subtitle),
        React.createElement(SpacerVertical, { spaceToken: 'space.300' }),
        onResetMfa && (React.createElement("div", { style: { display: 'flex' } },
            React.createElement(ButtonSecondary, { size: 'medium', disabled: disableUserManagementExperiment, onClick: handleResetMfaDevice }, t('core.updateProfile.twoFactor.reset')),
            React.createElement(SpacerHorizontal, { spaceToken: 'space.100' })))));
};
ManageMfaSection.propTypes = {
    isCurrentUser: PropTypes.bool,
    isMfaEnabled: PropTypes.bool,
    onResetMfa: PropTypes.func
};
ManageMfaSection.defaultProps = {
    isMfaEnabled: false
};
export default ManageMfaSection;
