module.exports = {
	"da": {
		"credentials": {
			"addOriginModal": {
				"buttonPrimaryText": "Registrer",
				"buttonSecondaryText": "Fortryd",
				"description": "Registrér eventuelt oprindelsesdomæne, du forventer at bruge Web SDK på. Hvis du har brug for at registrere mere end ét, kan du gøre det senere.",
				"errorMessage": "Der opstod en fejl. Prøv igen senere, eller kontakt support, hvis problemet fortsætter.",
				"inputLabel": "Indtast resten af ​​din URL",
				"title": "Registrér tilladt oprindelse"
			},
			"clientKeyBlock": {
				"mainText": "Din kundeidentifikator indsættes direkte i koden med det oprindelsesdomæne, som du har angivet.",
				"secondaryText": "For at administrere det yderligere, bedes du følge linket til",
				"linkDescription": "siden for kundeidentifikatorer",
				"linkLabel": "Luk"
			},
			"clientKeyField": {
				"label": "Kundeidentifikator",
				"defaultErrorMessage": "Kundeidentifikatoren mangler"
			},
			"copyToClipboardButton": {
				"label": "Kopiér",
				"labelOnCopy": "Kopieret"
			},
			"originField": {
				"label": "Oprindelsesdomæne",
				"tooltipMainText": "For at registrere flere oprindelsesdomæner eller administrere allerede eksisterende domæner skal du gå til",
				"tooltipLinkLabel": "siden for kundeidentifikatorer"
			},
			"originInputField": {
				"label": "Indtast resten af ​​din URL",
				"validationError": "Indtast et gyldigt domæne-URL",
				"invalidProtocolError": "URL skal starte med http:// eller https://"
			},
			"addApiKeySuccessModal": {
				"title": "",
				"description": "Gem følgende brugeroplysninger på din enhed eller på et andet sikkert sted.",
				"warning": "Adgangskoden vil kun blive vist denne gang, og den vil forsvinde, når du lukke dette vindue.",
				"checkbox": "Jeg har gemt brugeroplysningerne på min enhed.",
				"download": "Hent som .txt"
			},
			"common": {
				"password": "Adgangskode",
				"username": "Brugernavn",
				"close": "Luk"
			}
		}
	}
};