module.exports = {
	"pt": {
		"core": {
			"alert": {
				"message": "Ocorreu um erro! Tente novamente.",
				"correlationIdLabel": "ID de correlação:"
			},
			"header": {
				"title": "Portal do comerciante"
			},
			"usermenu": {
				"language": "Idioma",
				"country": "País",
				"timezone": "Fuso horário",
				"logout": "Terminar sessão",
				"profile": "Perfil",
				"changeProfile": "Editar",
				"tooltip": "O seu perfil"
			},
			"appsmenu": {
				"defaultTooltip": "As suas aplicações",
				"initialTooltip": "Encontre as suas aplicações aqui.",
				"newAppsTooltip": "Temos novas aplicações para si!"
			},
			"two-factor-opt-in": {
				"title": "Configurar a autenticação de dois fatores",
				"intro-paragraph": "Não tem a autenticação de dois fatores (2FA) ativada na sua conta no portal do comerciante. A partir de <b>16 de janeiro de 2023</b>, o 2FA é obrigatório para todos os utilizadores terem acesso ao portal do comerciante. Pode ler mais sobre a importância da 2FA, como configurá-la e respostas a perguntas comuns <faqLink>aqui</faqLink>.",
				"help-link-text": "Clique aqui",
				"help-text": " para saber mais sobre como a configurar e sobre como o(a) protege.",
				"warning-text": "Certifique-se de que tem o seu smartphone e acesso à internet antes de prosseguir",
				"continue-button": "Começar a configuração"
			},
			"kuasPrompter": {
				"title": "Selecionou",
				"content": "{count} IDC",
				"contentTooltip": "Clique para copiar MID para a área de transferência",
				"button": "Alterar IDC selecionadas"
			},
			"notification": {
				"title": "Anúncio",
				"content": {
					"uptodate": "Está tudo atualizado!"
				}
			},
			"languages": {
				"cs": "Checo",
				"da": "Dinamarquês",
				"de": "Alemão",
				"el": "Grego",
				"en": "Inglês",
				"en_GB": "Inglês (Grã-Bretanha)",
				"en_US": "Inglês (Estados Unidos)",
				"es": "Espanhol",
				"fi": "Finlandês",
				"fr": "Francês",
				"it": "Italiano",
				"nl": "Neerlandês",
				"no": "Norueguês",
				"pl": "Polaco",
				"pt": "Português",
				"sv": "Sueco"
			},
			"countries": {
				"at": "Áustria",
				"au": "Austrália",
				"be": "Bélgica",
				"ca": "Canadá",
				"ch": "Suíça",
				"cz": "Chéquia",
				"de": "Alemanha",
				"dk": "Dinamarca",
				"ee": "Estónia",
				"es": "Espanha",
				"fi": "Finlândia",
				"fr": "França",
				"gb": "Grã-Bretanha",
				"gr": "Grécia",
				"ie": "Irlanda",
				"it": "Itália",
				"mx": "México",
				"nl": "Países Baixos",
				"no": "Noruega",
				"se": "Suécia",
				"us": "Estados Unidos",
				"nz": "Nova Zelândia",
				"pl": "Polónia",
				"pt": "Portugal",
				"ro": "Roménia"
			},
			"selectLanguage": {
				"title": "Selecione o seu idioma",
				"paragraph": "Este será o seu idioma predefinido.",
				"submit": "OK"
			},
			"selectCountry": {
				"title": "Selecione o seu país",
				"paragraph": "Este será o seu país predefinido.",
				"submit": "OK"
			},
			"updateProfile": {
				"title": "Atualizar perfil",
				"submit": "OK",
				"emailLabel": "Endereço de e-mail",
				"firstNameLabel": "Nome próprio",
				"lastNameLabel": "Apelido",
				"resetPasswordLabel": "Palavra-passe",
				"resetPasswordSelectedValue": "Repor",
				"resetPasswordSuccessLabel": "E-mail de reposição reenviado com sucesso.",
				"resetPasswordErrorLabel": "Erro ao enviar o e-mail. Tente novamente mais tarde.",
				"error": "A atualização de detalhes falhou",
				"changeCookiePolicyLabel": "Política de cookies",
				"changeCookiePolicySelectedValue": "Alterar",
				"securitySectionTitle": "Segurança",
				"preferencesSectionTitle": "Preferências",
				"mfaLabel": "Autenticação de dois fatores",
				"mfaDescription": "Requer que inicie sessão com uma palavra-passe avulsa por tempo limitado. Recomendamos que ative isto.",
				"twoFactor": {
					"reset": "Alterar a sua 2FA",
					"isEnabled": "caixa de seleção",
					"isDisabled": "Desativada",
					"title": "Autenticação de dois fatores (2FA)",
					"description": "Isto permite aos utilizadores iniciar sessão com a segurança adicional de uma palavra-passe de utilização única baseada no tempo.",
					"tooltip": "Certifique-se de que tem pelo menos a permissão {permission} para a aplicação {app} para todas as lojas às quais o utilizador tem acesso, para comutar a definição de autenticação de dois fatores.",
					"confirmationDialog": {
						"closeButton": "Fechar",
						"confirmationButton": "Sim, continuar",
						"cancelButton": "Cancelar",
						"resetSuccess": {
							"title": "Reposição de dispositivo 2FA bem-sucedida.",
							"description": "Na próxima vez que o utilizador iniciar sessão, precisará de configurar um novo dispositivo para 2FA."
						},
						"resetConfirmation": {
							"title": "Tem a certeza de que deseja repor este dispositivo 2FA do utilizador?",
							"description": "Ao continuar, irá repor o dispositivo utilizado para gerar códigos 2FA para este utilizador. Na próxima vez que o utilizador iniciar sessão, precisará de configurar um novo dispositivo para 2FA."
						}
					}
				}
			},
			"footer": {
				"copyright": {
					"eu": "Copyright © 2005-{currentYear} Klarna Bank AB (publ). Todos os direitos reservados",
					"oc": "Copyright © 2005-{currentYear} Klarna Australia Pty Ltd, ABN # 82 635 912 579",
					"ap": "Copyright © 2005-{currentYear} Klarna Australia Pty Ltd, ABN # 82 635 912 579",
					"us": "Copyright © 2005-{currentYear} Klarna Inc. NMLS #1353190, 629 N. High Street, Third Floor, Columbus, OH 43215"
				},
				"terms": "Termos e Condições"
			},
			"accessDenied": {
				"title": "Precisa de acesso adicional para esta página.",
				"message": "Contacte o seu administrador ou a Klarna com quaisquer perguntas."
			},
			"midSelector": {
				"label": "Loja"
			},
			"storeSelector": {
				"label": "Loja"
			},
			"storesSelector": {
				"closeText": "Concluído",
				"closeTextWithSelection": "Aplicar # seleção(ões)",
				"deselectAllOptionText": "Limpar seleção",
				"emptyStateText": "Nenhuma loja encontrada",
				"label": "Lojas",
				"searchFieldLabel": "Pesquisar por loja",
				"selectAllOptionText": "Selecionar todas as lojas",
				"selectedAllText": "Todas as lojas",
				"selectedText": "# lojas"
			},
			"pagination": {
				"ofLabel": "de"
			},
			"singleDatePicker": {
				"selectDate": "Selecionar data"
			},
			"dateRangePicker": {
				"startLabel": "Data de início",
				"endLabel": "Data de fim",
				"months": {
					"january": "Janeiro",
					"february": "Fevereiro",
					"march": "Março",
					"april": "Abril",
					"may": "Maio",
					"june": "Junho",
					"july": "Julho",
					"august": "Agosto",
					"september": "Setembro",
					"october": "Outubro",
					"november": "Novembro",
					"december": "Dezembro"
				},
				"weekDaysShort": {
					"monday": "S",
					"tuesday": "T",
					"wednesday": "Q",
					"thursday": "Q",
					"friday": "S",
					"saturday": "S",
					"sunday": "D"
				}
			},
			"regionSelector": {
				"noStores": "Nenhuma loja atribuída",
				"andMore": "e mais",
				"regions": {
					"eu": "Europa",
					"us": "América do Norte",
					"ap": "Ásia Pacífico"
				}
			},
			"regionSelectorBar": {
				"modal": {
					"title": "Alterar Região",
					"description": "Selecione uma região com lojas associadas para apresentar dados relevantes.",
					"buttonText": "Confirmar"
				},
				"selectedRegion": "Região selecionada",
				"changeButton": "Alterar"
			},
			"appIcon": {
				"newLabel": "NOVO"
			},
			"appCategory": {
				"dayToDay": "Para o seu dia a dia",
				"nko": "Klarna Online",
				"tools": "Ferramentas",
				"preferences": "Preferências"
			},
			"apps": {
				"account-information-fe": {
					"title": "Informações da conta",
					"description": "Encontre o seu negócio, os dados bancários e os contratos que assinou."
				},
				"home-fe": {
					"title": "Página inicial de pagamentos"
				},
				"boosters-home-fe": {
					"title": "Começar"
				},
				"marketing-home-fe": {
					"title": "Página inicial de marketing"
				},
				"nko-app": {
					"title": "Aplicações Klarna Online"
				},
				"orders-fe": {
					"title": "Gestor de encomendas",
					"description": "Pesquisar e gerir as suas encomendas da Klarna.",
					"nkoLinkText": "Aceder a encomendas mais antigas"
				},
				"nko-orders": {
					"title": "Encomendas (KO)",
					"description": "Pesquisar e gerir as suas encomendas feitas através de Klarna Online."
				},
				"settlements-fe": {
					"title": "Liquidações",
					"description": "Receber relatórios personalizados para as necessidades da sua empresa.",
					"nkoLinkText": "Aceder a relatórios mais antigos"
				},
				"nko-settlements": {
					"title": "Liquidações (KO)",
					"description": "Encontrar relatórios sobre encomendas e pagamentos de Klarna Online."
				},
				"logs-app-fe": {
					"title": "Registos de programadores",
					"description": "Pesquisar registos da API para resolver problemas de integração."
				},
				"users-fe": {
					"title": "Gestor de utilizadores",
					"description": "Convidar novos utilizadores e gerir utilizadores existentes no portal."
				},
				"settings-fe": {
					"title": "Definições de pagamento",
					"description": ""
				},
				"nko-settings": {
					"title": "Definições (KO)",
					"description": "Gerir as definições, os relatórios e os dados de contacto da sua loja."
				},
				"seller-fe": {
					"title": "Em lojas",
					"description": "Criar novas encomendas com a solução Klarna In-Store."
				},
				"products-fe": {
					"title": "Integração",
					"description": "Configura a tua conta para ativar pagamentos."
				},
				"upstream-fe": {
					"title": "Mensagem no local",
					"description": "Vender mais com ofertas de financiamento personalizadas e à medida."
				},
				"lending-fe": {
					"title": "Business Financing",
					"description": "Financiamento de empresas simplificado."
				},
				"branding-fe": {
					"title": "Gestor de marcas",
					"description": "Assumir o controlo sobre como a sua marca é apresentada na Klarna."
				},
				"disputes-fe": {
					"title": "Disputas",
					"description": "Tratar das suas disputas."
				},
				"logistics-fe": {
					"title": "Integrações de envio",
					"description": "Gerir toda a sua logística num lugar."
				},
				"developers": {
					"title": "Desenvolvedores"
				},
				"klarna-docs": {
					"title": "Klarna Docs"
				},
				"support": {
					"title": "Apoio ao cliente",
					"description": "Verificar e gerir os seus pedidos de assistência."
				},
				"integration-guides-fe": {
					"title": "Guias de integração",
					"description": ""
				},
				"klarna-search-b2b-portal": {
					"title": "Pesquisar e comparar",
					"description": "Comece com o motor de busca poderoso e a comparação de compras da Klarna. Ganhe acesso a estatísticas e ferramentas concebidas para melhorar a sua visibilidade."
				},
				"siwk-fe": {
					"title": "Iniciar sessão com Klarna",
					"description": "Faça a gestão da sua integração Iniciar sessão com Klarna."
				},
				"kec-fe": {
					"title": "Finalização de compra expresso",
					"description": "Faça a gestão da sua integração Express Checkout."
				},
				"partner-self-service": {
					"title": "Gerente da loja",
					"description": "Efetue a gestão da sua Loja Klarna.",
					"nkoLinkText": "Ver e gerir definições da sua loja"
				}
			},
			"ui": {
				"tabs": {
					"home": "Pagamentos",
					"growth": "Marketing de crescimento",
					"boosters": "Impulsionadores de conversão",
					"badge": "Novo"
				},
				"buttons": {
					"backButton": "Voltar"
				}
			},
			"errorBoundary": {
				"contact": "Experimente atualizar a página. {br}Se o erro persistir, contacte <supportLink>Apoio ao comerciante</supportLink> fornecendo a id {errorId}.",
				"errorDescription": "ID do erro - {errorId}",
				"reloadButton": "Atualizar",
				"title": "Infelizmente, ocorreu um erro"
			},
			"maintenance": {
				"pageTitle": "Manutenção",
				"title": "O portal do comerciante está atualmente a efetuar a manutenção agendada",
				"summary": "Estaremos de volta em breve",
				"scheduledSummary": "Estaremos de volta às {until}"
			},
			"errorTemplates": {
				"errorDescription": "Erro: {code}",
				"backButton": "Voltar",
				"reloadButton": "Atualizar",
				"default": {
					"errorDescription": "ID do erro - {errorId}",
					"title": "Infelizmente, ocorreu um erro",
					"contact": "Experimente atualizar a página. Se o erro persistir, contacte <supportLink>Apoio ao comerciante</supportLink> fornecer a id do erro {errorId}."
				},
				"internalServerError": {
					"title": "Ups, ocorreu um erro",
					"message": "Por algum motivo, não conseguimos executar o seu pedido. Pode tentar novamente ou voltar mais tarde."
				},
				"notFound": {
					"title": "Não existe nada aqui!",
					"message": "Esta página não existe ou escreveu um endereço errado no seu navegador. Verifique o endereço e tente novamente."
				},
				"forbidden": {
					"title": "Não tem acesso a esta página",
					"message": "A sua ID de comerciante não tem acesso a esta página. Se acredita que se trata de um erro, contacte o apoio ao comerciante ou o administrador da conta da sua empresa."
				}
			},
			"codeSnippet": {
				"clipboardButtonLabel": "Copiar para a área de transferência",
				"clipboardButtonLabelSuccess": "Copiado!"
			}
		},
		"blackFriday": {
			"v1": {
				"title": "Black Friday",
				"intro": "Um dos dias mais importantes para o retalho está quase a chegar. Durante a Black Friday, estaremos aqui para fornecer estatísticas em tempo próximo das suas vendas. Entretanto, vamos preparar-nos para o sucesso!",
				"countdown": "A começar dentro de #dd #hh #mm #ss…",
				"box1": {
					"title": "3,5x mais gastos*",
					"text": "No ano passado, os compradores na Klarna* gastaram, em média, 3,5x mais na Black Friday do que em qualquer outro dia do ano.",
					"footnote": "* Média em todas as categorias de comerciantes e regiões da Klarna."
				},
				"box2": {
					"title": "2,5x volume de vendas*",
					"text": "No ano passado, os comerciantes da Klarna geraram 2,5x mais volume de vendas na Black Friday do que em qualquer outro dia do ano.",
					"footnote": "* Média em todas as categorias de comerciantes e regiões da Klarna."
				},
				"sectionTitle": "Vamos aumentar as suas vendas na Black Friday",
				"marketing": {
					"title": "Divulgue a sua empresa antes e durante a Black Friday",
					"text": "Leve mais compradores à sua loja durante a época de compras da Black Friday com estes recursos de utilização gratuita para campanhas em redes sociais e por e-mail."
				},
				"branding": {
					"title": "Seja visto(a) na aplicação Klarna",
					"text": "12 milhões de compradores utilizam a aplicação Klarna todos os meses. Certifique-se de que brilha na aplicação nesta Black Friday, adicionando e atualizando as informações da sua marca aqui no portal do comerciante."
				},
				"cta": "Começar",
				"close": "Fechar"
			},
			"v2": {
				"title": "Black week",
				"orderVolume": "Volume de vendas",
				"orderCountTitle": "Número de encomendas",
				"orderCount": "{count} encomendas",
				"box": {
					"title": "Deseja aumentar ainda mais a conversão?",
					"subtitle1": "Mensagem no local",
					"subtitle2": "Mensagem promocional que educa.",
					"text": "A nossa ferramenta de Mensagem no local ajuda a impulsionar as suas vendas e o valor médio por encomenda com mensagens promocionais. Informe os seus compradores sobre as suas opções de pagamento assim que entrarem no seu site e ao longo do seu percurso de compras.",
					"cta": "Começar"
				},
				"selector": {
					"done": "Concluído",
					"applySelection": "Aplicar # seleção(ões)",
					"selectAll": "Selecionar tudo",
					"allOptions": "Todas as opções selecionadas",
					"someOptions": "# opções selecionadas",
					"storeMultiSelectLabel": "Escolher # lojas",
					"allStores": "Todas as lojas"
				},
				"currencyLabel": "Escolher moeda",
				"storeLabel": "Escolher loja"
			},
			"v3": {
				"title": "Semana da Black Friday",
				"text": "Estas são as suas vendas numa das semanas de vendas mais importantes do ano.",
				"summaryTitle": "Resumo",
				"orderVolume": "Volume total",
				"orderCount": "Encomendas totais",
				"summaryRange": "23-30 de novembro",
				"comparisonLastWeek": "comparativamente ao mesmo dia na semana passada",
				"tabs": {
					"monday": "Segunda-feira",
					"tuesday": "Terça-feira",
					"wednesday": "Quarta-feira",
					"thursday": "Quinta-feira",
					"blackFriday": "Black Friday",
					"saturday": "Sábado",
					"sunday": "Domingo",
					"cyberMonday": "Cyber Monday"
				}
			}
		},
		"mpSatSurvey": {
			"marble": {
				"topbarButtonLabel": "Feedback sobre o nosso novo visual",
				"step1": {
					"welcome": "Gosta do nosso novo visual e sensação?",
					"satisfied": "Muito satisfeito/a",
					"unsatisfied": "Muito insatisfeito/a"
				},
				"step2": {
					"textAreaLabel": "Outro (a sua opinião)",
					"textAreaMaxLength": "Tamanho máximo atingido",
					"submit": "Enviar",
					"content": {
						"titlePositive": "De que gosta mais?",
						"titleNegative": "De que gosta menos?",
						"tags": {
							"color": "Cor e estilo",
							"navigation": "Navigation",
							"names": "Nome de páginas",
							"structure": "Estrutura de páginas"
						}
					}
				},
				"step3": {
					"title": "Obrigado!",
					"description": "Apreciamos o seu feedback."
				}
			},
			"buttonLabel": "Dar feedback",
			"errorMessage": "Ocorreu um erro ao enviar o seu feedback.",
			"retry": "Tentar novamente",
			"step1": {
				"welcome1": "Quão feliz está com o",
				"welcome2": "portal do comerciante?",
				"emojiSelector": {
					"angry": "Muito infeliz",
					"unhappy": "Ligeiramente infeliz",
					"neutral": "Neutro",
					"happy": "Feliz",
					"love": "Adoro-o"
				},
				"remindMeText": "Não deseja partilhar a sua opinião?",
				"remindMeLink": "Lembrar-me mais tarde sobre este inquérito.",
				"dontShowAgainLink": "Não mostrar isto novamente."
			},
			"step2": {
				"textAreaLabel": "Outro (a sua opinião)",
				"textAreaMaxLength": "Tamanho máximo atingido",
				"submit": "Enviar",
				"content": {
					"negative": {
						"title": "Lamentamos saber isso.",
						"description": "Que aspetos do portal do comerciante devemos melhorar? (Selecione todas as que se aplicam)",
						"tags": {
							"loading": "Tempo de carregamento",
							"menu": "Organização do menu",
							"mobile": "Versão móvel",
							"filter": "Filtro de dados",
							"complexity": "Realizar ações eficientemente"
						}
					},
					"positive": {
						"title": "São boas notícias!",
						"description": "Ainda queremos fazer melhor. Quais são as coisas que tornariam a sua experiência do portal do comerciante ainda melhor",
						"tags": {
							"views": "Visualizações personalizadas",
							"looks": "Aspeto e sensação",
							"tours": "Visitas guiadas",
							"shortcuts": "Atalhos para tarefas"
						}
					}
				}
			},
			"step3": {
				"title": "Obrigado! Uma última coisa.",
				"content1": "Gostaríamos de o(a) convidar para uma entrevista, para podermos saber mais sobre a sua experiência ao utilizar o portal do comerciante.",
				"content2": "Gostaria de participar?",
				"content3": "Se concordar, entraremos em contacto consigo através do e-mail que registou para configurar isto.",
				"yes": "Sim, por favor",
				"no": "Não, obrigado"
			},
			"step4": {
				"yesTitle": "Ótimo! Entraremos em contacto.",
				"noTitle": "Entendi. Obrigado!",
				"content1": "Obrigado por reservar tempo para responder a estas perguntas.",
				"content2": "Pode fechar este pop-up agora."
			}
		},
		"boost": {
			"integrationTypeModal": {
				"title": "Como gostaria de configurar {productName}?",
				"labels": {
					"plugin": "Configurar com um plugin",
					"web": "Configurar com Web SDK"
				}
			}
		}
	}
};