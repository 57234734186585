import { useHelpContext } from './';
import { startConversationUserInfo } from 'mage-conversation';
import { useChannelDataState } from '../context/ChannelDataStateProvider';
import { useChannelSelectorState } from '../context/ChannelSelectorState';
import { gatherCallbacks } from '../utilities/callback';
import { generateSidebarController } from '../utilities/sidebarController';
import { useChatContext } from './useChatContext';
import { useRefreshClientGenerator } from './useRefreshClientGenerator';
import useIsChatActive from './useIsChatActive';
import { hooks as i18nHooks } from 'mage-i18n';
const useContactChannels = () => {
    useRefreshClientGenerator();
    // @ts-ignore
    const helpHook = useHelpContext();
    // @ts-ignore
    const { toggleWidget } = useHelpContext();
    const { data: channelData } = useChannelDataState();
    const { resetIsChatLoading } = useChannelSelectorState();
    const chatFeatures = useIsChatActive();
    const navigateToCreateTicketPage = () => {
        helpHook.navigation.push('support');
    };
    const sidebarController = generateSidebarController(toggleWidget);
    const context = useChatContext();
    const language = i18nHooks.useLanguage();
    const closeViewport = () => sidebarController(false);
    const getChatContext = (marketCode) => {
        const region = chatFeatures.getWorkingHoursRegion(marketCode);
        const restoreIdByRegion = channelData.restoreId;
        const onChatWidgetOpens = gatherCallbacks([closeViewport, resetIsChatLoading]);
        return Object.assign(Object.assign({}, context), { region, market: marketCode, restoreIdByRegion,
            onChatWidgetOpens,
            language });
    };
    const startChatConversation = (marketCode) => {
        startConversationUserInfo(getChatContext(marketCode));
    };
    return {
        navigateToCreateTicketPage,
        startChatConversation
    };
};
export default useContactChannels;
