module.exports = {
	"en": {
		"credentials": {
			"addOriginModal": {
				"buttonPrimaryText": "Register",
				"buttonSecondaryText": "Cancel",
				"description": "Please register any origin domain you are expecting to use the Web SDK on. If you need to register more than one you will be able to do so later.",
				"errorMessage": "An error occurred. Please try again later or contact support in case the problem persists.",
				"inputLabel": "Enter the rest of your URL",
				"title": "Register allowed origin"
			},
			"clientKeyBlock": {
				"mainText": "Your client identifier is setup directly in the code with the origin domain you provided.",
				"secondaryText": "To manage it further please follow the link to the",
				"linkDescription": "client identifiers page",
				"linkLabel": "Close"
			},
			"clientKeyField": {
				"label": "Client identifier",
				"defaultErrorMessage": "Client identifier is missing"
			},
			"copyToClipboardButton": {
				"label": "Copy",
				"labelOnCopy": "Copied"
			},
			"originField": {
				"label": "Origin domain",
				"tooltipMainText": "To register more origin domains or manage existing ones go to the",
				"tooltipLinkLabel": "client identifiers page"
			},
			"originInputField": {
				"label": "Enter the rest of your URL",
				"validationError": "Please enter a valid domain URL",
				"invalidProtocolError": "URL must start with http:// or https://"
			},
			"addApiKeySuccessModal": {
				"title": "Klarna API keys generated!",
				"description": "Please save the following credentials on your device or in another secure location.",
				"warning": "The password will only be revealed this time and will disappear after you close this window.",
				"checkbox": "I’ve saved the credentials on my device.",
				"download": "Download as .txt"
			},
			"common": {
				"password": "Password",
				"username": "Username",
				"close": "Close"
			}
		}
	}
};