import {
  Field,
  LinkPrimary,
  SpacerVertical,
  SwitchCheckbox
} from '@klarna/bubble-ui'
import { Modal } from '@klarna/mp-ui'
import { selectors as commonSelectors } from 'mage-common'
import { selectors as configSelectors } from 'mage-config'
import { components as coreComponents } from 'mage-core'
import { hooks as i18nHooks, Message } from 'mage-i18n'
import PropTypes from 'prop-types'
import React, {
  useState
} from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'

import * as a from '../../actions'
import * as s from '../../selectors'

const NkoWelcome = ({
  userId,
  username,
  termsAndConditionsUrl,
  isNkoUser,
  shouldShowDialog,
  setDialogShown
}) => {
  const [isTermsAndConditionsAccepted, setIsTermsAndConditionsAccepted] = useState(false)
  const t = i18nHooks.useTranslator()

  if (!isNkoUser || !shouldShowDialog) return null

  const isUsernameAvailable = username => username && typeof username === 'string' && username.trim().length > 0

  return (
    <Modal
      id='nko-welcome'
      focusTrapActive
      illustration='IllustrationMerchantPortalRational'
      title={t('home-fe.nko.dialog.title')}
      description={!isUsernameAvailable(username) ? t('home-fe.nko.dialog.subtitle') : undefined}
      buttonPrimary={{
        onClick: () => {
          setDialogShown(userId)
        },
        text: t('home-fe.nko.dialog.explore'),
        disabled: !isTermsAndConditionsAccepted
      }}
    >
      {!isUsernameAvailable(username) && (
        <>
          <Field
            label={t('home-fe.nko.dialog.name-field')}
            defaultValue={username}
          />
          <SpacerVertical spaceToken='space.300' />
        </>
      )}
      <SwitchCheckbox
        name='terms'
        defaultValue={isTermsAndConditionsAccepted}
        onChange={setIsTermsAndConditionsAccepted}
      >
        <Message path='home-fe.nko.dialog.terms-pre' />
        <LinkPrimary
          inParagraph
          href={termsAndConditionsUrl}
          target='_blank'
        >
          <Message path='home-fe.nko.dialog.terms-text' />
        </LinkPrimary>
      </SwitchCheckbox>
    </Modal>
  )
}

NkoWelcome.propTypes = {
  userId: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  termsAndConditionsUrl: PropTypes.string.isRequired,
  isNkoUser: PropTypes.bool,
  shouldShowDialog: PropTypes.bool,
  setDialogShown: PropTypes.func.isRequired
}

NkoWelcome.defaultProps = {
  isNkoUser: false,
  shouldShowDialog: false
}

export default compose(
  coreComponents.withTokenHelper(tokenHelper => ({
    userId: tokenHelper.getUserId(),
    username: tokenHelper.getUsername(),
    isNkoUser: commonSelectors.tokenHelper.isNkoUser(tokenHelper)
  })),
  connect(
    state => ({
      termsAndConditionsUrl: configSelectors.terms(state).url,
      shouldShowDialog: s.getShouldShowDialog(state)
    }),
    {
      setDialogShown: a.setDialogShown
    }
  )
)(NkoWelcome)

export {
  NkoWelcome
}
