module.exports = {
	"it": {
		"help-tool": {
			"button-text": "Aiuto",
			"support-button": "Richiedi supporto",
			"support-page": {
				"create-ticket": {
					"title": "Ottieni supporto",
					"instructions": "Invia una richiesta, ti risponderemo entro 1 giorno lavorativo.",
					"related-content": {
						"title": "Questi strumenti potrebbero aiutarti a risolvere il tuo problema"
					},
					"fields": {
						"mid": {
							"label": "Seleziona negozio",
							"validation": "Negozio necessario"
						},
						"phoneNumber": {
							"label": "Numero di telefono (opzionale)"
						},
						"topic": {
							"question": "A cosa si riferisce la sua domanda?",
							"label": "Seleziona un argomento",
							"validation": "Argomento necessario",
							"options": {
								"orders": "Ordini",
								"settlements": "Liquidazioni",
								"agreements": "Accordi",
								"others": "Altro"
							}
						},
						"subject": {
							"label": "Oggetto",
							"validation": "Oggetto necessario"
						},
						"description": {
							"label": "Messaggio",
							"validation": "Descrizione necessaria"
						},
						"attachment": {
							"label": "Aggiungi allegati",
							"button": "Carica",
							"info": "Aggiungere schermate ci aiuterà a risolvere questo problema più rapidamente.",
							"supportedFileTypes": "Tipi di file supportati: .png, jpg, .pdf",
							"error": "La dimensione totale dei file non può eccedere i {maxSize} MB"
						}
					},
					"submit": "Invia richiesta",
					"service-unavailable-error": "Oh, no! Si è verificato un problema, riprova in alcuni minuti. Se questo persiste, {link} per richiedere assistenza.",
					"generic-error": "Non è stato possibile elaborare la tua richiesta. I nostri sistemi hanno al momento problemi di comunicazione. Prova ad aggiornare la pagina, o riprova più tardi. Se questo problema persiste, {link} per richiedere assistenza.",
					"contact-link": "contattaci"
				},
				"view-ticket": {
					"title": "Completato tutto!",
					"details": "Abbiamo ricevuto la tua richiesta. Riceverai una risposta entro 1 giorno lavorativo. Ecco il riepilogo dei tuoi dati:",
					"ticket": "Richiesta n. {ticketId}",
					"email": "Email: {email}",
					"status": "Puoi tracciare lo stato nella nostra pagina di Supporto",
					"track": "Traccia stato"
				}
			},
			"header": {
				"homeLink": "Ripristina"
			},
			"home": {
				"greeting": "Buongiorno {givenName}! Come possiamo essere d’aiuto?",
				"topics": "In cosa possiamo aiutarti?"
			},
			"topics": {
				"expandButton": "Altro"
			},
			"feedback": {
				"title": "È stata una risposta utile?",
				"optionYes": "Sì, è stata utile",
				"optionNo": "No, ho bisogno di ulteriore aiuto",
				"positiveFeedback": "Grazie per il tuo feedback positivo, questo ci aiuta molto ad offrire articoli più pertinenti per il futuro."
			},
			"freeTextFeedback": {
				"title": "Aiutaci a migliorare",
				"placeholder": "Perché non hai trovato utili questi contenuti?",
				"submitButton": "Invia",
				"feedbackMessage": "Grazie per il tuo feedback, questo ci aiuta molto ad offrire articoli più pertinenti per il futuro."
			},
			"relatedContent": {
				"title": "Contenuti correlati"
			},
			"search": {
				"buttonLabel": "Ricerca",
				"input": "Ricerca articoli…",
				"loading": "Caricamento…",
				"results": {
					"contact": "Non riesci a trovare l’aiuto di cui hai bisogno?",
					"empty": "Spiacenti, non sono stati trovati risultati per la tua richiesta. Prova a cercare un termine differente.",
					"singleTopicFound": "Abbiamo trovato 1 argomento",
					"multipleTopicsFound": "Abbiamo trovato {count} argomenti",
					"singleArticleFound": "Abbiamo trovato 1 articolo",
					"multipleArticlesFound": "Abbiamo trovato {count} articolo"
				}
			},
			"contact": {
				"title": "Contatta",
				"channelsIntro": "Contattaci così potremo essere d’aiuto.",
				"businessDetailsTitle": "I dati della tua attività",
				"businessDetailsName": "Nome attività",
				"businessDetailsEmail": "Email",
				"businessDetailsMid": "Merchant ID",
				"phone": {
					"title": "Chiamaci",
					"paragraph": "Puoi chiamarci durante l’orario di lavoro nei giorni infrasettimanali. Se ti trovi in Svezia o nel Regno Unito, puoi anche chiamarci durante i fine settimana, tra le 10:00 e le 16:00."
				},
				"email": {
					"title": "Scrivici un’email",
					"paragraph": "Invia un’email, risponderemo il prima possibile."
				}
			},
			"errorBoundary": {
				"title": "Si è verificato un problema",
				"message": "Siamo molto spiacenti, si è verificato un errore. Puoi cliccare sul pulsante sottostante “Torna indietro” per ritornare allo strumento di aiuto oppure sul pulsante “Supporto” per contattarci.",
				"resetButton": "Torna indietro",
				"errorId": "ID errore: {errorId}"
			},
			"tour": {
				"prev": "Precedente",
				"next": "Avanti",
				"done": "Fatto"
			},
			"notAvailableMobile": {
				"title": "Non disponibile",
				"buttonBack": "Indietro",
				"paragraph": "Questo contenuto non è disponibile per il tuo dispositivo. Prova ad aprirlo su un dispositivo dallo schermo più grande, quale un computer."
			},
			"shareButton": {
				"title": {
					"article": "Condividi un link dettagliato a questo articolo",
					"topic": "Condividi un link dettagliato a questo argomento",
					"tour": "Condividi un link dettagliato a questa visita"
				},
				"radioItemHome": "Home dell’app",
				"descriptionHome": "Apre l’articolo di aiuto sulla pagina Home del Portale Merchant",
				"radioItemCurrent": "Pagina attuale",
				"descriptionCurrent": "Apre l’articolo di aiuto sulla pagina attuale"
			},
			"marketSelector": {
				"whichMarketToContact": "Quale mercato vorresti contattare?"
			}
		},
		"orientation": {
			"title": "Impara a…"
		},
		"helpbot": {
			"questions": {
				"whatDoYouNeedHelpWith": "Ciao! In cosa possiamo aiutarti oggi?",
				"selectSupportChannel": "Seleziona un canale per contattare il nostro servizio di assistenza.",
				"thatHelped": "Grazie per il tuo feedback. positivo. È molto importante per continuare ad offrire un aiuto sempre più preciso in futuro. 😀"
			},
			"orders": {
				"orderManagement": {
					"title": "Gestione dell’ordine",
					"createOrderPos": {
						"question": "Come creare un ordine con Point of sale?",
						"answer": "1. Apri Point of sale 2. Inserisci il nome dell’articolo, il prezzo e la quantità. 3. Invia al tuo cliente il link di pagamento generato via SMS, e-mail o chiedendo di scansionare il QR code visualizzato sulla schermata del pagamento. 4. Ora il cliente potrà completare l’acquisto dal suo dispositivo."
					},
					"extendExpiryReservation": {
						"question": "Come posticipare il termine di pagamento di una prenotazione?",
						"answer": "1. Vai al Gestore degli ordini e seleziona uno o più ordini per cui vuoi posticipare il termine di pagamento. 2. Clicca su Posticipa termine di pagamento. 3. Conferma la tua scelta.\nGli ordini vengono posticipati in base al tempo di prenotazione impostato per il tuo negozio (di solito 28 giorni). Gli ordini scaduti non possono essere posticipati.\""
					},
					"howToCaptureOrders": {
						"question": "Come acquisire un ordine?",
						"partially": {
							"question": "Voglio acquisire parzialmente un ordine",
							"answer": "Se sei in grado di spedire solo una parte dell’ordine, puoi acquisirlo parzialmente per linee d’ordine o per importo. Acquisisci la linea d’ordine 1. Apri l’ordine nel Gestore degli ordini e seleziona gli articoli attualmente disponibili in magazzino nella pagina dell’ordine. Ricorda di modificare le linee d’ordine per adattare le quantità, se necessario. 2. Clicca su Acquisisci. Acquisisci un importo: 1. Vai all’ordine nel Gestore degli ordini. Per acquisire un importo, clicca sul pulsante \"Acquisisci tutto\", quindi inserisci l’importo desiderato. 2. Clicca su Acquisisci.\nRicorda, se possibile, di completare l’ordine acquisendo gli articoli/importi rimanenti non appena disponibili per la spedizione o di annullare l’ordine rimanente se non potrai spedire la merce."
						},
						"fully": {
							"question": "Voglio acquisire totalmente gli ordini",
							"answer": "1. Vai al Gestore degli ordini e clicca sul numero di riferimento Klarna per aprire l’ordine. 2. Acquisisci l’ordine completo osservando uno dei seguenti passaggi: - Clicca sul pulsante \"Acquisisci\", quindi seleziona le linee d’ordine e clicca su \"Acquisisci\". - Clicca su \"Acquisisci tutto\" e inserisci l’importo totale dell’ordine. 3. Mentre acquisisci l’ordine, puoi scaricare la bolla di accompagnamento per includerla nella spedizione.\nVerifica che gli ordini vengano acquisiti prima del relativo termine di pagamento (di solito, 28 giorni dalla loro creazione). Se vuoi accelerare le cose, puoi acquisire più ordini selezionandoli all’interno dell’elenco e cliccando su \"Acquisisci integralmente\"."
						}
					},
					"canIUpdateCustomerInfo": {
						"question": "Posso aggiornare le informazioni del cliente?",
						"answer": "Non è mai possibile modificare il destinatario dell’ordine ma puoi modificare l’indirizzo di spedizione e fatturazione degli ordini non acquisiti o parzialmente acquisiti. Tuttavia, anche se Klarna rifiuta il nuovo indirizzo perché non risulta collegato al cliente, puoi modificare l’indirizzo di fatturazione."
					},
					"extendDueDate": {
						"question": "Come posticipare la data di scadenza di un ordine?",
						"answer": "Puoi posticipare la data di scadenza di un ordine cliccando su \"Posticipa scadenza\" dopo averlo acquisito. Estendere la data di scadenza di un ordine comporta un costo che verrà specificato prima di finalizzare l’azione."
					},
					"refundOrder": {
						"question": "Come posso rimborsare un ordine?",
						"answer": "1. Vai al Gestore degli ordini e seleziona l’ordine che intendi rimborsare. 2. Scegli il metodo di rimborso: seleziona gli articoli da rimborsare e clicca su \"Rimborsa\", oppure clicca su \"Rimborsa\" e inserisci l’importo da rimborsare. 3. L’importo rimborsato verrà stornato dal pagamento successivo e il cliente lo riceverà entro 5 giorni lavorativi.\nQuesto è valido solo per gli ordini acquisiti. Annulla gli ordini non acquisiti se non intendi spedire gli altri articoli. Se solo una parte dell’ordine è stata acquisita e spedita, revoca la parte non acquisita."
					},
					"cancelOrder": {
						"question": "Come si cancella un ordine?",
						"answer": "1. Vai al Gestore degli ordini e seleziona l’ordine che vuoi annullare. 2. Clicca su \"Cancella ordine\". Questa azione informa il tuo cliente che l’ordine non sarà spedito.\nSe necessario, è possibile ricreare gli ordini annullati. Non è possibile annullare gli ordini acquisiti, tuttavia puoi rimborsarli."
					},
					"unableToTakeActions": {
						"question": "Perché non riesco a eseguire azioni su un ordine?",
						"directPartner": {
							"question": "Ho stretto un accordo direttamente con Klarna",
							"answer": "Le autorizzazioni utente determinano ciò che gli utenti possono fare nel Partner Portal. Esistono due tipi di autorizzazioni utente: Amministratore - Utenti con accesso completo; ad esempio, un amministratore può accedere al Partner Portal e modificare tutte le funzionalità. Sezione specifica - Gli utenti non amministratori hanno accesso a una o più sezioni specifiche del Partner Portal.\nQuesto potrebbe comportare l’impossibilità di eseguire gli ordini. Esistono diversi ruoli di accesso al Gestore degli ordini; stabilisci insieme al tuo amministratore qual è il ruolo di accesso più adatto alle azioni che devi eseguire."
						},
						"distributionPartner": {
							"question": "Ho stretto un accordo direttamente con Klarna",
							"answer": "Tutte le azioni su un ordine possono essere effettuate solo attraverso la piattaforma fornita dal Distributon Partner"
						}
					}
				},
				"orderModification": "Modifica ordini e problemi",
				"orderProcessing": "Elaborazione ordini",
				"refundIssues": {
					"title": "Problemi con i rimborsi",
					"refundedOrderWhenCustomerFunds": {
						"question": "Ho rimborsato un ordine. Quando sarà disponibile per il mio cliente?",
						"answer": "Una volta emesso, il rimborso sarà ricevuto dai clienti entro 5 giorni lavorativi. Questo può variare in base al metodo di pagamento che il cliente ha scelto. Se il cliente ha delle domande su questo passaggio, indirizzalo al Servizio clienti Klarna."
					}
				},
				"reservations": {
					"title": "Prenotazioni",
					"whyUnableToExtendReservation": {
						"question": "Perché non riesco a prolungare il tempo di prenotazione di un ordine?",
						"answer": "Ci sono alcuni casi specifici in cui non è possibile posticipare la data di scadenza di un ordine, ecco quali:\n- L’ordine è già scaduto. In questo caso dovrai ricrearlo. Per farlo, vai nella pagina dell’ordine, clicca su “Copia” e seleziona “Crea nuovo ordine”.\n- L’ordine è stato pagato con carta. Il cliente dovrà fare un nuovo ordine poiché le date di scadenza di questi ordini non possono essere posticipate.\n"
					}
				},
				"paymentsForMyOrders": {
					"title": "Pagamenti dei miei ordini",
					"whenWillIGetPaid": {
						"question": "Quando riceverò il pagamento per un ordine?",
						"answer": "Per ricevere il pagamento di un ordine, prima di tutto dovrai acquisirlo. L’importo del pagamento, al netto di commissioni, resi e altre spese, sarà accreditato sul tuo conto bancario secondo il tuo piano di pagamento. Puoi rivedere le tue impostazioni di pagamento nella sezione “Liquidazioni”."
					},
					"refundOrderReceivedPayment": {
						"question": "Cosa succede se rimborso un ordine per cui ho già ricevuto il pagamento?",
						"answer": "L’importo rimborsato sarà detratto dal tuo prossimo pagamento programmato."
					},
					"referenceNotShowUp": {
						"question": "Il numero dell’ordine o di riferimento non viene visualizzato nel rapporto.",
						"answer": "Se non riesci a trovare il pagamento relativo a un ordine specifico:\n- Verifica che il numero ordine o il numero di riferimento siano corretti.\n- Assicurati di aver acquisito l’ordine.\n- Controlla se l’ordine rientra nel periodo di acquisizione del rapporto.\n- Ricorda che gli ordini cancellati prima della liquidazione non appaiono nel rapporto.\n"
					},
					"gotPaidSpecificOrder": {
						"question": "Come posso accertarmi di aver ricevuto il pagamento di un ordine specifico?",
						"answer": "Cerca l’ordine nel “Gestore degli ordini” del Portale Partner assicurandoti che il numero ordine o il numero di riferimento siano corretti. Sotto il numero di riferimento, verifica se l’ordine è stato acquisito. Se è stato acquisito, verrà visualizzato come in attesa o pagato. Per gli ordini pagati, clicca su “Vedi liquidazione” per visualizzare il rapporto corrispondente."
					}
				}
			},
			"disputes": {
				"disputeManagement": "Gestione delle contestazioni",
				"contactInfoAndNotifications": "Informazioni di contatto e Impostazioni delle notifiche",
				"timingAndDeadlines": "Tempistiche e scadenza delle contestazioni",
				"resolutionProcess": "Processo di risoluzione delle contestazioni",
				"merchantDisputesApp": {
					"title": "App Contestazioni per i commercianti",
					"respondToDispute": {
						"question": "Come faccio a sapere se devo rispondere a una richiesta di contestazione?",
						"answer": "Accedi alla sezione “Cose da fare” nella pagina home del Portale Partner e verifica se ci sono contestazioni in attesa di risposta."
					},
					"overviewDisputesCustomer": {
						"question": "Dove posso trovare una panoramica di tutte le contestazioni sollevate dai clienti?",
						"answer": "L’elenco delle contestazioni è disponibile nella sezione “Contestazioni”, nella pagina “Tutte le contestazioni”, non appena una contestazione viene sollevata.\n\nTieni presente che le contestazioni sono disponibili nella pagina “Tutte le contestazioni” fino a 180 giorni dopo la chiusura.\n"
					},
					"overviewDisputesResponse": {
						"question": "Dove posso trovare una panoramica di tutte le contestazioni in attesa di risposta?",
						"answer": "L’elenco delle contestazioni in attesa di risposta è disponibile nella sezione “Contestazioni” della pagina “Contestazioni aperte”, alla voce “Risposta richiesta”."
					},
					"updateContactInformation": {
						"question": "Come posso aggiornare le mie informazioni di contatto per le email di promemoria delle contestazioni?",
						"answer": "1. Vai alla sezione “Contestazioni”.\n2. Clicca sulla pagina “Impostazioni email e contestazioni”.\n3. Aggiorna le tue informazioni di contatto nella scheda “Email e notifiche”.\n\nTieni presente che puoi impostare informazioni di contatto diverse per gestire “Contestazioni”, “Acquisti non autorizzati” e “Ordini ad alto rischio”. Inoltre, puoi configurare la frequenza delle notifiche nella scheda “Notifiche”.\n"
					},
					"updateCustomerServiceOnKlarnaApp": {
						"question": "Come posso aggiornare le informazioni del mio Servizio Clienti nella Klarna app?",
						"answer": "1. Vai su “Impostazioni di pagamento”.\n2. Clicca su “Informazioni sul Servizio clienti”.\n3. Seleziona “Mercato” (se disponi di un supporto specifico a seconda del mercato).\n\nPer garantire ai clienti una migliore esperienza, puoi inserire le informazioni di contatto del cliente e la policy di reso da mostrare nella Klarna app.\n\nPer visualizzare queste informazioni devi avere tutti i diritti di accesso al Portale Partner.\n"
					},
					"improveDisputesPerformance": {
						"question": "Come posso migliorare il mio processo di gestione delle contestazioni?",
						"answer": "Per ottenere trucchi e suggerimenti su come migliorare la tua gestione delle contestazioni, consulta la “Guida per migliorare i resi e le contestazioni”. Per accedervi, vai alla pagina “Statistiche” cliccando sul pulsante “Ottieni informazioni” in alto a destra della pagina.\n\nPer visualizzare queste informazioni devi avere tutti i diritti di accesso al Portale Partner.\n"
					},
					"overviewDisputesPerformance": {
						"question": "Dove posso trovare una panoramica della mia gestione delle contestazioni?",
						"answer": "Per visualizzare statistiche dettagliate sulle contestazioni, consulta la pagina “Statistiche” nell’App Contestazioni. Questa sezione fornisce una panoramica delle contestazioni mensili, degli esiti, dei costi e delle perdite legate alle contestazioni.\n\nPer visualizzare queste informazioni devi avere tutti i diritti di accesso al Portale Partner.\n"
					}
				},
				"disputesLifecycle": {
					"title": "Fasi di gestione delle contestazioni",
					"whenCanSeeDisputes": {
						"question": "Quando posso vedere le contestazioni sollevate dai clienti?",
						"answer": "Non appena sollevate, tutte le contestazioni sono mostrate nell’App Contestazioni nella pagina “Tutte le contestazioni”."
					},
					"howLongToResolve": {
						"question": "Quanto tempo ho a disposizione per risolvere la contestazione direttamente con il cliente?",
						"answer": "Hai a disposizione 21 giorni dal giorno in cui viene sollevata una contestazione per risolverla con il cliente. Se la contestazione non viene risolta entro 21 giorni dalla sua apertura, Klarna interverrà per contribuire al processo di risoluzione tra le due parti."
					},
					"howMuchTimeToRefund": {
						"question": "Quanto tempo ho a disposizione per rimborsare un reso?",
						"answer": "Hai 21 giorni dal giorno in cui viene effettuato il reso per elaborare il rimborso prima che Klarna intervenga per contribuire al processo di risoluzione tra le due parti. Detto questo, è sempre meglio rimborsare i resi non appena li si riceve."
					},
					"howToResolve": {
						"question": "Come posso risolvere contestazioni e resi?",
						"answer": "Esistono diversi modi per risolvere una contestazione o un reso.\n\n- Elabora il reso o il rimborso non appena ricevuta la richiesta oppure concorda una soluzione con il cliente.\n- Imposta una soglia di chargeback nella sezione “Impostazioni email e contestazioni”.\n- Gestisci proattivamente le contestazioni controllando la sezione “Tutte le contestazioni”.\n- Accetta le perdite.\n"
					},
					"whenToRespond": {
						"question": "Quando devo “difendermi” o rispondere attivamente alle contestazioni?",
						"answer": "Se non riesci a trovare una soluzione con il cliente il termine stabilito (21 giorni), Klarna interverrà per contribuire al processo di risoluzione tra le due parti. Se la contestazione è valida, il team di assistenza di Klarna ti contatterà tramite l’App contestazioni richiedendoti ulteriori informazioni.\n\nA quel punto ti verrà chiesto di “difenderti” o rispondere a una contestazione.\n"
					},
					"howMuchTimeToRespond": {
						"question": "Quanto tempo ho a disposizione per rispondere a una richiesta di contestazione?",
						"answer": "Il termine per la prima richiesta è di 14 giorni per tutti i motivi di contestazione, ad eccezione degli Acquisti non autorizzati (7 giorni). Per ogni richiesta di assistenza* ricevuta (con un massimo di 5 richieste) hai a disposizione 7 giorni. Nel caso in cui hai bisogno di integrare informazioni alla tua risposta, avrai 60 minuti di tempo dall’arrivo della richiesta per aggiungere informazioni o allegati. Una volta scaduto il suddetto termine non potrai più “difenderti”.\n\n*Possono essere applicate regole speciali a seconda dei termini della collaborazione.\n"
					},
					"howLongToSeeDispute": {
						"question": "Per quanto tempo posso vedere una contestazione nell’App Contestazioni dopo la sua risoluzione?",
						"answer": "Le contestazioni sono disponibili nella pagina “Tutte le contestazioni” fino a 180 giorni dopo la loro chiusura."
					},
					"whenToGetCharged": {
						"question": "Quando vengono addebitate le spese di contestazione?",
						"answer": "Ti verrà richiesto di pagare una spesa di contestazione se:\n- La colpa della contestazione è tua.\n- Non riesci a rispondere prima della scadenza.\n- Emetti un rimborso dopo l’intervento di Klarna per supportare il processo di risoluzione.\n\nLa spesa di contestazione viene applicata retroattivamente dopo aver determinato l’esito della contestazione. Se la contestazione si risolve a tuo favore, viene annullata o rifiutata non ti verrà addebitato alcun costo.\n"
					}
				},
				"disputesHandling": {
					"title": "Gestione delle contestazioni",
					"whatIsDispute": {
						"question": "Quali informazioni ricevo insieme a una richiesta di contestazione?",
						"answer": "La richiesta di contestazione include:\n\n- Il motivo: ad es. reso, merce non ricevuta.\n- L’azienda: rilevante se più merchant ID (MID) sono registrati presso Klarna.\n- Data della contestazione: quando il cliente ha sollevato la contestazione.\n- Scadenza: il termine entro cui devi rispondere per risolvere la contestazione.\n- Prodotti contestati: articoli contestati.\n- Dettagli dell’ordine: include “Riferimento azienda 1 e 2”, “Data ordine” e “importo contestato”.\n"
					},
					"howManyTimesDefend": {
						"question": "Quante volte posso rispondere a una richiesta di contestazione?",
						"answer": "Hai a disposizione un massimo di cinque (5) risposte per rispondere alla tua contestazione, ma nella maggior parte dei casi non ne servono più di una o due."
					},
					"doIReceiveNotification": {
						"question": "Ricevo una notifica quando devo rispondere a una contestazione?",
						"answer": "Se hai una richiesta di contestazione in attesa di risposta, quest’ultima apparirà:\n\n- Nella sezione “Cose da fare” della homepage del Portale Partner.\n- Come notifica nella sezione “Contestazioni” della pagina “Contestazioni aperte”, incluso il numero di contestazioni in attesa di risposta.\n\nPuoi anche scegliere di ricevere un’email per ogni contestazione ricevuta andando in “Impostazioni email e contestazioni ” e cliccare su “Notifica via email per ogni contestazione”.\n"
					},
					"howToAvoidDisputesFees": {
						"question": "Come posso evitare il pagamento delle spese di contestazione?",
						"answer": "Per evitare le spese di contestazione:\n- Elabora tempestivamente i resi o i rimborsi.\n- Imposta una soglia di chargeback in “Impostazioni email e contestazioni”.\n- Controlla regolarmente “Tutte le contestazioni”.\n- Rispondi alle contestazioni fornendo informazioni pertinenti.\n- Rispondi prima della scadenza del termine.\n"
					}
				}
			},
			"settlements": {
				"payoutNotFound": {
					"title": "Pagamento per un ordine specifico non trovato",
					"orderNumberNotFound": {
						"question": "Il numero ordine o il numero di riferimento non vengono mostrati nel rapporto",
						"answer": "Se non riesci a trovare il pagamento relativo a un ordine specifico:\n- Verifica che il numero ordine o il numero di riferimento siano corretti.\n- Assicurati di aver acquisito l’ordine.\n- Controlla se l’ordine rientra nel periodo di acquisizione del rapporto.\n- Ricorda che gli ordini cancellati prima della liquidazione non appaiono nel rapporto.\n"
					},
					"checkIfPaidForOrder": {
						"question": "Come posso accertarmi di aver ricevuto il pagamento di un ordine specifico?",
						"answer": "Cerca l’ordine nel “Gestore degli ordini” del Portale Partner assicurandoti che il numero ordine o il numero di riferimento siano corretti. Sotto il numero di riferimento, verifica se l’ordine è stato acquisito. Se è stato acquisito, verrà visualizzato come in attesa o pagato. Per gli ordini pagati, clicca su “Vedi liquidazione” per visualizzare il rapporto corrispondente."
					}
				},
				"payouts": {
					"title": "Pagamenti",
					"whenIsNext": {
						"question": "Quando arriverà il mio prossimo pagamento?",
						"answer": null
					},
					"whyCalculationNotMatch": {
						"question": "Perché i miei calcoli non corrispondono ai pagamenti ricevuti?",
						"answer": "In base al contratto stipulato con il negozio vengono applicate specifiche commissioni, ad esempio per le transazioni e i resi tardivi. Pertanto, i tuoi calcoli potrebbero differire dalle liquidazioni di Klarna. Per vedere chiaramente le deduzioni applicate, consigliamo di aprire il rapporto di liquidazione nella versione PDF."
					},
					"whenGetPaid": {
						"question": "Quando riceverò il mio pagamento?",
						"answer": null
					},
					"missingPayout": {
						"question": "Non ho ricevuto il mio pagamento",
						"answer": "Prima di contattare il nostro canale di assistenza tieni in considerazione che i mancati pagamenti possono essere dovuti a quanto segue:\n\n- Klarna invia il pagamento lo stesso giorno in cui viene generato il rapporto, ma potrebbero essere necessari alcuni giorni prima che il denaro appaia sul tuo conto bancario.\n- Non è stato acquisito alcun ordine per questo periodo di acquisizione.\n- Hai recentemente aggiornato i dati del tuo conto bancario con Klarna, pertanto è possibile che il pagamento sia stato effettuato sul tuo precedente conto bancario. Questo può accadere se il pagamento viene effettuato prima dell’elaborazione della modifica.\n- Il numero di ordine o di riferimento che stai cercando non è corretto, fai una verifica.\n- I tuoi pagamenti sono stati trattenuti da Klarna. Dovresti aver ricevuto un’email in cui ti abbiamo comunicato della trattenuta del pagamento. Di solito questa comunicazione viene inviata a un amministratore.\n"
					},
					"howIsPayoutCalculated": {
						"question": "Come viene calcolato il mio pagamento?",
						"answer": "Il pagamento viene calcolato in base agli ordini acquisiti durante il periodo di acquisizione, al netto di commissioni, resi e altre spese. Il rapporto di liquidazione fornisce una ripartizione dettagliata di questi importi.\nSe i rimborsi superano le vendite in un determinato periodo di acquisizione, l’importo in eccesso sarà addebitato nel pagamento successivo.\n"
					}
				},
				"howPayoutsWork": {
					"question": "Come funzionano i pagamenti?",
					"answer": "La sezione Liquidazioni fornisce una panoramica sul funzionamento dei pagamenti, compresi i programmi e le date di pagamento, nonché i periodi di acquisizione. Puoi scaricare i rapporti sulle transazioni effettuate per ogni pagamento in formato PDF o CSV e visualizzare o scaricare i dettagli relativi a pagamenti specifici cliccando sui loro riferimenti."
				},
				"payoutSchedule": {
					"title": "Programmazione pagamento",
					"whyDelay": {
						"question": "Perché c’è un ritardo di pagamento?",
						"answer": "Lo scopo principale è di prevenire che il denaro delle fatture venga rinviato avanti e indietro tra Klarna e te, ma anche per coprire i casi in cui potresti rimborsare un ordine acquisito."
					},
					"changePayoutDelay": {
						"question": "Voglio modificare il ritardo di pagamento",
						"answer": "Abbiamo procedure interne e criteri generali che devono essere rispettati. Questi criteri generali includono quanto segue:\n- Devi aver effettuato una registrazione completa a Klarna\n- La prima data dell’ordine deve superare i 100 giorni\n- La tua attività deve essere aperta da più di 6 mesi.\n\nKlarna richiede anche informazioni da parte di un amministratore su…\n- Il nuovo termine di pagamento desiderato e il motivo per cui è necessario modificare il termine di pagamento\n- Quale merchant ID (MID) riguarda la modifica\nPossono essere applicati ulteriori criteri; le decisioni vengono prese su base individuale.\n"
					}
				},
				"generatingReports": {
					"title": "Generazione dei rapporti",
					"howToSeeReports": {
						"question": "Come posso fare a consultare i rapporti per periodi specifici?",
						"answer": "Per visualizzare i rapporti filtrandoli per periodi di tempo specifici vai nella sezione “Liquidazioni” e clicca su “Rapporti personalizzati”."
					},
					"convertCsvToExcel2": {
						"answer": "Segui questi passaggi\n1. Apri Excel, clicca su “File” > “Nuova cartella di lavoro”.\n"
					},
					"convertCsvToExcel": {
						"question": "Come posso convertire un report CSV in un foglio di calcolo Excel?",
						"answer": "1. Apri Excel, clicca su “File” > “Nuova cartella di lavoro”.\n2. Vai alla scheda “Dati” e seleziona “Dal testo”.\n3. Sceglie il file .CSV desiderato e clicca su “Ottieni dati”.\n4. In “Importazione guidata testo”, seleziona “Delimitato”, quindi clicca su “Avanti”.\n5. In “Delimitatori”, seleziona “Punto e virgola” e imposta “Qualificatore di testo” su “Nessuno”. Clicca su “Completa”.\n6. Nella finestra di dialogo “Importa dati”, clicca su “OK” per inserire i dati a partire dalla cella A1.\n\nNota: Nei report CSV nel Portale Merchant il punto e virgola (;) viene utilizzato come separatore. Ti consigliamo di controllare il tuo software dei fogli di calcolo per verificare come modificare il separatore predefinito.\n"
					}
				},
				"payoutAndFeeCalculation": {
					"title": "Calcolo dei pagamenti e delle commissioni",
					"howAreFeesCalculated": {
						"question": "Come vengono calcolate le commissioni?",
						"answer": "Le commissioni dedotte dai pagamenti Klarna sono indicate nel contratto con il negozio, mentre le informazioni sulle commissioni aggiuntive (come quelle per i resi tardivi) sono presenti nella sezione “Commissioni” dei rapporti di pagamento/liquidazione.\n\nPer personalizzare le descrizioni delle commissioni nei rapporti:\n1. Vai in “Rapporti di liquidazione” > “Configura rapporti” > “Aggiungi una nuova configurazione”.\n2. Per i rapporti CSV, seleziona “Tipo dettagliato di transazione” nell’elenco delle transazioni.\n3. Per i rapporti PDF, seleziona “Includi dettagli dell’ordine” nelle impostazioni di layout e contenuto.\n\nAssicurati di modificare le impostazioni per ciascun merchant ID e canale (SFTP, Portale Merchant, API) separatamente.\n"
					},
					"howIsPayoutCalculated": {
						"question": "Come viene calcolato il mio pagamento?",
						"answer": "Il pagamento viene calcolato in base agli ordini acquisiti durante il periodo di acquisizione, al netto di commissioni, resi e altre spese. Il rapporto di liquidazione fornisce una ripartizione dettagliata di questi importi.\n\nSe i rimborsi superano le vendite in un determinato periodo di acquisizione, l’importo in eccesso sarà addebitato nel pagamento successivo.\n"
					}
				}
			},
			"options": {
				"payout": "Pagamento",
				"orders": "Ordini",
				"settlements": "Liquidazioni",
				"disputes": "Contestazioni",
				"otherIssue": "Altri problemi",
				"yes": "Sì",
				"no": "No",
				"partially": "Parzialmente",
				"iNeedMoreHelp": "Ho bisogno di ulteriore aiuto",
				"thatHelped": "Questo mi è stato d’aiuto",
				"contactSupportChannel": "Contatta l’assistenza"
			},
			"button": {
				"label": "Richiedi supporto"
			},
			"contact": {
				"chat": "Chatta con un incaricato",
				"ticket": "Apri un ticket",
				"times": {
					"minutes": "Tempo di attesa: {time} minuto/i",
					"hours": "Tempo di attesa: {time} ora/e",
					"days": "Tempo di attesa:{time} giorno/i"
				}
			}
		}
	}
};