module.exports = {
	"de": {
		"home-fe": {
			"boosters-home-v03": {
				"install-plugin": {
					"stepNumber": "1",
					"title": "Das Plugin installieren",
					"description": "Wählen Sie Ihre E-Commerce-Plattform, damit wir einen Link für eine schnellere Installation erstellen können",
					"selectorLabel": "Bitte wählen Sie eine Plattform",
					"getPluginButtonLabel": "Holen Sie sich das Plugin",
					"klarnaDocsLinkLabel": "Weitere Informationen finden Sie in den Klarna Docs"
				},
				"start-setup": {
					"stepNumber": "2",
					"title": "Das Plugin aktivieren",
					"description": "Generieren und kopieren Sie die Mandantenkennung und den API-Schlüssel, denn diese werden zum Aktivieren des Plugins benötigt."
				},
				"promotion-banner": {
					"title": "Klarna für Plattformen",
					"subtitle": "Erweitern Sie Ihr Geschäft mit einem einzigen Plugin, der Ihnen ohne Zusatzkosten Zugang zu einer Vielzahl an Funktionen zur Umsatzsteigerung und einem verbesserten Shoppingerlebnis gewährt.",
					"banner-cards": {
						"osm-title": "Erhöhen Sie den durchschnittlichen Bestellwert mit <span>On-Site Messaging</span>",
						"kec-title": "Erhöhen Sie die Conversion mit Express Checkout",
						"siwk-title": "Gewinnen Sie Neukunden über <span>Mit Klarna anmelden</span>"
					}
				},
				"configure-boost-features": {
					"stepNumber": "3",
					"title": "Individuelle Platzierung von Funktionen",
					"description": "Dies ist ein optionaler Schritt, um das Aussehen der Funktionen in Ihrem Shop zu ändern. Dafür sollte Schritt 2 bereits abgeschlossen sein.",
					"no-credentials": "Generieren Sie erst die Mandantenkennung und den API-Schlüssel, um mit diesem Schritt fortzufahren.",
					"apps": {
						"osm": {
							"link-label": "Individuelles On-Site Messaging",
							"description": "Richten Sie das On-Site Messaging an Ihrem Markenauftritt aus, indem Sie das Theme anpassen und Platzierungen auswählen."
						},
						"kec": {
							"link-label": "Individueller Express Checkout",
							"description": "Passen Sie die Schaltfläche für Express Checkout bzw. schnelles Bezahlen durch Anpassen des Schaltflächen-Theme und dessen Form und Auswahl von Platzierungen an Ihren Markenauftritt an."
						},
						"siwk": {
							"link-label": "Mit Klarna anmelden einrichten",
							"description": "Legen Sie den Umfang für „MIt Klarna anmelden“ fest und fügen Sie eine Weiterleitungs-URL hinzu. Der Umfang bezieht sich auf die Daten, die von Ihrem Kunden gesammelt werden. Die Weiterleitungs-URL wird für Kunden mit Pop-up-Blockern verwendet."
						}
					}
				},
				"client-identifier-section": {
					"title": "Mandantenkennung",
					"description": "Die eindeutige Kennung zum Einrichten",
					"manage-link": "Die Mandantenkennung und den Ursprung verwalten",
					"generate-button": "Generieren",
					"tooltip": "Generieren und kopieren Sie die Mandantenkennung. Diese sind für die Einrichtung des Plugins auf dem Portal Ihrer Plattform erforderlich."
				},
				"api-key": {
					"warning-banner": {
						"content": "Sie verfügen bereits über aktive API-Zugangsdaten. Bitte deaktivieren Sie nicht genutzte Zugangsdaten, bevor Sie neue generieren."
					},
					"title": "Klarna API-Schlüssel",
					"description": "API-Schlüssel für die Einrichtung",
					"tooltip": "Bitte beachten Sie, dass Ihr API-Schlüssel geheim ist und nach dem Generieren nicht erneut angezeigt wird.",
					"generate-button": "Generieren",
					"manageApiKey": "API-Schlüssel verwalten"
				},
				"non-platform-integration": {
					"question": "Sie nutzen Klarna für Plattformen nicht?",
					"link": "Erfahren Sie mehr über die direkte Integration bei Klarna"
				}
			},
			"boosters-home": {
				"welcome-banner": {
					"title": "Nutzen Sie Klarna vollständig aus",
					"text": "Funktionen, die entwickelt wurden, um Besucher zu Power Shoppern zu machen. Verbessern Sie jeden Schritt des Shopping-Erlebnisses, vom Stöbern bis zum Bezahlvorgang."
				},
				"plugin-carousel": {
					"title": "Codefreie Einrichtung per Plugin",
					"overviewLink": "Alle Plattformen anzeigen",
					"directIntegrationLabel": "Sie nutzen Klarna für Plattformen nicht?",
					"directIntegrationLink": "Erfahren Sie mehr über die direkte Integration bei Klarna"
				},
				"booster-apps": {
					"title": "Mehr erfahren",
					"kec-fe": {
						"title": "Sorgen Sie für ein 5-mal schnelleres Check-out",
						"description": "Weniger Klicks führen zu weniger Abbrüchen. Sorgen Sie für mehr Conversions und minimieren Sie nicht abgeschlossene Warenkörbe mit Express-Checkouts auf Produkt- und Warenkorb-Seiten.",
						"callToAction": "Mehr zum Express Checkout"
					},
					"siwk-fe": {
						"title": "Social Sign-in fürs Shopping",
						"description": "Shopper erhalten eine schnelle und sichere Anmeldeoption mit einem Klick. Sie erhalten bessere, umsetzbare Profildaten zu Shoppern.",
						"callToAction": "Mehr zu Sign in mit Klarna"
					},
					"upstream-fe": {
						"title": "Verwandeln Sie Besucher in Käufer",
						"description": "Maximieren Sie die Conversion mit dynamischen Werbebotschaften während dem gesamten Shopping-Erlebnis.",
						"callToAction": "Mehr über On-Site-Messaging"
					},
					"branding-fe": {
						"title": "Verbessern Sie die Sichtbarkeit Ihres Shops",
						"description": "Verwalten Sie Ihre Marke in der Klarna App, indem Sie ein Shop-Logo, ein Symbol oder eine URL hochladen.",
						"callToAction": "Markenmanager einrichten"
					}
				}
			},
			"boosters-plugins": {
				"title": "Plugin-Einrichtung",
				"description": "Starten Sie mit unseren Plugins und stellen Sie sicher, dass Sie bereit sind, online zu gehen."
			},
			"boosters-plugin": {
				"title": "Klarna für {pluginName}",
				"description": "Folgen Sie den nachfolgenden Schritten, um Klarna Payments und/oder Boost-Features mit unserer codefreien Einrichtung zu aktiveren.",
				"access-warning": {
					"both": "Sie haben keine Berechtigung für {selectedStoreName} ({selectedMid}), um API-Schlüssel und eine Mandantenkennung zu generieren. Bitte kontaktieren Sie Ihren Admin-Benutzer und bitten Sie um Zugriff auf die Zahlungseinstellungen, um fortzufahren.",
					"client": "Sie haben keine Berechtigung für {selectedStoreName} ({selectedMid}), um eine Mandantenkennung zu generieren. Bitte kontaktieren Sie Ihren Admin-Benutzer und bitten Sie um Zugriff auf die Zahlungseinstellungen, um fortzufahren.",
					"api": "Sie haben keine Berechtigung für {selectedStoreName} ({selectedMid}), um API-Schlüssel zu generieren. Bitte kontaktieren Sie Ihren Admin-Benutzer und bitten Sie um Zugriff auf die Zahlungseinstellungen, um fortzufahren."
				},
				"back": "Zurück",
				"instructions": {
					"api-key": {
						"title": "Klarna Payments aktivieren",
						"content": "Für die Einrichtung ist es erforderlich, API-Schlüssel zu generieren und zu kopieren.",
						"link-label": "Weitere Informationen finden Sie in den Klarna Docs.",
						"generate": "API-Schlüssel generieren",
						"active-warning": {
							"content": "Sie verfügen bereits über aktive API-Zugangsdaten. Bitte deaktivieren Sie nicht genutzte Zugangsdaten, bevor Sie neue generieren.",
							"link": "API-Schlüssel verwalten"
						},
						"active-warning-api-keys": {
							"content": "Sie verfügen bereits über aktive API-Zugangsdaten. Bitte deaktivieren Sie nicht genutzte Zugangsdaten, bevor Sie neue generieren.",
							"link": "API-Schlüssel verwalten"
						}
					},
					"client-id": {
						"title": "Boost Features aktivieren",
						"content": "Um die Klarna Funktionen Express Checkout und On-Site Messaging hinzuzufügen, müssen Sie Ihre Mandantenkennung generieren und kopieren, um Sie bei der Einrichtung zu verwenden.",
						"generate": "Mandantenkennung generieren",
						"manage": "Mandantenkennung verwalten"
					},
					"plugin-doc": {
						"title": "Boost Features konfigurieren",
						"content": "Um Ihre Einrichtung abzuschließen, ist es erforderlich, Klarna Express Checkout und On-Site Messaging in den Einstellungen Ihrer Plattform zu konfigurieren."
					}
				},
				"error-messages": {
					"default": "Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut oder rufen Sie die Hilfeseite auf.",
					"fetch-data": "Beim Abrufen Ihrer Daten ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut oder rufen Sie die Hilfeseite auf.",
					"create-api-key": "Beim Erstellen Ihrer API-Schlüssel ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut oder rufen Sie die Hilfeseite auf.",
					"create-client-id": "Beim Erstellen Ihrer Mandantenkennung ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut oder rufen Sie die Hilfeseite auf."
				}
			},
			"marketing-home": {
				"description": "Willkommen bei unseres Marketingprodukten. Fangen Sie weiter unten an, zu störbern.",
				"essentials": {
					"title": "Marketing",
					"cta-label": "Mehr erfahren",
					"branding-fe": {
						"description": "Marke einrichten und managen. Lassen Sie Ihre Marke in Klarnas Ökosystem - einschließlich unserer smooothen App - im besten Licht erscheinen!"
					},
					"klarna-search-b2b-portal": {
						"description": "Nutzen Sie Klarnas effektive Suchmaschine und das Vergleichsshopping. Erhalten Sie Zugriff auf Statistiken und Tools für verbesserte Sichtbarkeit."
					}
				},
				"external-apps": {
					"title": "Apps",
					"cta-label": "App öffnen"
				},
				"popOvers": {
					"searchCompare": {
						"title": "Erste Schritte mit Search and Compare",
						"content": "Hier finden Sie Statistiken und Tools, die Ihre Sichtbarkeit auf Klarnas Vergleichsshopping-Service verbessern können."
					}
				}
			},
			"no-apps": {
				"title": "Keine Apps verfügbar!",
				"subtitle": "Wenden Sie sich an Ihren Admin, um Hilfe zum Zugriff auf benötigte Apps zu erhalten."
			},
			"contact": {
				"title": "Benötigen Sie bei irgendetwas Hilfe?",
				"paragraph": "Sollten Sie Fragen haben, können Sie sich einfach an uns wenden. Wir helfen Ihnen gerne weiter.",
				"label": "Kontakt"
			},
			"important-banner": {
				"title": "",
				"content": "",
				"linkLabel": ""
			},
			"two-factor-home-banner": {
				"banner-text": "Machen Sie Ihr Konto mit der Zwei-Faktor-Authentifizierung sicherer.",
				"banner-link": "Jetzt einrichten"
			},
			"two-factor-opt-in": {
				"title": "Richten Sie die Zwei-Faktor-Authentifizierung ein",
				"intro-paragraph": "Um die Sicherheit Ihres Kontos zu erhöhen, empfehlen wir Ihnen, heute die Zwei-Faktor-Authentifizierung einzurichten.",
				"help-link-text": "Klicken Sie hier",
				"help-text": ", um mehr darüber zu erfahren, wie Sie es einrichten und wie es Sie schützt.",
				"warning-text": "Stellen Sie sicher, dass Sie Ihr Smartphone haben und Zugang zum Internet haben, bevor Sie fortfahren",
				"continue-button": "Starten Sie das Setup"
			},
			"accept-invite-modal": {
				"title": "Unbestätigte Einladungen akzeptieren",
				"description": "Sie wurden eingeladen, auf die folgenden Shops zuzugreifen. Bitte beachten Sie, dass es einige Minuten dauern kann, bis Ihre Auswahl wirksam wird.",
				"accept-all-button": "Alle akzeptieren",
				"decline-all-button": "Alle ablehnen",
				"save-button": "Speichern",
				"error": "Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut!",
				"invite": {
					"accept-label": "Akzeptieren",
					"decline-label": "Ablehnen"
				}
			},
			"capture-orientation": {
				"title": "Neu bei Klarna?",
				"sub-title": "Befolgen Sie diese Schritte, um die Grundlagen zu lernen:",
				"order-label": "Eine Bestellung erhalten",
				"capture-label": "Eine Bestellung erfassen",
				"payout-label": "Zahlung erhalten",
				"capture-cta": "Diese Bestellung erfassen",
				"payout-cta": "Abrechnungs-App",
				"dismiss-cta": "Ablehnen",
				"order-step": "Zunächst sollten wir auf eine eingehende Bestellung Ihrer Kunden warten.",
				"capture-step": "Herzlichen Glückwunsch zu Ihrer ersten Bestellung.\nUm Ihre Zahlung zu erhalten, müssen Sie im nächsten Schritt Ihre Bestellung versenden und erfassen.",
				"payout-step": "Herzlichen Glückwunsch zum Erfassen Ihrer ersten Bestellung.\nSie erhalten eine Auszahlung von uns in Übereinstimmung mit den in Ihrem Klarna Vertrag festgelegten Fristen.\nWeitere Details zu Ihrer ersten Auszahlung finden Sie hier:",
				"completed-step": "Herzlichen Glückwunsch zu Ihrer ersten Auszahlung.\nWillkommen zu einer spannenden Reise mit Klarna.\nZögern Sie nicht, auch unserer anderen, unten vorgestellten Apps zu entdecken."
			},
			"banner": {
				"common": {
					"branding-fe": {
						"content": "Wir haben gerade unsere neue Branding-App gestartet!",
						"linkLabel": "Übernehmen Sie bei Ihrer Marke das Steuer!"
					},
					"onSiteMessaging": {
						"title": "Machen Sie Besucher mit On-Site Messaging zu Shoppern",
						"text": "Shopping fängt an, bevor Ihre Kunden den Warenkorb füllen. Mit On-Site Messaging verbessern Sie das Bewusstsein für Klarnas flexible Zahlungsoptionen und andere Vorteile schon früh im Sales-Funnel.",
						"cta": "Sehen Sie es sich an"
					}
				}
			},
			"nko": {
				"dialog": {
					"title": "Wir haben aufregende Neuerungen für Ihr Geschäft für Sie parat.",
					"subtitle": "Und wir würden Sie gerne näher kennenlernen.",
					"explore": "Ja, sehen wir uns das an!",
					"name-field": "Wie sollen wir Sie also nennen?",
					"terms-pre": "Ich habe die AGB gelesen",
					"terms-text": "und stimme ihnen zu"
				}
			},
			"insightsWidget": {
				"title": "Heutiger Umsatz",
				"orderCount": "Erstellte Bestellungen",
				"orderVolume": "Bestellumsatz",
				"orderVolumeAverage": "Durchschnittlicher Bestellwert",
				"currencyFootnote": "Angezeigt in {currency}",
				"link": "Bestellungen anzeigen",
				"allStores": "Alle Händler",
				"selectAllOptionText": "Alle auswählen",
				"deselectAllOptionText": "Auswahl löschen",
				"closeText": "Fertig",
				"closeTextWithSelection": "# Auswahl(en) anwenden",
				"elapsedTime": "Letzter Bestellungseingang vor {hours, plural, =0 {} =1 {einer Stunde} other {# Stunden}} {minutes, plural, =0 {} =1 {einer Minute} other {# Minuten}}.",
				"error": {
					"content": "Leider wurde Ihre Bestellstatistik nicht korrekt geladen."
				},
				"relativeDate": {
					"today": "Heute",
					"yesterday": "Gestern"
				},
				"weekDays": {
					"1": "Montag",
					"2": "Dienstag",
					"3": "Mittwoch",
					"4": "Donnerstag",
					"5": "Freitag",
					"6": "Samstag",
					"7": "Sonntag"
				}
			},
			"blackFridayWidget": {
				"title": "Die Black Friday Woche ist da.",
				"text": "Verfolgen Sie Ihre Statistiken in Beinahe-Echtzeit.",
				"button": "Siehe Statistiken"
			},
			"tasks": {
				"title": "Aufgaben",
				"error": {
					"title": "Wir konnten die To-Do-Liste nicht laden.",
					"desc": "Bitte aktualisieren Sie die Seite, damit wir es noch einmal versuchen können."
				},
				"empty": {
					"title": "Alles erledigt!",
					"desc": "Sie haben keine ausstehenden Aufgaben."
				},
				"single": {
					"products-fe:complete-recollection": "Ein obligatorisches AML Formular ist für Ihr Unternehmen auszufüllen zur Geldwäschebekämpfung",
					"products-fe:resume-onboarding": "Onboarding abschließen",
					"settings-fe:compile-cs-info": "Fügen Sie Angaben zu Ihrem Kundenservice hinzu"
				},
				"aggregated": {
					"disputes-fe:unresolved": "{count} ungelöste Beanstandungen",
					"orders-fe:expiring": "{ count, plural, =1 {1 ablaufende Bestellung} other {# ablaufende Bestellungen}}",
					"orders-fe:tocapture": "{ count, plural, =1 {1 zu aktivierende Bestellung} other {# zu aktivierende Bestellungen}}",
					"orders-fe:uncaptured": "{count} nicht aktivierte Bestellungen",
					"users-fe:mid-added": "{ count, plural, =1 {1 neuer Shop wurde hinzugefügt} other {# neue Shops wurden hinzugefügt}}",
					"users-fe:mid-invited": "{ count, plural, =1 {1 Shop-Einladung zu bearbeiten} other {# Shop-Einladungen zu bearbeiten}}"
				}
			}
		}
	}
};