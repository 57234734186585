module.exports = {
	"es": {
		"credentials": {
			"addOriginModal": {
				"buttonPrimaryText": "Registrar",
				"buttonSecondaryText": "Cancelar",
				"description": "Registra cualquier dominio de origen en el que esperas usar Web SDK. Si necesitas registrar más de uno podrás hacerlo más adelante.",
				"errorMessage": "Se ha producido un error. Inténtalo de nuevo más tarde o ponte en contacto con el servicio de soporte si el problema persiste.",
				"inputLabel": "Introduce el resto de tu URL",
				"title": "Registrar origen permitido"
			},
			"clientKeyBlock": {
				"mainText": "El identificador de tu cliente está configurado directamente en el código con el dominio de origen que has proporcionado.",
				"secondaryText": "Para gestionarlo, sigue el enlace a la",
				"linkDescription": "página de identificadores de cliente",
				"linkLabel": "Cerrar"
			},
			"clientKeyField": {
				"label": "Identificador de cliente",
				"defaultErrorMessage": "Falta el identificador de cliente"
			},
			"copyToClipboardButton": {
				"label": "Copiar",
				"labelOnCopy": "Se ha copiado"
			},
			"originField": {
				"label": "Dominio de origen",
				"tooltipMainText": "Para registrar más dominios de origen o gestionar dominios existentes, visita la",
				"tooltipLinkLabel": "página de identificadores de cliente"
			},
			"originInputField": {
				"label": "Introduce el resto de tu URL",
				"validationError": "Introduce una URL de dominio válida",
				"invalidProtocolError": "La URL debe empezar por http:// o https://"
			},
			"addApiKeySuccessModal": {
				"title": "",
				"description": "Guarda las credenciales siguientes en tu dispositivo o en otro lugar seguro.",
				"warning": "La contraseña sólo se mostrará esta vez y desaparecerá al cerrar esta ventana.",
				"checkbox": "He guardado las credenciales en mi dispositivo.",
				"download": "Descargar en formato .txt"
			},
			"common": {
				"password": "Contraseña",
				"username": "Nombre de usuario",
				"close": "Cerrar"
			}
		}
	}
};