import { constants, IconNotification } from '@klarna/bubble-ui'
import { memoizeWithTokenHelper } from '@mpp/token-helper'
import { AppIcon } from 'mage-components'
import { selectors as coreSelectors } from 'mage-core'
import { selectors as i18nSelectors } from 'mage-i18n'
import { selectors as stateSelectors } from 'mage-state'
import * as R from 'ramda'
import React from 'react'
import { createSelector } from 'reselect'
import shuffle from 'shuffle-array'

import { NAME } from './constants'

const homeState = R.propOr({}, NAME)

const getBanners = R.pipe(
  homeState,
  R.propOr([], 'banners')
)

const newAppNotificationBanner = createSelector(
  stateSelectors.getUserAccessibleApps,
  i18nSelectors.translator,
  (apps, t) => memoizeWithTokenHelper(
    tokenHelper => {
      return apps(tokenHelper)
        .filter(R.prop('newApp'))
        .map(({ clientId, url }) => ({
          content: t(`home-fe.banner.common.${clientId}.content`),
          link: url,
          linkLabel: t(`home-fe.banner.common.${clientId}.linkLabel`),
          Icon: () => <AppIcon color={constants.Colors.BLACK.toString()} clientId={clientId} />
        }))
        .filter(
          R.both(R.prop('content'), R.prop('linkLabel'))
        )
    }
  )
)

const generalNotificationBanners = createSelector(
  stateSelectors.getUserAccessibleApps,
  i18nSelectors.translator,
  getBanners,
  (apps, t, banners) => memoizeWithTokenHelper(
    tokenHelper => {
      return banners
        .filter(banner => apps(tokenHelper).some(R.propEq(banner.app, 'clientId')))
        .map(({ id, link, Icon = IconNotification }) => ({
          content: t(`home-fe.banner.${id}.content`),
          link,
          linkLabel: t(`home-fe.banner.${id}.linkLabel`),
          Icon
        }))
        .filter(
          R.both(R.prop('content'), R.prop('linkLabel'))
        )
    }
  )
)

const getNotificationBanners = createSelector(
  newAppNotificationBanner,
  generalNotificationBanners,
  (newAppBanners, generalBanners) => memoizeWithTokenHelper(
    tokenHelper => generalBanners(tokenHelper).concat(shuffle(newAppBanners(tokenHelper)))
  )
)

const getShouldShowDialog = createSelector(
  coreSelectors.getRequester,
  R.ifElse(
    R.equals(undefined),
    R.F,
    R.complement(R.propEq(true, 'has_accepted_nko_terms'))
  )
)

export {
  generalNotificationBanners,
  getNotificationBanners,
  getShouldShowDialog,
  newAppNotificationBanner
}
