import './CollapsibleStep.css';
import { Chip, IconChevronBottom, IconChevronTop, Typography } from '@klarna/bubble-ui';
import React from 'react';
import styled from 'styled-components';
import { StepContainer } from '../styled';
const ChipStyled = styled(Chip).withConfig({ displayName: "ChipStyled", componentId: "sc-f2rsft" }) `
  span {
    margin: 0 !important;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const SubContainer = styled.div.withConfig({ displayName: "SubContainer", componentId: "sc-1db7n72" }) `
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;
const HeaderContainer = styled.div.withConfig({ displayName: "HeaderContainer", componentId: "sc-1aupqp3" }) `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
`;
const CorrectTitleAlignement = styled.div.withConfig({ displayName: "CorrectTitleAlignement", componentId: "sc-ye4tlx" }) `
  position: relative;
  top: ${props => props.mustCorrect ? '8px' : '0'};
`;
const InvisibleButton = styled.button.withConfig({ displayName: "InvisibleButton", componentId: "sc-1x6rog9" }) `
  border: none;
  background: none;
  width: 24px;
  cursor: pointer;
`;
export default function CollapsibleStep({ isOpen, onClick, index, title, closedContent, children }) {
    return (React.createElement(StepContainer, null,
        React.createElement(ChipStyled, { title: String(index) }),
        React.createElement(SubContainer, null,
            React.createElement(HeaderContainer, null,
                React.createElement(CorrectTitleAlignement, { mustCorrect: !isOpen },
                    React.createElement(Typography, { textToken: "text-style/headings/titles/bold/grande" }, title)),
                React.createElement(InvisibleButton, { onClick: onClick, "data-testid": 'collapsible-step-button' }, isOpen ? React.createElement(IconChevronTop, null) : React.createElement(IconChevronBottom, null))),
            React.createElement("div", { className: `opacity-transition${isOpen ? ' show' : ''}` }, children),
            !isOpen && (closedContent === null || closedContent === void 0 ? void 0 : closedContent()))));
}
