import React, { createContext, useContext, useState } from 'react';
import { PLUGINS } from '../../../constants';
import { getPluginFromUrl } from './utils/getPluginFromUrl';
export const PluginContext = createContext(undefined);
export const PluginProvider = ({ children, mustExtractPluginIdFromUrl = true }) => {
    const [pluginId, setPluginId] = useState(mustExtractPluginIdFromUrl ? getPluginFromUrl() : '');
    const access = {
        pluginId,
        pluginName: '',
        pluginDocUrl: '',
        pluginLink: '',
        setPluginId: mustExtractPluginIdFromUrl ? undefined : setPluginId
    };
    if (pluginId.length > 0) {
        const plugin = getPlugin(pluginId);
        access.pluginName = (plugin === null || plugin === void 0 ? void 0 : plugin.name) || '';
        access.pluginDocUrl = (plugin === null || plugin === void 0 ? void 0 : plugin.docUrl) || '';
        access.pluginLink = (plugin === null || plugin === void 0 ? void 0 : plugin.pluginLink) || '';
    }
    return (React.createElement(PluginContext.Provider, { value: access }, children));
};
const getPlugin = (pluginId) => PLUGINS.find(plugin => plugin.id === pluginId);
export const usePlugin = () => {
    const value = useContext(PluginContext);
    if (!value)
        throw new Error('cannot get PluginContext as not been provided');
    return value;
};
